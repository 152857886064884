import * as React from 'react';
import { Redirect } from 'react-router';
import { Container } from 'typedi';

import { Error500LocationState } from '@app/modules/app/error500.page';
import { AppPath } from '@app/modules/app/routes';

import { hasWindow } from '../browser/platform';
import { cleanCache } from '../clean-cache/clean-cache';
import { Router } from '../route/router';
import { SessionTokenDataSource } from '../storage/session-token.datasource';

interface LoadableLoadingWrapperProps {
  error?: Error;
  timedOut?: boolean;
  pastDelay?: boolean;

  children: any;
}

/**
 * Use this component to wrap a Loadable loading. It enhances a loading view
 * with some recommendations from react-loadable docs.
 * https://github.com/jamiebuilds/react-loadable#avoiding-flash-of-loading-component
 */
export class LoadableLoadingWrapper extends React.Component<LoadableLoadingWrapperProps> {
  private sessionTokenDataSource = Container.get(SessionTokenDataSource);
  private router = Container.get(Router);

  render() {
    if (this.props.error || this.props.timedOut) {
      const url = hasWindow() ? window.location.pathname : '-';
      const hasAlreadyReload = this.sessionTokenDataSource.getPageLoadingError().has(url);

      if (!hasAlreadyReload) {
        /** tries to load this loadable again */
        this.sessionTokenDataSource.addPageLoadingError(url);

        cleanCache().then(() => {
          this.router.reload();
        });
        return this.props.children;
      } else {
        /** send user to error500 if it has already tried to load this Loadable */
        this.sessionTokenDataSource.removePageLoadingError(url);

        const currentUrl = hasWindow() ? window.location.href : AppPath.Login;
        const locationState: Error500LocationState = {
          message: 'Ocorreu um erro. Por favor, tente novamente.',
          retryUrl: currentUrl,
          stringfiedError: JSON.stringify(this.props.error),
        };
        const toValue = { pathname: AppPath.error500, state: locationState };
        return <Redirect to={toValue} />;
      }
    } else if (this.props.pastDelay) {
      return this.props.children;
    } else {
      return null;
    }
  }
}
