import { Theme } from '@atomic/obj.constants';
import { IcSchedule } from '@app/components/icons/ic-schedule.component';
import styled from 'styled-components';
import { IcWhatsapp } from '@app/components/icons/ic-whatsapp.component';

export const IcScheduleWhite = styled(IcSchedule).attrs((props: Theme) => ({
  color: props.theme.Color.White,
}))``;

export const IcWhatsappWhite = styled(IcWhatsapp).attrs((props: Theme) => ({
  color: props.theme.Color.White,
}))``;
