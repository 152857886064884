import { Service } from 'typedi';

import { HttpInterceptor } from '@app/core/http';
import { Response } from '@app/data/http/dtos';

@Service()
export class UnwrapDataInterceptor implements HttpInterceptor {
  async afterSuccess(response: Response<any>): Promise<any> {
    return response.data;
  }
}
