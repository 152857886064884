import { IcBotAppLogo } from '@app/components/icons/ic-bot-app-logo.component';
import { IcEudoraAppLogo } from '@app/components/icons/ic-eudora-app-logo.component';
import { Project } from '@app/config-variables';
import { CommonTheme } from '@atomic/obj.constants';
import * as React from 'react';
import { ThemeContext } from 'styled-components';

interface IcLogoProps {
  height?: string;
  width?: string;
}

export const IcAppLogo = (props: IcLogoProps) => {
  const theme = React.useContext<CommonTheme>(ThemeContext);

  switch (theme.Project) {
    case Project.bot:
      return <IcBotAppLogo {...props} />;
    case Project.eudora:
      return <IcEudoraAppLogo {...props} />;
    default:
      return null;
  }
};
