import styled from 'styled-components';

import { Button } from '@atomic/atm.button';
import { Theme } from '@atomic/obj.constants';

export const LARGE_BUTTON_HEIGHT = '48px';

export const LargeButton = styled(Button)`
  min-height: ${LARGE_BUTTON_HEIGHT};
  line-height: ${(props: Theme) => props.theme.FontSize.Medium};
`;
