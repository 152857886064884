import { Localized } from '@app/components/obj.localization';
import { ClientError, ClientErrorType } from '@app/core/error';
import { ApolloError, isApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';
import Container from 'typedi';
import { ErrorResponse } from '@apollo/client/link/error';
import { NewRelicErrorModel } from '@app/models/new-relic-error.model';

const localized = Container.get(Localized);

interface NetworkError {
  name: string;
  response: {
    type: string;
    status: number;
    ok: false;
    headers: {
      map: string;
    };
    url: string;
    bodyUsed: true;
  };
  statusCode: number;
  bodyText?: string;
  result?: any;
}

enum HttpStatusCode {
  BadGateway = 502,
  Unathorized = 401,
  NotFound = 404,
}

enum GraphQLErrorName {
  NotFoundError = 'NotFoundError',
}

function isTimeoutError(message: string): boolean {
  const TIMEOUT_ERROR_MESSAGE = 'timeout';
  return message.includes(TIMEOUT_ERROR_MESSAGE);
}

function isConnectionError(message: string): boolean {
  const CONNECTION_ERROR_MESSAGE = 'Network request failed';
  return message.includes(CONNECTION_ERROR_MESSAGE);
}

export function handleNetworkError(
  networkError: NetworkError,
  fallbackMessage: string = localized.strings.error.base,
): ClientError {
  if (networkError.result?.errors?.length > 0) {
    const [error] = networkError.result.errors;
    return new ClientError(error.message || fallbackMessage, ClientErrorType.Server, error.uuid);
  } else if (networkError.response?.status === HttpStatusCode.BadGateway) {
    return new ClientError(localized.strings.error.GraphQLClient.BadGateway, ClientErrorType.Server);
  } else if (networkError.response?.status === HttpStatusCode.Unathorized) {
    return new ClientError(localized.strings.error.GraphQLClient.Unauthorized, ClientErrorType.Unauthorized);
  } else {
    console.warn('[NetworkError] Unexpected network error.');
    const errorMessage = networkError.bodyText || fallbackMessage;
    return new ClientError(errorMessage, ClientErrorType.Unexpected);
  }
}

function handleGraphQLError([graphQLError]: (GraphQLError & { uuid: string })[]): ClientError {
  const type = graphQLError.name === GraphQLErrorName.NotFoundError ? ClientErrorType.NotFound : ClientErrorType.Server;
  return new ClientError(graphQLError.message, type, graphQLError.uuid);
}

function handleApolloError({ graphQLErrors, networkError: apolloNetworkError, message }: ApolloError): ClientError {
  const networkError: NetworkError = apolloNetworkError as any;

  if (isTimeoutError(message)) {
    return new ClientError(localized.strings.error.GraphQLClient.Timeout, ClientErrorType.Timeout);
  }

  if (isConnectionError(message)) {
    return new ClientError(localized.strings.error.GraphQLClient.Connection, ClientErrorType.Connection);
  }

  if (networkError !== null) {
    return handleNetworkError(networkError, message);
  } else if (graphQLErrors.length > 0) {
    return handleGraphQLError(graphQLErrors as (GraphQLError & { uuid: string })[]);
  } else {
    return new ClientError(message, ClientErrorType.Unexpected);
  }
}

export function mapApolloError(error: any | null): ClientError {
  console.warn('[GraphQLError] Error', JSON.stringify(error, null, 2));

  if (!error) {
    if (typeof error === 'object') {
      return new ClientError(localized.strings.error.GraphQLClient.Connection, ClientErrorType.Connection);
    } else {
      console.warn('[GraphQLError] No error object found.');
      return new ClientError(localized.strings.error.GraphQLClient.Unexpected, ClientErrorType.Unexpected);
    }
  }

  if (isApolloError(error)) {
    return handleApolloError(error);
  } else {
    console.warn('[GraphQLError] Invalid error format.');
    return new ClientError(localized.strings.error.GraphQLClient.Unexpected, ClientErrorType.Unexpected);
  }
}

export const isNetworkNotFoundError = (error: ApolloError) => {
  const networkError: NetworkError = error?.networkError as any;
  if (!networkError) {
    return false;
  }

  return networkError.statusCode === HttpStatusCode.NotFound;
};

export function mapGraphqlErrorToNewRelic(error: ErrorResponse): NewRelicErrorModel {
  return {
    operationName: error.operation.operationName,
    variables: JSON.stringify(error.operation.variables),
  };
}
