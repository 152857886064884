import * as React from 'react';
import { Frame } from '@atomic/atm.frame';
import { Hbox } from '@atomic/obj.box';
import { H1, H1Light } from '@atomic/atm.typography';
import { FrameColorTypes, FrameRoundBorders } from '@atomic/atm.frame/frame.component.style';

import { useHistory, useLocation } from 'react-router-dom';
import {
  StepWrapper,
  StepChevron,
  CheckedStep,
  Circle,
} from '@app/components/mol.step-header/step-header.style.component';

export enum SteptHeaderPosition {
  First = 'First',
  Middle = 'Middle',
  Last = 'Last',
}

export interface StepHeaderItemProps {
  title: string;
  link: string;
  completed: boolean;
  position?: SteptHeaderPosition;
  disabled?: boolean;
}

const FRAME_BORDERS = {
  [SteptHeaderPosition.First]: FrameRoundBorders.Left,
  [SteptHeaderPosition.Middle]: null,
  [SteptHeaderPosition.Last]: FrameRoundBorders.Right,
};

export const StepHeaderItem: React.FC<StepHeaderItemProps> = (props) => {
  const location = useLocation();
  const history = useHistory();

  const color = props.completed && FrameColorTypes.Sucess;
  const { disabled, link, position, title, completed } = props;

  return (
    <StepWrapper
      onClick={() => !disabled && history.push({ pathname: link, search: location.search, state: location.state })}
      disabled={disabled}
    >
      <Frame color={color} roundBorder={FRAME_BORDERS[position]} selected={location?.pathname === link}>
        <Hbox>
          <Hbox.Item vAlign='center' noGrow>
            {props.completed ? <H1Light>{title}</H1Light> : <H1>{title}</H1>}
          </Hbox.Item>
          <Hbox.Separator />
          <Hbox.Item vAlign='center'>
            <StepChevron completed={completed} />
          </Hbox.Item>
        </Hbox>
        <Hbox>
          <Hbox.Item hAlign='center'>{completed ? <CheckedStep /> : <Circle />}</Hbox.Item>
        </Hbox>
      </Frame>
    </StepWrapper>
  );
};
