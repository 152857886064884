import { ProductCellProps } from '@app/components/mol.cart-product-cell';
import { StartOrderStarterPackInput } from '@app/data/graphql';
import { StarterPacks, StarterPacksProducts } from '@app/domain/initial-params.use-case';

export const getProductCellPropsFromStarterPackOption = (
  product: StarterPacksProducts,
  selectedStarterPacks: StartOrderStarterPackInput[],
  pack: StarterPacks,
  onStarterPackChange: (input: StartOrderStarterPackInput) => void,
): ProductCellProps => {
  const previousPackSelection = selectedStarterPacks?.find((selectedPack) => pack.id === selectedPack.id);
  const isSelected = previousPackSelection?.productId === product.id;
  const packAlreadyHasAnotherProductSelected = !!previousPackSelection?.productId && !isSelected;

  return {
    loading: false,
    maxValue: packAlreadyHasAnotherProductSelected ? 0 : pack.quantity,
    minValue: 0,
    onChangeQuantity: (quantity) =>
      onStarterPackChange({
        id: pack.id,
        productId: quantity < pack.quantity ? null : product.id,
      }),
    product: {
      ...product,
      quantity: isSelected ? pack.quantity : 0,
    },
  };
};
