import { FlashDispatcherService } from '@app/components/obj.flash-wrapper/flash-dispatcher.service';
import { LocalizedContext } from '@app/components/obj.localization';
import { Project } from '@app/config-variables';
import { Route, Switch } from '@app/core/route';
import { useErrorFlashDispatcher } from '@app/domain/error-dispatcher.hook';
import { useCancelOrderMutation } from '@app/domain/orders.use-case';
import { CartPageLocationState } from '@app/models/orders.model';
import { useAppType } from '@app/modules/account/use-appType';
import { AppPath } from '@app/modules/app/routes';
import { CartManagementPage } from '@app/modules/cart/cart-management.page';
import { CartSelectAddressPage } from '@app/modules/cart/cart-select-address.page';
import { CartSelectCyclePage } from '@app/modules/cart/cart-select-cycle.page';
import { CartSelectStarterKitPage } from '@app/modules/cart/cart-select-starter-kit.page';
import { CartStepHeaderNavigator } from '@app/modules/cart/mol.cart-step-header/cart-step-header-navigator.component';
import { OrderContext } from '@app/providers';
import { DividerGrayVertical } from '@atomic/atm.divider/divider.component.style';
import { Frame } from '@atomic/atm.frame';
import { ShimmerBox, ShimmerButton, ShimmerH, ShimmerText } from '@atomic/mol.shimmer';
import { Hbox } from '@atomic/obj.box';
import { CommonTheme } from '@atomic/obj.constants';
import { Col, Grid, Row, VSeparator } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router';
import { ThemeContext } from 'styled-components';
import Container from 'typedi';
import { CartSelectBusinessModelPage } from './cart-business-model.page';
import { CartEditOrderPage } from './cart-edit-order.page';

export const CartPagesRouter: React.FC = () => {
  const localized = React.useContext(LocalizedContext).strings.components;
  const theme = React.useContext<CommonTheme>(ThemeContext);
  const order = React.useContext(OrderContext);
  const location = useLocation<CartPageLocationState>();
  const history = useHistory();
  const { isStarts } = useAppType();

  const flashDispatcherService = Container.get(FlashDispatcherService);

  const handleSuccessCancelOrder = () => {
    flashDispatcherService.dispatchMessage({ message: localized.cancelOrder.success }, 'success');
    history.push({
      pathname: isStarts ? AppPath.Starts.Base : AppPath.Activation.Home.Dashboard.Base,
      state: { resellerId: location.state.resellerId },
    });
  };

  const { cancelOrder, cancelResult } = useCancelOrderMutation({
    onCompleted: handleSuccessCancelOrder,
    onError: () => {
      flashDispatcherService.dispatchMessage({ message: localized.cancelOrder.error }, 'alert');
    },
  });

  useErrorFlashDispatcher(cancelResult.error);

  const handleCancel = () => {
    if (location.pathname.includes(AppPath.Cart.Management)) {
      cancelOrder(order.cart?.orderCode);
    } else {
      history.push({
        pathname: isStarts ? AppPath.Starts.Base : AppPath.Activation.Home.Dashboard.Base,
        state: { resellerId: location.state.resellerId },
      });
    }
  };

  return (
    <LoadingState loading={order.loading}>
      <LoadingState.Shimmer>
        <CartPageShimmer />
      </LoadingState.Shimmer>

      <CartStepHeaderNavigator onCancel={handleCancel} loading={cancelResult.loading} />

      <Switch>
        {theme.Project === Project.eudora && (
          <Route path={AppPath.Cart.BusinessModel} component={CartSelectBusinessModelPage} exact />
        )}
        <Route path={AppPath.Cart.Address} component={CartSelectAddressPage} exact />
        <Route path={AppPath.Cart.StarterKits} component={CartSelectStarterKitPage} exact />
        <Route path={AppPath.Cart.Cycle} component={CartSelectCyclePage} exact />
        <Route path={AppPath.Cart.Management} component={CartManagementPage} exact />
        <Route path={AppPath.Cart.EditOrder} component={CartEditOrderPage} exact />
      </Switch>
    </LoadingState>
  );
};

export default CartPagesRouter;

const CartPageShimmer = () => {
  return (
    <>
      <Frame>
        <Hbox>
          <Hbox.Item>
            <ShimmerH heading='H3' />

            <ShimmerText />

            <ShimmerText />

            <VSeparator />
            <ShimmerButton />
          </Hbox.Item>

          <Hbox.Separator />
          <Hbox.Item noGrow>
            <DividerGrayVertical />
          </Hbox.Item>

          <Hbox.Item hAlign='center'>
            <ShimmerBox width='80%' />
          </Hbox.Item>
        </Hbox>
      </Frame>
      <Grid>
        <Row center='xs' mt>
          <Col xs={8}>
            <Frame>
              <ShimmerBox width='300px' />
            </Frame>
          </Col>
        </Row>
      </Grid>
    </>
  );
};
