import { useQueryHook } from '@app/core/graphql';
import { ResellerInfo, ResellerInfoVariables } from '@app/data/graphql';
import { ApolloError } from '@apollo/client';

export const useResellerInfoClientQuery = (
  { resellerId }: ResellerInfoVariables,
  onError?: (error: ApolloError) => void,
) => {
  const result = useQueryHook<ResellerInfo, ResellerInfoVariables>('reseller-info.query', {
    fetchPolicy: 'cache-and-network',
    onError,
    variables: { resellerId },
  });

  return {
    data: result.data?.ResellerInfo,
    error: result.error,
    loading: result.loading,
    refetch: () => result.refetch(),
  };
};
