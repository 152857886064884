import { commonStrings } from '@app/components/obj.localization/common-strings';
import { Project } from '@app/config-variables';

export const eudoraStrings = {
  ...commonStrings(Project.eudora),
  components: {
    ...commonStrings(Project.eudora).components,
    boletoCell: {
      boletoNumber: (number: string) => `Título n˚ ${number}`,
      createdAt: (issueDate: string) => `Criado em ${issueDate}`,
      expiresIn: (expireDate: string) => `Vence em ${expireDate}`,
      order: 'Pedido',
      paymentMode: (paymentMode: string) => `Modo de pagamento: ${paymentMode}`,
      seeDetails: 'Ver detalhes',
      shareBoleto: 'Compartilhar título',
      titles: 'Títulos',
    },
    boletoDetail: {
      noResultFound: 'Nenhum resultado encontrado',
      title: 'Detalhe do título',
    },
    orderInfo: {
      ...commonStrings(Project.eudora).components.orderInfo,
      inCaseOfDivergence:
        'Caso exista alguma divergência, entre em contato com sua consultora ou procure o seu espaço da representante para maiores detalhes.',
    },
    orderTotal: {
      ...commonStrings(Project.eudora).components.orderTotal,
      priceWithoutDiscount: 'Valor total a pagar',
    },
  },
  header: {
    ...commonStrings(Project.eudora).header,
    backgroundImage: 'assets/img/eudora-img-background.png',
  },
};
