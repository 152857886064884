import { LocalizedContext } from '@app/components/obj.localization';
import { Button } from '@atomic/atm.button';
import { Body, Centered } from '@atomic/atm.typography';
import * as React from 'react';

interface ErrorMessageProps {
  text?: string;
  refetch?: () => void;
}

export const ErrorMessage: React.FC<ErrorMessageProps> = (props) => {
  const localized = React.useContext(LocalizedContext);
  return (
    <Centered>
      <Body>
        {props.text ?? localized.strings.error.base}
        {props.refetch && (
          <Button kind='link' onClick={props.refetch}>
            {localized.strings.error.tryAgain}
          </Button>
        )}
      </Body>
    </Centered>
  );
};
