import { FastShopContentWrapper } from '@app/components/mol.fast-shop';
import { parseObjectToSearch } from '@app/components/obj.formatter/query-param-formatter';
import { PageWrapper, SideMenuWrapper } from '@app/components/org-side-menu/side-menu.style';
import { Project } from '@app/config-variables';
import { Cart } from '@app/data/graphql/graphql-schema';
import { useAddToCartMutation, useCartStateQuery } from '@app/domain/orders.use-case';
import { AppPath } from '@app/modules/app/routes';
import { AddProductsSpreadsheet } from '@app/modules/cart/mol-products-spreadsheet/add-product-spreadsheet.component';
import { OrderContext } from '@app/providers';
import { Frame } from '@atomic/atm.frame';
import { Tabs } from '@atomic/mol.tabs';
import { CommonTheme } from '@atomic/obj.constants';
import { Grid, VSeparator } from '@atomic/obj.grid';
import { createBrowserHistory } from 'history';
import * as React from 'react';
import { ThemeContext } from 'styled-components';
import { ProductNameSearch } from './mol-product-name-search/product-name-search.container';
import { EudoraProductNameSearch } from './mol-product-name-search/product-name-search.container.eudora';
import { CartManagementPageSection } from './mol.cart-management/cart-management-page-section.component';

const STARTS_PATH = 'starts';

enum SideTabs {
  byName = 'name',
  bySpreadsheet = 'spreadsheet',
}

const SIDE_TABS = [
  { name: 'nome', value: SideTabs.byName },
  { name: 'planilha', value: SideTabs.bySpreadsheet },
];

export const CartManagementPage: React.FC = () => {
  const theme = React.useContext<CommonTheme>(ThemeContext);
  const order = React.useContext(OrderContext);
  const history = createBrowserHistory({
    forceRefresh: true,
  });

  const { loading: cartDetailLoading, refetch: updateCartState } = useCartStateQuery({
    onCompleted: (newCart: Cart) => order.updateCart(newCart),
    onError: order.handleOrderError,
    orderCode: order?.currentOrder?.id,
  });

  const { addToCart, loading: addToCartLoading } = useAddToCartMutation({
    onCompleted: order.updateCart,
    onError: order.handleOrderError,
    orderCode: order?.cart?.orderCode,
  });

  const handleSearchAdd = (productCode: string) => {
    addToCart({ productCode: productCode, quantity: 1 });
  };

  const goToCheckout = () => {
    history.push({
      pathname: location.pathname.includes(STARTS_PATH)
        ? AppPath.Starts.Checkout.Base
        : AppPath.Activation.Checkout.Base,
      search: parseObjectToSearch({
        resellerId: order.resellerId,
      }),
    });
  };

  const [tab, setTab] = React.useState<SideTabs>(SideTabs.byName);
  const orderInfo = React.useContext(OrderContext)?.currentOrder;

  return (
    <PageWrapper>
      <Grid>
        <VSeparator />
        <Frame>
          <CartManagementPageSection
            selectedCycle={order.selectedCycle}
            deliveryMode={order.selectedDeliveryMode}
            onContinueClick={goToCheckout}
            recoverLastCancelledOrder={order.recoverLastCancelledOrder}
            onRecoverSuccess={() => order.updateRecoverLastCancelledOrder(false)}
            loading={cartDetailLoading}
            onError={order.handleOrderError}
          />
        </Frame>
      </Grid>

      <SideMenuWrapper>
        <FastShopContentWrapper>
          <Tabs
            items={SIDE_TABS.map((sideTab) => sideTab.name)}
            onTabIndexChanged={(index) => setTab(SIDE_TABS[index].value)}
          />
          <VSeparator />

          {tab === SideTabs.byName ? (
            theme.Project === Project.eudora ? (
              <EudoraProductNameSearch
                loading={addToCartLoading || cartDetailLoading}
                onProductClick={handleSearchAdd}
              />
            ) : (
              <ProductNameSearch loading={addToCartLoading || cartDetailLoading} onProductClick={handleSearchAdd} />
            )
          ) : (
            <AddProductsSpreadsheet
              loading={cartDetailLoading}
              orderCode={orderInfo?.id}
              onComplete={updateCartState}
            />
          )}
        </FastShopContentWrapper>
      </SideMenuWrapper>
    </PageWrapper>
  );
};
