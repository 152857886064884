import { LazyLoadImage } from '@atomic/atm.lazy-load-image';
import { Body, H3 } from '@atomic/atm.typography';
import { ThemeToken } from '@atomic/obj.constants';
import * as React from 'react';
import Container from 'typedi';
import {
  ScreenPlaceholderActionStyled,
  ScreenPlaceholderImageWrapperStyled,
  ScreenPlaceholderStyled,
} from './screen-placeholder.component.style';

const theme = Container.get(ThemeToken);

export interface ScreenPlaceholderProps {
  src: string;
  title?: string;
  message?: string;
}

export const ScreenPlaceholder: React.FC<ScreenPlaceholderProps> = (props) => {
  return (
    <ScreenPlaceholderStyled>
      <ScreenPlaceholderImageWrapperStyled>
        <LazyLoadImage src={props.src} aspectRatio={theme.AspectRatio.Square} />
      </ScreenPlaceholderImageWrapperStyled>
      {props.title && <H3>{props.title}</H3>}
      {props.message && <Body>{props.message}</Body>}
      <ScreenPlaceholderActionStyled>{props.children}</ScreenPlaceholderActionStyled>
    </ScreenPlaceholderStyled>
  );
};
