import { FlashDispatcherService } from '@app/components/obj.flash-wrapper/flash-dispatcher.service';
import { Router } from '@app/core/route';
import { AuthenticationRepository } from '@app/data/http/modules/authentication/authentication.repository';
import { AppPath } from '@app/modules/app/routes/routes-list';
import { LoadingState } from '@atomic/obj.loading-state';
import * as React from 'react';
import { Container } from 'typedi';

interface LogoutContainerProps {
  children?: (logout: () => void) => JSX.Element;
  router: Router;
}

export const LogoutContainer: React.FC<LogoutContainerProps> = (props) => {
  const [loading, setLoading] = React.useState(false);
  const authenticationRepository = Container.get(AuthenticationRepository);
  const flashDispatcherService = Container.get(FlashDispatcherService);

  const logout = async () => {
    try {
      setLoading(true);
      await authenticationRepository.logout();
      props.router.push(AppPath.Login);
    } catch (error) {
      if (error.response && error.response.data) {
        flashDispatcherService.dispatchMessage(error.response.data.moreInformation, 'alert');
      } else {
        console.error(error);
      }
      setLoading(false);
    }
  };

  return <LoadingState loading={loading}>{props.children(logout)}</LoadingState>;
};
