import { ThemeToken } from '@atomic/obj.constants';
import styled from 'styled-components';
import Container from 'typedi';

const theme = Container.get(ThemeToken);

const DIVISOR_KINDS = {
  medium: {
    backgroundColor: theme.Color.GrayXLight,
    height: theme.Spacing.Medium,
  },
  xxsmall: {
    backgroundColor: theme.Border.Color,
    height: theme.Border.Width,
  },
};

interface DivisorProps {
  margin?: boolean;
  kind?: keyof typeof DIVISOR_KINDS;
}

export const Divisor = styled.div`
  height: ${({ kind }: DivisorProps) => DIVISOR_KINDS[kind].height};
  width: 100%;
  background-color: ${({ kind }: DivisorProps) => DIVISOR_KINDS[kind].backgroundColor};
  margin: ${(props: DivisorProps) => (props.margin ? `${theme.Spacing.Medium} 0` : 0)};
`;

Divisor.defaultProps = {
  kind: 'xxsmall',
};
