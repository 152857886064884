import {
  isCnpjPatternValid,
  isCpfPatternValid,
  isDateValid,
  isEmailValid,
  isLuhnValid,
  isPasswordValid,
  isPhoneValid,
  isSocialSecurityNumberValid,
  isZipCodeValid,
  hasOnlyValidWords,
  isExpirationDateValid,
} from './regex-validator';

export interface Validator {
  error: ValidationError;
  validationFn: any;
}

export interface ValidationError {
  name: string;
  message: string;
}

export class Validators {
  static MaxLength(length: number, message: string): Validator {
    return {
      error: {
        message,
        name: 'MaxLength',
      },
      validationFn: (value: any) => {
        return !value || value.toLocaleString().length <= length;
      },
    };
  }

  static LettersOnly(acceptSpace: boolean, message: string): Validator {
    return {
      error: {
        message,
        name: 'LettersOnly',
      },
      validationFn: (value: any) => {
        return (
          value &&
          value.match(acceptSpace ? /^[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF\s]*$/ : /^[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF]*$/)
        );
      },
    };
  }

  static MinLength(length: number, message: string): Validator {
    return {
      error: {
        message,
        name: 'MinLength',
      },
      validationFn: (value: any) => {
        return !value || value.toLocaleString().length >= length;
      },
    };
  }

  static Required(message?: string): Validator {
    return {
      error: {
        message: message || 'Required',
        name: 'Required',
      },
      validationFn: (value: any) => {
        if (value === '' || (value && value.length === 0)) {
          return false;
        }
        return (value && Object.keys(value).length > 0) || (value && !isNaN(value)) || value instanceof Date;
      },
    };
  }

  static CreditCard(message: string): Validator {
    return {
      error: {
        message,
        name: 'CreditCard',
      },
      validationFn: (value: string) => {
        return isLuhnValid(value);
      },
    };
  }

  static EmailRegex(message: string): Validator {
    return {
      error: {
        message,
        name: 'EmailRegex',
      },
      validationFn: (value: any) => {
        return isEmailValid(value);
      },
    };
  }

  static CpfPatternRegex(message: string): Validator {
    return {
      error: {
        message,
        name: 'Cpf pattern regex',
      },
      validationFn: (value: any) => {
        return isCpfPatternValid(value);
      },
    };
  }

  static CnpjPatternRegex(message: string): Validator {
    return {
      error: {
        message,
        name: 'Cnpj pattern regex',
      },
      validationFn: (value: any) => {
        return isCnpjPatternValid(value);
      },
    };
  }

  static ZipCodeRegex(message: string): Validator {
    return {
      error: {
        message,
        name: 'Zip code regex',
      },
      validationFn: (value: any) => {
        return isZipCodeValid(value);
      },
    };
  }
  static PhoneRegex(message: string): Validator {
    return {
      error: {
        message,
        name: 'PhoneRegex',
      },
      validationFn: (value: any) => {
        return isPhoneValid(value);
      },
    };
  }

  static PasswordRegex(message: string): Validator {
    return {
      error: {
        message,
        name: 'PasswordRegex',
      },
      validationFn: (value: any) => {
        return isPasswordValid(value);
      },
    };
  }

  static DateRegex(message: string): Validator {
    return {
      error: {
        message,
        name: 'DateRegex',
      },
      validationFn: (value: any) => {
        return isDateValid(value);
      },
    };
  }

  static CreditCardExpiration(message: string): Validator {
    return {
      error: {
        message,
        name: 'CreditCardExpiration',
      },
      validationFn: (value: any) => {
        return isExpirationDateValid(value);
      },
    };
  }

  static IsNotEqualToField(valueToCompare: any, message: string): Validator {
    return {
      error: {
        message,
        name: 'IsNotEqualToField',
      },
      validationFn: (value: any) => {
        return value === valueToCompare;
      },
    };
  }

  // Validates Brazilian social security number
  static SocialSecurityNumberRegex(message?: string): Validator {
    return {
      error: {
        message,
        name: 'SocialSecurityNumberRegex',
      },
      validationFn: (value: any) => {
        return isSocialSecurityNumberValid(value);
      },
    };
  }

  static NumbersOnly(message: string): Validator {
    return {
      error: {
        message,
        name: 'NumbersOnly',
      },
      validationFn: (value: any) => {
        return value && value.match(/^\d+(\.\d+)?$/);
      },
    };
  }

  static NotAllowedWords(message?: string): Validator {
    return {
      error: {
        message,
        name: 'NotAllowedWords',
      },
      validationFn: (value: any) => {
        return hasOnlyValidWords(value);
      },
    };
  }

  static InputRange(minValue: number, maxValue: number, message?: string): Validator {
    return {
      error: {
        message,
        name: 'NotAllowedInputRange',
      },
      validationFn: (value: number | string) => {
        return Number(value) >= minValue && Number(value) <= maxValue;
      },
    };
  }
}
