import * as React from 'react';

export const IcMagnifier = (props) => (
  <svg width='64' height='64' viewBox='0 0 64 64' fill='none' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M44.6959 47.0797L60.1054 62.4892C60.4459 62.8297 60.8716 63 61.2973 63C61.723 63 62.1486 62.8297 62.4892 62.4892C63.1703 61.8081 63.1703 60.7865 62.4892 60.1054L47.1649 44.6959C51.3365 39.9284 53.8905 33.7135 53.8905 26.9027C53.8905 12.0892 41.7162 0 26.9027 0C12.0892 0 0 12.0041 0 26.9027C0 41.8013 12.0892 53.8905 26.9027 53.8054C33.7135 53.8054 39.9284 51.2514 44.6959 47.0797ZM3.4054 26.9027C3.4054 13.9622 13.9622 3.40541 26.9027 3.40541C39.9284 3.40541 50.4851 13.9622 50.4851 26.9027C50.4851 39.9284 39.8432 50.4 26.9027 50.4C13.9622 50.4 3.4054 39.8432 3.4054 26.9027Z'
      fill='#BDBDBD'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M44.73 47.1135L47.0634 44.7801L62.6905 60.4071C63.2763 60.9929 63.2763 61.9427 62.6905 62.5285L62.4784 62.7406C61.8926 63.3264 60.9428 63.3264 60.357 62.7406L44.73 47.1135Z'
      fill='#525252'
    />
  </svg>
);
