import { Service } from 'typedi';

@Service()
class UrlGuard {
  private blockUrl: string;
  private redirectTo: string;

  constructor(blockUrl: string, redirectTo: string) {
    this.blockUrl = blockUrl;
    this.redirectTo = redirectTo;
  }

  verifyUrl() {
    if (this.blockUrl && this.redirectTo) {
      if (window.location.hostname === this.blockUrl) {
        this.redirect();
      }
    }
  }

  private redirect() {
    window.location.href = this.redirectTo;
  }
}

export { UrlGuard };
