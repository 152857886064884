import { MutationResult, MutationTuple, NetworkStatus } from '@apollo/client';
import { FlashDispatcherService } from '@app/components/obj.flash-wrapper/flash-dispatcher.service';
import { LocalizedContext } from '@app/components/obj.localization';
import { RESELLER_FEEDBACK_LIST_LIMIT, RESELLER_FEEDBACK_LIST_OFFSET } from '@app/constants';
import { GraphQLDocsService, useMutationHook, useQueryHook } from '@app/core/graphql';
import {
  ConversationCreationMethod,
  FvcEndConversation,
  FvcEndConversationVariables,
  FvcResellerConversation,
  ResellersList,
  ResellersListVariables,
  StartConversation,
  StartConversationVariables,
  StartSpecificConversation,
  StartSpecificConversationVariables,
} from '@app/data/graphql';
import { useConversationsQuery } from '@app/domain/queue.use-case';
import { HomeQueryArguments } from '@app/interfaces';
import { QueueContext } from '@app/providers/queue.provider';
import * as React from 'react';
import { useContext } from 'react';
import Container from 'typedi';
import { mapResellerList } from './home.mapper';

export const useResellerListQuery = ({ commercialStructure }: HomeQueryArguments) => {
  const skipQuery = !commercialStructure;
  const result = useQueryHook<ResellersList, ResellersListVariables>('resellers-list.query', {
    notifyOnNetworkStatusChange: true,
    skip: skipQuery,
    variables: { commercialStructure },
  });
  return {
    data: mapResellerList(result.data),
    error: result.error,
    loading: result.loading || result.networkStatus === NetworkStatus.refetch,
    networkStatus: result.networkStatus,
    refetch: () => result.refetch(),
  };
};

export const useStartConversationMutation = ({
  commercialStructure,
  onCompleted,
}: HomeQueryArguments): MutationTuple<StartConversation, StartConversationVariables> => {
  const refetch = [{ query: 'conversation-list.query', variables: { commercialStructure } }];
  const result = useMutationHook<StartConversation, StartConversationVariables>({
    mutationName: 'start-conversation.mutation',
    options: {
      onCompleted: (data) => onCompleted(data.FvcStartConversation[data.FvcStartConversation.length - 1].resellerId),
    },
    refetchQueries: refetch,
  });

  return result;
};

export const useStartSpecificConversationMutation = ({
  commercialStructure,
  queueLimit,
  onCompleted,
}: HomeQueryArguments): {
  startSpecificConversation: (id: string, name: string, creationMethod: ConversationCreationMethod) => void;
  startSpecificConversationResult: MutationResult<StartSpecificConversation>;
} => {
  const graphqlDocsService = Container.get(GraphQLDocsService);
  const conversationListQueryDocument = graphqlDocsService.getQuery('conversation-list.query');
  const conversationListQuery = useConversationsQuery(commercialStructure);
  const flashMessageService = Container.get(FlashDispatcherService);
  const limitErrorMessage = useContext(LocalizedContext).strings.components.queue.limitReached;
  const queueContext = React.useContext(QueueContext);

  const [mutate, startSpecificConversationResult] = useMutationHook<
    StartSpecificConversation,
    StartSpecificConversationVariables
  >({
    mutationName: 'start-specific-conversation.mutation',
    options: {
      onError: queueContext?.handleEndConversation,
    },
  });

  const startSpecificConversation = (id: string, name: string, creationMethod: ConversationCreationMethod) => {
    const queueItem = conversationListQuery.data.find((item) => item.id === id);
    const randomValue = crypto.getRandomValues(new Uint8Array(1))[0] / Math.pow(2, 8);

    if (!queueItem) {
      if (conversationListQuery.data.length < queueLimit) {
        mutate({
          optimisticResponse: {
            FvcStartSpecificConversation: [
              {
                __typename: 'FvcResellerConversationResponse',
                completedAt: null,
                createdAt: new Date().toISOString(),
                id: Math.round(randomValue * -1000000).toString(),
                isScheduling: null,
                resellerId: id,
                resellerName: name,
              },
            ],
          },
          update: (cache, { data }) => {
            const conversationListQueryCached = cache.readQuery<FvcResellerConversation>({
              query: conversationListQueryDocument,
              variables: { commercialStructure },
            });

            cache.writeQuery({
              data: Object.assign({}, conversationListQueryCached, {
                FvcResellerConversation: [
                  ...conversationListQueryCached.FvcResellerConversation,
                  ...data.FvcStartSpecificConversation,
                ],
              }),
              query: conversationListQueryDocument,
              variables: { commercialStructure },
            });
            onCompleted(data.FvcStartSpecificConversation[data.FvcStartSpecificConversation.length - 1].resellerId);
          },
          variables: {
            creationMethod,
            resellerCode: id,
          },
        });
      } else {
        flashMessageService.dispatchMessage({ message: limitErrorMessage }, 'alert');
        mutate({
          variables: {
            creationMethod,
            resellerCode: id,
          },
        });
      }
    } else {
      onCompleted(id);
    }
  };

  return { startSpecificConversation, startSpecificConversationResult };
};

export const useFvcEndConversationMutation = ({
  commercialStructure,
}: HomeQueryArguments): MutationTuple<FvcEndConversation, FvcEndConversationVariables> => {
  const id = React.useContext(QueueContext).activeIndex;
  const refetch = [
    { query: 'conversation-list.query', variables: { commercialStructure } },
    {
      query: 'reseller-feedback-list.query',
      variables: { id, limit: RESELLER_FEEDBACK_LIST_LIMIT, offset: RESELLER_FEEDBACK_LIST_OFFSET },
    },
  ];
  const result = useMutationHook<FvcEndConversation, FvcEndConversationVariables>({
    mutationName: 'fvc-end-conversation.mutation',
    refetchQueries: refetch,
  });
  return result;
};

export const fuseOptions = {
  geraIdOptions: {
    keys: ['id'],
    shouldSort: true,
  },
  nameOptions: {
    keys: ['name'],
    shouldSort: true,
  },
};
