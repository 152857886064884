import { FeedbackModalAppointmentForm } from '@app/components/obj.feedback-modal-appointment-form';
import { FlashDispatcherService } from '@app/components/obj.flash-wrapper/flash-dispatcher.service';
import { extractReadableDate } from '@app/components/obj.formatter/date-formatter';
import { LocalizedContext } from '@app/components/obj.localization';
import { NextSchedulesModal } from '@app/components/org.schedule-notifications/next-schedules-modal.component';
import { ScheduleNotifications } from '@app/components/org.schedule-notifications/schedule-notifications.component';
import { ApolloImperative } from '@app/core/graphql';
import { mapFutureSchedulesList, mapSchedulingList, useSchedulingListQuery } from '@app/domain/scheduling.use-case';
import { ScheduleAction } from '@app/models/schedule.model';
import { LARGE_BUTTON_HEIGHT } from '@app/modules/activation/home/components/atm.large-button';
import { EventAction, EventCategory, EventLabel, sanitizeMessage } from '@app/utils/data-layer';
import { Button } from '@atomic/atm.button';
import { Body, Centered } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { VSeparator } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';
import * as React from 'react';
import Container from 'typedi';

const flashDispatcherService = Container.get(FlashDispatcherService);
const client = Container.get(ApolloImperative);

const MAX_DAILY_SCHEDULES = 50;

export const ScheduleNotificationsSection: React.FC = () => {
  const scheduling = useSchedulingListQuery({ limit: MAX_DAILY_SCHEDULES });
  const localized = React.useContext(LocalizedContext).strings.components.scheduleNotifications;

  const [isRescheduleModalOpen, toggleRescheduleModal] = React.useState<boolean>(false);
  const [isCancelModalOpen, toggleCancelModal] = React.useState<boolean>(false);
  const [isNextSchedulesModalOpen, toggleNextSchedulesModal] = React.useState<boolean>(false);
  const [scheduleId, setScheduleId] = React.useState(0);
  const [scheduleAction, setScheduleAction] = React.useState<ScheduleAction>(null);

  const schedulingList = mapSchedulingList(scheduling.data);
  const futureSchedulesList = mapFutureSchedulesList(scheduling.data);

  const mapScheduleActionToToggleModal = (action: string) =>
    ({
      cancelSchedule: () => {
        toggleCancelModal(!isCancelModalOpen);
        toggleNextSchedulesModal(false);
      },
      reschedule: () => {
        toggleRescheduleModal(!isRescheduleModalOpen);
        toggleNextSchedulesModal(false);
      },
      showNextSchedules: () => {
        toggleNextSchedulesModal(!isNextSchedulesModalOpen);
      },
    }[action]);

  const handleClick = (action: ScheduleAction, id: number) => {
    setScheduleId(id);
    setScheduleAction(action);
    mapScheduleActionToToggleModal(ScheduleAction[action])();
  };

  const handleCloseModal = () => {
    setScheduleId(0);
    mapScheduleActionToToggleModal(ScheduleAction[scheduleAction])();
    setScheduleAction(null);
  };

  const handleRescheduleModalSubmit = async (date: Date) => {
    toggleRescheduleModal(false);
    const rescheduledDate = extractReadableDate(date);
    try {
      await client.mutate('reschedule.mutation', {
        id: scheduleId,
        scheduledTo: new Date(date),
      });

      flashDispatcherService.dispatchMessage(
        {
          message: localized.rescheduleSuccessFn(rescheduledDate),
        },
        'success',
        LARGE_BUTTON_HEIGHT,
      );
    } catch (error) {
      flashDispatcherService.dispatchMessage(
        {
          message: error.message,
        },
        'alert',
        LARGE_BUTTON_HEIGHT,
      );
    }
    scheduling.refetch();
  };

  const handleCancelScheduleModalSubmit = async () => {
    toggleCancelModal(false);
    try {
      await client.mutate('cancel-schedule.mutation', {
        id: scheduleId,
      });
    } catch (error) {
      flashDispatcherService.dispatchMessage(
        {
          message: error.message,
        },
        'alert',
        LARGE_BUTTON_HEIGHT,
      );
    }
    flashDispatcherService.dispatchMessage(
      {
        message: localized.successfullyCanceled,
      },
      'success',
      LARGE_BUTTON_HEIGHT,
    );
    scheduling.refetch();
  };

  return (
    <>
      <ScheduleNotifications
        loading={scheduling.loading}
        error={!!scheduling.error}
        refetch={scheduling.refetch}
        scheduleList={schedulingList}
        onClick={handleClick}
        dataGtmEventCategory={EventCategory.Attendence}
        dataGtmEventAction={EventAction.Click.Schedule}
        dataGtmEventLabel={EventLabel.Attendence.Notification}
      />
      <Modal opened={isRescheduleModalOpen} isScheduling onClose={handleCloseModal} small>
        <FeedbackModalAppointmentForm onSubmit={handleRescheduleModalSubmit} onBack={handleCloseModal} />
      </Modal>
      <Modal opened={isCancelModalOpen} onClose={handleCloseModal} small>
        <VSeparator />
        <VSeparator />
        <Centered>
          <Body>{localized.confirmDelete}</Body>
        </Centered>
        <VSeparator />
        <Hbox>
          <Hbox.Separator />
          <Hbox.Item>
            <Button
              kind='neutral'
              expanded
              onClick={handleCloseModal}
              outlined
              dataGtmEventAction={EventAction.Click.Button}
              dataGtmEventCategory={EventCategory.Attendence}
              dataGtmEventLabel={sanitizeMessage(localized.goBack)}
            >
              {localized.goBack}
            </Button>
          </Hbox.Item>
          <Hbox.Separator />
          <Hbox.Item>
            <Button
              kind='alert'
              expanded
              onClick={handleCancelScheduleModalSubmit}
              dataGtmEventAction={EventAction.Click.Button}
              dataGtmEventCategory={EventCategory.Attendence}
              dataGtmEventLabel={sanitizeMessage(localized.deleteSchedule)}
            >
              {localized.deleteSchedule}
            </Button>
          </Hbox.Item>
          <Hbox.Separator />
        </Hbox>
        <VSeparator />
      </Modal>
      <NextSchedulesModal
        opened={isNextSchedulesModalOpen}
        onClose={handleCloseModal}
        refetch={scheduling.refetch}
        scheduleList={futureSchedulesList}
        loading={scheduling.loading}
        error={!!scheduling.error}
        onClick={handleClick}
      />
    </>
  );
};
