import * as React from 'react';
import Observer from 'react-intersection-observer';

import {
  LazyLoadErrorImageStyled,
  LazyLoadImageStyled,
  LazyLoadPlaceholderImageStyled,
  LazyLoadPlaceholderStyled,
} from './lazy-load-image.component.style';

// https://developer.mozilla.org/en-US/docs/Learn/HTML/Multimedia_and_embedding/Responsive_images#Resolution_switching_Different_sizes
export interface LazyLoadResponsiveImageProps {
  srcset: string;
  sizes: string;
}

interface LazyLoadImageProps {
  src: string;
  aspectRatio?: number;
  onClick?: () => void;
  round?: boolean;
  responsive?: LazyLoadResponsiveImageProps;
}

enum Status {
  Loading,
  Error,
  Loaded,
}

interface LazyLoadImageState {
  status: Status;
  height: number;
}

export class LazyLoadImage extends React.Component<LazyLoadImageProps, LazyLoadImageState> {
  private imageWrapper;

  constructor(props: LazyLoadImageProps) {
    super(props);
    this.state = {
      height: 0,
      status: Status.Loading,
    };
  }

  handleError = () => {
    this.setState({ status: Status.Error });
  };

  handleLoad = () => {
    if (this.props.aspectRatio && this.imageWrapper) {
      const height = this.props.aspectRatio * this.imageWrapper.clientWidth;
      this.setState({ height, status: Status.Loaded });
    } else {
      this.setState({ status: Status.Loaded });
    }
  };

  referImageWrapper = (imageWrapper: any) => {
    this.imageWrapper = imageWrapper;
  };

  render() {
    return (
      <Observer triggerOnce>
        {({ inView, ref }) => {
          return (
            <div ref={ref as any}>
              {this.state.status === Status.Loading && (
                <LazyLoadPlaceholderStyled aspectRatio={this.props.aspectRatio}>
                  <LazyLoadPlaceholderImageStyled />
                </LazyLoadPlaceholderStyled>
              )}
              {this.state.status === Status.Error && (
                <LazyLoadPlaceholderStyled aspectRatio={this.props.aspectRatio}>
                  <LazyLoadErrorImageStyled />
                </LazyLoadPlaceholderStyled>
              )}
              {((inView && this.state.status === Status.Loading) || this.state.status === Status.Loaded) && (
                <div ref={this.referImageWrapper}>
                  <LazyLoadImageStyled
                    onClick={this.props.onClick}
                    src={this.props.src}
                    onLoad={this.handleLoad}
                    onError={this.handleError}
                    loaded={this.state.status === Status.Loaded}
                    round={this.props.round}
                    height={this.state.height}
                    srcSet={this.props.responsive && this.props.responsive.srcset}
                    sizes={this.props.responsive && this.props.responsive.sizes}
                  />
                </div>
              )}
            </div>
          );
        }}
      </Observer>
    );
  }
}
