import * as defaults from 'lodash.defaults';
import * as merge from 'lodash.merge';

import { HttpClient } from './http-client';
import { HttpHeaders, HttpRequest } from './http-request';
import { HttpInterceptor } from './http.interceptor';

export interface HttpRequestBuilderConfig {
  baseUrl: string;
  client?: HttpClient;
  headers?: HttpHeaders;
  interceptors?: HttpInterceptor[];
}

const EMPTY_CONFIG: HttpRequestBuilderConfig = {
  baseUrl: '',
  headers: {},
  interceptors: [],
};

export interface HttpRequestBuilder {
  get(url: string): HttpRequestBuilder;
  post(url: string): HttpRequestBuilder;
  put(url: string): HttpRequestBuilder;
  delete(url: string): HttpRequestBuilder;
  head(url: string): HttpRequestBuilder;
  patch(url: string): HttpRequestBuilder;
}

export class HttpRequestBuilder {
  protected request: HttpRequest;
  protected config: HttpRequestBuilderConfig;

  constructor() {
    this.config = { ...EMPTY_CONFIG };
  }

  /**
   * Creates a shallow copy of this instance
   */
  clone(): HttpRequestBuilder {
    const copy = new HttpRequestBuilder();

    copy.config = { ...this.config };
    copy.config.interceptors = [].concat(this.config.interceptors);

    if (this.request) {
      copy.request = { ...this.request };
      copy.request.interceptors = [].concat(this.request.interceptors);
    }

    return copy;
  }

  /**
   * Set builder's configuration (default values used in a request). Unspecified config values will be
   * filled with default values.
   *
   * A builder's configuration are values used in every request created by it.
   *
   * @param config configuration object
   */
  configure(config: HttpRequestBuilderConfig): HttpRequestBuilder {
    this.config = defaults(config, EMPTY_CONFIG);
    return this;
  }

  /**
   * Set default http client.
   *
   * @param client Http client
   */
  configClient(client: HttpClient): HttpRequestBuilder {
    this.config.client = client;
    return this;
  }

  /**
   * Set default headers value
   *
   * @param headers headers to be sent with request
   */
  configHeaders(headers: HttpHeaders): HttpRequestBuilder {
    this.config.headers = headers;
    return this;
  }

  /**
   * Set configuration's base URL
   *
   * @param baseUrl base URL used by a request
   */
  configBaseUrl(baseUrl: string): HttpRequestBuilder {
    this.config.baseUrl = baseUrl;
    return this;
  }

  /**
   * Set configuration's interceptors
   *
   * @param interceptors interceptors used during a request execution
   */
  configInterceptors(...interceptors: HttpInterceptor[]) {
    this.config.interceptors = [].concat(interceptors);
    return this;
  }

  /**
   * Merges headers to existing request / configuration headers
   *
   * @param headers headers object
   */
  headers(headers: any): HttpRequestBuilder {
    this.request.headers = merge({}, this.request.headers, headers);
    return this;
  }

  /**
   * Concatenates interceptors to existing request / configuration interceptors
   *
   * @param interceptors interceptors list
   */
  interceptors(...interceptors: HttpInterceptor[]) {
    this.request.interceptors = [].concat(this.request.interceptors, interceptors);
    return this;
  }

  /**
   * Set request's body object
   *
   * @param data
   */
  data(data: any): HttpRequestBuilder {
    this.request.data = data;
    return this;
  }

  /**
   * Set request's params
   *
   * @param params
   */
  params(params: any): HttpRequestBuilder {
    this.request.params = params;
    return this;
  }

  /**
   * Set request's timeout (in miliseconds)
   *
   * @param timeout
   */
  timeout(timeout: number): HttpRequestBuilder {
    this.request.timeout = timeout;
    return this;
  }

  /**
   * Executes a request using configured http client.
   */
  execute<T>(): Promise<T> {
    if (this.config.client) {
      return this.config.client.execute(this.build());
    } else {
      throw new Error('[Core][HttpRequestBuilder] http client not configured');
    }
  }

  /**
   * Returns built request object
   */
  build(): HttpRequest {
    this.request.baseURL = this.config.baseUrl;
    this.request.headers = merge({}, this.config.headers, this.request.headers);
    this.request.interceptors = []
      .concat(this.config.interceptors, this.request.interceptors)
      .filter((interceptor) => interceptor);

    return this.request;
  }
}

['get', 'delete', 'head', 'post', 'put', 'patch'].forEach((method) => {
  HttpRequestBuilder.prototype[method] = function (path): HttpRequestBuilder {
    const copy = this.clone();
    copy.request = { method, path };
    return copy;
  };
});
