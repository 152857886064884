import { getStorage, StorageType } from '@app/core/storage/storage.provider';
import { Service } from 'typedi';

import { VISUALIZED_NEWS } from './storage-constants';

@Service()
export class NewsDatasource {
  private storage = getStorage(StorageType.Session);

  getVisualizedNews(): string[] {
    return this.storage.get(VISUALIZED_NEWS);
  }

  setVisualizedNews(newsId: string[]) {
    this.storage.put(VISUALIZED_NEWS, newsId);
  }

  clearStorage() {
    [VISUALIZED_NEWS].forEach((datasource) => this.storage.put(datasource, null));
  }
}
