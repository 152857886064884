/**
 * Returns a random integer between min (inclusive) and max (inclusive)
 * Using Math.round() will give you a non-uniform distribution!
 * src: https://stackoverflow.com/a/1527820/3670829
 */
export function getRandomInt(min, max) {
  const randomValue = crypto.getRandomValues(new Uint8Array(1))[0] / Math.pow(2, 8);
  return Math.floor(randomValue * (max - min + 1)) + min;
}

export const getRandomPercentage = (min = 0, max = 100) => {
  // Get random number between min and max
  const randomValue = crypto.getRandomValues(new Uint8Array(1))[0] / Math.pow(2, 8);
  const random = Math.floor(randomValue * (max - min)) + min;

  return random + '%';
};
