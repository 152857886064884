import Container, { Service } from 'typedi';
import { AuthenticationRepositoryProxy } from '@app/data/http/modules';
import { HttpRequest, HttpInterceptor } from '@app/core/http';

@Service()
export class MiddlewareTokenHttpInterceptor implements HttpInterceptor {
  private authDatasource = Container.get(AuthenticationRepositoryProxy);
  private isRefreshing = false;

  async before(request: HttpRequest): Promise<HttpRequest> {
    while (this.isRefreshing) {
      await new Promise((resolve) => setTimeout(resolve, 50));
    }
    this.isRefreshing = true;

    const middlewareToken = await this.getToken();
    request.headers.authorization = `bearer ${middlewareToken.substring(0, middlewareToken.length - 15)}`;

    this.isRefreshing = false;
    return request;
  }

  private async getToken(): Promise<string> {
    try {
      let busToken = await this.authDatasource.getValidBusToken();

      if (!busToken) {
        busToken = await this.authDatasource.refreshBusToken();
      }

      return busToken;
    } catch (err) {
      return '';
    }
  }
}
