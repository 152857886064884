import { InputPlaceholderCss } from '@atomic/atm.typography';
import { Theme } from '@atomic/obj.constants';
import { FieldBorderCss, FieldCss } from '@atomic/obj.form/field.component.style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import * as TextInputMask from 'react-masked-text';
import styled from 'styled-components';
import { TextFieldProps } from './text-field.component';

const ICON_HEIGHT = '18px';
const SMALL_INPUT_HEIGHT = '36px';

export const TextFieldStyled = styled.input`
  ${FieldBorderCss}
  ${FieldCss}

  ${(props: TextFieldProps & Theme) =>
    props.icon ? 'padding-left: ' + 'calc(' + props.theme.Spacing.Medium + ' * 3)' : null};
  ${(props: TextFieldProps) => props.textAlign && `text-align: ${props.textAlign};`}
  ${(props: TextFieldProps) => props.small && `height: ${SMALL_INPUT_HEIGHT}`};
  ${(props: TextFieldProps) =>
    props.rightButton && 'border-top-right-radius: 0; border-bottom-right-radius: 0; border-right-width: 0'};
  ::placeholder {
    ${InputPlaceholderCss}
    opacity: 0.5;
  }
`;

const DismissButtonSize = 16;

export const TextFieldDismissButtonStyled = styled.div`
  position: absolute;
  top: ${(props) => props.theme.Spacing.Medium};
  right: ${(props) => props.theme.Spacing.Small};
  width: ${DismissButtonSize}px;
  height: ${DismissButtonSize}px;
  border-radius: ${DismissButtonSize / 2}px;
  background-color: ${(props) => props.theme.Color.Gray};
  text-align: center;

  &::before,
  &::after {
    content: '';
    position: absolute;
    height: 2px;
    width: 10px;
    top: 50%;
    left: 3px;
    background: ${(props) => props.theme.Color.White};
  }
  &::before {
    transform: rotate(45deg);
    margin-top: -1px;
  }
  &::after {
    transform: rotate(-45deg);
    margin-top: -1px;
  }
`;

export const TextFieldWrapperStyled = styled.div`
  position: relative;
`;

const TextInputWrapper = ({ textAlign, ...props }) => <TextInputMask {...props} />;

export const TextFieldMaskedStyled: TextInputMask = TextFieldStyled.withComponent(TextInputWrapper);

export const TextFieldIconWrapperStyled = styled.div`
  position: relative;
`;

export const TextFieldIconStyled = styled(FontAwesomeIcon).attrs((props) => ({
  icon: props.icon ? props.icon : null,
}))`
  position: absolute;
  z-index: ${(props) => props.theme.Zindex.TextFieldIconStyled};
  top: ${(props) => 'calc((' + props.theme.FieldHeight + ' - ' + ICON_HEIGHT + ') / 2)'};
  left: ${(props) => props.theme.Spacing.Medium};
`;
