import { Project } from '@app/config-variables';
import { useQueryHook } from '@app/core/graphql';
import { ResellerCartInitialParams, ResellerCartInitialParamsVariables } from '@app/data/graphql';
import { ThemeToken } from '@atomic/obj.constants';
import Container from 'typedi';

const theme = Container.get(ThemeToken);

export interface StarterPacksProducts {
  id?: string;
  name?: string;
  description?: string;
}

export interface StarterPacks {
  id?: string;
  name?: string;
  description?: string;
  quantity?: number;
  products?: StarterPacksProducts[];
}

export interface InitialParamsResult {
  deliveryModes: {
    descriptiveAddress: string;
    distributionCenterCode: string;
    isWithdrawalCenter: boolean;
  }[];
  starterPacks: StarterPacks[];
}

export const useInitialParamsQuery = (resellerId: string, onCompleted?: (data: InitialParamsResult) => void) => {
  const result = useQueryHook<ResellerCartInitialParams, ResellerCartInitialParamsVariables>('initial-params', {
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => onCompleted?.(mapInitialParams(data)),
    skip: theme.Project === Project.eudora,
    variables: {
      resellerId,
    },
  });

  const data = mapInitialParams(result.data);

  return {
    data,
    error: result.error,
    loading: result.loading,
    refetch: () => result.refetch(),
  };
};

const mapInitialParams = (data: ResellerCartInitialParams): InitialParamsResult => {
  if (!data && !data?.ResellerCartInitialParams && !data?.ResellerCartInitialParams?.delivery) {
    return null;
  }

  return {
    deliveryModes: data.ResellerCartInitialParams.delivery.modes.map((mode) => ({
      descriptiveAddress: mode.address,
      distributionCenterCode: mode.modeId,
      isWithdrawalCenter: !!mode.withdraw,
    })),
    starterPacks: data?.ResellerCartInitialParams?.starterPacks,
  };
};
