import { LocalizedContext } from '@app/components/obj.localization';
import { EventAction, sanitizeMessage } from '@app/utils/data-layer';
import { Button } from '@atomic/atm.button';
import { CardContentWrapper } from '@atomic/atm.card';
import { Body, H1 } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { VSeparator } from '@atomic/obj.grid';
import { Modal, ModalProps } from '@atomic/obj.modal';
import * as React from 'react';

export interface ConfirmationModalProps extends ModalProps {
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  message: string;
  confirmationButtonText: string;
  loading?: boolean;
  kind?: 'primary' | 'alert';
  eventCategory?: string;
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  eventCategory,
  onClose,
  opened,
  onConfirm,
  message,
  confirmationButtonText,
  kind,
  loading,
  title,
}) => {
  const localized = React.useContext(LocalizedContext).strings.components.confirmationModal;
  return (
    <Modal opened={opened} onClose={onClose}>
      <CardContentWrapper>
        <H1>{title}</H1>
        <VSeparator />
        <Body>{message}</Body>
        <VSeparator />
        <Hbox>
          <Hbox.Item>
            <Button
              kind='neutral'
              outlined
              onClick={onClose}
              dataGtmEventCategory={eventCategory}
              dataGtmEventAction={EventAction.Click.Button}
              dataGtmEventLabel={sanitizeMessage(localized.cancel)}
            >
              {localized.cancel}
            </Button>
          </Hbox.Item>
          <Hbox.Separator />
          <Hbox.Item>
            <Button
              kind={kind || 'primary'}
              onClick={onConfirm}
              loading={loading}
              dataGtmEventCategory={eventCategory}
              dataGtmEventAction={EventAction.Click.Button}
              dataGtmEventLabel={sanitizeMessage(confirmationButtonText)}
            >
              {confirmationButtonText}
            </Button>
          </Hbox.Item>
        </Hbox>
      </CardContentWrapper>
    </Modal>
  );
};
