import * as React from 'react';
import Container from 'typedi';
import { Router } from '@app/core/route';
import { LARGE_BUTTON_HEIGHT } from '@app/modules/activation/home/components/atm.large-button';
import { ScreenPlaceholderOptions, ScreenPlaceholderKind } from '@atomic/mol.screen-placeholder';
import { Button } from '@atomic/atm.button/button.component';
import { FlashDispatcherService } from '@app/components/obj.flash-wrapper/flash-dispatcher.service';

const flashDispatcherService = Container.get(FlashDispatcherService);

interface ErrorBoundaryState {
  hasError: boolean;
}

export class ErrorBoundary extends React.Component<any, ErrorBoundaryState> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    if (window?.newrelic) {
      window?.newrelic?.noticeError(error, { errorInfo: errorInfo.componentStack });
    }
    flashDispatcherService.dispatchMessage(
      { additionalInfo: 'error-boundary.component.tsx:29', message: error.message },
      'warning',
      LARGE_BUTTON_HEIGHT,
    );
  }

  render() {
    if (this.state.hasError) {
      return (
        <ScreenPlaceholderOptions kind={ScreenPlaceholderKind.internalError}>
          <Button kind='primary' onClick={this.redirect}>
            Voltar à página inicial
          </Button>
        </ScreenPlaceholderOptions>
      );
    }

    return this.props.children;
  }

  private redirect = () => {
    this.setState({ hasError: false }, () => {
      Container.get(Router).push({ pathname: '/' });
    });
  };
}
