import { ScheduleCell } from '@app/components/atm.schedule-cell/';
import { IcSchedulePlaceholder } from '@app/components/icons/ic-schedule-placeholder.component';
import { LocalizedContext } from '@app/components/obj.localization';
import { Schedule, ScheduleAction } from '@app/models/schedule.model';
import { IcScheduleWhite } from '@app/modules/header/components/mol.header-notification/header-notification.component.style';
import { EventAction, EventCategory, GtmEventTag, sanitizeMessage } from '@app/utils/data-layer';
import { Button } from '@atomic/atm.button';
import { DividerGray } from '@atomic/atm.divider/divider.component.style';
import { Body, H4 } from '@atomic/atm.typography';
import { NotificationDialog } from '@atomic/mol.notification-dialog';
import { ShimmerInput } from '@atomic/mol.shimmer';
import { Hbox } from '@atomic/obj.box';
import { VSeparator } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import * as React from 'react';
import { ScheduleNotificationsWrapper } from './schedule-notifications-wrapper.component';

interface ScheduleNotificationsProps extends GtmEventTag {
  scheduleList?: Schedule[];
  refetch: () => void;
  onClick: (action: ScheduleAction, id?: number) => void;
  loading: boolean;
  error: boolean;
}

export const ScheduleNotifications: React.FC<ScheduleNotificationsProps> = (props) => {
  const localized = React.useContext(LocalizedContext).strings.components.scheduleNotifications;
  const { scheduleList, refetch, loading, error } = props;
  const notifications = scheduleList?.length || 0;

  const handleClickNextSchedules = () => {
    props.onClick(ScheduleAction.showNextSchedules);
  };

  return (
    <NotificationDialog
      dataGtmEventAction={props.dataGtmEventAction}
      dataGtmEventCategory={props.dataGtmEventCategory}
      dataGtmEventLabel={props.dataGtmEventLabel}
      refetch={refetch}
      notifications={notifications}
      icon={<IcScheduleWhite />}
    >
      <VSeparator />
      <Hbox>
        <Hbox.Separator />
        <Hbox.Item>
          <H4>{localized.titleFn(notifications)}</H4>
        </Hbox.Item>
        <Hbox.Separator />
      </Hbox>
      <VSeparator />

      <LoadingState loading={loading} data={scheduleList?.length > 0} error={error}>
        <LoadingState.Shimmer>
          <ShimmerInput />
        </LoadingState.Shimmer>

        <LoadingState.Error>
          <DividerGray />
          <VSeparator />
          <Hbox>
            <Hbox.Item hAlign='center'>
              <Body>{localized.error}</Body>
              <Button onClick={refetch} kind='link'>
                {localized.tryAgain}
              </Button>
            </Hbox.Item>
          </Hbox>
          <VSeparator />
        </LoadingState.Error>

        <LoadingState.NoData>
          <DividerGray />
          <VSeparator />
          <Hbox>
            <Hbox.Item hAlign='center'>
              <IcSchedulePlaceholder />
              <VSeparator />
              <Body>{localized.nothingScheduled}</Body>
            </Hbox.Item>
          </Hbox>
          <VSeparator />
        </LoadingState.NoData>

        <ScheduleNotificationsWrapper>
          {scheduleList?.map((schedule) => {
            return <ScheduleCell key={schedule.id} schedule={schedule} onClick={props.onClick} />;
          })}
        </ScheduleNotificationsWrapper>
      </LoadingState>

      <DividerGray />
      <VSeparator />
      <Hbox>
        <Hbox.Item>
          <Button
            kind='link'
            onClick={handleClickNextSchedules}
            dataGtmEventAction={EventAction.Click.Button}
            dataGtmEventCategory={EventCategory.Attendence}
            dataGtmEventLabel={sanitizeMessage(localized.seeNextSchedules)}
          >
            {localized.seeNextSchedules}
          </Button>
        </Hbox.Item>
      </Hbox>
      <VSeparator />
    </NotificationDialog>
  );
};
