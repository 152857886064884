import { LocalizedContext } from '@app/components/obj.localization';
import { SectionProps, StepType } from '@app/components/org.feedback/feedback-modal.constants';
import { EventAction, EventCategory, sanitizeMessage } from '@app/utils/data-layer';
import { Button } from '@atomic/atm.button';
import { RadioField } from '@atomic/atm.radio';
import { H1 } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { Form } from '@atomic/obj.form';
import { VSeparator } from '@atomic/obj.grid';
import * as React from 'react';
import { FeedbackModalCategoryWrapperStyled } from './feedback-modal-category-wrapper.component.style';

interface CategoryFormProps extends SectionProps {
  name: string;
  step: StepType;
  title?: string;
}

export const FeedbackModalCategoryForm: React.FC<CategoryFormProps> = ({
  onSubmit,
  options,
  name,
  loading,
  hasNextStep,
  onBack,
  step,
  title,
}) => {
  const localized = React.useContext(LocalizedContext).strings.components.feedbackModal;
  const [category, setCategory] = React.useState<string>('');

  const handleBack = () => {
    setCategory(null);
    onBack();
  };

  const handleSubmit = (data: any) => onSubmit(data);

  return (
    <FeedbackModalCategoryWrapperStyled>
      <Form onSubmit={handleSubmit}>
        <H1>{title ?? localized.giveFeedback(name)}</H1>
        <VSeparator />

        <Form.Field name={step} value={category} onValueChange={setCategory}>
          {options?.map((option, index) => {
            return (
              <RadioField
                key={index}
                id={option.value}
                disabled={!option.active}
                dataGtmEventCategory={EventCategory.Attendence}
                dataGtmEventAction={EventAction.Click.Feedback}
                dataGtmEventLabel={sanitizeMessage(option.label)}
              >
                {option.label}
              </RadioField>
            );
          })}
        </Form.Field>
        <VSeparator />

        <Hbox>
          {step !== StepType.status && (
            <>
              <Hbox.Item>
                <Button
                  kind='neutral'
                  onClick={handleBack}
                  loading={loading}
                  outlined
                  dataGtmEventCategory={EventCategory.Attendence}
                  dataGtmEventAction={EventAction.Click.Button}
                  dataGtmEventLabel={sanitizeMessage(localized.back)}
                >
                  {localized.back}
                </Button>
              </Hbox.Item>
              <Hbox.Separator />
            </>
          )}

          <Hbox.Item>
            <Button
              kind='callToAction'
              disabled={!category}
              expanded
              type='submit'
              data-cy='button-send-feedback'
              loading={loading}
              light
              dataGtmEventCategory={EventCategory.Attendence}
              dataGtmEventAction={EventAction.Click.Button}
              dataGtmEventLabel={sanitizeMessage(hasNextStep ? localized.continue : localized.finish)}
            >
              {hasNextStep ? localized.continue : localized.finish}
            </Button>
          </Hbox.Item>
        </Hbox>
      </Form>
    </FeedbackModalCategoryWrapperStyled>
  );
};
