import {
  ApolloError,
  LazyQueryHookOptions,
  LazyQueryResult,
  MutationHookOptions,
  MutationTuple,
  NetworkStatus,
  OperationVariables,
  PureQueryOptions,
  QueryHookOptions,
  QueryLazyOptions,
  QueryResult,
  SubscriptionHookOptions,
  useLazyQuery,
  useMutation,
  useQuery,
  useSubscription,
} from '@apollo/client';
import { Container } from 'typedi';
import { ApolloAdminClient, GraphqlAdminDocsService } from './tokens';

interface Variables {
  [key: string]: any;
}

interface Refetch {
  query: string;
  variables?: Variables;
}

interface UseMutationHook<TData, TVariables> {
  mutationName: string;
  options?: MutationHookOptions<TData, TVariables>;
  refetchQueries?: Refetch[];
  refetchQueriesByQueryName?: string[];
}

export function useAdminQueryHook<TData = any, TVariables = OperationVariables>(
  queryName: string,
  options?: QueryHookOptions<TData, TVariables>,
): QueryResult<TData, TVariables> {
  const graphqlDocsService = Container.get(GraphqlAdminDocsService);
  const client = Container.get(ApolloAdminClient);
  const query = graphqlDocsService.getQuery(queryName);
  const adminOptions: QueryHookOptions<TData, TVariables> = { ...options, client };
  const queryresult = useQuery<TData, TVariables>(query, adminOptions);
  return { ...queryresult, loading: queryresult.loading || queryresult.networkStatus === NetworkStatus.refetch };
}

export function useAdminLazyQueryHook<TData = any, TVariables = OperationVariables>(
  queryName: string,
  options?: LazyQueryHookOptions<TData, TVariables>,
): [(options?: QueryLazyOptions<TVariables> | undefined) => void, LazyQueryResult<TData, TVariables>] {
  const graphqlDocsService = Container.get(GraphqlAdminDocsService);
  const client = Container.get(ApolloAdminClient);
  const query = graphqlDocsService.getQuery(queryName);
  const adminOptions = { ...options, client };
  return useLazyQuery<TData, TVariables>(query, adminOptions);
}

export function useAdminMutationHook<TData = any, TVariables = OperationVariables>({
  mutationName,
  options = {},
  refetchQueries,
  refetchQueriesByQueryName,
}: UseMutationHook<TData, TVariables>): MutationTuple<TData, TVariables> {
  const graphqlDocsService = Container.get(GraphqlAdminDocsService);
  const queries: PureQueryOptions[] = (refetchQueries || []).map((refetch) => ({
    query: graphqlDocsService.getQuery(refetch.query),
    variables: refetch.variables,
  }));
  options.refetchQueries = queries.length > 0 ? queries : refetchQueriesByQueryName;
  const client = Container.get(ApolloAdminClient);
  const mutation = graphqlDocsService.getMutation(mutationName);
  const adminOptions = { ...options, client };
  return useMutation<TData, TVariables>(mutation, adminOptions);
}

interface UseSubscriptionHookResponse<TData> {
  loading: boolean;
  data?: TData;
  error?: ApolloError;
}

export function useAdminSubscriptionHook<TData, TVariables = OperationVariables>(
  subscriptionName: string,
  options: SubscriptionHookOptions<TData, TVariables> = {},
): UseSubscriptionHookResponse<TData> {
  const graphqlDocsService = Container.get(GraphqlAdminDocsService);
  const client = Container.get(ApolloAdminClient);
  const subscription = graphqlDocsService.getSubscription(subscriptionName);
  const adminOptions = { ...options, client };
  return useSubscription<TData, TVariables>(subscription, adminOptions);
}
