import { withRouter } from '@app/components/obj.with-router';
import { Router } from '@app/core/route';
import { FaIcon } from '@atomic/atm.fa-icon';
import { ThemeToken } from '@atomic/obj.constants';
import * as React from 'react';
import Container from 'typedi';
import { LogoutButtonStyled } from './logout-button.component.style';
import { LogoutContainer } from './logout.container';

const theme = Container.get(ThemeToken);

interface LogoutButtonProps {
  router: Router;
}

const _LogoutButton: React.FC<LogoutButtonProps> = (props) => (
  <LogoutContainer router={props.router}>
    {(logout) => (
      <LogoutButtonStyled onClick={logout}>
        <FaIcon.PowerOff color={theme.Color.Primary} />
        Sair
      </LogoutButtonStyled>
    )}
  </LogoutContainer>
);

export const LogoutButton = withRouter(_LogoutButton);
