import { removeTmzFromDateString } from '@app/components/obj.formatter/date-formatter';
import { parseNumberValue } from '@app/components/obj.formatter/number-formatter';
import { Project } from '@app/config-variables';
import { RewardTypeEnum } from '@app/data/graphql';
import { arrayToSentence, pluralizeS } from '@app/data/http/modules/authentication/utils';
import { AppPath } from '@app/modules/app/routes';
import { CartCutProductItem } from '@app/view-model/cart-cut-product/cart-cut-product.vm';
import { format } from 'date-fns';

interface CycleVariables {
  start: string;
  end: string;
}

interface CycleNoteVariables {
  note: string;
  name: string;
}

export const commonStrings = (project: Project) => {
  const reseller = project === Project.bot ? 'revendedora' : 'representante';
  const supervisor = project === Project.bot ? 'supervisora' : 'consultora';
  return {
    components: {
      AttendanceOverview: {
        monthAttendance: 'Atendimentos do mês',
      },
      DocumentsSection: {
        Buttons: {
          Confirm: 'Confirmar',
          Edit: 'Editar',
        },
        DocumentStatus: {
          Checked: 'OK',
          Pending: 'Pendente',
          Unchecked: 'NOK',
        },
        DocumentType: {
          Cpf: 'CPF',
          ProofOfAddress: 'Comprovante de endereço',
          Rg: 'RG',
        },
        Title: 'Status dos Documentos',
      },
      Placeholders: {
        Error: { ButtonText: 'Tentar novamente', Description: 'Algo deu errado' },
      },
      ResellerDetailSection: {
        activationChannelFn: (activationChannel: string) => `Canal de ativação: ${activationChannel}`,
        activationChannelNotFound: 'Canal de ativação não encontrado',
        addressFn: (address: string) => `Endereço: ${address}`,
        availableCredit: (credit: string) => `Crédito atual: ${credit}`,
        checklist: 'Checklist',
        contactInfo: 'Informações de contato',
        cpNameFn: (cp: string) => `CP: ${cp}`,
        cpNotFound: 'CP não encontrado.',
        csNameFn: (cs: string) => `CS: ${cs}`,
        csNotFound: 'CS não encontrada.',
        data: 'Dados',
        distanceFn: (distance: number, zone: string) =>
          `${parseNumberValue(distance, false, 2)} KM da ER (Zona ${zone})`,
        distanceNotFound: 'Distância indisponível',
        erNameFn: (er: string) => `Nome do ER: ${er}`,
        erNotFound: 'ER não encontrado.',
        firstOrderCycleFn: (cycle: string) => `Ciclo primeira compra: ${cycle}`,
        firstOrderCycleNotFound: 'Ciclo de primeira compra não encontrado',
        general: 'Geral',
        lifeCycleNotFound: 'Berçário indisponível',
        noActualCredit: 'Não possui crédito',
        orderMachine: 'Máquina de pedidos',
        promotions: 'Promoções',
        registerCycleFn: (registrationCycle: number) => `Ciclo de cadastro: ${registrationCycle}`,
        registerCycleNotFound: 'Ciclo de cadastro não encontrado',
        registrationDateFn: (registrationDate: string) => `Data de cadastro: ${registrationDate}`,
        registrationDateNotFound: 'Data de cadastro não encontrada',
        resellerData: 'Dados da RE',
        resellerStateNameFn: (state: string) => `Estado da RE: ${state}`,
        resellerStateNotFound: 'Estado da RE indisponível',
        startOrder: 'Fazer pedido',
        usesApp: 'Tem o App RE',
        usesMRD: 'Tem MRD',
        welch: 'Inadimplente',
      },
      ResellerInfoErrorModal: {
        button: 'tentar novamente',
        description:
          'não conseguimos salvar as informações da revendedora, isso é necessário para concluirmos o feedack do atendimento',
        hide: 'esconder',
        title: 'Algo deu errado',
      },
      SearchAutocomplete: {
        SelectorOptions: {
          SearchCPF: 'Busca por CPF',
          SearchGeraId: 'Busca por id',
          SearchName: 'Busca por nome',
          SearchRG: 'Busca por RG',
        },
        error: `Não foi possível carregar a lista de ${reseller}s.`,
        notFound: 'Não encontrado',
        tryAgain: 'Tentar Novamente',
      },
      acquiredPrizes: {
        gift: 'Grátis',
        promotionDescriptionFn: (value: number, quantity: number, promotionType: RewardTypeEnum) => {
          if (promotionType === RewardTypeEnum.Percentage) {
            return `${value}% de desconto em ${quantity} produto${pluralizeS(quantity)}`;
          }
          // TODO the API is broken. Use it as discount not absolute price.
          // if (promotionType === RewardTypeEnum.FinalValue) {
          //   return `Esse${pluralize(quantity)} produto${pluralize(quantity)} agora custa${pluralizeM(
          //     quantity,
          //   )} ${parseNumberValue(value, true, 2)}`;
          // }
          return `${parseNumberValue(value, true, 2)} de desconto em ${quantity} produto${pluralizeS(quantity)}`;
        },
      },
      activeOnCycle: {
        resellers: `${reseller}s`,
      },
      addressConfirmation: {
        cancel: 'Cancelar',
        change: 'Alterar',
        changeAddress: 'Alterar endereço de entrega',
        changeAddressConfirmationMessage:
          'Se você alterar o endereço de entrega, seu pedido será reiniciado e você poderá perder os seus produtos. Alterar mesmo assim?',
      },
      addressData: {
        addressLabel: 'Endereço',
        billingAddressLabel: 'Endereço de cobrança',
        deliveryAddressLabel: 'Endereço de entrega',
        edit: 'Editar',
        errorMessage: 'Erro ao editar informações',
        noAddress: 'Não há endereços cadastrados',
        successMessage: 'Informações editadas',
      },
      admin: {
        clearConfirmation: {
          clear: 'Limpar',
          clearOrderConfirmationMessage: 'Você perderá a seleção de produtos atual. Continuar?',
          title: 'Limpar seleção de produtos',
        },
        cpInfoHeaderCNPJ: 'CNPJ',
        cpInfoHeaderCod: 'Cód',
        cpListTitle: 'Lista de franqueados',
        createCPButton: 'Adicionar franquia',
        createCPPage: {
          cpCreated: 'Criada com sucesso',
          cpEdited: 'Editada com sucesso',
          createInfo: 'Informações cadastrais',
          editCP: 'Editar',
          editInfo: 'Editar informações',
          existingCode: 'Código de franquia já existente',
          franchiseName: 'Nome da franquia',
          referenceId: 'Código da franquia',
          saveCP: 'Salvar',
          validators: {
            numbersOnly: 'Preencha somente com números',
            required: 'Campo obrigatório',
          },
        },
        createCS: {
          CSCreated: 'Central de Serviço criada com sucesso!',
          CSEdited: 'Central de Serviço editada com sucesso!',
          createInfo: 'Informações cadastrais',
          franchiseCode: 'Código da  Franquia',
          required: 'Este campo não deve ser vazio',
          saveCS: 'Salvar',
        },
        createCSButton: 'Adicionar CS',

        createCSPage: {
          csCode: 'Código da CS',
          csName: 'Nome da CS',
        },
        createNewsButton: 'Adicionar notícia',
        createNewsPage: {
          active: 'Ativo',
          editButton: 'Editar',
          isPublic: 'Público',
          newsCreated: 'Notícia criada',
          newsCriticityLevel: 'Nível de criticidade',
          newsDescription: 'Mensagem',
          newsEdited: 'Editada com sucesso',
          newsEnvironment: 'Projeto',
          newsIsEnabled: 'Ativa',
          newsIsPublic: 'Tornar notícia visível também para usuários deslogados',
          newsReferences: 'Lista de URLs relacionadas a notícia (separadas por vírgula)',
          newsTitle: 'Título da notícia',
          project: 'Projeto',
          saveButton: 'Salvar',
          validators: {
            maxLength: 'Você atingiu o máximo de 1500 caracteres',
            required: 'Campo obrigatório',
          },
        },
        createPromotionButton: 'Criar promoção',
        createPromotionPage: {
          PromotionCreated: 'Promoção criada',
          createPromotion: 'Criar promoção',
          savePromotion: 'Salvar',
        },
        createScriptPage: {
          create: 'Criar passos',
          saveScript: 'Salvar',
          scriptCreated: 'Criado com sucesso',
        },
        csInfo: 'Informações da CS',
        csListPage: {
          downloadTemplate: 'Baixar template',
        },
        csListTitle: 'Lista de CS',
        csPromotion: 'Promoções da CS',
        deleteCS: {
          deleteCSModal: {
            button: 'Deletar',
            message: 'Tem certeza que quer deletar esse CS ?',
            title: 'Deletar CS',
          },
          sucess: 'CS removido com sucesso',
        },
        editCPButton: 'Editar informação do CP',
        editCSButton: 'Editar informação da CS',
        editPromotionPage: {
          PromotionEdited: 'Promoção editada',
          edit: 'Editar',
          editPromotion: 'Editar promoção',
        },
        editScriptPage: {
          edit: 'Editar passos',
          editScript: 'Editar',
          scriptEdited: 'Editado com sucesso',
        },
        filters: {
          by: 'Filtrado pelo',
          field: 'Campo',
          header: 'Ordenação dinâmica',
          newOption: 'Nova opção',
          optionModal: {
            Both: 'Ambos',
            Honored: 'Adimplente',
            InNegotiation: 'Em negociação',
            NonBlocked: 'Não bloqueado',
            NonCeased: 'Não cessado',
            Welch: 'Inadimplente',
            WelchOnly: 'Inadimplente',
            boti: { franchisees: 'CP', shops: 'CS' },
            categories: 'Categorias',
            clubeBoti: 'Clube',
            description: 'Descrição',
            eud: { channel: 'Canal', coordinator: 'Coordenação comercial', sector: 'Setor' },
            geraStructures: 'Estruturas comerciais',
            isEnabledFn: (isEnabled: boolean) => (isEnabled ? 'Habilitado' : 'Desabilitado'),
            name: 'Nome',
            others: 'Outros',
            payments: 'Pagamentos',
            save: 'Salvar',
            status: 'Status',
            successMessageFn: (iSedition: boolean, name: string) =>
              `Opção ${name} ${iSedition ? 'editada' : 'criada'} com successo`,
            titleFn: (name?: string) => (name ? `Editando: ${name}` : 'Nova opção'),
            validators: {
              requiredFn: (fieldName: string) => `O campo '${fieldName}' não pode estar vazio`,
            },
          },
          optionsList: {
            edit: 'Editar',
            headerFn: (name: string, id: number) => `${name} - ${id}`,
            status: {
              active: 'Ativo',
              inactive: 'Inativo',
            },
          },
          type: 'Tipo',
        },
        formsPromotion: {
          createInfo: 'Informações cadastrais',
          labels: {
            description: 'Descrição',
            endDate: 'Data de término',
            startDate: 'Data de início',
            title: 'Título',
          },
          validators: {
            date: 'Data inválida',
            maxLength: 'Você atingiu o máximo de 200 caracteres',
            required: 'Campo obrigatório',
          },
        },
        formsScript: {
          labels: {
            instructionLabel: 'Descrição',
            titleLabel: 'Título do passo',
          },
          validators: {
            maxLength: 'Você atingiu o máximo de 300 caracteres',
            required: 'Campo obrigatório',
          },
        },
        home: {
          searchByResellerNameOrCodeOrCPF: 'Busque por CPF ou RG',
          tabs: {
            checklist: 'Checklist da Franquia',
            irData: 'Dados da IR',
            promotions: 'Promoções',
            script: 'Script',
          },
        },
        importCS: {
          dragNDropMessage: 'Arraste aqui seu arquivo CSV ou',
          uploadZoneButtonMessage: 'Selecione',
          uploadZoneErrorMessage: 'Formato de arquivo incorreto. Favor selecione um arquivo CSV',
          uploadZoneMessage: 'Solte aqui',
        },
        irData: {
          active: 'Ativo',
          contactInfo: {
            birthDate: 'Data de nascimento',
            cancelButton: 'Cancelar',
            cpf: 'CPF',
            editContactInfoButton: 'Editar informações',
            email: 'Email',
            errorMessage: 'Erro ao editar as informações',
            name: 'Nome',
            phone: 'Telefone',
            saveInformation: 'Salvar informações',
            successMessage: 'Informações editadas',
            title: 'Informações de contato',
          },
          conversationButton: 'Ver mais conversas',
          conversations: 'Histórico de conversas',
          deliveryAdress: {
            address: 'Endereço',
            city: 'Cidade',
            neighborhood: 'Bairro',
            title: 'Endereço de entrega',
          },
          latestConversation: 'Última conversa',
          lister: {
            noData: 'Não foi possível carregar os dados da IR.',
          },
          receptive: 'Receptivo',
        },
        irNotFound: {
          call: 'Você está recebendo uma ligação.',
          crtlP: '(CRTL + P)',
          searchData: 'Procure os dados dessa revendedora',
        },
        listPromotion: {
          activated: 'Ativada',
          desactivated: 'Desativada',
          editButton: 'Editar',
          endDate: 'Data de término',
          hideMessage: 'Ativar/Desativar mensagem para a assistente',
          promotionStatus: 'Status da promoção',
          startDate: 'Data de início',
          switchPromotion: 'Promoção modificada',
        },
        listSelectedTemplates: {
          documentFile: 'Arquivo PDF',
          edit: 'Editar',
          environment: 'Disponível em: ',
          file: 'Upload de arquivo',
          friendlyName: 'Nome amigável: ',
          imageFile: 'Arquivo de imagem',
          isEditable: 'Valor é editável',
          link: 'Link do arquivo',
          noTemplateSelected: 'Nenhum template selecionado',
          officialName: 'Nome original: ',
          remove: 'Remover',
          requiredField: 'Campo obrigatório',
          save: 'Salvar',
          templateName: 'Nome amigável (que será exibido para a atendente)',
          title: 'Lista de templates selecionados',
          uploadFailed: 'Erro no envio do arquivo',
          variable: (variable) => `Variável ${variable}`,
          variableTitle: (messageComponent: string, isPlural?: boolean) =>
            `Variáveis do${isPlural ? 's' : ''} ${messageComponent} da mensagem`,
          videoFile: 'Arquivo de vídeo',
        },
        listTemplates: {
          addTitle: 'Adicionar templates',
          editTitle: 'Editar template',
          message: 'Mensagen do template',
          next: 'Próxima página',
          previous: 'Página anterior',
          searchPlaceholder: 'Pesquisar template por nome',
          select: 'Selecionar',
          templateAlreadyAdded: 'Esse template já foi adicionado.',
          title: 'Lista de templates disponíveis',
        },
        medias: {
          addNewMedia: 'Adicionar nova mídia',
          delete: 'Deletar',
          description: 'Descrição',
          edit: 'Editar',
          file: 'Arquivo da mídia',
          headerFn: (name: string, id: string) => `${name} - ${id}`,
          name: 'Nome',
          preview: 'Visualizar',
          removeMedia: 'Remover mídia',
          save: 'Salvar',
          subtitle: 'Lista de mídias públicas',
          successDeleteMessage: 'Mídia deletada com successo',
          successMessageFn: (edition: boolean, name: string) =>
            `Mídia ${name} ${edition ? 'editada' : 'criada'} com successo`,
          title: 'Mídias públicas',
          titleFn: (name?: string) => (name ? `Editando: ${name}` : 'Nova opção'),
          update: 'Atualizar',
          validators: {
            requiredFn: (fieldName: string) => `O campo '${fieldName}' não pode estar vazio`,
          },
        },
        newsDetail: {
          deleteNews: 'Notícia deletada',
          editButton: 'Editar',
          environment: 'Projeto: ',
          hideMessage: 'Esconder mensagem para a assistente',
          isPublic: (isPublic: boolean) => `Notícia ${isPublic ? '' : 'não '}pública`,
        },
        newsListTitle: 'Últimas notícias criadas',
        promotionDetail: {
          endDate: 'Data de término',
          startDate: 'Data de início',
        },
        questionsPage: {
          environmentActivation: 'Ativação',
          environmentBoth: 'Ambos',
          environmentStarts: 'Inícios',
          question: 'Pergunta',
          questionEnvironment: 'Página da pergunta',
          questionRegistration: 'Cadastro de perguntas',
          save: 'Salvar',
        },
        scriptDetail: {
          deleteStep: 'Excluir passo',
          editButton: 'Editar',
          hideMessage: 'Esconder mensagem para a assistente',
          stepDeleted: 'Passo excluído',
        },
        scriptPage: {
          createStep: 'Adicionar passo',
          noData: 'Nenhum roteiro foi encontrado',
          scriptTitle: 'Script de atendimento',
        },
        searchByCPNameOrCode: 'Buscar franquia por nome ou código',
        searchByCSNameOrCode: 'Buscar CS por nome ou código',
        searchByNewsOrCode: 'Buscar notícia por título ou código',
        tabulation: {
          activated: 'ativado',
          activation: 'Ativações',
          active: 'Ativo',
          addNewItem: 'Adicionar novo item',
          deactivated: 'desativado',
          edit: 'Editar',
          editOrder: 'Reordenar opções',
          element: 'elemento',
          geraCode: 'Código Gera:',
          header: 'Tabulação',
          inactive: 'Inativo',
          inputShouldNotBeEmpty: 'O campo de Título não pode estar vazio',
          modal: {
            addDetailButton: 'adicionar detalhe +',
            addNewStep: 'Adicionar novo',
            category: 'Categoria',
            categoryName: 'Nome da categoria',
            categoryShouldNotBeEmpty: 'O campo de Categoria não pode estar vazio',
            deleteCategory: 'Deletar categoria',
            detailOption: (optionNumber: number) => `opção de detalhe ${optionNumber}`,
            editFn: (categoryName: string) => `Edição: ${categoryName}`,
            hide: 'Inativar',
            isEnabledFn: (enabled: boolean) => (enabled ? 'Habilitada' : 'Desabilitada'),
            newCategory: 'Nova categoria',
            no: 'Não',
            save: 'Salvar',
            show: 'Mostrar',
            subTitle: 'Feedbacks',
            successMessage: 'Feedback editado',
            yes: 'Sim',
          },
          nCallCode: 'Código NCall:',
          newCategory: 'Nova categoria',
          permissionTitles: {
            hasScheduling: 'Incluir agendamento',
            includeCommunicationMethod: 'Incluir meio de comunicação',
            includeNote: 'Incluir observação',
            makeObservationRequired: 'Tornar observação obrigatória',
            noTabulation: 'Não tabular',
          },
          reorderCancel: 'Cancelar reordenação',
          reorderCategory: 'Reordenar categoria',
          reorderCategorySuccess: 'Categoria reordenada com sucesso',
          saveOrder: 'Salvar ordem',
          secondStepOptions: 'Opções de segundo passo',
          startings: 'Inícios',
          title: 'Título',
          visibility: 'Visibilidade',
        },
        templates: {
          addTemplateButton: 'Adicionar novo template',
          header: 'Templates de mensagens',
          messageConfirmation: (templateName) => `Tem certeza que deseja remover o template "${templateName}"?`,
          removeConfirmation: 'Confirmar',
          removeSuccessful: 'Template removido com sucesso',
          templateListTitle: 'Lista de templates selecionados',
          titleConfirmation: 'Remover template',
        },
      },
      antiFraud: {
        Button: {
          cancel: 'Cancelar',
          copy: 'Copiar',
        },
        copiedToClipboard: 'Ramal copiado para a área de transferência',
        title: 'Ramal Anti-Fraude',
      },
      baseIndicatorCard: {
        notFound: 'Dado não encontrado',
        updatedAt: (date: string) => `Atualizado ${date}`,
      },
      baseInfo: {
        body: `Não há nenhum dado adicional. Adicione informações dessa ${reseller}.`,
      },
      benefitsModal: {
        benefits: 'Benefícios',
      },
      boletoCell: {
        boletoNumber: (number: string) => `Boleto n˚ ${number}`,
        createdAt: (issueDate: string) => `Criado em ${issueDate}`,
        expiresIn: (expireDate: string) => `Vence em ${expireDate}`,
        order: 'Pedido',
        paymentMode: (paymentMode: string) => `Modo de pagamento: ${paymentMode}`,
        seeDetails: 'Ver detalhes',
        shareBoleto: 'Compartilhar boleto',
        titles: 'Títulos',
      },
      boletoDetail: {
        noResultFound: 'Nenhum resultado encontrado',
        title: 'Detalhe do boleto',
      },
      boletoFilter: {
        creationDate: 'Data de criação:',
        endDate: 'Fim',
        exportTitles: 'Exportar',
        extractTitles: 'Exportar para Excel',
        extractedTitlesFileName: 'boletos_extraidos',
        order: 'Ordenação',
        payment: 'Pagamento',
        quantity: 'Quantidade',
        quantityRange: 'A quantidade deve ser entre 1 e 50',
        required: 'Campo obrigatório',
        saveResults: 'Salvar resultados',
        search: 'Buscar',
        situation: 'Situação',
        startDate: 'Início',
        title: 'Filtros',
        titleOrder: 'Ordernar Por',
      },
      boletos: {
        boletoNumber: (id: string) => `Boleto ${id}`,
        dueCredit: 'A vencer',
        hasExpired: (days: number) => {
          return `Vencido há ${Math.abs(days)} dia${pluralizeS(Math.abs(days))}`;
        },
        isExpiring: (days: number) => {
          return days > 0 ? `${days} dia${pluralizeS(days)} para o vencimento` : 'Expira hoje';
        },
        noData: 'Nenhum boleto encontrado.',
        overdueCredit: 'Vencido',
        seeAllSlips: 'Ver todos os títulos',
        title: 'Status dos boletos abertos',
        willExpire: (date: Date) => {
          return `Vencimento: ${format(date, 'dd/MM')}`;
        },
      },
      boletosEudora: {
        boletoNumber: (id: string) => `Boleto ${id}`,
        dueCredit: 'A vencer',
        hasExpired: (days: number) => {
          return `Vencido há ${Math.abs(days)} dia${pluralizeS(Math.abs(days))}`;
        },
        isExpiring: (days: number) => {
          return days > 0 ? `${days} dia${pluralizeS(days)} para o vencimento` : 'Expira hoje';
        },
        noData: 'Nenhum boleto encontrado.',
        overdueCredit: 'Vencido',
        seeAllSlips: 'Ver todos os títulos',
        title: 'Títulos',
        willExpire: (date: Date) => {
          return `Vencimento: ${format(date, 'dd/MM')}`;
        },
      },
      boletosHistory: {
        title: 'Histórico de títulos',
      },
      buyingPotential: {
        availableCredit: 'Crédito disponível',
        purchasePotential: (data: string) => `Potencial de compra: ${data ? data : '-'}`,
        purchasedOnCurrentCycle: 'Compras no ciclo atual',
        totalCredit: 'Crédito total',
      },
      cancelOrder: {
        button: 'Cancelar',
        error: 'Erro ao cancelar pedido',
        modal: {
          cancel: 'Cancelar',
          confirm: 'Confirmar',
          message: 'Tem certeza que deseja cancelar o pedido?',
          title: 'Cancelar pedido',
        },
        success: 'Pedido cancelado com sucesso!',
      },
      cartManagement: {
        notEnoughProductsFn: (amountToMinimum: number, currentAmount: number) =>
          `Faltam ${parseNumberValue(amountToMinimum, true, 2)} para atingir o valor mínimo de ${parseNumberValue(
            currentAmount,
            true,
            2,
          )}`,
        orderFn: (name: string, isEditing: boolean) => (isEditing ? `Edição - Pedido de ${name}` : `Pedido de ${name}`),
        proceedToReview: 'Prosseguir para revisão',
        starterPackCreditMessage: 'O Kit Início não subtrai do crédito! Aproveite para sugerir outros produtos!',
      },
      cartPages: {
        advance: 'Avançar',
        back: 'Voltar',
        chooseAddress: 'Escolha o local de entrega',
        chooseBusinessModel: 'Selecione um modelo de negócio',
        chooseCycle: 'Escolha o ciclo',
        chooseCycleAndSubcycle: 'Escolha ciclo e subciclo',
        chooseProduct: 'Escolha um produto',
        chooseStartItems: 'Escolha um produto do Kit Início',
        chooseStartItemsMultiplePacks: 'Escolha um produto p/ cada Kit Início',
        chooseStarterPack: 'Escolha um Kit Início',
        chooseSubcycle: 'Escolha o subciclo',
        continueToAddress: 'Continuar para escolha de endereço',
        continueToCycle: 'Continuar para escolha do ciclo',
        continueToEditOrder: 'Continuar para edição do carrinho',
        continueToProduct: 'Continuar para escolha de produto',
        continueToProducts: 'Continuar para adição de produtos',
        continueToStarterPack: 'Continuar para o Kit Início',
        copyLastCancelledOrder: 'Copiar o último pedido, se foi cancelado',
        currentCycleFn: (cycle: string) => `Ciclo ${cycle} (atual)`,
        cycle: 'Ciclo de marketing',
        editOrderBody: (orderCode: string) =>
          `Se deseja editar o pedido ${orderCode}, clique em "Continuar para edição do carrinho"`,
        editOrderTitle: 'Editar pedido',
        orderTitle: 'Pedido',
        previousCycleFn: (cycle: string) => `Ciclo ${cycle} (anterior)`,
        steps: {
          address: 'Endereço',
          businessModel: 'Modelo de negócio',
          cancel: 'Cancelar',
          cycle: 'Ciclos',
          editOrder: 'Editar pedido',
          management: 'Carrinho',
          orderNumber: 'N° do pedido',
          starterKits: 'Kits',
        },
        subcycle: 'Subciclo',
      },
      cartSection: {
        addressNotSelected: 'Selecione um endereço',
        businessModelNotSelected: 'Selecione um modelo de negócio',
        continueToCycle: 'Continuar para escolha do ciclo',
        continueToEditOrder: 'Continuar para edição do carrinho',
        continueToProducts: 'Continuar para adição de produtos',
        continueToStartItems: 'Continuar para escolha do Kit Início',
        cycleNotSelected: 'Selecione um ciclo',
        cycleSelectionConfirmation: {
          buttonText: 'Continuar',
          message: (previousCicle: string, currentCycle: string) =>
            `Estamos em período de captação estendida. Caso prossiga, será iniciado um pedido no CICLO ATUAL (${currentCycle}), e não será mais possível captar no CICLO ANTERIOR (${previousCicle})`,
          title: 'Selecionar CICLO ATUAL',
        },
        orderTitle: 'Pedido',
        starterPackNotSelected: 'Selecione um Kit Início',
        starterPackProductNotSelected: 'Selecione um produto',
      },
      changeAddressModal: {
        changeAddress: 'Escolher endereço',
        pickupOnDistributionCenter: 'Retirar no espaço revendedor',
        selectNewAddress: 'Selecionar outra forma de entrega',
      },
      checklist: {
        address: 'Endereço (Logradouro/Bairro/Cidade)',
        allowedFlags: 'Quais são as bandeiras de cartão de crédito aceitas?',
        alternativeCicleClosingSchedule: 'Possui horário diferenciado no fechamento do ciclo?',
        canFvcUseGeraOnlineCreditCard: 'Se sim, FVC pode utilizar o recurso ou somente a Central?',
        cpWorksWithBillets: 'CP trabalha com boleto?',
        creditCardPaymentsConditions:
          'Quais as condições de parcelamento no cartão de crédito? (Nº de parcelas, valor mínimo da parcela)',
        csCode: 'Código da CS',
        cycleProrrogationInfoTitle: 'Prorrogação de ciclo',
        deliveryCost: 'Qual o valor do frete cobrado para entrega?',
        deliveryTimeAfterInvoice: 'Após faturado, qual o prazo para entrega/retirada?',
        deliverymanCanReceiveCash: 'O entregador pode receber em dinheiro na entrega?',
        deliverymanHasPaymentMachine: 'O entregador leva maquininha para pagamento com cartão na entrega?',
        depositAccountData: 'Se sim, quais os dados?',
        dragNDropMessage: 'Arraste aqui seu arquivo CSV ou',
        generalCSDataInfoTitle: 'Dados gerais da CS',
        generalInfoTitle: 'Informações gerais',
        hasDepositAccount: 'CP possui conta para depósito?',
        installmentConditions: 'Se parcelado, com quais condições? (30 dias ou 2x)',
        logisticInfoTitle: 'Informações sobre logística',
        maximumDayAndTimeToGetOrder: 'Até que dia e horário pode ser feita captação de pedidos?',
        maximumDayAndTimeToInvoiceOrder: 'Até que dia e horário os pedidos são faturados?',
        maximumTimeToRetrieveOrderOnDistributionCenter:
          'No caso de pedidos com solicitação de retirada na Central, qual o prazo máximo para retirada?',
        minimumOrderAmountToFreeShipping: 'Qual o valor mínimo do pedido para frete grátis?',
        minimumOrderValue: 'Qual o valor mínimo dos pedidos?',
        openingHours: 'Horário de atendimento (segunda à sexta)',
        openingHoursWeekends: 'Horário de atendimento (sábados)',
        orderInvoiceDeadline: 'Qual o prazo para faturamento dos pedidos?',
        ordersInfoTitle: 'Pedidos',
        paymentConditionsInfoTitle: 'Condições de pagamento',
        phone: 'Telefone',
        promptPaymentOrInstallments: 'Se sim, apenas para pagamento à vista ou parcelado?',
        referencePoint: 'Ponto de referência',
        registeredToUseGeraOnlineCreditCard: 'CP está cadastrado para utilizar Cartão de Crédito Online no Gera?',
        uploadZoneButtonMessage: 'Selecione',
        uploadZoneErrorMessage: 'Formato de arquivo incorreto. Favor selecione um arquivo CSV',
        uploadZoneMessage: 'Solte aqui',
        worksAsResellerSpace: 'Possui horário diferenciado no fechamento do ciclo?',
        worksWithCycleProrrogation: 'CP trabalha com prorrogação de ciclo?',
      },
      checkout: {
        changeCreditCardData: 'Verificar dados do cartão',
        createdMessage: `Criamos uma mensagem para enviar à ${reseller} com todas as informações importantes! É só copiar e enviar para ela!`,
        editionDone: 'Edição feita com sucesso!',
        orderDone: 'Pedido feito com sucesso!',
        paymentDescription: (paymentMode: string, PaymentPlanName?: string) => `${paymentMode} - ${PaymentPlanName}`,
        paymentEditionError: 'Edição realizada, porém houve um erro no envio do pagamento.',
        paymentError: 'Pedido realizado, porém houve um erro no envio do pagamento.',
        retry: 'Tentar novamente',
      },
      checkoutAddressCell: {
        atDeliveryAddress: 'No endereço de entrega',
        cellTitle: 'Endereço de entrega',
        commonDelivery: 'Entrega normal',
        deliveryTime: (days: number | string) => `${days} dia${pluralizeS(+days)} ${days === 1 ? 'útil' : 'úteis'}`,
        error: 'Ocorreu um erro. por favor, recarregue a página',
        linkTitle: 'Trocar',
        pickupOnDistributionCenter: 'Retirar no espaço do revendedor',
        pickupOnDistributionCenterAlert:
          'Você optou por retirar seu pedido na central. Para alterar a forma de entrega o pedido deve ser cancelado',
      },
      checkoutDeliveryObsCell: {
        cellTitle: 'Observação para o entregador',
        message: 'Mensagem da nota fiscal',
      },
      checkoutFinishPage: {
        orderMessage: `Criamos uma mensagem para enviar à ${reseller} com todas as informações importantes! É só copiar e enviar para ela!`,
        title: 'Pedido feito com sucesso!',
      },
      checkoutGanheMaisTiersCell: {
        accumulatedValue: (value: string) => `Você já acumulou ${value}`,
        and: '+',
        nextTierGoal: (value: string) => `Faltam ${value} para você ganhar os seguintes prêmios`,
        or: 'ou',
        quantity: (value: string) => `Qtd ${value}`,
        title: 'Ganhe mais',
      },
      checkoutParcialPromotionsCell: {
        linkTitle: 'Modificar pedido',
        missing: 'O que falta',
        missingProduct: (quantity, name) => `${quantity}x ${name}`,
        title: 'Promoções parcialmente conquistadas',
      },
      checkoutPaymentCell: {
        cellTitle: 'Pagamento',
        changeCard: 'Alterar dados',
        creditInfoFn: (money: string) => `Seu crédito é de ${money}`,
        defaultPaymentOptionLabel: 'Selecionar opção de pagamento',
        important: 'Importante: ',
        multipleForms:
          'Nesta modalidade, o pedido poderá ser pago combinando mais de uma opção de pagamento (ex: cartão e boleto, 2 cartões diferentes, etc).',
        multipleFormsAlert: 'é necessário finalizar o pedido para detalhar o pagamento',
        selectCard: 'Adicionar informações do cartão de crédito',
      },
      checkoutPrizeCell: {
        quantity: (qtd: number) => `Qtd ${qtd}`,
      },
      checkoutProductCell: {
        cutReason: 'Motivo do corte',
        discount: (discount: number) => `${discount}% de desconto`,
        hideComboDetail: 'Ver menos detalhes do combo',
        magazine: 'Revista',
        magazineUnitPrice: 'Unid',
        pay: 'Pague',
        profit: 'Lucre',
        quantity: 'Qtd ',
        resellerUnitPrice: 'Unid',
        showComboDetail: 'Ver mais detalhes do combo',
        type: (type: string) => `Tipo: ${type}`,
      },
      checkoutSideSummary: {
        adminTax: 'Taxa de boleto',
        availableCredit: 'Crédito disponível',
        checkingAccount: 'Conta corrente',
        cycleFn: (cycle: number, subCycle: number) => `Ciclo ${cycle} ${subCycle ? `/ Subciclo ${subCycle}` : ''}`,
        deliveryPrice: 'Frete',
        discount: 'Descontos',
        finishOrder: 'Finalizar compra',
        free: 'Grátis',
        orderFn: (order: string) => `Pedido ${order}`,
        prizeTotal: 'Total de prêmios',
        profit: 'Seu lucro total',
        sellBy: 'Venda por',
        subtotal: 'Subtotal',
        tax: 'Imposto',
        total: 'Total a pagar',
        totalDiscount: 'Desconto total',
        validation: {
          address: 'Selecione um endereço de entrega',
          payment: 'Selecione uma forma de pagamento',
          promotion: 'Ainda restam prêmios a selecionar',
        },
      },
      checkoutWantMorePrizesCell: {
        cellTitle: 'Quero mais prêmios',
        choose: 'Escolher',
        discountOrPromo: 'Promoções ou descontos',
        discountOrPromoDesc: 'É você quem escolhe: promoção ou desconto!',
        discounts: 'Descontos',
        discountsDesc: 'Aplique descontos nos produtos que você comprou.',
        modify: 'Modificar',
        pickAPrize: 'Escolha um brinde!',
        pickAPrizeDesc: 'Nesta opção, você poderá pegar um brinde da lista.',
      },
      clearOrderBanner: {
        confirmationText: 'Remover produtos',
        dismissText: 'Manter produtos',
        message: 'Os produtos abaixo foram resgatados do último pedido cancelado.',
      },
      clubeBoti: {
        accumulatedValue: 'Acumulado: ',
        benefits: 'Consulte os benefícios',
        highestLevel: `A ${reseller} está no nível mais alto do Meu Clube Boti!`,
        highestLevelDesc: 'Não deixe ela cair e perder seus benefícios!',
        noData: 'Sem dados do Meu Clube Boti',
        remainingForNextTierFn: (value: string) => `Faltam ${value}`,
        tier: `${reseller}`,
        title: 'Meu Clube Boti',
        toReachTierFn: (tier: string) => `para atingir a faixa ${tier}`,
      },
      comboEudora: {
        back: 'Voltar',
        canSelectMoreProductsInPreviousCategories: (categories: number[]) =>
          `Você ainda pode adicionar produtos da${pluralizeS(categories.length)} Categoria${pluralizeS(
            categories.length,
          )} ${arrayToSentence(categories)}`,
        category: (category: number) => `Categoria ${category}`,
        close: 'Fechar',
        code: 'Código',
        confirm: 'Salvar',
        content: 'Conteúdo',
        details: 'Detalhes',
        edit: 'Editar',
        fixedProducts: 'Produtos fixos',
        nextSelection: 'Avançar',
        product: 'Produto',
        productDetail: (quantity: number, code: string, name: string) => `${quantity}x - ${code} - ${name}`,
        productSelectionDetail: (quantity: number) => `${quantity}x - Produtos a sua escolha da `,
        products: 'Produtos',
        productsSelected: 'Produtos selecionados com sucesso! Avance para a próxima etapa',
        quantity: (unities: number) => `${unities} unid.`,
        review: 'Revisão',
        select: 'Seleção de produtos',
        selectExactQuantity: 'Por favor, selecione a quantidade exata de produtos',
        selectedProducts: (quantity: number, maxQuantity: number) => `${quantity} de ${maxQuantity} selecionados`,
        step: 'Passo',
        summary: 'Resumo do combo',
        title: 'Detalhes de combo',
      },
      commercialStructure: {
        label: (item: string) => `Estrutura ${item}`,
      },
      configurationPages: {
        configList: {
          schedules: 'Horários',
        },
        configurationTitle: 'Configurações',
        workTimePage: {
          buttons: {
            cancel: 'Cancelar',
            edit: 'Editar',
          },
          title: 'Horário de trabalho',
          weekDaysWorkTime: {
            end: 'Saída',
            notAvailable: 'N/A',
            save: 'Salvar',
            shouldInputStartAndEnd: (weekday: string) => `${weekday}: Entrada e saída devem ser preenchidos juntos`,
            start: 'Entrada',
          },
        },
      },
      confirmationModal: {
        cancel: 'Cancelar',
      },
      contactData: {
        additionalInfo: 'Informações adicionais',
        cancel: 'Cancelar',
        contactPhone: 'Telefone de recado',
        contactPhoneNotFound: 'Telefone de recado não cadastrado',
        dataNotFound: 'Não há dados de contato cadastrados',
        edit: 'Editar',
        editAdditionalInfo: 'Editar informações adicionais',
        editPhones: 'Editar/Adicionar telefone',
        email: 'Email',
        emailNotFound: 'Email não cadastrado',
        errorMessage: 'Erro ao editar informações',
        mobilePhone: 'Celular',
        mobilePhoneNotFound: 'Celular não cadastrado',
        required: 'Campo obrigatório',
        residentialPhone: 'Telefone',
        residentialPhoneNotFound: 'Telefone não cadastrado',
        save: 'Salvar',
        successMessage: 'Informações editadas',
        talkTo: 'Falar com',
        talkToNotFound: 'Falar com não cadastrado',
        title: 'Dados de contato',
        validPhone: 'Digite um telefone válido',
      },
      contactInfo: {
        addAddress: 'Adicionar um novo endereço',
        addTelephone: 'Adicionar um novo telefone',
        addressLabel: 'Endereço',
        addresses: 'Endereços',
        cancel: 'Cancelar',
        contactInfo: 'Informações de contato',
        contactInfoNotFound: 'Informações de contato não encontrados',
        copiedToClipboard: 'Copiado para a área de transferência',
        save: 'Salvar',
        telephoneLabel: 'Telefone com DDD',
        telephones: 'Telefones',
        validAddress: 'O endereço deve ser preenchido',
        validTelephone: 'Digite um telefone válido',
      },
      contactLinks: {
        adressSectionTitle: 'Endereço',
        cancel: 'Cancelar',
        copiedToClipboard: 'Copiado para a área de transferência',
        email: (count: number) => (count > 1 ? 'Emails' : 'Email'),
        emailsNotFound: 'Nenhum email encontrado.',
        getInTouch: 'Entrar em contato',
        manualMessage: 'Efetuando ligação manual',
        phone: (count: number) => (count > 1 ? 'Telefones' : 'Telefone'),
        phonesNotFound: 'Nenhum telefone encontrado.',
      },
      copyLink: {
        button: 'Copiar link',
        sucess: 'Link copiado com sucesso',
      },
      credit: {
        ccrBalance: 'Saldo CCR',
        lastLimit: (credit: string, date: Date) => `Último limite de crédito ${credit} (${format(date, 'dd/MM')})`,
        noData: 'Histórico de crédito não encontrado.',
        title: 'Crédito disponível',
        totalCreditTitle: 'Crédito total',
        uninvoicedTitle: 'A faturar',
      },
      creditCardForm: {
        cardNumber: {
          Label: 'Número',
          invalidNumber: 'Número inválido',
          required: 'Campo obrigatório',
        },
        confirm: 'Confirmar',
        documentNumber: {
          label: 'CPF',
          required: 'Campo obrigatório',
        },
        expiration: {
          label: 'Validade',
          required: 'Campo obrigatório',
        },
        installment: 'Parcelamento',
        name: {
          label: 'Nome',
          maxLength: 'Tamanho máximo',
          required: 'Campo obrigatório',
        },
        phoneNumber: {
          label: 'Telefone',
          required: 'Campo obrigatório',
        },
        securityCode: {
          label: 'CVV',
          required: 'Campo obrigatório',
        },
        title: 'Cartão de crédito',
        type: {
          label: 'Bandeira',
          required: 'Campo obrigatório',
        },
      },
      csListPage: {
        importFromCSV: 'Importar CS',
      },
      currentOrderDeliveryModes: {
        onDeliveryAddress: 'No endereço de entrega',
        onResellerSpace: 'No espaço revendedor',
      },
      cutDate: {
        isTheDay: (name: string) => `Hoje é dia de corte ${name ? `de ${name}` : ''}!  🎉`,
      },
      cutProduct: {
        accordionTitle: 'Produtos cortados',
        quantity: 'Qtd ',
      },
      cutProductListCell: {
        cellTitle: 'Produtos cortados',
      },
      cycleBar: {
        error: 'Não foi possível exibir o ciclo atual',
      },
      cycleInfo: {
        cycle: (cycle: string, cycleStartDate: string, cycleEndDate: string) =>
          `CICLO ${cycle} (${removeTmzFromDateString(cycleStartDate)} A ${removeTmzFromDateString(cycleEndDate)})`,
        subCycle: (subcycle: number, subcycleStartDate: string, subcycleEndDate: string) =>
          `SUBCICLO ${subcycle} (${removeTmzFromDateString(subcycleStartDate)} A ${removeTmzFromDateString(
            subcycleEndDate,
          )})`,
      },
      dailyContact: {
        notification: `${reseller}s`,
      },
      dashboardPlaceholder: {
        noResellers: 'Nenhum atendimento iniciado',
      },
      earnMoreCell: {
        cellTitle: 'Ganhe mais',
        priceToNextTier: (value: string) => `Faltam ${value} para você ganhar:`,
      },
      earnedPrizesCell: {
        cellTitle: 'Prêmios conquistados',
      },
      euPossoMais: {
        balance: 'Saldo EPM',
        categoryTitle: 'Categoria',
        currentCategory: (category: string) => `Faixa ${category}`,
        nextCategory: (nextCategory: number) => `para atingir a Faixa ${nextCategory}`,
        noCategory: 'Não possui categoria',
        pendingForNextCategory: (totalLeft: string) => `Faltam ${totalLeft || '-'}`,
        title: 'Eu Posso Mais',
      },
      fastShop: {
        addToCard: 'Adicionar ao pedido',
        dontKnow: 'Não sabe o código?',
        inputShouldNotBeEmpty: 'O campo de pesquisa não pode estar vazio',
        label: 'Código',
        placeholder: '12345',
        productLenFn: (quantity: number) => `Produtos (${quantity})`,
        quantityLabel: 'Quantidade',
        quantityPlaceholder: '2',
        searchError: 'Produto não encontrado',
        searchProduct: 'Procure por nome do produto',
        submit: 'Adicionar',
      },
      featuredPromotion: {
        lastSync: 'Última atualização: ',
        seeMore: 'Saiba mais',
        share: 'Compartilhar',
      },
      feedbackModal: {
        back: 'Voltar',
        channel: 'Conte-nos como ela chegou a você',
        continue: 'Próximo',
        dateCannotBeInPast: 'Você não pode marcar um atendimento numa data do passado.',
        dateMustBeSelected: 'É necessário escolher uma data para marcar o agendamento.',
        doNotForgetTheScheduling: 'Não esqueça de falar com ela!',
        finish: 'Concluir',
        finishedOrderFn: (orderCode: string) => `Você finalizou o pedido ${orderCode}!`,
        giveFeedback: (name: string) => `Dê o feedback para ${name}`,
        information: 'Que informação ela pediu?',
        leaveNote: 'Deixe uma observação',
        maxLengthNote: (maxLength: number) => `O limite máximo é de ${maxLength} caracteres`,
        notAllowedWords: 'Não utilize palavras inapropriadas',
        noteLabel: (required: boolean) => `Deixe uma observação${required ? '' : ' (opcional)'}`,
        orderFn: (orderCode: string) => `Pedido ${orderCode}`,
        reason: 'Motivo',
        requiredNote: 'A observação é obrigatória',
        selectOption: 'Selecione uma opção',
        statusTitle: 'Selecionar o tipo do atendimento realizado',
        subReason: 'Motivo',
        validators: {
          checkbox: { required: 'Campo obrigatório, por favor escolha uma das opções.' },
        },
      },
      ganheMais: {
        currentTier: 'Faixa atual',
        currentValue: 'Valor acumulado',
        forNextTier: 'Para atingir a próxima faixa',
        noData: 'Não foram encontradas informações do ganhe mais',
        remainingValue: (value: string) => `Faltam ${value}`,
        title: 'Ganhe mais',
      },
      generalPromotions: {
        exclusiveTitleFn: (name: string) => `PROMOÇÕES EXCLUSIVAS PARA ${name}`,
        generalTitleFn: (name: string) => `PROMOÇÕES GERAIS PARA ${name}`,
        promotionNotFound: 'Nenhuma promoção encontrada',
        seeMore: 'Ver mais promoções',
      },
      giftModal: {
        addToCard: 'Adicionar ao pedido',
        allowedGiftsFn: (giftsAmount: number) =>
          `Você pode escolher até ${giftsAmount.toString()} brinde${pluralizeS(giftsAmount)}`,
        giftAdded: 'Brinde(s) adicionado(s) com sucesso!',
        guaranteedNoProdcutsAvailable:
          'Estamos com estoque insuficiente, mas você ainda deve escolher os produtos para que sejam incorporados numa próxima compra.',
        notGuaranteedBackToCheckout: 'Voltar à finalização da compra',
        notGuaranteedNoProductsAvailable: 'Infelizmente os estoques acabaram e não serão repostos :(',
        selectGift: 'Selecione um brinde',
      },
      goalAdditionalBody: {
        cycleGoal: 'Meta do ciclo',
        todayGoal: 'Meta até hoje',
      },
      goalProgressBar: {
        goalReached: 'Uau! Você ultrapassou sua meta de hoje! Continue assim!',
        missingReseller: (parsedRemainingGoal: string) => `Falta ${parsedRemainingGoal} para atingir sua meta hoje`,
        missingResellers: (parsedRemainingGoal: string) => `Faltam ${parsedRemainingGoal} para atingir sua meta hoje.`,
      },
      header: {
        searchByResellerDocument: 'Busque por CPF ou RG',
        searchByResellerNameOrCode: 'Busque por código ou nome',
      },
      home: {
        current: (currentId: string) => `Ciclo ${currentId}`,
        duration: ({ start, end }: CycleVariables) => `${start} à ${end}`,
        feedbackSent: 'Feedback enviado!',
        feedbackSentButNoScoreChanges: `${reseller} consultada! Ela ainda pode aparecer para atendimento. `,
        note: (name: string) => `Por ${name}`,
        noteWithMessage: ({ name, note }: CycleNoteVariables) => `${note} - Por ${name}`,
      },
      importCS: {
        dragNDropMessage: 'Arraste aqui seu arquivo CSV ou',
        uploadZoneButtonMessage: 'Selecione',
        uploadZoneErrorMessage: 'Formato de arquivo incorreto. Favor selecione um arquivo CSV',
        uploadZoneMessage: 'Solte aqui',
      },
      indicators: {
        activeResellerInStructure: 'Revendedores ativos na estrutura:',
        allFeedbacks: 'Total de tabulações:',
        boughtFeedbacks: 'Vendas realizadas:',
        boughtFeedbacksRanking: 'Ranking de vendas',
        category: 'Motivo',
        count: 'Quantidade',
        errorMessage: 'Tivemos um probleminha...',
        feedback: 'Submotivo',
        feedbackPerDay: 'Tabulações por dia:',
        feedbackRanking: 'Ranking de motivos',
        feedbackStatusCount: 'Quantidade de tabulações em cada status:',
        noData: 'Sem dados neste ciclo',
        percentage: 'Porcentagem',
        position: 'Posição',
        ranking: (rank: number) => `Você está na colocação ${rank}`,
        recoveryCount: 'Revendededoras no status I6 que fizeram pedido no ciclo:',
        resellerAttendedCount: 'Quantidade de REs atendidas em cada status:',
        resellerInStructure: 'Revendedores na estrutura:',
        resellerStatusCount: 'Quantidade de REs em cada status:',
        sellCount: 'Quantidade de vendas',
        title: 'Indicadores',
        update: 'Atualizar',
      },
      infoCell: {
        giftsChosen: 'Brinde(s) escolhido(s)',
        unavailableGifts: 'Brindes indisponíveis',
      },
      irResellerDetailSection: {
        activationChannel: 'Canal de ativação',
        activationChannelNotFound: 'Canal de ativação não encontrado',
        address: 'Endereço',
        csName: 'CS',
        csNotFound: 'CS não encontrada.',
        currentCredit: 'Crédito atual',
        distance: 'Distancia da ER',
        distanceFn: (distance: number, zone: string) =>
          `${parseNumberValue(distance, false, 2)} KM da ER (Zona ${zone})`,
        distanceNotFound: 'Distância indisponível',
        erName: 'Nome do ER',
        erNotFound: 'ER não encontrado.',
        noActualCredit: 'Não possui crédito',
        registerCycle: 'Ciclo de cadastro',
        registerCycleNotFound: 'Ciclo de cadastro não encontrado',
        registrationDate: 'Data de cadastro',
        registrationDateNotFound: 'Data de cadastro não encontrada',
        resellerStateName: 'Estado',
        resellerStateNotFound: 'Estado indisponível',
      },
      lastOrder: {
        noOrderFound: 'Nenhum pedido encontrado.',
        orders: 'Pedidos',
        ordersHistory: 'Ver histórico de pedidos',
      },
      listScript: {
        step: 'Passo',
      },
      lister: {
        cpNotFound:
          'Nenhuma franquia foi encontrada com os dados informados. Certifique-se que o código está correto e tente novamente.',
        csDetailSearch: 'Não foi encontrada nenhuma informação dessa Central de Serviço',
        csSearch:
          'Nenhuma Central de Serviço foi encontrada com os dados informados. Certifique-se que o código está correto e tente novamente.',
        newsSearch: 'Nenhuma notícia foi encontrada com os dados informados',
        promotionSearch: 'Nenhuma promoção foi encontrada com os dados informados',
      },
      login: {
        outOfService: 'O servidor para estar fora do ar, por favor contate o suporte.',
        permissionDenied: 'Você não tem permissão para acessar essa seção',
      },
      messageNotifications: {
        browserMessageNotification: {
          body: (name: string) => `Você recebeu uma mensagem de ${name}`,
          title: 'Mensagem recebida',
        },
        error: 'Não foi possível carregar suas mensagens',
        expired: 'Expirado',
        noInteractions: 'Sua caixa de entrada está vazia',
        title: 'Whatsapp',
        tryAgain: 'Tentar novamente',
      },
      mostRecentFeedback: {
        feedback: 'Feedback ',
        notFound: `A ${reseller} não possui feedbacks`,
        notes: 'Feedbacks',
        showMore: 'Mostrar mais feedbacks',
      },
      multipleForms: {
        confirmPayment: {
          back: 'Voltar',
          boletoDetail: {
            attention: 'Atenção:',
            consumesCredit: ' o plano selecionado consome créditos',
            dueDate: (dueDate) => `Vencimento ${format(dueDate, 'dd/MM/yyyy')}`,
            selectedOption: 'Opção selecionada',
            title: 'Boleto',
            value: 'Valor',
            valueSelected: 'Valor atribuido ao boleto: ',
          },
          confirm: 'Confirmar pagamento',
          creditCardDetail: {
            amount: 'Valor atribuido',
            cardNumber: 'Número do cartão',
            cardNumberInvalid: 'Número do cartão inválido',
            cpf: 'CPF do titular',
            cpfInvalid: 'CPF inválido',
            expiration: 'Validade',
            expirationInvalid: 'Data inválida',
            flag: 'Bandeira',
            installment: 'Parcelamento',
            name: 'Nome impresso no cartão',
            phone: 'Telefone para contato',
            phoneInvalid: 'Número de telefone inválido',
            required: 'Campo obrigatório',
            securityCode: 'Código de segurança (CVV)',
          },
          linkTitle: 'Alterar forma de pagamento',
          subtitle: 'Verifique as Informações e preencha os dados necessarios para finalizar o pedido.',
          summary: 'Resumo',
          title: 'Confirmação do pagamento',
        },
        errors: {
          boleto: 'Por favor, selecione a opção de parcelamento do boleto.',
          creditCard: 'Por favor, selecione a bandeira do Cartão',
          filledValue: 'Por favor, distribua o valor total entre as formas de pagamento selecioadas.',
          finish: 'Não foi possível finalizar o pedido, por favor tente novamente',
          sendBoleto: 'Ocorreu um erro na operação de pagamento do cartão de crédito',
          sendCreditCard: 'Ocorreu um erro na operação de pagamento do cartão de crédito',
        },
        header: {
          filledValue: 'Valor preenchido',
          payValue: 'Valor a ser pago',
          remainValue: 'Valor restante',
        },
        paymentMethodSelector: {
          addBoleto: 'Incluir boleto',
          addCreditCard: (cardQuantity) => `Incluir ${cardQuantity > 0 ? 'outro' : ''} cartão`,
          adminTax: (adminTax: string) => `A opção de pagamento selecionada possui uma taxa de ${adminTax}`,
          boleto: 'Boleto',
          boletoAvailableCredit: (credit) => `Crédito: ${credit}`,
          confirm: 'Continuar',
          creditCard: 'Cartão de crédito',
          creditCardDescription: 'Você poderá adicionar até 3 cartões. Os dados serão preenchidos na proxima etapa.',
          creditCardLabel: (cardNumber) => `Cartão ${cardNumber}`,
          flagPlaceholder: 'Bandeira',
          installmentPlaceholder: 'Parcelamento',
          subtitle:
            'Escolha como deseja pagar, distribuíndo o valor total as opções disponíveis. Você poderá combinar duas opções diferentes e incluir até 3 cartões',
          title: 'Múltiplas formas',
        },
        summary: {
          installments: (number: string, value: string, date?: string) =>
            `Parcela ${number} - valor: ${value}${date ? ` (vencimento ${date})` : ''}`,
          paymentTitle: (name: string, detail: string) => `${name} - ${detail}`,
          title: 'Revisão',
        },
      },
      news: {
        criticalityLevel: 'Nível de criticidade: ',
        details: 'Detalhes',
        environment: 'Projeto: ',
        lastNews: 'Últimas notícias',
        noReferences: 'não há referencias',
        references: 'Referencias: ',
      },
      order: {
        creditCardUnknownError: 'Ocorreu um erro na operação de pagamento do cartão de crédito',
      },
      orderCell: {
        cycle: 'Ciclo',
        orderNumberFn: (number: string) => `Pedido n˚ ${number}`,
        orders: 'Pedidos',
        redirectFn: (resellerId: string, orderId: string) =>
          `${AppPath.Activation.Reseller.Order}?resellerId=${resellerId}&orderId=${orderId}`,
        seeDetails: 'Ver detalhes',
      },
      orderComposition: {
        buyMoreSite: 'Compra mais no site',
        buyMoreStore: 'Compra mais na loja',
        didntBought: 'Não efetuou nenhuma compra',
        notFound: 'Não há informações dos pedidos',
        paymentFrequency: (data) => `${data.toString()}%`,
        paymentMethod: 'Forma de pagamento',
        shopLabel: 'Loja',
        siteLabel: 'Site',
        title: 'Composição de pedidos',
      },
      orderConfirmationCell: {
        cellTitle: 'Mensagem para revisão',
      },
      orderDetail: {
        bought: 'Produtos Comprados',
        cut: 'Produtos cortados',
        detail: 'Detalhe do pedido',
        payments: 'Pagamentos',
        prize: 'Produtos brinde',
        title: 'Título',
      },
      orderInfo: {
        availableCredit: (credit: string) => `Seu limite é de R$ ${credit}`,
        boletoDetail: (installments: number, amount: string, dueDate: string) =>
          `Parc. ${installments} ${amount}, vencimento ${dueDate}`,
        boletoMethod: (name: string, amount: string) => `${name} - ${amount}`,
        comboProductItemFn: (quantity: number, name: string, code: string) => `   ${quantity}x ${code} -${name}`,
        conqueredPrizes: 'Brindes conquistados',
        copyMessage: 'Copiar mensagem',
        creditCardDetail: (installments: number, amount: string) => `${installments}x ${amount}`,
        creditCardFlag: 'Bandeira do cartão: ',
        creditCardMethod: (name: string, flag: string, cardNumber: string) => `${name} - ${flag} ${cardNumber}`,
        cutProductItemFn: (item: CartCutProductItem) =>
          `${item.quantity}x ${item.code} - ${item.name} - Motivo do corte: ${item.cutReason}`,
        cutProducts: 'Produtos cortados:',
        cycle: (cycleNumber: number) => `Pedido Ciclo ${cycleNumber}`,
        delivery: 'Endereço para entrega/retirada',
        discounts: 'Promoções conquistadas:',
        donation: 'Doação',
        endConversation: 'Finalizar atendimento',
        eudoraEmailMessage:
          'Você receberá em seu e-mail “Eudora quer ouvir você”, se possível participe da nossa pesquisa. Sucesso e ótimas vendas!',
        eudoraMeuClube: (clubeBotiCateogry: string, remainValueNextCateogry: string, nextCategory: string) =>
          `Revendedora nível: ${clubeBotiCateogry} - Faltam ${remainValueNextCateogry} para A faixa ${nextCategory}`,
        externalPaymentExpireDate: (date: string) => `Link expira em ${date}`,
        externalPaymentLink: 'Seu link de pagamento externo:',
        inCaseOfDivergence: `Caso exista alguma divergência, entre em contato com sua ${supervisor} ou procure o seu espaço do revendedor para maiores detalhes.`,
        installment: (number: string, value: string, date?: string) =>
          `Parcela ${number} - valor: ${value}${date ? ` (vencimento ${date})` : ''}`,
        namedConfirm: (name: string, isEditing: boolean) =>
          `${name} por favor, confirme ${isEditing ? 'a edição do pedido' : 'o seu pedido'}:`,
        notChosen: 'Não escolhido',
        orderCodeFn: (order: string, isEditing: boolean) =>
          `Número do pedido: ${order}${isEditing ? ' - editado' : ''}`,
        orderConfirmed: 'Seu pedido foi realizado!',
        orderEdited: 'Edição de pedido realizada com sucesso!',
        orderPending: 'Pendências do pedido:',
        orderStatus: 'Status do pedido:',
        payment: 'TOTAL A PAGAR',
        paymentInfoFn: (price: string, amount: number, installment: string) =>
          `${price} (${amount}x de ${installment})`,
        paymentType: 'FORMA DE PAGAMENTO',
        pleaseConfirmFn: (isEditing: boolean) =>
          `Oi! Por favor, confirme ${isEditing ? 'a edição do pedido' : 'o seu pedido'}:`,
        prizeItemFn: (quantity: number, name: string, note: string) => `${quantity}x ${name}, ${note}`,
        prizes: 'Brindes:',
        productItemFn: (isGift: boolean, quantity: number, code: string, name: string, price: string) =>
          `${quantity}x ${code} - ${name} - ${isGift ? 'Doação' : `${price}/cada`}`,
        products: 'Produtos: Confira produtos e quantidades',
        profitFn: (profit: string) => `Seu lucro é de ${profit}`,
        resellerApp: {
          negative: 'Revendedora não tem o App',
          positive: 'Revendedora tem o App',
        },
        resellerCredit: (resellerCredit: string, usedCredit: string) =>
          `Crédito com tolerância R$${resellerCredit} - Conta corrente: ${usedCredit}`,
        resellerInfo: (resellerName: string, resellerId: string, resellerStatus: string, resellerCredit: string) =>
          `Nome: ${resellerName ?? '_'} - Id: ${resellerId ?? '_'} - Status: ${resellerStatus ?? '_'} - Crédito: ${
            resellerCredit ?? '_'
          }`,
        resellerInfoEudora: (resellerName: string, resellerId: string) =>
          `Nome: ${resellerName ?? '_'} - Id: ${resellerId ?? '_'}`,
      },
      orderStatus: {
        orderPending: 'Pendências do pedido:',
        status: 'Status do pedido:',
      },
      orderTotal: {
        availableCredit: 'Crédito com tolerância',
        priceWithoutDiscount: 'Preço sem desconto',
        prizesFn: (parsedAmountToNextPrize: string, prizesWonOnNextBonus: number) =>
          `Mais ${parsedAmountToNextPrize} e ela ganha mais ${prizesWonOnNextBonus} brinde${pluralizeS(
            prizesWonOnNextBonus,
          )}!`,
      },
      ordersHistory: {
        title: 'Histórico de pedidos',
      },
      ordersPage: {
        boletosHistory: 'Histórico de boletos',
        ordersHistory: 'Histórico de pedidos',
        title: 'Histórico de pedidos',
      },
      paSelectModal: {
        error: 'Erro ao selecionar Pa',
        placeholder: 'Busque a sua PA',
      },
      pdfPreviw: {
        alternativeLink: 'Clique aqui para abrir o documento em uma nova aba',
        notAvailable: 'Não é possível visualizar o documento.',
      },
      potentialBoleto: {
        potential: (data: string) => `Boleto potencial: ${data}`,
        title: 'Boleto atual',
      },
      productCell: {
        areYouSure: 'Você tem certeza que deseja remover este item?',
        cancel: 'Cancelar',
        comboDetail: 'Ver detalhes do combo',
        gift: 'Gratuito',
        magazine: 'Revista',
        quantity: (quantity: number) => `${quantity} unid.`,
        remove: 'Remover',
        total: 'Total a pagar',
      },
      productGiftCell: {
        outOfStock: 'Estoque insuficiente',
      },
      productListCell: {
        cellTitle: 'Produtos',
        linkTitle: 'Modificar pedido',
      },
      productOrDiscountModal: {
        discount: 'Desconto',
        discountOrPromotions: 'Promoções ou descontos',
        gift: 'Brinde',
        selectOption: 'Escolher prêmio',
        whatDoYouPrefer: 'O que você prefere?',
      },
      productSelection: {
        selectedQuantityFn: (selectedQuantity: number, maxQuantity: number) =>
          `${selectedQuantity} de ${maxQuantity} selecionado${pluralizeS(selectedQuantity)}`,
      },
      productsList: {
        amount: 'Qtd.',
        value: 'Valor',
      },
      productsSpreadsheetModal: {
        cancel: 'Cancelar',
        productCode: 'Código do produto',
        quantity: 'Quantidade',
        required: 'O upload da planilha é obrigatório',
        submit: 'Confirmar',
        title: 'Adicionar planilha de itens',
      },
      promotion: {
        seeMore: 'Ver mais',
        share: 'Compartilhar',
      },
      promotionDescription: {
        period: 'Período da promoção',
        share: 'Compartilhar promoção',
        shareGMail: 'Compartilhar via Gmail',
        sharePromotion: 'Compartilhar promoção',
        shareWhatsapp: 'Compartilhar via WhatsApp',
      },
      promotionDetail: {
        promotionPeriod: 'Período da promoção',
        sharePromotion: 'Compartilhar promoção',
      },
      promotionalCode: {
        addCoupon: 'Adicionar',
        added: 'Cupom adicionado com sucesso',
        coupon: 'Cupom de desconto',
        couponAdded: 'Cupom inserido',
        description: 'Caso tenha um cupom, insira no campo abaixo e clique em adicionar.',
        error: 'Erro',
        length: 'O cupom de desconto deve ter 17 caracteres',
        numberOnly: 'O cupom de desconto possui somente números',
        placeholder: 'Insira o cupom',
        removed: 'Cupom removido com sucesso',
        success: 'Sucesso',
      },
      promotions: {
        createCustomCardButton: 'Criar card personalizado',
        createCustomCardMessage: `Agora você pode criar os próprios cards promocionais para enviar promoções já existentes às ${reseller}s!`,
        createCustomCardTitle: 'CARDS PERSONALIZADOS',
      },
      purchaseDetails: {
        createLink: 'Criar link',
        createLinkSucess: 'Link criado e enviado por e-mail',
        externalPaymentLink: 'Link de pagamento',
        paymentLinkExpiration: (date: string) => `Link válido até ${date}`,
        sendLink: 'Enviar link via email',
        title: 'Detalhe do pedido',
        update: 'Atualizar',
      },
      purchaseHistory: {
        noBuyingHistory: `A ${reseller} não possui histórico de compras`,
        title: 'Histórico de compras',
      },
      queue: {
        conversationEnded: 'Atendimento encerrado com sucesso.',
        endConversation: 'Finalizar atendimento',
        limitReached:
          'Não foi possível abrir um atendimento. Você atingiu o limite máximo, finalize alguns atendimentos para poder abrir mais',
        startConversation: 'Iniciar novo atendimento',
      },
      recuperationOnCycle: {
        resellers: `${reseller}s`,
      },
      registerData: {
        birthdate: 'Data de nascimento',
        birthdateNotFound: 'Data de nascimento não cadastrada',
        cancel: 'Cancelar',
        cpf: 'CPF',
        cpfNotFound: 'CPF não cadastrado',
        dataNotFound: 'Não há informações pessoais cadastradas',
        edit: 'Editar',
        nickname: 'Apelido',
        nicknameNotFound: 'Apelino não cadastrado',
        required: 'Campo obrigatório',
        rg: 'RG',
        rgNotFound: 'RG não cadastrado',
        save: 'Salvar',
        sex: 'Sexo',
        sexNotFound: 'Sexo não cadastrado',
        title: 'Informações cadastrais',
      },
      resellerInfo: {
        Preferences: 'Canais',
        PreferencesNoData: 'Canais não encontrados',
        blockedFields: {
          date: 'Data do bloqueio',
          description: 'Informações adicionais',
          info: 'Motivo do bloqueio',
        },
        blockedFn: (isBlocked: boolean) => (isBlocked ? 'Bloqueada ' : 'Não bloqueada'),
        blockedNotFound: 'Informação sobre bloqueio não encontrada',
        boletoInstallments: 'Parcelamento no Boleto',
        boletoInstallmentsNotFound: 'Informação sobre parcelamento por boleto não encontrada',
        boughtLast7Cicles: 'Nos últimos 7 ciclos realizou compras no: ',
        boughtLastCicle: 'No último ciclo realizou compras no: ',
        ccrStatment: {
          date: 'Data de registro',
          noData: 'Extrato vazio',
          orderCredit: 'Pedido',
          origin: 'Origem',
          title: 'Extrato CCR',
          type: 'Tipo do crédito',
          usedAt: 'Utilizado em',
          value: 'Valor',
        },
        ceasedFields: {
          date: 'Data do cessamento',
          description: 'Informações adicionais',
          info: 'Motivo do cessamento',
        },
        ceasedFn: (isCeased: boolean) => (isCeased ? 'Cessada ' : 'Não cessada'),
        ceasedNotFound: 'Informação sobre cessamento não encontrada',
        dataTitle: 'Dados da revendedora',
        engagement: 'Engajamento ER: ',
        favChannel: 'Canal favorito: ',
        firstOrderCycle: 'Ciclo do primeiro pedido',
        firstOrderStructure: 'Estrutura do primeiro pedido',
        negotiatingFn: (isNegotiating: boolean) => (isNegotiating ? 'Negociando ' : 'Não está negociando'),
        negotiatingNotFound: 'Informação sobre negociação não encontrada',
        nextTierValue: 'Para a próxima faixa: ',
        notBoughtLast7Cicles: 'Não realizou compras nos ultimos 7 ciclos',
        notBoughtLastCicle: 'Não realizou compras no ultimo ciclo',
        paymentMethodsNoData: 'Formas de pagamento não encontradas',
        paymentPlan: 'Formas de pagamento',
        paymentPlanNotFound: 'Informação sobre formas de pagamento não encontrada',
        registerDate: 'Data de cadastro',
        resellerNoData: 'Dados da revendedora não encontrados',
        sector: 'Setor',
        sectorCode: 'Código setor',
        situation: 'Situação',
        situationNoData: 'Situação não encontrada',
        subSector: 'Subsetor',
        subSectorCode: 'Código subsetor',
        subSectorResponsible: 'Responsável pelo subsetor',
        vipTier: 'Faixa VIP: ',
      },
      rewardsToChooseModal: {
        discountSelected: 'Desconto aplicado!',
        giftSelected: 'Brinde(s) adicionado(s) com sucesso!',
        notEnoughProductsFn: (missingQuantity: number) =>
          `Ainda resta selecionar ${missingQuantity} brinde${pluralizeS(missingQuantity)}`,
      },
      scheduleList: {
        attendedFn: (hour: string) => `Atendida às ${hour}`,
        cancel: 'Desmarcar',
        notAttended: 'Não atendida',
        reschedule: 'Reagendar',
      },
      scheduleNotifications: {
        confirmDelete: 'Tem certeza que deseja desmarcar este agendamento?',
        deleteSchedule: 'Desmarcar',
        error: 'Não foi possível carregar seus agendamentos',
        goBack: 'Voltar',
        nothingScheduled: 'Sem agendamentos por hoje! :)',
        nothingScheduledFuture: 'Sem agendamentos nos próximos dias! :)',
        rescheduleSuccessFn: (rescheduledTo: string) => `Atendimento reagendado: ${rescheduledTo}`,
        schedulesOn: 'Agendamentos para',
        seeNextSchedules: 'Ver agendamentos dos próximos dias',
        successfullyCanceled: 'Agendamento desmarcado com sucesso',
        titleFn: (number: number) =>
          number === 0 ? 'Hoje - nenhum agendamento' : `Hoje - ${number} agendamento${pluralizeS(number)}`,
        tomorrow: 'AMANHÃ',
        tryAgain: 'Tentar novamente',
      },
      searchProducts: {
        error: 'Ocorreu um erro durante a busca de produtos',
        productsNotFound: 'Nenhum produto encontrado. Verifique se o nome do produto está correto',
        searchProduct: 'Procure por nome do produto',
        sideMenuTitle: 'Busca por nome',
        startSearching: 'Consulte os produtos pelo nome',
        title: 'Busca de produtos por nome',
      },
      serviceCell: {
        active: 'Ativo',
        attendant: 'Atendente:',
        feedback: 'Feedback',
        observation: 'Observação',
        owner: (id: string) => `Atendido por: ${id}`,
        receptive: 'Receptivo',
      },
      serviceHistory: {
        all: 'Todos',
        cycle: (cycleId: string) => `Ciclo ${cycleId ?? 'não encontrado'}`,
        datePeriod: (startDate: string, endDate: string) => `De ${startDate} até ${endDate}`,
        finished: 'Finalizado',
        ongoing: 'Em andamento',
        periodPicker: {
          button: 'Pesquisar',
          end: 'Fim',
          start: 'Início',
        },
        pickAnotherDate: 'Escolher outra data',
      },
      serviceHistoryAttendance: {
        activeAttendances: 'Atendimentos Ativos',
        cycleAttendances: 'Atendimentos do ciclo',
        receptiveAttendances: 'Atendimentos Receptivos',
        totalAttendance: 'Revendededoras Atendidas',
      },
      serviceHistoryCell: {
        active: 'Ativo',
        noteFn: (note: string) => `Observação: ${note}`,
        receptive: 'Receptivo',
      },

      shareButton: {
        downloadFile: 'Baixar arquivo',
        fileWhatsapp: 'Compartilhar arquivo via WhatsApp',
        linkGmail: 'Compartilhar pelo Gmail',
        linkWhatsapp: 'Compartilhar link via WhatsApp',
        share: {
          error: 'Algo deu errado, tente reenviar',
          sucessWhatsapp: 'Arquivo enviado com sucesso',
        },
        title: 'Compartilhar',
      },

      starts: {
        attendanceHeader: {
          birthDate: 'Data de nascimento:',
          cash: 'R$',
          city: 'Cidade',
          contactButton: 'Entrar em contato',
          cp: 'CP',
          cpf: 'CPF:',
          credit: ' Crédito',
          cs: 'CS',
          fraudRisk: 'Risco de fraude:',
          noData: 'Não foi possível carregar os dados da IR.',
          noFraudRisk: 'Não disponível',
          orderButton: 'Fazer pedido',
          registrationDate: (registartionDate: string) => `(registado em: ${registartionDate})`,
          score: 'Risco:',
          status: 'Situação:',
        },
        attendanceProvider: {
          pauseSucess: 'Pausa retirada',
        },
        attendanceSearchModal: {
          button: 'Voltar',
          title: 'Atenção',
          warning: 'Para continuar favor buscar o revendedor(a)',
        },
        checklist: {
          csInfo: 'Nenhuma informação da franquia disponível',
        },
        filter: {
          button: 'Buscar',
          reseller90Days: 'Revendedora cadastrada a mais de 90 dias',
          resellerSearch: 'Buscar revendedora',
          resellerSearchError: 'Não foi possível encontrar a revendedora',
        },
        footer: {
          attendanceByWhatsapp: 'Atender por Whatsapp',
          conversationError: 'Erro ao enviar o feedback',
          dialerTransfer: 'Efetuando transferência',
          endAttendance: 'Finalizar atendimento',
          logout: 'Sair da PA',
          logoutConfirmation: 'Deseja mesmo sair da Posição de atendimento?',
          modalButton: 'Confirmar',
          modalTitle: 'Iniciar atendimento',
          pauseAttendance: 'Pausar atendimento',
          redirectToAntiFraud: 'Redirecionar para Anti-Fraude',
          startAttendance: 'Iniciar atendimento',
          textLabel: 'Informe sua PA',
          validators: {
            numbersOnly: 'Preencha somente com números',
            required: 'Campo obrigatório',
          },
          warning: 'Confirmação',
        },
        home: {
          backToHome: 'Voltar para a tela inicial',
          orderMachine: 'Máquina de pedidos',
          searchByResellerNameOrCodeOrCPF: 'Busque por CPF ou RG',
          tabs: {
            checklist: 'Checklist da Franquia',
            documents: 'Documentos',
            irData: 'Dados da IR',
            promotions: 'Promoções',
            script: 'Script',
          },
        },
        irData: {
          active: 'Ativo',
          contactInfo: {
            birthDate: 'Data de nascimento',
            cancelButton: 'Cancelar',
            cep: 'CEP',
            cpf: 'CPF',
            editContactInfoButton: 'Editar informações',
            email: 'Email',
            errorMessage: 'Erro ao editar as informações',
            name: 'Nome',
            phone: {
              contact: 'Telefone de contato',
              mobile: 'Telefone celular',
              residential: 'Telefone residencial',
            },
            residentialAddress: 'Endereço residencial',
            saveInformation: 'Salvar informações',
            successMessage: 'Informações editadas',
            title: 'Informações de contato',
          },
          conversationButton: 'Ver mais conversas',
          conversations: 'Histórico de conversas',
          deliveryAdress: {
            address: 'Endereço',
            addressNumber: 'Número',
            cancelButton: 'Cancelar',
            cep: 'CEP',
            city: 'Cidade',
            complement: 'Complemento',
            editDeliveryAddress: 'Editar endereço de entrega',
            errorMessage: 'Erro ao editar as informações',
            ladmark: 'Ponto de referência',
            neighborhood: 'Bairro',
            saveInformation: 'Salvar informações',
            state: 'Estado',
            streetName: 'Rua',
            successMessage: 'Endereço de entrega atualizado com sucesso.',
            title: 'Endereço de entrega',
            validators: {
              cepOnly: 'Preencha somente com um CEP válido',
              neighborhoodLength: 'Nome com caracteres excessivos',
              numbersOnly: 'Preencha somente com números',
            },
          },
          latestConversation: 'Última conversa',
          lister: {
            noData: 'Não foi possível carregar os dados da IR.',
          },
          receptive: 'Receptivo',
        },
        irNotFound: {
          call: 'Buscando por uma intenção de revenda.',
        },
        pauseModal: {
          flashMessage: 'Pausa confirmada após finalização do atendimento',
          modalButton: 'Confirmar',
          title: 'Escolha o motivo',
          validators: {
            required: 'Campo obrigatório',
          },
        },
      },

      toBeCeased: {
        resellers: `${reseller}s`,
      },
      topCategory: {
        additionalInfo: 'Info Adicional:',
        campainFocus: 'Campanha foco: ',
        firstCategory: 'Top Categoria',
        goal: 'Objetivo:',
        secondCategory: 'Top Categoria 2',
        theme: 'Temática:',
        title: 'Estratégia CRM',
      },
      topProducts: {
        productsNotFound: `A ${reseller} não comprou produtos`,
        title: 'Produtos que ela mais compra',
      },
      whatsappAttendancePage: {
        searchByResellerNameOrCodeOrCPF: 'Busque por CPF ou RG',
      },
      whatsappAttendanceSearch: {
        reseller90Days: 'Revendedora cadastrada a mais de 90 dias',
        searchError: 'Nao foi possivel encontrar a revendedora',
      },
    },
    error: {
      GraphQLClient: {
        BadGateway: 'O servidor parece estar fora do ar. Tente novamente mais tarde ou contate o suporte.',
        Connection: 'Erro de conexão com o servidor. Verifique a sua conexão com a internet e tente novamente.',
        Timeout:
          'O servidor demorou muito para responder. Verifique a sua conexão com a internet, caso ela esteja normal, entre em contato o suporte ou tente novamente mais tarde.',
        Unauthorized: 'A sua sessão expirou. Por favor tente sair e entrar novamente no site.',
        Unexpected: 'Um erro inesperado aconteceu. Contate o suporte e tente novamente mais tarde.',
      },
      base: 'Ocorreu um erro.',
      order: {
        resetContent: 'Ocorreu um problema com o pedido e ele precisa ser reiniciado.',
      },
      refreshPage: 'Por favor recarregue a página e tente novamente',
      tryAgain: 'Tentar novamente',
    },
    filter: {
      order: {
        ascendent: 'A0 a I6',
        descendent: 'I6 a A0',
        label: 'Ciclo de atividade',
      },
    },
    general: {
      cancel: 'Cancelar',
      notFound: 'Nenhum resultado encontrado.',
      send: 'Enviar',
    },
    header: {
      backgroundImage: 'assets/img/bot-img-background.png',
      privacyPolicy: 'Política de Privacidade',
    },
    irDigital: {
      dragAndDrop: 'Solte aqui',
      endConversationSuccess: 'Atendimento finalizado com sucesso.',
    },
    login: {
      button: 'Login',
      code: `Cód. ${capitalize(supervisor)}`,
      forgotPassword: 'Esqueci minha senha',
      warnings: {
        noCode: 'O seu código é obrigatório.',
        noPassword: 'Por favor, digite sua senha do GERA.',
      },
    },
    register: {
      documents: {
        documentType: {
          individualResellerTerm: `Termos de revenda individual assinado pela ${reseller}`,
        },
      },
      indication: `Se você recebeu esse cadastro a partir de uma indicação informe o código de ${supervisor} de quem o indicou ou informe o seu código de ${supervisor}.`,
      lead: {
        form: {
          name: `Nome da ${reseller}`,
        },
      },
    },
    whatsapp: {
      download: 'Baixar documento',
      dragAndDrop: 'Solte aqui',
      error: {
        dowloadMedia: 'Houve um erro no download do arquivo',
        sendMessage: 'Houve um erro no envio da mensagem. Por favor, tente novamente.',
        upload: 'Tivemos um erro ao carregar o arquivo enviado. Por favor, verifique o formato e tente novamente.',
        uploadMedia: 'Houve um erro no envio do arquivo',
      },
      medias: {
        back: 'Voltar',
        download: 'Baixar',
        fileType: 'Tipo de arquivo:',
        localMedias: 'Mídias locais',
        medias: 'Mídias',
        noData: 'Não há mídias públicas disponíveis',
        preview: 'Preview',
        publicMedias: 'Mídias públicas',
        save: 'Salvar',
        select: 'Selecionar',
        upload: 'Subir',
      },
      status: {
        deleted: 'Mensagem removida',
        failed: 'Erro no envio da mensagem',
      },
      templates: {
        back: 'Voltar',
        document: 'Arquivo pdf',
        fieldNameFn: (messageSection: string, index: number) => `${messageSection}.${index}`,
        image: 'Arquivo de imagem',
        link: 'link',
        message: 'Mensagem',
        name: 'Nome',
        noData: 'Não existe templates disponíveis no momento',
        noTemplateSelected: 'Nenhum template selecionado',
        requiredField: 'O campo não pode estar vazio',
        select: 'Selecionar',
        selectLabel: 'Templates de mensagens',
        selectPlaceholder: 'Template',
        sendMessage: 'Enviar mensagem',
        successSend: 'Mensagem enviada com sucesso',
        textVariableLabel: (variableKey: string) => `Variável de texto ${variableKey}`,
        title: 'Enviar mensagem utilizando template',
        variableTypeTitleFn: (type: string, buttonIndex?: number) =>
          `Variáveis do ${type} ${buttonIndex ? `- ${buttonIndex}` : ''}`,
        video: 'Arquivo de vídeo',
      },
      toolbar: {
        blockedMessage:
          'O envio de mensagens está bloqueado, pois não houve retorno dentro de 24hrs. Você ainda pode entrar em contato por telefone',
        sendTemplateMessage: 'Enviar mensagem template',
      },
    },
  };
};

const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1);
// const pluralizeM = (quantity: number) => (quantity > 1 ? 'm' : '');
