import { Service } from 'typedi';

import { FlashMessageType } from '@atomic/mol.flash-message';

export interface FlashMessageData {
  fullMessage: Message;
  type: FlashMessageType;
  time: Date;
  additionalSpacing?: string;
}

interface Message {
  message: string;
  error?: string;
  additionalInfo?: string;
}

export interface FlashDispatcherListener {
  onReceiveMessage: (data: FlashMessageData) => void;
}

/**
 * This class is used to dispatch a message to notify its listener that something
 * wants to show a flash message.
 * The dispatched messaged is caught by FlashWrapper (@see FlashWrapper)
 */
@Service()
class FlashDispatcherService {
  private listener: FlashDispatcherListener;

  setListener(listener: FlashDispatcherListener) {
    this.listener = listener;
  }

  dispatchMessage(fullMessage: Message, type: FlashMessageType, additionalSpacing?: string) {
    if (this.listener) {
      this.listener.onReceiveMessage({
        additionalSpacing: additionalSpacing ? additionalSpacing : '0',
        fullMessage,
        time: new Date(),
        type,
      });

      if (['alert', 'warning'].includes(type) && window?.newrelic) {
        window?.newrelic?.addPageAction('notification', {
          additionalInfo: fullMessage.additionalInfo,
          message: fullMessage.message,
          type,
        });
      }
    }
  }
}

export { FlashDispatcherService };
