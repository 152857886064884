import { History, LocationDescriptorObject, Path } from 'history';
import { Container, Service, Token } from 'typedi';

import { hasWindow } from '../browser/platform';

export const HistoryToken = new Token<History>('history');

export enum HistoryAction {
  PUSH = 'PUSH',
  POP = 'POP',
}

export interface RouterObserver {
  onHistoryChange: (location, action) => void;
}

@Service()
export class Router {
  private historyStack = [];
  private observers: Set<RouterObserver>;
  private _history: History;

  constructor() {
    this._history = Container.get(HistoryToken);
    this.observers = new Set();
    this.setupHistory();
  }

  addObserver(observer: RouterObserver) {
    this.observers.add(observer);
  }

  removeObserver(observer: RouterObserver) {
    this.observers.delete(observer);
  }

  canGoBack() {
    return this.historyStack.length > 0;
  }

  push = (path: Path | LocationDescriptorObject): void => {
    if (typeof path === 'object') {
      this._history.push(this.computePath(path) as LocationDescriptorObject);
    } else {
      this._history.push(this.computePath(path) as string, undefined);
    }
  };

  replace = (path: Path | LocationDescriptorObject): void => {
    if (typeof path === 'object') {
      this._history.replace(this.computePath(path) as LocationDescriptorObject);
    } else {
      this._history.replace(this.computePath(path) as string, undefined);
    }
  };

  reload(): void {
    if (hasWindow()) {
      window.location.reload();
    }
  }

  go = (n: number): void => {
    this._history.go(n);
  };
  goBack = (): void => {
    this._history.goBack();
  };

  goForward = (): void => {
    this._history.goForward();
  };

  computePath(path: Path | LocationDescriptorObject): Path | LocationDescriptorObject {
    return this.resolve(undefined, path);
  }

  resolve(basePath: string, path: Path | LocationDescriptorObject): Path | LocationDescriptorObject {
    if (typeof path === 'object') {
      const pathname = path.pathname;
      if (isRelative(pathname)) {
        path.pathname = this.resolveRelativePath(basePath, pathname);
      }
      return path;
    }

    if (isRelative(path)) {
      return this.resolveRelativePath(basePath, path);
    }

    return path;
  }

  private resolveRelativePath(basePath: string, path: Path): Path {
    basePath = basePath || this._history.location.pathname;

    if (isRelativeToLocation(path)) {
      return basePath + path.substr(1);
    }

    const pathSegments = path.split('/');
    const trimCount = pathSegments.filter((segment) => segment === '..').length;
    const locationPathSegments = basePath.split('/').filter((segment) => segment.length > 0);

    locationPathSegments.splice(trimCount);

    const resolvedPath = '/' + [...locationPathSegments, ...pathSegments.slice(trimCount)].join('/');

    return resolvedPath.replace(/^\/\./, '.');
  }

  private handleHistoryChange = (location, action) => {
    switch (action) {
      case HistoryAction.PUSH:
        this.historyStack.push(location);
        break;
      case HistoryAction.POP:
        this.historyStack.pop();
        break;
    }

    this.observers.forEach((observer) => {
      observer.onHistoryChange(location, action);
    });
  };

  private setupHistory = () => {
    if (this._history) {
      this._history.listen(this.handleHistoryChange);
    }

    // disable Chrome's auto scrollRestoration:
    // https://developers.google.com/web/updates/2015/09/history-api-scroll-restoration
    if (typeof history !== 'undefined' && 'scrollRestoration' in history) {
      history.scrollRestoration = 'manual';
    }
  };
}

function isRelative(path: Path): boolean {
  return /^\.{1,2}\/\S*$/.test(path) || /^\.$/.test(path);
}

function isRelativeToLocation(path: Path): boolean {
  return /^\.{1}\/\S*$/.test(path) || /^\.$/.test(path);
}
