import * as React from 'react';
import { Container } from 'typedi';

import {
  FlashDispatcherListener,
  FlashDispatcherService,
  FlashMessageData,
} from '@app/components/obj.flash-wrapper/flash-dispatcher.service';
import { FlashWrapperStyled } from '@app/components/obj.flash-wrapper/flash-wrapper.component.style';
import { Body } from '@atomic/atm.typography';
import { FlashMessage } from '@atomic/mol.flash-message';

interface FlashWrapperState {
  messageQueue: FlashMessageData[];
  additionalSpacing: string;
}

/**
 * It is a wrapper that shows Flash Message on the window.
 * It also manages a queue of messages by listening to flashDispatcher
 */

export class FlashWrapper extends React.Component<any, FlashWrapperState> implements FlashDispatcherListener {
  private flashDispatcher = Container.get(FlashDispatcherService);

  constructor(props) {
    super(props);
    this.state = {
      additionalSpacing: '0',
      messageQueue: [],
    };
  }

  componentDidMount() {
    this.flashDispatcher.setListener(this);
  }

  componentWillUnmount() {
    this.flashDispatcher.setListener(null);
  }

  render() {
    return (
      <FlashWrapperStyled additionalSpacing={this.state.additionalSpacing}>
        {this.state.messageQueue.map((item, index) => (
          <FlashMessage
            type={item.type}
            dismissible
            onClose={this.handleMessageClose(index)}
            key={`${item.time.getTime().toString()}`}
          >
            <Body>{item.fullMessage.message}</Body>
            {item.fullMessage.error && <Body>{item.fullMessage.error}</Body>}
          </FlashMessage>
        ))}
      </FlashWrapperStyled>
    );
  }

  handleMessageClose = (index: number) => {
    return () => {
      const messageQueue = this.state.messageQueue;
      messageQueue.splice(index, 1);
      this.setState({ messageQueue });
    };
  };

  // FlashDispatcherListener
  onReceiveMessage = (data: FlashMessageData) => {
    const messageQueue = this.state.messageQueue;
    const { additionalSpacing } = data;
    this.setState({ additionalSpacing });
    messageQueue.push(data);
    this.setState({ messageQueue });
  };
}
