import { ConversationStatusEnum } from '@app/data/graphql';

export enum StepType {
  status = 'status',
  category = 'category',
  detail = 'detail',
  feedback = 'feedback',
  channel = 'channel',
  note = 'note',
  appointment = 'appointment',
  end = 'end',
}

export interface Option {
  label: string;
  value: number | string;
  active?: boolean;
}

export interface SectionProps {
  hasNextStep: boolean;
  options: Option[];
  onSubmit: (data: any) => void;
  onBack?: () => void;
  loading: boolean | null;
}

export interface FvcConversationModalData {
  note?: string;
  category?: string;
  detail?: string;
  info?: number;
  feedback?: string;
  telephone?: boolean;
  whatsapp?: boolean;
  schedule?: number;
  channel?: number;
  finished?: boolean;
  scheduledTo?: Date;
  status?: string;
}

export interface StepData {
  status: string;
  category: number;
  feedback: number;
  detail: {
    label: string;
    value: string;
  };
  channel: string[];
}

export enum ChannelType {
  whatsApp = 'whatsapp',
  telephone = 'telephone',
}

export const channelOptions = [
  { label: 'Por WhatsApp', value: ChannelType.whatsApp },
  { label: 'Por telefone', value: ChannelType.telephone },
];

export const statusOptions = [
  {
    active: true,
    label: 'Ativo',
    value: ConversationStatusEnum.Active,
  },
  {
    active: true,
    label: 'Receptivo',
    value: ConversationStatusEnum.Receptive,
  },
];
