import * as React from 'react';

import { Ellipsed } from '@atomic/atm.typography';
import { FormFieldContext, FormFieldContextState } from '@atomic/obj.form';
import { LoadingCentered } from '@atomic/mol.loading';
import { Hbox } from '@atomic/obj.box';

import { FileFieldIconStyled, FileFieldInputStyled, FileFieldStyled } from './file-field.component.style';

interface FileFieldProps {
  id: string;
  onValueChange?: (file: File) => void;
  loading?: boolean;
  /**
   * From html https://www.w3schools.com/tags/att_input_accept.asp
   * file_extension | audio/* | video/* | image/* | media_type
   * E.g: '.jpg, .csv' or 'image/*'
   */
  accept?: string;
}

export class FileField extends React.Component<FileFieldProps, undefined> {
  private formFieldConsumer;

  handleChange = (ev: any) => {
    const file: File = ev.target.files[0];

    if (this.props.onValueChange) {
      this.props.onValueChange(file);
    }

    if (this.formFieldConsumer) {
      this.formFieldConsumer.onValueChange(file);
    }
  };

  render() {
    const { onValueChange, ...props } = this.props;
    return (
      <FormFieldContext.Consumer>
        {(formFieldConsumer: FormFieldContextState) => {
          this.formFieldConsumer = formFieldConsumer;

          return (
            <FileFieldStyled empty={!this.formFieldConsumer.value}>
              <FileFieldIconStyled />
              <FileFieldInputStyled onChange={this.handleChange} {...props} />
              <Hbox>
                <Hbox.Item>
                  <Ellipsed>
                    {this.formFieldConsumer.value ? this.formFieldConsumer.value.name : 'Selecione o arquivo'}
                  </Ellipsed>
                </Hbox.Item>
                <Hbox.Item noGrow>{this.props.loading && <LoadingCentered />}</Hbox.Item>
              </Hbox>
            </FileFieldStyled>
          );
        }}
      </FormFieldContext.Consumer>
    );
  }
}
