export const chatScriptStr = `
      var initESW = function (gslbBaseURL) {
        embedded_svc.settings.displayHelpButton = true; //Ou falso

        // Início definições customizadas

        embedded_svc.settings.language = 'pt-BR';
        embedded_svc.settings.defaultMinimizedText = 'Apoio Comercial';
        embedded_svc.settings.disabledMinimizedText = 'Assistente off-line';
        embedded_svc.settings.loadingText = 'Carregando';
        //embedded_svc.settings.fallbackRouting = []; //Uma matriz de IDs de botão, IDs de usuário ou userId_buttonId
        //embedded_svc.settings.offlineSupportMinimizedText = '...'; //(Padronizado para Entre em contato conosco)

        /*
          Atualmente  enviando para botão: CAR_CHAT_EUD_ApoioComercialTerceiros
          // Direcionar ao botão fila correto. Necessário atualização por ORG.
          //CRC_CHAT_EUD_CompraLojaOnline     :   5733D0000004FGk : Compra realizada na loja online
          //CRC_CHAT_EUD_FaleEudora           :   5733D0000004FGu : Fale com Eudora
          //CRC_CHAT_EUD_QueroRevender        :   5733D0000004FGp : Quero Revender

          embedded_svc.settings.directToButtonRouting = function(prechatFormData) {
              if (prechatFormData[4].value === "Compra realizada na loja online")
                  return "5733D0000004FGk";
              else if (prechatFormData[4].value === "Fale com Eudora")
                  return "5733D0000004FGu";
              else if (prechatFormData[4].value === "Quero Revender")
                  return "5733D0000004FGp";
          };*/

        /*Passar variável de Consumidor pelo Site
          embedded_svc.settings.prepopulatedPrechatFields = {
              //ContactFranchiseCode__c: localStorage.getItem("FranchiseCode"),
              CPF__c: "xx",
              FirstName: "xx",
              LastName: "xx",
              Email: "xx@gmail.com",
              Phone: "xx",
              MobilePhone: "xx"
           };*/

        // Custom imagens
        embedded_svc.settings.avatarImgURL =
          'https://grupoboticario--c.documentforce.com/servlet/servlet.ImageServer?id=0151U000001rlvz&oid=00D1U000000xbd2&lastMod=1564061725000';
        //embedded_svc.settings.chatbotAvatarImgURL = "https://grupoboticario--c.documentforce.com/servlet/servlet.ImageServer?id=0151U000001YK8M&oid=00D1U000000xbd2&lastMod=1583521133000";
        embedded_svc.settings.smallCompanyLogoImgURL =
          'https://grupoboticario--c.documentforce.com/servlet/servlet.ImageServer?id=0151U000001rlvz&oid=00D1U000000xbd2&lastMod=1564061725000';
        embedded_svc.settings.prechatBackgroundImgURL =
          'https://grupoboticario--c.documentforce.com/servlet/servlet.ImageServer?id=0151U000001YQtk&oid=00D1U000000xbd2&lastMod=1588795206000';

        // Match de contato e definição para não criar contato no Salesforce
        embedded_svc.settings.extraPrechatInfo = [
          {
            entityFieldMaps: [
              {
                doCreate: false,
                doFind: false,
                fieldName: 'LastName',
                isExactMatch: true,
                label: 'Sobrenome',
              },
              {
                doCreate: false,
                doFind: false,
                fieldName: 'FirstName',
                isExactMatch: true,
                label: 'Nome',
              },
              {
                doCreate: false,
                doFind: false,
                fieldName: 'Email',
                isExactMatch: true,
                label: 'Email',
              },
            ],
            entityName: 'Contact',
            saveToTranscript: 'Contact',
            showOnCreate: true,
          },
        ];

        // Match de campos do pre chat com o registro de transcrição criado pelo Chat
        embedded_svc.settings.extraPrechatFormDetails = [
          {
            label: 'CPF do contato',
            transcriptFields: ['ContactCPF__c'],
            displayToAgent: true,
          },
          {
            label: 'Nome',
            transcriptFields: ['ContactFirstName__c'],
            displayToAgent: true,
          },
          {
            label: 'Sobrenome',
            transcriptFields: ['ContactLastName__c'],
            displayToAgent: true,
          },
          {
            label: 'Email',
            transcriptFields: ['Email__c'],
            displayToAgent: true,
          },
          {
            label: 'Telefone',
            transcriptFields: ['Phone__c'],
            displayToAgent: true,
          },
          {
            label: 'Celular',
            transcriptFields: ['MobilePhone__c'],
            displayToAgent: true,
          },
          {
            label: 'É continuidade do caso',
            transcriptFields: ['CustomerCase__c'],
            displayToAgent: true,
          },
          {
            label: 'Marca',
            transcriptFields: ['Brand__c'],
            displayToAgent: true,
          },
          {
            label: 'Assunto',
            transcriptFields: ['ChatSubject__c'],
            displayToAgent: true,
          },
        ];

        // Fim definições customizadas

        embedded_svc.settings.enabledFeatures = ['LiveAgent'];
        embedded_svc.settings.entryFeature = 'LiveAgent';

        // Registering Events for FAQHelper
        embedded_svc.addEventHandler('afterMaximize', function (data) {
          FAQHelper.show(faqTopics);
        });

        embedded_svc.addEventHandler('afterDestroy', function (data) {
          FAQHelper.destroy();
        });
        // End of FAQHelper Events Registering

        embedded_svc.init(
          'https://grupoboticario.my.salesforce.com',
          'https://atendimento.force.com/franchisechat',
          gslbBaseURL,
          '00D1U000000xbd2',
          'CAR_EUD_ApoioComercialColaborador',
          {
            baseLiveAgentContentURL: 'https://c.la2-c1-ia2.salesforceliveagent.com/content',
            deploymentId: '5721U000000Ifqh',
            buttonId: '5731U000000IhDd',
            baseLiveAgentURL: 'https://d.la2-c1-ia2.salesforceliveagent.com/chat',
            eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I1U0000008WvYUAU_171f068fea1',
            isOfflineSupportEnabled: false,
          },
        );
      };

      if (!window.embedded_svc) {
        var s = document.createElement('script');
        s.setAttribute('src', 'https://grupoboticario.my.salesforce.com/embeddedservice/5.0/esw.min.js');
        s.onload = function () {
          initESW(null);
        };
        document.body.appendChild(s);
      } else {
        initESW('https://service.force.com');
      }

      class FAQHelper {
        static displayed = false;
        static elementsCreated = false;
        static topics;

        static show(renderTopics) {
          if (FAQHelper.displayed) {
            return;
          }
          FAQHelper.topics = renderTopics;
          FAQHelper.init();

          FAQHelper.displayed = true;
        }

        static init() {
          FAQHelper.createTopicListElements();
          FAQHelper.setupTopicListInitialVisibility();
        }

        static destroy() {
          FAQHelper.displayed = false;
          FAQHelper.elementsCreated = false;
        }

        static createTopicListElements() {
          if (FAQHelper.elementsCreated) {
            return;
          }

          var topicsDiv = document.createElement('div');
          topicsDiv.className = 'topicList';

          var topicsTitle = document.createElement('h2');
          topicsTitle.textContent = 'Principais Tópicos';
          topicsDiv.appendChild(topicsTitle);

          var topicList = document.createElement('ol');
          topicList.setAttribute('id', 'topicListOL');
          topicsDiv.appendChild(topicList);

          FAQHelper.topics.map(function (item, index) {
            var listItem = document.createElement('li');
            listItem.innerHTML = '<a href =' + item.link + ' target=_blank>' + item.text + '</a>;';
            topicList.appendChild(listItem);
          });

          var fieldList = document.querySelector('.fieldList');
          fieldList.before(topicsDiv);

          var spanBtnText = document.createElement('span');
          spanBtnText.className = 'label bBody';
          spanBtnText.textContent = 'Atendimento';

          var nextButton = document.createElement('button');
          nextButton.className = 'uiButton--default uiButton embeddedServiceSidebarButton';
          nextButton.onclick = FAQHelper.showFieldList;
          nextButton.appendChild(spanBtnText);

          topicsDiv.appendChild(nextButton);

          FAQHelper.elementsCreated = true;
        }

        static setupTopicListInitialVisibility() {
          var fieldList = document.querySelector('.fieldList');
          fieldList.style.display = 'none';

          var startChatBtn = document.querySelector('.buttonWrapper');
          startChatBtn.style.display = 'none';
        }

        static showFieldList() {
          var fieldList = document.querySelector('.fieldList');
          fieldList.style.display = 'block';

          var startChatBtn = document.querySelector('.buttonWrapper');
          startChatBtn.style.display = 'block';

          var topicList = document.querySelector('.topicList');
          topicList.style.display = 'none';
        }
      }
`;
