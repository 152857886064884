import * as React from 'react';
import { RouteGuardProps } from '@app/core/route';
import { useAppType } from '@app/modules/account/use-appType';

export const AdminGuard: React.FC<RouteGuardProps> = (props) => {
  const { isAdmin } = useAppType();

  if (!isAdmin) {
    return props.children(false);
  }

  return props.children(true);
};
