import { defaultLoadableConfig } from '@app/core/loadable/default-config';
import { LoadingCentered } from '@atomic/mol.loading';
import React from 'react';
import * as Loadable from 'react-loadable';

export const IrDigitalLoadable = Loadable({
  loader: () => import(/* webpackChunkName: "ir-digital" */ './ir-digital.router'),
  loading: () => <LoadingCentered />,
  ...defaultLoadableConfig,
  modules: ['./ir-digital.router'],
  webpack: () => [require.resolveWeak('./ir-digital.router')],
});
