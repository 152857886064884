import { Fade } from '@app/components/obj.animation/animation.component.style';
import { Localized } from '@app/components/obj.localization';
import styled from 'styled-components';
import Container from 'typedi';

const localized = Container.get(Localized);

export const HeadlessPageWrapperBkgStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url(${localized.strings.header.backgroundImage});
  background-size: cover;
  height: 100vh;
  width: 100%;
`;

export const HeadlessPageWrapperContentStyled = styled(Fade)`
  overflow: hidden;
  max-height: 75vh;

  width: 90%;

  @media all and (min-width: ${(props) => props.theme.Breakpoint.sm}em) {
    width: 70%;
  }

  @media all and (min-width: ${(props) => props.theme.Breakpoint.md}em) {
    width: 35%;
  }
`;
