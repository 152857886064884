import { EudoraProductSearch, EudoraProductType } from '@app/data/graphql';
import { useEudoraProductSearchQuery } from '@app/domain/orders.use-case';
import { debounce } from 'debounce';
import * as React from 'react';

interface EudoraProductSearchParams {
  onProductClick?: (product: EudoraProductType) => void;
  onCompleted?: (result: EudoraProductSearch) => void;
  orderCode: string;
}

const MinLengthToSearch = 3;
const DebounceToSearchMillis = 300;

export const useEudoraProductSearch = ({ onProductClick, onCompleted, orderCode }: EudoraProductSearchParams) => {
  const [searchInput, setSearchInput] = React.useState('');

  const { data, loading, error, search } = useEudoraProductSearchQuery({
    onCompleted,
    orderCode,
  });

  const handleSearch = (input: string) => {
    setSearchInput(input);
    handleChangeText(input);
  };

  const handleProductClick = (product: EudoraProductType) => {
    return () => {
      onProductClick(product);
    };
  };

  const handleChangeText = React.useMemo(
    () =>
      debounce((text: string) => {
        if (text.length >= MinLengthToSearch) {
          search(text);
        }
      }, DebounceToSearchMillis),
    [search],
  );

  return {
    data,
    error,
    handleProductClick,
    hasSearchStarted: searchInput.length > MinLengthToSearch,
    loading,
    search: handleSearch,
  };
};
