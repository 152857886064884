import { hasDocument } from '@app/core/browser/platform';

export class HotJarService {
  private hotJarId: string;
  private hotJarSV: string;

  constructor(hotJarId: string, hotJarSV: string) {
    this.hotJarId = hotJarId;
    this.hotJarSV = hotJarSV;
  }

  appendScriptToHead() {
    if (hasDocument() && this.hotJarId && this.hotJarSV) {
      const hotJarScript = document.createElement('script');
      hotJarScript.innerHTML = `(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:${this.hotJarId},hjsv:${this.hotJarSV}};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;

      document.head.appendChild(hotJarScript);
    }
  }
}
