import * as React from 'react';
import { RouteGuardProps } from '@app/core/route';
import { useAppType } from '@app/modules/account/use-appType';

export const ActivationGuard: React.FC<RouteGuardProps> = (props) => {
  const { isActivation } = useAppType();

  if (!isActivation) {
    return props.children(false);
  }

  return props.children(true);
};
