import { LocalizedContext } from '@app/components/obj.localization';
import { parseNumberValue } from '@app/components/obj.formatter/number-formatter';
import { DD, DT } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { VSeparator } from '@atomic/obj.grid';
import * as React from 'react';

interface OrderTotalProps {
  availableCredit: number;
  totalPriceWithoutDiscount: number;
}

export const OrderTotal: React.FC<OrderTotalProps> = ({ availableCredit, totalPriceWithoutDiscount }) => {
  const localized = React.useContext(LocalizedContext).strings.components.orderTotal;
  const parsedAvailableCredit = parseNumberValue(availableCredit, true, 2);
  const parsedTotalPriceWithoutDiscount = parseNumberValue(totalPriceWithoutDiscount, true, 2);

  return (
    <>
      <Hbox>
        <Hbox.Item>
          <DT>{localized.availableCredit}</DT>
        </Hbox.Item>
        <Hbox.Item hAlign='flex-end'>
          <DD>{parsedAvailableCredit}</DD>
        </Hbox.Item>
      </Hbox>
      <VSeparator small />
      <Hbox>
        <Hbox.Item>
          <DT>{localized.priceWithoutDiscount}</DT>
        </Hbox.Item>
        <Hbox.Item hAlign='flex-end'>
          <DD>{parsedTotalPriceWithoutDiscount}</DD>
        </Hbox.Item>
      </Hbox>
      <VSeparator small />
    </>
  );
};
