import { ScheduleNotificationsSection } from '@app/modules/activation/home/components/org.schedule-notifications-section/schedule-notifications.section';
import { AttendanceMessageNotifications } from '@app/modules/header/components/mol.message-notifications/attendance-message-notifications.component';
import { DividerGrayVertical } from '@atomic/atm.divider/divider.component.style';
import { Hbox } from '@atomic/obj.box';
import * as React from 'react';

interface HeaderNotificationProps {
  whatsappEnabled: boolean;
  scheduleEnabled: boolean;
}

export const HeaderNotifications: React.FC<HeaderNotificationProps> = (props) => {
  return (
    <Hbox>
      {props.whatsappEnabled && (
        <Hbox.Item vAlign='center'>
          <AttendanceMessageNotifications />
        </Hbox.Item>
      )}

      {props.whatsappEnabled && props.scheduleEnabled && (
        <>
          <Hbox.Separator />
          <Hbox.Item vAlign='center'>
            <DividerGrayVertical />
          </Hbox.Item>
          <Hbox.Separator />
        </>
      )}

      {props.scheduleEnabled && (
        <Hbox.Item vAlign='center'>
          <ScheduleNotificationsSection />
        </Hbox.Item>
      )}
    </Hbox>
  );
};
