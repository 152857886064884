export enum ClientErrorType {
  Server = 'Server',
  Connection = 'Connection',
  Timeout = 'Timeout',
  Unexpected = 'Unexpected',
  Unauthorized = 'Unauthorized',
  NotFound = 'NotFound',
}

export function isClientError(error: any): error is ClientError {
  return error?.type in ClientErrorType;
}

export class ClientError extends Error {
  type: ClientErrorType;
  message: string;
  uuid?: string;
  constructor(message: string, type: ClientErrorType, uuid?: string) {
    super(message);
    this.type = type;
    this.message = message;
    this.uuid = uuid;
  }
}
