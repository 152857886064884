import { Service } from 'typedi';

import { HttpInterceptor } from '@app/core/http';

@Service()
export class AxiosResponseInterceptor implements HttpInterceptor {
  async afterSuccess(response): Promise<any> {
    return response.data;
  }
}
