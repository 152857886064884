import { Theme } from '@atomic/obj.constants';
import styled, { css } from 'styled-components';

interface HDisplayProps extends Theme {
  nowrap?: boolean;
}

const hdisplayColors = (props: any) => ({
  alert: props.theme.Color.Alert,
  success: props.theme.Color.Success,
});

export const HDisplay = styled.label`
  color: ${(props) => (props.type ? hdisplayColors(props)[props.type] : props.theme.Color.Black)};
  font-size: ${(props) => props.theme.FontSize.XLarge};
  font-family: ${(props) => props.theme.FontFamily.Primary};
  font-weight: ${(props) => props.theme.FontWeight.Normal};
  line-height: 1;
  white-space: ${(props: HDisplayProps) => (props.nowrap ? 'nowrap' : 'normal')};
`;
HDisplay.displayName = 'HDisplay';

export const H1 = styled.h1`
  color: ${(props) => props.theme.Color.Black};
  font-size: ${(props) => props.theme.FontSize.XLarge};
  font-family: ${(props) => props.theme.FontFamily.Secondary};
  font-weight: ${(props) => props.theme.FontWeight.Normal};
  margin: 0;
  margin-bottom: ${(props) => props.theme.Spacing.XSmall};
`;

export const H1Light = styled(H1)`
  color: ${(props) => props.theme.Color.White};
`;

export const H2 = styled.h2`
  color: ${(props) => props.theme.Color.Gray};
  font-size: ${(props) => props.theme.FontSize.Small};
  font-family: ${(props) => props.theme.FontFamily.Primary};
  font-weight: ${(props) => props.theme.FontWeight.Bold};
  line-height: 15px;
  text-transform: uppercase;
  text-align: ${(props) => (props.align ? props.align : 'center')};
  margin: 0;
`;
H2.displayName = 'H2';

export const H3 = styled.h3`
  color: ${(props) => props.theme.Color.GrayDark};
  font-size: ${(props) => props.theme.FontSize.Medium};
  font-family: ${(props) => props.theme.FontFamily.Primary};
  font-weight: ${(props) => props.theme.FontWeight.Bold};
  margin: 0;
  margin-bottom: ${(props) => props.theme.Spacing.XSmall};
`;
H3.displayName = 'H3';

export const HTab = styled.h3`
  color: ${(props) => (props.underlined && props.selected ? props.theme.Color.Primary : props.theme.Color.GrayDark)};
  font-size: ${(props) => props.theme.FontSize.Medium};
  font-family: ${(props) => props.theme.FontFamily.Primary};
  font-weight: ${(props) =>
    props.underlined && props.selected ? props.theme.FontWeight.Bold : props.theme.FontWeight.Normal};
  margin: 0;
  margin-bottom: 0;
`;

export const HCycle = styled.h3`
  color: ${(props) => props.theme.Color.White};
  font-size: ${(props) => props.theme.FontSize.Medium};
  font-family: ${(props) => props.theme.FontFamily.Primary};
  font-weight: ${(props) => props.theme.FontWeight.Normal};
  margin: 0;
  margin-bottom: 0;
`;

export const H3Light = styled(H3)`
  color: ${(props) => props.theme.Color.White};
  margin: 0;
`;

export const H4 = styled.h4`
  color: ${(props) => props.theme.Color.GrayDark};
  font-size: ${(props) => props.theme.FontSize.Small};
  font-family: ${(props) => props.theme.FontFamily.Primary};
  font-weight: ${(props) => props.theme.FontWeight.Bold};
  margin: 0;
`;
H4.displayName = 'H4';

export const H5 = styled.h5`
  color: ${(props) => props.theme.Color.GrayXDark};
  font-size: ${(props) => props.theme.FontSize.XSmall};
  font-family: ${(props) => props.theme.FontFamily.Primary};
  font-weight: ${(props) => props.theme.FontWeight.Bold};
`;
H5.displayName = 'H5';

interface BodyProps extends Theme {
  type: 'alert' | 'white' | 'primary';
}

const bodyColor = (props: any) => {
  return {
    alert: props.theme.Color.Alert,
    primary: props.theme.Color.Primary,
    white: props.theme.Color.White,
  };
};

export const Body = styled.p`
  color: ${(props: BodyProps) => (props.type ? bodyColor(props)[props.type] : props.theme.Color.GrayDark)};
  margin: 0;
  font-size: ${(props) => props.theme.FontSize.Small};
  font-family: ${(props) => props.theme.FontFamily.Primary};
  font-weight: ${(props) => props.theme.FontWeight.Normal};
`;
Body.displayName = 'Body';

export const BodySuccess = styled(Body)`
  color: ${(props) => props.theme.Color.Success};
`;

export const BodyCta = styled(Body)`
  color: ${(props) => props.theme.Color.CallToAction};
  font-weight: ${(props) => props.theme.FontWeight.Bold};
`;

export const BodyAlert = styled(Body)`
  color: ${(props) => props.theme.Color.Alert};
`;

interface BodySecondaryProps extends Theme {
  type?: 'alert' | 'light' | 'success';
}

const bodySecondaryColor = (props: any) => {
  return {
    alert: props.theme.Color.Alert,
    light: props.theme.Color.GrayLight,
    success: props.theme.Color.Success,
  };
};

export const BodySecondary = styled.p`
  color: ${(props: BodySecondaryProps) =>
    props.type ? bodySecondaryColor(props)[props.type] : props.theme.Color.Gray};
  font-size: ${(props) => props.theme.FontSize.XSmall};
  font-family: ${(props) => props.theme.FontFamily.Primary};
  font-weight: ${(props) => props.theme.FontWeight.Normal};
  line-height: 1.5;
  margin: 0;
`;

interface InputLabelProps extends Theme {
  hasError?: boolean;
}
export const InputLabel = styled.label`
  color: ${(props: InputLabelProps) => (props.hasError ? props.theme.Color.Alert : props.theme.Color.GrayDark)};
  display: block;
  font-size: ${(props) => props.theme.FontSize.XSmall};
  font-family: ${(props) => props.theme.FontFamily.Primary};
  font-weight: ${(props) => props.theme.FontWeight.Bold};
  margin-bottom: ${(props) => props.theme.Spacing.XSmall};
`;

export const InputValueCss = css`
  color: ${(props) => props.theme.Color.Black};
  font-size: ${(props) => props.theme.FontSize.Small};
  font-family: ${(props) => props.theme.FontFamily.Primary};
  font-weight: ${(props) => props.theme.FontWeight.Normal};
`;

export const InputValue = styled.label`
  ${InputValueCss};
`;

export const InputDisabledCss = css`
  color: ${(props) => props.theme.Color.Gray};
  font-size: ${(props) => props.theme.FontSize.Small};
  font-family: ${(props) => props.theme.FontFamily.Primary};
  font-weight: ${(props) => props.theme.FontWeight.Normal};

  /* https://stackoverflow.com/a/45209441/3670829 */
  -webkit-text-fill-color: ${(props) => props.theme.Color.GrayLight};
  -webkit-opacity: 1;
`;

export const InputDisabled = styled.label`
  ${InputDisabledCss};
`;

export const InputCaption = styled.label`
  color: ${(props) => props.theme.Color.Gray};
  font-size: ${(props) => props.theme.FontSize.XSmall};
  font-family: ${(props) => props.theme.FontFamily.Primary};
  font-weight: ${(props) => props.theme.FontWeight.Normal};
  margin-top: ${(props) => props.theme.Spacing.XSmall};
  display: block;
`;

export const InputCaptionError = styled(InputCaption)`
  color: ${(props) => props.theme.Color.Alert};
`;

export const InputPlaceholderCss = css`
  color: ${(props) => props.theme.Color.Gray};
  font-size: ${(props) => props.theme.FontSize.Small};
  font-family: ${(props) => props.theme.FontFamily.Primary};
  font-weight: ${(props) => props.theme.FontWeight.Normal};
`;

export const InputPlaceholder = styled.label`
  pointer-events: none;
  ${InputPlaceholderCss};
`;

export const ProductPricePrefix = styled.span`
  color: ${(props) => props.theme.Color.GrayDark};
  font-weight: ${(props) => props.theme.FontWeight.Bold};
`;

export interface PriceProps extends Theme {
  isFeatured?: boolean;
}

export const ProductPrice = styled.label`
  color: ${(props: PriceProps) => (props.isFeatured ? props.theme.Color.Alert : props.theme.Color.GrayDark)};
  font-size: ${(props) => props.theme.FontSize.Small};
  font-family: ${(props) => props.theme.FontFamily.Primary};
  font-weight: ${(props) => props.theme.FontWeight.Bold};
`;

export const ProductOldPrice = styled.label`
  color: ${(props) => props.theme.Color.GrayDark};
  text-decoration: line-through;
  font-size: ${(props) => props.theme.FontSize.XSmall};
  font-family: ${(props) => props.theme.FontFamily.Primary};
  font-weight: ${(props) => props.theme.FontWeight.Lighter};
`;

export const DT = styled.dt`
  display: inline;
  color: ${(props) => props.theme.Color.Gray};
  font-size: ${(props) => props.theme.FontSize.Small};
  font-family: ${(props) => props.theme.FontFamily.Primary};
  font-weight: ${(props) => props.theme.FontWeight.Normal};
`;

export const DTCss = css`
  color: ${(props) => props.theme.Color.GrayDark};
  font-size: ${(props) => props.theme.FontSize.Small};
  font-family: ${(props) => props.theme.FontFamily.Primary};
  font-weight: ${(props) => props.theme.FontWeight.Normal};
`;

export interface SizeProps extends Theme {
  size?: string;
}

export const DD = styled.dd`
  display: inline;
  color: ${(props) => props.theme.Color.GrayXDark};
  font-size: ${(props: SizeProps) => (props.size === 'xl' ? props.theme.FontSize.XLarge : props.theme.FontSize.Small)};
  font-family: ${(props) => props.theme.FontFamily.Primary};
  font-weight: ${(props) => props.theme.FontWeight.Normal};
  margin: 0;

  /* stylelint-disable declaration-block-semicolon-newline-after */
  ${DT} + & {
    margin-top: ${(props) => props.theme.Spacing.XSmall};
  }
`;

export const DDAlert = styled(DD)`
  color: ${(props) => props.theme.Color.Alert};
`;

export const BadgeTextCss = css`
  color: ${(props) => props.theme.Color.Black};
  font-size: ${(props) => props.theme.FontSize.XSmall};
  font-family: ${(props) => props.theme.FontFamily.Primary};
  font-weight: ${(props) => props.theme.FontWeight.Bold};
  line-height: 1.5;
`;

export const BadgeText = styled.span`
  ${BadgeTextCss};
`;

// Utilities

export const Ellipsed = styled.span`
  max-width: 100%;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Bold = styled.span`
  font-weight: ${(props) => props.theme.FontWeight.Bold};
`;

export const Disabled = styled.span`
  color: ${(props) => props.theme.Color.GrayLight};
`;

export const Centered = styled.span`
  display: block;
  text-align: center;
`;
