import 'reflect-metadata';

import { AppConfig } from '@app/config-variables';
import { configure } from '@app/configure-app';

interface AppWindow extends Window {
  __APP_CONFIG__: AppConfig;
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const appConfig = ((window as unknown) as AppWindow).__APP_CONFIG__ || ({} as AppConfig);
configure(appConfig);

// !!! Warning !!! Hack explanation!
//
// Q: Why are we using require instead of import?
//    Why in the middle of the code?
// A: GraphQL decorator access an object that is only
//    available after `configure` is called. However
//    a decorator code is evaluated at reading time
//    while the configure is executed at runtime.

// tslint:disable:no-var-requires
const { bootstrap } = require('./app/bootstrap-client');
// tslint:enable:no-var-requires

bootstrap();
