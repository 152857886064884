import * as React from 'react';

import {
  ProgressCellBadgeStyled,
  ProgressCellChecked,
  ProgressCellFailed,
  ProgressCellNumberStyled,
  ProgressCellStyled,
  ProgressCellTextStyled,
  ProgressCellWaiting,
} from './progress-cell.component.style';

export type ProgressStatus = 'past' | 'current' | 'future' | 'failed' | 'waiting';

export interface ProgressCellProps {
  number: number;
  text: string;
  status?: ProgressStatus;
}

export class ProgressCell extends React.Component<ProgressCellProps, undefined> {
  render() {
    return (
      <ProgressCellStyled {...this.props}>
        <ProgressCellBadgeStyled {...this.props}>
          {this.props.status === 'failed' && <ProgressCellFailed {...this.props} />}
          {this.props.status === 'past' && <ProgressCellChecked {...this.props} />}
          {this.props.status === 'waiting' && <ProgressCellWaiting {...this.props} />}
          <ProgressCellNumberStyled {...this.props}>{this.props.number}</ProgressCellNumberStyled>
        </ProgressCellBadgeStyled>
        <ProgressCellTextStyled {...this.props}>{this.props.text}</ProgressCellTextStyled>
      </ProgressCellStyled>
    );
  }
}
