import { getStorage, StorageType } from '@app/core/storage/storage.provider';
import { Service } from 'typedi';

import { CONTINUE_CALL, RESELLER_ID, RESELLER_PAUSE, SUPERVISOR_PA } from './storage-constants';

@Service()
export class AttendanceDatasource {
  private storage = getStorage(StorageType.Local);

  isAttendancePaused() {
    return this.storage.get(RESELLER_PAUSE);
  }

  setPause(isPaused: boolean) {
    this.storage.put(RESELLER_PAUSE, isPaused);
  }

  getResellerId() {
    return this.storage.get(RESELLER_ID);
  }

  setResellerId(resellerId: string) {
    this.storage.put(RESELLER_ID, resellerId);
  }

  shouldContinueCall() {
    return this.storage.get(CONTINUE_CALL);
  }

  setContinueCall(shouldContinue: boolean) {
    this.storage.put(CONTINUE_CALL, shouldContinue);
  }

  getSupervisorPosition() {
    return this.storage.get(SUPERVISOR_PA);
  }

  setSupervisorPosition(attendantPosition: string) {
    this.storage.put(SUPERVISOR_PA, attendantPosition);
  }

  clearStorage() {
    [SUPERVISOR_PA, CONTINUE_CALL, RESELLER_ID, RESELLER_PAUSE].forEach((datasource) =>
      this.storage.remove(datasource),
    );
  }
}
