export function convertTtlToExpirationDate(ttlInSeconds: number): number {
  return Date.now() + ttlInSeconds * 1000;
}

export function blobToFile(blob: Blob, fileName: string, type: string): File {
  return new File([blob], fileName, { lastModified: new Date().getTime(), type });
}

export const pluralizeS = (quantity: number) => (quantity > 1 ? 's' : '');

export const arrayToSentence = (array: any[]): string => {
  if (array.length === 1) {
    return `${array[0]}`;
  }
  const sentence = array.reduce((previousValue, currentValue, index) => {
    return previousValue + (index === array.length - 1 ? 'e ' : ', ') + currentValue;
  }, '');

  return sentence;
};

export const noDiactrics = (str: string) => {
  // remove-accents-diacritics: https://stackoverflow.com/a/37511463/3670829
  return str?.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export const noSpecialCharacters = (str: string) => {
  return str?.replace(/[^\w\s]/g, '');
};
