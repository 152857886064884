import { HttpInterceptor, HttpRequest } from '@app/core/http';
import { CLIENT_ID } from '@app/modules/modules.token';
import { Container, Service, Token } from 'typedi';

export const BusinessUnitToken = new Token<string>('business-unit');

@Service()
export class ExternalApiHeadersInterceptor implements HttpInterceptor {
  private businessUnit = Container.get(BusinessUnitToken);

  async before(request: HttpRequest): Promise<HttpRequest> {
    request.headers.unidadeNegocio = this.businessUnit;
    const clientId = Container.get(CLIENT_ID);
    request.headers['X-IBM-Client-Id'] = clientId;
    return request;
  }
}
