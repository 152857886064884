import { StartItemsSelectorSection } from '@app/components/mol.start-items-selector/start-items-selector.section';
import { LocalizedContext } from '@app/components/obj.localization/localized.hook';
import { Project } from '@app/config-variables';
import { Cart } from '@app/data/graphql/graphql-schema';
import { useStartOrderMutation } from '@app/domain/orders.use-case';
import { CartPageLocationState } from '@app/models/orders.model';
import { AppPath } from '@app/modules/app/routes';
import { useStartOrderParams } from '@app/modules/cart/org.start-oder-params-hook/start-order-params.hook';
import { OrderContext, QueueContext } from '@app/providers';
import { Button } from '@atomic/atm.button';
import { Frame } from '@atomic/atm.frame';
import { Hbox } from '@atomic/obj.box';
import { CommonTheme } from '@atomic/obj.constants';
import { Col, Grid, Row } from '@atomic/obj.grid';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router';
import { ThemeContext } from 'styled-components';

export const CartSelectStarterKitPage: React.FC = () => {
  const theme = React.useContext<CommonTheme>(ThemeContext);
  const localized = React.useContext(LocalizedContext).strings.components.cartPages;
  const order = React.useContext(OrderContext);
  const history = useHistory();
  const { state } = useLocation<CartPageLocationState>();
  const queue = React.useContext(QueueContext);

  const startOrderParams = useStartOrderParams();

  const handleStartOrderComplete = (newCart: Cart) => {
    order.updateCart(newCart);
    order.updateCurrentOrder();
    queue?.currentOrders?.refetch();
    history.push({ pathname: AppPath.Cart.Management, state });
  };

  const { loading: startOrderLoading, updateOrder } = useStartOrderMutation({
    onCompleted: handleStartOrderComplete,
    onError: order.handleOrderError,
    startOrderParams,
  });

  return (
    <Grid>
      <Row center='xs' mt>
        <Col xs={8}>
          <Frame>
            <StartItemsSelectorSection
              onStarterPackChange={order.updateSelectedStarterPacks}
              starterPackOptions={order.starterPacks}
              selectedStarterPacks={order.selectedStarterPacks}
            />

            <Hbox>
              <Hbox.Item>
                <Button expanded outlined onClick={() => history.goBack()} loading={startOrderLoading}>
                  {localized.back}
                </Button>
              </Hbox.Item>

              <Hbox.Separator />
              <Hbox.Item hAlign='flex-end'>
                <Button
                  onClick={() =>
                    theme.Project === Project.eudora
                      ? history.push({ pathname: AppPath.Cart.Address, state })
                      : updateOrder()
                  }
                  loading={startOrderLoading}
                  disabled={!(order.selectedStarterPacks?.length > 0)}
                >
                  {localized.advance}
                </Button>
              </Hbox.Item>
            </Hbox>
          </Frame>
        </Col>
      </Row>
    </Grid>
  );
};
