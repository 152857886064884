import { ThemeToken } from '@atomic/obj.constants';
import { HighlightStyle } from '@atomic/obj.mixin';
import * as ColorFunc from 'color';
import styled from 'styled-components';
import Container from 'typedi';
import { FlashMessageProps } from './flash-message.component';

const theme = Container.get(ThemeToken);

const flashMessageTypes = {
  alert: theme.Color.Alert,
  info: theme.Color.Accessory,
  success: theme.Color.Success,
  warning: theme.Color.Warning,
};

export const FlashMessageContentStyled = styled.div`
  flex-grow: 99;
  margin: 0 ${(props) => props.theme.Spacing.Small};
`;

export const FlashMessageCloseStyled = styled.div`
  ${HighlightStyle}

  &:hover > span {
    color: ${(props: FlashMessageProps) => ColorFunc(flashMessageTypes[props.type]).darken(0.5).string()};
  }
`;

export const FlashMessageStyled = styled.div`
  background-color: ${(props: FlashMessageProps) => ColorFunc(flashMessageTypes[props.type]).lighten(1).string()};
  border-radius: ${(props) => props.theme.Border.Radius};
  display: flex;
  padding: ${(props) => props.theme.Spacing.Medium};
  font-family: ${(props) => props.theme.FontFamily.Primary};
  font-weight: ${(props) => props.theme.FontWeight.Normal};
  font-size: ${(props) => props.theme.FontSize.Small};
  transition: opacity 0.3s ease-in-out;

  p,
  svg {
    color: ${(props: FlashMessageProps) => ColorFunc(flashMessageTypes[props.type]).darken(0.3).string()};
  }
`;
