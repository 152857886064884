import { Service } from 'typedi';

@Service()
class AppVersion {
  private version: string;

  constructor(appVersion?: string) {
    this.version = appVersion;
  }

  getAppVersion() {
    return `Versão: ${this.version}`;
  }
}

export { AppVersion };
