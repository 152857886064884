import { Localized } from '@app/components/obj.localization';
import { CellData } from '@atomic/mol.radio-icon-list/radio-icon-list.component';
import Container from 'typedi';
import { DeliveryMode } from './current-order-delivery-modes.vm';

const localized = Container.get(Localized).strings.components.currentOrderDeliveryModes;

export const mapCurrentOrderDeliveryModes = (deliveryModes: DeliveryMode[]): CellData[] => {
  if (!deliveryModes) {
    return null;
  }

  return deliveryModes.map((deliveryMode, idx) => {
    return {
      description: deliveryMode?.descriptiveAddress,
      radioId: idx.toString(),
      title: deliveryMode.isWithdrawalCenter
        ? deliveryMode?.distributionCenterName ?? localized.onResellerSpace
        : localized.onDeliveryAddress,
    };
  });
};
