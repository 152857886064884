import { Theme, ThemeToken } from '@atomic/obj.constants';
import styled from 'styled-components';
import Container from 'typedi';

const theme = Container.get(ThemeToken);

export enum FrameRoundBorders {
  Left = 'left',
  Right = 'right',
  Both = 'both',
}

export enum FrameColorTypes {
  Sucess = 'sucess',
  Alert = 'alert',
}

const FRAME_COLORS = {
  [FrameColorTypes.Alert]: theme.Color.Alert,
  [FrameColorTypes.Sucess]: theme.Color.Success,
};

export interface FrameStyleProps extends Theme {
  color?: FrameColorTypes;
  roundBorder?: FrameRoundBorders;
  selected?: boolean;
  noPaddingBottom?: boolean;
}

export const FrameStyled = styled.div`
  box-shadow: ${(props: FrameStyleProps) =>
    props.selected
      ? `0 0 ${props.theme.Spacing.Small} ${props.theme.Color.Primary}`
      : `${props.theme.BoxShadow.Depth2} rgba(0, 0, 0, 0.1)`};
  background-color: ${(props) => (props.color ? FRAME_COLORS[props.color] : props.theme.Color.White)};
  border: 1px solid ${(props) => props.theme.Color.GrayLight};
  position: relative;
  padding: ${(props) => props.theme.Spacing.Large};
  ${(props: FrameStyleProps) =>
    props.roundBorder &&
    `border-radius: ${props.roundBorder !== FrameRoundBorders.Right ? props.theme.Border.RadiusLarge : 0}
    ${props.roundBorder !== FrameRoundBorders.Left ? props.theme.Border.RadiusLarge : 0}
    ${props.roundBorder !== FrameRoundBorders.Left ? props.theme.Border.RadiusLarge : 0}
    ${props.roundBorder !== FrameRoundBorders.Right ? props.theme.Border.RadiusLarge : 0} `};
  padding-bottom: ${(props) => props.noPaddingBottom && '0px'};
`;
