import React from 'react';
import { ApolloError } from '@apollo/client';
import { ResellerInteraction } from '@app/data/graphql';
import { LocalizedContext } from '@app/components/obj.localization';
import { useAudio } from '@app/components/obj.sounds/sound.hook';
import { Sounds } from '@app/components/obj.sounds/sounds.component';
import { useQueryHook, useSubscriptionHook } from '@app/core/graphql';
import { BrowserNotificationContext } from '@app/providers/browser-notification.provider';
import { FeatureToggleContext } from '@app/providers/feature-toggle.provider';
import { QueueContext } from '@app/providers/queue.provider';
import { useAppType } from '@app/modules/account/use-appType';
import { UserContext } from './user.provider';
import { NOTIFICATION_POLLING_INTERVAL } from '@app/constants';

export interface MessageNotificationProviderState {
  error: ApolloError;
  hasNextPage: boolean;
  interactions: ResellerInteraction[];
  interactionsLength: number;
  loading: boolean;
  handleClose: () => void;
  onLoadMore: () => void;
  refetch: () => void;
}

interface QueryData {
  ResellerInteractions?: ResellerInteraction[];
  ResaleIntentionInteractions?: ResellerInteraction[];
}

const ITEMS_PER_PAGE = 6;

export const MessageNotificationContext = React.createContext<MessageNotificationProviderState>(null);

export const MessageNotificationProvider: React.FC = (props) => {
  const queueContext = React.useContext(QueueContext);
  const userContext = React.useContext(UserContext);
  const browserNotificationContext = React.useContext(BrowserNotificationContext);
  const { isStarts } = useAppType();
  const query = isStarts ? 'resale-intention-interactions' : 'reseller-interactions';

  const featureToggleOptions = React.useContext(FeatureToggleContext).featureToggleOptions;

  const [interactions, setInteractions] = React.useState<ResellerInteraction[]>();
  const [sliceIndex, setSliceIndex] = React.useState(0);

  const notificationSound = useAudio(Sounds.NotificationSound);

  const strings = React.useContext(LocalizedContext).strings.components.messageNotifications.browserMessageNotification;

  const { loading, error, refetch } = useQueryHook<QueryData>(query, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setInteractions(data?.ResellerInteractions ?? data?.ResaleIntentionInteractions);

      data.ResellerInteractions.map((item) => {
        window?.newrelic?.addPageAction('instantMessageData', {
          attendantCode: userContext.userProfile.id,
          cellphone: item.cellphone,
          execution_time: new Date().toJSON(),
          messages: item.lastMessage.message.toString(),
          resellerCode: item.resellerId,
        });
      });
    },
  });

  React.useEffect(() => {
    let interval;

    if (userContext.isLogged()) {
      interval = setInterval(refetch, NOTIFICATION_POLLING_INTERVAL);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  });

  useSubscriptionHook<{ MessageReceived: ResellerInteraction }>('message-received', {
    onSubscriptionData: (subscriptionResult) => {
      const receivedInteraction = subscriptionResult?.subscriptionData?.data?.MessageReceived;
      if (!receivedInteraction) {
        return;
      }

      if (featureToggleOptions.fvcNotificationSound && receivedInteraction) {
        notificationSound.play();
        browserNotificationContext.handleSendNotification({
          body: strings.body(subscriptionResult?.subscriptionData?.data?.MessageReceived?.name),
          title: strings.title,
        });

        window?.newrelic?.addPageAction('instantMessageNotification', {
          attendantCode: userContext.userProfile.id,
          cellphone: receivedInteraction.cellphone,
          execution_time: new Date().toJSON(),
          message: receivedInteraction.lastMessage.message.toString(),
          resellerCode: receivedInteraction.resellerId,
        });
      }

      const index = interactions?.findIndex((int) => int.resellerId === receivedInteraction.resellerId);
      if (index >= 0) {
        interactions.splice(index, 1);
      }

      setInteractions([...interactions, receivedInteraction]);
    },
  });

  React.useEffect(() => {
    if (queueContext?.activeIndex) {
      const indexToRemove = interactions?.findIndex(
        (interaction) => interaction.resellerId === queueContext?.activeIndex,
      );

      if (indexToRemove >= 0) {
        interactions.splice(indexToRemove, 1);
        setInteractions([...interactions]);
      }
    }
  }, [interactions, queueContext?.activeIndex]);

  const onLoadMore = React.useCallback(() => setSliceIndex((idx) => idx + ITEMS_PER_PAGE), []);

  const handleClose = () => setSliceIndex(0);

  return (
    <MessageNotificationContext.Provider
      value={{
        error,
        handleClose,
        hasNextPage: sliceIndex < interactions?.length,
        interactions: interactions?.slice(0, sliceIndex),
        interactionsLength: interactions?.length,
        loading,
        onLoadMore,
        refetch,
      }}
    >
      {props.children}
    </MessageNotificationContext.Provider>
  );
};
