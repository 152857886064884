import * as React from 'react';
import { Redirect as RouterRedirect, RedirectProps, withRouter } from 'react-router';
import { Container } from 'typedi';

import { Router } from './router';

export function Redirect({ to, from, ...others }: RedirectProps) {
  const router = Container.get(Router);
  const Component = withRouter((props) => (
    <RouterRedirect
      to={router.resolve(props.match.url, to)}
      from={from ? router.resolve(props.match.url, from).toString() : undefined}
      {...others}
    />
  ));
  return <Component />;
}
