import { ApolloError } from '@apollo/client';
import { ComboEudoraModal } from '@app/components/mol.combo-eudora-modal/combo-eudora-modal.component';
import { useEudoraProductSearch } from '@app/components/mol.fast-shop/eudora-product-search.hook';
import { FlashDispatcherService } from '@app/components/obj.flash-wrapper/flash-dispatcher.service';
import { LocalizedContext } from '@app/components/obj.localization';
import { isResetContentError } from '@app/components/orders/checkout-reset-content-error.hook';
import { Cart, EudoraProductType } from '@app/data/graphql';
import { useErrorFlashDispatcher } from '@app/domain/error-dispatcher.hook';
import { useAddToCartMutation, useComboDetailLazyQuery } from '@app/domain/orders.use-case';
import { OrderContext } from '@app/providers';
import * as React from 'react';
import Container from 'typedi';
import { ProductNameSearchView } from './product-name-search-view.component';

interface ProductNameSearchProps {
  onProductClick: (product: string) => void;
  loading: boolean;
}

export const EudoraProductNameSearch: React.FC<ProductNameSearchProps> = (props) => {
  const order = React.useContext(OrderContext);
  const [comboModalOpened, setComboModalOpened] = React.useState(false);
  const [clearComboModal, setClearComboModal] = React.useState(Date.now());
  const flashDispatcherService = Container.get(FlashDispatcherService);
  const localized = React.useContext(LocalizedContext).strings.error.order;

  const handleProductAdded = (cart: Cart) => {
    order.updateCart(cart);
    handleCloseComboModal();
  };

  const handleResetContentError = (error: ApolloError) => {
    if (isResetContentError(error)) {
      flashDispatcherService.dispatchMessage(
        { additionalInfo: 'product-name-search.container.eudora.tsx:35', message: localized.resetContent },
        'warning',
      );

      order.updateCurrentOrder();
    }
  };

  const { addToCart, error, loading: addToCartLoading } = useAddToCartMutation({
    onCompleted: handleProductAdded,
    onError: handleResetContentError,
    orderCode: order?.cart?.orderCode,
  });

  const {
    getComboDetail,
    data: comboDetailData,
    loading: comboDetailLoading,
    error: comboDetailError,
    refetch: comboDetailRefetch,
  } = useComboDetailLazyQuery(order?.cart?.orderCode);

  const handleSearchAdd = (product: EudoraProductType) => {
    if (product.isCombo) {
      getComboDetail(product.code);
      setComboModalOpened(true);
      setClearComboModal(Date.now());
    } else {
      props.onProductClick(product.code);
    }
  };

  const {
    data,
    error: searchError,
    loading: searchLoading,
    handleProductClick,
    hasSearchStarted,
    search,
  } = useEudoraProductSearch({
    onProductClick: handleSearchAdd,
    orderCode: order?.cart?.orderCode,
  });

  const handleCloseComboModal = () => {
    setComboModalOpened(false);
  };

  useErrorFlashDispatcher(error);

  return (
    <>
      <ProductNameSearchView
        onProductClick={handleProductClick}
        onValueChange={search}
        loading={searchLoading || props.loading}
        error={!!searchError}
        data={data}
        hasSearchStarted={hasSearchStarted}
      />

      <ComboEudoraModal
        key={`${clearComboModal}`}
        opened={comboModalOpened}
        data={comboDetailData?.ComboDetail}
        loading={comboDetailLoading || addToCartLoading || props.loading}
        error={comboDetailError}
        onSubmit={addToCart}
        refetch={comboDetailRefetch}
        onClose={handleCloseComboModal}
      />
    </>
  );
};
