import * as React from 'react';

export const IcDashboard = (props) => {
  const color = props.color || '#007E78';
  return (
    <svg {...props} viewBox='0 0 22 22'>
      <g fill='none' fillRule='evenodd'>
        <path
          d='M19.377 1H2.623A1.623 1.623 0 0 0 1 2.623v16.754A1.623 1.623 0 0 0 2.623 21h16.754c.896 0 1.623-.727 1.623-1.623V2.623C21 1.727 20.273 1 19.377 1z'
          strokeWidth={1.4}
          stroke={color}
        />
        <path
          d='M14.858 7.456a.758.758 0 0 0-1.01-.279l-1.65.895a.558.558 0 0 0-.225.772.596.596 0 0 0 .798.218l.553-.3-1.776 4.783-1.646-2.329a.987.987 0 0 0-.785-.41.975.975 0 0 0-.801.377l-1.98 2.55a.553.553 0 0 0-.072.564.595.595 00 0 1.009.119l1.816-2.34 1.715 2.425a.985.985 0 0 0 .806.412.99.99 0 0 0 .817-.426l2.008-5.367.243.449c.097.18.286.295.495.303a.591.591 0 0 0 .519-.263.552.552 0 0 0 .023-.567l-.857-1.586z'
          fill={color}
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};
