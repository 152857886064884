import * as React from 'react';
import { Container } from 'typedi';

import { Router } from '@app/core/route/router';

export function withRouter(WrappedComponent: any) {
  return class extends React.Component<any> {
    render() {
      const router = Container.get(Router);
      return <WrappedComponent router={router} {...this.props} />;
    }
  };
}
