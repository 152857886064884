import '../../../assets/fonts/fonts.css';

import { FlashWrapper } from '@app/components/obj.flash-wrapper/flash-wrapper.component';
import { Localized, LocalizedProvider } from '@app/components/obj.localization';
import { UrlGuard } from '@app/components/obj.url-guard/url-guard.service';
import { HeadTags } from '@app/modules/app/head-tags.component';
import { FeatureToggleProvider, QueueProvider, UserProvider } from '@app/providers';
import { ThemeToken } from '@atomic/obj.constants';
import { GlobalStyled } from '@atomic/obj.globals';
import { ApolloClient, ApolloProvider } from '@apollo/client';
import * as React from 'react';
import { ThemeProvider } from 'styled-components';
import { Container } from 'typedi';

import { ExternalRoutes } from './routes/external-routes';
import { BlocklistProvider } from '@app/providers/blocklist.provider';
import { HotJarService } from '@app/components/obj.hotjar/hotjar.service';
import { GoogleAnalyticsTagManagerService } from '@app/components/obj.google-analytics-tag-manager/google-analytics-tag-manager.service';
import { ErrorBoundary } from '@app/modules/error-boundary/error-boundary.component';
import { BrowserNotificationProvider } from '@app/providers/browser-notification.provider';

const theme = Container.get(ThemeToken);

export const App: React.FC = () => {
  const apolloClient: ApolloClient<any> = Container.get(ApolloClient);

  const localized = Container.get(Localized);
  const urlGuard = Container.get(UrlGuard);
  const hotJarService = Container.get(HotJarService);
  const googleAnalyticsService = Container.get(GoogleAnalyticsTagManagerService);

  urlGuard.verifyUrl();
  hotJarService.appendScriptToHead();
  googleAnalyticsService.appendScript();

  return (
    <UserProvider>
      <LocalizedProvider strings={localized.strings}>
        <ThemeProvider theme={theme}>
          <ApolloProvider client={apolloClient as any}>
            <FeatureToggleProvider>
              <BrowserNotificationProvider>
                <QueueProvider>
                  <BlocklistProvider>
                    <GlobalStyled />
                    <FlashWrapper />
                    <ErrorBoundary>
                      <ExternalRoutes />
                      <HeadTags />
                    </ErrorBoundary>
                  </BlocklistProvider>
                </QueueProvider>
              </BrowserNotificationProvider>
            </FeatureToggleProvider>
          </ApolloProvider>
        </ThemeProvider>
      </LocalizedProvider>
    </UserProvider>
  );
};
