import { Cart_products } from '@app/data/graphql';
import { ProductOrderInfo } from '@app/models/orders.model';
import { ProductDetail } from '@app/utils/data-layer';
import { CartProduct } from '@app/view-model/cart-product/cart-product.vm';

const giftIds = ['2'];

export const mapCartProducts = (products: Cart_products[]): CartProduct[] => {
  return products?.map((product) => ({
    code: product.code,
    discountPercentage: (product.profit * 100) / (product.unitMarketValue * product.quantity),
    isCombo: product.isCombo,
    isFixedQuantity: product.blockEdit,
    isGift: giftIds.includes(product.origin.id),
    isRemovable: !product.blockDelete,
    name: product.name,
    originId: product.origin.id,
    payValue: product.payValue,
    profit: product.profit,
    profitWithDiscount: product.profitWithDiscount,
    quantity: product.quantity,
    sequentialNumber: product.sequentialNumber,
    unitMarketValue: product.unitMarketValue,
    unitNetValue: product.unitNetValue,
    unitPayValue: product.unitPayValue,
  }));
};

export const mapProducts = (cartProducts: CartProduct[]): ProductOrderInfo[] =>
  cartProducts?.map((product) => {
    return {
      code: product.code,
      isGift: product.isGift,
      name: product.name,
      price: product.payValue / product.quantity,
      quantity: product.quantity,
    };
  });

export const mapCartProductsToProductDetails = (products: CartProduct[]): ProductDetail[] => {
  return products?.map((product) => ({
    id: product.code,
    name: product.name,
    price: product.payValue,
    quantity: product.quantity,
  }));
};

export const mapProductsToProductDetails = (products: ProductOrderInfo[]): ProductDetail[] => {
  return products?.map((product) => ({
    id: product.code,
    name: product.name,
    price: product.price,
    quantity: product.quantity,
  }));
};
