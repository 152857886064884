import { IcClose } from '@app/components/icons/ic-close.component';
import { EventAction, EventCategory, EventLabel } from '@app/utils/data-layer';
import { Button } from '@atomic/atm.button';
import { H3 } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import React from 'react';

import { CartStepHeaderHboxStyled } from './cart-step-header.style';

interface CartStepHeaderProps {
  title: string;
  onClose?: () => void;
  withShadow?: boolean;
}

export const CartStepHeader: React.FC<CartStepHeaderProps> = ({ title, onClose, withShadow }) => {
  return (
    <CartStepHeaderHboxStyled withShadow={withShadow}>
      {onClose && (
        <>
          <Hbox.Item onClick={onClose} noGrow hAlign='flex-start'>
            <Button
              kind='link'
              dataGtmEventAction={EventAction.Click.Button}
              dataGtmEventCategory={EventCategory.Attendence}
              dataGtmEventLabel={EventLabel.Close}
            >
              <IcClose />
            </Button>
          </Hbox.Item>
          <Hbox.Separator />
        </>
      )}
      <Hbox.Item>
        <H3>{title}</H3>
      </Hbox.Item>
    </CartStepHeaderHboxStyled>
  );
};
