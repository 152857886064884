import { AppVersion } from '@app/components/obj.app-version/app-version.service';
import { LogoutButton } from '@app/components/obj.logout/logout-button.component';
import { PRIVACY_POLICY_URL } from '@app/modules/modules.token';
import { Button } from '@atomic/atm.button';
import { BodySecondary } from '@atomic/atm.typography';
import { VSeparator } from '@atomic/obj.grid';
import * as React from 'react';
import Container from 'typedi';
import { LocalizedContext } from '../obj.localization';

import { MenuContentStyled } from './menu-content.component.style';

export const MenuContent: React.FC = () => {
  const localized = React.useContext(LocalizedContext).strings.header;
  const appVersion = Container.get(AppVersion);
  const privacyPolicyUrl = Container.get(PRIVACY_POLICY_URL);

  return (
    <MenuContentStyled>
      <VSeparator />
      <Button kind='externalLink' to={privacyPolicyUrl}>
        {localized.privacyPolicy}
      </Button>

      <VSeparator />
      <LogoutButton />

      <VSeparator />
      <BodySecondary>{appVersion.getAppVersion()}</BodySecondary>
    </MenuContentStyled>
  );
};
