import { removeTmzFromDateString } from '@app/components/obj.formatter/date-formatter';
import { CurrentOrder } from '@app/data/graphql';
import { useResellerOrderCyclesQuery } from '@app/domain/orders.use-case';
import { CartSectionState } from '@app/models/orders.model';
import { SelectOption } from '@atomic/atm.select';
import { isAfter, isWithinInterval, parseISO } from 'date-fns';
import * as React from 'react';
import { useLocation } from 'react-router-dom';

export const useCartCycles = (currentOrder: CurrentOrder, resellerId: string) => {
  const [selectedCycle, setSelectedCycle] = React.useState<string>();
  const [selectedSubcycle, setSelectedSubcycle] = React.useState<string>();

  const location = useLocation<CartSectionState>();

  const availableCycles = useResellerOrderCyclesQuery(resellerId);
  const currentCycle = availableCycles.data?.currentCycle;
  const previousCycle = availableCycles.data?.previousCycle;
  const currentSubCycles = availableCycles?.data?.currentCycleInfo?.subCycles;
  const previousSubCycles = availableCycles?.data?.previousCycleInfo?.subCycles;

  const canOrderOnPreviousCycle =
    availableCycles.data?.isAllowedToOrderOnPreviousCycle && availableCycles.data?.isExtendedCyclePeriod;

  React.useEffect(() => {
    const findCurrentSubcycle = (subcycles) => {
      return subcycles?.find((subcycle) => {
        return isWithinInterval(new Date(), {
          end: parseISO(subcycle.end),
          start: parseISO(subcycle.start),
        });
      });
    };

    const subCycleId = findCurrentSubcycle(canOrderOnPreviousCycle ? previousSubCycles : currentSubCycles)?.id;
    setSelectedSubcycle(subCycleId);
  }, [selectedCycle, previousSubCycles, currentSubCycles, canOrderOnPreviousCycle]);

  React.useEffect(() => {
    if (!selectedCycle) {
      setSelectedCycle(canOrderOnPreviousCycle ? previousCycle : currentCycle);
    }
  }, [selectedCycle, currentCycle, previousCycle, canOrderOnPreviousCycle]);

  React.useEffect(() => {
    if (new Date(currentOrder?.expiration).getTime() >= new Date().getTime() && !location.state?.changeAddress) {
      setSelectedCycle(currentOrder.cycle);
    }
  }, [canOrderOnPreviousCycle, currentCycle, currentOrder, location.state]);

  const previousSubCyclesOptions: SelectOption[] = previousSubCycles?.map((it) => ({
    label: `${removeTmzFromDateString(it.start)} à ${removeTmzFromDateString(it.end)} (Subciclo: ${+it.id + 1})`,
    value: it.id,
  }));

  const currentSubCyclesOptions: SelectOption[] = currentSubCycles
    ?.filter((it) => !isAfter(it.start, new Date()))
    .map((it) => ({
      label: `${removeTmzFromDateString(it.start)} à ${removeTmzFromDateString(it.end)} (Subciclo: ${+it.id + 1})`,
      value: it.id,
    }));

  React.useEffect(() => {
    if (!selectedSubcycle) {
      currentOrder?.subcycle
        ? setSelectedSubcycle(currentOrder?.subcycle)
        : setSelectedSubcycle(currentSubCyclesOptions?.[0]?.value);
    }
  }, [selectedSubcycle, currentOrder?.subcycle, currentSubCyclesOptions]);

  return {
    availableCycles,
    canOrderOnPreviousCycle,
    currentCycle,
    currentSubCycles: currentSubCyclesOptions,
    handleChangeCycle: setSelectedCycle,
    handleChangeSubcycle: setSelectedSubcycle,
    previousCycle,
    previousSubCycles: previousSubCyclesOptions,
    selectedCycle,
    selectedSubcycle,
  };
};
