import * as React from 'react';

import { FaIcon } from '@atomic/atm.fa-icon';

import { MenuContent } from '../org.menu-content/menu-content.component';
import {
  DropDownMenuContentStyled,
  DropDownMenuStyled,
  DropDownMenuWrapperStyled,
} from './drop-down-menu.component.style';

export interface DropDownMenuState {
  menuOpen: boolean;
}

export class DropDownMenu extends React.PureComponent<{}, DropDownMenuState> {
  private hover: boolean;
  constructor(props) {
    super(props);

    this.state = {
      menuOpen: false,
    };
  }

  componentDidUpdate(_prevProps, prevState: DropDownMenuState) {
    if (prevState.menuOpen !== this.state.menuOpen) {
      if (this.state.menuOpen) {
        window.addEventListener('mousedown', this.closeMenu, false);
        window.addEventListener('touchstart', this.closeMenu, false);
      }
      if (!this.state.menuOpen) {
        window.removeEventListener('mousedown', this.closeMenu, false);
        window.removeEventListener('touchstart', this.closeMenu, false);
      }
    }
  }

  render() {
    return (
      <DropDownMenuWrapperStyled>
        <DropDownMenuStyled
          onMouseOver={this.handleMouseOver}
          onMouseOut={this.handleMouseOut}
          onClick={this.handleClick}
        >
          <FaIcon.EllipsisV />
        </DropDownMenuStyled>
        <DropDownMenuContentStyled show={this.state.menuOpen}>
          <MenuContent />
        </DropDownMenuContentStyled>
      </DropDownMenuWrapperStyled>
    );
  }

  private handleClick = () => this.setState({ menuOpen: !this.state.menuOpen });

  private closeMenu = () => {
    if (this.hover) {
      return;
    }
    this.setState({ menuOpen: false });
  };
  private handleMouseOver = () => (this.hover = true);
  private handleMouseOut = () => (this.hover = false);
}
