import * as React from 'react';

export const useEventListener = (eventName, handler, element = window) => {
  const savedHandler = React.useRef(null);

  React.useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  React.useEffect(() => {
    const isSupported = element?.addEventListener;
    if (!isSupported) {
      return undefined;
    }
    const eventListener = (event) => {
      savedHandler.current(event);
    };

    element.addEventListener(eventName, eventListener);

    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element]);
};
