import { BadgeTextCss, InputPlaceholderCss, InputValueCss } from '@atomic/atm.typography';
import * as ColorFunc from 'color';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import styled, { css } from 'styled-components';

// https://react-select.com/styles
const selectCss = css`
  font-family: ${(props) => props.theme.FontFamily.Primary};
  & .select__control {
    background-color: ${(props) => props.theme.Color.White};
    border: ${(props) => props.theme.Border.WidthLarge} ${(props) => props.theme.Color.GrayLight} solid;
    overflow: auto;
    &.select__control--is-focused {
      border: ${(props) => props.theme.Border.WidthLarge} ${(props) => props.theme.Color.Accessory} solid;
      box-shadow: unset;
      &:hover {
        border: ${(props) => props.theme.Border.WidthLarge} ${(props) => props.theme.Color.Accessory} solid;
      }
    }
    &:hover {
      border-color: ${(props) => props.theme.Color.GrayLight};
      box-shadow: unset;
    }
  }
  & svg {
    fill: ${(props) => props.theme.Color.Accessory};
  }
  & .select__value-container {
    height: calc(${(props) => props.theme.FieldHeight} - 2 * ${(props) => props.theme.Border.WidthLarge});
    padding: ${(props) => props.theme.Spacing.Small};
    overflow-x: hidden;
    overflow-y: auto;
  }
  & .select__placeholder {
    ${InputPlaceholderCss};
  }
  & .select__menu {
    z-index: ${(props) => props.theme.Zindex.SelectMenu};
  }
  /* stylelint-disable block-no-empty */
  & .select__menu-list {
  }
  & .select__option {
    ${InputValueCss};
  }
  & .select__option--is-focused {
    background-color: ${(props) => props.theme.Color.Accessory}20;
  }
  & .select__option--is-selected {
    background-color: ${(props) => props.theme.Color.Accessory};
    color: ${(props) => props.theme.Color.White};
  }
  & .select__menu-notice--no-options {
    ${InputValueCss};
  }
  & .select__multi-value {
    background-color: ${(props) => props.theme.Color.Accessory}20;
    height: calc(
      ${(props) =>
        `${props.theme.FieldHeight} - 2 * ${props.theme.Spacing.Small} - 4 * ${props.theme.Border.WidthLarge}`}
    );
    align-items: center;
  }
  & .select__multi-value__label {
    ${BadgeTextCss};
    padding: ${(props) => props.theme.Spacing.Small} ${(props) => props.theme.Spacing.XSmall};
  }
  & .select__multi-value__remove:hover {
    background-color: ${(props) => ColorFunc(props.theme.Color.Accessory).lighten(0.8).hsl().string()};
  }
`;

export const SelectStyled = styled(Select).attrs({ classNamePrefix: 'select' })`
  ${selectCss}
`;

export const AsyncSelectStyled = styled(AsyncSelect).attrs({ classNamePrefix: 'select' })`
  ${selectCss}
`;
