import { AuthenticationRepository } from '@app/data/http';
import { ApolloLink, createHttpLink, FetchResult, Observable, Operation, split } from '@apollo/client';
import { ContextSetter, setContext } from '@apollo/client/link/context';
import { ErrorHandler, onError } from '@apollo/client/link/error';
import TimeoutLink from 'apollo-link-timeout';
import { WebSocketLink } from '@app/core/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import Container from 'typedi';

import { AfterHandler, GraphqlInterceptor } from './graphql.interceptor';

const FORTY_FIVE_SECONDS = 45000;
const SUBSCRIPTIONS_ROUTE = '/subscriptions';

export class ApolloLinkBuilder {
  static build = (uri: string, interceptors: GraphqlInterceptor[] = []): ApolloLink => {
    const links = interceptors.map(buildLinksFromInterceptor).reduce((acc, value) => acc.concat(...value), []);
    const timeoutLink = new TimeoutLink(FORTY_FIVE_SECONDS);
    const httpLink = createHttpLink({ uri });
    const websocketLink = buildWebsocketLink(uri);

    links.push(timeoutLink);
    links.push(buildGraphQLLink(httpLink, websocketLink));

    return ApolloLink.from(links);
  };
}

const buildLinksFromInterceptor = (interceptor: GraphqlInterceptor): ApolloLink[] => {
  const links = [];

  if (interceptor.before) {
    links.push(buildBeforeLink(interceptor.before.bind(interceptor)));
  }

  if (interceptor.after) {
    links.push(buildAfterLink(interceptor.after.bind(interceptor)));
  }

  if (interceptor.error) {
    links.push(buildErrorLink(interceptor.error.bind(interceptor)));
  }

  return links;
};

const buildBeforeLink = (handler: ContextSetter): ApolloLink => {
  return setContext(handler);
};

const buildAfterLink = (handler: AfterHandler): ApolloLink => {
  return new ApolloLink((operation: Operation, forward): Observable<FetchResult> | null => {
    return forward(operation).map((response) => handler(response, operation));
  });
};

const buildErrorLink = (handler: ErrorHandler): ApolloLink => {
  return onError(handler);
};

const buildWebsocketLink = (uri: string): WebSocketLink => {
  const baseUrl = uri.split('://')[1];
  return new WebSocketLink({
    connectionParams: () => ({ authToken: Container.get(AuthenticationRepository).getMiddlewareToken() }),
    lazy: true,
    url: `wss://${baseUrl.replace('/graphql', '')}${SUBSCRIPTIONS_ROUTE}`,
  });
};

const buildGraphQLLink = (httpLink: ApolloLink, websocketLink: WebSocketLink) => {
  // From: https://www.apollographql.com/docs/react/data/subscriptions/
  return split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    websocketLink,
    httpLink,
  );
};
