import { parseNumberValue } from '@app/components/obj.formatter/number-formatter';
import { useQueryHook } from '@app/core/graphql';
import { Credit, ResellerCredit, ResellerCreditVariables, ResellerCredit_ResellerCredit } from '@app/data/graphql';
import { NetworkStatus } from '@apollo/client';

interface ValuePurchasedOnCurrentCycle {
  valuePurchasedOnCurrentCycle: number;
  valueTotalPurchasePotencial: number;
}

export const mapResellerCredit = (
  data: ResellerCredit_ResellerCredit,
  purchasedOnCurrentCycle?: ValuePurchasedOnCurrentCycle,
) => {
  if (!data) {
    return null;
  }

  const { valuePurchasedOnCurrentCycle: valuePurchasedOnCurrentCicle = null, valueTotalPurchasePotencial = null } =
    purchasedOnCurrentCycle || {};

  return {
    available: parseNumberValue(data.available, true, 2),
    purchasePotential: parseNumberValue(valueTotalPurchasePotencial, true, 2),
    purchased: parseNumberValue(valuePurchasedOnCurrentCicle, true, 2),
    total: parseNumberValue(data.total, true, 2),
  };
};

interface ResellerCreditQueryArguments {
  id: string;
  onCompleted?: (resellerCredit: Credit) => void;
}

export const useResellerCreditQuery = ({ id, onCompleted }: ResellerCreditQueryArguments) => {
  const result = useQueryHook<ResellerCredit, ResellerCreditVariables>('reseller-credit.query', {
    notifyOnNetworkStatusChange: true,
    onCompleted: (data: ResellerCredit) => onCompleted?.(data?.ResellerCredit),
    skip: !id,
    variables: { id },
  });
  return {
    data: result.data?.ResellerCredit,
    error: result.error,
    loading: result.loading || result.networkStatus === NetworkStatus.refetch,
    networkStatus: result.networkStatus,
    refetch: () => result.refetch(),
  };
};
