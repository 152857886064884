import * as React from 'react';
import { AccordionCell } from '@app/modules/checkout/components/mol.accordion-cell/accordion-cell.component';
import { VSeparator } from '@atomic/obj.grid';
import { CartCutProductCell } from '@app/components/mol.cart-cut-product-cell/cart-cut-product-cell.component';
import { mapCartCutProducts } from '@app/view-model/cart-cut-product/cart-cut-product.vm.mapper';
import { LocalizedContext } from '@app/components/obj.localization';
import { OrderContext } from '@app/providers/order.provider';

export const CartCutProductList: React.FC = () => {
  const order = React.useContext(OrderContext);
  const localized = React.useContext(LocalizedContext).strings.components.cutProduct;
  const cutProduct = mapCartCutProducts(order.cart?.cutProducts);

  return (
    !!cutProduct?.length && (
      <>
        <AccordionCell title={localized.accordionTitle} noPadding>
          {cutProduct.map((product) => {
            return (
              <React.Fragment key={product.code}>
                <VSeparator />
                <CartCutProductCell product={product} />
              </React.Fragment>
            );
          })}
        </AccordionCell>
        <VSeparator />
      </>
    )
  );
};
