import { RemoveFromCartInput } from '@app/data/graphql';
import { useRemoveFromCartMutation } from '@app/domain/orders.use-case';
import { OrderContext } from '@app/providers';
import { CartProduct } from '@app/view-model/cart-product/cart-product.vm';
import { ApolloError } from '@apollo/client';
import * as React from 'react';
import { CartProductCellView } from './cart-product-cell-view.component';
import { GtmEventTag } from '@app/utils/data-layer';

export interface ProductCellProps extends GtmEventTag {
  product: Partial<CartProduct>;
  minValue?: number;
  maxValue?: number;
  error?: boolean;
  loading: boolean;
  onChangeQuantity: (newQuantity: number) => void;
  onRemoveFromCart?: (data: RemoveFromCartInput) => void;
  onComboDetailClick?: (quantity: number) => void;
  onError?: (error: ApolloError) => void;
}

export const CartProductCell: React.FC<ProductCellProps> = (props) => {
  const order = React.useContext(OrderContext);

  const { removeFromCart, loading: removeFromCartLoading, error: removeFromCartError } = useRemoveFromCartMutation({
    onError: props.onError,
    onSuccess: order.updateCart,
    orderCode: order?.cart?.orderCode,
  });

  return (
    <CartProductCellView
      product={props.product}
      minValue={props.minValue}
      maxValue={props.maxValue}
      error={props.error || !!removeFromCartError}
      loading={props.loading || removeFromCartLoading}
      onChangeQuantity={props.onChangeQuantity}
      onRemoveFromCart={removeFromCart}
      onComboDetailClick={props.onComboDetailClick}
      dataGtmEventAction={props.dataGtmEventAction}
      dataGtmEventCategory={props.dataGtmEventCategory}
      dataGtmEventLabel={props.dataGtmEventLabel}
    />
  );
};
