import { Card } from '@atomic/atm.card';
import { ScrollYStyle } from '@atomic/obj.mixin';
import styled from 'styled-components';

export const FastShopProductListWrapper = styled.div`
  flex: 1;
  height: 100%;
  overflow-y: auto;
`;

export const FastShopContentWrapper = styled.div`
  padding: ${(props) => props.theme.Spacing.Medium};
  display: flex;
  flex-direction: column;
  ${ScrollYStyle}
  height: calc(100vh - 200px);
`;

export const FastShopCard = styled(Card)`
  overflow: hidden;
  flex: 1;
  height: 100%;
`;
