import { Service } from 'typedi';

type GetAuthTokenFnType = () => Promise<string>;

/**
 * Proxy class to workaround DI's problem.
 *
 * This class is intended to be used when we have either a circular reference or when we
 * have a config phase that needs to be run before the real class is instantiated.
 */
@Service()
export class AuthenticationRepositoryProxy {
  private getValidBusTokenFn: GetAuthTokenFnType;
  private refreshBusTokenFn: GetAuthTokenFnType;
  private getMiddlwareTokenFn: GetAuthTokenFnType;
  private refreshMiddlewareTokenFn: GetAuthTokenFnType;

  config([getBus, refreshBus, getMiddleware, refreshMiddleware]: GetAuthTokenFnType[]) {
    this.getValidBusTokenFn = getBus;
    this.refreshBusTokenFn = refreshBus;
    this.getMiddlwareTokenFn = getMiddleware;
    this.refreshMiddlewareTokenFn = refreshMiddleware;
  }

  getValidBusToken(): Promise<string> {
    return this.getToken(this.getValidBusTokenFn);
  }

  refreshBusToken(): Promise<string> {
    return this.getToken(this.refreshBusTokenFn);
  }

  getMiddlewareToken(): Promise<string> {
    return this.getToken(this.getMiddlwareTokenFn);
  }

  refreshMiddlewareToken(): Promise<string> {
    return this.getToken(this.refreshMiddlewareTokenFn);
  }

  private async getToken(tokenGetter: GetAuthTokenFnType): Promise<string> {
    if (tokenGetter) {
      return tokenGetter();
    } else {
      return undefined;
    }
  }
}
