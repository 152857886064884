import { useEffect } from 'react';

export const useStyleTag = ({ textContent }: Partial<HTMLStyleElement>) => {
  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = textContent;
    document.body.appendChild(style);
    return () => {
      document.body.removeChild(style);
    };
  }, [textContent]);
};
