import { parseSearchToObject } from '@app/components/obj.formatter/query-param-formatter';
import { ClientError } from '@app/core/error';
import { CurrentOrders } from '@app/data/graphql';
import { useCommercialStructureListQuery } from '@app/domain/commercial-structure.use-case';
import { useCurrentConversationsQuery } from '@app/domain/current-conversations.use-case';
import { useCurrentOrdersQuery } from '@app/domain/current-orders.use-case';
import { useClientErrorFlashDispatcher } from '@app/domain/error-dispatcher.hook';
import { useConversationsQuery } from '@app/domain/queue.use-case';
import { QueueItem } from '@app/interfaces';
import { useAppType } from '@app/modules/account/use-appType';
import * as React from 'react';
import { useLocation } from 'react-router';

interface QueueSearchParams {
  resellerId: string;
}

interface QueueProviderProps {
  initialIndex?: string;
  shouldOpenCart?: boolean;
  editOrderCode?: string;
  onIndexChanged?: (index: string) => void;
  queue?: QueueResult;
}

interface CurrentOrdersResult {
  loading: boolean;
  data: CurrentOrders;
  error: ClientError;
  refetch: () => void;
}

interface QueueResult {
  loading: boolean;
  data: QueueItem[];
  error: ClientError;
  refetch: () => void;
}

interface QueueContextState {
  queueResult: QueueResult;
  currentOrders: CurrentOrdersResult;
  editOrderCode: string;
  setEditOrderCode: (orderCode: string) => void;
  activeIndex: string;
  isCartOpenedForCurrentReseller: boolean;
  handleTap: (index: string) => void;
  handleEndConversation: () => void;
  toggleOpenCartForCurrentReseller: () => void;
}

const findLastQueueItem = (queue: QueueItem[], id: string) => {
  const filteredQueue = queue && queue.filter((it) => it.id !== id);
  const newQueueItem = filteredQueue && filteredQueue.pop();
  const newQueueItemId = newQueueItem && newQueueItem.id;
  return newQueueItemId;
};

export const QueueContext = React.createContext<QueueContextState>(null);

export const QueueProvider: React.FC<QueueProviderProps> = (props) => {
  const location = useLocation<{
    shouldOpenCart?: boolean;
    editOrderCode?: string;
  }>();
  const pageSearchParams = parseSearchToObject<QueueSearchParams>(location.search);
  const initialIndex = props.initialIndex || pageSearchParams.resellerId;
  const { isStarts } = useAppType();

  const shouldOpenCart = props.shouldOpenCart || location.state?.shouldOpenCart;
  const shouldEditOrderCode = props.editOrderCode || location.state?.editOrderCode;

  const [activeIndex, setActiveIndex] = React.useState(initialIndex);
  const [cartOpenedManually, setCartOpenedManually] = React.useState(shouldOpenCart || false);
  const [cartClosedManually, setCartClosedManually] = React.useState(false);
  const [editOrderCode, setEditOrderCode] = React.useState(shouldEditOrderCode);
  const [isCartOpenedForCurrentReseller, setIsCartOpenedForCurrentReseller] = React.useState(false);

  const currentOrders = useCurrentOrdersQuery();

  const commercialStructure = useCommercialStructureListQuery();

  const activationQueueHook = useConversationsQuery(commercialStructure.data);
  const startsQueueHook = useCurrentConversationsQuery();

  useClientErrorFlashDispatcher(activationQueueHook.error);
  useClientErrorFlashDispatcher(startsQueueHook.error);

  const queue = props.queue || (isStarts ? startsQueueHook : activationQueueHook);

  const handleTap = (index: string) => {
    setCartOpenedManually(false);
    setActiveIndex(index);
    setEditOrderCode(undefined);
    if (props.onIndexChanged) {
      props.onIndexChanged(index);
    }
  };

  const handleEndConversation = () => {
    const index = findLastQueueItem(queue?.data, activeIndex);
    setActiveIndex(index);
    if (props.onIndexChanged) {
      props.onIndexChanged(index);
    }
  };

  const verifyIfHasValidCurrentOrder = React.useCallback(() => {
    const haveCurrentOrder = currentOrders.data?.ResellerCurrentOrders?.find(
      (currentOrder) => currentOrder.resellerId === activeIndex,
    );

    const isCurrentOrderValid =
      haveCurrentOrder?.expiration && new Date(haveCurrentOrder.expiration).getTime() > new Date().getTime();

    return isCurrentOrderValid;
  }, [activeIndex, currentOrders]);

  const toggleOpenCartForCurrentReseller = React.useCallback(() => {
    if (isCartOpenedForCurrentReseller) {
      setCartClosedManually(true);
      setEditOrderCode(undefined);
    } else {
      setCartOpenedManually((prevState) => !prevState);
      setCartClosedManually(false);
    }
  }, [isCartOpenedForCurrentReseller]);

  React.useEffect(() => {
    setIsCartOpenedForCurrentReseller((verifyIfHasValidCurrentOrder() || cartOpenedManually) && !cartClosedManually);
  }, [currentOrders, activeIndex, cartOpenedManually, cartClosedManually, verifyIfHasValidCurrentOrder]);

  React.useEffect(() => {
    if (queue?.data?.[0] && !activeIndex) {
      setActiveIndex(queue?.data?.[0].id);
    }
    if (queue?.data?.length === 0) {
      setActiveIndex(null);
    }
  }, [queue?.data, activeIndex]);

  return (
    <QueueContext.Provider
      value={{
        activeIndex,
        currentOrders,
        editOrderCode,
        handleEndConversation,
        handleTap,
        isCartOpenedForCurrentReseller,
        queueResult: queue,
        setEditOrderCode,
        toggleOpenCartForCurrentReseller,
      }}
    >
      {props.children}
    </QueueContext.Provider>
  );
};
