import { AppType } from '@app/components/obj.page-wrapper/mol.app-header/pathname-to-app-type.mapper';

const APP_STARTS = 'FVC_INI';
const APP_ADMIN = 'FVC_ADM';
const APP_IR_DIGITAL = 'FVC_IR';

// Todo create query to verify if she belongs to starts, activation or admin
export const filterApp = (supervisorStructures: string[]) => {
  if (supervisorStructures?.find((structure) => structure.includes(APP_STARTS))) {
    return AppType.Starts;
  }
  if (supervisorStructures?.find((structure) => structure.includes(APP_ADMIN))) {
    return AppType.Admin;
  }
  if (supervisorStructures?.find((structure) => structure.includes(APP_IR_DIGITAL))) {
    return AppType.IrDigital;
  }
  return AppType.Activation;
};
