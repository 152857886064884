import { GraphQLRequest } from '@apollo/client';
import { Container, Service } from 'typedi';

import { decodeJWT } from '@app/components/obj.jwt-decoder/jwt-decoder.service';
import { GraphqlInterceptor } from '@app/core/graphql';
import { AuthenticationRepositoryProxy } from '@app/data/http/';

interface Headers {
  [key: string]: string;
}

interface Context {
  headers: Headers;
  busTokenRefreshed?: boolean;
}

@Service()
export class MiddlewareTokenGraphqlInterceptor implements GraphqlInterceptor {
  private authDatasource = Container.get(AuthenticationRepositoryProxy);
  private isRefreshing = false;

  async before(req: GraphQLRequest, { headers = {}, busTokenRefreshed = false }: Context): Promise<Context> {
    // this removes the need of two separates instances of apollo client
    if (req.operationName === 'Auth') {
      return { headers };
    }

    await this.waitRefreshing();

    const middlewareToken = await this.getToken(busTokenRefreshed);

    if (middlewareToken) {
      headers.authorization = `Bearer ${middlewareToken}`;
    }

    return { headers };
  }

  private async getToken(busTokenRefreshed: boolean): Promise<string> {
    try {
      if (busTokenRefreshed) {
        this.isRefreshing = true;
        const middlewareToken = await this.authDatasource.refreshMiddlewareToken();

        return middlewareToken;
      } else {
        const middlewareToken = await this.authDatasource.getMiddlewareToken();
        const token = decodeJWT(middlewareToken);
        if (token.exp * 1000 < Date.now()) {
          return this.getToken(true);
        }
        return middlewareToken;
      }
    } catch (err) {
      return '';
    } finally {
      this.isRefreshing = false;
    }
  }

  private async waitRefreshing(): Promise<void> {
    while (this.isRefreshing) {
      await new Promise((resolve) => setTimeout(resolve, 50));
    }
  }
}
