import { DocumentNode } from 'graphql';
import { Service } from 'typedi';

@Service()
export class GraphQLDocsService {
  private graphQLDocs: object;
  private queryPath: string;
  private mutationPath: string;
  private subscriptionPath: string;

  constructor(queryPath: string, mutationPath: string, subscriptionPath: string, docs: any) {
    this.queryPath = queryPath;
    this.mutationPath = mutationPath;
    this.subscriptionPath = subscriptionPath;
    this.graphQLDocs = docs.default as object;
  }

  getDocument = (documentName: string): DocumentNode => {
    if (documentName.indexOf('.query') !== -1) {
      return this.getQuery(documentName);
    } else if (documentName.indexOf('.mutation') !== -1) {
      return this.getMutation(documentName);
    }

    throw new Error('GraphQL document not found. Verify document name');
  };

  getQuery = (queryName: string): DocumentNode => {
    if (!queryName.includes('.query')) {
      queryName = queryName + '.query';
    }

    const key = `${this.queryPath}/${queryName}.graphql`;
    return this.graphQLDocs[key];
  };

  getMutation = (mutationName: string): DocumentNode => {
    if (!mutationName.includes('.mutation')) {
      mutationName = mutationName + '.mutation';
    }

    const key = `${this.mutationPath}/${mutationName}.graphql`;
    return this.graphQLDocs[key];
  };

  getSubscription = (subscriptionName: string): DocumentNode => {
    if (!subscriptionName.includes('.subscription')) {
      subscriptionName = subscriptionName + '.subscription';
    }

    const key = `${this.subscriptionPath}/${subscriptionName}.graphql`;
    return this.graphQLDocs[key];
  };
}
