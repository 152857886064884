import { useEffect } from 'react';

export const useScriptTag = ({
  src,
  textContent,
  type = 'text/javascript',
  async = true,
}: Partial<HTMLScriptElement>) => {
  useEffect(() => {
    const script = document.createElement('script');
    src && (script.src = src);
    script.async = async;
    script.type = type;
    script.textContent = textContent;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [src, textContent, type, async]);
};
