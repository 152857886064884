import { FaIcon } from '@atomic/atm.fa-icon';
import { Body } from '@atomic/atm.typography';
import { ThemeToken } from '@atomic/obj.constants';
import styled from 'styled-components';
import Container from 'typedi';
import { ProgressCellProps } from './progress-cell.component';
const theme = Container.get(ThemeToken);

interface ProgressStatusStyle {
  lineColor: string;
  badgeBorderColor: string;
  badgeBackgroundColor: string;
  showNumber: boolean;
  numberColor: string;
  textColor: string;
}

const statusStyle: { [status: string]: ProgressStatusStyle } = {
  current: {
    badgeBackgroundColor: theme.Color.Primary,
    badgeBorderColor: theme.Color.Primary,
    lineColor: theme.Color.GrayLight,
    numberColor: theme.Color.White,
    showNumber: true,
    textColor: theme.Color.Black,
  },
  failed: {
    badgeBackgroundColor: theme.Color.Alert,
    badgeBorderColor: theme.Color.Alert,
    lineColor: theme.Color.Alert,
    numberColor: theme.Color.White,
    showNumber: false,
    textColor: theme.Color.Gray,
  },
  future: {
    badgeBackgroundColor: theme.Color.White,
    badgeBorderColor: theme.Color.Primary,
    lineColor: theme.Color.GrayLight,
    numberColor: theme.Color.Primary,
    showNumber: true,
    textColor: theme.Color.Gray,
  },
  past: {
    badgeBackgroundColor: theme.Color.Success,
    badgeBorderColor: theme.Color.Success,
    lineColor: theme.Color.Success,
    numberColor: theme.Color.White,
    showNumber: false,
    textColor: theme.Color.Gray,
  },
  waiting: {
    badgeBackgroundColor: theme.Color.Warning,
    badgeBorderColor: theme.Color.Warning,
    lineColor: theme.Color.Warning,
    numberColor: theme.Color.White,
    showNumber: false,
    textColor: theme.Color.Gray,
  },
};

export const ProgressCellStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  & + ::before {
    position: relative;
    top: 18px;
    left: -50%;
    z-index: ${(props) => props.theme.Zindex.ProgressCellStyled};
    display: inline-block;
    width: 100%;
    height: 2px;
    background-color: ${(props: ProgressCellProps) => statusStyle[props.status].lineColor};
    content: '';
  }
`;

export const ProgressCellBadgeStyled = styled.div`
  height: 32px;
  width: 32px;
  border-radius: 16px;
  border-width: 2px;
  border-color: ${(props: ProgressCellProps) => statusStyle[props.status].badgeBorderColor};
  background-color: ${(props: ProgressCellProps) => statusStyle[props.status].badgeBackgroundColor};
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-self: center;
  z-index: ${(props) => props.theme.Zindex.ProgressCellBadgeStyled};
`;

export const ProgressCellChecked = styled(FaIcon.Check)`
  color: ${(props) => props.theme.Color.White};
  display: ${(props: ProgressCellProps) => (statusStyle[props.status].showNumber ? 'none' : 'block')} !important;
  align-self: center;
  font-size: 14px;
`;

export const ProgressCellFailed = styled(FaIcon.Close)`
  color: ${(props) => props.theme.Color.White};
  display: ${(props: ProgressCellProps) => (statusStyle[props.status].showNumber ? 'none' : 'block')} !important;
  align-self: center;
  font-size: 14px;
`;

export const ProgressCellWaiting = styled(FaIcon.Clock)`
  color: ${(props) => props.theme.Color.White};
  display: ${(props: ProgressCellProps) => (statusStyle[props.status].showNumber ? 'none' : 'block')} !important;
  align-self: center;
  font-size: 14px;
`;

export const ProgressCellNumberStyled = styled(Body)`
  position: relative;
  display: ${(props: ProgressCellProps) => (statusStyle[props.status].showNumber ? 'block' : 'none')};
  text-align: center;
  color: ${(props: ProgressCellProps) => statusStyle[props.status].numberColor};
`;

export const ProgressCellTextStyled = styled.label`
  text-align: center;
  color: ${(props: ProgressCellProps) => statusStyle[props.status].textColor};
  font-size: ${(props) => props.theme.FontSize.Medium};
  font-family: ${(props) => props.theme.FontFamily.Primary};
  font-weight: ${(props) => props.theme.FontWeight.Bold};
  margin-top: ${(props) => props.theme.Spacing.Small};
`;
