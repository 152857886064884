import styled from 'styled-components';

export const ScreenPlaceholderStyled = styled.div`
  padding: ${(props) => props.theme.Spacing.Medium};
  text-align: center;
`;

const imageSize = 80;
export const ScreenPlaceholderImageWrapperStyled = styled.div`
  width: ${imageSize}px;
  height: ${imageSize}px;
  margin: ${(props) => props.theme.Spacing.Medium} auto;
`;

export const ScreenPlaceholderActionStyled = styled.div`
  margin-top: ${(props) => props.theme.Spacing.Large};
`;
