import { CartCutProduct } from '@app/data/graphql';
import { CartCutProductItem } from '@app/view-model/cart-cut-product/cart-cut-product.vm';

export const mapCartCutProducts = (products: CartCutProduct[]): CartCutProductItem[] => {
  return products?.map((product) => ({
    code: product.code,
    cutReason: product.cutReason.description,
    name: product.name,
    quantity: product.quantity,
    type: product.type.description,
    unitMarketValue: product.unitMarketValue,
  }));
};
