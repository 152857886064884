import styled from 'styled-components';
import { FieldBorderCss, FieldCss } from '@atomic/obj.form/field.component.style';
import { ScrollYStyle } from '@atomic/obj.mixin';

const TEXT_AREA_HEIGHT = '20vh';

export const TextAreaStyled = styled.textarea`
  ${FieldBorderCss}
  ${FieldCss}
  ${ScrollYStyle}
  max-width: 100%;
  min-height: 100px;
`;

export const TextMaskStyled = styled.div`
  ${FieldBorderCss}
  ${FieldCss}
  height: 100%;
  width: 100%;
  overflow-wrap: anywhere;
`;

export const InvisibleTextAreaStyled = styled.textarea`
  ${FieldBorderCss}
  ${FieldCss}
  ${ScrollYStyle}
  width: 100%;
  height: 100%;
  background: transparent;
  color: transparent;
  caret-color: ${(props) => props.theme.Color.Black};
  position: absolute;
  resize: inherit;
  z-index: ${(props) => props.theme.Zindex.InvisibleTextArea};
`;

export const NotAllowedTextAreaWrapper = styled.div`
  ${FieldCss}
  height: ${TEXT_AREA_HEIGHT};
  padding: 0;
  min-height: 100px;
  max-width: 100%;
  overflow: auto;
  position: relative;
`;
