import { useQueryHook } from '@app/core/graphql';
import { FvcResellerConversation, FvcResellerConversationVariables } from '@app/data/graphql';
import { mapApolloError } from '@app/data/graphql/graphql-error.mapper';
import { QueueItem } from '@app/interfaces';
import { NetworkStatus } from '@apollo/client';
import { useAppType } from '@app/modules/account/use-appType';

const mapConversations = (data: FvcResellerConversation) => {
  if (!data || !data.FvcResellerConversation) {
    return null;
  }
  const queue: QueueItem[] = data.FvcResellerConversation.map((node) => ({
    createdAt: node.createdAt,
    id: node.resellerId,
    isScheduling: node.isScheduling,
    name: node.resellerName,
  })).reverse();
  return queue;
};

export const useConversationsQuery = (commercialStructure: string) => {
  const { isStarts } = useAppType();
  const skipQuery = !commercialStructure ?? isStarts;
  const result = useQueryHook<FvcResellerConversation, FvcResellerConversationVariables>('conversation-list.query', {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    skip: skipQuery,
    variables: { commercialStructure },
  });

  return {
    data: mapConversations(result.data),
    error: result.error && mapApolloError(result.error),
    loading: result.loading || result.networkStatus === NetworkStatus.refetch,
    refetch: () => result.refetch,
  };
};
