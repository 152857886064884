import * as React from 'react';
import { Card } from '@atomic/atm.card';
import { Hbox } from '@atomic/obj.box';
import { H4, BodySecondary, DT, DD } from '@atomic/atm.typography';
import { LocalizedContext } from '@app/components/obj.localization';
import { CartCutProductItem } from '@app/view-model/cart-cut-product/cart-cut-product.vm';
import { VSeparator } from '@atomic/obj.grid';

interface CartCutProductProps {
  product: CartCutProductItem;
}

export const CartCutProductCell: React.FC<CartCutProductProps> = (props) => {
  const { product } = props;
  const localized = React.useContext(LocalizedContext).strings.components.cutProduct;

  return (
    <Card bordered>
      <Hbox>
        <Hbox.Item>
          <H4>{product.code}</H4>
          <VSeparator small />
          <BodySecondary>{product.name}</BodySecondary>
        </Hbox.Item>
        <Hbox.Separator />
        <Hbox.Item vAlign='center' hAlign='center' noGrow>
          <DT>{localized.quantity}</DT>
          <DD>{product.quantity}</DD>
        </Hbox.Item>
      </Hbox>
    </Card>
  );
};
