import { Theme } from '@atomic/obj.constants';
import styled from 'styled-components';

interface MenuLinkStyledProps {
  selected?: boolean;
}

export const MenuLinkWrapperStyled = styled.div`
  display: flex;
  flex: 1;
  padding: 0 ${(props) => props.theme.Spacing.XLarge};
  align-items: center;
`;

export const MenuLinkIconStyled = styled.div.attrs((props: MenuLinkStyledProps & Theme) => ({
  color: props.selected ? props.theme.Color.Primary : props.theme.Color.GrayDark,
  height: props.theme.IconSize.Large,
  width: props.theme.IconSize.Large,
}))``;

export const MenuLinkTitleStyled = styled.label`
  position: relative;
  color: ${(props) => props.theme.Color.GrayDark};
  font-family: ${(props) => props.theme.FontFamily.Primary};
  font-size: ${(props) => props.theme.FontSize.Large};
  font-weight: ${(props) => props.theme.FontWeight.Normal};
  transition: all ${(props) => props.theme.TransitionDuration} ease-in-out;

  &::after {
    display: block;
    position: absolute;
    content: '';
    width: 100%;
    height: 2px;
    background-color: ${(props) => props.theme.Color.White};
    bottom: -4px;
    transition: all ${(props) => props.theme.TransitionDuration} ease-in-out;
    opacity: 0;
  }

  ${(props: MenuLinkStyledProps & Theme) =>
    props.selected
      ? `
    &::after {
      opacity: 1;
    }

    color: ${props.theme.Color.Primary};
  `
      : `
    cursor: pointer;
  `}

  &:hover {
    &::after {
      opacity: 1;
    }
  }

  @media all and (min-width: ${(props) => props.theme.Breakpoint.md}em) {
    color: ${(props) => props.theme.Color.White};
  }
`;

export const DeskMenuLinkStyled = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(props) => props.theme.Spacing.Medium};
`;

export const MobileMenuLinkStyled = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: ${(props) => props.theme.Spacing.Medium};
  margin-bottom: ${(props) => props.theme.Spacing.Small};
  height: 80px;

  &::before {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    top: 8px;
    right: 0;
    border-top: 32px ${(props) => props.theme.Color.Primary} solid;
    border-top-left-radius: 50%;
    border-bottom: 32px ${(props) => props.theme.Color.Primary} solid;
    border-bottom-left-radius: 50%;

    transition: width 0.3s;
  }

  ${(props: MenuLinkStyledProps) =>
    props.selected
      ? `
    &::before {
      width: 8px;
    }
  `
      : `

    &:hover:before {
      width: 8px;
    }
  `};
`;
