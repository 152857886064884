import { EventAction, EventLabel, GtmEventTag } from '@app/utils/data-layer';
import { TextField } from '@atomic/atm.text-field';
import { Hbox } from '@atomic/obj.box';
import { Form } from '@atomic/obj.form/form.component';
import * as React from 'react';

import {
  StepperCounterStyled,
  StepperMinusStyled,
  StepperPlusStyled,
  StepperWrapperStyled,
} from './stepper.component.style';

export interface StepperProps extends GtmEventTag {
  value: number;
  minValue?: number;
  maxValue?: number;
  disablePlus?: boolean;
  disableMinus?: boolean;
  disableText?: boolean;
  onChangeValue: (newValue: number) => void;
  onDebouncedChangeValue?: (newValue: number) => void;
}

export const Stepper: React.FC<StepperProps> = (props) => {
  const { value, onChangeValue, onDebouncedChangeValue, minValue, maxValue, disableMinus, disablePlus } = props;
  const minusDisabled = value <= minValue || disableMinus;
  const plusDisabled = value >= maxValue || disablePlus;

  const handleMinusClick = () => {
    onChangeValue(value - 1);
    onDebouncedChangeValue?.(value - 1);
  };

  const handlePlusClick = () => {
    onChangeValue(value + 1);
    onDebouncedChangeValue?.(value + 1);
  };

  const handleTextValue = (newValue: string) => {
    const filteredNewValue = +newValue >= minValue ? +newValue : minValue;
    onChangeValue(filteredNewValue);
    onDebouncedChangeValue?.(filteredNewValue);
  };

  return (
    <Hbox>
      <Hbox.Item vAlign='center' hAlign='flex-end'>
        <StepperWrapperStyled
          disabled={minusDisabled}
          onClick={handleMinusClick}
          type='button'
          size='small'
          data-gtm-event-action={props.dataGtmEventAction ?? EventAction.Click.Button}
          data-gtm-event-category={props.dataGtmEventCategory}
          data-gtm-event-label={props.dataGtmEventLabel ?? EventLabel.Stepper.Minus}
        >
          <StepperMinusStyled />
        </StepperWrapperStyled>
      </Hbox.Item>
      <Hbox.Separator />
      <Hbox.Item hAlign='center' vAlign='center' noGrow>
        <StepperCounterStyled>
          <Form.Field onValueChange={props.disableText ? null : handleTextValue} value={value} name='productQuantity'>
            <TextField textAlign='center' type='number' small />
          </Form.Field>
        </StepperCounterStyled>
      </Hbox.Item>
      <Hbox.Separator />
      <Hbox.Item hAlign='flex-start'>
        <StepperWrapperStyled
          disabled={plusDisabled}
          onClick={handlePlusClick}
          type='button'
          size='small'
          data-gtm-event-action={props.dataGtmEventAction ?? EventAction.Click.Button}
          data-gtm-event-category={props.dataGtmEventCategory}
          data-gtm-event-label={props.dataGtmEventLabel ?? EventLabel.Stepper.Plus}
        >
          <StepperPlusStyled />
        </StepperWrapperStyled>
      </Hbox.Item>
    </Hbox>
  );
};
