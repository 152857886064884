import { FlashDispatcherService } from '@app/components/obj.flash-wrapper/flash-dispatcher.service';
import { LocalizedContext } from '@app/components/obj.localization';
import { GraphQLDocsService, useMutationHook } from '@app/core/graphql';
import {
  ConversationStatusEnum,
  CurrentConversations,
  StartResellerConversation,
  StartResellerConversationVariables,
} from '@app/data/graphql';
import { useCurrentConversationsQuery } from '@app/domain/current-conversations.use-case';
import { QUEUE_LIMITS } from '@app/modules/modules.token';
import Container from 'typedi';
import { useContext } from 'react';

export const useStartResellerConversation = (
  commercialStructure: string,
  onCompleted: (resellerId: string) => void,
  blockedIds?: string[],
) => {
  const refetch = [{ query: 'current-conversations.query', variables: { commercialStructure } }];

  const [mutate, startResellerConversationResult] = useMutationHook<
    StartResellerConversation,
    StartResellerConversationVariables
  >({
    mutationName: 'start-reseller-conversation',
    refetchQueries: refetch,
  });
  const graphqlDocsService = Container.get(GraphQLDocsService);
  const document = graphqlDocsService.getQuery('current-conversations');
  const currentConversations = useCurrentConversationsQuery();
  const flashMessageService = Container.get(FlashDispatcherService);
  const limitErrorMessage = useContext(LocalizedContext).strings.components.queue.limitReached;

  const startResellerConversation = (id: string, name?: string) => {
    const queueItem = blockedIds?.find((item) => item === id);
    const queueLimit = Container.get(QUEUE_LIMITS);

    if (!queueItem) {
      if (currentConversations.data.length < queueLimit.starts) {
        mutate({
          optimisticResponse: {
            StartResellerConversation: {
              __typename: 'ResellerConversationResponse',
              reseller: {
                __typename: 'ResellerResponse',
                name: name,
                referenceId: id,
              },
              status: ConversationStatusEnum.Receptive,
            },
          },
          update: (cache, { data }) => {
            const conversationListQueryCached = cache.readQuery<CurrentConversations>({
              query: document,
              variables: { commercialStructure },
            });

            cache.writeQuery({
              data: Object.assign({}, conversationListQueryCached, {
                CurrentConversations: {
                  ...conversationListQueryCached.CurrentConversations,
                  conversations: [
                    ...conversationListQueryCached.CurrentConversations.conversations,
                    {
                      __typename: 'CurrentConversationsItemResponse',
                      createdAt: new Date(),
                      resellerId: data.StartResellerConversation.reseller.referenceId,
                      resellerName: data.StartResellerConversation.reseller.name,
                    },
                  ],
                },
              }),
              query: document,
              variables: { commercialStructure },
            });
            onCompleted(data.StartResellerConversation?.reseller?.referenceId);
          },
          variables: {
            resellerId: id,
          },
        });
      } else {
        flashMessageService.dispatchMessage({ message: limitErrorMessage }, 'alert');
      }
    } else {
      onCompleted(id);
    }
  };

  return { startResellerConversation, startResellerConversationResult };
};
