import * as React from 'react';

import { FaIcon } from '@atomic/atm.fa-icon';

import {
  ModalBoxStyled,
  ModalBoxWrapperStyled,
  ModalCloseStyled,
  ModalOpacityStyled,
  ModalStyled,
} from './modal.component.style';
import { EventAction, EventLabel } from '@app/utils/data-layer';

export interface ModalProps {
  small?: boolean;
  opened: boolean;
  onClose: () => void;
  isScrollable?: boolean;
  isScheduling?: boolean;
  full?: boolean;
  gtmEventCategory?: string;
}

export const Modal: React.FC<ModalProps> = (props) => {
  const handleClose = () => props.onClose();

  return (
    <ModalStyled opened={props.opened}>
      <ModalOpacityStyled opened={props.opened} onClick={handleClose} />
      {props.opened && (
        <ModalBoxWrapperStyled small={props.small} full={props.full}>
          <ModalBoxStyled isScrollable={props.isScrollable} isScheduling={props.isScheduling} full={props.full}>
            {props.children}
          </ModalBoxStyled>
          <ModalCloseStyled
            data-cy='close-modal'
            data-gtm-event-category={props.gtmEventCategory}
            data-gtm-event-action={EventAction.Click.Button}
            data-gtm-event-label={EventLabel.Close}
          >
            <FaIcon.Close onClick={handleClose} />
          </ModalCloseStyled>
        </ModalBoxWrapperStyled>
      )}
    </ModalStyled>
  );
};
