export enum ShadesOfGray {
  GrayXLight = '#F2F2F2',
  GrayLight = '#DEDEDE',
  Gray = '#757575',
  GrayDark = '#525252',
  GrayXDark = '#333333',
}

enum FontWeight {
  Medium = 500,
  Bold = 'bold',
  Normal = 'normal',
  Lighter = 300,
}

enum FontSize {
  XSmall = '12px',
  Small = '14px',
  Medium = '16px',
  Large = '20px',
  XLarge = '24px',
}

enum Spacing {
  XSmall = '4px',
  Small = '8px',
  Medium = '16px',
  Large = '24px',
  XLarge = '32px',
}

const HeaderHeight = {
  Desk: '80px',
  Mobile: '64px',
};

enum IconSize {
  XSmall = '12px',
  Small = '16px',
  Medium = '20px',
  Large = '32px',
  XLarge = '80px',
}

enum FaIconSize {
  XSmall = 'xs',
  Large = 'lg',
  Small = 'sm',
  X1 = '1x',
  X2 = '2x',
  X3 = '3x',
  X4 = '4x',
  X5 = '5x',
  X6 = '6x',
  X7 = '7x',
  X8 = '8x',
  X9 = '9x',
  X10 = '10x',
}

// Based on https://material.io/design/environment/light-shadows.html#shadows
enum BoxShadow {
  Depth2 = '0 1px 2px 0',
  Depth2Negative = '0 -1px 2px 0',
  Depth6 = '0 3px 6px 1px',
  Depth12 = '0 6px 12px 3px',
}

enum Zindex {
  DrawerMenuStyledInactive = -2,
  DrawerStyledInactive = -1,
  DrawerOverlayStyledInactive = -1,
  DropDownMenuInactive = -1,
  // BELOW starts z-index: 0
  CheckboxUncheckedStyled,
  CheckboxCheckedStyled,
  CheckboxFieldTextStyled,
  FileFieldInputStyled,
  FileFieldIconStyled,
  FileFieldClearStyled,
  TextFieldIconStyled,
  InvisibleTextArea,
  UploadFileButtonStyled,
  FileUploadFieldStyled,
  ProgressCellStyled,
  ProgressCellBadgeStyled,
  GoalProgressSecondaryBarStyled,
  GoalProgressBarStyled,
  GoalProgressBarIconWrapperStyled,
  GoalProgressBarIconStyled,
  DockedWrapperStyled,
  HomeWhatsappStyled,
  StickyHeaderStyled,
  notificationDialogStyled,
  TooltipContentBoxStyled,
  SelectMenu,
  DropDownMenuActive,
  DrawerOverlayStyledActive,
  DrawerMenuStyledActive,
  DrawerStyledActive,
  ModalOpacityStyled,
  ModalBoxWrapperStyled,
  ModalBoxStyled,
  ModalCloseStyled,
  AutoCompleteWrapper,
  FlashMessageWrapperStyled,
  BannerMessageWrapperStyled,
}

enum WhatsAppColors {
  CenterBalloon = '#E1F3FB',
  RightBalloon = '#DCF8C6',
  LeftBalloon = '#FFFFFF',
  BackGroundColor = '#E5DDD5',
}

const Navbar = {
  a: '1',
};

const FieldHeight = '48px';
const TransitionDuration = '.3s';

const Border = {
  Color: ShadesOfGray.GrayLight,
  Radius: '4px',
  RadiusLarge: '24px',
  Width: '1px',
  WidthLarge: '2px',
};

// Security recommendation: Failing to enforce passwords of at least 7 characters, a complexity
// of at least alpha and numeric characters increases the risk of a brute force attack.
enum PasswordLength {
  Max = 18,
  Min = 7,
}

enum AspectRatio {
  Square = 1,
}

const Breakpoint = {
  // em
  lg: 64,

  // em
  md: 48,

  // em
  sm: 32,

  xs: 0, // em
};

const Background = {
  Color: ShadesOfGray.GrayXLight,
};

export const HalfScreenHeight = '50vh';

const GridSettings = {
  flexboxgrid: {
    // rem
    breakpoints: {
      // em
      lg: 64,

      // em
      md: 48,

      // em
      sm: 32,

      xs: 0, // em
    },

    gridSize: 12,

    // Defaults
    gutterWidth: 1,
    // rem
    outerMargin: 2,
  },
};

export const defaultTheme = {
  ...GridSettings,
  AspectRatio,
  Background,
  Border,
  BoxShadow,
  Breakpoint,
  FaIconSize,
  FieldHeight,
  FontSize,
  FontWeight,
  HeaderHeight,
  IconSize,
  Navbar,
  PasswordLength,
  Spacing,
  TransitionDuration,
  WhatsAppColors,
  Zindex,
};
