import { MessageNotifications } from '@app/components/org.whatsapp/mol.message-notifications';
import { useAttendanceMessageNotifications } from '@app/components/org.whatsapp/mol.message-notifications/message-notifications.hook';
import { EventAction, EventCategory, EventLabel } from '@app/utils/data-layer';
import * as React from 'react';

export const AttendanceMessageNotifications: React.FC = () => {
  const {
    error,
    handleStartConversation,
    interactions,
    loading,
    refetch,
    startConversationError,
    hasNextPage,
    interactionsLength,
    onClose,
    onLoadMore,
  } = useAttendanceMessageNotifications();

  return (
    <MessageNotifications
      dataGtmEventCategory={EventCategory.Attendence}
      dataGtmEventAction={EventAction.Click.WhatsApp}
      dataGtmEventLabel={EventLabel.Attendence.Notification}
      hasNextPage={hasNextPage}
      interactionsLength={interactionsLength}
      onClose={onClose}
      onLoadMore={onLoadMore}
      error={error}
      startConversation={handleStartConversation}
      interactions={interactions}
      loading={loading}
      refetch={refetch}
      startConversationError={startConversationError}
    />
  );
};
