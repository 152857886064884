import { ApolloError } from '@apollo/client';
import * as React from 'react';
import { FlashDispatcherService } from '@app/components/obj.flash-wrapper/flash-dispatcher.service';
import Container from 'typedi';
import { LARGE_BUTTON_HEIGHT } from '@app/modules/activation/home/components/atm.large-button';
import { ClientError } from '@app/core/error';

const flashDispatcherService = Container.get(FlashDispatcherService);

export const useErrorFlashDispatcher = (error: ApolloError, height: string = LARGE_BUTTON_HEIGHT, hideUuid = false) => {
  React.useEffect(() => {
    if (error) {
      const fullMessage = mapErrorMessage(error, hideUuid);
      flashDispatcherService.dispatchMessage(
        { additionalInfo: 'error-dispatcher.hook.ts:15', error: fullMessage.error, message: fullMessage.message },
        'warning',
        height,
      );
    }
  }, [error, height, hideUuid]);
};

export function mapErrorMessage(error: ApolloError, hideUuid = false) {
  const uuid = (error as any).graphQLErrors?.[0]?.uuid || (error as any).networkError?.result?.errors?.[0]?.uuid;
  const message =
    error.graphQLErrors?.[0]?.message || (error as any).networkError?.result?.errors?.[0]?.message || error.message;
  if (hideUuid) {
    return {
      message,
    };
  } else {
    return {
      error: `Uuid ${uuid ?? 'não encontrado'}.`,
      message,
    };
  }
}

export const useClientErrorFlashDispatcher = (
  error: ClientError,
  height: string = LARGE_BUTTON_HEIGHT,
  hideUuid = false,
) => {
  React.useEffect(() => {
    if (error) {
      const fullMessage = mapClientErrorMessage(error, hideUuid);
      flashDispatcherService.dispatchMessage(
        { additionalInfo: 'error-dispatcher.hook.ts:48', error: fullMessage.error, message: fullMessage.message },
        'warning',
        height,
      );
    }
  }, [error, height, hideUuid]);
};

const mapClientErrorMessage = (error: ClientError, hideUuid = false) => {
  const errorMessage = `Uuid ${error.uuid ?? 'não encontrado'}`;
  if (hideUuid) {
    return {
      message: error.message,
    };
  } else {
    return {
      error: errorMessage,
      message: error.message,
    };
  }
};
