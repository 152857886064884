import { ApolloError } from '@apollo/client';
import { CartListWrapper } from '@app/components/atm.cart-section-wrapper';
import { LocalizedContext } from '@app/components/obj.localization';
import { BusinessModelResponse } from '@app/data/graphql';
import { mapBusinessModels } from '@app/domain/business-models.use-case';
import { EventAction, EventCategory, EventLabel } from '@app/utils/data-layer';
import { CheckboxField } from '@atomic/atm.checkbox';
import { Divisor } from '@atomic/atm.divisor';
import { Body } from '@atomic/atm.typography';
import { RadioIconList } from '@atomic/mol.radio-icon-list/radio-icon-list.component';
import { ShimmerInput } from '@atomic/mol.shimmer';
import { VSeparator } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import * as React from 'react';

export const RESTORE_CANCELLED_ORDER_ID = 'restoreCancelledOrder';

interface BusinessModelSelectorProps {
  businessModels: { data: BusinessModelResponse[]; loading: boolean; error: ApolloError };
  handleRestoreOrderSelection: (checked: boolean) => void;
  handleBusinessModelChange: (value: string) => void;
  hasCurrentOrder: boolean;
  recoverLastOrder: boolean;
}

export const BusinessModelSelector = ({
  businessModels,
  handleRestoreOrderSelection,
  handleBusinessModelChange,
  recoverLastOrder,
  hasCurrentOrder,
}: BusinessModelSelectorProps) => {
  const localized = React.useContext(LocalizedContext).strings.components.cartPages;

  const mappedBusinessModels = mapBusinessModels(businessModels.data);

  return (
    <>
      <VSeparator />
      <Body>{localized.chooseBusinessModel}</Body>
      <VSeparator />
      <CartListWrapper>
        <LoadingState loading={businessModels.loading} error={!!businessModels.error} data={!!businessModels.data}>
          <LoadingState.Shimmer>
            <ShimmerInput height='60px' />
            <VSeparator small />
            <ShimmerInput height='60px' />
            <VSeparator small />
            <ShimmerInput height='60px' />
          </LoadingState.Shimmer>
          <RadioIconList
            dataCy='address-selector-list'
            fieldName='address'
            list={mappedBusinessModels}
            onValueChange={handleBusinessModelChange}
          />
        </LoadingState>
      </CartListWrapper>
      <VSeparator />
      <Divisor />
      <VSeparator />
      {!hasCurrentOrder && (
        <>
          <CheckboxField
            id={RESTORE_CANCELLED_ORDER_ID}
            dataGtmEventAction={EventAction.Click.Checkbox}
            dataGtmEventCategory={EventCategory.Attendence}
            dataGtmEventLabel={EventLabel.Attendence.Order.CopyLastOrder}
            checked={recoverLastOrder}
            onValueChange={(_, checked) => handleRestoreOrderSelection(checked)}
          >
            {localized.copyLastCancelledOrder}
          </CheckboxField>

          <VSeparator />
        </>
      )}
    </>
  );
};
