import { Fade } from '@app/components/obj.animation/animation.component.style';
import { ScrollYStyle } from '@atomic/obj.mixin';
import styled from 'styled-components';

export const PageWrapperStyled = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;
`;

export const HeadlessPageWrapperBkgStyled = styled(PageWrapperStyled)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url('assets/img/img_background.png') repeat;
`;

export const HeadlessPageWrapperContentStyled = styled(Fade)`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 2px;
  background-color: ${(props) => props.theme.Color.White};
  overflow: hidden;
  padding: ${(props) => props.theme.Spacing.XLarge};
  max-height: 75vh;

  width: 90%;

  @media all and (min-width: ${(props) => props.theme.Breakpoint.sm}em) {
    width: 70%;
  }

  @media all and (min-width: ${(props) => props.theme.Breakpoint.md}em) {
    width: 35%;
  }
`;

export const PageWrapperContentStyled = styled.div`
  overflow-x: hidden;
  background-color: ${(props) => props.theme.Color.GrayXLight};
  ${ScrollYStyle}

  height: calc(100vh - ${(props) => props.theme.HeaderHeight.Mobile});

  @media all and (min-width: ${(props) => props.theme.Breakpoint.md}em) {
    height: calc(100vh - ${(props) => props.theme.HeaderHeight.Desk});
  }
`;
