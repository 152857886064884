import * as React from 'react';

import { Button } from '@atomic/atm.button/button.component';

import { ScreenPlaceholder, ScreenPlaceholderProps } from './screen-placeholder.component';
import { Separator } from '@atomic/obj.box';
import { Row, Col } from '@atomic/obj.grid';

export enum ScreenPlaceholderKind {
  emptyList,
  error500,
  error404,
  internalError,
  wip,
}

export interface ScreenPlaceholderOptionsProps {
  kind: ScreenPlaceholderKind;
  customMessage?: string;
  to?: any;
}

export class ScreenPlaceholderOptions extends React.PureComponent<ScreenPlaceholderOptionsProps, any> {
  render() {
    const config = this.getPlaceholderConfig();
    if (this.props.customMessage) {
      config.message = this.props.customMessage;
    }
    return (
      <>
        <Separator />
        <Row center='xs'>
          <Col xs={12} lg={6}>
            <ScreenPlaceholder {...config}>{this.getPlaceholderAction() ?? this.props.children}</ScreenPlaceholder>
          </Col>
        </Row>
      </>
    );
  }

  private getPlaceholderConfig: () => ScreenPlaceholderProps = () => {
    switch (this.props.kind) {
      case ScreenPlaceholderKind.emptyList:
        return {
          message: 'Tente novamente com outros filtros',
          src: 'assets/icons/ic-error.svg',
          title: 'Sem resultados para os filtros',
        };
      case ScreenPlaceholderKind.internalError:
        return {
          message: 'Desculpe-nos pelo transtorno.',
          src: 'assets/icons/ic-error.svg',
          title: 'Ocorreu um erro interno',
        };
      case ScreenPlaceholderKind.error500:
        return {
          message: 'Desculpe-nos pelo transtorno.',
          src: 'assets/icons/ic-error.svg',
          title: 'Ocorreu um erro',
        };
      case ScreenPlaceholderKind.error404:
        return {
          message: '',
          src: 'assets/icons/ic-error.svg',
          title: 'Página não encontrada',
        };
      case ScreenPlaceholderKind.wip:
        return {
          message: 'Essa página ainda não está disponível. Desculpe-nos pelo transtorno.',
          src: 'assets/icons/ic-error.svg',
          title: 'Página em desenvolvimento',
        };
    }
  };

  private getPlaceholderAction = () => {
    if (this.props.kind === ScreenPlaceholderKind.error500) {
      return (
        <Button expanded to={this.props.to}>
          {'Tentar novamente'}
        </Button>
      );
    } else if (this.props.kind === ScreenPlaceholderKind.error404) {
      return (
        <Button expanded to={this.props.to}>
          {'Voltar a home'}
        </Button>
      );
    } else if (this.props.kind === ScreenPlaceholderKind.emptyList) {
      return (
        <Button expanded to={this.props.to}>
          {'Alterar filtros'}
        </Button>
      );
    } else if (this.props.kind === ScreenPlaceholderKind.internalError) {
      return null;
    } else {
      console.error('ScreenPlaceholder', 'getPlaceholderAction', 'invalid option');
      return null;
    }
  };
}
