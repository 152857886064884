import { InputValue } from '@atomic/atm.typography';
import { HighlightStyle } from '@atomic/obj.mixin';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

export const checkboxHeight = 24;

export const CheckboxFieldStyled = styled.div`
  position: relative;
  cursor: pointer;

  & ~ * {
    margin-top: ${(props) => props.theme.Spacing.XSmall};
  }
`;

export const CheckboxFieldTextStyled = styled(InputValue)`
  padding-left: ${(props) => props.theme.Spacing.XLarge};
  line-height: ${checkboxHeight}px;
  z-index: ${(props) => props.theme.Zindex.CheckboxFieldTextStyled};
  ${HighlightStyle};
`;

export const CheckboxCheckedStyled = styled(FontAwesomeIcon).attrs({ icon: 'check-square' })`
  position: absolute;
  font-size: ${checkboxHeight}px !important;
  color: ${(props) => props.theme.Color.Accessory};
  z-index: ${(props) => props.theme.Zindex.CheckboxCheckedStyled};
  opacity: 0;
`;

export const CheckboxUncheckedStyled = styled(FontAwesomeIcon).attrs({ icon: ['far', 'square'] })`
  position: absolute;
  font-size: ${checkboxHeight}px !important;
  color: ${(props) => props.theme.Color.Black};
  z-index: ${(props) => props.theme.Zindex.CheckboxUncheckedStyled};
  opacity: 1;
`;

export const CheckboxFieldBulletStyled = styled.input`
  display: none;

  &:checked + ${CheckboxCheckedStyled} {
    opacity: 1;
    + ${CheckboxUncheckedStyled} {
      opacity: 0;
    }
  }
`;
