import { InputDisabledCss, InputValueCss } from '@atomic/atm.typography';
import { Theme } from '@atomic/obj.constants';
import { css } from 'styled-components';

export interface FieldProps extends Theme {
  invalid?: boolean;
  disabled?: boolean;
}

export const FieldBorderCss = css`
  border-radius: ${(props) => props.theme.Border.Radius};
  overflow: hidden;
  border: ${(props) => props.theme.Border.Width} solid;
  border-color: ${(props: FieldProps) => (props.invalid ? props.theme.Color.Alert : props.theme.Color.GrayLight)};

  :focus {
    border-color: ${(props) => props.theme.Color.Primary};
    /* Box-shadow instead of border-width, so the text won't dance */
    box-shadow: inset 0 0 0 ${(props) => props.theme.Border.Width} ${(props) => props.theme.Color.Primary};
  }

  @media all and (min-width: 48em) {
    :hover {
      box-shadow: inset 0 0 0 ${(props) => props.theme.Border.Width} ${(props) => props.theme.Color.Gray};
      border-color: ${(props) => props.theme.Color.Gray};
    }
  }
`;

export const FieldTypographyCss = css`
  ${(props: FieldProps) => (props.disabled ? InputDisabledCss : InputValueCss)}
  font-family: ${(props) => props.theme.FontFamily.Primary};
  font-size: ${(props) => props.theme.FontSize.Small};
`;

export const FieldCss = css`
  ${FieldTypographyCss}
  width: 100%;
  height: ${(props) => props.theme.FieldHeight};
  padding: ${(props) => props.theme.Spacing.Medium};
  align-self: stretch;
  background-color: ${(props) => props.theme.Color.White};

  :focus {
    outline: none;
  }

  /* https://stackoverflow.com/a/38573257/3670829 */
  -webkit-appearance: none;

  transition: all 0.3s;
`;
