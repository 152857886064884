import { CartProductCell } from '@app/components/mol.cart-product-cell';
import { useErrorFlashDispatcher } from '@app/domain/error-dispatcher.hook';
import { useChangeProductQuantityMutation } from '@app/domain/orders.use-case';
import { OrderContext } from '@app/providers/order.provider';
import { debounce } from '@app/utils/debounce';
import { CartProduct } from '@app/view-model/cart-product/cart-product.vm';
import { mapCartProducts } from '@app/view-model/cart-product/cart-product.vm.mapper';
import { VSeparator } from '@atomic/obj.grid';
import { ApolloError } from '@apollo/client';
import * as React from 'react';

interface CartProductListProps {
  onComboQuantityChange?: (product: CartProduct, quantity: number) => void;
  onComboDetailClick?: (product: CartProduct, quantity: number) => void;
  onError?: (error: ApolloError) => void;
}

export const CartProductList: React.FC<CartProductListProps> = ({
  onError,
  onComboQuantityChange,
  onComboDetailClick,
}) => {
  const order = React.useContext(OrderContext);
  const cartProducts = mapCartProducts(order.cart?.products);

  const {
    changeProductQuantity,
    loading: changeProductLoading,
    error: changeProductError,
  } = useChangeProductQuantityMutation({
    onCompleted: order.updateCart,
    onError,
    orderCode: order?.cart?.orderCode,
  });

  const DEBOUNCE_TIME = 500;
  const handleDebouncedValueChange = debounce<number>((newValue, fn) => {
    fn?.(newValue ?? 1);
  }, DEBOUNCE_TIME);

  useErrorFlashDispatcher(changeProductError);

  return (
    <>
      {cartProducts.reverse().map((product, index) => {
        const handleChangeProductQuantity = (newQuantity: number) => {
          product.isCombo
            ? onComboQuantityChange(product, newQuantity)
            : changeProductQuantity({
                originId: product.originId,
                productCode: product.code,
                quantity: newQuantity,
                sequentialNumber: product.sequentialNumber,
              });
        };

        return (
          <React.Fragment key={product.code + product.sequentialNumber}>
            {index > 0 && <VSeparator />}
            <CartProductCell
              product={product}
              onChangeQuantity={(newQuantity: number) =>
                handleDebouncedValueChange(newQuantity, handleChangeProductQuantity)
              }
              loading={changeProductLoading}
              error={!!changeProductError}
              onComboDetailClick={(quantity: number) => onComboDetailClick(product, quantity)}
              onError={onError}
            />
          </React.Fragment>
        );
      })}
    </>
  );
};
