import XLSX from 'xlsx';

export const readFirstSheet = (spreadsheet: ArrayBuffer) => {
  const data = new Uint8Array(spreadsheet);
  const workbook = XLSX.read(data, { type: 'array' });
  const result = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { header: 1 });
  const header = result.shift();

  return { header, rows: result as any };
};
