import { IcAppLogo } from '@app/components/icons/ic-app-logo.component';
import {
  AppType,
  mapPathnameToAppType,
} from '@app/components/obj.page-wrapper/mol.app-header/pathname-to-app-type.mapper';
import { DropDownMenu } from '@app/components/org.drop-down-menu/drop-down-menu.component';
import { Project } from '@app/config-variables';
import { useCycleDetailQuery } from '@app/domain/cycle-details.use-case';
import { useAppType } from '@app/modules/account/use-appType';
import { DeskMenuLinks, MobileMenuLinks } from '@app/modules/app/org.menu/menu-links.component';
import { AppPath } from '@app/modules/app/routes/routes-list';
import { HeaderNotifications } from '@app/modules/header/components/mol.header-notification/header-notification.component';
import { StartsMessageNotifications } from '@app/modules/starts/whatsapp/mol.message-notifications/starts-message-notifications.component';
import { mapCycleToCaption } from '@app/view-model/cycle/cycle.vm.mapper';
import { Drawer } from '@atomic/atm.drawer';
import { HamburgerButton } from '@atomic/atm.hamburger-button';
import { HCycle } from '@atomic/atm.typography';
import { useBooleanState } from '@atomic/obj.boolean-state/boolean-state.hook';
import { Hbox, Separator } from '@atomic/obj.box';
import { CommonTheme } from '@atomic/obj.constants';
import { Header } from '@atomic/org.header';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { ThemeContext } from 'styled-components';

export const AppHeader: React.FC<{}> = () => {
  const theme = React.useContext<CommonTheme>(ThemeContext);
  const { value, toggle } = useBooleanState(false);
  const { isStarts, isActivation } = useAppType();

  const cycleDetail = useCycleDetailQuery();
  const cycleDetailData = mapCycleToCaption(cycleDetail.data);
  const appType = mapPathnameToAppType(location.pathname);
  const shouldShowCycle = theme.Project !== Project.eudora && appType !== AppType.Admin;
  let redirectTo: string;
  if (appType === AppType.Admin) {
    redirectTo = AppPath.Admin.Base;
  } else if (appType === AppType.Starts) {
    redirectTo = AppPath.Starts.Base;
  } else if (appType === AppType.IrDigital) {
    redirectTo = AppPath.IrDigital.Home;
  } else {
    redirectTo = AppPath.Activation.Home.Dashboard.Base;
  }

  return (
    <Header>
      <Header.Desk>
        <Hbox>
          <Hbox.Item noGrow vAlign='center'>
            <Link to={redirectTo}>
              <IcAppLogo />
            </Link>
          </Hbox.Item>
          <Hbox.Separator />
          <Hbox.Item>
            <DeskMenuLinks appType={appType} />
          </Hbox.Item>
          <Hbox.Item vAlign='center' hAlign='flex-end'>
            {isStarts && <StartsMessageNotifications />}
            {isActivation && <HeaderNotifications whatsappEnabled scheduleEnabled />}
          </Hbox.Item>
          <Hbox.Separator />
          {shouldShowCycle && (
            <Hbox.Item vAlign='center' hAlign='flex-end' noGrow>
              <HCycle>
                {cycleDetailData?.current} ({cycleDetailData?.durationHeader})
              </HCycle>
            </Hbox.Item>
          )}
          <Hbox.Item vAlign='center' noGrow>
            <DropDownMenu />
          </Hbox.Item>
        </Hbox>
      </Header.Desk>
      <Header.Mobile>
        <Hbox>
          <Hbox.Item vAlign='center' hAlign='flex-start'>
            <HamburgerButton active={value} onClick={toggle} />
          </Hbox.Item>
          <Hbox.Item vAlign='center' hAlign='flex-end'>
            {isStarts && <StartsMessageNotifications />}
            {isActivation && <HeaderNotifications whatsappEnabled scheduleEnabled />}
          </Hbox.Item>
        </Hbox>
        <Drawer active={value} onClick={toggle}>
          <MobileMenuLinks appType={appType} onClick={toggle} />
          <Separator />
          <DropDownMenu />
        </Drawer>
      </Header.Mobile>
    </Header>
  );
};
