import * as React from 'react';

import { Card } from '@atomic/atm.card';
import { Table, TD, TR } from '@atomic/mol.table';

const ListRow = (props) => (
  <TR noColor>
    <TD textAlign='left' noColor>
      {props.children}
    </TD>
  </TR>
);

export const List = (props) => {
  const row = React.Children.map(props.children, (child: any) => {
    return child && child.type === ListRow ? child : null;
  });

  return (
    <Card>
      <Table>{row}</Table>
    </Card>
  );
};

List.Row = ListRow;
