import { FlashDispatcherService } from '@app/components/obj.flash-wrapper/flash-dispatcher.service';
import React from 'react';
import Container from 'typedi';

export interface BrowserNotificationProviderState {
  notificationStatus: string;
  handleSendNotification: ({ title, body, icon }: { title: string; body: string; icon?: string }) => void;
}

export const BrowserNotificationContext = React.createContext<BrowserNotificationProviderState>(null);

export const BrowserNotificationProvider: React.FC = (props) => {
  const notificationApi = window?.Notification;
  const flashDispatcherService = Container.get(FlashDispatcherService);

  React.useEffect(() => {
    async function checkNotificationPromise() {
      try {
        await notificationApi.requestPermission();
      } catch (error) {
        flashDispatcherService.dispatchMessage({ message: error }, 'warning');
      }
    }

    if (notificationApi?.permission === 'default') {
      checkNotificationPromise();
    }
  }, [flashDispatcherService, notificationApi]);

  const handleSendNotification = React.useCallback(
    ({ title, body, icon }: { title: string; body: string; icon?: string }) => {
      if (notificationApi.permission === 'granted') {
        new Notification(title, { body, icon });
      }
      return;
    },
    [notificationApi],
  );

  return (
    <BrowserNotificationContext.Provider
      value={{ handleSendNotification, notificationStatus: notificationApi.permission }}
    >
      {props.children}
    </BrowserNotificationContext.Provider>
  );
};
