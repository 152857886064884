export interface Schedule {
  id: string;
  resellerName: string;
  hasPassed?: boolean;
  attendanceTime?: string;
  scheduledTime: string;
}

export interface FutureSchedule {
  id: string;
  resellerName: string;
  hasPassed?: boolean;
  attendanceTime?: string;
  scheduledTime: string;
  scheduledDate: Date;
}

export enum ScheduleAction {
  reschedule,
  cancelSchedule,
  showNextSchedules,
}
