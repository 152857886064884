import styled from 'styled-components';

import { TextAlignProps } from '.';

export const TableStyled = styled.table`
  table-layout: fixed;
  border-style: solid;
  border-width: 0;
  border-color: ${(props) => (props.noColor ? props.theme.Border.Color : 'transparent')};
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  text-align: center;
  font-family: ${(props) => props.theme.FontFamily.Primary};
`;

export const TableHeadStyled = styled.thead``;

export const TableHeaderStyled = styled.th`
  color: ${(props) => (!props.noColor ? props.theme.Color.Primary : 'transparent')};
  padding: ${(props) => props.theme.Spacing.Medium} ${(props) => props.theme.Spacing.Medium};
  text-align: ${(props: TextAlignProps) => props.textAlign || 'center'};
`;

export const TableColumnStyled = styled.td`
  border-style: solid;
  border-color: ${(props) => (!props.noColor ? props.theme.Border.Color : 'transparent')};
  border-width: 0;
  padding: ${(props) => props.theme.Spacing.Medium} ${(props) => props.theme.Spacing.Medium};
  width: auto;
  border-right-width: ${(props) => props.theme.Border.Width};
  text-align: ${(props: TextAlignProps) => props.textAlign || 'center'};

  &:last-child {
    padding-right: ${(props) => props.theme.Spacing.Medium};
    border-right-width: 0;
  }
`;

export const TableRowStyled = styled.tr`
  & > ${TableColumnStyled} {
    background-color: ${(props) => props.theme.Color.White};
  }

  ${(props) =>
    props.onClick &&
    `
      &:hover > td {
        background-color: ${props.theme.Color.GrayXLight};
        cursor: pointer;
      }`}

  &:first-child {
    > ${TableColumnStyled} {
      border-top-width: ${(props) => props.theme.Border.Width};
    }

    > ${TableColumnStyled}:first-child {
      border-top-left-radius: ${(props) => props.theme.Border.Radius};
    }

    > ${TableColumnStyled}:last-child {
      border-top-right-radius: ${(props) => props.theme.Border.Radius};
    }
  }

  &:last-child {
    > ${TableColumnStyled}:first-child {
      border-bottom-left-radius: ${(props) => props.theme.Border.Radius};
    }

    > ${TableColumnStyled}:last-child {
      border-bottom-right-radius: ${(props) => props.theme.Border.Radius};
    }
  }
`;
