import { ThemeToken } from '@atomic/obj.constants';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import Container from 'typedi';
import { OneTrustBaseUrlToken, OneTrustConsentIdToken } from '@app/core/one-trust';
import { useEmbeddedChat } from './embedded-chat.hook';

interface HeadAttributes {
  title: string;
  linkHrefs: {
    appleTouchIcon: string;
    favicon32x32: string;
    favicon16x16: string;
    manifest: string;
    shortcutIcon: string;
    safariPinnedTabIcon: string;
  };
}

const projectMetadataStrings: { bot: HeadAttributes; eudora: HeadAttributes } = {
  bot: {
    linkHrefs: {
      appleTouchIcon: '/assets/icons/bot-apple-touch-icon.png',
      favicon16x16: '/assets/icons/bot-favicon-16x16.png',
      favicon32x32: '/assets/icons/bot-favicon-32x32.png',
      manifest: '/bot-manifest.json',
      safariPinnedTabIcon: '/assets/icons/bot-safari-pinned-tab.svg',
      shortcutIcon: '/assets/icons/bot-favicon.ico',
    },
    title: 'Boticário Supervisora FVC',
  },
  eudora: {
    linkHrefs: {
      appleTouchIcon: '/assets/icons/eudora-apple-touch-icon.png',
      favicon16x16: '/assets/icons/eudora-favicon-16x16.png',
      favicon32x32: '/assets/icons/eudora-favicon-32x32.png',
      manifest: '/eud-manifest.json',
      safariPinnedTabIcon: '/assets/icons/eudora-safari-pinned-tab.svg',
      shortcutIcon: '/assets/icons/eudora-favicon.ico',
    },
    title: 'Eudora Consultora FVC',
  },
};

export const HeadTags = () => {
  const theme = Container.get(ThemeToken);
  const attributes: HeadAttributes = projectMetadataStrings[theme.Project];

  const oneTrustConsentId = Container.get(OneTrustConsentIdToken);
  const oneTrustBaseUrl = Container.get(OneTrustBaseUrlToken);
  useEmbeddedChat();
  return (
    <Helmet>
      <title>{projectMetadataStrings[theme.Project].title}</title>
      <meta name='description' content={projectMetadataStrings[theme.Project].title} />
      <link rel='bot-apple-touch-icon' sizes='180x180' href={attributes.linkHrefs.appleTouchIcon} />
      <link rel='icon' type='image/png' sizes='32x32' href={attributes.linkHrefs.favicon32x32} />
      <link rel='icon' type='image/png' sizes='16x16' href={attributes.linkHrefs.favicon16x16} />
      <link rel='manifest' href={attributes.linkHrefs.manifest} />
      <link rel='mask-icon' href={attributes.linkHrefs.safariPinnedTabIcon} color={theme.Color.Primary} />
      <link rel='shortcut icon' href={attributes.linkHrefs.shortcutIcon} />
      <meta name='msapplication-TileColor' content={theme.Color.Primary} />
      <meta name='theme-color' content={theme.Color.Primary} />
      <meta name='msapplication-config' content='/assets/icons/bot-browserconfig.xml' />
      {oneTrustConsentId && oneTrustBaseUrl && (
        <script
          data-domain-script={oneTrustConsentId}
          type='text/javascript'
          src={`${oneTrustBaseUrl}scripttemplates/otSDKStub.js`}
        />
      )}
      <script>{'function OptanonWrapper() {}'}</script>
    </Helmet>
  );
};
