import { MutationTuple } from '@apollo/client';
import {
  isToday,
  parseDateToFormattedTimeString,
  isOneOrMoreDaysAhead,
} from '@app/components/obj.formatter/date-formatter';
import { useMutationHook, useQueryHook } from '@app/core/graphql';
import {
  FvcRescheduling,
  FvcReschedulingVariables,
  FvcSchedulingList,
  FvcSchedulingListVariables,
  FvcCancelScheduling,
  FvcCancelSchedulingVariables,
} from '@app/data/graphql';
import { Schedule, FutureSchedule } from '@app/models/schedule.model';

export const useSchedulingListQuery = ({ offset, limit }: FvcSchedulingListVariables) => {
  const result = useQueryHook<FvcSchedulingList, FvcSchedulingListVariables>('scheduling-list.query', {
    fetchPolicy: 'no-cache',
    variables: { limit, offset },
  });

  return {
    data: result.data,
    error: result.error,
    loading: result.loading,
    refetch: () => result.refetch(),
  };
};

export const mapSchedulingList = (data: FvcSchedulingList) => {
  if (!data?.FvcSchedulingList?.nodes) {
    return null;
  }

  const currentDate = new Date();

  const mappedScheduling: Schedule[] = data.FvcSchedulingList.nodes
    .filter((item) => isToday(new Date(item.scheduledTo)))
    .map((node) => ({
      attendanceTime: node?.completedAt ? parseDateToFormattedTimeString(new Date(node?.completedAt)) : null,
      hasPassed: currentDate > new Date(node.scheduledTo),
      id: node.id,
      resellerName: node.resellerName,
      scheduledTime: parseDateToFormattedTimeString(new Date(node?.scheduledTo)),
    }))
    .reverse();

  return mappedScheduling;
};

export const mapFutureSchedulesList = (data: FvcSchedulingList) => {
  if (!data?.FvcSchedulingList?.nodes) {
    return null;
  }

  const mappedNextSchedules: FutureSchedule[] = data.FvcSchedulingList.nodes
    .filter((item) => isOneOrMoreDaysAhead(new Date(item.scheduledTo)))
    .map((node) => ({
      id: node.id,
      resellerName: node.resellerName,
      scheduledDate: new Date(node.scheduledTo),
      scheduledTime: parseDateToFormattedTimeString(new Date(node.scheduledTo)),
    }))
    .reverse();

  return mappedNextSchedules;
};

export const useRescheduleMutation = (): MutationTuple<FvcRescheduling, FvcReschedulingVariables> => {
  const refetch = ['FvcSchedulingList'];
  const result = useMutationHook<FvcRescheduling, FvcReschedulingVariables>({
    mutationName: 'reschedule.mutation',
    refetchQueriesByQueryName: refetch,
  });
  return result;
};

export const useCancelScheduleMutation = (): MutationTuple<FvcCancelScheduling, FvcCancelSchedulingVariables> => {
  const refetch = ['FvcSchedulingList'];
  const result = useMutationHook<FvcCancelScheduling, FvcCancelSchedulingVariables>({
    mutationName: 'cancel-schedule.mutation',
    refetchQueriesByQueryName: refetch,
  });
  return result;
};
