import styled, { keyframes } from 'styled-components';

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

interface FadeProps {
  show: boolean;
}

export const Fade = styled.div`
  border: 1px solid transparent;
  animation: ${(props: FadeProps) => (props.show ? fadeIn : fadeOut)} ${(props) => props.theme.TransitionDuration}
    linear forwards;
`;
