import { ThemeToken } from '@atomic/obj.constants';
import { HighlightStyle } from '@atomic/obj.mixin';
import * as ColorFunc from 'color';
import styled from 'styled-components';
import Container from 'typedi';
import { FloatingActionButtonProps } from './floating-action-button.component';
const theme = Container.get(ThemeToken);

export const FloatingActionButtonStyled = styled.button`
  background-color: ${(props) => props.theme.Color.White};
  width: 48px;
  height: 48px;
  border-radius: 24px;
  border-width: 0;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.15);
  opacity: ${(props: FloatingActionButtonProps) => (props.disabled || props.loading ? 0.5 : 1)};
  color: ${(props) => props.theme.Color.Primary};
  font-family: ${(props) => props.theme.FontFamily.Primary};
  font-weight: ${(props) => props.theme.FontWeight.Bold};
  font-size: 24px;
  text-align: center;
  ${HighlightStyle}
  position: relative;
  letter-spacing: 1px;
  padding: 0;

  :focus {
    outline: 0;
  }

  :active {
    background-color: ${(props: FloatingActionButtonProps) =>
      props.outlined ? 'transparent' : ColorFunc(theme.Color.White).darken(0.2).hsl().string()};
  }

  @media (hover: hover) {
    :hover {
      background-color: ${ColorFunc(theme.Color.White).darken(0.2).hsl().string()};
    }
  }

  & + & {
    margin-left: ${(props) => props.theme.Spacing.Small};
  }
`;

export const FloatingActionButtonContentStyled = styled.div`
  transition: all 0.2s ease-in-out;
  opacity: ${(props: { loading: boolean }) => (props.loading ? 0 : 1)};

  & .fa {
    color: ${(props) => props.theme.Color.Primary};
    position: relative;
    left: 1px;
    line-height: 48px;
  }
`;

export const FloatingActionButtonSpinnerStyled = styled.span`
  display: ${(props: { loading: boolean }) => (props.loading ? 'inline-block' : 'none')} !important;
  position: absolute;
  right: calc(50% - 13px);
  top: calc(50% - 13px);
`;
