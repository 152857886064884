import { useQueryHook } from '@app/core/graphql';
import {
  ListNewsClient,
  ListNewsClientVariables,
  ListPublicNews,
  ListPublicNewsVariables,
  NewsResponse,
} from '@app/data/graphql';

interface ListNewsClientParams {
  offset: number;
  limit: number;
  onCompleted?: (data: NewsResponse[]) => void;
}

export const useListNewsClientQuery = ({ limit, offset, onCompleted }: ListNewsClientParams) => {
  const result = useQueryHook<ListNewsClient, ListNewsClientVariables>('list-news-client.query', {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => onCompleted?.(data.ListNewsClient.news),
    variables: { limit, offset },
  });

  return {
    data: result.data?.ListNewsClient,
    error: result.error,
    loading: result.loading,
    refetch: () => result.refetch(),
  };
};

export const useListPublicNewsQuery = () => {
  const result = useQueryHook<ListPublicNews, ListPublicNewsVariables>('list-public-news.query', {
    context: {
      headers: {},
    },
  });

  return {
    data: result?.data?.ListPublicNews?.news,
    error: result.error,
    loading: result.loading,
  };
};
