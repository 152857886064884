import { hasDocument } from '@app/core/browser/platform';

export class GoogleAnalyticsTagManagerService {
  private gtmContainerId: string;

  constructor(gtmContainerId: string) {
    this.gtmContainerId = gtmContainerId;
  }

  // adding script as documented in the official google tag manager: https://developers.google.com/tag-manager/quickstart
  appendScript() {
    if (hasDocument() && this.gtmContainerId) {
      const gtmHeadScript = document.createElement('script');
      gtmHeadScript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
        var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
        j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer','${this.gtmContainerId}')`;

      const gtmBodyScript = document.createElement('noscript');

      const iframeChild = document.createElement('iframe');
      iframeChild.src = `https://www.googletagmanager.com/ns.html?id=${this.gtmContainerId}`;
      iframeChild.height = '0';
      iframeChild.width = '0';
      iframeChild.style.display = 'none';
      iframeChild.style.visibility = 'hidden';
      iframeChild.innerHTML = '';
      gtmBodyScript.appendChild(iframeChild);

      document.head.appendChild(gtmHeadScript);
      document.body.appendChild(gtmBodyScript);
    }
  }
}
