import * as React from 'react';
import { CardStyledProps, CardWrapperStyled } from './card.component.style';

export interface CardProps extends CardStyledProps {
  bordered?: boolean;
  selected?: boolean;
  onClick?: () => void;
}

export const Card: React.FC<CardProps> = CardWrapperStyled;
