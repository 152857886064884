import { parseDateToFormattedTimeString } from '@app/components/obj.formatter/date-formatter';
import { LocalizedContext } from '@app/components/obj.localization';
import { EventCategory, EventAction, sanitizeMessage } from '@app/utils/data-layer';
import { Thumb } from '@atomic/atm.thumb';
import { Body, BodySecondary, DDAlert, Ellipsed, H4 } from '@atomic/atm.typography';
import { ShimmerText } from '@atomic/mol.shimmer';
import { Hbox } from '@atomic/obj.box';
import { Cell } from '@atomic/obj.cell';
import { ThemeToken } from '@atomic/obj.constants';
import { differenceInDays } from 'date-fns';
import * as React from 'react';
import Container from 'typedi';
const theme = Container.get(ThemeToken);

export interface WhatsappContactCellProps {
  name: string;
  lastMessage: string;
  lastMessageAt: Date;
  thubmnailUrl?: string;
  onClick?: () => void;
}

export const WhatsappContactCell: React.FC<WhatsappContactCellProps> = (props) => {
  const { thubmnailUrl, name, lastMessage: message, lastMessageAt, onClick } = props;
  const strings = React.useContext(LocalizedContext).strings.components.messageNotifications;
  const isMoreThan24Hours = lastMessageAt && differenceInDays(new Date(), lastMessageAt) >= 1;

  return (
    <Cell
      onClick={onClick}
      dataGtmEventCategory={EventCategory.Attendence}
      dataGtmEventAction={EventAction.Click.Button}
      dataGtmEventLabel={sanitizeMessage(name)}
    >
      <Hbox>
        {thubmnailUrl && (
          <>
            <Hbox.Item noGrow>
              <Thumb size='small' src={thubmnailUrl} noMargin circular />
            </Hbox.Item>
            <Hbox.Separator />
          </>
        )}
        <Hbox.Item hideOverflow>
          <H4>{name}</H4>
          <Body>
            <Ellipsed>{message}</Ellipsed>
          </Body>
        </Hbox.Item>
        <Hbox.Separator />
        <Hbox.Item noGrow>
          <BodySecondary>{parseDateToFormattedTimeString(lastMessageAt)}</BodySecondary>
        </Hbox.Item>
        <Hbox.Separator />
        {isMoreThan24Hours && (
          <Hbox.Item noGrow>
            <DDAlert>{strings.expired}</DDAlert>
          </Hbox.Item>
        )}
      </Hbox>
    </Cell>
  );
};

export const WhatsAppContactCellShimmer = () => (
  <>
    {Array(4)
      .fill(0)
      .map((_, i) => (
        <Cell key={i}>
          <Hbox>
            <Hbox.Item>
              <ShimmerText size={theme.FontSize.Medium} maxWidthPercent={60} />
              <ShimmerText size={theme.FontSize.Small} maxWidthPercent={100} />
            </Hbox.Item>
            <Hbox.Separator />
            <Hbox.Item noGrow>
              <ShimmerText width={36} />
            </Hbox.Item>
          </Hbox>
        </Cell>
      ))}
  </>
);
