import * as React from 'react';

export function useBooleanState(defaultValue: boolean) {
  const [value, setValue] = React.useState(defaultValue);

  function setTrue() {
    setValue(true);
  }

  function setFalse() {
    setValue(false);
  }

  function toggle() {
    setValue(!value);
  }

  return { setFalse, setTrue, setValue, toggle, value };
}
