import { Service } from 'typedi';

import { HttpInterceptor, HttpRequest } from '@app/core/http';

@Service()
export class JsonContentHeaderInterceptor implements HttpInterceptor {
  async before(request: HttpRequest): Promise<HttpRequest> {
    request.headers['Content-Type'] = 'application/json';

    return request;
  }
}
