import { defaultTheme, ShadesOfGray } from './constants';

enum EudoraBrandColor {
  Green = '#588527',
  Ochre = '#FFBF00',
  Red = '#F03232',
  Blue = '#7DA0F5',
  SoftViolet = '#7F3BBD',
  DarkViolet = '#542785',
  VioletGradient = '#6631A3',
  LightViolet = '#8F85B7',
  Gold = '#B4955D',
}

export enum EudoraCategoryColor {
  I0 = '#588527',
  I1 = '#89A967',
  I2 = '#ABC192',
  I3 = '#CDDABE',
  I4 = '#F5C0C0',
  I5 = '#EF9797',
  I6 = '#E86D6D',
  I7 = '#DF2F2F',
}

export class EudoraColor {
  public static readonly Black = '#000000';
  public static readonly White = '#FFFFFF';
  public static readonly YellowBadge = '#CCA314';

  public static readonly GrayXLight = ShadesOfGray.GrayXLight;
  public static readonly GrayLight = ShadesOfGray.GrayLight;
  public static readonly Gray = ShadesOfGray.Gray;
  public static readonly GrayDark = ShadesOfGray.GrayDark;
  public static readonly GrayXDark = ShadesOfGray.GrayXDark;

  public static readonly Primary = EudoraBrandColor.DarkViolet;
  public static readonly Secondary = EudoraBrandColor.LightViolet;
  public static readonly Accessory = EudoraBrandColor.DarkViolet;
  public static readonly Neutral = EudoraColor.GrayDark;
  public static readonly CallToAction = EudoraBrandColor.VioletGradient;

  public static readonly Alert = EudoraBrandColor.Red;
  public static readonly Warning = EudoraBrandColor.Ochre;
  public static readonly Success = EudoraBrandColor.Green;

  public static readonly GoalSpecial = EudoraBrandColor.Gold;
}

enum EudoraFontFamily {
  Primary = '"Myriad Pro", Helvetica, Arial, sans-serif',
  Secondary = 'Myriad Pro, "Helvetica Neue", Helvetica, Arial, sans-serif',
}

export const eudoraTheme = {
  ...defaultTheme,
  BrandColor: EudoraBrandColor,
  CategoryColor: EudoraCategoryColor,
  Color: EudoraColor,
  FontFamily: EudoraFontFamily,
  Project: 'eudora',
};
