import { HistoryToken } from '@app/core/route';
import { ConversationCreationMethod } from '@app/data/graphql';
import { useCommercialStructureListQuery } from '@app/domain/commercial-structure.use-case';
import { useStartResellerConversation } from '@app/domain/start-reseller-conversation.use-case';
import { useAppType } from '@app/modules/account/use-appType';
import { useStartSpecificConversationMutation } from '@app/modules/activation/home/home.container';
import { AppPath } from '@app/modules/app/routes';
import { useStartSpecificIrConversation } from '@app/modules/ir-digital/ir-digital-conversation.hook';
import { FeatureToggleContext } from '@app/providers';
import { MessageNotificationContext } from '@app/providers/message-notification.provider';
import { QueueContext } from '@app/providers/queue.provider';
import * as React from 'react';
import Container from 'typedi';

const history = Container.get(HistoryToken);

export const useStartMessageNotifications = () => {
  const {
    error,
    interactions,
    loading,
    refetch,
    hasNextPage,
    interactionsLength,
    handleClose,
    onLoadMore,
  } = React.useContext(MessageNotificationContext);

  const commercialStructure = useCommercialStructureListQuery()?.data;
  const queueContext = React.useContext(QueueContext);

  const { startResellerConversation, startResellerConversationResult } = useStartResellerConversation(
    commercialStructure,
    queueContext?.handleTap,
    queueContext.queueResult?.data?.map((item) => item.id),
  );

  const handleStartConversation = React.useCallback(
    (id: string, name: string) => {
      startResellerConversation(id, name);
    },
    [startResellerConversation],
  );

  return {
    error,
    handleStartConversation,
    hasNextPage,
    interactions,
    interactionsLength,
    loading,
    onClose: handleClose,
    onLoadMore,
    refetch,
    startConversationError: startResellerConversationResult.error,
  };
};

export const useAttendanceMessageNotifications = () => {
  const { isActivation, isIrDigital } = useAppType();
  const queueLimit = React.useContext(FeatureToggleContext).featureToggleOptions.fvcQueueLimit;

  const {
    error,
    interactions,
    loading,
    refetch,
    hasNextPage,
    interactionsLength,
    handleClose,
    onLoadMore,
  } = React.useContext(MessageNotificationContext);

  const commercialStructure = useCommercialStructureListQuery()?.data;
  const queueContext = React.useContext(QueueContext);

  const { startSpecificConversation, startSpecificConversationResult } = useStartSpecificConversationMutation({
    commercialStructure,
    onCompleted: queueContext?.handleTap,
    queueLimit,
  });

  const { startSpecificIrConversation, startSpecificIrConversationResult } = useStartSpecificIrConversation(
    commercialStructure,
    queueContext?.handleTap,
    queueLimit,
  );

  const handleStartConversation = React.useCallback(
    (resellerCode: string, name: string) => {
      isIrDigital
        ? startSpecificIrConversation(resellerCode, name)
        : startSpecificConversation(resellerCode, name, ConversationCreationMethod.Notification);

      if (isIrDigital && history.location.pathname !== AppPath.IrDigital.Home) {
        history.push({ pathname: AppPath.IrDigital.Home });
      } else if (isActivation && history.location.pathname !== AppPath.Activation.Home.Dashboard.Base) {
        history.push({ pathname: AppPath.Activation.Home.Dashboard.Base });
      }
    },
    [isActivation, isIrDigital, startSpecificConversation, startSpecificIrConversation],
  );

  return {
    error,
    handleStartConversation,
    hasNextPage,
    interactions,
    interactionsLength,
    loading,
    onClose: handleClose,
    onLoadMore,
    refetch,
    startConversationError: isIrDigital
      ? startSpecificConversationResult.error
      : startSpecificIrConversationResult.error,
  };
};
