import { StepHeader } from '@app/components/mol.step-header/step-header.component';
import { LocalizedContext } from '@app/components/obj.localization';
import { Project } from '@app/config-variables';
import { CartPageLocationState } from '@app/models/orders.model';
import { AppPath } from '@app/modules/app/routes';
import { BodySecondaryEllipsis } from '@app/modules/cart/mol.cart-step-header/cart-step-header-navigation.style';
import { FeatureToggleContext, OrderContext } from '@app/providers';
import { Button } from '@atomic/atm.button';
import { DividerGrayVertical } from '@atomic/atm.divider/divider.component.style';
import { Frame } from '@atomic/atm.frame';
import { BodySecondary, Bold, H3 } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { ThemeToken } from '@atomic/obj.constants';
import { VSeparator } from '@atomic/obj.grid';
import * as React from 'react';
import { useLocation } from 'react-router';
import Container from 'typedi';

interface CartStepHeaderNavigatorProps {
  onCancel: () => void;
  loading: boolean;
}

export const CartStepHeaderNavigator: React.FC<CartStepHeaderNavigatorProps> = (props) => {
  const order = React.useContext(OrderContext);
  const theme = Container.get(ThemeToken);
  const localized = React.useContext(LocalizedContext).strings.components.cartPages.steps;
  const location = useLocation<CartPageLocationState>();
  const allowGoToManagement = !!order?.cart;
  const featureOptions = React.useContext(FeatureToggleContext).featureToggleOptions;

  const isEudoraProject = theme.Project === Project.eudora;

  const getEudoraCartSteps = () => {
    const steps = [];

    if (location?.state?.editOrderCode) {
      steps.push({
        completed: !!order.cart,
        disabled: !!order.cart,
        link: AppPath.Cart.EditOrder,
        title: localized.editOrder,
      });
    } else {
      steps.push({
        completed: !!order.selectedBusinessModel,
        disabeld: !order.selectedBusinessModel,
        link: AppPath.Cart.BusinessModel,
        title: localized.businessModel,
      });

      if (order.starterPacks?.length > 0) {
        steps.push({
          completed: !!order.selectedStarterPacks,
          disabled: !order.selectedStarterPacks,
          link: AppPath.Cart.StarterKits,
          title: localized.starterKits,
        });
      }

      steps.push(
        {
          completed: !!order.selectedDeliveryMode,
          disabled: !order.selectedDeliveryMode,
          link: AppPath.Cart.Address,
          title: localized.address,
        },
        {
          completed: order.selectedCycle,
          disabled: !order.selectedCycle,
          link: AppPath.Cart.Cycle,
          title: localized.cycle,
        },
      );
    }

    steps.push({
      completed: false,
      disabled: !allowGoToManagement,
      link: AppPath.Cart.Management,
      title: localized.management,
    });

    return steps;
  };

  const getBotiCartSteps = () => {
    const steps = [];

    if (location.state.editOrderCode) {
      steps.push({
        completed: !!order.cart,
        disabled: !!order.cart,
        link: AppPath.Cart.EditOrder,
        title: localized.editOrder,
      });
    } else {
      steps.push({
        completed: !!order.selectedDeliveryMode?.descriptiveAddress,
        disabled: !order.selectedDeliveryMode,
        link: AppPath.Cart.Address,
        title: localized.address,
      });

      if (order.canOrderOnPreviousCycle) {
        steps.push({
          completed: !!order?.selectedCycle,
          disabled: !order?.selectedCycle,
          link: AppPath.Cart.Cycle,
          title: localized.cycle,
        });
      }

      if (order.starterPacks?.length > 0) {
        steps.push({
          completed: order.selectedStarterPacks?.length > 0,
          disabled: !order.selectedStarterPacks,
          link: AppPath.Cart.StarterKits,
          title: localized.starterKits,
        });
      }
    }

    steps.push({
      completed: false,
      disabled: !allowGoToManagement,
      link: AppPath.Cart.Management,
      title: localized.management,
    });

    return steps;
  };

  const cartSteps = isEudoraProject ? getEudoraCartSteps() : getBotiCartSteps();

  return (
    <Frame>
      <Hbox>
        <Hbox.Item noGrow>
          <H3>{`${localized.orderNumber} ${order.currentOrder?.id ?? '_'}`} </H3>

          <BodySecondary>
            <Bold>{`${localized.cycle} `}</Bold>
            {order?.currentCycle ? order?.currentCycle : '_'}
          </BodySecondary>

          <BodySecondaryEllipsis>
            <Bold>{`${localized.address}: `}</Bold>
            {order.selectedDeliveryMode?.descriptiveAddress ?? '_'}
          </BodySecondaryEllipsis>

          <VSeparator />
          {featureOptions.fvcCancelOrder && (
            <Button kind='alert' onClick={props.onCancel} loading={props.loading}>
              {localized.cancel}
            </Button>
          )}
        </Hbox.Item>

        <Hbox.Separator />
        <Hbox.Item noGrow>
          <DividerGrayVertical />
        </Hbox.Item>

        <Hbox.Item hAlign='center'>
          <StepHeader items={cartSteps} />
        </Hbox.Item>
      </Hbox>
    </Frame>
  );
};
