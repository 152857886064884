import { ApolloClient } from '@apollo/client';
import { Container } from 'typedi';

import { GraphQLDocsService } from '@app/core/graphql/graphql-docs.service';
import { Auth, AuthInput, AuthVariables } from '@app/data/graphql/';

export class AuthenticationDataSource {
  static async login(code: string): Promise<Auth> {
    const loginInput: AuthInput = {
      code,
    };

    const variables: AuthVariables = {
      data: loginInput,
    };

    const graphqlDocsService = Container.get(GraphQLDocsService);
    const authQUery = graphqlDocsService.getQuery('authentication.query');

    const authApolloClient = Container.get(ApolloClient);
    return authApolloClient
      .query<Auth>({ query: authQUery, variables })
      .then((response) => response.data);
  }

  static async logout(): Promise<void> {
    const apolloClient = Container.get(ApolloClient);
    apolloClient.clearStore();
  }
}
