import { Service } from 'typedi';

import { getStorage, StorageType } from '@app/core/storage/storage.provider';
import { Auth_Auth } from '@app/data/graphql';
import { BusAuthenticationData } from '@app/data/http/dtos';

export const LOGIN_BUS_STATE = '@login:bus_state';
export const LOGIN_MID_STATE = '@login:mid_state';

type MiddlewareAuth = Auth_Auth;

@Service()
export class LocalAuthenticationDataSource {
  private storage = getStorage(StorageType.Local);

  saveBusAuth(bus: BusAuthenticationData): BusAuthenticationData {
    return this.storage.put(LOGIN_BUS_STATE, bus);
  }

  getBusAuth(): BusAuthenticationData {
    return this.storage.get(LOGIN_BUS_STATE);
  }

  saveMiddlewareAuth(middleware: MiddlewareAuth): MiddlewareAuth {
    return this.storage.put(LOGIN_MID_STATE, middleware);
  }

  getMiddlewareAuth(): MiddlewareAuth {
    return this.storage.get(LOGIN_MID_STATE);
  }

  clearAuthData(): void {
    this.storage.remove(LOGIN_BUS_STATE);
    this.storage.remove(LOGIN_MID_STATE);
  }

  isLoggedIn(): boolean {
    const state = this.storage.get(LOGIN_MID_STATE);
    return state != null;
  }

  updateBusTokens(bus: BusAuthenticationData): BusAuthenticationData {
    const oldBus = this.storage.get(LOGIN_BUS_STATE);
    bus.supervisorId = oldBus.supervisorId;
    return this.storage.put(LOGIN_BUS_STATE, bus);
  }
}
