import { Project } from '@app/config-variables';
import { ApplicationTypeEnum, StartOrderInput } from '@app/data/graphql/graphql-schema';
import { CartSectionState } from '@app/models/orders.model';
import { useAppType } from '@app/modules/account/use-appType';
import { useResellerOrderDetailQuery } from '@app/modules/activation/reseller/order-details/order-detail.container';
import { OrderContext } from '@app/providers';
import { CommonTheme } from '@atomic/obj.constants';
import * as React from 'react';
import { useLocation } from 'react-router';
import { ThemeContext } from 'styled-components';

export const useStartOrderParams = (): StartOrderInput => {
  const theme = React.useContext<CommonTheme>(ThemeContext);
  const order = React.useContext(OrderContext);
  const { isStarts } = useAppType();
  const location = useLocation<CartSectionState>();
  const detail = useResellerOrderDetailQuery({
    orderId: order.currentOrder?.id,
    resellerId: order.currentOrder?.resellerId,
  });

  const shouldPreserveAdress =
    detail?.data?.deliveryAdress?.id && detail?.data?.deliveryAdress?.id === order.currentOrderAddress?.id;

  const isEudoraProject = theme.Project === Project.eudora;

  return {
    application: isStarts ? ApplicationTypeEnum.Starts : ApplicationTypeEnum.Activation,
    businessModelCode: +order.selectedBusinessModel,
    changingDeliveryMode: location.state?.changeAddress || !shouldPreserveAdress,
    copyCanceledLastOrder: order?.recoverLastCancelledOrder,
    cycle: order.selectedCycle ?? order.currentCycle,
    distributionCenterCode: order?.selectedDeliveryMode?.distributionCenterCode ?? '0',
    editOrderCode: order.editOrderCode,
    pickupOnDistributionCenter: order?.selectedDeliveryMode?.isWithdrawalCenter ?? false,
    resellerId: order.resellerId,
    starterPacks: order.selectedStarterPacks,
    subcycleId: isEudoraProject ? order.selectedSubcycle : undefined,
  };
};
