import { FlashDispatcherService } from '@app/components/obj.flash-wrapper/flash-dispatcher.service';
import { LocalizedContext } from '@app/components/obj.localization';
import { FvcConversationModalData } from '@app/components/org.feedback/feedback-modal.constants';
import { Project } from '@app/config-variables';
import { GraphQLDocsService, useMutationHook, useQueryHook } from '@app/core/graphql';
import {
  ConversationStatusEnum,
  EndIrConversation,
  EndIrConversationVariables,
  IrConversationFeedbackInput,
  ListIrCurrentConversation,
  ListIrCurrentConversationVariables,
  StartIrConversation,
  StartIrConversationVariables,
  StartSpecificIrConversation,
  StartSpecificIrConversationVariables,
} from '@app/data/graphql';
import { mapApolloError } from '@app/data/graphql/graphql-error.mapper';
import { useCommercialStructureListQuery } from '@app/domain/commercial-structure.use-case';
import { QueueItem } from '@app/interfaces';
import { LARGE_BUTTON_HEIGHT } from '@app/modules/activation/home/components/atm.large-button';
import { QueueContext } from '@app/providers';
import { ThemeToken } from '@atomic/obj.constants';
import * as React from 'react';
import Container from 'typedi';

const flashDispatcherService = Container.get(FlashDispatcherService);

export const useListIrCurrentConversations = (commercialStructure: string) => {
  const theme = Container.get(ThemeToken);
  const skipQuery = theme.Project === Project.bot || !commercialStructure;
  const result = useQueryHook<ListIrCurrentConversation, ListIrCurrentConversationVariables>(
    'list-ir-current-conversation.query',
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      skip: skipQuery,
      variables: {
        commercialStructure,
      },
    },
  );

  return {
    data: mapConversations(result.data),
    error: result.error && mapApolloError(result.error),
    loading: result.loading,
    refetch: () => result.refetch(),
  };
};

const mapConversations = (data: ListIrCurrentConversation) => {
  if (!data?.ListIrCurrentConversation?.conversations) {
    return null;
  }
  const queue: QueueItem[] = data.ListIrCurrentConversation.conversations
    .map((conversation) => ({
      createdAt: conversation.createdAt,
      id: conversation.resellerId,
      name: conversation.resellerName,
    }))
    .reverse();
  return queue;
};

export const useStartIrConversation = (commercialStructure: string, onCompleted: (resellerId: string) => void) => {
  const refetch = [{ query: 'list-ir-current-conversation.query', variables: { commercialStructure } }];

  const [mutate, result] = useMutationHook<StartIrConversation, StartIrConversationVariables>({
    mutationName: 'start-ir-conversation.mutation',
    options: {
      onCompleted: (data) => onCompleted(data.StartIrConversation.resellerId),
    },
    refetchQueries: refetch,
  });

  const startIrConversation = () => {
    mutate({
      variables: {
        commercialStructure,
      },
    });
  };

  return {
    error: result.error,
    loading: result.loading,
    startIrConversation,
  };
};

export const useStartSpecificIrConversation = (
  commercialStructure: string,
  onCompleted: (resellerId: string) => void,
  queueLimit?: number,
) => {
  const graphqlDocsService = Container.get(GraphQLDocsService);
  const conversationListQueryDocument = graphqlDocsService.getQuery('list-ir-current-conversation.query');
  const conversationListQuery = useListIrCurrentConversations(commercialStructure);

  const [mutate, startSpecificIrConversationResult] = useMutationHook<
    StartSpecificIrConversation,
    StartSpecificIrConversationVariables
  >({
    mutationName: 'start-specific-ir-conversation.mutation',
  });

  const startSpecificIrConversation = (id, name) => {
    const queueItem = conversationListQuery.data.find((item) => item.id === id);

    const randomValue = crypto.getRandomValues(new Uint8Array(1))[0] / Math.pow(2, 8);

    if (!queueItem) {
      if (conversationListQuery.data.length < queueLimit) {
        mutate({
          optimisticResponse: {
            StartSpecificIrConversation: {
              __typename: 'StartIrConversationResponse',
              completedAt: null,
              createdAt: new Date().toISOString(),
              id: Math.round(randomValue * -1000000),
              inConversation: true,
              resellerId: id,
              resellerName: name,
            },
          },
          update: (cache, { data }) => {
            const conversationListQueryCached = cache.readQuery<ListIrCurrentConversation>({
              query: conversationListQueryDocument,
              variables: { commercialStructure },
            });

            cache.writeQuery({
              data: Object.assign({}, conversationListQueryCached, {
                ListIrCurrentConversation: {
                  ...conversationListQueryCached.ListIrCurrentConversation,
                  conversations: [
                    ...conversationListQueryCached.ListIrCurrentConversation.conversations,
                    data.StartSpecificIrConversation,
                  ],
                  count: conversationListQueryCached.ListIrCurrentConversation.count + 1,
                },
              }),
              query: conversationListQueryDocument,
              variables: { commercialStructure },
            });

            onCompleted(data.StartSpecificIrConversation.resellerId);
          },
          variables: {
            commercialStructure,
            resellerId: id,
          },
        });
      } else {
        mutate({
          variables: {
            commercialStructure,
            resellerId: id,
          },
        });
      }
    } else {
      onCompleted(id);
    }
  };

  return { startSpecificIrConversation, startSpecificIrConversationResult };
};

export const useEndIrConversation = (onCompleted: () => void) => {
  const queueContext = React.useContext(QueueContext);
  const localized = React.useContext(LocalizedContext).strings.components.home;
  const [isModalOpened, setIsModalOpened] = React.useState(false);
  const commercialStructure = useCommercialStructureListQuery();

  const [mutate, result] = useMutationHook<EndIrConversation, EndIrConversationVariables>({
    mutationName: 'end-ir-conversation.mutation',
    options: {
      onCompleted,
    },
    refetchQueries: [
      { query: 'list-ir-current-conversation.query', variables: { commercialStructure: commercialStructure.data } },
    ],
  });

  const endIrConversation = async (onCloseModalData: FvcConversationModalData, onSucceeded?: () => void) => {
    if (onCloseModalData.finished) {
      const feedback: IrConversationFeedbackInput = {
        byTelephone: onCloseModalData?.telephone,
        byWhatsApp: onCloseModalData?.whatsapp,
        feedbackId: onCloseModalData?.feedback,
        note: onCloseModalData?.note,
        status: ConversationStatusEnum[onCloseModalData?.status],
      };

      try {
        await mutate({ variables: { feedback, resellerId: queueContext.activeIndex } });
      } catch (_error) {
        return;
      }
      flashDispatcherService.dispatchMessage(
        {
          message: localized.feedbackSent,
        },
        'success',
        LARGE_BUTTON_HEIGHT,
      );
      queueContext.handleEndConversation();
      onSucceeded();
    }
    setIsModalOpened(false);
  };

  return {
    endIrConversation,
    error: result.error,
    isModalOpened,
    loading: result.loading,
    openEndConversationModal: () => setIsModalOpened(true),
  };
};
