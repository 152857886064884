import { ShimmerInput } from '@atomic/mol.shimmer';
import { VSeparator } from '@atomic/obj.grid';
import * as React from 'react';

const CART_PRODUCT_HEIGHT = '133px';

export const CartProductListShimmer: React.FC = () => {
  return (
    <>
      <ShimmerInput height={CART_PRODUCT_HEIGHT} />
      <VSeparator />
      <ShimmerInput height={CART_PRODUCT_HEIGHT} />
      <VSeparator />
      <ShimmerInput height={CART_PRODUCT_HEIGHT} />
    </>
  );
};
