import { FlashDispatcherService } from '@app/components/obj.flash-wrapper/flash-dispatcher.service';
import { LocalizedContext } from '@app/components/obj.localization';
import { useProductsSpreadsheetParser } from '@app/components/orders/org.cart-management/mol.products-spreadsheet/products-spreadsheet-parser.hook';
import { AddBatchProductItemInput, AddBatchToCart, AddProductResultTypeEnum } from '@app/data/graphql';
import { useErrorFlashDispatcher } from '@app/domain/error-dispatcher.hook';
import { useAddBatchToCartMutation } from '@app/domain/orders.use-case';
import { Button } from '@atomic/atm.button';
import { Card } from '@atomic/atm.card';
import { FileField } from '@atomic/atm.file-field';
import { Body, H1, H4 } from '@atomic/atm.typography';
import { ShimmerButton, ShimmerInput } from '@atomic/mol.shimmer';
import { Table, TD, TH, THead, TR } from '@atomic/mol.table';
import { Hbox } from '@atomic/obj.box';
import { Form, FormData, FormField, Validators } from '@atomic/obj.form';
import { VSeparator } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import * as React from 'react';
import Container from 'typedi';

interface AddBatchToCartInput {
  orderCode: string;
  items: AddBatchProductItemInput[];
}

interface AddProductsSpreadsheetProps {
  loading: boolean;
  orderCode: string;
  onComplete?: () => void;
}

export const AddProductsSpreadsheet: React.FC<AddProductsSpreadsheetProps> = (props) => {
  const localized = React.useContext(LocalizedContext).strings.components.productsSpreadsheetModal;
  const { items, setSpreadsheetFile, cleanup } = useProductsSpreadsheetParser();
  const flashDispatcherService = Container.get(FlashDispatcherService);

  const handleComplete = (data: AddBatchToCart) => {
    data.AddBatchToCart?.items?.forEach((item) => {
      if (item.result === AddProductResultTypeEnum.Error) {
        flashDispatcherService.dispatchMessage({ message: item.message }, 'warning');
      }
    });

    props.onComplete();
    cleanup();
  };

  const { addBatchToCart, error, loading: addBatchToCartLoading } = useAddBatchToCartMutation({
    onCompleted: handleComplete,
    orderCode: props.orderCode,
  });

  useErrorFlashDispatcher(error);

  const handleSubmit = (data?: FormData<AddBatchToCartInput>) => {
    if (Object.keys(data.error).length === 0) {
      addBatchToCart(data.data.items);
    }
  };

  return (
    <Card>
      <LoadingState loading={props.loading}>
        <LoadingState.Shimmer>
          <H1>{localized.title}</H1>
          <VSeparator />

          <ShimmerInput />

          <VSeparator />
          <Hbox>
            <Hbox.Item hAlign='flex-end'>
              <ShimmerButton />
            </Hbox.Item>
          </Hbox>
        </LoadingState.Shimmer>

        <Form onSubmit={handleSubmit}>
          <H1>{localized.title}</H1>
          <VSeparator />

          <FormField name='orderCode' value={props.orderCode} />

          <FormField name='spreadsheet'>
            <FileField id='spreadsheet' accept='.xls, .xlsx' onValueChange={setSpreadsheetFile} />
          </FormField>

          <FormField name='items' validators={[Validators.Required(localized.required)]} value={items} />

          <VSeparator />
          <Hbox>
            <Hbox.Item hAlign='flex-end'>
              <Button kind='primary' type='submit' loading={props.loading || addBatchToCartLoading}>
                {localized.submit}
              </Button>
            </Hbox.Item>
          </Hbox>

          {!!items.length && (
            <Table>
              <THead>
                <TR>
                  <TH>
                    <H4>{localized.productCode}</H4>
                  </TH>
                  <TH>
                    <H4>{localized.quantity}</H4>
                  </TH>
                </TR>
              </THead>
              {items?.map((row, index) => (
                <TR key={index}>
                  <TD>
                    <Body>{row.productCode}</Body>
                  </TD>
                  <TD>
                    <Body>{row.quantity}</Body>
                  </TD>
                </TR>
              ))}
            </Table>
          )}
        </Form>
      </LoadingState>
    </Card>
  );
};
