import { ActivityIndicator } from '@atomic/atm.activity-indicator';
import { Col, Row } from '@atomic/obj.grid';
import * as React from 'react';

export interface InfiniteScrollLoadingRowProps {
  isVisible: boolean;
  loadMore?: () => void;
  ref?: React.Ref<HTMLDivElement>;
  mb?: boolean;
  mt?: boolean;
}

export const InfiniteScrollLoadingRow: React.FC<InfiniteScrollLoadingRowProps> = React.forwardRef((props, ref) => {
  const { isVisible, loadMore } = props;

  React.useEffect(() => {
    if (isVisible) {
      loadMore?.();
    }
  }, [isVisible, loadMore]);

  return (
    <div ref={ref}>
      <Row center='xs' mt={props.mt} mb={props.mb}>
        <Col>
          <ActivityIndicator type='spinner' />
        </Col>
      </Row>
    </div>
  );
});
