import { AppPath } from '@app/modules/app/routes';

export enum AppType {
  Admin,
  Starts,
  Activation,
  IrDigital,
  Unknown,
}

export const mapPathnameToAppType = (pathname: string) => {
  if (pathname.includes(AppPath.Admin.Base)) {
    return AppType.Admin;
  }

  if (pathname.includes(AppPath.Starts.Base)) {
    return AppType.Starts;
  }

  if (pathname.includes(AppPath.IrDigital.Base)) {
    return AppType.IrDigital;
  }

  return AppType.Activation;
};
