import { GoogleAnalyticsService } from '@app/core/analytics';
import { ApolloClientBuilderService, ApolloImperative, GraphQLDocsService, GraphqlUrlToken } from '@app/core/graphql';
import { ApolloAdminClient, ApolloAdminImperative, GraphqlAdminDocsService } from '@app/core/graphql/tokens';
import { HttpClient, HttpRequestBuilder } from '@app/core/http';
import { HistoryToken, Router } from '@app/core/route';
import { BusTokenGraphqlInterceptor, MiddlewareTokenGraphqlInterceptor } from '@app/data/graphql';
import * as GraphqlDocs from '@app/data/graphql/graphql-documents.json';
import { ExternalApiHeadersInterceptor, OriginToken, TimeoutInterceptor } from '@app/data/http';
import { AxiosResponseInterceptor, BusinessUnitToken } from '@app/data/http/interceptor';
import { configPaymentRequestBuilder } from '@app/data/http/request-builders/sgi-payment.request-builder';
import { botTheme, eudoraTheme, ThemeToken } from '@atomic/obj.constants';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircle, faSquare } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { ApolloClient } from '@apollo/client';
import { createBrowserHistory, createMemoryHistory } from 'history';
import { Container } from 'typedi';
import { AppVersion } from './components/obj.app-version/app-version.service';
import { GoogleAnalyticsTagManagerService } from './components/obj.google-analytics-tag-manager/google-analytics-tag-manager.service';
import { HotJarService } from './components/obj.hotjar/hotjar.service';
import { Localized } from './components/obj.localization/localized';
import { UrlGuard } from './components/obj.url-guard/url-guard.service';
import { AppConfig, HotjarConfig, Project, QueueLimit } from './config-variables';
import { OneTrustBaseUrlToken, OneTrustConsentIdToken } from './core/one-trust';
import NewRelicBrowser from 'new-relic-browser';
import {
  CLIENT_ID,
  COMMERCIAL_MODEL_CODE,
  CONFIG_CAT_SDK_KEY,
  ORDER_MACHINE_URL,
  PRIVACY_POLICY_URL,
  QUEUE_LIMITS,
} from './modules/modules.token';
import { NewRelicErrorInterceptor } from '@app/data/graphql/interceptor/new-relic-error-interceptor';
import { UuidErrorInterceptor } from '@app/data/graphql/interceptor/uuid-error-interceptor';

/**
 * App config
 *
 * Remember to update metadata in config/webpack/client to reflect
 * changes when running in webpack-dev-server mode
 */

const DEFAULT_QUEUE_LIMIT_STARTS = 12;
const DEFAULT_QUEUE_LIMIT_ACTIVATION = 10;

declare global {
  interface Window {
    newrelic: typeof NewRelicBrowser;
  }
}

export function configure(config: AppConfig, ssr = false) {
  configureGraphlUrl(config.graphQLUrl);
  configureOrderMachineUrl(config.orderMachine);
  configureConfigCatSDKKey(config.configCatSDKKey);
  configurePrivacyPolicyUrl(config.privacyPolicy);
  configureLocalization(config);
  configureProject(config);
  configHttpBuilder(config);

  // as URI interceptor is dependent of Router, we should configure the latter first
  configureRoutesService(ssr);

  configureApolloClient(config, ssr);
  configureGoogleAnalyticsService(config);
  configureAppVersionService(config.version);
  configureFontAwesome();
  configPaymentRequestBuilder(config);
  configureOneTrust(config);
  configureHotJarService(config.hotjar);
  configureQueueLimits(config.queueLimit);
  configureGoogleAnalyticsTagManagerService(config);
}

function configHttpBuilder(env: AppConfig) {
  const client = Container.get(HttpClient);

  const interceptors = [new AxiosResponseInterceptor(), new ExternalApiHeadersInterceptor(), new TimeoutInterceptor()];

  const builder = new HttpRequestBuilder().configure({
    baseUrl: env.busUrl,
    client,
    interceptors,
  });
  Container.set(HttpRequestBuilder, builder);
}

function configureApolloClient({ graphQLUrl, debug }: AppConfig, ssr: boolean) {
  const interceptors = [
    new BusTokenGraphqlInterceptor(),
    new MiddlewareTokenGraphqlInterceptor(),
    new NewRelicErrorInterceptor(),
    new UuidErrorInterceptor(),
  ];
  const client = ApolloClientBuilderService.build({
    interceptors,
    isDevelop: debug,
    pathname: 'graphql',
    ssr,
    url: graphQLUrl,
  });

  const graphqlDocsService = new GraphQLDocsService(
    'src/app/data/graphql/client/query',
    'src/app/data/graphql/client/mutation',
    'src/app/data/graphql/client/subscription',
    GraphqlDocs,
  );

  Container.set(GraphQLDocsService, graphqlDocsService);
  Container.set(ApolloClient, client);
  Container.set(ApolloImperative, new ApolloImperative(client, graphqlDocsService));

  const admin = ApolloClientBuilderService.build({
    interceptors,
    isDevelop: debug,
    pathname: 'admin/graphql',
    ssr,
    url: graphQLUrl,
  });

  const graphqlAdminDocsService = new GraphQLDocsService(
    'src/app/data/graphql/admin/query',
    'src/app/data/graphql/admin/mutation',
    'src/app/data/graphql/admin/subscription',
    GraphqlDocs,
  );

  Container.set(GraphqlAdminDocsService, graphqlAdminDocsService);
  Container.set(ApolloAdminClient, admin);
  Container.set(ApolloAdminImperative, new ApolloImperative(admin, graphqlAdminDocsService));
}

function configureRoutesService(ssr: boolean) {
  const history: any = ssr ? createMemoryHistory() : createBrowserHistory();
  Container.set(HistoryToken, history);
  Container.set(Router, new Router());
}

function configureGraphlUrl(url: string) {
  Container.set(GraphqlUrlToken, url);
}

function configureOrderMachineUrl(url: string) {
  Container.set(ORDER_MACHINE_URL, url);
}

function configureConfigCatSDKKey(key: string) {
  Container.set(CONFIG_CAT_SDK_KEY, key);
}

function configureGoogleAnalyticsService(env: AppConfig) {
  Container.set(GoogleAnalyticsService, new GoogleAnalyticsService(env.googleAnalyticsTrackingId, env.debug));
}

function configureGoogleAnalyticsTagManagerService(env: AppConfig) {
  Container.set(GoogleAnalyticsTagManagerService, new GoogleAnalyticsTagManagerService(env.googleTagManagerId));
}

function configureAppVersionService(version: string) {
  Container.set(AppVersion, new AppVersion(version));
}

function configureFontAwesome() {
  library.add(fas, faCircle, faSquare);
}

function configureLocalization(env: AppConfig) {
  Container.set(Localized, new Localized(env.project));
}

function configureProject(env: AppConfig) {
  Container.set(OriginToken, env.origin);
  Container.set(BusinessUnitToken, env.businessUnit);
  Container.set(UrlGuard, new UrlGuard(env.blockFirebaseUrl, env.blockFirebaseUrlRedirectTo));
  Container.set(CLIENT_ID, env.clientId);
  Container.set(COMMERCIAL_MODEL_CODE, env.commercialModelCode);

  const themes = {
    [Project.bot]: botTheme,
    [Project.eudora]: eudoraTheme,
  };

  const theme = themes[env.project];

  Container.set(ThemeToken, theme);
}

function configureOneTrust(env: AppConfig) {
  Container.set(OneTrustBaseUrlToken, env.oneTrust.baseUrl);
  Container.set(OneTrustConsentIdToken, env.oneTrust.consentId);
}

function configurePrivacyPolicyUrl(url: string) {
  Container.set(PRIVACY_POLICY_URL, url);
}

function configureHotJarService(hotjar: HotjarConfig) {
  Container.set(HotJarService, new HotJarService(hotjar.id, hotjar.sv));
}

function configureQueueLimits(envQueueLimits: QueueLimit) {
  const queueLimits = {
    activation: envQueueLimits.activation || DEFAULT_QUEUE_LIMIT_ACTIVATION,
    starts: envQueueLimits.starts || DEFAULT_QUEUE_LIMIT_STARTS,
  };
  Container.set(QUEUE_LIMITS, queueLimits);
}
