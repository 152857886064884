import { PageWrapper } from '@app/components/obj.page-wrapper';
import { Route, Switch } from '@app/core/route';
import { ActivationLoadable } from '@app/modules/activation/activation.loadable';
import { AdminLoadable } from '@app/modules/admin/admin.loadable';
import { Error404Page } from '@app/modules/app/error404.page';
import { ActivationGuard } from '@app/modules/app/routes/activation.guard';
import { AdminGuard } from '@app/modules/app/routes/admin.guard';
import { StartsGuard } from '@app/modules/app/routes/starts.guard';
import CartRouter from '@app/modules/cart/cart.router';
import { IrDigitalLoadable } from '@app/modules/ir-digital/ir-digital.loadable';
import { StartsLoadable } from '@app/modules/starts/starts.loadable';
import { UserContext } from '@app/providers';
import { MessageNotificationProvider } from '@app/providers/message-notification.provider';
import { AnalyticsDimension, pushToDataLayer } from '@app/utils/data-layer';
import * as React from 'react';
import { IrDigitalGuard } from './ir-digital.guard';
import { AppPath } from './routes-list';

/**
 * Internal routes. We call internal routes every route which IS wrapped by the app shell (menu, navbar etc)
 */

export const InternalRoutes: React.FC = () => {
  const userContext = React.useContext(UserContext);

  React.useEffect(() => {
    if (userContext?.isLogged()) {
      pushToDataLayer({
        [AnalyticsDimension.UserId]: userContext?.userProfile?.id,
      });
    }
  }, [userContext, userContext.isLogged]);

  return (
    <MessageNotificationProvider>
      <PageWrapper>
        <Switch>
          <Route
            guard={AdminGuard}
            redirectPath={AppPath.Admin.Login}
            path={AppPath.Admin.Base}
            component={AdminLoadable}
          />
          <Route
            guard={StartsGuard}
            redirectPath={AppPath.Starts.Login}
            path={AppPath.Starts.Base}
            component={StartsLoadable}
          />
          <Route redirectPath={AppPath.Cart.Base} path={AppPath.Cart.Base} component={CartRouter} />
          <Route
            guard={IrDigitalGuard}
            redirectPath={AppPath.IrDigital.Login}
            path={[AppPath.IrDigital.Base]}
            component={IrDigitalLoadable}
          />
          <Route
            guard={ActivationGuard}
            redirectPath={AppPath.Activation.Login}
            path={[AppPath.Activation.Base, AppPath.Base]}
            component={ActivationLoadable}
          />
          <Route component={Error404Page} />
        </Switch>
      </PageWrapper>
    </MessageNotificationProvider>
  );
};
