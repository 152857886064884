import { Theme, ThemeToken } from '@atomic/obj.constants';
import styled from 'styled-components';
import Container from 'typedi';

export interface DotBadgeProps extends Theme {
  type: 'success' | 'light' | 'accessory' | 'alert';
}
const theme = Container.get(ThemeToken);

const badgeColors = {
  accessory: theme.Color.Accessory,
  alert: theme.Color.Alert,
  light: theme.Color.GrayXLight,
  success: theme.Color.Primary,
};

export const DotBadge = styled.span`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: ${(props: DotBadgeProps) => badgeColors[props.type] || props.theme.Color.Primary};
  display: inline-block;
  align-self: flex-start;
  justify-content: center;
`;
