import React from 'react';
import { Body, H3 } from '@atomic/atm.typography';
import { Stepper } from '@atomic/atm.stepper';
import { Hbox } from '@atomic/obj.box';
import { ShimmerText, ShimmerButton } from '@atomic/mol.shimmer';
import { Card } from '@atomic/atm.card';
import { ComboProduct } from '@app/components/mol.combo-eudora-modal/combo-eudora-modal.component';
import { VSeparator } from '@atomic/obj.grid';

export interface ComboProductListItemProps {
  selectionCode?: string;
  productCode: string;
  imageUrl: string;
  quantity: number;
  title?: string;
  disabledMinus?: boolean;
  disabledPlus?: boolean;
  onValueChange: (product: ComboProduct) => void;
}

export const ComboProductListItem: React.FC<ComboProductListItemProps> = (props) => {
  const [quantity, setQuantity] = React.useState(props.quantity);

  const handleValueChange = (value) => {
    setQuantity(value);
    props.onValueChange({ productCode: props.productCode, quantity: value, selectionCode: props.selectionCode });
  };

  return (
    <>
      <VSeparator />
      <Card bordered>
        <Hbox>
          <Hbox.Item>
            <H3>{props.productCode}</H3>
            <Body>{props.title}</Body>
          </Hbox.Item>
          <Hbox.Separator />
          <Hbox.Item vAlign='center'>
            <Stepper
              value={quantity}
              onChangeValue={handleValueChange}
              minValue={0}
              disablePlus={props.disabledPlus}
              disableMinus={props.disabledMinus}
              disableText
            />
          </Hbox.Item>
        </Hbox>
      </Card>
    </>
  );
};

export const ComboProductListItemShimmer = () => {
  return (
    <Hbox>
      <Hbox.Item>
        <ShimmerText />
        <ShimmerText />
      </Hbox.Item>
      <Hbox.Separator />
      <Hbox.Item>
        <ShimmerButton />
      </Hbox.Item>
    </Hbox>
  );
};
