import { defaultTheme, ShadesOfGray } from './constants';

enum BotBrandColor {
  Green = '#007E78',
  DarkGreen = '#006B66',
  Purple = '#A5407D',
  VibrantGreen = '#07B79C',
  GreenAndBlue = '#00857C',
  LightGreen = '#009F67',
  SeaGreen = '#08DCBB',
  Red = '#FA364A',
  Yellow = '#FFCC5B',
}

export enum BotCategoryColor {
  A0 = '#9CCC66',
  A1 = '#AFD484',
  A2 = '#C3E0A6',
  A3 = '#DEECC9',
  I4 = '#EF999C',
  I5 = '#e57172',
  I6 = '#FA364A',
  I7 = '#BDBDBD',
}

export class BotColor {
  public static readonly Black = '#000000';
  public static readonly White = '#FFFFFF';
  public static readonly YellowBadge = '#CCA314';

  public static readonly GrayXLight = ShadesOfGray.GrayXLight;
  public static readonly GrayLight = ShadesOfGray.GrayLight;
  public static readonly Gray = ShadesOfGray.Gray;
  public static readonly GrayDark = ShadesOfGray.GrayDark;
  public static readonly GrayXDark = ShadesOfGray.GrayXDark;

  public static readonly Primary = BotBrandColor.Green;
  public static readonly Secondary = BotBrandColor.DarkGreen;
  public static readonly Accessory = BotBrandColor.Purple;
  public static readonly Neutral = BotColor.GrayDark;
  public static readonly CallToAction = BotBrandColor.VibrantGreen;

  public static readonly Alert = BotBrandColor.Red;
  public static readonly Warning = BotBrandColor.Yellow;
  public static readonly Success = BotBrandColor.LightGreen;

  public static readonly GoalSpecial = BotBrandColor.SeaGreen;
}

enum BotFontFamily {
  Primary = '"Effra", Helvetica, Arial, sans-serif',
  Secondary = 'OBoticario, "Helvetica Neue", Helvetica, Arial, sans-serif',
}

export const botTheme = {
  ...defaultTheme,
  BrandColor: BotBrandColor,
  CategoryColor: BotCategoryColor,
  Color: BotColor,
  FontFamily: BotFontFamily,
  Project: 'bot',
};
