import React from 'react';
import { H1, H3, Bold, Body } from '@atomic/atm.typography';
import { VSeparator } from '@atomic/obj.grid';
import { LocalizedContext } from '@app/components/obj.localization';
import { ComboType } from '@app/data/graphql';
import { Hbox } from '@atomic/obj.box';
import { Stepper } from '@atomic/atm.stepper';
import { Progress } from '@atomic/atm.progress';
import { ProgressCell } from '@atomic/atm.progress/progress-cell.component';
import { FlashDispatcherService } from '@app/components/obj.flash-wrapper/flash-dispatcher.service';
import Container from 'typedi';

export enum ComboStep {
  Details = 1,
  Categories,
  Review,
}

interface ComboModalHeaderProps {
  combo: ComboType;
  step: number;
  category: number;
  comboQuantity: number;
  onComboQuantityChange: (newValue: number) => void;
}

export const ComboModalHeader: React.FC<ComboModalHeaderProps> = ({
  combo,
  step,
  category,
  comboQuantity,
  onComboQuantityChange,
}) => {
  const localized = React.useContext(LocalizedContext).strings.components.comboEudora;
  const flashDispatcher = Container.get(FlashDispatcherService);

  const handleComboQuantityChange = (newValue: number) => {
    onComboQuantityChange(newValue);

    if (newValue !== comboQuantity) {
      notifyToSelectPreviousCategoriesProducts();
    }
  };

  const notifyToSelectPreviousCategoriesProducts = () => {
    if (step === ComboStep.Categories && category > 0) {
      flashDispatcher.dispatchMessage(
        {
          message: localized.canSelectMoreProductsInPreviousCategories(
            combo.productSelections.map((_, index) => index + 1).slice(0, category),
          ),
        },
        'warning',
      );
    }
  };

  return (
    <>
      <Hbox>
        <Hbox.Item>
          <H1>{localized.title}</H1>
        </Hbox.Item>
      </Hbox>
      <VSeparator />

      <Hbox>
        <Hbox.Item>
          <Body>
            <Bold>{`${localized.code}: `}</Bold>
            {combo?.code}
          </Body>
          <VSeparator small />
          <Body>
            <Bold>{`${localized.product}: `}</Bold>
            {combo?.name}
          </Body>
        </Hbox.Item>
        <Hbox.Separator />
        <Hbox.Item noGrow>
          {step === ComboStep.Review ? (
            <H3>{localized.quantity(comboQuantity)}</H3>
          ) : (
            <Stepper minValue={1} value={comboQuantity} onChangeValue={handleComboQuantityChange} />
          )}
        </Hbox.Item>
        <Hbox.Separator />
      </Hbox>
      <VSeparator />

      <Progress step={step + category}>
        <ProgressCell number={1} text={localized.details} key={1} />
        {combo?.productSelections?.map((_comboSelections, index) => (
          <ProgressCell number={index + 2} text={localized.category(index + 1)} key={index} />
        ))}
        <ProgressCell
          number={combo?.productSelections?.length + 2}
          text={localized.review}
          key={combo?.productSelections?.length}
        />
      </Progress>
    </>
  );
};
