import * as React from 'react';
import { readFirstSheet } from '@app/utils/spreadsheet';
import { SALE_ORIGIN } from '@app/domain/orders.use-case';

export const useProductsSpreadsheetParser = () => {
  const [sheetItems, setSheetItems] = React.useState<number[][]>([]);
  const [spreadsheetFile, setSpreadsheetFile] = React.useState<File>(null);

  const cleanup = () => {
    setSpreadsheetFile(null);
    setSheetItems([]);
  };

  React.useEffect(() => {
    if (spreadsheetFile) {
      const reader = new FileReader();

      reader.onload = () => {
        const { rows } = readFirstSheet(reader.result as ArrayBuffer);
        setSheetItems(rows);
      };

      reader.readAsArrayBuffer(spreadsheetFile);
    }
  }, [spreadsheetFile]);

  return {
    cleanup,
    items: mapItemsToAddBatch(sheetItems),
    setSpreadsheetFile,
  };
};

const mapItemsToAddBatch = (items?: number[][]) => {
  if (!items) {
    return null;
  }

  return items
    .filter((row) => row[0] !== undefined)
    .map((row) => ({
      originId: SALE_ORIGIN,
      productCode: row[0].toString(),
      quantity: row[1] ?? 1,
    }));
};
