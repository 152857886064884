import { IcContact } from '@app/components/icons/ic-contact.component';
import { IcDashboard } from '@app/components/icons/ic-dashboard.component';
import { IcNote } from '@app/components/icons/ic-note.component';
import {
  DeskMenuLinkStyled,
  MenuLinkTitleStyled,
} from '@app/components/obj.page-wrapper/mol.app-header/app-header.component.style';
import { AppType } from '@app/components/obj.page-wrapper/mol.app-header/pathname-to-app-type.mapper';
import { MenuContent } from '@app/components/org.menu-content/menu-content.component';
import { Project } from '@app/config-variables';
import { hasDocument } from '@app/core/browser/platform';
import { AppPath } from '@app/modules/app/routes/routes-list';
import { FeatureToggleContext } from '@app/providers';
import { EventAction, EventCategory, EventLabel, GtmEventTag } from '@app/utils/data-layer';
import { Hbox } from '@atomic/obj.box';
import { ThemeToken } from '@atomic/obj.constants';
import { LocationDescriptorObject } from 'history';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'typedi';
import {
  DeskMenuLinksStyled,
  MenuLinksItemTitleStyled,
  MenuLinksItemWrapperStyled,
  MenuLinksWrapperStyled,
} from './menu.component.style';

export interface MenuLinksProps {
  onClick?: () => void;
  appType: AppType;
}

interface MenuLinksItemProps extends GtmEventTag {
  to: LocationDescriptorObject;
  currentPathname: string;
  onClick?: () => void;
}

interface DeskMenuLinksProps extends GtmEventTag {
  appType: AppType;
}

const themeContext = Container.get(ThemeToken);

export const MobileMenuLinks: React.FC<MenuLinksProps> = (props) => {
  const featureToggleOption = React.useContext(FeatureToggleContext).featureToggleOptions;
  const currentPathname = hasDocument() && location.pathname;
  const appType = props.appType;
  return (
    <MenuLinksWrapperStyled>
      {appType === AppType.Admin && (
        <>
          <MobileMenuLinksItem
            to={{ pathname: AppPath.Admin.Franchise }}
            currentPathname={currentPathname}
            onClick={props.onClick}
            dataGtmEventCategory={EventCategory.General}
            dataGtmEventAction={EventAction.Click.SideMenu}
            dataGtmEventLabel={EventLabel.General.Franchise}
          />
          <MobileMenuLinksItem
            to={{ pathname: AppPath.Admin.News.Base }}
            currentPathname={currentPathname}
            onClick={props.onClick}
            dataGtmEventCategory={EventCategory.General}
            dataGtmEventAction={EventAction.Click.SideMenu}
            dataGtmEventLabel={EventLabel.General.News}
          />
          <MobileMenuLinksItem
            to={{ pathname: AppPath.Admin.Questions }}
            currentPathname={currentPathname}
            onClick={props.onClick}
            dataGtmEventCategory={EventCategory.General}
            dataGtmEventAction={EventAction.Click.SideMenu}
            dataGtmEventLabel={EventLabel.Question.Base}
          />
          <MobileMenuLinksItem
            to={{ pathname: AppPath.Admin.Tabulation }}
            currentPathname={currentPathname}
            onClick={props.onClick}
            dataGtmEventCategory={EventCategory.General}
            dataGtmEventAction={EventAction.Click.SideMenu}
            dataGtmEventLabel={EventLabel.General.Tabulation}
          />
          <MobileMenuLinksItem
            to={{ pathname: AppPath.Admin.Script.Base }}
            currentPathname={currentPathname}
            onClick={props.onClick}
            dataGtmEventCategory={EventCategory.General}
            dataGtmEventAction={EventAction.Click.SideMenu}
            dataGtmEventLabel={EventLabel.Script.Base}
          />
        </>
      )}
      {appType === AppType.Activation && (
        <>
          <MobileMenuLinksItem
            to={{ pathname: AppPath.Activation.Home.Base }}
            currentPathname={currentPathname}
            onClick={props.onClick}
            dataGtmEventCategory={EventCategory.General}
            dataGtmEventAction={EventAction.Click.SideMenu}
            dataGtmEventLabel={EventLabel.General.Attendence}
          />
          {featureToggleOption.fvcIndicatorsPage && themeContext.Project === Project.bot && (
            <MobileMenuLinksItem
              to={{ pathname: AppPath.Activation.Indicators }}
              currentPathname={currentPathname}
              onClick={props.onClick}
              dataGtmEventCategory={EventCategory.General}
              dataGtmEventAction={EventAction.Click.SideMenu}
              dataGtmEventLabel={EventLabel.General.Indicators}
            />
          )}
          <MobileMenuLinksItem
            to={{ pathname: AppPath.Activation.History }}
            currentPathname={currentPathname}
            onClick={props.onClick}
            dataGtmEventCategory={EventCategory.General}
            dataGtmEventAction={EventAction.Click.SideMenu}
            dataGtmEventLabel={EventLabel.General.History}
          />
        </>
      )}
      <Hbox>
        <Hbox.Item vAlign='flex-end'>
          <MenuContent />
        </Hbox.Item>
      </Hbox>
    </MenuLinksWrapperStyled>
  );
};

const MobileMenuLinksItem: React.FC<MenuLinksItemProps> = (props) => {
  const { name } = getIconAndTitleFromPathname(props.to.pathname);
  const selected = isSelectedPath(props.currentPathname, props.to.pathname);
  return (
    <Link
      to={props.to}
      onClick={props.onClick}
      data-gtm-event-category={props.dataGtmEventCategory}
      data-gtm-event-action={props.dataGtmEventAction}
      data-gtm-event-label={props.dataGtmEventLabel}
    >
      <MenuLinksItemWrapperStyled selected={selected}>
        <Hbox>
          <Hbox.Item vAlign='center'>
            <MenuLinksItemTitleStyled selected={selected}>{name}</MenuLinksItemTitleStyled>
          </Hbox.Item>
        </Hbox>
      </MenuLinksItemWrapperStyled>
    </Link>
  );
};

export const DeskMenuLinks: React.FC<DeskMenuLinksProps> = (props) => {
  const featureToggleOption = React.useContext(FeatureToggleContext).featureToggleOptions;
  const currentPathname = hasDocument() && location.pathname;
  const appType = props.appType;
  return (
    <DeskMenuLinksStyled>
      {appType === AppType.Admin && (
        <>
          {themeContext.Project === Project.bot && (
            <DeskMenuLink
              to={{ pathname: AppPath.Admin.Franchise }}
              currentPathname={currentPathname}
              dataGtmEventCategory={EventCategory.General}
              dataGtmEventAction={EventAction.Click.SuperiorMenu}
              dataGtmEventLabel={EventLabel.General.Franchise}
            />
          )}
          <DeskMenuLink
            to={{ pathname: AppPath.Admin.News.Base }}
            currentPathname={currentPathname}
            dataGtmEventCategory={EventCategory.General}
            dataGtmEventAction={EventAction.Click.SuperiorMenu}
            dataGtmEventLabel={EventLabel.General.News}
          />
          <DeskMenuLink
            to={{ pathname: AppPath.Admin.Tabulation }}
            currentPathname={currentPathname}
            dataGtmEventCategory={EventCategory.General}
            dataGtmEventAction={EventAction.Click.SuperiorMenu}
            dataGtmEventLabel={EventLabel.General.Tabulation}
          />
          {themeContext.Project === Project.bot && (
            <>
              <DeskMenuLink
                to={{ pathname: AppPath.Admin.Questions }}
                currentPathname={currentPathname}
                dataGtmEventCategory={EventCategory.General}
                dataGtmEventAction={EventAction.Click.SuperiorMenu}
                dataGtmEventLabel={EventLabel.Question.Base}
              />
              <DeskMenuLink
                to={{ pathname: AppPath.Admin.Script.Base }}
                currentPathname={currentPathname}
                dataGtmEventCategory={EventCategory.General}
                dataGtmEventAction={EventAction.Click.SuperiorMenu}
                dataGtmEventLabel={EventLabel.Script.Base}
              />
            </>
          )}
          {featureToggleOption.fvcMessageTemplates && (
            <DeskMenuLink
              to={{ pathname: AppPath.Admin.Templates }}
              currentPathname={currentPathname}
              dataGtmEventCategory={EventCategory.General}
              dataGtmEventAction={EventAction.Click.SuperiorMenu}
              dataGtmEventLabel={EventLabel.General.Templates}
            />
          )}
          <DeskMenuLink
            to={{ pathname: AppPath.Admin.Filters }}
            currentPathname={currentPathname}
            dataGtmEventCategory={EventCategory.General}
            dataGtmEventAction={EventAction.Click.SuperiorMenu}
            dataGtmEventLabel={EventLabel.General.Filters}
          />
          <DeskMenuLink
            to={{ pathname: AppPath.Admin.PublicMedia }}
            currentPathname={currentPathname}
            dataGtmEventCategory={EventCategory.General}
            dataGtmEventAction={EventAction.Click.SuperiorMenu}
            dataGtmEventLabel={EventLabel.General.PublicMedia}
          />
          <DeskMenuLink
            to={{ pathname: AppPath.Admin.Configuration.Base }}
            currentPathname={currentPathname}
            dataGtmEventCategory={EventCategory.General}
            dataGtmEventAction={EventAction.Click.SuperiorMenu}
            dataGtmEventLabel={EventLabel.General.Configurations}
          />
        </>
      )}
      {appType === AppType.Activation && (
        <>
          <DeskMenuLink
            to={{ pathname: AppPath.Activation.Home.Dashboard.Base }}
            currentPathname={currentPathname}
            dataGtmEventCategory={EventCategory.General}
            dataGtmEventAction={EventAction.Click.SuperiorMenu}
            dataGtmEventLabel={EventLabel.General.Attendence}
          />
          {featureToggleOption.fvcIndicatorsPage && themeContext.Project === Project.bot && (
            <DeskMenuLink
              to={{ pathname: AppPath.Activation.Indicators }}
              currentPathname={currentPathname}
              dataGtmEventCategory={EventCategory.General}
              dataGtmEventAction={EventAction.Click.SuperiorMenu}
              dataGtmEventLabel={EventLabel.General.Indicators}
            />
          )}
          <DeskMenuLink
            to={{ pathname: AppPath.Activation.News.Base }}
            currentPathname={currentPathname}
            dataGtmEventCategory={EventCategory.General}
            dataGtmEventAction={EventAction.Click.SuperiorMenu}
            dataGtmEventLabel={EventLabel.General.News}
          />
          <DeskMenuLink
            to={{ pathname: AppPath.Activation.History }}
            currentPathname={currentPathname}
            dataGtmEventCategory={EventCategory.General}
            dataGtmEventAction={EventAction.Click.SuperiorMenu}
            dataGtmEventLabel={EventLabel.General.History}
          />
        </>
      )}
      {appType === AppType.Starts && (
        <>
          <DeskMenuLink
            to={{ pathname: AppPath.Starts.Attendance }}
            currentPathname={currentPathname}
            dataGtmEventCategory={EventCategory.General}
            dataGtmEventAction={EventAction.Click.SuperiorMenu}
            dataGtmEventLabel={EventLabel.General.Attendence}
          />
          <DeskMenuLink
            to={{ pathname: AppPath.Starts.History }}
            currentPathname={currentPathname}
            dataGtmEventCategory={EventCategory.General}
            dataGtmEventAction={EventAction.Click.SuperiorMenu}
            dataGtmEventLabel={EventLabel.General.History}
          />
          <DeskMenuLink
            to={{ pathname: AppPath.Starts.News.Base }}
            currentPathname={currentPathname}
            dataGtmEventCategory={EventCategory.General}
            dataGtmEventAction={EventAction.Click.SuperiorMenu}
            dataGtmEventLabel={EventLabel.General.News}
          />
        </>
      )}
      {appType === AppType.IrDigital && (
        <DeskMenuLink
          to={{ pathname: AppPath.IrDigital.Home }}
          currentPathname={currentPathname}
          dataGtmEventCategory={EventCategory.General}
          dataGtmEventAction={EventAction.Click.SuperiorMenu}
          dataGtmEventLabel={EventLabel.General.Attendence}
        />
      )}
    </DeskMenuLinksStyled>
  );
};

const DeskMenuLink: React.FC<MenuLinksItemProps> = (props) => {
  const { name } = getIconAndTitleFromPathname(props.to.pathname);
  const selected = isSelectedPath(props.currentPathname, props.to.pathname);
  return (
    <Link
      to={props.to}
      data-gtm-event-category={props.dataGtmEventCategory}
      data-gtm-event-action={props.dataGtmEventAction}
      data-gtm-event-label={props.dataGtmEventLabel}
    >
      <DeskMenuLinkStyled selected={selected} data-cy={name}>
        <MenuLinkTitleStyled selected={selected}>{name}</MenuLinkTitleStyled>
      </DeskMenuLinkStyled>
    </Link>
  );
};

enum MenuTitles {
  Attendance = 'Atendimento',
  Configurations = 'Configurações',
  Indicators = 'Indicadores',
  History = 'Histórico',
  Franchise = 'Franquias',
  News = 'Notícias',
  Tabulation = 'Tabulação',
  Questions = 'Perguntas',
  Script = 'Roteiro',
  Templates = 'Templates',
  Filters = 'Filtros',
  PublicMedia = 'Mídias',
}

export const getIconAndTitleFromPathname = (pathname: string) => {
  switch (pathname) {
    case AppPath.Activation.Home.Base:
      return { icon: IcContact, name: MenuTitles.Attendance };
    case AppPath.IrDigital.Home:
      return { icon: IcContact, name: MenuTitles.Attendance };
    case AppPath.Activation.Indicators:
      return { icon: IcDashboard, name: MenuTitles.Indicators };
    case AppPath.Activation.History:
      return { icon: IcNote, name: MenuTitles.History };
    case AppPath.Activation.News.Base:
      return { icon: IcContact, name: MenuTitles.News };
    case AppPath.Admin.News.Base:
      return { icon: IcContact, name: MenuTitles.News };
    case AppPath.Admin.Configuration.Base:
      return { icon: IcContact, name: MenuTitles.Configurations };
    case AppPath.Admin.Franchise:
      return { icon: IcContact, name: MenuTitles.Franchise };
    case AppPath.Admin.Questions:
      return { icon: IcContact, name: MenuTitles.Questions };
    case AppPath.Admin.Script.Base:
      return { icon: IcContact, name: MenuTitles.Script };
    case AppPath.Starts.News.Base:
      return { icon: IcContact, name: MenuTitles.News };
    case AppPath.Admin.Tabulation:
      return { icon: IcContact, name: MenuTitles.Tabulation };
    case AppPath.Admin.Templates:
      return { icon: IcContact, name: MenuTitles.Templates };
    case AppPath.Admin.Filters:
      return { icon: IcContact, name: MenuTitles.Filters };
    case AppPath.Admin.PublicMedia:
      return { icon: IcContact, name: MenuTitles.PublicMedia };
    case AppPath.Starts.History:
      return { icon: IcContact, name: MenuTitles.History };
    default:
      return { icon: IcDashboard, name: MenuTitles.Attendance };
  }
};

export const isSelectedPath = (currentPathname: string, toPathname: string) => {
  return currentPathname.includes(toPathname);
};
