import { MenuLinkTitleStyled } from '@app/components/obj.page-wrapper/mol.app-header/app-header.component.style';
import { H1, H1Light } from '@atomic/atm.typography';
import { Theme } from '@atomic/obj.constants';
import { ScrollYStyle } from '@atomic/obj.mixin';
import styled from 'styled-components';

export const MenuWrapperStyled = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;
`;

export const MenuContentWrapperStyled = styled.div`
  overflow-x: hidden;
  ${ScrollYStyle}

  height: calc(100vh - ${(props) => props.theme.HeaderHeight.Mobile});

  @media all and (min-width: ${(props) => props.theme.Breakpoint.lg}em) {
    height: calc(100vh - ${(props) => props.theme.HeaderHeight.Desk});
  }
`;

export const MenuLogoWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  padding: 0 ${(props) => props.theme.Spacing.Medium};
`;

export const MenuInfoWidth = '400px';
export const MenuDeskInfoWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: ${MenuInfoWidth};
  overflow: hidden;
  height: ${(props) => props.theme.HeaderHeight.Desk};
`;

export const MenuLinksWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${(props) => props.theme.Spacing.XLarge} 0;
  height: calc(100vh - ${(props) => props.theme.HeaderHeight.Mobile});
  ${ScrollYStyle}

  @media all and (min-width: ${(props) => props.theme.Breakpoint.lg}em) {
    height: calc(100vh - ${(props) => props.theme.HeaderHeight.Desk});
  }
`;

interface MenuLinksItemStyledProps extends Theme {
  selected: boolean;
}

export const MenuLinksItemIconStyled = styled.div.attrs((props: MenuLinksItemStyledProps) => ({
  color: props.selected ? props.theme.Color.Primary : props.theme.Color.GrayDark,
  height: props.theme.IconSize.Large,
  width: props.theme.IconSize.Large,
}))``;

export const MenuLinksItemTitleStyled = styled(H1)`
  color: ${(props: MenuLinksItemStyledProps) =>
    props.selected ? props.theme.Color.Primary : props.theme.Color.GrayDark};
`;

export const MenuLinksItemWrapperStyled = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: ${(props) => props.theme.Spacing.Medium};
  margin-bottom: ${(props) => props.theme.Spacing.Small};
  height: 80px;

  &::before {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    top: 8px;
    right: 0;
    border-top: 32px ${(props) => props.theme.Color.Primary} solid;
    border-top-left-radius: 50%;
    border-bottom: 32px ${(props) => props.theme.Color.Primary} solid;
    border-bottom-left-radius: 50%;

    transition: width 0.3s;
  }

  ${(props: MenuLinksItemStyledProps) =>
    props.selected
      ? `
    &:before {
      width: 8px;
    }
  `
      : `

    &:hover:before {
      width: 8px;
    }
  `};
`;

export const DeskMenuLinksStyled = styled.div`
  display: flex;
  width: 550px;
  height: 100%;
`;

export const DeskMenuLinksIconStyled = styled(MenuLinksItemIconStyled).attrs((props) => ({
  color: props.theme.Color.White,
}))`
  position: absolute;
  top: 8px;
  left: calc(50% - 16px);
  opacity: 0;
  transform: rotateY(180deg);
  transition: all ${(props) => props.theme.TransitionDuration} ease-in-out;
`;

export const DeskMenuLinksItemStyled = styled.div`
  position: relative;
  padding: ${(props) => props.theme.Spacing.Medium};
  ${(props: MenuLinksItemStyledProps) => props.selected && 'text-decoration-line: underline'};

  & > ${H1Light} {
    opacity: ${(props: MenuLinksItemStyledProps) => (props.selected ? 1 : 0.5)};
    transition: all ${(props) => props.theme.TransitionDuration} ease-in-out;
  }

  &:hover {
    & ${DeskMenuLinksIconStyled} {
      transform: rotateY(0deg);
      opacity: 1;
    }

    & ${MenuLinkTitleStyled} {
      transform: rotateY(180deg);
      opacity: 0;
    }
  }

  transition: all ${(props) => props.theme.TransitionDuration} ease-in-out;
`;
