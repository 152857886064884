import { AttendanceDatasource } from '@app/data/local/attendance.datasource';
import { AttendanceStatus } from '@app/models/attendance-status.model';
import { AppPath } from '@app/modules/app/routes';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Container from 'typedi';

interface AttendanceContextState {
  attendanceStatus: AttendanceStatus;
  setAttendanceStatus: React.Dispatch<React.SetStateAction<AttendanceStatus>>;
}

export const AttendanceContext = React.createContext<AttendanceContextState>(null);
const attendanceDatasource = Container.get(AttendanceDatasource);

export const AttendanceProvider: React.FC = (props) => {
  const history = useHistory();
  const location = useLocation();

  const [attendanceStatus, setAttendanceStatus] = React.useState<AttendanceStatus>(
    location.pathname.match('starts/attendance') ? AttendanceStatus.onAttendance : AttendanceStatus.notOnAttendance,
  );

  React.useEffect(() => {
    switch (attendanceStatus) {
      case AttendanceStatus.paused:
        attendanceDatasource.setPause(true);
        attendanceDatasource.setContinueCall(false);
        break;
      case AttendanceStatus.notOnAttendance:
        if (attendanceDatasource.shouldContinueCall()) {
          setAttendanceStatus(AttendanceStatus.onStartSearch);
        }
        break;
      case AttendanceStatus.attendingByWhatsapp:
        if (location.pathname !== AppPath.Starts.Attendance) {
          history.push({
            pathname: AppPath.Starts.Attendance,
          });
        }
    }
  }, [attendanceStatus, history, location]);

  return (
    <AttendanceContext.Provider
      value={{
        attendanceStatus,
        setAttendanceStatus,
      }}
    >
      {props.children}
    </AttendanceContext.Provider>
  );
};
