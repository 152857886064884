import React from 'react';
import { H3, Body } from '@atomic/atm.typography';
import { LocalizedContext } from '@app/components/obj.localization';
import { Hbox } from '@atomic/obj.box';
import { Button } from '@atomic/atm.button';
import { ComboProducts } from '@app/components/mol.combo-eudora-modal/combo-eudora-modal.component';
import { ComboType } from '@app/data/graphql';
import { Card } from '@atomic/atm.card';
import { VSeparator } from '@atomic/obj.grid';

interface ComboReviewProps {
  productsSelected: ComboProducts;
  combo: ComboType;
  onEditClick: (newStep: number) => void;
  comboQuantity: number;
}

export const ComboReview: React.FC<ComboReviewProps> = ({ combo, productsSelected, onEditClick, comboQuantity }) => {
  return (
    <>
      {combo?.productSelections?.map((selection, index) => {
        return selection.products
          .filter((product) => productsSelected?.[product?.code]?.quantity > 0)
          .map((product) => {
            return (
              <ComboReviewListItem
                key={product?.code}
                code={product?.code}
                name={product?.name}
                quantity={productsSelected?.[product?.code]?.quantity}
                onEditClick={() => onEditClick(index + 2)}
              />
            );
          });
      })}
      {combo?.products?.map((product) => {
        return (
          <ComboReviewListItem
            key={product?.code}
            code={product?.code}
            name={product?.name}
            quantity={product?.quantity * comboQuantity}
          />
        );
      })}
    </>
  );
};

interface ComboReviewListItemProps {
  code: string;
  name: string;
  quantity: number;
  onEditClick?: () => void;
}

const ComboReviewListItem: React.FC<ComboReviewListItemProps> = (props) => {
  const localized = React.useContext(LocalizedContext).strings.components.comboEudora;
  return (
    <>
      <VSeparator />
      <Card bordered>
        <Hbox>
          <Hbox.Item>
            <H3>{props.code}</H3>
            <Body>{props.name}</Body>
          </Hbox.Item>
          <Hbox.Separator />
          <Hbox.Item noGrow vAlign='center'>
            {props.onEditClick && (
              <Button kind='externalLink' onClick={props.onEditClick}>
                {localized.edit}
              </Button>
            )}
          </Hbox.Item>
          <Hbox.Separator />
          <Hbox.Item noGrow vAlign='center'>
            <Body>{localized.quantity(props.quantity)}</Body>
          </Hbox.Item>
        </Hbox>
      </Card>
    </>
  );
};
