import { Button } from '@atomic/atm.button';
import { Card, CardContentWrapper } from '@atomic/atm.card';
import { FaIcon } from '@atomic/atm.fa-icon';
import { H4 } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { ThemeToken } from '@atomic/obj.constants';
import * as React from 'react';
import Container from 'typedi';
import {
  AccordionCollapsibleStyled,
  AccordionContentStyled,
  AccordionHeaderStyled,
  AccordionStyled,
} from './accordion-cell.component.style';

const theme = Container.get(ThemeToken);

export interface AccordionCellProps {
  accordionId?: string;
  onHeaderTap?: (key: string, expanded: boolean) => void;
  expanded?: boolean;
  title?: string;
  onContentResize?: (size: number) => void;
  noPadding?: boolean;
  bordered?: boolean;
  headerButtonText?: string;
  onHeaderButtonClick?: () => void;
}

interface AccordionState {
  expanded: boolean;
  contentHeight: number;
}

export class AccordionCell extends React.Component<AccordionCellProps, AccordionState> {
  private contentElement: React.RefObject<HTMLElement>;

  constructor(props) {
    super(props);
    this.state = {
      contentHeight: 0,
      expanded: props.expanded ? true : false,
    };

    this.contentElement = React.createRef();
  }

  componentDidMount() {
    const contentHeight: number = this.contentElement.current.clientHeight;
    if (this.state.contentHeight !== contentHeight) {
      this.setState({ contentHeight });
    }
  }

  componentDidUpdate(prevProps: AccordionCellProps) {
    if (prevProps.expanded !== this.props.expanded && this.props.expanded !== this.state.expanded) {
      this.setState({ expanded: this.props.expanded });
    }
  }

  handleContentResize = (size: number) => {
    this.setState({ contentHeight: this.contentElement.current.clientHeight + size });
  };

  handleHeaderTap = () => {
    const shouldExpand = !this.state.expanded;
    if (this.props.onHeaderTap) {
      this.props.onHeaderTap(this.props.accordionId, shouldExpand);
    }

    const contentHeight: number = shouldExpand ? this.contentElement.current.clientHeight : 0;

    this.setState({ contentHeight, expanded: !this.state.expanded });

    if (shouldExpand && this.props.onContentResize) {
      this.props.onContentResize(this.contentElement.current.clientHeight);
    }
  };

  render() {
    const wrapped = React.Children.map(this.props.children, (child: any, i: number) => {
      if (child && child.type === AccordionCell) {
        const key = 'Accordion_' + i;
        return React.cloneElement(child, {
          key: child.key || key,
          onContentResize: this.handleContentResize,
        });
      } else {
        return child;
      }
    });

    return (
      <AccordionStyled>
        <Card bordered={this.props.bordered}>
          <CardContentWrapper noPadding={this.props.noPadding}>
            <AccordionHeaderStyled onClick={this.handleHeaderTap}>
              <Hbox>
                <Hbox.Item noGrow hAlign='flex-start'>
                  {this.state.expanded ? (
                    <FaIcon.StepperMinus color={theme.Color.GrayDark} />
                  ) : (
                    <FaIcon.StepperPlus color={theme.Color.GrayDark} />
                  )}
                </Hbox.Item>
                <Hbox.Separator />

                <Hbox.Item>
                  <H4>{this.props?.title?.toUpperCase()}</H4>
                </Hbox.Item>

                {this.props?.headerButtonText && (
                  <Hbox.Item noGrow>
                    <Button kind='link' onClick={this.props?.onHeaderButtonClick}>
                      {this.props?.headerButtonText}
                    </Button>
                  </Hbox.Item>
                )}
              </Hbox>
            </AccordionHeaderStyled>
            <AccordionCollapsibleStyled expanded={this.state.expanded} contentHeight={this.state.contentHeight}>
              <AccordionContentStyled ref={this.contentElement}>{wrapped}</AccordionContentStyled>
            </AccordionCollapsibleStyled>
          </CardContentWrapper>
        </Card>
      </AccordionStyled>
    );
  }
}
