import { useQueryHook } from '@app/core/graphql';
import { CartDetails, CartDetailsVariables } from '@app/data/graphql';

interface CartDetailQueryParams {
  orderCode: string;
  onCompleted?: () => void;
  onError?: () => void;
}

export const useCartDetailsQuery = ({ orderCode, onCompleted, onError }: CartDetailQueryParams) => {
  const result = useQueryHook<CartDetails, CartDetailsVariables>('cart-detail', {
    onCompleted,
    onError,
    skip: !orderCode,
    variables: {
      orderCode,
    },
  });

  return {
    data: result.data?.CartDetails,
    error: result.error,
    loading: result.loading,
    refetch: () => result.refetch(),
  };
};
