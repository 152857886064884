/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditQuestion
// ====================================================

export interface EditQuestion_EditQuestion {
  __typename: "AdminQuestionResponse";
  id: string;
  order: number;
  question: string;
  questionEnvironment: QuestionEnvironment;
}

export interface EditQuestion {
  /**
   * Edita uma questão
   */
  EditQuestion: EditQuestion_EditQuestion | null;
}

export interface EditQuestionVariables {
  newQuestion: string;
  order: number;
  questionEnvironment?: QuestionEnvironment | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateFranchise
// ====================================================

export interface CreateFranchise_CreateFranchise {
  __typename: "AdminFranchiseResponse";
  /**
   * Id da franquia no banco de dados
   */
  id: string;
}

export interface CreateFranchise {
  /**
   * Cria uma franquia
   */
  CreateFranchise: CreateFranchise_CreateFranchise | null;
}

export interface CreateFranchiseVariables {
  franchise: CreateFranchiseInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateNews
// ====================================================

export interface CreateNews_CreateNews {
  __typename: "NewsResponse";
  id: string;
}

export interface CreateNews {
  /**
   * Cria uma notícia
   */
  CreateNews: CreateNews_CreateNews;
}

export interface CreateNewsVariables {
  news: CreateNewsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreatePromotion
// ====================================================

export interface CreatePromotion_CreatePromotion {
  __typename: "AdminPromotionResponse";
  id: string;
}

export interface CreatePromotion {
  /**
   * Cria uma promoção
   */
  CreatePromotion: CreatePromotion_CreatePromotion;
}

export interface CreatePromotionVariables {
  promotion: CreatePromotionInput;
  referenceId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreatePublicMedia
// ====================================================

export interface CreatePublicMedia_CreatePublicMedia {
  __typename: "CreatePublicMediaResponse";
  id: number;
}

export interface CreatePublicMedia {
  CreatePublicMedia: CreatePublicMedia_CreatePublicMedia;
}

export interface CreatePublicMediaVariables {
  data: CreatePublicMediaInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateScriptStep
// ====================================================

export interface CreateScriptStep_CreateScriptStep {
  __typename: "AdminScriptStepResponse";
  id: string;
}

export interface CreateScriptStep {
  /**
   * Cria uma tópico do roteiro
   */
  CreateScriptStep: CreateScriptStep_CreateScriptStep;
}

export interface CreateScriptStepVariables {
  scriptStep: CreateScriptStepInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteNews
// ====================================================

export interface DeleteNews_DeleteNews {
  __typename: "NewsResponse";
  id: string;
}

export interface DeleteNews {
  /**
   * Exclui uma notícia
   */
  DeleteNews: DeleteNews_DeleteNews;
}

export interface DeleteNewsVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeletePromotion
// ====================================================

export interface DeletePromotion_SwitchPromotion {
  __typename: "AdminPromotionResponse";
  id: string;
}

export interface DeletePromotion {
  /**
   * Ativa ou desativa uma promoção
   */
  SwitchPromotion: DeletePromotion_SwitchPromotion;
}

export interface DeletePromotionVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeletePublicMedia
// ====================================================

export interface DeletePublicMedia_DeletePublicMedia {
  __typename: "DeletePublicMediaResponse";
  id: number;
}

export interface DeletePublicMedia {
  DeletePublicMedia: DeletePublicMedia_DeletePublicMedia;
}

export interface DeletePublicMediaVariables {
  data: DeletePublicMediaInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteScriptStep
// ====================================================

export interface DeleteScriptStep_DeleteScriptStep {
  __typename: "AdminScriptStepResponse";
  id: string;
}

export interface DeleteScriptStep {
  /**
   * Exclui um tópico do roteiro
   */
  DeleteScriptStep: DeleteScriptStep_DeleteScriptStep;
}

export interface DeleteScriptStepVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditCS
// ====================================================

export interface EditCS_EditServiceCenter {
  __typename: "AdminAnswerResponse";
  answer: string;
  id: string;
}

export interface EditCS {
  /**
   * Edita ou, caso não exista, cria uma central de serviços
   */
  EditServiceCenter: EditCS_EditServiceCenter[] | null;
}

export interface EditCSVariables {
  serviceCenter: EditServiceCenterInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditFranchise
// ====================================================

export interface EditFranchise_EditFranchise {
  __typename: "AdminFranchiseResponse";
  /**
   * Id da franquia no banco de dados
   */
  id: string;
  /**
   * Código da franquia no Boticário
   */
  referenceId: string;
  franchiseName: string;
}

export interface EditFranchise {
  /**
   * Edita uma franquia
   */
  EditFranchise: EditFranchise_EditFranchise | null;
}

export interface EditFranchiseVariables {
  franchise: EditFranchiseInput;
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditNews
// ====================================================

export interface EditNews_EditNews {
  __typename: "NewsResponse";
  id: string;
}

export interface EditNews {
  /**
   * Edita notícia
   */
  EditNews: EditNews_EditNews;
}

export interface EditNewsVariables {
  news: EditNewsInput;
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditPromotion
// ====================================================

export interface EditPromotion_EditPromotion {
  __typename: "AdminPromotionResponse";
  id: string;
}

export interface EditPromotion {
  /**
   * Edita uma promoção
   */
  EditPromotion: EditPromotion_EditPromotion;
}

export interface EditPromotionVariables {
  promotion: EditPromotionInput;
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditScriptStep
// ====================================================

export interface EditScriptStep_EditScriptStep {
  __typename: "AdminScriptStepResponse";
  id: string;
}

export interface EditScriptStep {
  /**
   * Edita um tópico do roteiro
   */
  EditScriptStep: EditScriptStep_EditScriptStep;
}

export interface EditScriptStepVariables {
  scriptStep: EditScriptStepInput;
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveCommercialTime
// ====================================================

export interface RemoveCommercialTime_RemoveCommercialTime {
  __typename: "CommercialTimeResponse";
  id: number;
  weekDay: number;
  startHour: string;
  stopHour: string;
}

export interface RemoveCommercialTime {
  /**
   * # Mutation - RemoveCommercialTime
   * ## Description:
   * 
   * 
   *  - Remove um horário comercial
   * ## Access Roles:
   * 
   * 
   *  - admin
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * ## Internal Dependencies:
   * 
   * 
   *  - ### RemoveCommercialTimeUseCase
   */
  RemoveCommercialTime: RemoveCommercialTime_RemoveCommercialTime;
}

export interface RemoveCommercialTimeVariables {
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveMessageTemplate
// ====================================================

export interface RemoveMessageTemplate_RemoveMessageTemplate_message {
  __typename: "TemplateMessage";
  /**
   * Texto da mensagem do header
   */
  header: string | null;
  /**
   * Texto da mensagem do body
   */
  body: string;
  /**
   * Texto da mensagem do footer
   */
  footer: string | null;
  /**
   * Texto da mensagens dos botões
   */
  buttons: string[] | null;
}

export interface RemoveMessageTemplate_RemoveMessageTemplate_messageVariables_header {
  __typename: "Variables";
  /**
   * Identificador/número da variável
   */
  key: string | null;
  /**
   * Índice do botão
   */
  index: number | null;
  /**
   * Valor padrão da variável
   */
  defaultValue: string | null;
  /**
   * Link da media a ser incluida na mensagem
   */
  link: string | null;
  /**
   * Identificador da media a ser incluida na mensagem
   */
  mediaId: string | null;
  /**
   * Tipo da variável
   */
  type: string | null;
  /**
   * Flag que indica se o valor padrão pode ser alterado
   */
  isEditable: boolean;
}

export interface RemoveMessageTemplate_RemoveMessageTemplate_messageVariables_body {
  __typename: "Variables";
  /**
   * Identificador/número da variável
   */
  key: string | null;
  /**
   * Índice do botão
   */
  index: number | null;
  /**
   * Valor padrão da variável
   */
  defaultValue: string | null;
  /**
   * Link da media a ser incluida na mensagem
   */
  link: string | null;
  /**
   * Identificador da media a ser incluida na mensagem
   */
  mediaId: string | null;
  /**
   * Tipo da variável
   */
  type: string | null;
  /**
   * Flag que indica se o valor padrão pode ser alterado
   */
  isEditable: boolean;
}

export interface RemoveMessageTemplate_RemoveMessageTemplate_messageVariables_buttons {
  __typename: "Variables";
  /**
   * Identificador/número da variável
   */
  key: string | null;
  /**
   * Índice do botão
   */
  index: number | null;
  /**
   * Valor padrão da variável
   */
  defaultValue: string | null;
  /**
   * Link da media a ser incluida na mensagem
   */
  link: string | null;
  /**
   * Identificador da media a ser incluida na mensagem
   */
  mediaId: string | null;
  /**
   * Tipo da variável
   */
  type: string | null;
  /**
   * Flag que indica se o valor padrão pode ser alterado
   */
  isEditable: boolean;
}

export interface RemoveMessageTemplate_RemoveMessageTemplate_messageVariables {
  __typename: "TemplateVariables";
  /**
   * Variáveis do header
   */
  header: RemoveMessageTemplate_RemoveMessageTemplate_messageVariables_header[] | null;
  /**
   * Variáveis do body
   */
  body: RemoveMessageTemplate_RemoveMessageTemplate_messageVariables_body[] | null;
  /**
   * Variáveis do botão
   */
  buttons: RemoveMessageTemplate_RemoveMessageTemplate_messageVariables_buttons[] | null;
}

export interface RemoveMessageTemplate_RemoveMessageTemplate {
  __typename: "MessageTemplateBaseResponse";
  /**
   * Id do template de mensagem
   */
  id: number | null;
  /**
   * Nome oficial do template de mensagem
   */
  name: string;
  /**
   * Nome amigável do template de mensagem que será exibido
   */
  friendlyName: string;
  /**
   * Mensagem do template
   */
  message: RemoveMessageTemplate_RemoveMessageTemplate_message;
  /**
   * Variáveis do template de mensagem
   */
  messageVariables: RemoveMessageTemplate_RemoveMessageTemplate_messageVariables | null;
  /**
   * Ambiente que o template está disponível
   */
  environment: TemplateEnvironment;
}

export interface RemoveMessageTemplate {
  RemoveMessageTemplate: RemoveMessageTemplate_RemoveMessageTemplate;
}

export interface RemoveMessageTemplateVariables {
  templateId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveServiceCenter
// ====================================================

export interface RemoveServiceCenter_RemoveServiceCenter {
  __typename: "ServiceCenterResponse";
  id: string;
  /**
   * Código da central de serviços
   */
  referenceId: string;
}

export interface RemoveServiceCenter {
  RemoveServiceCenter: RemoveServiceCenter_RemoveServiceCenter;
}

export interface RemoveServiceCenterVariables {
  referenceId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SwitchPromotion
// ====================================================

export interface SwitchPromotion_SwitchPromotion {
  __typename: "AdminPromotionResponse";
  id: string;
}

export interface SwitchPromotion {
  /**
   * Ativa ou desativa uma promoção
   */
  SwitchPromotion: SwitchPromotion_SwitchPromotion;
}

export interface SwitchPromotionVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCategoryPriority
// ====================================================

export interface UpdateCategoryPriority_UpdateCategoryPriority_feedbacks {
  __typename: "FeedbackBaseType";
  id: number | null;
  title: string;
  /**
   * Sinaliza se feedback terá campo de forma de contato
   */
  hasContactForm: boolean;
  /**
   * Sinaliza se o campo possui agendamento
   */
  hasScheduling: boolean | null;
  /**
   * Sinaliza se feedback terá campo de observações
   */
  hasNote: boolean;
  /**
   * Sinaliza se o campo de observação é obrigatório
   */
  isRequiredNote: boolean | null;
  /**
   * Sinaliza para feedbacks de Ativação se eles são visíveis para adimplentes e inadimplentes
   */
  visibility: ActivationFeedbackVisibilty | null;
  /**
   * Código usado na tabulação do Gera
   */
  geraCode: string | null;
  /**
   * Código usado na tabulação do NCall
   */
  nCallCode: string | null;
  /**
   * Prioridade de ordenação
   */
  priority: number | null;
  active: boolean | null;
  /**
   * Sinaliza se o feedback não é tabulado no gera
   */
  noTabulation: boolean | null;
}

export interface UpdateCategoryPriority_UpdateCategoryPriority {
  __typename: "UpsertFeedbackResponse";
  id: number;
  title: string;
  active: boolean;
  page: FvcPage;
  /**
   * Prioridade de ordenação
   */
  priority: number | null;
  feedbacks: UpdateCategoryPriority_UpdateCategoryPriority_feedbacks[];
}

export interface UpdateCategoryPriority {
  /**
   * Atualiza a prioridade das categorias
   */
  UpdateCategoryPriority: UpdateCategoryPriority_UpdateCategoryPriority[];
}

export interface UpdateCategoryPriorityVariables {
  categoryIds: number[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateFeedbackPriority
// ====================================================

export interface UpdateFeedbackPriority_UpdateFeedbackPriority_feedbacks {
  __typename: "FeedbackBaseType";
  id: number | null;
  title: string;
  /**
   * Sinaliza se feedback terá campo de forma de contato
   */
  hasContactForm: boolean;
  /**
   * Sinaliza se o campo possui agendamento
   */
  hasScheduling: boolean | null;
  /**
   * Sinaliza se feedback terá campo de observações
   */
  hasNote: boolean;
  /**
   * Sinaliza se o campo de observação é obrigatório
   */
  isRequiredNote: boolean | null;
  /**
   * Sinaliza para feedbacks de Ativação se eles são visíveis para adimplentes e inadimplentes
   */
  visibility: ActivationFeedbackVisibilty | null;
  /**
   * Código usado na tabulação do Gera
   */
  geraCode: string | null;
  /**
   * Código usado na tabulação do NCall
   */
  nCallCode: string | null;
  /**
   * Prioridade de ordenação
   */
  priority: number | null;
  active: boolean | null;
  /**
   * Sinaliza se o feedback não é tabulado no gera
   */
  noTabulation: boolean | null;
}

export interface UpdateFeedbackPriority_UpdateFeedbackPriority {
  __typename: "UpsertFeedbackResponse";
  id: number;
  title: string;
  active: boolean;
  page: FvcPage;
  /**
   * Prioridade de ordenação
   */
  priority: number | null;
  feedbacks: UpdateFeedbackPriority_UpdateFeedbackPriority_feedbacks[];
}

export interface UpdateFeedbackPriority {
  /**
   * Atualiza a prioridade dos feedbacks de uma categoria
   */
  UpdateFeedbackPriority: UpdateFeedbackPriority_UpdateFeedbackPriority[];
}

export interface UpdateFeedbackPriorityVariables {
  feedbackIds: number[];
  categoryId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePublicMedia
// ====================================================

export interface UpdatePublicMedia_UpdatePublicMedia {
  __typename: "UpdatePublicMediaResponse";
  id: number;
}

export interface UpdatePublicMedia {
  UpdatePublicMedia: UpdatePublicMedia_UpdatePublicMedia;
}

export interface UpdatePublicMediaVariables {
  data: UpdatePublicMediaInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertCommercialTime
// ====================================================

export interface UpsertCommercialTime_UpsertCommercialTime {
  __typename: "CommercialTimeResponse";
  id: number;
  weekDay: number;
  startHour: string;
  stopHour: string;
}

export interface UpsertCommercialTime {
  /**
   * # Mutation - UpsertCommercialTime
   * ## Description:
   * 
   * 
   *  - Adiciona/Edita um horario comercial
   * ## Access Roles:
   * 
   * 
   *  - admin
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * ## Internal Dependencies:
   * 
   * 
   *  - ### UpsertCommercialTimeUseCase
   */
  UpsertCommercialTime: UpsertCommercialTime_UpsertCommercialTime[];
}

export interface UpsertCommercialTimeVariables {
  commercialTimes: UpsertCommercialTimeInput[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertFeedback
// ====================================================

export interface UpsertFeedback_UpsertFeedback_feedbacks {
  __typename: "FeedbackBaseType";
  id: number | null;
  title: string;
  /**
   * Sinaliza se feedback terá campo de forma de contato
   */
  hasContactForm: boolean;
  /**
   * Sinaliza se o campo possui agendamento
   */
  hasScheduling: boolean | null;
  /**
   * Sinaliza se feedback terá campo de observações
   */
  hasNote: boolean;
  /**
   * Sinaliza se o campo de observação é obrigatório
   */
  isRequiredNote: boolean | null;
  /**
   * Sinaliza para feedbacks de Ativação se eles são visíveis para adimplentes e inadimplentes
   */
  visibility: ActivationFeedbackVisibilty | null;
  /**
   * Código usado na tabulação do Gera
   */
  geraCode: string | null;
  /**
   * Código usado na tabulação do NCall
   */
  nCallCode: string | null;
  /**
   * Prioridade de ordenação
   */
  priority: number | null;
  active: boolean | null;
  /**
   * Sinaliza se o feedback não é tabulado no gera
   */
  noTabulation: boolean | null;
}

export interface UpsertFeedback_UpsertFeedback {
  __typename: "UpsertFeedbackResponse";
  id: number;
  title: string;
  active: boolean;
  page: FvcPage;
  /**
   * Prioridade de ordenação
   */
  priority: number | null;
  feedbacks: UpsertFeedback_UpsertFeedback_feedbacks[];
}

export interface UpsertFeedback {
  /**
   * Cria feedback
   */
  UpsertFeedback: UpsertFeedback_UpsertFeedback[];
}

export interface UpsertFeedbackVariables {
  categories: UpsertFeedbackInput[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertMessageTemplates
// ====================================================

export interface UpsertMessageTemplates_UpsertMessageTemplates_message {
  __typename: "TemplateMessage";
  /**
   * Texto da mensagem do header
   */
  header: string | null;
  /**
   * Texto da mensagem do body
   */
  body: string;
  /**
   * Texto da mensagem do footer
   */
  footer: string | null;
  /**
   * Texto da mensagens dos botões
   */
  buttons: string[] | null;
}

export interface UpsertMessageTemplates_UpsertMessageTemplates_messageVariables_header {
  __typename: "Variables";
  /**
   * Identificador/número da variável
   */
  key: string | null;
  /**
   * Índice do botão
   */
  index: number | null;
  /**
   * Valor padrão da variável
   */
  defaultValue: string | null;
  /**
   * Link da media a ser incluida na mensagem
   */
  link: string | null;
  /**
   * Identificador da media a ser incluida na mensagem
   */
  mediaId: string | null;
  /**
   * Tipo da variável
   */
  type: string | null;
  /**
   * Flag que indica se o valor padrão pode ser alterado
   */
  isEditable: boolean;
}

export interface UpsertMessageTemplates_UpsertMessageTemplates_messageVariables_body {
  __typename: "Variables";
  /**
   * Identificador/número da variável
   */
  key: string | null;
  /**
   * Índice do botão
   */
  index: number | null;
  /**
   * Valor padrão da variável
   */
  defaultValue: string | null;
  /**
   * Link da media a ser incluida na mensagem
   */
  link: string | null;
  /**
   * Identificador da media a ser incluida na mensagem
   */
  mediaId: string | null;
  /**
   * Tipo da variável
   */
  type: string | null;
  /**
   * Flag que indica se o valor padrão pode ser alterado
   */
  isEditable: boolean;
}

export interface UpsertMessageTemplates_UpsertMessageTemplates_messageVariables_buttons {
  __typename: "Variables";
  /**
   * Identificador/número da variável
   */
  key: string | null;
  /**
   * Índice do botão
   */
  index: number | null;
  /**
   * Valor padrão da variável
   */
  defaultValue: string | null;
  /**
   * Link da media a ser incluida na mensagem
   */
  link: string | null;
  /**
   * Identificador da media a ser incluida na mensagem
   */
  mediaId: string | null;
  /**
   * Tipo da variável
   */
  type: string | null;
  /**
   * Flag que indica se o valor padrão pode ser alterado
   */
  isEditable: boolean;
}

export interface UpsertMessageTemplates_UpsertMessageTemplates_messageVariables {
  __typename: "TemplateVariables";
  /**
   * Variáveis do header
   */
  header: UpsertMessageTemplates_UpsertMessageTemplates_messageVariables_header[] | null;
  /**
   * Variáveis do body
   */
  body: UpsertMessageTemplates_UpsertMessageTemplates_messageVariables_body[] | null;
  /**
   * Variáveis do botão
   */
  buttons: UpsertMessageTemplates_UpsertMessageTemplates_messageVariables_buttons[] | null;
}

export interface UpsertMessageTemplates_UpsertMessageTemplates {
  __typename: "MessageTemplateBaseResponse";
  /**
   * Id do template de mensagem
   */
  id: number | null;
  /**
   * Nome oficial do template de mensagem
   */
  name: string;
  /**
   * Nome amigável do template de mensagem que será exibido
   */
  friendlyName: string;
  /**
   * Mensagem do template
   */
  message: UpsertMessageTemplates_UpsertMessageTemplates_message;
  /**
   * Variáveis do template de mensagem
   */
  messageVariables: UpsertMessageTemplates_UpsertMessageTemplates_messageVariables | null;
  /**
   * Ambiente que o template está disponível
   */
  environment: TemplateEnvironment;
}

export interface UpsertMessageTemplates {
  UpsertMessageTemplates: UpsertMessageTemplates_UpsertMessageTemplates[];
}

export interface UpsertMessageTemplatesVariables {
  templates: UpsertMessageTemplateInput[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertQueueOption
// ====================================================

export interface UpsertQueueOption_UpsertQueueOption_state {
  __typename: "QueueOptionStateFieldResponse";
  /**
   * Tipo do campo
   */
  type: FvcQueueOptionStateFieldType;
  /**
   * Posição relativa escolhida pelo usuário
   */
  position: number;
  /**
   * Nível do status (se o tipo for um Status)
   */
  statusLevel: ResellerActivationCategoryType | null;
  /**
   * Identificador da categoria (se o tipo for uma categoria)
   */
  categoryId: number | null;
  /**
   * Nível do status Meu Clube Boti (se o tipo for Meu Clube Boti)
   */
  meuClubeBotiLevel: ResellerMeuClubeBotiEnum | null;
}

export interface UpsertQueueOption_UpsertQueueOption {
  __typename: "QueueOptionResponse";
  id: number;
  /**
   * Estado atual das configurações da opção sobre a régua
   */
  state: UpsertQueueOption_UpsertQueueOption_state[];
  /**
   * Lista de estuturas Gera ao qual a opção está aplicada
   */
  geraStructures: string[];
  /**
   * Indica se a opção está ativada ou não pelo usuário
   */
  isEnabled: boolean;
  /**
   * Nome da opção
   */
  name: string;
  /**
   * Nome livre da opção
   */
  description: string | null;
}

export interface UpsertQueueOption {
  /**
   * Cria ou atualiza uma opção
   */
  UpsertQueueOption: UpsertQueueOption_UpsertQueueOption;
}

export interface UpsertQueueOptionVariables {
  data: QueueOptionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCommercialTimes
// ====================================================

export interface GetCommercialTimes_GetCommercialTimes {
  __typename: "CommercialTimeResponse";
  id: number;
  weekDay: number;
  startHour: string;
  stopHour: string;
}

export interface GetCommercialTimes {
  /**
   * # Query - GetCommercialTimes
   * ## Description:
   * 
   * 
   *  - Lista com os horários comerciais
   * ## Access Roles:
   * 
   * 
   *  - admin
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * ## Internal Dependencies:
   * 
   * 
   *  - ### GetCommercialTimesUseCase
   */
  GetCommercialTimes: GetCommercialTimes_GetCommercialTimes[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetServiceCenterAdmin
// ====================================================

export interface GetServiceCenterAdmin_GetServiceCenterAdmin_categories_questions_answers {
  __typename: "AdminAnswerResponse";
  answer: string;
  id: string;
}

export interface GetServiceCenterAdmin_GetServiceCenterAdmin_categories_questions {
  __typename: "AdminQuestionAndAnswersResponse";
  id: string;
  order: number;
  /**
   * Pergunta
   */
  question: string | null;
  /**
   * Resposta
   */
  answers: GetServiceCenterAdmin_GetServiceCenterAdmin_categories_questions_answers[] | null;
}

export interface GetServiceCenterAdmin_GetServiceCenterAdmin_categories {
  __typename: "AdminCategoriesResponse";
  id: string;
  /**
   * Nome da categoria
   */
  categoryName: string | null;
  /**
   * Perguntas
   */
  questions: GetServiceCenterAdmin_GetServiceCenterAdmin_categories_questions[] | null;
}

export interface GetServiceCenterAdmin_GetServiceCenterAdmin {
  __typename: "AdminServiceCenterResponse";
  /**
   * Código da central de serviços
   */
  referenceId: string;
  /**
   * Seções das perguntas
   */
  categories: GetServiceCenterAdmin_GetServiceCenterAdmin_categories[];
}

export interface GetServiceCenterAdmin {
  /**
   * Obtém os dados de uma CS
   */
  GetServiceCenterAdmin: GetServiceCenterAdmin_GetServiceCenterAdmin | null;
}

export interface GetServiceCenterAdminVariables {
  referenceId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ListCategories
// ====================================================

export interface ListCategories_ListCategories_questions {
  __typename: "AdminQuestionResponse";
  question: string;
  order: number;
  questionEnvironment: QuestionEnvironment;
}

export interface ListCategories_ListCategories {
  __typename: "AdminCategoryResponse";
  categoryName: string;
  id: string;
  /**
   * Lista das perguntas referente a categoria
   */
  questions: ListCategories_ListCategories_questions[];
}

export interface ListCategories {
  /**
   * Lista todas as categorias e questões
   */
  ListCategories: ListCategories_ListCategories[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ListServiceCenters
// ====================================================

export interface ListServiceCenters_ListServiceCenters {
  __typename: "ServiceCenterResponse";
  /**
   * Código da central de serviços
   */
  referenceId: string;
  id: string;
}

export interface ListServiceCenters {
  /**
   * Lista as centrais de serviços (CSs) de uma franquia
   */
  ListServiceCenters: ListServiceCenters_ListServiceCenters[] | null;
}

export interface ListServiceCentersVariables {
  franchiseId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ListCategoriesAndFeedbacksAdmin
// ====================================================

export interface ListCategoriesAndFeedbacksAdmin_ListCategoriesAndFeedbacks_feedbacks {
  __typename: "FeedbackResponse";
  id: number | null;
  title: string;
  /**
   * Sinaliza se feedback terá campo de forma de contato
   */
  hasContactForm: boolean;
  /**
   * Sinaliza se o campo possui agendamento
   */
  hasScheduling: boolean | null;
  /**
   * Sinaliza se feedback terá campo de observações
   */
  hasNote: boolean;
  /**
   * Sinaliza se o campo de observação é obrigatório
   */
  isRequiredNote: boolean | null;
  /**
   * Sinaliza para feedbacks de Ativação se eles são visíveis para adimplentes e inadimplentes
   */
  visibility: ActivationFeedbackVisibilty | null;
  /**
   * Código usado na tabulação do Gera
   */
  geraCode: string | null;
  /**
   * Código usado na tabulação do NCall
   */
  nCallCode: string | null;
  /**
   * Prioridade de ordenação
   */
  priority: number | null;
  active: boolean | null;
  /**
   * Detalhe do subnível de tabulação
   */
  details: string[] | null;
  /**
   * Sinaliza se o feedback não é tabulado no gera
   */
  noTabulation: boolean | null;
}

export interface ListCategoriesAndFeedbacksAdmin_ListCategoriesAndFeedbacks {
  __typename: "FeedbackCategoryItemsResponse";
  id: number;
  title: string;
  active: boolean;
  page: FvcPage;
  priority: number | null;
  feedbacks: ListCategoriesAndFeedbacksAdmin_ListCategoriesAndFeedbacks_feedbacks[];
}

export interface ListCategoriesAndFeedbacksAdmin {
  /**
   * # Query - ListCategoriesAndFeedbacks
   * ## Description:
   * 
   * 
   *  - Traz as categorias e feedbacks disponíveis conforme a página e o status da revendedora, lista todos independente caso seja admin.
   * ## Access Roles:
   * 
   * 
   *  - admin
   *  - supervisor
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## Internal Dependencies:
   * 
   * 
   *  - ### ListCategoriesAndFeedbacksUseCase
   *     
   * 
   * 
   *  - ### FeedbackCategoryDataSource
   */
  ListCategoriesAndFeedbacks: ListCategoriesAndFeedbacksAdmin_ListCategoriesAndFeedbacks[];
}

export interface ListCategoriesAndFeedbacksAdminVariables {
  page: FvcPage;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ListFranchises
// ====================================================

export interface ListFranchises_ListFranchises {
  __typename: "AdminFranchiseResponse";
  /**
   * Id da franquia no banco de dados
   */
  id: string;
  /**
   * Código da franquia no Boticário
   */
  referenceId: string;
  franchiseName: string;
  /**
   * Indica se o cadastro da franquia está ativo ou não
   */
  active: boolean;
}

export interface ListFranchises {
  /**
   * # Query - ListFranchises
   * ## Description:
   * 
   * 
   *  - Lista todas as franquias
   * ## Access Roles:
   * 
   * 
   *  - admin
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## Internal Dependencies:
   * 
   * 
   *  - ### ListFranchisesUseCase
   *     
   * 
   * 
   *  - ### FranchiseDataSource
   */
  ListFranchises: ListFranchises_ListFranchises[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ListNews
// ====================================================

export interface ListNews_ListNews_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface ListNews_ListNews_news {
  __typename: "NewsResponse";
  id: string;
  title: string;
  message: string;
  /**
   * Nível de criticidade
   */
  criticalityLevel: NewsCriticalityLevel;
  /**
   * Projeto pertencente
   */
  environments: NewsEnvironment[];
  /**
   * Indica se a notícia é pública, isto é, pode ser acessada sem fazer login
   */
  isPublic: boolean;
  /**
   * Indica se a notícia está ativada e por isso pode ser visualizada pelos usuários
   */
  isEnable: boolean;
  /**
   * Lista de URLs vinculadas a notícia
   */
  references: string[];
}

export interface ListNews_ListNews {
  __typename: "NewsListResponse";
  pageInfo: ListNews_ListNews_pageInfo;
  news: ListNews_ListNews_news[];
  count: number;
}

export interface ListNews {
  /**
   * Obtém lista de notícias
   */
  ListNews: ListNews_ListNews | null;
}

export interface ListNewsVariables {
  limit: number;
  offset: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ListPromotions
// ====================================================

export interface ListPromotions_ListPromotions_pageInfo {
  __typename: "AdminPageInfoResponse";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface ListPromotions_ListPromotions_promotions {
  __typename: "AdminPromotionResponse";
  id: string;
  title: string;
  description: string;
  startDate: any;
  endDate: any;
  active: boolean;
}

export interface ListPromotions_ListPromotions {
  __typename: "PromotionListResponse";
  pageInfo: ListPromotions_ListPromotions_pageInfo;
  promotions: ListPromotions_ListPromotions_promotions[];
  count: number;
}

export interface ListPromotions {
  /**
   * Lista promoções
   */
  ListPromotions: ListPromotions_ListPromotions;
}

export interface ListPromotionsVariables {
  limit: number;
  offset: number;
  referenceId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdminListPublicMedia
// ====================================================

export interface AdminListPublicMedia_ListPublicMedia_medias {
  __typename: "ShowPublicMediaResponse";
  id: number;
  /**
   * Nome da mídia
   */
  name: string;
  /**
   * Descrição da mídia
   */
  description: string | null;
  /**
   * ID da mídia
   */
  mediaId: string;
  /**
   * Tipo de arquivo
   */
  type: string;
}

export interface AdminListPublicMedia_ListPublicMedia {
  __typename: "ListPublicMediaResponse";
  /**
   * Items obtidos
   */
  medias: AdminListPublicMedia_ListPublicMedia_medias[] | null;
  /**
   * Total de items encontrados
   */
  count: number | null;
}

export interface AdminListPublicMedia {
  /**
   * # Query - ListPublicMedia
   * ## Description:
   * 
   * 
   *  - Lista as mídias públicas
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## Internal Dependencies:
   * 
   * 
   *  - ### ListPublicMediaUseCase
   *     
   * 
   * 
   *  - ### InstantMessagePublicMediaDbDataSource
   */
  ListPublicMedia: AdminListPublicMedia_ListPublicMedia;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ListQueueOptionStructures
// ====================================================

export interface ListQueueOptionStructures {
  /**
   * Lista todas as estruturas Gera que podem ser aplicadas à uma opção
   */
  ListQueueOptionStructures: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ListQueueOptions
// ====================================================

export interface ListQueueOptions_ListQueueOptions_state {
  __typename: "QueueOptionStateFieldResponse";
  /**
   * Tipo do campo
   */
  type: FvcQueueOptionStateFieldType;
  /**
   * Posição relativa escolhida pelo usuário
   */
  position: number;
  /**
   * Nível do status (se o tipo for um Status)
   */
  statusLevel: ResellerActivationCategoryType | null;
  /**
   * Identificador da categoria (se o tipo for uma categoria)
   */
  categoryId: number | null;
  /**
   * Nível do status Meu Clube Boti (se o tipo for Meu Clube Boti)
   */
  meuClubeBotiLevel: ResellerMeuClubeBotiEnum | null;
}

export interface ListQueueOptions_ListQueueOptions {
  __typename: "QueueOptionResponse";
  id: number;
  /**
   * Estado atual das configurações da opção sobre a régua
   */
  state: ListQueueOptions_ListQueueOptions_state[];
  /**
   * Lista de estuturas Gera ao qual a opção está aplicada
   */
  geraStructures: string[];
  /**
   * Indica se a opção está ativada ou não pelo usuário
   */
  isEnabled: boolean;
  /**
   * Nome da opção
   */
  name: string;
  /**
   * Nome livre da opção
   */
  description: string | null;
}

export interface ListQueueOptions {
  /**
   * Lista todas as opções de manipulação da régua/fila disponíveis
   */
  ListQueueOptions: ListQueueOptions_ListQueueOptions[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetScript
// ====================================================

export interface GetScript_GetScript {
  __typename: "AdminScriptStepResponse";
  id: string;
  step: string;
  instruction: string;
}

export interface GetScript {
  /**
   * Obtém script completo
   */
  GetScript: GetScript_GetScript[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ShowPublicMedia
// ====================================================

export interface ShowPublicMedia_ShowPublicMedia {
  __typename: "ShowPublicMediaResponse";
  id: number;
  /**
   * Nome da mídia
   */
  name: string;
  /**
   * Descrição da mídia
   */
  description: string | null;
  /**
   * ID da mídia
   */
  mediaId: string;
  /**
   * Tipo de arquivo
   */
  type: string;
}

export interface ShowPublicMedia {
  /**
   * # Query - ShowPublicMedia
   * ## Description:
   * 
   * 
   *  - Traz as mídias públicas
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## Internal Dependencies:
   * 
   * 
   *  - ### ShowPublicMediaUseCase
   *     
   * 
   * 
   *  - ### InstantMessagePublicMediaDbDataSource
   */
  ShowPublicMedia: ShowPublicMedia_ShowPublicMedia;
}

export interface ShowPublicMediaVariables {
  data: ShowPublicMediaInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddBatchToCart
// ====================================================

export interface AddBatchToCart_AddBatchToCart_cart_externalPaymentLink {
  __typename: "NewPaymentLinkResponse";
  /**
   * Url de pagamento
   */
  externalPaymentLink: string | null;
  /**
   * Data de expiração da url de pagamento
   */
  expirationDatePaymentLink: any | null;
}

export interface AddBatchToCart_AddBatchToCart_cart_credit {
  __typename: "ResellerCreditResponse";
  /**
   * Crédito disponível para compra no momento
   */
  available: number | null;
}

export interface AddBatchToCart_AddBatchToCart_cart_products_origin {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface AddBatchToCart_AddBatchToCart_cart_products {
  __typename: "CartProductType";
  /**
   * Número representativo do produto no carrinho Gera
   */
  sequentialNumber: string;
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * URL da imagem do produto
   */
  imageUrl: string;
  /**
   * Quantidade
   */
  quantity: number;
  /**
   * Preço de mercado (consumidor)
   */
  unitMarketValue: number;
  /**
   * Preço de tabela (consumidor)
   */
  unitTableValue: number;
  /**
   * Preço promocionado (consumidor)
   */
  unitNetValue: number | null;
  /**
   * Origem do produto no pedido
   */
  origin: AddBatchToCart_AddBatchToCart_cart_products_origin;
  /**
   * Bloqueado para edição?
   */
  blockEdit: boolean;
  /**
   * Bloqueado para remoção?
   */
  blockDelete: boolean;
  /**
   * Valor unitário de compra
   */
  unitPayValue: number;
  /**
   * Valor de compra
   */
  payValue: number;
  /**
   * Lucro
   */
  profit: number;
  /**
   * Lucro com descontos
   */
  profitWithDiscount: number;
  /**
   * Se o produto é um combo personalizável
   */
  isCombo: boolean;
}

export interface AddBatchToCart_AddBatchToCart_cart_cutProducts_type {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface AddBatchToCart_AddBatchToCart_cart_cutProducts_cutReason {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface AddBatchToCart_AddBatchToCart_cart_cutProducts {
  __typename: "CartCutProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * URL da imagem do produto
   */
  imageUrl: string;
  /**
   * Quantidade
   */
  quantity: number;
  /**
   * Preço de mercado (consumidor)
   */
  unitMarketValue: number;
  /**
   * Preço de tabela (consumidor)
   */
  unitTableValue: number;
  /**
   * Preço promocionado (consumidor)
   */
  unitNetValue: number | null;
  /**
   * Tipo do item
   */
  type: AddBatchToCart_AddBatchToCart_cart_cutProducts_type;
  /**
   * Razão do corte
   */
  cutReason: AddBatchToCart_AddBatchToCart_cart_cutProducts_cutReason;
}

export interface AddBatchToCart_AddBatchToCart_cart_acquiredProducts_promotion {
  __typename: "CartPromotionType";
  /**
   * Código
   */
  code: string;
  /**
   * Título
   */
  title: string;
}

export interface AddBatchToCart_AddBatchToCart_cart_acquiredProducts {
  __typename: "AcquiredProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * URL da imagem
   */
  imageUrl: string;
  /**
   * Quantidade
   */
  quantity: number | null;
  /**
   * Promoção que originou o brinde
   */
  promotion: AddBatchToCart_AddBatchToCart_cart_acquiredProducts_promotion | null;
}

export interface AddBatchToCart_AddBatchToCart_cart_acquiredPromotions_destination {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface AddBatchToCart_AddBatchToCart_cart_acquiredPromotions_rewards {
  __typename: "RewardProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Quantidade
   */
  quantity: number | null;
  /**
   * URL da imagem
   */
  imageUrl: string;
  /**
   * Desconto
   */
  discount: number;
  /**
   * Tipo
   */
  type: RewardTypeEnum;
  /**
   * Descrição
   */
  description: string | null;
  /**
   * Indica se é um brinde a ser escolhido
   */
  isGiftToChoose: boolean;
}

export interface AddBatchToCart_AddBatchToCart_cart_acquiredPromotions {
  __typename: "AcquiredPromotionType";
  /**
   * Código da promoção
   */
  code: string;
  /**
   * Nome
   */
  title: string;
  /**
   * Descrição
   */
  description: string | null;
  /**
   * Destino
   */
  destination: AddBatchToCart_AddBatchToCart_cart_acquiredPromotions_destination;
  /**
   * Indica se é promoção de cupom de desconto
   */
  isCupomPromotion: boolean;
  /**
   * Lista de brindes/descontos
   */
  rewards: AddBatchToCart_AddBatchToCart_cart_acquiredPromotions_rewards[];
}

export interface AddBatchToCart_AddBatchToCart_cart_partialPromotions_destination {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface AddBatchToCart_AddBatchToCart_cart_partialPromotions_requirements_type {
  __typename: "RequirementType";
  /**
   * Id do tipo de requisito
   */
  id: number | null;
  /**
   * Descrição do requisito
   */
  description: string | null;
}

export interface AddBatchToCart_AddBatchToCart_cart_partialPromotions_requirements_productRequired {
  __typename: "RequiredProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome do produto
   */
  name: string;
  /**
   * Descrição do produto
   */
  description: string | null;
  /**
   * Indica se é um kit
   */
  isKit: boolean | null;
  /**
   * Quantidade de pontos
   */
  points: number | null;
  /**
   * Preço
   */
  price: number | null;
}

export interface AddBatchToCart_AddBatchToCart_cart_partialPromotions_requirements_structureRequired {
  __typename: "RequiredStructureType";
  /**
   * Código da estrutura
   */
  code: string;
  /**
   * Nome da estrutura
   */
  name: string;
}

export interface AddBatchToCart_AddBatchToCart_cart_partialPromotions_requirements {
  __typename: "PromotionRequirementType";
  /**
   * Valor faltante para obter a promoção
   */
  missingValue: number;
  /**
   * Tipo do requisito
   */
  type: AddBatchToCart_AddBatchToCart_cart_partialPromotions_requirements_type;
  /**
   * Produto requisitado
   */
  productRequired: AddBatchToCart_AddBatchToCart_cart_partialPromotions_requirements_productRequired | null;
  /**
   * Estrutura na qual a compra deve ser feita
   */
  structureRequired: AddBatchToCart_AddBatchToCart_cart_partialPromotions_requirements_structureRequired | null;
  /**
   * Descrição
   */
  description: string | null;
}

export interface AddBatchToCart_AddBatchToCart_cart_partialPromotions {
  __typename: "PartialPromotionType";
  /**
   * Código da promoção
   */
  code: string;
  /**
   * Nome
   */
  title: string;
  /**
   * Descrição
   */
  description: string | null;
  /**
   * Destino
   */
  destination: AddBatchToCart_AddBatchToCart_cart_partialPromotions_destination;
  /**
   * Indica se é promoção de cupom de desconto
   */
  isCupomPromotion: boolean;
  /**
   * Lista de requisitos para conquistar a promoção
   */
  requirements: AddBatchToCart_AddBatchToCart_cart_partialPromotions_requirements[];
}

export interface AddBatchToCart_AddBatchToCart_cart_rewardsToChoose_destination {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface AddBatchToCart_AddBatchToCart_cart_rewardsToChoose_productsToChoose {
  __typename: "RewardProductToChooseType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Url da imagem
   */
  imageUrl: string;
  /**
   * Quantidade escolhida
   */
  chosenQuantity: number | null;
  /**
   * Quantidade máxima a ser selecionada
   */
  maxQuantity: number;
  /**
   * Indica se está indisponível (só atualiza depois de escolhido)
   */
  unavailable: boolean;
  /**
   * ?
   */
  isReorderAllowed: boolean;
}

export interface AddBatchToCart_AddBatchToCart_cart_rewardsToChoose_discountsToChoose {
  __typename: "RewardDiscountToChooseType";
  /**
   * Código da opção de desconto
   */
  code: string;
  /**
   * Valor do desconto (unidade é de acordo com o tipo do desconto)
   */
  value: number;
  /**
   * Descrição
   */
  auxiliaryDescription: string | null;
  /**
   * Tipo da conquista
   */
  rewardType: RewardTypeEnum;
}

export interface AddBatchToCart_AddBatchToCart_cart_rewardsToChoose {
  __typename: "RewardToChooseType";
  /**
   * Código da promoção (pode repetir)
   */
  code: string;
  /**
   * Título da promoção
   */
  title: string;
  /**
   * Indica se escolheu produtos
   */
  hasChosenProducts: boolean;
  /**
   * Indica se escolheu a opção de brindes
   */
  hasChosenDiscount: boolean;
  /**
   * Número sequencial (diferenciador das opções)
   */
  giftNumber: string;
  /**
   * Número sequencial (diferenciador dos itens)
   */
  giftItemNumber: string;
  /**
   * Número máximo de brindes a serem escolhidos
   */
  maxGiftsToChoose: number;
  /**
   * Descrição
   */
  description: string | null;
  /**
   * Destino
   */
  destination: AddBatchToCart_AddBatchToCart_cart_rewardsToChoose_destination;
  /**
   * Indica se é promoção de cupom
   */
  isCupomPromotion: boolean;
  /**
   * Produto (brinde) a ser escolhido na promoção
   */
  productsToChoose: AddBatchToCart_AddBatchToCart_cart_rewardsToChoose_productsToChoose[] | null;
  /**
   * Detalhes de uma opção de desconto em uma promoção
   */
  discountsToChoose: AddBatchToCart_AddBatchToCart_cart_rewardsToChoose_discountsToChoose[] | null;
}

export interface AddBatchToCart_AddBatchToCart_cart_chosenAddress_type {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface AddBatchToCart_AddBatchToCart_cart_chosenAddress_address {
  __typename: "RegistrationAddressType";
  /**
   * Logradouro
   */
  streetName: string | null;
  /**
   * Número
   */
  addressNumber: string | null;
  /**
   * CEP
   */
  cep: string | null;
  /**
   * Bairro
   */
  neighborhood: string | null;
  /**
   * Complemento
   */
  complement: string | null;
  /**
   * Cidade
   */
  city: string | null;
  /**
   * Estado
   */
  state: string | null;
  /**
   * País
   */
  country: string | null;
}

export interface AddBatchToCart_AddBatchToCart_cart_chosenAddress {
  __typename: "CartDeliveryAddressType";
  /**
   * Tipo do endereço
   */
  type: AddBatchToCart_AddBatchToCart_cart_chosenAddress_type;
  /**
   * Endereço em formato de objeto
   */
  address: AddBatchToCart_AddBatchToCart_cart_chosenAddress_address;
  /**
   * Endereço formatado
   */
  formattedAddress: string;
  /**
   * CEP
   */
  cep: string;
}

export interface AddBatchToCart_AddBatchToCart_cart_chosenFreightOption_type {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface AddBatchToCart_AddBatchToCart_cart_chosenFreightOption {
  __typename: "FreightOptionResponse";
  /**
   * Código da opção de frete
   */
  code: string;
  /**
   * Nome da opção de frete
   */
  name: string;
  /**
   * Tipo
   */
  type: AddBatchToCart_AddBatchToCart_cart_chosenFreightOption_type;
  /**
   * Valor da entrega
   */
  price: number;
  /**
   * Dias úteis para a entrega
   */
  deliveryUtilDays: number;
}

export interface AddBatchToCart_AddBatchToCart_cart_chosenPaymentPlan_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface AddBatchToCart_AddBatchToCart_cart_chosenPaymentPlan_creditCardOptions_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface AddBatchToCart_AddBatchToCart_cart_chosenPaymentPlan_creditCardOptions {
  __typename: "CreditCardOptionType";
  /**
   * Descrição da bandeira do cartão de crédito
   */
  type: string;
  /**
   * Identificador da bandeira do cartão de crédito
   */
  flag: string;
  /**
   * Modo de pagamento para o cartão especificado
   */
  paymentMode: AddBatchToCart_AddBatchToCart_cart_chosenPaymentPlan_creditCardOptions_paymentMode;
  /**
   * Parcelas possíveis de serem escolhidas
   */
  possibleNumberOfInstallments: number[] | null;
}

export interface AddBatchToCart_AddBatchToCart_cart_chosenPaymentPlan_selectedInstallments {
  __typename: "InstallmentType";
  /**
   * Número da parcela
   */
  number: number;
  /**
   * Valor da parcela
   */
  value: number;
  /**
   * Data de vencimento
   */
  dueDate: any;
  /**
   * Indica se está bloqueada
   */
  isBlocked: boolean;
}

export interface AddBatchToCart_AddBatchToCart_cart_chosenPaymentPlan {
  __typename: "PaymentPlanResponse";
  /**
   * Código do plano de pagamento
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Indica se consumirá crédito da revendedora
   */
  willConsumeCredit: boolean;
  /**
   * Indica se ela deve ter saldo de crédito para poder comprar
   */
  shouldVerifyCredit: boolean;
  /**
   * Indica se está bloqueado
   */
  isBlocked: boolean;
  /**
   * Taxa de administração
   */
  tax: number;
  /**
   * Número de parcelas (para planos sem cartão crédito)
   */
  installments: number | null;
  /**
   * Identificador do modo de pagamento
   */
  paymentMode: AddBatchToCart_AddBatchToCart_cart_chosenPaymentPlan_paymentMode | null;
  /**
   * Opções quando pagamento é por cartão de crédito
   */
  creditCardOptions: AddBatchToCart_AddBatchToCart_cart_chosenPaymentPlan_creditCardOptions[] | null;
  /**
   * Parcelas do plano selecionado
   */
  selectedInstallments: AddBatchToCart_AddBatchToCart_cart_chosenPaymentPlan_selectedInstallments[] | null;
  /**
   * Indica se é múltiplas formas de pagamento
   */
  isMultiplePayment: boolean;
}

export interface AddBatchToCart_AddBatchToCart_cart_totals {
  __typename: "CartTotalsType";
  /**
   * Total de venda
   */
  totalSell: number | null;
  /**
   * Lucro
   */
  profit: number | null;
  /**
   * Frete
   */
  deliveryTax: number | null;
  /**
   * Subtotal
   */
  subtotalPay: number | null;
  /**
   * Total
   */
  totalPay: number | null;
  /**
   * Lucro vindo dos brindes
   */
  profitFromGifts: number | null;
  /**
   * Valor de crédito utilizado da CCR (Conta Corrente Residual)
   */
  checkingAccount: number | null;
  /**
   * Taxa administrativa
   */
  collectionFee: number | null;
  /**
   * Impostos
   */
  taxes: number | null;
  /**
   * Comissão
   */
  commission: number | null;
}

export interface AddBatchToCart_AddBatchToCart_cart_deliveryAddresses_type {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface AddBatchToCart_AddBatchToCart_cart_deliveryAddresses_address {
  __typename: "RegistrationAddressType";
  /**
   * Logradouro
   */
  streetName: string | null;
  /**
   * Número
   */
  addressNumber: string | null;
  /**
   * CEP
   */
  cep: string | null;
  /**
   * Bairro
   */
  neighborhood: string | null;
  /**
   * Complemento
   */
  complement: string | null;
  /**
   * Cidade
   */
  city: string | null;
  /**
   * Estado
   */
  state: string | null;
  /**
   * País
   */
  country: string | null;
}

export interface AddBatchToCart_AddBatchToCart_cart_deliveryAddresses {
  __typename: "CartDeliveryAddressType";
  /**
   * Tipo do endereço
   */
  type: AddBatchToCart_AddBatchToCart_cart_deliveryAddresses_type;
  /**
   * Endereço em formato de objeto
   */
  address: AddBatchToCart_AddBatchToCart_cart_deliveryAddresses_address;
  /**
   * Endereço formatado
   */
  formattedAddress: string;
  /**
   * CEP
   */
  cep: string;
}

export interface AddBatchToCart_AddBatchToCart_cart_paymentPlans_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface AddBatchToCart_AddBatchToCart_cart_paymentPlans_creditCardOptions_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface AddBatchToCart_AddBatchToCart_cart_paymentPlans_creditCardOptions {
  __typename: "CreditCardOptionType";
  /**
   * Descrição da bandeira do cartão de crédito
   */
  type: string;
  /**
   * Identificador da bandeira do cartão de crédito
   */
  flag: string;
  /**
   * Modo de pagamento para o cartão especificado
   */
  paymentMode: AddBatchToCart_AddBatchToCart_cart_paymentPlans_creditCardOptions_paymentMode;
  /**
   * Parcelas possíveis de serem escolhidas
   */
  possibleNumberOfInstallments: number[] | null;
}

export interface AddBatchToCart_AddBatchToCart_cart_paymentPlans_selectedInstallments {
  __typename: "InstallmentType";
  /**
   * Número da parcela
   */
  number: number;
  /**
   * Valor da parcela
   */
  value: number;
  /**
   * Data de vencimento
   */
  dueDate: any;
  /**
   * Indica se está bloqueada
   */
  isBlocked: boolean;
}

export interface AddBatchToCart_AddBatchToCart_cart_paymentPlans {
  __typename: "PaymentPlanResponse";
  /**
   * Código do plano de pagamento
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Indica se consumirá crédito da revendedora
   */
  willConsumeCredit: boolean;
  /**
   * Indica se ela deve ter saldo de crédito para poder comprar
   */
  shouldVerifyCredit: boolean;
  /**
   * Indica se está bloqueado
   */
  isBlocked: boolean;
  /**
   * Taxa de administração
   */
  tax: number;
  /**
   * Número de parcelas (para planos sem cartão crédito)
   */
  installments: number | null;
  /**
   * Identificador do modo de pagamento
   */
  paymentMode: AddBatchToCart_AddBatchToCart_cart_paymentPlans_paymentMode | null;
  /**
   * Opções quando pagamento é por cartão de crédito
   */
  creditCardOptions: AddBatchToCart_AddBatchToCart_cart_paymentPlans_creditCardOptions[] | null;
  /**
   * Parcelas do plano selecionado
   */
  selectedInstallments: AddBatchToCart_AddBatchToCart_cart_paymentPlans_selectedInstallments[] | null;
  /**
   * Indica se é múltiplas formas de pagamento
   */
  isMultiplePayment: boolean;
}

export interface AddBatchToCart_AddBatchToCart_cart {
  __typename: "CartResponse";
  /**
   * Código do pedido
   */
  orderCode: string;
  /**
   * Código do espaço revendedor
   */
  distributionCenterCode: string;
  /**
   * Modo de entrega (retirar na central / entrega em endereço)
   */
  pickupOnDistributionCenter: boolean | null;
  /**
   * Valor do pedido mínimo
   */
  requiredMinimum: number;
  /**
   * Valor atual considerado para o pedido mínimo
   */
  currentValueConsideredToMinimum: number;
  /**
   * Ciclo do pedido
   */
  cycle: number;
  /**
   * SubCiclo do pedido
   */
  subCycle: number;
  /**
   * Dados do link externo de pagamento
   */
  externalPaymentLink: AddBatchToCart_AddBatchToCart_cart_externalPaymentLink | null;
  /**
   * Crédito disponível
   */
  credit: AddBatchToCart_AddBatchToCart_cart_credit | null;
  /**
   * Produtos do pedido
   */
  products: AddBatchToCart_AddBatchToCart_cart_products[];
  /**
   * Produtos cortados
   */
  cutProducts: AddBatchToCart_AddBatchToCart_cart_cutProducts[];
  /**
   * Produto (brinde) conquistado
   */
  acquiredProducts: AddBatchToCart_AddBatchToCart_cart_acquiredProducts[] | null;
  /**
   * Promoção conquistada
   */
  acquiredPromotions: AddBatchToCart_AddBatchToCart_cart_acquiredPromotions[] | null;
  /**
   * Promoção conquistada
   */
  partialPromotions: AddBatchToCart_AddBatchToCart_cart_partialPromotions[] | null;
  /**
   * Opção a ser escolhida na promoção (pode ser brinde ou desconto)
   */
  rewardsToChoose: AddBatchToCart_AddBatchToCart_cart_rewardsToChoose[] | null;
  /**
   * Endereço escolhido (ou padrão) para a entrega
   */
  chosenAddress: AddBatchToCart_AddBatchToCart_cart_chosenAddress | null;
  /**
   * Opção de frete escolhida (ou padrão)
   */
  chosenFreightOption: AddBatchToCart_AddBatchToCart_cart_chosenFreightOption | null;
  /**
   * Opção de pagamento escolhida (ou padrão)
   */
  chosenPaymentPlan: AddBatchToCart_AddBatchToCart_cart_chosenPaymentPlan | null;
  /**
   * Resumo de valores do pedido
   */
  totals: AddBatchToCart_AddBatchToCart_cart_totals;
  /**
   * Endereços para entrega
   */
  deliveryAddresses: AddBatchToCart_AddBatchToCart_cart_deliveryAddresses[] | null;
  /**
   * Planos de pagamento
   */
  paymentPlans: AddBatchToCart_AddBatchToCart_cart_paymentPlans[] | null;
}

export interface AddBatchToCart_AddBatchToCart_items {
  __typename: "AddProductResultType";
  /**
   * Código do produto
   */
  productCode: string;
  /**
   * Resultado da adição do produto
   */
  result: AddProductResultTypeEnum;
  /**
   * Mensagem de erro da adição do produto
   */
  message: string | null;
}

export interface AddBatchToCart_AddBatchToCart {
  __typename: "AddBatchResponse";
  cart: AddBatchToCart_AddBatchToCart_cart | null;
  items: AddBatchToCart_AddBatchToCart_items[] | null;
}

export interface AddBatchToCart {
  /**
   * Adiciona vários produtos no pedido da revendedora
   */
  AddBatchToCart: AddBatchToCart_AddBatchToCart;
}

export interface AddBatchToCartVariables {
  data: AddBatchToCartInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddToCart
// ====================================================

export interface AddToCart_AddToCart_externalPaymentLink {
  __typename: "NewPaymentLinkResponse";
  /**
   * Url de pagamento
   */
  externalPaymentLink: string | null;
  /**
   * Data de expiração da url de pagamento
   */
  expirationDatePaymentLink: any | null;
}

export interface AddToCart_AddToCart_credit {
  __typename: "ResellerCreditResponse";
  /**
   * Crédito disponível para compra no momento
   */
  available: number | null;
}

export interface AddToCart_AddToCart_products_origin {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface AddToCart_AddToCart_products {
  __typename: "CartProductType";
  /**
   * Número representativo do produto no carrinho Gera
   */
  sequentialNumber: string;
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * URL da imagem do produto
   */
  imageUrl: string;
  /**
   * Quantidade
   */
  quantity: number;
  /**
   * Preço de mercado (consumidor)
   */
  unitMarketValue: number;
  /**
   * Preço de tabela (consumidor)
   */
  unitTableValue: number;
  /**
   * Preço promocionado (consumidor)
   */
  unitNetValue: number | null;
  /**
   * Origem do produto no pedido
   */
  origin: AddToCart_AddToCart_products_origin;
  /**
   * Bloqueado para edição?
   */
  blockEdit: boolean;
  /**
   * Bloqueado para remoção?
   */
  blockDelete: boolean;
  /**
   * Valor unitário de compra
   */
  unitPayValue: number;
  /**
   * Valor de compra
   */
  payValue: number;
  /**
   * Lucro
   */
  profit: number;
  /**
   * Lucro com descontos
   */
  profitWithDiscount: number;
  /**
   * Se o produto é um combo personalizável
   */
  isCombo: boolean;
}

export interface AddToCart_AddToCart_cutProducts_type {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface AddToCart_AddToCart_cutProducts_cutReason {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface AddToCart_AddToCart_cutProducts {
  __typename: "CartCutProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * URL da imagem do produto
   */
  imageUrl: string;
  /**
   * Quantidade
   */
  quantity: number;
  /**
   * Preço de mercado (consumidor)
   */
  unitMarketValue: number;
  /**
   * Preço de tabela (consumidor)
   */
  unitTableValue: number;
  /**
   * Preço promocionado (consumidor)
   */
  unitNetValue: number | null;
  /**
   * Tipo do item
   */
  type: AddToCart_AddToCart_cutProducts_type;
  /**
   * Razão do corte
   */
  cutReason: AddToCart_AddToCart_cutProducts_cutReason;
}

export interface AddToCart_AddToCart_acquiredProducts_promotion {
  __typename: "CartPromotionType";
  /**
   * Código
   */
  code: string;
  /**
   * Título
   */
  title: string;
}

export interface AddToCart_AddToCart_acquiredProducts {
  __typename: "AcquiredProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * URL da imagem
   */
  imageUrl: string;
  /**
   * Quantidade
   */
  quantity: number | null;
  /**
   * Promoção que originou o brinde
   */
  promotion: AddToCart_AddToCart_acquiredProducts_promotion | null;
}

export interface AddToCart_AddToCart_acquiredPromotions_destination {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface AddToCart_AddToCart_acquiredPromotions_rewards {
  __typename: "RewardProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Quantidade
   */
  quantity: number | null;
  /**
   * URL da imagem
   */
  imageUrl: string;
  /**
   * Desconto
   */
  discount: number;
  /**
   * Tipo
   */
  type: RewardTypeEnum;
  /**
   * Descrição
   */
  description: string | null;
  /**
   * Indica se é um brinde a ser escolhido
   */
  isGiftToChoose: boolean;
}

export interface AddToCart_AddToCart_acquiredPromotions {
  __typename: "AcquiredPromotionType";
  /**
   * Código da promoção
   */
  code: string;
  /**
   * Nome
   */
  title: string;
  /**
   * Descrição
   */
  description: string | null;
  /**
   * Destino
   */
  destination: AddToCart_AddToCart_acquiredPromotions_destination;
  /**
   * Indica se é promoção de cupom de desconto
   */
  isCupomPromotion: boolean;
  /**
   * Lista de brindes/descontos
   */
  rewards: AddToCart_AddToCart_acquiredPromotions_rewards[];
}

export interface AddToCart_AddToCart_partialPromotions_destination {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface AddToCart_AddToCart_partialPromotions_requirements_type {
  __typename: "RequirementType";
  /**
   * Id do tipo de requisito
   */
  id: number | null;
  /**
   * Descrição do requisito
   */
  description: string | null;
}

export interface AddToCart_AddToCart_partialPromotions_requirements_productRequired {
  __typename: "RequiredProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome do produto
   */
  name: string;
  /**
   * Descrição do produto
   */
  description: string | null;
  /**
   * Indica se é um kit
   */
  isKit: boolean | null;
  /**
   * Quantidade de pontos
   */
  points: number | null;
  /**
   * Preço
   */
  price: number | null;
}

export interface AddToCart_AddToCart_partialPromotions_requirements_structureRequired {
  __typename: "RequiredStructureType";
  /**
   * Código da estrutura
   */
  code: string;
  /**
   * Nome da estrutura
   */
  name: string;
}

export interface AddToCart_AddToCart_partialPromotions_requirements {
  __typename: "PromotionRequirementType";
  /**
   * Valor faltante para obter a promoção
   */
  missingValue: number;
  /**
   * Tipo do requisito
   */
  type: AddToCart_AddToCart_partialPromotions_requirements_type;
  /**
   * Produto requisitado
   */
  productRequired: AddToCart_AddToCart_partialPromotions_requirements_productRequired | null;
  /**
   * Estrutura na qual a compra deve ser feita
   */
  structureRequired: AddToCart_AddToCart_partialPromotions_requirements_structureRequired | null;
  /**
   * Descrição
   */
  description: string | null;
}

export interface AddToCart_AddToCart_partialPromotions {
  __typename: "PartialPromotionType";
  /**
   * Código da promoção
   */
  code: string;
  /**
   * Nome
   */
  title: string;
  /**
   * Descrição
   */
  description: string | null;
  /**
   * Destino
   */
  destination: AddToCart_AddToCart_partialPromotions_destination;
  /**
   * Indica se é promoção de cupom de desconto
   */
  isCupomPromotion: boolean;
  /**
   * Lista de requisitos para conquistar a promoção
   */
  requirements: AddToCart_AddToCart_partialPromotions_requirements[];
}

export interface AddToCart_AddToCart_rewardsToChoose_destination {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface AddToCart_AddToCart_rewardsToChoose_productsToChoose {
  __typename: "RewardProductToChooseType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Url da imagem
   */
  imageUrl: string;
  /**
   * Quantidade escolhida
   */
  chosenQuantity: number | null;
  /**
   * Quantidade máxima a ser selecionada
   */
  maxQuantity: number;
  /**
   * Indica se está indisponível (só atualiza depois de escolhido)
   */
  unavailable: boolean;
  /**
   * ?
   */
  isReorderAllowed: boolean;
}

export interface AddToCart_AddToCart_rewardsToChoose_discountsToChoose {
  __typename: "RewardDiscountToChooseType";
  /**
   * Código da opção de desconto
   */
  code: string;
  /**
   * Valor do desconto (unidade é de acordo com o tipo do desconto)
   */
  value: number;
  /**
   * Descrição
   */
  auxiliaryDescription: string | null;
  /**
   * Tipo da conquista
   */
  rewardType: RewardTypeEnum;
}

export interface AddToCart_AddToCart_rewardsToChoose {
  __typename: "RewardToChooseType";
  /**
   * Código da promoção (pode repetir)
   */
  code: string;
  /**
   * Título da promoção
   */
  title: string;
  /**
   * Indica se escolheu produtos
   */
  hasChosenProducts: boolean;
  /**
   * Indica se escolheu a opção de brindes
   */
  hasChosenDiscount: boolean;
  /**
   * Número sequencial (diferenciador das opções)
   */
  giftNumber: string;
  /**
   * Número sequencial (diferenciador dos itens)
   */
  giftItemNumber: string;
  /**
   * Número máximo de brindes a serem escolhidos
   */
  maxGiftsToChoose: number;
  /**
   * Descrição
   */
  description: string | null;
  /**
   * Destino
   */
  destination: AddToCart_AddToCart_rewardsToChoose_destination;
  /**
   * Indica se é promoção de cupom
   */
  isCupomPromotion: boolean;
  /**
   * Produto (brinde) a ser escolhido na promoção
   */
  productsToChoose: AddToCart_AddToCart_rewardsToChoose_productsToChoose[] | null;
  /**
   * Detalhes de uma opção de desconto em uma promoção
   */
  discountsToChoose: AddToCart_AddToCart_rewardsToChoose_discountsToChoose[] | null;
}

export interface AddToCart_AddToCart_chosenAddress_type {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface AddToCart_AddToCart_chosenAddress_address {
  __typename: "RegistrationAddressType";
  /**
   * Logradouro
   */
  streetName: string | null;
  /**
   * Número
   */
  addressNumber: string | null;
  /**
   * CEP
   */
  cep: string | null;
  /**
   * Bairro
   */
  neighborhood: string | null;
  /**
   * Complemento
   */
  complement: string | null;
  /**
   * Cidade
   */
  city: string | null;
  /**
   * Estado
   */
  state: string | null;
  /**
   * País
   */
  country: string | null;
}

export interface AddToCart_AddToCart_chosenAddress {
  __typename: "CartDeliveryAddressType";
  /**
   * Tipo do endereço
   */
  type: AddToCart_AddToCart_chosenAddress_type;
  /**
   * Endereço em formato de objeto
   */
  address: AddToCart_AddToCart_chosenAddress_address;
  /**
   * Endereço formatado
   */
  formattedAddress: string;
  /**
   * CEP
   */
  cep: string;
}

export interface AddToCart_AddToCart_chosenFreightOption_type {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface AddToCart_AddToCart_chosenFreightOption {
  __typename: "FreightOptionResponse";
  /**
   * Código da opção de frete
   */
  code: string;
  /**
   * Nome da opção de frete
   */
  name: string;
  /**
   * Tipo
   */
  type: AddToCart_AddToCart_chosenFreightOption_type;
  /**
   * Valor da entrega
   */
  price: number;
  /**
   * Dias úteis para a entrega
   */
  deliveryUtilDays: number;
}

export interface AddToCart_AddToCart_chosenPaymentPlan_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface AddToCart_AddToCart_chosenPaymentPlan_creditCardOptions_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface AddToCart_AddToCart_chosenPaymentPlan_creditCardOptions {
  __typename: "CreditCardOptionType";
  /**
   * Descrição da bandeira do cartão de crédito
   */
  type: string;
  /**
   * Identificador da bandeira do cartão de crédito
   */
  flag: string;
  /**
   * Modo de pagamento para o cartão especificado
   */
  paymentMode: AddToCart_AddToCart_chosenPaymentPlan_creditCardOptions_paymentMode;
  /**
   * Parcelas possíveis de serem escolhidas
   */
  possibleNumberOfInstallments: number[] | null;
}

export interface AddToCart_AddToCart_chosenPaymentPlan_selectedInstallments {
  __typename: "InstallmentType";
  /**
   * Número da parcela
   */
  number: number;
  /**
   * Valor da parcela
   */
  value: number;
  /**
   * Data de vencimento
   */
  dueDate: any;
  /**
   * Indica se está bloqueada
   */
  isBlocked: boolean;
}

export interface AddToCart_AddToCart_chosenPaymentPlan {
  __typename: "PaymentPlanResponse";
  /**
   * Código do plano de pagamento
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Indica se consumirá crédito da revendedora
   */
  willConsumeCredit: boolean;
  /**
   * Indica se ela deve ter saldo de crédito para poder comprar
   */
  shouldVerifyCredit: boolean;
  /**
   * Indica se está bloqueado
   */
  isBlocked: boolean;
  /**
   * Taxa de administração
   */
  tax: number;
  /**
   * Número de parcelas (para planos sem cartão crédito)
   */
  installments: number | null;
  /**
   * Identificador do modo de pagamento
   */
  paymentMode: AddToCart_AddToCart_chosenPaymentPlan_paymentMode | null;
  /**
   * Opções quando pagamento é por cartão de crédito
   */
  creditCardOptions: AddToCart_AddToCart_chosenPaymentPlan_creditCardOptions[] | null;
  /**
   * Parcelas do plano selecionado
   */
  selectedInstallments: AddToCart_AddToCart_chosenPaymentPlan_selectedInstallments[] | null;
  /**
   * Indica se é múltiplas formas de pagamento
   */
  isMultiplePayment: boolean;
}

export interface AddToCart_AddToCart_totals {
  __typename: "CartTotalsType";
  /**
   * Total de venda
   */
  totalSell: number | null;
  /**
   * Lucro
   */
  profit: number | null;
  /**
   * Frete
   */
  deliveryTax: number | null;
  /**
   * Subtotal
   */
  subtotalPay: number | null;
  /**
   * Total
   */
  totalPay: number | null;
  /**
   * Lucro vindo dos brindes
   */
  profitFromGifts: number | null;
  /**
   * Valor de crédito utilizado da CCR (Conta Corrente Residual)
   */
  checkingAccount: number | null;
  /**
   * Taxa administrativa
   */
  collectionFee: number | null;
  /**
   * Impostos
   */
  taxes: number | null;
  /**
   * Comissão
   */
  commission: number | null;
}

export interface AddToCart_AddToCart_deliveryAddresses_type {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface AddToCart_AddToCart_deliveryAddresses_address {
  __typename: "RegistrationAddressType";
  /**
   * Logradouro
   */
  streetName: string | null;
  /**
   * Número
   */
  addressNumber: string | null;
  /**
   * CEP
   */
  cep: string | null;
  /**
   * Bairro
   */
  neighborhood: string | null;
  /**
   * Complemento
   */
  complement: string | null;
  /**
   * Cidade
   */
  city: string | null;
  /**
   * Estado
   */
  state: string | null;
  /**
   * País
   */
  country: string | null;
}

export interface AddToCart_AddToCart_deliveryAddresses {
  __typename: "CartDeliveryAddressType";
  /**
   * Tipo do endereço
   */
  type: AddToCart_AddToCart_deliveryAddresses_type;
  /**
   * Endereço em formato de objeto
   */
  address: AddToCart_AddToCart_deliveryAddresses_address;
  /**
   * Endereço formatado
   */
  formattedAddress: string;
  /**
   * CEP
   */
  cep: string;
}

export interface AddToCart_AddToCart_paymentPlans_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface AddToCart_AddToCart_paymentPlans_creditCardOptions_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface AddToCart_AddToCart_paymentPlans_creditCardOptions {
  __typename: "CreditCardOptionType";
  /**
   * Descrição da bandeira do cartão de crédito
   */
  type: string;
  /**
   * Identificador da bandeira do cartão de crédito
   */
  flag: string;
  /**
   * Modo de pagamento para o cartão especificado
   */
  paymentMode: AddToCart_AddToCart_paymentPlans_creditCardOptions_paymentMode;
  /**
   * Parcelas possíveis de serem escolhidas
   */
  possibleNumberOfInstallments: number[] | null;
}

export interface AddToCart_AddToCart_paymentPlans_selectedInstallments {
  __typename: "InstallmentType";
  /**
   * Número da parcela
   */
  number: number;
  /**
   * Valor da parcela
   */
  value: number;
  /**
   * Data de vencimento
   */
  dueDate: any;
  /**
   * Indica se está bloqueada
   */
  isBlocked: boolean;
}

export interface AddToCart_AddToCart_paymentPlans {
  __typename: "PaymentPlanResponse";
  /**
   * Código do plano de pagamento
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Indica se consumirá crédito da revendedora
   */
  willConsumeCredit: boolean;
  /**
   * Indica se ela deve ter saldo de crédito para poder comprar
   */
  shouldVerifyCredit: boolean;
  /**
   * Indica se está bloqueado
   */
  isBlocked: boolean;
  /**
   * Taxa de administração
   */
  tax: number;
  /**
   * Número de parcelas (para planos sem cartão crédito)
   */
  installments: number | null;
  /**
   * Identificador do modo de pagamento
   */
  paymentMode: AddToCart_AddToCart_paymentPlans_paymentMode | null;
  /**
   * Opções quando pagamento é por cartão de crédito
   */
  creditCardOptions: AddToCart_AddToCart_paymentPlans_creditCardOptions[] | null;
  /**
   * Parcelas do plano selecionado
   */
  selectedInstallments: AddToCart_AddToCart_paymentPlans_selectedInstallments[] | null;
  /**
   * Indica se é múltiplas formas de pagamento
   */
  isMultiplePayment: boolean;
}

export interface AddToCart_AddToCart {
  __typename: "CartResponse";
  /**
   * Código do pedido
   */
  orderCode: string;
  /**
   * Código do espaço revendedor
   */
  distributionCenterCode: string;
  /**
   * Modo de entrega (retirar na central / entrega em endereço)
   */
  pickupOnDistributionCenter: boolean | null;
  /**
   * Valor do pedido mínimo
   */
  requiredMinimum: number;
  /**
   * Valor atual considerado para o pedido mínimo
   */
  currentValueConsideredToMinimum: number;
  /**
   * Ciclo do pedido
   */
  cycle: number;
  /**
   * SubCiclo do pedido
   */
  subCycle: number;
  /**
   * Dados do link externo de pagamento
   */
  externalPaymentLink: AddToCart_AddToCart_externalPaymentLink | null;
  /**
   * Crédito disponível
   */
  credit: AddToCart_AddToCart_credit | null;
  /**
   * Produtos do pedido
   */
  products: AddToCart_AddToCart_products[];
  /**
   * Produtos cortados
   */
  cutProducts: AddToCart_AddToCart_cutProducts[];
  /**
   * Produto (brinde) conquistado
   */
  acquiredProducts: AddToCart_AddToCart_acquiredProducts[] | null;
  /**
   * Promoção conquistada
   */
  acquiredPromotions: AddToCart_AddToCart_acquiredPromotions[] | null;
  /**
   * Promoção conquistada
   */
  partialPromotions: AddToCart_AddToCart_partialPromotions[] | null;
  /**
   * Opção a ser escolhida na promoção (pode ser brinde ou desconto)
   */
  rewardsToChoose: AddToCart_AddToCart_rewardsToChoose[] | null;
  /**
   * Endereço escolhido (ou padrão) para a entrega
   */
  chosenAddress: AddToCart_AddToCart_chosenAddress | null;
  /**
   * Opção de frete escolhida (ou padrão)
   */
  chosenFreightOption: AddToCart_AddToCart_chosenFreightOption | null;
  /**
   * Opção de pagamento escolhida (ou padrão)
   */
  chosenPaymentPlan: AddToCart_AddToCart_chosenPaymentPlan | null;
  /**
   * Resumo de valores do pedido
   */
  totals: AddToCart_AddToCart_totals;
  /**
   * Endereços para entrega
   */
  deliveryAddresses: AddToCart_AddToCart_deliveryAddresses[] | null;
  /**
   * Planos de pagamento
   */
  paymentPlans: AddToCart_AddToCart_paymentPlans[] | null;
}

export interface AddToCart {
  /**
   * # Mutation - AddToCart
   * ## Description:
   * 
   * 
   *  - Adiciona um produto no pedido da revendedora
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## External Dependencies:
   * 
   * 
   *  - ### ManageOrderDatasource
   *     
   * 
   * 	
   * 	 - Endpoint Path: /cart/orders/${orderCode}/Manage
   * ## Internal Dependencies:
   * 
   * 
   *  - ### AddToCartUseCase
   *     
   * 
   * 
   *  - ### ManageOrderDatasource
   */
  AddToCart: AddToCart_AddToCart;
}

export interface AddToCartVariables {
  data: AddToCartInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ApplyPromotions
// ====================================================

export interface ApplyPromotions_ApplyPromotions_externalPaymentLink {
  __typename: "NewPaymentLinkResponse";
  /**
   * Url de pagamento
   */
  externalPaymentLink: string | null;
  /**
   * Data de expiração da url de pagamento
   */
  expirationDatePaymentLink: any | null;
}

export interface ApplyPromotions_ApplyPromotions_credit {
  __typename: "ResellerCreditResponse";
  /**
   * Crédito disponível para compra no momento
   */
  available: number | null;
}

export interface ApplyPromotions_ApplyPromotions_products_origin {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ApplyPromotions_ApplyPromotions_products {
  __typename: "CartProductType";
  /**
   * Número representativo do produto no carrinho Gera
   */
  sequentialNumber: string;
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * URL da imagem do produto
   */
  imageUrl: string;
  /**
   * Quantidade
   */
  quantity: number;
  /**
   * Preço de mercado (consumidor)
   */
  unitMarketValue: number;
  /**
   * Preço de tabela (consumidor)
   */
  unitTableValue: number;
  /**
   * Preço promocionado (consumidor)
   */
  unitNetValue: number | null;
  /**
   * Origem do produto no pedido
   */
  origin: ApplyPromotions_ApplyPromotions_products_origin;
  /**
   * Bloqueado para edição?
   */
  blockEdit: boolean;
  /**
   * Bloqueado para remoção?
   */
  blockDelete: boolean;
  /**
   * Valor unitário de compra
   */
  unitPayValue: number;
  /**
   * Valor de compra
   */
  payValue: number;
  /**
   * Lucro
   */
  profit: number;
  /**
   * Lucro com descontos
   */
  profitWithDiscount: number;
  /**
   * Se o produto é um combo personalizável
   */
  isCombo: boolean;
}

export interface ApplyPromotions_ApplyPromotions_cutProducts_type {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ApplyPromotions_ApplyPromotions_cutProducts_cutReason {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ApplyPromotions_ApplyPromotions_cutProducts {
  __typename: "CartCutProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * URL da imagem do produto
   */
  imageUrl: string;
  /**
   * Quantidade
   */
  quantity: number;
  /**
   * Preço de mercado (consumidor)
   */
  unitMarketValue: number;
  /**
   * Preço de tabela (consumidor)
   */
  unitTableValue: number;
  /**
   * Preço promocionado (consumidor)
   */
  unitNetValue: number | null;
  /**
   * Tipo do item
   */
  type: ApplyPromotions_ApplyPromotions_cutProducts_type;
  /**
   * Razão do corte
   */
  cutReason: ApplyPromotions_ApplyPromotions_cutProducts_cutReason;
}

export interface ApplyPromotions_ApplyPromotions_acquiredProducts_promotion {
  __typename: "CartPromotionType";
  /**
   * Código
   */
  code: string;
  /**
   * Título
   */
  title: string;
}

export interface ApplyPromotions_ApplyPromotions_acquiredProducts {
  __typename: "AcquiredProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * URL da imagem
   */
  imageUrl: string;
  /**
   * Quantidade
   */
  quantity: number | null;
  /**
   * Promoção que originou o brinde
   */
  promotion: ApplyPromotions_ApplyPromotions_acquiredProducts_promotion | null;
}

export interface ApplyPromotions_ApplyPromotions_acquiredPromotions_destination {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ApplyPromotions_ApplyPromotions_acquiredPromotions_rewards {
  __typename: "RewardProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Quantidade
   */
  quantity: number | null;
  /**
   * URL da imagem
   */
  imageUrl: string;
  /**
   * Desconto
   */
  discount: number;
  /**
   * Tipo
   */
  type: RewardTypeEnum;
  /**
   * Descrição
   */
  description: string | null;
  /**
   * Indica se é um brinde a ser escolhido
   */
  isGiftToChoose: boolean;
}

export interface ApplyPromotions_ApplyPromotions_acquiredPromotions {
  __typename: "AcquiredPromotionType";
  /**
   * Código da promoção
   */
  code: string;
  /**
   * Nome
   */
  title: string;
  /**
   * Descrição
   */
  description: string | null;
  /**
   * Destino
   */
  destination: ApplyPromotions_ApplyPromotions_acquiredPromotions_destination;
  /**
   * Indica se é promoção de cupom de desconto
   */
  isCupomPromotion: boolean;
  /**
   * Lista de brindes/descontos
   */
  rewards: ApplyPromotions_ApplyPromotions_acquiredPromotions_rewards[];
}

export interface ApplyPromotions_ApplyPromotions_partialPromotions_destination {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ApplyPromotions_ApplyPromotions_partialPromotions_requirements_type {
  __typename: "RequirementType";
  /**
   * Id do tipo de requisito
   */
  id: number | null;
  /**
   * Descrição do requisito
   */
  description: string | null;
}

export interface ApplyPromotions_ApplyPromotions_partialPromotions_requirements_productRequired {
  __typename: "RequiredProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome do produto
   */
  name: string;
  /**
   * Descrição do produto
   */
  description: string | null;
  /**
   * Indica se é um kit
   */
  isKit: boolean | null;
  /**
   * Quantidade de pontos
   */
  points: number | null;
  /**
   * Preço
   */
  price: number | null;
}

export interface ApplyPromotions_ApplyPromotions_partialPromotions_requirements_structureRequired {
  __typename: "RequiredStructureType";
  /**
   * Código da estrutura
   */
  code: string;
  /**
   * Nome da estrutura
   */
  name: string;
}

export interface ApplyPromotions_ApplyPromotions_partialPromotions_requirements {
  __typename: "PromotionRequirementType";
  /**
   * Valor faltante para obter a promoção
   */
  missingValue: number;
  /**
   * Tipo do requisito
   */
  type: ApplyPromotions_ApplyPromotions_partialPromotions_requirements_type;
  /**
   * Produto requisitado
   */
  productRequired: ApplyPromotions_ApplyPromotions_partialPromotions_requirements_productRequired | null;
  /**
   * Estrutura na qual a compra deve ser feita
   */
  structureRequired: ApplyPromotions_ApplyPromotions_partialPromotions_requirements_structureRequired | null;
  /**
   * Descrição
   */
  description: string | null;
}

export interface ApplyPromotions_ApplyPromotions_partialPromotions {
  __typename: "PartialPromotionType";
  /**
   * Código da promoção
   */
  code: string;
  /**
   * Nome
   */
  title: string;
  /**
   * Descrição
   */
  description: string | null;
  /**
   * Destino
   */
  destination: ApplyPromotions_ApplyPromotions_partialPromotions_destination;
  /**
   * Indica se é promoção de cupom de desconto
   */
  isCupomPromotion: boolean;
  /**
   * Lista de requisitos para conquistar a promoção
   */
  requirements: ApplyPromotions_ApplyPromotions_partialPromotions_requirements[];
}

export interface ApplyPromotions_ApplyPromotions_rewardsToChoose_destination {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ApplyPromotions_ApplyPromotions_rewardsToChoose_productsToChoose {
  __typename: "RewardProductToChooseType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Url da imagem
   */
  imageUrl: string;
  /**
   * Quantidade escolhida
   */
  chosenQuantity: number | null;
  /**
   * Quantidade máxima a ser selecionada
   */
  maxQuantity: number;
  /**
   * Indica se está indisponível (só atualiza depois de escolhido)
   */
  unavailable: boolean;
  /**
   * ?
   */
  isReorderAllowed: boolean;
}

export interface ApplyPromotions_ApplyPromotions_rewardsToChoose_discountsToChoose {
  __typename: "RewardDiscountToChooseType";
  /**
   * Código da opção de desconto
   */
  code: string;
  /**
   * Valor do desconto (unidade é de acordo com o tipo do desconto)
   */
  value: number;
  /**
   * Descrição
   */
  auxiliaryDescription: string | null;
  /**
   * Tipo da conquista
   */
  rewardType: RewardTypeEnum;
}

export interface ApplyPromotions_ApplyPromotions_rewardsToChoose {
  __typename: "RewardToChooseType";
  /**
   * Código da promoção (pode repetir)
   */
  code: string;
  /**
   * Título da promoção
   */
  title: string;
  /**
   * Indica se escolheu produtos
   */
  hasChosenProducts: boolean;
  /**
   * Indica se escolheu a opção de brindes
   */
  hasChosenDiscount: boolean;
  /**
   * Número sequencial (diferenciador das opções)
   */
  giftNumber: string;
  /**
   * Número sequencial (diferenciador dos itens)
   */
  giftItemNumber: string;
  /**
   * Número máximo de brindes a serem escolhidos
   */
  maxGiftsToChoose: number;
  /**
   * Descrição
   */
  description: string | null;
  /**
   * Destino
   */
  destination: ApplyPromotions_ApplyPromotions_rewardsToChoose_destination;
  /**
   * Indica se é promoção de cupom
   */
  isCupomPromotion: boolean;
  /**
   * Produto (brinde) a ser escolhido na promoção
   */
  productsToChoose: ApplyPromotions_ApplyPromotions_rewardsToChoose_productsToChoose[] | null;
  /**
   * Detalhes de uma opção de desconto em uma promoção
   */
  discountsToChoose: ApplyPromotions_ApplyPromotions_rewardsToChoose_discountsToChoose[] | null;
}

export interface ApplyPromotions_ApplyPromotions_chosenAddress_type {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ApplyPromotions_ApplyPromotions_chosenAddress_address {
  __typename: "RegistrationAddressType";
  /**
   * Logradouro
   */
  streetName: string | null;
  /**
   * Número
   */
  addressNumber: string | null;
  /**
   * CEP
   */
  cep: string | null;
  /**
   * Bairro
   */
  neighborhood: string | null;
  /**
   * Complemento
   */
  complement: string | null;
  /**
   * Cidade
   */
  city: string | null;
  /**
   * Estado
   */
  state: string | null;
  /**
   * País
   */
  country: string | null;
}

export interface ApplyPromotions_ApplyPromotions_chosenAddress {
  __typename: "CartDeliveryAddressType";
  /**
   * Tipo do endereço
   */
  type: ApplyPromotions_ApplyPromotions_chosenAddress_type;
  /**
   * Endereço em formato de objeto
   */
  address: ApplyPromotions_ApplyPromotions_chosenAddress_address;
  /**
   * Endereço formatado
   */
  formattedAddress: string;
  /**
   * CEP
   */
  cep: string;
}

export interface ApplyPromotions_ApplyPromotions_chosenFreightOption_type {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ApplyPromotions_ApplyPromotions_chosenFreightOption {
  __typename: "FreightOptionResponse";
  /**
   * Código da opção de frete
   */
  code: string;
  /**
   * Nome da opção de frete
   */
  name: string;
  /**
   * Tipo
   */
  type: ApplyPromotions_ApplyPromotions_chosenFreightOption_type;
  /**
   * Valor da entrega
   */
  price: number;
  /**
   * Dias úteis para a entrega
   */
  deliveryUtilDays: number;
}

export interface ApplyPromotions_ApplyPromotions_chosenPaymentPlan_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ApplyPromotions_ApplyPromotions_chosenPaymentPlan_creditCardOptions_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ApplyPromotions_ApplyPromotions_chosenPaymentPlan_creditCardOptions {
  __typename: "CreditCardOptionType";
  /**
   * Descrição da bandeira do cartão de crédito
   */
  type: string;
  /**
   * Identificador da bandeira do cartão de crédito
   */
  flag: string;
  /**
   * Modo de pagamento para o cartão especificado
   */
  paymentMode: ApplyPromotions_ApplyPromotions_chosenPaymentPlan_creditCardOptions_paymentMode;
  /**
   * Parcelas possíveis de serem escolhidas
   */
  possibleNumberOfInstallments: number[] | null;
}

export interface ApplyPromotions_ApplyPromotions_chosenPaymentPlan_selectedInstallments {
  __typename: "InstallmentType";
  /**
   * Número da parcela
   */
  number: number;
  /**
   * Valor da parcela
   */
  value: number;
  /**
   * Data de vencimento
   */
  dueDate: any;
  /**
   * Indica se está bloqueada
   */
  isBlocked: boolean;
}

export interface ApplyPromotions_ApplyPromotions_chosenPaymentPlan {
  __typename: "PaymentPlanResponse";
  /**
   * Código do plano de pagamento
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Indica se consumirá crédito da revendedora
   */
  willConsumeCredit: boolean;
  /**
   * Indica se ela deve ter saldo de crédito para poder comprar
   */
  shouldVerifyCredit: boolean;
  /**
   * Indica se está bloqueado
   */
  isBlocked: boolean;
  /**
   * Taxa de administração
   */
  tax: number;
  /**
   * Número de parcelas (para planos sem cartão crédito)
   */
  installments: number | null;
  /**
   * Identificador do modo de pagamento
   */
  paymentMode: ApplyPromotions_ApplyPromotions_chosenPaymentPlan_paymentMode | null;
  /**
   * Opções quando pagamento é por cartão de crédito
   */
  creditCardOptions: ApplyPromotions_ApplyPromotions_chosenPaymentPlan_creditCardOptions[] | null;
  /**
   * Parcelas do plano selecionado
   */
  selectedInstallments: ApplyPromotions_ApplyPromotions_chosenPaymentPlan_selectedInstallments[] | null;
  /**
   * Indica se é múltiplas formas de pagamento
   */
  isMultiplePayment: boolean;
}

export interface ApplyPromotions_ApplyPromotions_totals {
  __typename: "CartTotalsType";
  /**
   * Total de venda
   */
  totalSell: number | null;
  /**
   * Lucro
   */
  profit: number | null;
  /**
   * Frete
   */
  deliveryTax: number | null;
  /**
   * Subtotal
   */
  subtotalPay: number | null;
  /**
   * Total
   */
  totalPay: number | null;
  /**
   * Lucro vindo dos brindes
   */
  profitFromGifts: number | null;
  /**
   * Valor de crédito utilizado da CCR (Conta Corrente Residual)
   */
  checkingAccount: number | null;
  /**
   * Taxa administrativa
   */
  collectionFee: number | null;
  /**
   * Impostos
   */
  taxes: number | null;
  /**
   * Comissão
   */
  commission: number | null;
}

export interface ApplyPromotions_ApplyPromotions_deliveryAddresses_type {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ApplyPromotions_ApplyPromotions_deliveryAddresses_address {
  __typename: "RegistrationAddressType";
  /**
   * Logradouro
   */
  streetName: string | null;
  /**
   * Número
   */
  addressNumber: string | null;
  /**
   * CEP
   */
  cep: string | null;
  /**
   * Bairro
   */
  neighborhood: string | null;
  /**
   * Complemento
   */
  complement: string | null;
  /**
   * Cidade
   */
  city: string | null;
  /**
   * Estado
   */
  state: string | null;
  /**
   * País
   */
  country: string | null;
}

export interface ApplyPromotions_ApplyPromotions_deliveryAddresses {
  __typename: "CartDeliveryAddressType";
  /**
   * Tipo do endereço
   */
  type: ApplyPromotions_ApplyPromotions_deliveryAddresses_type;
  /**
   * Endereço em formato de objeto
   */
  address: ApplyPromotions_ApplyPromotions_deliveryAddresses_address;
  /**
   * Endereço formatado
   */
  formattedAddress: string;
  /**
   * CEP
   */
  cep: string;
}

export interface ApplyPromotions_ApplyPromotions_paymentPlans_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ApplyPromotions_ApplyPromotions_paymentPlans_creditCardOptions_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ApplyPromotions_ApplyPromotions_paymentPlans_creditCardOptions {
  __typename: "CreditCardOptionType";
  /**
   * Descrição da bandeira do cartão de crédito
   */
  type: string;
  /**
   * Identificador da bandeira do cartão de crédito
   */
  flag: string;
  /**
   * Modo de pagamento para o cartão especificado
   */
  paymentMode: ApplyPromotions_ApplyPromotions_paymentPlans_creditCardOptions_paymentMode;
  /**
   * Parcelas possíveis de serem escolhidas
   */
  possibleNumberOfInstallments: number[] | null;
}

export interface ApplyPromotions_ApplyPromotions_paymentPlans_selectedInstallments {
  __typename: "InstallmentType";
  /**
   * Número da parcela
   */
  number: number;
  /**
   * Valor da parcela
   */
  value: number;
  /**
   * Data de vencimento
   */
  dueDate: any;
  /**
   * Indica se está bloqueada
   */
  isBlocked: boolean;
}

export interface ApplyPromotions_ApplyPromotions_paymentPlans {
  __typename: "PaymentPlanResponse";
  /**
   * Código do plano de pagamento
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Indica se consumirá crédito da revendedora
   */
  willConsumeCredit: boolean;
  /**
   * Indica se ela deve ter saldo de crédito para poder comprar
   */
  shouldVerifyCredit: boolean;
  /**
   * Indica se está bloqueado
   */
  isBlocked: boolean;
  /**
   * Taxa de administração
   */
  tax: number;
  /**
   * Número de parcelas (para planos sem cartão crédito)
   */
  installments: number | null;
  /**
   * Identificador do modo de pagamento
   */
  paymentMode: ApplyPromotions_ApplyPromotions_paymentPlans_paymentMode | null;
  /**
   * Opções quando pagamento é por cartão de crédito
   */
  creditCardOptions: ApplyPromotions_ApplyPromotions_paymentPlans_creditCardOptions[] | null;
  /**
   * Parcelas do plano selecionado
   */
  selectedInstallments: ApplyPromotions_ApplyPromotions_paymentPlans_selectedInstallments[] | null;
  /**
   * Indica se é múltiplas formas de pagamento
   */
  isMultiplePayment: boolean;
}

export interface ApplyPromotions_ApplyPromotions {
  __typename: "CartResponse";
  /**
   * Código do pedido
   */
  orderCode: string;
  /**
   * Código do espaço revendedor
   */
  distributionCenterCode: string;
  /**
   * Modo de entrega (retirar na central / entrega em endereço)
   */
  pickupOnDistributionCenter: boolean | null;
  /**
   * Valor do pedido mínimo
   */
  requiredMinimum: number;
  /**
   * Valor atual considerado para o pedido mínimo
   */
  currentValueConsideredToMinimum: number;
  /**
   * Ciclo do pedido
   */
  cycle: number;
  /**
   * SubCiclo do pedido
   */
  subCycle: number;
  /**
   * Dados do link externo de pagamento
   */
  externalPaymentLink: ApplyPromotions_ApplyPromotions_externalPaymentLink | null;
  /**
   * Crédito disponível
   */
  credit: ApplyPromotions_ApplyPromotions_credit | null;
  /**
   * Produtos do pedido
   */
  products: ApplyPromotions_ApplyPromotions_products[];
  /**
   * Produtos cortados
   */
  cutProducts: ApplyPromotions_ApplyPromotions_cutProducts[];
  /**
   * Produto (brinde) conquistado
   */
  acquiredProducts: ApplyPromotions_ApplyPromotions_acquiredProducts[] | null;
  /**
   * Promoção conquistada
   */
  acquiredPromotions: ApplyPromotions_ApplyPromotions_acquiredPromotions[] | null;
  /**
   * Promoção conquistada
   */
  partialPromotions: ApplyPromotions_ApplyPromotions_partialPromotions[] | null;
  /**
   * Opção a ser escolhida na promoção (pode ser brinde ou desconto)
   */
  rewardsToChoose: ApplyPromotions_ApplyPromotions_rewardsToChoose[] | null;
  /**
   * Endereço escolhido (ou padrão) para a entrega
   */
  chosenAddress: ApplyPromotions_ApplyPromotions_chosenAddress | null;
  /**
   * Opção de frete escolhida (ou padrão)
   */
  chosenFreightOption: ApplyPromotions_ApplyPromotions_chosenFreightOption | null;
  /**
   * Opção de pagamento escolhida (ou padrão)
   */
  chosenPaymentPlan: ApplyPromotions_ApplyPromotions_chosenPaymentPlan | null;
  /**
   * Resumo de valores do pedido
   */
  totals: ApplyPromotions_ApplyPromotions_totals;
  /**
   * Endereços para entrega
   */
  deliveryAddresses: ApplyPromotions_ApplyPromotions_deliveryAddresses[] | null;
  /**
   * Planos de pagamento
   */
  paymentPlans: ApplyPromotions_ApplyPromotions_paymentPlans[] | null;
}

export interface ApplyPromotions {
  /**
   * # Mutation - ApplyPromotions
   * ## Description:
   * 
   * 
   *  - Aplica/simula as promoções, retornando os brindes/descontos e os endereços de entrega
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## External Dependencies:
   * 
   * 
   *  - ### ManageOrderDatasource
   *     
   * 
   * 	
   * 	 - Endpoint Path: /cart/orders/${orderCode}/Manage
   * ## Internal Dependencies:
   * 
   * 
   *  - ### ApplyPromotionsUseCase
   *     
   * 
   * 
   *  - ### ManageOrderDatasource
   */
  ApplyPromotions: ApplyPromotions_ApplyPromotions;
}

export interface ApplyPromotionsVariables {
  orderCode: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CancelOrder
// ====================================================

export interface CancelOrder {
  /**
   * # Mutation - CancelOrder
   * ## Description:
   * 
   * 
   *  - Cancela o pedido para a revendedora
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## External Dependencies:
   * 
   * 
   *  - ### BoticarioBaseGeraHttpDatasource
   *     
   * 
   * 	
   * 	 - Endpoint Path: /grb/sb/CarrinhoGera/orders/${orderCode}/close
   * ## Internal Dependencies:
   * 
   * 
   *  - ### CancelOrderUseCase
   *     
   * 
   * 
   *  - ### CancelOrderDatasource
   */
  CancelOrder: boolean;
}

export interface CancelOrderVariables {
  orderCode: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: FvcCancelScheduling
// ====================================================

export interface FvcCancelScheduling_FvcCancelScheduling {
  __typename: "FvcSchedulingResponse";
  /**
   * Id
   */
  id: string;
}

export interface FvcCancelScheduling {
  /**
   * # Mutation - FvcCancelScheduling
   * ## Description:
   * 
   * 
   *  - Cancela um agendamento
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## Internal Dependencies:
   * 
   * 
   *  - ### FvcCancelSchedulingUseCase
   *     
   * 
   * 
   *  - ### FvcSchedulingDbDatasource
   */
  FvcCancelScheduling: FvcCancelScheduling_FvcCancelScheduling;
}

export interface FvcCancelSchedulingVariables {
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ChangeProductQuantity
// ====================================================

export interface ChangeProductQuantity_ChangeProductQuantity_externalPaymentLink {
  __typename: "NewPaymentLinkResponse";
  /**
   * Url de pagamento
   */
  externalPaymentLink: string | null;
  /**
   * Data de expiração da url de pagamento
   */
  expirationDatePaymentLink: any | null;
}

export interface ChangeProductQuantity_ChangeProductQuantity_credit {
  __typename: "ResellerCreditResponse";
  /**
   * Crédito disponível para compra no momento
   */
  available: number | null;
}

export interface ChangeProductQuantity_ChangeProductQuantity_products_origin {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ChangeProductQuantity_ChangeProductQuantity_products {
  __typename: "CartProductType";
  /**
   * Número representativo do produto no carrinho Gera
   */
  sequentialNumber: string;
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * URL da imagem do produto
   */
  imageUrl: string;
  /**
   * Quantidade
   */
  quantity: number;
  /**
   * Preço de mercado (consumidor)
   */
  unitMarketValue: number;
  /**
   * Preço de tabela (consumidor)
   */
  unitTableValue: number;
  /**
   * Preço promocionado (consumidor)
   */
  unitNetValue: number | null;
  /**
   * Origem do produto no pedido
   */
  origin: ChangeProductQuantity_ChangeProductQuantity_products_origin;
  /**
   * Bloqueado para edição?
   */
  blockEdit: boolean;
  /**
   * Bloqueado para remoção?
   */
  blockDelete: boolean;
  /**
   * Valor unitário de compra
   */
  unitPayValue: number;
  /**
   * Valor de compra
   */
  payValue: number;
  /**
   * Lucro
   */
  profit: number;
  /**
   * Lucro com descontos
   */
  profitWithDiscount: number;
  /**
   * Se o produto é um combo personalizável
   */
  isCombo: boolean;
}

export interface ChangeProductQuantity_ChangeProductQuantity_cutProducts_type {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ChangeProductQuantity_ChangeProductQuantity_cutProducts_cutReason {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ChangeProductQuantity_ChangeProductQuantity_cutProducts {
  __typename: "CartCutProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * URL da imagem do produto
   */
  imageUrl: string;
  /**
   * Quantidade
   */
  quantity: number;
  /**
   * Preço de mercado (consumidor)
   */
  unitMarketValue: number;
  /**
   * Preço de tabela (consumidor)
   */
  unitTableValue: number;
  /**
   * Preço promocionado (consumidor)
   */
  unitNetValue: number | null;
  /**
   * Tipo do item
   */
  type: ChangeProductQuantity_ChangeProductQuantity_cutProducts_type;
  /**
   * Razão do corte
   */
  cutReason: ChangeProductQuantity_ChangeProductQuantity_cutProducts_cutReason;
}

export interface ChangeProductQuantity_ChangeProductQuantity_acquiredProducts_promotion {
  __typename: "CartPromotionType";
  /**
   * Código
   */
  code: string;
  /**
   * Título
   */
  title: string;
}

export interface ChangeProductQuantity_ChangeProductQuantity_acquiredProducts {
  __typename: "AcquiredProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * URL da imagem
   */
  imageUrl: string;
  /**
   * Quantidade
   */
  quantity: number | null;
  /**
   * Promoção que originou o brinde
   */
  promotion: ChangeProductQuantity_ChangeProductQuantity_acquiredProducts_promotion | null;
}

export interface ChangeProductQuantity_ChangeProductQuantity_acquiredPromotions_destination {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ChangeProductQuantity_ChangeProductQuantity_acquiredPromotions_rewards {
  __typename: "RewardProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Quantidade
   */
  quantity: number | null;
  /**
   * URL da imagem
   */
  imageUrl: string;
  /**
   * Desconto
   */
  discount: number;
  /**
   * Tipo
   */
  type: RewardTypeEnum;
  /**
   * Descrição
   */
  description: string | null;
  /**
   * Indica se é um brinde a ser escolhido
   */
  isGiftToChoose: boolean;
}

export interface ChangeProductQuantity_ChangeProductQuantity_acquiredPromotions {
  __typename: "AcquiredPromotionType";
  /**
   * Código da promoção
   */
  code: string;
  /**
   * Nome
   */
  title: string;
  /**
   * Descrição
   */
  description: string | null;
  /**
   * Destino
   */
  destination: ChangeProductQuantity_ChangeProductQuantity_acquiredPromotions_destination;
  /**
   * Indica se é promoção de cupom de desconto
   */
  isCupomPromotion: boolean;
  /**
   * Lista de brindes/descontos
   */
  rewards: ChangeProductQuantity_ChangeProductQuantity_acquiredPromotions_rewards[];
}

export interface ChangeProductQuantity_ChangeProductQuantity_partialPromotions_destination {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ChangeProductQuantity_ChangeProductQuantity_partialPromotions_requirements_type {
  __typename: "RequirementType";
  /**
   * Id do tipo de requisito
   */
  id: number | null;
  /**
   * Descrição do requisito
   */
  description: string | null;
}

export interface ChangeProductQuantity_ChangeProductQuantity_partialPromotions_requirements_productRequired {
  __typename: "RequiredProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome do produto
   */
  name: string;
  /**
   * Descrição do produto
   */
  description: string | null;
  /**
   * Indica se é um kit
   */
  isKit: boolean | null;
  /**
   * Quantidade de pontos
   */
  points: number | null;
  /**
   * Preço
   */
  price: number | null;
}

export interface ChangeProductQuantity_ChangeProductQuantity_partialPromotions_requirements_structureRequired {
  __typename: "RequiredStructureType";
  /**
   * Código da estrutura
   */
  code: string;
  /**
   * Nome da estrutura
   */
  name: string;
}

export interface ChangeProductQuantity_ChangeProductQuantity_partialPromotions_requirements {
  __typename: "PromotionRequirementType";
  /**
   * Valor faltante para obter a promoção
   */
  missingValue: number;
  /**
   * Tipo do requisito
   */
  type: ChangeProductQuantity_ChangeProductQuantity_partialPromotions_requirements_type;
  /**
   * Produto requisitado
   */
  productRequired: ChangeProductQuantity_ChangeProductQuantity_partialPromotions_requirements_productRequired | null;
  /**
   * Estrutura na qual a compra deve ser feita
   */
  structureRequired: ChangeProductQuantity_ChangeProductQuantity_partialPromotions_requirements_structureRequired | null;
  /**
   * Descrição
   */
  description: string | null;
}

export interface ChangeProductQuantity_ChangeProductQuantity_partialPromotions {
  __typename: "PartialPromotionType";
  /**
   * Código da promoção
   */
  code: string;
  /**
   * Nome
   */
  title: string;
  /**
   * Descrição
   */
  description: string | null;
  /**
   * Destino
   */
  destination: ChangeProductQuantity_ChangeProductQuantity_partialPromotions_destination;
  /**
   * Indica se é promoção de cupom de desconto
   */
  isCupomPromotion: boolean;
  /**
   * Lista de requisitos para conquistar a promoção
   */
  requirements: ChangeProductQuantity_ChangeProductQuantity_partialPromotions_requirements[];
}

export interface ChangeProductQuantity_ChangeProductQuantity_rewardsToChoose_destination {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ChangeProductQuantity_ChangeProductQuantity_rewardsToChoose_productsToChoose {
  __typename: "RewardProductToChooseType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Url da imagem
   */
  imageUrl: string;
  /**
   * Quantidade escolhida
   */
  chosenQuantity: number | null;
  /**
   * Quantidade máxima a ser selecionada
   */
  maxQuantity: number;
  /**
   * Indica se está indisponível (só atualiza depois de escolhido)
   */
  unavailable: boolean;
  /**
   * ?
   */
  isReorderAllowed: boolean;
}

export interface ChangeProductQuantity_ChangeProductQuantity_rewardsToChoose_discountsToChoose {
  __typename: "RewardDiscountToChooseType";
  /**
   * Código da opção de desconto
   */
  code: string;
  /**
   * Valor do desconto (unidade é de acordo com o tipo do desconto)
   */
  value: number;
  /**
   * Descrição
   */
  auxiliaryDescription: string | null;
  /**
   * Tipo da conquista
   */
  rewardType: RewardTypeEnum;
}

export interface ChangeProductQuantity_ChangeProductQuantity_rewardsToChoose {
  __typename: "RewardToChooseType";
  /**
   * Código da promoção (pode repetir)
   */
  code: string;
  /**
   * Título da promoção
   */
  title: string;
  /**
   * Indica se escolheu produtos
   */
  hasChosenProducts: boolean;
  /**
   * Indica se escolheu a opção de brindes
   */
  hasChosenDiscount: boolean;
  /**
   * Número sequencial (diferenciador das opções)
   */
  giftNumber: string;
  /**
   * Número sequencial (diferenciador dos itens)
   */
  giftItemNumber: string;
  /**
   * Número máximo de brindes a serem escolhidos
   */
  maxGiftsToChoose: number;
  /**
   * Descrição
   */
  description: string | null;
  /**
   * Destino
   */
  destination: ChangeProductQuantity_ChangeProductQuantity_rewardsToChoose_destination;
  /**
   * Indica se é promoção de cupom
   */
  isCupomPromotion: boolean;
  /**
   * Produto (brinde) a ser escolhido na promoção
   */
  productsToChoose: ChangeProductQuantity_ChangeProductQuantity_rewardsToChoose_productsToChoose[] | null;
  /**
   * Detalhes de uma opção de desconto em uma promoção
   */
  discountsToChoose: ChangeProductQuantity_ChangeProductQuantity_rewardsToChoose_discountsToChoose[] | null;
}

export interface ChangeProductQuantity_ChangeProductQuantity_chosenAddress_type {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ChangeProductQuantity_ChangeProductQuantity_chosenAddress_address {
  __typename: "RegistrationAddressType";
  /**
   * Logradouro
   */
  streetName: string | null;
  /**
   * Número
   */
  addressNumber: string | null;
  /**
   * CEP
   */
  cep: string | null;
  /**
   * Bairro
   */
  neighborhood: string | null;
  /**
   * Complemento
   */
  complement: string | null;
  /**
   * Cidade
   */
  city: string | null;
  /**
   * Estado
   */
  state: string | null;
  /**
   * País
   */
  country: string | null;
}

export interface ChangeProductQuantity_ChangeProductQuantity_chosenAddress {
  __typename: "CartDeliveryAddressType";
  /**
   * Tipo do endereço
   */
  type: ChangeProductQuantity_ChangeProductQuantity_chosenAddress_type;
  /**
   * Endereço em formato de objeto
   */
  address: ChangeProductQuantity_ChangeProductQuantity_chosenAddress_address;
  /**
   * Endereço formatado
   */
  formattedAddress: string;
  /**
   * CEP
   */
  cep: string;
}

export interface ChangeProductQuantity_ChangeProductQuantity_chosenFreightOption_type {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ChangeProductQuantity_ChangeProductQuantity_chosenFreightOption {
  __typename: "FreightOptionResponse";
  /**
   * Código da opção de frete
   */
  code: string;
  /**
   * Nome da opção de frete
   */
  name: string;
  /**
   * Tipo
   */
  type: ChangeProductQuantity_ChangeProductQuantity_chosenFreightOption_type;
  /**
   * Valor da entrega
   */
  price: number;
  /**
   * Dias úteis para a entrega
   */
  deliveryUtilDays: number;
}

export interface ChangeProductQuantity_ChangeProductQuantity_chosenPaymentPlan_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ChangeProductQuantity_ChangeProductQuantity_chosenPaymentPlan_creditCardOptions_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ChangeProductQuantity_ChangeProductQuantity_chosenPaymentPlan_creditCardOptions {
  __typename: "CreditCardOptionType";
  /**
   * Descrição da bandeira do cartão de crédito
   */
  type: string;
  /**
   * Identificador da bandeira do cartão de crédito
   */
  flag: string;
  /**
   * Modo de pagamento para o cartão especificado
   */
  paymentMode: ChangeProductQuantity_ChangeProductQuantity_chosenPaymentPlan_creditCardOptions_paymentMode;
  /**
   * Parcelas possíveis de serem escolhidas
   */
  possibleNumberOfInstallments: number[] | null;
}

export interface ChangeProductQuantity_ChangeProductQuantity_chosenPaymentPlan_selectedInstallments {
  __typename: "InstallmentType";
  /**
   * Número da parcela
   */
  number: number;
  /**
   * Valor da parcela
   */
  value: number;
  /**
   * Data de vencimento
   */
  dueDate: any;
  /**
   * Indica se está bloqueada
   */
  isBlocked: boolean;
}

export interface ChangeProductQuantity_ChangeProductQuantity_chosenPaymentPlan {
  __typename: "PaymentPlanResponse";
  /**
   * Código do plano de pagamento
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Indica se consumirá crédito da revendedora
   */
  willConsumeCredit: boolean;
  /**
   * Indica se ela deve ter saldo de crédito para poder comprar
   */
  shouldVerifyCredit: boolean;
  /**
   * Indica se está bloqueado
   */
  isBlocked: boolean;
  /**
   * Taxa de administração
   */
  tax: number;
  /**
   * Número de parcelas (para planos sem cartão crédito)
   */
  installments: number | null;
  /**
   * Identificador do modo de pagamento
   */
  paymentMode: ChangeProductQuantity_ChangeProductQuantity_chosenPaymentPlan_paymentMode | null;
  /**
   * Opções quando pagamento é por cartão de crédito
   */
  creditCardOptions: ChangeProductQuantity_ChangeProductQuantity_chosenPaymentPlan_creditCardOptions[] | null;
  /**
   * Parcelas do plano selecionado
   */
  selectedInstallments: ChangeProductQuantity_ChangeProductQuantity_chosenPaymentPlan_selectedInstallments[] | null;
  /**
   * Indica se é múltiplas formas de pagamento
   */
  isMultiplePayment: boolean;
}

export interface ChangeProductQuantity_ChangeProductQuantity_totals {
  __typename: "CartTotalsType";
  /**
   * Total de venda
   */
  totalSell: number | null;
  /**
   * Lucro
   */
  profit: number | null;
  /**
   * Frete
   */
  deliveryTax: number | null;
  /**
   * Subtotal
   */
  subtotalPay: number | null;
  /**
   * Total
   */
  totalPay: number | null;
  /**
   * Lucro vindo dos brindes
   */
  profitFromGifts: number | null;
  /**
   * Valor de crédito utilizado da CCR (Conta Corrente Residual)
   */
  checkingAccount: number | null;
  /**
   * Taxa administrativa
   */
  collectionFee: number | null;
  /**
   * Impostos
   */
  taxes: number | null;
  /**
   * Comissão
   */
  commission: number | null;
}

export interface ChangeProductQuantity_ChangeProductQuantity_deliveryAddresses_type {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ChangeProductQuantity_ChangeProductQuantity_deliveryAddresses_address {
  __typename: "RegistrationAddressType";
  /**
   * Logradouro
   */
  streetName: string | null;
  /**
   * Número
   */
  addressNumber: string | null;
  /**
   * CEP
   */
  cep: string | null;
  /**
   * Bairro
   */
  neighborhood: string | null;
  /**
   * Complemento
   */
  complement: string | null;
  /**
   * Cidade
   */
  city: string | null;
  /**
   * Estado
   */
  state: string | null;
  /**
   * País
   */
  country: string | null;
}

export interface ChangeProductQuantity_ChangeProductQuantity_deliveryAddresses {
  __typename: "CartDeliveryAddressType";
  /**
   * Tipo do endereço
   */
  type: ChangeProductQuantity_ChangeProductQuantity_deliveryAddresses_type;
  /**
   * Endereço em formato de objeto
   */
  address: ChangeProductQuantity_ChangeProductQuantity_deliveryAddresses_address;
  /**
   * Endereço formatado
   */
  formattedAddress: string;
  /**
   * CEP
   */
  cep: string;
}

export interface ChangeProductQuantity_ChangeProductQuantity_paymentPlans_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ChangeProductQuantity_ChangeProductQuantity_paymentPlans_creditCardOptions_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ChangeProductQuantity_ChangeProductQuantity_paymentPlans_creditCardOptions {
  __typename: "CreditCardOptionType";
  /**
   * Descrição da bandeira do cartão de crédito
   */
  type: string;
  /**
   * Identificador da bandeira do cartão de crédito
   */
  flag: string;
  /**
   * Modo de pagamento para o cartão especificado
   */
  paymentMode: ChangeProductQuantity_ChangeProductQuantity_paymentPlans_creditCardOptions_paymentMode;
  /**
   * Parcelas possíveis de serem escolhidas
   */
  possibleNumberOfInstallments: number[] | null;
}

export interface ChangeProductQuantity_ChangeProductQuantity_paymentPlans_selectedInstallments {
  __typename: "InstallmentType";
  /**
   * Número da parcela
   */
  number: number;
  /**
   * Valor da parcela
   */
  value: number;
  /**
   * Data de vencimento
   */
  dueDate: any;
  /**
   * Indica se está bloqueada
   */
  isBlocked: boolean;
}

export interface ChangeProductQuantity_ChangeProductQuantity_paymentPlans {
  __typename: "PaymentPlanResponse";
  /**
   * Código do plano de pagamento
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Indica se consumirá crédito da revendedora
   */
  willConsumeCredit: boolean;
  /**
   * Indica se ela deve ter saldo de crédito para poder comprar
   */
  shouldVerifyCredit: boolean;
  /**
   * Indica se está bloqueado
   */
  isBlocked: boolean;
  /**
   * Taxa de administração
   */
  tax: number;
  /**
   * Número de parcelas (para planos sem cartão crédito)
   */
  installments: number | null;
  /**
   * Identificador do modo de pagamento
   */
  paymentMode: ChangeProductQuantity_ChangeProductQuantity_paymentPlans_paymentMode | null;
  /**
   * Opções quando pagamento é por cartão de crédito
   */
  creditCardOptions: ChangeProductQuantity_ChangeProductQuantity_paymentPlans_creditCardOptions[] | null;
  /**
   * Parcelas do plano selecionado
   */
  selectedInstallments: ChangeProductQuantity_ChangeProductQuantity_paymentPlans_selectedInstallments[] | null;
  /**
   * Indica se é múltiplas formas de pagamento
   */
  isMultiplePayment: boolean;
}

export interface ChangeProductQuantity_ChangeProductQuantity {
  __typename: "CartResponse";
  /**
   * Código do pedido
   */
  orderCode: string;
  /**
   * Código do espaço revendedor
   */
  distributionCenterCode: string;
  /**
   * Modo de entrega (retirar na central / entrega em endereço)
   */
  pickupOnDistributionCenter: boolean | null;
  /**
   * Valor do pedido mínimo
   */
  requiredMinimum: number;
  /**
   * Valor atual considerado para o pedido mínimo
   */
  currentValueConsideredToMinimum: number;
  /**
   * Ciclo do pedido
   */
  cycle: number;
  /**
   * SubCiclo do pedido
   */
  subCycle: number;
  /**
   * Dados do link externo de pagamento
   */
  externalPaymentLink: ChangeProductQuantity_ChangeProductQuantity_externalPaymentLink | null;
  /**
   * Crédito disponível
   */
  credit: ChangeProductQuantity_ChangeProductQuantity_credit | null;
  /**
   * Produtos do pedido
   */
  products: ChangeProductQuantity_ChangeProductQuantity_products[];
  /**
   * Produtos cortados
   */
  cutProducts: ChangeProductQuantity_ChangeProductQuantity_cutProducts[];
  /**
   * Produto (brinde) conquistado
   */
  acquiredProducts: ChangeProductQuantity_ChangeProductQuantity_acquiredProducts[] | null;
  /**
   * Promoção conquistada
   */
  acquiredPromotions: ChangeProductQuantity_ChangeProductQuantity_acquiredPromotions[] | null;
  /**
   * Promoção conquistada
   */
  partialPromotions: ChangeProductQuantity_ChangeProductQuantity_partialPromotions[] | null;
  /**
   * Opção a ser escolhida na promoção (pode ser brinde ou desconto)
   */
  rewardsToChoose: ChangeProductQuantity_ChangeProductQuantity_rewardsToChoose[] | null;
  /**
   * Endereço escolhido (ou padrão) para a entrega
   */
  chosenAddress: ChangeProductQuantity_ChangeProductQuantity_chosenAddress | null;
  /**
   * Opção de frete escolhida (ou padrão)
   */
  chosenFreightOption: ChangeProductQuantity_ChangeProductQuantity_chosenFreightOption | null;
  /**
   * Opção de pagamento escolhida (ou padrão)
   */
  chosenPaymentPlan: ChangeProductQuantity_ChangeProductQuantity_chosenPaymentPlan | null;
  /**
   * Resumo de valores do pedido
   */
  totals: ChangeProductQuantity_ChangeProductQuantity_totals;
  /**
   * Endereços para entrega
   */
  deliveryAddresses: ChangeProductQuantity_ChangeProductQuantity_deliveryAddresses[] | null;
  /**
   * Planos de pagamento
   */
  paymentPlans: ChangeProductQuantity_ChangeProductQuantity_paymentPlans[] | null;
}

export interface ChangeProductQuantity {
  /**
   * # Mutation - ChangeProductQuantity
   * ## Description:
   * 
   * 
   *  - Altera a quantidade de um produto no pedido da revendedora
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## External Dependencies:
   * 
   * 
   *  - ### ManageOrderDatasource
   *     
   * 
   * 	
   * 	 - Endpoint Path: /cart/orders/${orderCode}/Manage
   * ## Internal Dependencies:
   * 
   * 
   *  - ### ChangeProductQuantityUseCase
   *     
   * 
   * 
   *  - ### ManageOrderDatasource
   */
  ChangeProductQuantity: ChangeProductQuantity_ChangeProductQuantity;
}

export interface ChangeProductQuantityVariables {
  data: ChangeProductQuantityInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ChooseFreightOption
// ====================================================

export interface ChooseFreightOption_ChooseFreightOption_externalPaymentLink {
  __typename: "NewPaymentLinkResponse";
  /**
   * Url de pagamento
   */
  externalPaymentLink: string | null;
  /**
   * Data de expiração da url de pagamento
   */
  expirationDatePaymentLink: any | null;
}

export interface ChooseFreightOption_ChooseFreightOption_credit {
  __typename: "ResellerCreditResponse";
  /**
   * Crédito disponível para compra no momento
   */
  available: number | null;
}

export interface ChooseFreightOption_ChooseFreightOption_products_origin {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ChooseFreightOption_ChooseFreightOption_products {
  __typename: "CartProductType";
  /**
   * Número representativo do produto no carrinho Gera
   */
  sequentialNumber: string;
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * URL da imagem do produto
   */
  imageUrl: string;
  /**
   * Quantidade
   */
  quantity: number;
  /**
   * Preço de mercado (consumidor)
   */
  unitMarketValue: number;
  /**
   * Preço de tabela (consumidor)
   */
  unitTableValue: number;
  /**
   * Preço promocionado (consumidor)
   */
  unitNetValue: number | null;
  /**
   * Origem do produto no pedido
   */
  origin: ChooseFreightOption_ChooseFreightOption_products_origin;
  /**
   * Bloqueado para edição?
   */
  blockEdit: boolean;
  /**
   * Bloqueado para remoção?
   */
  blockDelete: boolean;
  /**
   * Valor unitário de compra
   */
  unitPayValue: number;
  /**
   * Valor de compra
   */
  payValue: number;
  /**
   * Lucro
   */
  profit: number;
  /**
   * Lucro com descontos
   */
  profitWithDiscount: number;
  /**
   * Se o produto é um combo personalizável
   */
  isCombo: boolean;
}

export interface ChooseFreightOption_ChooseFreightOption_cutProducts_type {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ChooseFreightOption_ChooseFreightOption_cutProducts_cutReason {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ChooseFreightOption_ChooseFreightOption_cutProducts {
  __typename: "CartCutProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * URL da imagem do produto
   */
  imageUrl: string;
  /**
   * Quantidade
   */
  quantity: number;
  /**
   * Preço de mercado (consumidor)
   */
  unitMarketValue: number;
  /**
   * Preço de tabela (consumidor)
   */
  unitTableValue: number;
  /**
   * Preço promocionado (consumidor)
   */
  unitNetValue: number | null;
  /**
   * Tipo do item
   */
  type: ChooseFreightOption_ChooseFreightOption_cutProducts_type;
  /**
   * Razão do corte
   */
  cutReason: ChooseFreightOption_ChooseFreightOption_cutProducts_cutReason;
}

export interface ChooseFreightOption_ChooseFreightOption_acquiredProducts_promotion {
  __typename: "CartPromotionType";
  /**
   * Código
   */
  code: string;
  /**
   * Título
   */
  title: string;
}

export interface ChooseFreightOption_ChooseFreightOption_acquiredProducts {
  __typename: "AcquiredProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * URL da imagem
   */
  imageUrl: string;
  /**
   * Quantidade
   */
  quantity: number | null;
  /**
   * Promoção que originou o brinde
   */
  promotion: ChooseFreightOption_ChooseFreightOption_acquiredProducts_promotion | null;
}

export interface ChooseFreightOption_ChooseFreightOption_acquiredPromotions_destination {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ChooseFreightOption_ChooseFreightOption_acquiredPromotions_rewards {
  __typename: "RewardProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Quantidade
   */
  quantity: number | null;
  /**
   * URL da imagem
   */
  imageUrl: string;
  /**
   * Desconto
   */
  discount: number;
  /**
   * Tipo
   */
  type: RewardTypeEnum;
  /**
   * Descrição
   */
  description: string | null;
  /**
   * Indica se é um brinde a ser escolhido
   */
  isGiftToChoose: boolean;
}

export interface ChooseFreightOption_ChooseFreightOption_acquiredPromotions {
  __typename: "AcquiredPromotionType";
  /**
   * Código da promoção
   */
  code: string;
  /**
   * Nome
   */
  title: string;
  /**
   * Descrição
   */
  description: string | null;
  /**
   * Destino
   */
  destination: ChooseFreightOption_ChooseFreightOption_acquiredPromotions_destination;
  /**
   * Indica se é promoção de cupom de desconto
   */
  isCupomPromotion: boolean;
  /**
   * Lista de brindes/descontos
   */
  rewards: ChooseFreightOption_ChooseFreightOption_acquiredPromotions_rewards[];
}

export interface ChooseFreightOption_ChooseFreightOption_partialPromotions_destination {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ChooseFreightOption_ChooseFreightOption_partialPromotions_requirements_type {
  __typename: "RequirementType";
  /**
   * Id do tipo de requisito
   */
  id: number | null;
  /**
   * Descrição do requisito
   */
  description: string | null;
}

export interface ChooseFreightOption_ChooseFreightOption_partialPromotions_requirements_productRequired {
  __typename: "RequiredProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome do produto
   */
  name: string;
  /**
   * Descrição do produto
   */
  description: string | null;
  /**
   * Indica se é um kit
   */
  isKit: boolean | null;
  /**
   * Quantidade de pontos
   */
  points: number | null;
  /**
   * Preço
   */
  price: number | null;
}

export interface ChooseFreightOption_ChooseFreightOption_partialPromotions_requirements_structureRequired {
  __typename: "RequiredStructureType";
  /**
   * Código da estrutura
   */
  code: string;
  /**
   * Nome da estrutura
   */
  name: string;
}

export interface ChooseFreightOption_ChooseFreightOption_partialPromotions_requirements {
  __typename: "PromotionRequirementType";
  /**
   * Valor faltante para obter a promoção
   */
  missingValue: number;
  /**
   * Tipo do requisito
   */
  type: ChooseFreightOption_ChooseFreightOption_partialPromotions_requirements_type;
  /**
   * Produto requisitado
   */
  productRequired: ChooseFreightOption_ChooseFreightOption_partialPromotions_requirements_productRequired | null;
  /**
   * Estrutura na qual a compra deve ser feita
   */
  structureRequired: ChooseFreightOption_ChooseFreightOption_partialPromotions_requirements_structureRequired | null;
  /**
   * Descrição
   */
  description: string | null;
}

export interface ChooseFreightOption_ChooseFreightOption_partialPromotions {
  __typename: "PartialPromotionType";
  /**
   * Código da promoção
   */
  code: string;
  /**
   * Nome
   */
  title: string;
  /**
   * Descrição
   */
  description: string | null;
  /**
   * Destino
   */
  destination: ChooseFreightOption_ChooseFreightOption_partialPromotions_destination;
  /**
   * Indica se é promoção de cupom de desconto
   */
  isCupomPromotion: boolean;
  /**
   * Lista de requisitos para conquistar a promoção
   */
  requirements: ChooseFreightOption_ChooseFreightOption_partialPromotions_requirements[];
}

export interface ChooseFreightOption_ChooseFreightOption_rewardsToChoose_destination {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ChooseFreightOption_ChooseFreightOption_rewardsToChoose_productsToChoose {
  __typename: "RewardProductToChooseType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Url da imagem
   */
  imageUrl: string;
  /**
   * Quantidade escolhida
   */
  chosenQuantity: number | null;
  /**
   * Quantidade máxima a ser selecionada
   */
  maxQuantity: number;
  /**
   * Indica se está indisponível (só atualiza depois de escolhido)
   */
  unavailable: boolean;
  /**
   * ?
   */
  isReorderAllowed: boolean;
}

export interface ChooseFreightOption_ChooseFreightOption_rewardsToChoose_discountsToChoose {
  __typename: "RewardDiscountToChooseType";
  /**
   * Código da opção de desconto
   */
  code: string;
  /**
   * Valor do desconto (unidade é de acordo com o tipo do desconto)
   */
  value: number;
  /**
   * Descrição
   */
  auxiliaryDescription: string | null;
  /**
   * Tipo da conquista
   */
  rewardType: RewardTypeEnum;
}

export interface ChooseFreightOption_ChooseFreightOption_rewardsToChoose {
  __typename: "RewardToChooseType";
  /**
   * Código da promoção (pode repetir)
   */
  code: string;
  /**
   * Título da promoção
   */
  title: string;
  /**
   * Indica se escolheu produtos
   */
  hasChosenProducts: boolean;
  /**
   * Indica se escolheu a opção de brindes
   */
  hasChosenDiscount: boolean;
  /**
   * Número sequencial (diferenciador das opções)
   */
  giftNumber: string;
  /**
   * Número sequencial (diferenciador dos itens)
   */
  giftItemNumber: string;
  /**
   * Número máximo de brindes a serem escolhidos
   */
  maxGiftsToChoose: number;
  /**
   * Descrição
   */
  description: string | null;
  /**
   * Destino
   */
  destination: ChooseFreightOption_ChooseFreightOption_rewardsToChoose_destination;
  /**
   * Indica se é promoção de cupom
   */
  isCupomPromotion: boolean;
  /**
   * Produto (brinde) a ser escolhido na promoção
   */
  productsToChoose: ChooseFreightOption_ChooseFreightOption_rewardsToChoose_productsToChoose[] | null;
  /**
   * Detalhes de uma opção de desconto em uma promoção
   */
  discountsToChoose: ChooseFreightOption_ChooseFreightOption_rewardsToChoose_discountsToChoose[] | null;
}

export interface ChooseFreightOption_ChooseFreightOption_chosenAddress_type {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ChooseFreightOption_ChooseFreightOption_chosenAddress_address {
  __typename: "RegistrationAddressType";
  /**
   * Logradouro
   */
  streetName: string | null;
  /**
   * Número
   */
  addressNumber: string | null;
  /**
   * CEP
   */
  cep: string | null;
  /**
   * Bairro
   */
  neighborhood: string | null;
  /**
   * Complemento
   */
  complement: string | null;
  /**
   * Cidade
   */
  city: string | null;
  /**
   * Estado
   */
  state: string | null;
  /**
   * País
   */
  country: string | null;
}

export interface ChooseFreightOption_ChooseFreightOption_chosenAddress {
  __typename: "CartDeliveryAddressType";
  /**
   * Tipo do endereço
   */
  type: ChooseFreightOption_ChooseFreightOption_chosenAddress_type;
  /**
   * Endereço em formato de objeto
   */
  address: ChooseFreightOption_ChooseFreightOption_chosenAddress_address;
  /**
   * Endereço formatado
   */
  formattedAddress: string;
  /**
   * CEP
   */
  cep: string;
}

export interface ChooseFreightOption_ChooseFreightOption_chosenFreightOption_type {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ChooseFreightOption_ChooseFreightOption_chosenFreightOption {
  __typename: "FreightOptionResponse";
  /**
   * Código da opção de frete
   */
  code: string;
  /**
   * Nome da opção de frete
   */
  name: string;
  /**
   * Tipo
   */
  type: ChooseFreightOption_ChooseFreightOption_chosenFreightOption_type;
  /**
   * Valor da entrega
   */
  price: number;
  /**
   * Dias úteis para a entrega
   */
  deliveryUtilDays: number;
}

export interface ChooseFreightOption_ChooseFreightOption_chosenPaymentPlan_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ChooseFreightOption_ChooseFreightOption_chosenPaymentPlan_creditCardOptions_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ChooseFreightOption_ChooseFreightOption_chosenPaymentPlan_creditCardOptions {
  __typename: "CreditCardOptionType";
  /**
   * Descrição da bandeira do cartão de crédito
   */
  type: string;
  /**
   * Identificador da bandeira do cartão de crédito
   */
  flag: string;
  /**
   * Modo de pagamento para o cartão especificado
   */
  paymentMode: ChooseFreightOption_ChooseFreightOption_chosenPaymentPlan_creditCardOptions_paymentMode;
  /**
   * Parcelas possíveis de serem escolhidas
   */
  possibleNumberOfInstallments: number[] | null;
}

export interface ChooseFreightOption_ChooseFreightOption_chosenPaymentPlan_selectedInstallments {
  __typename: "InstallmentType";
  /**
   * Número da parcela
   */
  number: number;
  /**
   * Valor da parcela
   */
  value: number;
  /**
   * Data de vencimento
   */
  dueDate: any;
  /**
   * Indica se está bloqueada
   */
  isBlocked: boolean;
}

export interface ChooseFreightOption_ChooseFreightOption_chosenPaymentPlan {
  __typename: "PaymentPlanResponse";
  /**
   * Código do plano de pagamento
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Indica se consumirá crédito da revendedora
   */
  willConsumeCredit: boolean;
  /**
   * Indica se ela deve ter saldo de crédito para poder comprar
   */
  shouldVerifyCredit: boolean;
  /**
   * Indica se está bloqueado
   */
  isBlocked: boolean;
  /**
   * Taxa de administração
   */
  tax: number;
  /**
   * Número de parcelas (para planos sem cartão crédito)
   */
  installments: number | null;
  /**
   * Identificador do modo de pagamento
   */
  paymentMode: ChooseFreightOption_ChooseFreightOption_chosenPaymentPlan_paymentMode | null;
  /**
   * Opções quando pagamento é por cartão de crédito
   */
  creditCardOptions: ChooseFreightOption_ChooseFreightOption_chosenPaymentPlan_creditCardOptions[] | null;
  /**
   * Parcelas do plano selecionado
   */
  selectedInstallments: ChooseFreightOption_ChooseFreightOption_chosenPaymentPlan_selectedInstallments[] | null;
  /**
   * Indica se é múltiplas formas de pagamento
   */
  isMultiplePayment: boolean;
}

export interface ChooseFreightOption_ChooseFreightOption_totals {
  __typename: "CartTotalsType";
  /**
   * Total de venda
   */
  totalSell: number | null;
  /**
   * Lucro
   */
  profit: number | null;
  /**
   * Frete
   */
  deliveryTax: number | null;
  /**
   * Subtotal
   */
  subtotalPay: number | null;
  /**
   * Total
   */
  totalPay: number | null;
  /**
   * Lucro vindo dos brindes
   */
  profitFromGifts: number | null;
  /**
   * Valor de crédito utilizado da CCR (Conta Corrente Residual)
   */
  checkingAccount: number | null;
  /**
   * Taxa administrativa
   */
  collectionFee: number | null;
  /**
   * Impostos
   */
  taxes: number | null;
  /**
   * Comissão
   */
  commission: number | null;
}

export interface ChooseFreightOption_ChooseFreightOption_deliveryAddresses_type {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ChooseFreightOption_ChooseFreightOption_deliveryAddresses_address {
  __typename: "RegistrationAddressType";
  /**
   * Logradouro
   */
  streetName: string | null;
  /**
   * Número
   */
  addressNumber: string | null;
  /**
   * CEP
   */
  cep: string | null;
  /**
   * Bairro
   */
  neighborhood: string | null;
  /**
   * Complemento
   */
  complement: string | null;
  /**
   * Cidade
   */
  city: string | null;
  /**
   * Estado
   */
  state: string | null;
  /**
   * País
   */
  country: string | null;
}

export interface ChooseFreightOption_ChooseFreightOption_deliveryAddresses {
  __typename: "CartDeliveryAddressType";
  /**
   * Tipo do endereço
   */
  type: ChooseFreightOption_ChooseFreightOption_deliveryAddresses_type;
  /**
   * Endereço em formato de objeto
   */
  address: ChooseFreightOption_ChooseFreightOption_deliveryAddresses_address;
  /**
   * Endereço formatado
   */
  formattedAddress: string;
  /**
   * CEP
   */
  cep: string;
}

export interface ChooseFreightOption_ChooseFreightOption_paymentPlans_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ChooseFreightOption_ChooseFreightOption_paymentPlans_creditCardOptions_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ChooseFreightOption_ChooseFreightOption_paymentPlans_creditCardOptions {
  __typename: "CreditCardOptionType";
  /**
   * Descrição da bandeira do cartão de crédito
   */
  type: string;
  /**
   * Identificador da bandeira do cartão de crédito
   */
  flag: string;
  /**
   * Modo de pagamento para o cartão especificado
   */
  paymentMode: ChooseFreightOption_ChooseFreightOption_paymentPlans_creditCardOptions_paymentMode;
  /**
   * Parcelas possíveis de serem escolhidas
   */
  possibleNumberOfInstallments: number[] | null;
}

export interface ChooseFreightOption_ChooseFreightOption_paymentPlans_selectedInstallments {
  __typename: "InstallmentType";
  /**
   * Número da parcela
   */
  number: number;
  /**
   * Valor da parcela
   */
  value: number;
  /**
   * Data de vencimento
   */
  dueDate: any;
  /**
   * Indica se está bloqueada
   */
  isBlocked: boolean;
}

export interface ChooseFreightOption_ChooseFreightOption_paymentPlans {
  __typename: "PaymentPlanResponse";
  /**
   * Código do plano de pagamento
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Indica se consumirá crédito da revendedora
   */
  willConsumeCredit: boolean;
  /**
   * Indica se ela deve ter saldo de crédito para poder comprar
   */
  shouldVerifyCredit: boolean;
  /**
   * Indica se está bloqueado
   */
  isBlocked: boolean;
  /**
   * Taxa de administração
   */
  tax: number;
  /**
   * Número de parcelas (para planos sem cartão crédito)
   */
  installments: number | null;
  /**
   * Identificador do modo de pagamento
   */
  paymentMode: ChooseFreightOption_ChooseFreightOption_paymentPlans_paymentMode | null;
  /**
   * Opções quando pagamento é por cartão de crédito
   */
  creditCardOptions: ChooseFreightOption_ChooseFreightOption_paymentPlans_creditCardOptions[] | null;
  /**
   * Parcelas do plano selecionado
   */
  selectedInstallments: ChooseFreightOption_ChooseFreightOption_paymentPlans_selectedInstallments[] | null;
  /**
   * Indica se é múltiplas formas de pagamento
   */
  isMultiplePayment: boolean;
}

export interface ChooseFreightOption_ChooseFreightOption {
  __typename: "CartResponse";
  /**
   * Código do pedido
   */
  orderCode: string;
  /**
   * Código do espaço revendedor
   */
  distributionCenterCode: string;
  /**
   * Modo de entrega (retirar na central / entrega em endereço)
   */
  pickupOnDistributionCenter: boolean | null;
  /**
   * Valor do pedido mínimo
   */
  requiredMinimum: number;
  /**
   * Valor atual considerado para o pedido mínimo
   */
  currentValueConsideredToMinimum: number;
  /**
   * Ciclo do pedido
   */
  cycle: number;
  /**
   * SubCiclo do pedido
   */
  subCycle: number;
  /**
   * Dados do link externo de pagamento
   */
  externalPaymentLink: ChooseFreightOption_ChooseFreightOption_externalPaymentLink | null;
  /**
   * Crédito disponível
   */
  credit: ChooseFreightOption_ChooseFreightOption_credit | null;
  /**
   * Produtos do pedido
   */
  products: ChooseFreightOption_ChooseFreightOption_products[];
  /**
   * Produtos cortados
   */
  cutProducts: ChooseFreightOption_ChooseFreightOption_cutProducts[];
  /**
   * Produto (brinde) conquistado
   */
  acquiredProducts: ChooseFreightOption_ChooseFreightOption_acquiredProducts[] | null;
  /**
   * Promoção conquistada
   */
  acquiredPromotions: ChooseFreightOption_ChooseFreightOption_acquiredPromotions[] | null;
  /**
   * Promoção conquistada
   */
  partialPromotions: ChooseFreightOption_ChooseFreightOption_partialPromotions[] | null;
  /**
   * Opção a ser escolhida na promoção (pode ser brinde ou desconto)
   */
  rewardsToChoose: ChooseFreightOption_ChooseFreightOption_rewardsToChoose[] | null;
  /**
   * Endereço escolhido (ou padrão) para a entrega
   */
  chosenAddress: ChooseFreightOption_ChooseFreightOption_chosenAddress | null;
  /**
   * Opção de frete escolhida (ou padrão)
   */
  chosenFreightOption: ChooseFreightOption_ChooseFreightOption_chosenFreightOption | null;
  /**
   * Opção de pagamento escolhida (ou padrão)
   */
  chosenPaymentPlan: ChooseFreightOption_ChooseFreightOption_chosenPaymentPlan | null;
  /**
   * Resumo de valores do pedido
   */
  totals: ChooseFreightOption_ChooseFreightOption_totals;
  /**
   * Endereços para entrega
   */
  deliveryAddresses: ChooseFreightOption_ChooseFreightOption_deliveryAddresses[] | null;
  /**
   * Planos de pagamento
   */
  paymentPlans: ChooseFreightOption_ChooseFreightOption_paymentPlans[] | null;
}

export interface ChooseFreightOption {
  /**
   * # Mutation - ChooseFreightOption
   * ## Description:
   * 
   * 
   *  - Define o tipo de entrega
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## External Dependencies:
   * 
   * 
   *  - ### ManageOrderDatasource
   *     
   * 
   * 	
   * 	 - Endpoint Path: /cart/orders/${orderCode}/Manage
   * ## Internal Dependencies:
   * 
   * 
   *  - ### ChooseFreightOptionUseCase
   *     
   * 
   * 
   *  - ### ManageOrderDatasource
   */
  ChooseFreightOption: ChooseFreightOption_ChooseFreightOption;
}

export interface ChooseFreightOptionVariables {
  data: ChooseFreightOptionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ChooseGift
// ====================================================

export interface ChooseGift_ChooseGift_externalPaymentLink {
  __typename: "NewPaymentLinkResponse";
  /**
   * Url de pagamento
   */
  externalPaymentLink: string | null;
  /**
   * Data de expiração da url de pagamento
   */
  expirationDatePaymentLink: any | null;
}

export interface ChooseGift_ChooseGift_credit {
  __typename: "ResellerCreditResponse";
  /**
   * Crédito disponível para compra no momento
   */
  available: number | null;
}

export interface ChooseGift_ChooseGift_products_origin {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ChooseGift_ChooseGift_products {
  __typename: "CartProductType";
  /**
   * Número representativo do produto no carrinho Gera
   */
  sequentialNumber: string;
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * URL da imagem do produto
   */
  imageUrl: string;
  /**
   * Quantidade
   */
  quantity: number;
  /**
   * Preço de mercado (consumidor)
   */
  unitMarketValue: number;
  /**
   * Preço de tabela (consumidor)
   */
  unitTableValue: number;
  /**
   * Preço promocionado (consumidor)
   */
  unitNetValue: number | null;
  /**
   * Origem do produto no pedido
   */
  origin: ChooseGift_ChooseGift_products_origin;
  /**
   * Bloqueado para edição?
   */
  blockEdit: boolean;
  /**
   * Bloqueado para remoção?
   */
  blockDelete: boolean;
  /**
   * Valor unitário de compra
   */
  unitPayValue: number;
  /**
   * Valor de compra
   */
  payValue: number;
  /**
   * Lucro
   */
  profit: number;
  /**
   * Lucro com descontos
   */
  profitWithDiscount: number;
  /**
   * Se o produto é um combo personalizável
   */
  isCombo: boolean;
}

export interface ChooseGift_ChooseGift_cutProducts_type {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ChooseGift_ChooseGift_cutProducts_cutReason {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ChooseGift_ChooseGift_cutProducts {
  __typename: "CartCutProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * URL da imagem do produto
   */
  imageUrl: string;
  /**
   * Quantidade
   */
  quantity: number;
  /**
   * Preço de mercado (consumidor)
   */
  unitMarketValue: number;
  /**
   * Preço de tabela (consumidor)
   */
  unitTableValue: number;
  /**
   * Preço promocionado (consumidor)
   */
  unitNetValue: number | null;
  /**
   * Tipo do item
   */
  type: ChooseGift_ChooseGift_cutProducts_type;
  /**
   * Razão do corte
   */
  cutReason: ChooseGift_ChooseGift_cutProducts_cutReason;
}

export interface ChooseGift_ChooseGift_acquiredProducts_promotion {
  __typename: "CartPromotionType";
  /**
   * Código
   */
  code: string;
  /**
   * Título
   */
  title: string;
}

export interface ChooseGift_ChooseGift_acquiredProducts {
  __typename: "AcquiredProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * URL da imagem
   */
  imageUrl: string;
  /**
   * Quantidade
   */
  quantity: number | null;
  /**
   * Promoção que originou o brinde
   */
  promotion: ChooseGift_ChooseGift_acquiredProducts_promotion | null;
}

export interface ChooseGift_ChooseGift_acquiredPromotions_destination {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ChooseGift_ChooseGift_acquiredPromotions_rewards {
  __typename: "RewardProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Quantidade
   */
  quantity: number | null;
  /**
   * URL da imagem
   */
  imageUrl: string;
  /**
   * Desconto
   */
  discount: number;
  /**
   * Tipo
   */
  type: RewardTypeEnum;
  /**
   * Descrição
   */
  description: string | null;
  /**
   * Indica se é um brinde a ser escolhido
   */
  isGiftToChoose: boolean;
}

export interface ChooseGift_ChooseGift_acquiredPromotions {
  __typename: "AcquiredPromotionType";
  /**
   * Código da promoção
   */
  code: string;
  /**
   * Nome
   */
  title: string;
  /**
   * Descrição
   */
  description: string | null;
  /**
   * Destino
   */
  destination: ChooseGift_ChooseGift_acquiredPromotions_destination;
  /**
   * Indica se é promoção de cupom de desconto
   */
  isCupomPromotion: boolean;
  /**
   * Lista de brindes/descontos
   */
  rewards: ChooseGift_ChooseGift_acquiredPromotions_rewards[];
}

export interface ChooseGift_ChooseGift_partialPromotions_destination {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ChooseGift_ChooseGift_partialPromotions_requirements_type {
  __typename: "RequirementType";
  /**
   * Id do tipo de requisito
   */
  id: number | null;
  /**
   * Descrição do requisito
   */
  description: string | null;
}

export interface ChooseGift_ChooseGift_partialPromotions_requirements_productRequired {
  __typename: "RequiredProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome do produto
   */
  name: string;
  /**
   * Descrição do produto
   */
  description: string | null;
  /**
   * Indica se é um kit
   */
  isKit: boolean | null;
  /**
   * Quantidade de pontos
   */
  points: number | null;
  /**
   * Preço
   */
  price: number | null;
}

export interface ChooseGift_ChooseGift_partialPromotions_requirements_structureRequired {
  __typename: "RequiredStructureType";
  /**
   * Código da estrutura
   */
  code: string;
  /**
   * Nome da estrutura
   */
  name: string;
}

export interface ChooseGift_ChooseGift_partialPromotions_requirements {
  __typename: "PromotionRequirementType";
  /**
   * Valor faltante para obter a promoção
   */
  missingValue: number;
  /**
   * Tipo do requisito
   */
  type: ChooseGift_ChooseGift_partialPromotions_requirements_type;
  /**
   * Produto requisitado
   */
  productRequired: ChooseGift_ChooseGift_partialPromotions_requirements_productRequired | null;
  /**
   * Estrutura na qual a compra deve ser feita
   */
  structureRequired: ChooseGift_ChooseGift_partialPromotions_requirements_structureRequired | null;
  /**
   * Descrição
   */
  description: string | null;
}

export interface ChooseGift_ChooseGift_partialPromotions {
  __typename: "PartialPromotionType";
  /**
   * Código da promoção
   */
  code: string;
  /**
   * Nome
   */
  title: string;
  /**
   * Descrição
   */
  description: string | null;
  /**
   * Destino
   */
  destination: ChooseGift_ChooseGift_partialPromotions_destination;
  /**
   * Indica se é promoção de cupom de desconto
   */
  isCupomPromotion: boolean;
  /**
   * Lista de requisitos para conquistar a promoção
   */
  requirements: ChooseGift_ChooseGift_partialPromotions_requirements[];
}

export interface ChooseGift_ChooseGift_rewardsToChoose_destination {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ChooseGift_ChooseGift_rewardsToChoose_productsToChoose {
  __typename: "RewardProductToChooseType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Url da imagem
   */
  imageUrl: string;
  /**
   * Quantidade escolhida
   */
  chosenQuantity: number | null;
  /**
   * Quantidade máxima a ser selecionada
   */
  maxQuantity: number;
  /**
   * Indica se está indisponível (só atualiza depois de escolhido)
   */
  unavailable: boolean;
  /**
   * ?
   */
  isReorderAllowed: boolean;
}

export interface ChooseGift_ChooseGift_rewardsToChoose_discountsToChoose {
  __typename: "RewardDiscountToChooseType";
  /**
   * Código da opção de desconto
   */
  code: string;
  /**
   * Valor do desconto (unidade é de acordo com o tipo do desconto)
   */
  value: number;
  /**
   * Descrição
   */
  auxiliaryDescription: string | null;
  /**
   * Tipo da conquista
   */
  rewardType: RewardTypeEnum;
}

export interface ChooseGift_ChooseGift_rewardsToChoose {
  __typename: "RewardToChooseType";
  /**
   * Código da promoção (pode repetir)
   */
  code: string;
  /**
   * Título da promoção
   */
  title: string;
  /**
   * Indica se escolheu produtos
   */
  hasChosenProducts: boolean;
  /**
   * Indica se escolheu a opção de brindes
   */
  hasChosenDiscount: boolean;
  /**
   * Número sequencial (diferenciador das opções)
   */
  giftNumber: string;
  /**
   * Número sequencial (diferenciador dos itens)
   */
  giftItemNumber: string;
  /**
   * Número máximo de brindes a serem escolhidos
   */
  maxGiftsToChoose: number;
  /**
   * Descrição
   */
  description: string | null;
  /**
   * Destino
   */
  destination: ChooseGift_ChooseGift_rewardsToChoose_destination;
  /**
   * Indica se é promoção de cupom
   */
  isCupomPromotion: boolean;
  /**
   * Produto (brinde) a ser escolhido na promoção
   */
  productsToChoose: ChooseGift_ChooseGift_rewardsToChoose_productsToChoose[] | null;
  /**
   * Detalhes de uma opção de desconto em uma promoção
   */
  discountsToChoose: ChooseGift_ChooseGift_rewardsToChoose_discountsToChoose[] | null;
}

export interface ChooseGift_ChooseGift_chosenAddress_type {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ChooseGift_ChooseGift_chosenAddress_address {
  __typename: "RegistrationAddressType";
  /**
   * Logradouro
   */
  streetName: string | null;
  /**
   * Número
   */
  addressNumber: string | null;
  /**
   * CEP
   */
  cep: string | null;
  /**
   * Bairro
   */
  neighborhood: string | null;
  /**
   * Complemento
   */
  complement: string | null;
  /**
   * Cidade
   */
  city: string | null;
  /**
   * Estado
   */
  state: string | null;
  /**
   * País
   */
  country: string | null;
}

export interface ChooseGift_ChooseGift_chosenAddress {
  __typename: "CartDeliveryAddressType";
  /**
   * Tipo do endereço
   */
  type: ChooseGift_ChooseGift_chosenAddress_type;
  /**
   * Endereço em formato de objeto
   */
  address: ChooseGift_ChooseGift_chosenAddress_address;
  /**
   * Endereço formatado
   */
  formattedAddress: string;
  /**
   * CEP
   */
  cep: string;
}

export interface ChooseGift_ChooseGift_chosenFreightOption_type {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ChooseGift_ChooseGift_chosenFreightOption {
  __typename: "FreightOptionResponse";
  /**
   * Código da opção de frete
   */
  code: string;
  /**
   * Nome da opção de frete
   */
  name: string;
  /**
   * Tipo
   */
  type: ChooseGift_ChooseGift_chosenFreightOption_type;
  /**
   * Valor da entrega
   */
  price: number;
  /**
   * Dias úteis para a entrega
   */
  deliveryUtilDays: number;
}

export interface ChooseGift_ChooseGift_chosenPaymentPlan_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ChooseGift_ChooseGift_chosenPaymentPlan_creditCardOptions_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ChooseGift_ChooseGift_chosenPaymentPlan_creditCardOptions {
  __typename: "CreditCardOptionType";
  /**
   * Descrição da bandeira do cartão de crédito
   */
  type: string;
  /**
   * Identificador da bandeira do cartão de crédito
   */
  flag: string;
  /**
   * Modo de pagamento para o cartão especificado
   */
  paymentMode: ChooseGift_ChooseGift_chosenPaymentPlan_creditCardOptions_paymentMode;
  /**
   * Parcelas possíveis de serem escolhidas
   */
  possibleNumberOfInstallments: number[] | null;
}

export interface ChooseGift_ChooseGift_chosenPaymentPlan_selectedInstallments {
  __typename: "InstallmentType";
  /**
   * Número da parcela
   */
  number: number;
  /**
   * Valor da parcela
   */
  value: number;
  /**
   * Data de vencimento
   */
  dueDate: any;
  /**
   * Indica se está bloqueada
   */
  isBlocked: boolean;
}

export interface ChooseGift_ChooseGift_chosenPaymentPlan {
  __typename: "PaymentPlanResponse";
  /**
   * Código do plano de pagamento
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Indica se consumirá crédito da revendedora
   */
  willConsumeCredit: boolean;
  /**
   * Indica se ela deve ter saldo de crédito para poder comprar
   */
  shouldVerifyCredit: boolean;
  /**
   * Indica se está bloqueado
   */
  isBlocked: boolean;
  /**
   * Taxa de administração
   */
  tax: number;
  /**
   * Número de parcelas (para planos sem cartão crédito)
   */
  installments: number | null;
  /**
   * Identificador do modo de pagamento
   */
  paymentMode: ChooseGift_ChooseGift_chosenPaymentPlan_paymentMode | null;
  /**
   * Opções quando pagamento é por cartão de crédito
   */
  creditCardOptions: ChooseGift_ChooseGift_chosenPaymentPlan_creditCardOptions[] | null;
  /**
   * Parcelas do plano selecionado
   */
  selectedInstallments: ChooseGift_ChooseGift_chosenPaymentPlan_selectedInstallments[] | null;
  /**
   * Indica se é múltiplas formas de pagamento
   */
  isMultiplePayment: boolean;
}

export interface ChooseGift_ChooseGift_totals {
  __typename: "CartTotalsType";
  /**
   * Total de venda
   */
  totalSell: number | null;
  /**
   * Lucro
   */
  profit: number | null;
  /**
   * Frete
   */
  deliveryTax: number | null;
  /**
   * Subtotal
   */
  subtotalPay: number | null;
  /**
   * Total
   */
  totalPay: number | null;
  /**
   * Lucro vindo dos brindes
   */
  profitFromGifts: number | null;
  /**
   * Valor de crédito utilizado da CCR (Conta Corrente Residual)
   */
  checkingAccount: number | null;
  /**
   * Taxa administrativa
   */
  collectionFee: number | null;
  /**
   * Impostos
   */
  taxes: number | null;
  /**
   * Comissão
   */
  commission: number | null;
}

export interface ChooseGift_ChooseGift_deliveryAddresses_type {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ChooseGift_ChooseGift_deliveryAddresses_address {
  __typename: "RegistrationAddressType";
  /**
   * Logradouro
   */
  streetName: string | null;
  /**
   * Número
   */
  addressNumber: string | null;
  /**
   * CEP
   */
  cep: string | null;
  /**
   * Bairro
   */
  neighborhood: string | null;
  /**
   * Complemento
   */
  complement: string | null;
  /**
   * Cidade
   */
  city: string | null;
  /**
   * Estado
   */
  state: string | null;
  /**
   * País
   */
  country: string | null;
}

export interface ChooseGift_ChooseGift_deliveryAddresses {
  __typename: "CartDeliveryAddressType";
  /**
   * Tipo do endereço
   */
  type: ChooseGift_ChooseGift_deliveryAddresses_type;
  /**
   * Endereço em formato de objeto
   */
  address: ChooseGift_ChooseGift_deliveryAddresses_address;
  /**
   * Endereço formatado
   */
  formattedAddress: string;
  /**
   * CEP
   */
  cep: string;
}

export interface ChooseGift_ChooseGift_paymentPlans_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ChooseGift_ChooseGift_paymentPlans_creditCardOptions_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ChooseGift_ChooseGift_paymentPlans_creditCardOptions {
  __typename: "CreditCardOptionType";
  /**
   * Descrição da bandeira do cartão de crédito
   */
  type: string;
  /**
   * Identificador da bandeira do cartão de crédito
   */
  flag: string;
  /**
   * Modo de pagamento para o cartão especificado
   */
  paymentMode: ChooseGift_ChooseGift_paymentPlans_creditCardOptions_paymentMode;
  /**
   * Parcelas possíveis de serem escolhidas
   */
  possibleNumberOfInstallments: number[] | null;
}

export interface ChooseGift_ChooseGift_paymentPlans_selectedInstallments {
  __typename: "InstallmentType";
  /**
   * Número da parcela
   */
  number: number;
  /**
   * Valor da parcela
   */
  value: number;
  /**
   * Data de vencimento
   */
  dueDate: any;
  /**
   * Indica se está bloqueada
   */
  isBlocked: boolean;
}

export interface ChooseGift_ChooseGift_paymentPlans {
  __typename: "PaymentPlanResponse";
  /**
   * Código do plano de pagamento
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Indica se consumirá crédito da revendedora
   */
  willConsumeCredit: boolean;
  /**
   * Indica se ela deve ter saldo de crédito para poder comprar
   */
  shouldVerifyCredit: boolean;
  /**
   * Indica se está bloqueado
   */
  isBlocked: boolean;
  /**
   * Taxa de administração
   */
  tax: number;
  /**
   * Número de parcelas (para planos sem cartão crédito)
   */
  installments: number | null;
  /**
   * Identificador do modo de pagamento
   */
  paymentMode: ChooseGift_ChooseGift_paymentPlans_paymentMode | null;
  /**
   * Opções quando pagamento é por cartão de crédito
   */
  creditCardOptions: ChooseGift_ChooseGift_paymentPlans_creditCardOptions[] | null;
  /**
   * Parcelas do plano selecionado
   */
  selectedInstallments: ChooseGift_ChooseGift_paymentPlans_selectedInstallments[] | null;
  /**
   * Indica se é múltiplas formas de pagamento
   */
  isMultiplePayment: boolean;
}

export interface ChooseGift_ChooseGift {
  __typename: "CartResponse";
  /**
   * Código do pedido
   */
  orderCode: string;
  /**
   * Código do espaço revendedor
   */
  distributionCenterCode: string;
  /**
   * Modo de entrega (retirar na central / entrega em endereço)
   */
  pickupOnDistributionCenter: boolean | null;
  /**
   * Valor do pedido mínimo
   */
  requiredMinimum: number;
  /**
   * Valor atual considerado para o pedido mínimo
   */
  currentValueConsideredToMinimum: number;
  /**
   * Ciclo do pedido
   */
  cycle: number;
  /**
   * SubCiclo do pedido
   */
  subCycle: number;
  /**
   * Dados do link externo de pagamento
   */
  externalPaymentLink: ChooseGift_ChooseGift_externalPaymentLink | null;
  /**
   * Crédito disponível
   */
  credit: ChooseGift_ChooseGift_credit | null;
  /**
   * Produtos do pedido
   */
  products: ChooseGift_ChooseGift_products[];
  /**
   * Produtos cortados
   */
  cutProducts: ChooseGift_ChooseGift_cutProducts[];
  /**
   * Produto (brinde) conquistado
   */
  acquiredProducts: ChooseGift_ChooseGift_acquiredProducts[] | null;
  /**
   * Promoção conquistada
   */
  acquiredPromotions: ChooseGift_ChooseGift_acquiredPromotions[] | null;
  /**
   * Promoção conquistada
   */
  partialPromotions: ChooseGift_ChooseGift_partialPromotions[] | null;
  /**
   * Opção a ser escolhida na promoção (pode ser brinde ou desconto)
   */
  rewardsToChoose: ChooseGift_ChooseGift_rewardsToChoose[] | null;
  /**
   * Endereço escolhido (ou padrão) para a entrega
   */
  chosenAddress: ChooseGift_ChooseGift_chosenAddress | null;
  /**
   * Opção de frete escolhida (ou padrão)
   */
  chosenFreightOption: ChooseGift_ChooseGift_chosenFreightOption | null;
  /**
   * Opção de pagamento escolhida (ou padrão)
   */
  chosenPaymentPlan: ChooseGift_ChooseGift_chosenPaymentPlan | null;
  /**
   * Resumo de valores do pedido
   */
  totals: ChooseGift_ChooseGift_totals;
  /**
   * Endereços para entrega
   */
  deliveryAddresses: ChooseGift_ChooseGift_deliveryAddresses[] | null;
  /**
   * Planos de pagamento
   */
  paymentPlans: ChooseGift_ChooseGift_paymentPlans[] | null;
}

export interface ChooseGift {
  /**
   * # Mutation - ChooseGift
   * ## Description:
   * 
   * 
   *  - Escolhe o brinde/desconto para uma promoção. Quando escolhendo brindes,
   *           é necessário enviar todos as opções dos produtos, mesmo os não escolhidos (estes com quantidade 0).
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## External Dependencies:
   * 
   * 
   *  - ### ManageOrderDatasource
   *     
   * 
   * 	
   * 	 - Endpoint Path: /cart/orders/${orderCode}/Manage
   * ## Internal Dependencies:
   * 
   * 
   *  - ### ChooseGiftUseCase
   *     
   * 
   * 
   *  - ### ManageOrderDatasource
   */
  ChooseGift: ChooseGift_ChooseGift;
}

export interface ChooseGiftVariables {
  data: ChooseGiftInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ChooseMultiplePaymentOptions
// ====================================================

export interface ChooseMultiplePaymentOptions_ChooseMultiplePaymentOptions_paymentPlanSelected_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ChooseMultiplePaymentOptions_ChooseMultiplePaymentOptions_paymentPlanSelected_creditCardOptions_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ChooseMultiplePaymentOptions_ChooseMultiplePaymentOptions_paymentPlanSelected_creditCardOptions {
  __typename: "CreditCardOptionType";
  /**
   * Descrição da bandeira do cartão de crédito
   */
  type: string;
  /**
   * Identificador da bandeira do cartão de crédito
   */
  flag: string;
  /**
   * Modo de pagamento para o cartão especificado
   */
  paymentMode: ChooseMultiplePaymentOptions_ChooseMultiplePaymentOptions_paymentPlanSelected_creditCardOptions_paymentMode;
  /**
   * Parcelas possíveis de serem escolhidas
   */
  possibleNumberOfInstallments: number[] | null;
}

export interface ChooseMultiplePaymentOptions_ChooseMultiplePaymentOptions_paymentPlanSelected_selectedInstallments {
  __typename: "InstallmentType";
  /**
   * Número da parcela
   */
  number: number;
  /**
   * Valor da parcela
   */
  value: number;
  /**
   * Data de vencimento
   */
  dueDate: any;
  /**
   * Indica se está bloqueada
   */
  isBlocked: boolean;
}

export interface ChooseMultiplePaymentOptions_ChooseMultiplePaymentOptions_paymentPlanSelected {
  __typename: "PaymentPlanResponse";
  /**
   * Código do plano de pagamento
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Indica se consumirá crédito da revendedora
   */
  willConsumeCredit: boolean;
  /**
   * Indica se ela deve ter saldo de crédito para poder comprar
   */
  shouldVerifyCredit: boolean;
  /**
   * Indica se está bloqueado
   */
  isBlocked: boolean;
  /**
   * Taxa de administração
   */
  tax: number;
  /**
   * Número de parcelas (para planos sem cartão crédito)
   */
  installments: number | null;
  /**
   * Identificador do modo de pagamento
   */
  paymentMode: ChooseMultiplePaymentOptions_ChooseMultiplePaymentOptions_paymentPlanSelected_paymentMode | null;
  /**
   * Opções quando pagamento é por cartão de crédito
   */
  creditCardOptions: ChooseMultiplePaymentOptions_ChooseMultiplePaymentOptions_paymentPlanSelected_creditCardOptions[] | null;
  /**
   * Parcelas do plano selecionado
   */
  selectedInstallments: ChooseMultiplePaymentOptions_ChooseMultiplePaymentOptions_paymentPlanSelected_selectedInstallments[] | null;
  /**
   * Indica se é múltiplas formas de pagamento
   */
  isMultiplePayment: boolean;
}

export interface ChooseMultiplePaymentOptions_ChooseMultiplePaymentOptions_paymentPlansAvailable_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ChooseMultiplePaymentOptions_ChooseMultiplePaymentOptions_paymentPlansAvailable_creditCardOptions_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ChooseMultiplePaymentOptions_ChooseMultiplePaymentOptions_paymentPlansAvailable_creditCardOptions {
  __typename: "CreditCardOptionType";
  /**
   * Descrição da bandeira do cartão de crédito
   */
  type: string;
  /**
   * Identificador da bandeira do cartão de crédito
   */
  flag: string;
  /**
   * Modo de pagamento para o cartão especificado
   */
  paymentMode: ChooseMultiplePaymentOptions_ChooseMultiplePaymentOptions_paymentPlansAvailable_creditCardOptions_paymentMode;
  /**
   * Parcelas possíveis de serem escolhidas
   */
  possibleNumberOfInstallments: number[] | null;
}

export interface ChooseMultiplePaymentOptions_ChooseMultiplePaymentOptions_paymentPlansAvailable_selectedInstallments {
  __typename: "InstallmentType";
  /**
   * Número da parcela
   */
  number: number;
  /**
   * Valor da parcela
   */
  value: number;
  /**
   * Data de vencimento
   */
  dueDate: any;
  /**
   * Indica se está bloqueada
   */
  isBlocked: boolean;
}

export interface ChooseMultiplePaymentOptions_ChooseMultiplePaymentOptions_paymentPlansAvailable {
  __typename: "PaymentPlanResponse";
  /**
   * Código do plano de pagamento
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Indica se consumirá crédito da revendedora
   */
  willConsumeCredit: boolean;
  /**
   * Indica se ela deve ter saldo de crédito para poder comprar
   */
  shouldVerifyCredit: boolean;
  /**
   * Indica se está bloqueado
   */
  isBlocked: boolean;
  /**
   * Taxa de administração
   */
  tax: number;
  /**
   * Número de parcelas (para planos sem cartão crédito)
   */
  installments: number | null;
  /**
   * Identificador do modo de pagamento
   */
  paymentMode: ChooseMultiplePaymentOptions_ChooseMultiplePaymentOptions_paymentPlansAvailable_paymentMode | null;
  /**
   * Opções quando pagamento é por cartão de crédito
   */
  creditCardOptions: ChooseMultiplePaymentOptions_ChooseMultiplePaymentOptions_paymentPlansAvailable_creditCardOptions[] | null;
  /**
   * Parcelas do plano selecionado
   */
  selectedInstallments: ChooseMultiplePaymentOptions_ChooseMultiplePaymentOptions_paymentPlansAvailable_selectedInstallments[] | null;
  /**
   * Indica se é múltiplas formas de pagamento
   */
  isMultiplePayment: boolean;
}

export interface ChooseMultiplePaymentOptions_ChooseMultiplePaymentOptions_creditCardFlag_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ChooseMultiplePaymentOptions_ChooseMultiplePaymentOptions_creditCardFlag {
  __typename: "CreditCardOptionType";
  /**
   * Descrição da bandeira do cartão de crédito
   */
  type: string;
  /**
   * Identificador da bandeira do cartão de crédito
   */
  flag: string;
  /**
   * Modo de pagamento para o cartão especificado
   */
  paymentMode: ChooseMultiplePaymentOptions_ChooseMultiplePaymentOptions_creditCardFlag_paymentMode;
  /**
   * Parcelas possíveis de serem escolhidas
   */
  possibleNumberOfInstallments: number[] | null;
}

export interface ChooseMultiplePaymentOptions_ChooseMultiplePaymentOptions_statusPayment {
  __typename: "StatusPaymentType";
  /**
   * Cartão recusado
   */
  refused: boolean | null;
  /**
   * Pagamento cancelado
   */
  cancel: boolean | null;
  /**
   * Pagamento aprovado
   */
  approved: boolean | null;
  /**
   * Em processamento
   */
  notProcess: boolean | null;
  /**
   * Esperando aprovação do pagamento
   */
  waitingApproved: boolean | null;
  /**
   * Em processo de cancelamento
   */
  waitingCancel: boolean | null;
  /**
   * Pagamento com erro
   */
  failed: boolean | null;
}

export interface ChooseMultiplePaymentOptions_ChooseMultiplePaymentOptions_fraudPrevention {
  __typename: "FraudPreventionType";
  /**
   * App
   */
  app: string;
  /**
   * Session id
   */
  sessionID: string;
}

export interface ChooseMultiplePaymentOptions_ChooseMultiplePaymentOptions {
  __typename: "PaymentOptionSelectedType";
  /**
   * Id do pagamento
   */
  id: number | null;
  /**
   * Plano de pagamento selecionado
   */
  paymentPlanSelected: ChooseMultiplePaymentOptions_ChooseMultiplePaymentOptions_paymentPlanSelected;
  /**
   * Plano de pagamento disponíveis
   */
  paymentPlansAvailable: ChooseMultiplePaymentOptions_ChooseMultiplePaymentOptions_paymentPlansAvailable[] | null;
  /**
   * Código do pagamento
   */
  distributionCodeDetails: number | null;
  /**
   * Valor escolhido
   */
  amount: number;
  /**
   * Informação do cartão
   */
  creditCardFlag: ChooseMultiplePaymentOptions_ChooseMultiplePaymentOptions_creditCardFlag | null;
  /**
   * Status do pagamento do cartão
   */
  statusPayment: ChooseMultiplePaymentOptions_ChooseMultiplePaymentOptions_statusPayment | null;
  /**
   * Prevenção de fraude
   */
  fraudPrevention: ChooseMultiplePaymentOptions_ChooseMultiplePaymentOptions_fraudPrevention | null;
  /**
   * Bloqueado para alteração
   */
  isChangeBlocked: boolean;
  /**
   * Quantidade de parcelas selecionada
   */
  installmentSelected: number | null;
  /**
   * Número do pagamento
   */
  numberPayment: number | null;
}

export interface ChooseMultiplePaymentOptions {
  /**
   * # Mutation - SetCoupon
   * ## Description:
   * 
   * 
   *  - Adiciona um cupom de desconto no pedido
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## External Dependencies:
   * 
   * 
   *  - ### BoticarioBaseGeraHttpDatasource
   *     
   * 
   * 	
   * 	 - Endpoint Path: orders/${orderCode}/multiplePaymentPlans
   * ## Internal Dependencies:
   * 
   * 
   *  - ### MultiplePaymentChooseOptionsUseCase
   *     
   * 
   * 
   *  - ### MultiplePaymentDatasource
   */
  ChooseMultiplePaymentOptions: ChooseMultiplePaymentOptions_ChooseMultiplePaymentOptions[];
}

export interface ChooseMultiplePaymentOptionsVariables {
  data: ChooseMultiplePaymentOptionsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ChooseOrderAddress
// ====================================================

export interface ChooseOrderAddress_ChooseOrderAddress_type {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ChooseOrderAddress_ChooseOrderAddress {
  __typename: "FreightOptionResponse";
  /**
   * Código da opção de frete
   */
  code: string;
  /**
   * Nome da opção de frete
   */
  name: string;
  /**
   * Tipo
   */
  type: ChooseOrderAddress_ChooseOrderAddress_type;
  /**
   * Valor da entrega
   */
  price: number;
  /**
   * Dias úteis para a entrega
   */
  deliveryUtilDays: number;
}

export interface ChooseOrderAddress {
  /**
   * # Mutation - ChooseOrderAddress
   * ## Description:
   * 
   * 
   *  - Define o endereço de entrega
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## External Dependencies:
   * 
   * 
   *  - ### ManageOrderDatasource
   *     
   * 
   * 	
   * 	 - Endpoint Path: /cart/orders/${orderCode}/Manage
   * 
   * 
   *  - ### BoticarioBaseCartHttpDatasource
   *     
   * ## Internal Dependencies:
   * 
   * 
   *  - ### ChooseOrderAddressUseCase
   */
  ChooseOrderAddress: ChooseOrderAddress_ChooseOrderAddress[];
}

export interface ChooseOrderAddressVariables {
  data: ChooseOrderAddressInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ChoosePaymentPlan
// ====================================================

export interface ChoosePaymentPlan_ChoosePaymentPlan_externalPaymentLink {
  __typename: "NewPaymentLinkResponse";
  /**
   * Url de pagamento
   */
  externalPaymentLink: string | null;
  /**
   * Data de expiração da url de pagamento
   */
  expirationDatePaymentLink: any | null;
}

export interface ChoosePaymentPlan_ChoosePaymentPlan_credit {
  __typename: "ResellerCreditResponse";
  /**
   * Crédito disponível para compra no momento
   */
  available: number | null;
}

export interface ChoosePaymentPlan_ChoosePaymentPlan_products_origin {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ChoosePaymentPlan_ChoosePaymentPlan_products {
  __typename: "CartProductType";
  /**
   * Número representativo do produto no carrinho Gera
   */
  sequentialNumber: string;
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * URL da imagem do produto
   */
  imageUrl: string;
  /**
   * Quantidade
   */
  quantity: number;
  /**
   * Preço de mercado (consumidor)
   */
  unitMarketValue: number;
  /**
   * Preço de tabela (consumidor)
   */
  unitTableValue: number;
  /**
   * Preço promocionado (consumidor)
   */
  unitNetValue: number | null;
  /**
   * Origem do produto no pedido
   */
  origin: ChoosePaymentPlan_ChoosePaymentPlan_products_origin;
  /**
   * Bloqueado para edição?
   */
  blockEdit: boolean;
  /**
   * Bloqueado para remoção?
   */
  blockDelete: boolean;
  /**
   * Valor unitário de compra
   */
  unitPayValue: number;
  /**
   * Valor de compra
   */
  payValue: number;
  /**
   * Lucro
   */
  profit: number;
  /**
   * Lucro com descontos
   */
  profitWithDiscount: number;
  /**
   * Se o produto é um combo personalizável
   */
  isCombo: boolean;
}

export interface ChoosePaymentPlan_ChoosePaymentPlan_cutProducts_type {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ChoosePaymentPlan_ChoosePaymentPlan_cutProducts_cutReason {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ChoosePaymentPlan_ChoosePaymentPlan_cutProducts {
  __typename: "CartCutProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * URL da imagem do produto
   */
  imageUrl: string;
  /**
   * Quantidade
   */
  quantity: number;
  /**
   * Preço de mercado (consumidor)
   */
  unitMarketValue: number;
  /**
   * Preço de tabela (consumidor)
   */
  unitTableValue: number;
  /**
   * Preço promocionado (consumidor)
   */
  unitNetValue: number | null;
  /**
   * Tipo do item
   */
  type: ChoosePaymentPlan_ChoosePaymentPlan_cutProducts_type;
  /**
   * Razão do corte
   */
  cutReason: ChoosePaymentPlan_ChoosePaymentPlan_cutProducts_cutReason;
}

export interface ChoosePaymentPlan_ChoosePaymentPlan_acquiredProducts_promotion {
  __typename: "CartPromotionType";
  /**
   * Código
   */
  code: string;
  /**
   * Título
   */
  title: string;
}

export interface ChoosePaymentPlan_ChoosePaymentPlan_acquiredProducts {
  __typename: "AcquiredProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * URL da imagem
   */
  imageUrl: string;
  /**
   * Quantidade
   */
  quantity: number | null;
  /**
   * Promoção que originou o brinde
   */
  promotion: ChoosePaymentPlan_ChoosePaymentPlan_acquiredProducts_promotion | null;
}

export interface ChoosePaymentPlan_ChoosePaymentPlan_acquiredPromotions_destination {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ChoosePaymentPlan_ChoosePaymentPlan_acquiredPromotions_rewards {
  __typename: "RewardProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Quantidade
   */
  quantity: number | null;
  /**
   * URL da imagem
   */
  imageUrl: string;
  /**
   * Desconto
   */
  discount: number;
  /**
   * Tipo
   */
  type: RewardTypeEnum;
  /**
   * Descrição
   */
  description: string | null;
  /**
   * Indica se é um brinde a ser escolhido
   */
  isGiftToChoose: boolean;
}

export interface ChoosePaymentPlan_ChoosePaymentPlan_acquiredPromotions {
  __typename: "AcquiredPromotionType";
  /**
   * Código da promoção
   */
  code: string;
  /**
   * Nome
   */
  title: string;
  /**
   * Descrição
   */
  description: string | null;
  /**
   * Destino
   */
  destination: ChoosePaymentPlan_ChoosePaymentPlan_acquiredPromotions_destination;
  /**
   * Indica se é promoção de cupom de desconto
   */
  isCupomPromotion: boolean;
  /**
   * Lista de brindes/descontos
   */
  rewards: ChoosePaymentPlan_ChoosePaymentPlan_acquiredPromotions_rewards[];
}

export interface ChoosePaymentPlan_ChoosePaymentPlan_partialPromotions_destination {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ChoosePaymentPlan_ChoosePaymentPlan_partialPromotions_requirements_type {
  __typename: "RequirementType";
  /**
   * Id do tipo de requisito
   */
  id: number | null;
  /**
   * Descrição do requisito
   */
  description: string | null;
}

export interface ChoosePaymentPlan_ChoosePaymentPlan_partialPromotions_requirements_productRequired {
  __typename: "RequiredProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome do produto
   */
  name: string;
  /**
   * Descrição do produto
   */
  description: string | null;
  /**
   * Indica se é um kit
   */
  isKit: boolean | null;
  /**
   * Quantidade de pontos
   */
  points: number | null;
  /**
   * Preço
   */
  price: number | null;
}

export interface ChoosePaymentPlan_ChoosePaymentPlan_partialPromotions_requirements_structureRequired {
  __typename: "RequiredStructureType";
  /**
   * Código da estrutura
   */
  code: string;
  /**
   * Nome da estrutura
   */
  name: string;
}

export interface ChoosePaymentPlan_ChoosePaymentPlan_partialPromotions_requirements {
  __typename: "PromotionRequirementType";
  /**
   * Valor faltante para obter a promoção
   */
  missingValue: number;
  /**
   * Tipo do requisito
   */
  type: ChoosePaymentPlan_ChoosePaymentPlan_partialPromotions_requirements_type;
  /**
   * Produto requisitado
   */
  productRequired: ChoosePaymentPlan_ChoosePaymentPlan_partialPromotions_requirements_productRequired | null;
  /**
   * Estrutura na qual a compra deve ser feita
   */
  structureRequired: ChoosePaymentPlan_ChoosePaymentPlan_partialPromotions_requirements_structureRequired | null;
  /**
   * Descrição
   */
  description: string | null;
}

export interface ChoosePaymentPlan_ChoosePaymentPlan_partialPromotions {
  __typename: "PartialPromotionType";
  /**
   * Código da promoção
   */
  code: string;
  /**
   * Nome
   */
  title: string;
  /**
   * Descrição
   */
  description: string | null;
  /**
   * Destino
   */
  destination: ChoosePaymentPlan_ChoosePaymentPlan_partialPromotions_destination;
  /**
   * Indica se é promoção de cupom de desconto
   */
  isCupomPromotion: boolean;
  /**
   * Lista de requisitos para conquistar a promoção
   */
  requirements: ChoosePaymentPlan_ChoosePaymentPlan_partialPromotions_requirements[];
}

export interface ChoosePaymentPlan_ChoosePaymentPlan_rewardsToChoose_destination {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ChoosePaymentPlan_ChoosePaymentPlan_rewardsToChoose_productsToChoose {
  __typename: "RewardProductToChooseType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Url da imagem
   */
  imageUrl: string;
  /**
   * Quantidade escolhida
   */
  chosenQuantity: number | null;
  /**
   * Quantidade máxima a ser selecionada
   */
  maxQuantity: number;
  /**
   * Indica se está indisponível (só atualiza depois de escolhido)
   */
  unavailable: boolean;
  /**
   * ?
   */
  isReorderAllowed: boolean;
}

export interface ChoosePaymentPlan_ChoosePaymentPlan_rewardsToChoose_discountsToChoose {
  __typename: "RewardDiscountToChooseType";
  /**
   * Código da opção de desconto
   */
  code: string;
  /**
   * Valor do desconto (unidade é de acordo com o tipo do desconto)
   */
  value: number;
  /**
   * Descrição
   */
  auxiliaryDescription: string | null;
  /**
   * Tipo da conquista
   */
  rewardType: RewardTypeEnum;
}

export interface ChoosePaymentPlan_ChoosePaymentPlan_rewardsToChoose {
  __typename: "RewardToChooseType";
  /**
   * Código da promoção (pode repetir)
   */
  code: string;
  /**
   * Título da promoção
   */
  title: string;
  /**
   * Indica se escolheu produtos
   */
  hasChosenProducts: boolean;
  /**
   * Indica se escolheu a opção de brindes
   */
  hasChosenDiscount: boolean;
  /**
   * Número sequencial (diferenciador das opções)
   */
  giftNumber: string;
  /**
   * Número sequencial (diferenciador dos itens)
   */
  giftItemNumber: string;
  /**
   * Número máximo de brindes a serem escolhidos
   */
  maxGiftsToChoose: number;
  /**
   * Descrição
   */
  description: string | null;
  /**
   * Destino
   */
  destination: ChoosePaymentPlan_ChoosePaymentPlan_rewardsToChoose_destination;
  /**
   * Indica se é promoção de cupom
   */
  isCupomPromotion: boolean;
  /**
   * Produto (brinde) a ser escolhido na promoção
   */
  productsToChoose: ChoosePaymentPlan_ChoosePaymentPlan_rewardsToChoose_productsToChoose[] | null;
  /**
   * Detalhes de uma opção de desconto em uma promoção
   */
  discountsToChoose: ChoosePaymentPlan_ChoosePaymentPlan_rewardsToChoose_discountsToChoose[] | null;
}

export interface ChoosePaymentPlan_ChoosePaymentPlan_chosenAddress_type {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ChoosePaymentPlan_ChoosePaymentPlan_chosenAddress_address {
  __typename: "RegistrationAddressType";
  /**
   * Logradouro
   */
  streetName: string | null;
  /**
   * Número
   */
  addressNumber: string | null;
  /**
   * CEP
   */
  cep: string | null;
  /**
   * Bairro
   */
  neighborhood: string | null;
  /**
   * Complemento
   */
  complement: string | null;
  /**
   * Cidade
   */
  city: string | null;
  /**
   * Estado
   */
  state: string | null;
  /**
   * País
   */
  country: string | null;
}

export interface ChoosePaymentPlan_ChoosePaymentPlan_chosenAddress {
  __typename: "CartDeliveryAddressType";
  /**
   * Tipo do endereço
   */
  type: ChoosePaymentPlan_ChoosePaymentPlan_chosenAddress_type;
  /**
   * Endereço em formato de objeto
   */
  address: ChoosePaymentPlan_ChoosePaymentPlan_chosenAddress_address;
  /**
   * Endereço formatado
   */
  formattedAddress: string;
  /**
   * CEP
   */
  cep: string;
}

export interface ChoosePaymentPlan_ChoosePaymentPlan_chosenFreightOption_type {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ChoosePaymentPlan_ChoosePaymentPlan_chosenFreightOption {
  __typename: "FreightOptionResponse";
  /**
   * Código da opção de frete
   */
  code: string;
  /**
   * Nome da opção de frete
   */
  name: string;
  /**
   * Tipo
   */
  type: ChoosePaymentPlan_ChoosePaymentPlan_chosenFreightOption_type;
  /**
   * Valor da entrega
   */
  price: number;
  /**
   * Dias úteis para a entrega
   */
  deliveryUtilDays: number;
}

export interface ChoosePaymentPlan_ChoosePaymentPlan_chosenPaymentPlan_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ChoosePaymentPlan_ChoosePaymentPlan_chosenPaymentPlan_creditCardOptions_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ChoosePaymentPlan_ChoosePaymentPlan_chosenPaymentPlan_creditCardOptions {
  __typename: "CreditCardOptionType";
  /**
   * Descrição da bandeira do cartão de crédito
   */
  type: string;
  /**
   * Identificador da bandeira do cartão de crédito
   */
  flag: string;
  /**
   * Modo de pagamento para o cartão especificado
   */
  paymentMode: ChoosePaymentPlan_ChoosePaymentPlan_chosenPaymentPlan_creditCardOptions_paymentMode;
  /**
   * Parcelas possíveis de serem escolhidas
   */
  possibleNumberOfInstallments: number[] | null;
}

export interface ChoosePaymentPlan_ChoosePaymentPlan_chosenPaymentPlan_selectedInstallments {
  __typename: "InstallmentType";
  /**
   * Número da parcela
   */
  number: number;
  /**
   * Valor da parcela
   */
  value: number;
  /**
   * Data de vencimento
   */
  dueDate: any;
  /**
   * Indica se está bloqueada
   */
  isBlocked: boolean;
}

export interface ChoosePaymentPlan_ChoosePaymentPlan_chosenPaymentPlan {
  __typename: "PaymentPlanResponse";
  /**
   * Código do plano de pagamento
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Indica se consumirá crédito da revendedora
   */
  willConsumeCredit: boolean;
  /**
   * Indica se ela deve ter saldo de crédito para poder comprar
   */
  shouldVerifyCredit: boolean;
  /**
   * Indica se está bloqueado
   */
  isBlocked: boolean;
  /**
   * Taxa de administração
   */
  tax: number;
  /**
   * Número de parcelas (para planos sem cartão crédito)
   */
  installments: number | null;
  /**
   * Identificador do modo de pagamento
   */
  paymentMode: ChoosePaymentPlan_ChoosePaymentPlan_chosenPaymentPlan_paymentMode | null;
  /**
   * Opções quando pagamento é por cartão de crédito
   */
  creditCardOptions: ChoosePaymentPlan_ChoosePaymentPlan_chosenPaymentPlan_creditCardOptions[] | null;
  /**
   * Parcelas do plano selecionado
   */
  selectedInstallments: ChoosePaymentPlan_ChoosePaymentPlan_chosenPaymentPlan_selectedInstallments[] | null;
  /**
   * Indica se é múltiplas formas de pagamento
   */
  isMultiplePayment: boolean;
}

export interface ChoosePaymentPlan_ChoosePaymentPlan_totals {
  __typename: "CartTotalsType";
  /**
   * Total de venda
   */
  totalSell: number | null;
  /**
   * Lucro
   */
  profit: number | null;
  /**
   * Frete
   */
  deliveryTax: number | null;
  /**
   * Subtotal
   */
  subtotalPay: number | null;
  /**
   * Total
   */
  totalPay: number | null;
  /**
   * Lucro vindo dos brindes
   */
  profitFromGifts: number | null;
  /**
   * Valor de crédito utilizado da CCR (Conta Corrente Residual)
   */
  checkingAccount: number | null;
  /**
   * Taxa administrativa
   */
  collectionFee: number | null;
  /**
   * Impostos
   */
  taxes: number | null;
  /**
   * Comissão
   */
  commission: number | null;
}

export interface ChoosePaymentPlan_ChoosePaymentPlan_deliveryAddresses_type {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ChoosePaymentPlan_ChoosePaymentPlan_deliveryAddresses_address {
  __typename: "RegistrationAddressType";
  /**
   * Logradouro
   */
  streetName: string | null;
  /**
   * Número
   */
  addressNumber: string | null;
  /**
   * CEP
   */
  cep: string | null;
  /**
   * Bairro
   */
  neighborhood: string | null;
  /**
   * Complemento
   */
  complement: string | null;
  /**
   * Cidade
   */
  city: string | null;
  /**
   * Estado
   */
  state: string | null;
  /**
   * País
   */
  country: string | null;
}

export interface ChoosePaymentPlan_ChoosePaymentPlan_deliveryAddresses {
  __typename: "CartDeliveryAddressType";
  /**
   * Tipo do endereço
   */
  type: ChoosePaymentPlan_ChoosePaymentPlan_deliveryAddresses_type;
  /**
   * Endereço em formato de objeto
   */
  address: ChoosePaymentPlan_ChoosePaymentPlan_deliveryAddresses_address;
  /**
   * Endereço formatado
   */
  formattedAddress: string;
  /**
   * CEP
   */
  cep: string;
}

export interface ChoosePaymentPlan_ChoosePaymentPlan_paymentPlans_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ChoosePaymentPlan_ChoosePaymentPlan_paymentPlans_creditCardOptions_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ChoosePaymentPlan_ChoosePaymentPlan_paymentPlans_creditCardOptions {
  __typename: "CreditCardOptionType";
  /**
   * Descrição da bandeira do cartão de crédito
   */
  type: string;
  /**
   * Identificador da bandeira do cartão de crédito
   */
  flag: string;
  /**
   * Modo de pagamento para o cartão especificado
   */
  paymentMode: ChoosePaymentPlan_ChoosePaymentPlan_paymentPlans_creditCardOptions_paymentMode;
  /**
   * Parcelas possíveis de serem escolhidas
   */
  possibleNumberOfInstallments: number[] | null;
}

export interface ChoosePaymentPlan_ChoosePaymentPlan_paymentPlans_selectedInstallments {
  __typename: "InstallmentType";
  /**
   * Número da parcela
   */
  number: number;
  /**
   * Valor da parcela
   */
  value: number;
  /**
   * Data de vencimento
   */
  dueDate: any;
  /**
   * Indica se está bloqueada
   */
  isBlocked: boolean;
}

export interface ChoosePaymentPlan_ChoosePaymentPlan_paymentPlans {
  __typename: "PaymentPlanResponse";
  /**
   * Código do plano de pagamento
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Indica se consumirá crédito da revendedora
   */
  willConsumeCredit: boolean;
  /**
   * Indica se ela deve ter saldo de crédito para poder comprar
   */
  shouldVerifyCredit: boolean;
  /**
   * Indica se está bloqueado
   */
  isBlocked: boolean;
  /**
   * Taxa de administração
   */
  tax: number;
  /**
   * Número de parcelas (para planos sem cartão crédito)
   */
  installments: number | null;
  /**
   * Identificador do modo de pagamento
   */
  paymentMode: ChoosePaymentPlan_ChoosePaymentPlan_paymentPlans_paymentMode | null;
  /**
   * Opções quando pagamento é por cartão de crédito
   */
  creditCardOptions: ChoosePaymentPlan_ChoosePaymentPlan_paymentPlans_creditCardOptions[] | null;
  /**
   * Parcelas do plano selecionado
   */
  selectedInstallments: ChoosePaymentPlan_ChoosePaymentPlan_paymentPlans_selectedInstallments[] | null;
  /**
   * Indica se é múltiplas formas de pagamento
   */
  isMultiplePayment: boolean;
}

export interface ChoosePaymentPlan_ChoosePaymentPlan {
  __typename: "CartResponse";
  /**
   * Código do pedido
   */
  orderCode: string;
  /**
   * Código do espaço revendedor
   */
  distributionCenterCode: string;
  /**
   * Modo de entrega (retirar na central / entrega em endereço)
   */
  pickupOnDistributionCenter: boolean | null;
  /**
   * Valor do pedido mínimo
   */
  requiredMinimum: number;
  /**
   * Valor atual considerado para o pedido mínimo
   */
  currentValueConsideredToMinimum: number;
  /**
   * Ciclo do pedido
   */
  cycle: number;
  /**
   * SubCiclo do pedido
   */
  subCycle: number;
  /**
   * Dados do link externo de pagamento
   */
  externalPaymentLink: ChoosePaymentPlan_ChoosePaymentPlan_externalPaymentLink | null;
  /**
   * Crédito disponível
   */
  credit: ChoosePaymentPlan_ChoosePaymentPlan_credit | null;
  /**
   * Produtos do pedido
   */
  products: ChoosePaymentPlan_ChoosePaymentPlan_products[];
  /**
   * Produtos cortados
   */
  cutProducts: ChoosePaymentPlan_ChoosePaymentPlan_cutProducts[];
  /**
   * Produto (brinde) conquistado
   */
  acquiredProducts: ChoosePaymentPlan_ChoosePaymentPlan_acquiredProducts[] | null;
  /**
   * Promoção conquistada
   */
  acquiredPromotions: ChoosePaymentPlan_ChoosePaymentPlan_acquiredPromotions[] | null;
  /**
   * Promoção conquistada
   */
  partialPromotions: ChoosePaymentPlan_ChoosePaymentPlan_partialPromotions[] | null;
  /**
   * Opção a ser escolhida na promoção (pode ser brinde ou desconto)
   */
  rewardsToChoose: ChoosePaymentPlan_ChoosePaymentPlan_rewardsToChoose[] | null;
  /**
   * Endereço escolhido (ou padrão) para a entrega
   */
  chosenAddress: ChoosePaymentPlan_ChoosePaymentPlan_chosenAddress | null;
  /**
   * Opção de frete escolhida (ou padrão)
   */
  chosenFreightOption: ChoosePaymentPlan_ChoosePaymentPlan_chosenFreightOption | null;
  /**
   * Opção de pagamento escolhida (ou padrão)
   */
  chosenPaymentPlan: ChoosePaymentPlan_ChoosePaymentPlan_chosenPaymentPlan | null;
  /**
   * Resumo de valores do pedido
   */
  totals: ChoosePaymentPlan_ChoosePaymentPlan_totals;
  /**
   * Endereços para entrega
   */
  deliveryAddresses: ChoosePaymentPlan_ChoosePaymentPlan_deliveryAddresses[] | null;
  /**
   * Planos de pagamento
   */
  paymentPlans: ChoosePaymentPlan_ChoosePaymentPlan_paymentPlans[] | null;
}

export interface ChoosePaymentPlan {
  /**
   * # Mutation - ChoosePaymentPlan
   * ## Description:
   * 
   * 
   *  - Define o plano de pagamento
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## External Dependencies:
   * 
   * 
   *  - ### PaymentAeroDatasource
   *     
   * 
   * 	
   * 	 - Endpoint Path: /cart/orders/${orderCode}/paymentplans
   * ## Internal Dependencies:
   * 
   * 
   *  - ### ChoosePaymentPlansUseCase
   *     
   * 
   * 
   *  - ### PaymentAeroDatasource
   */
  ChoosePaymentPlan: ChoosePaymentPlan_ChoosePaymentPlan;
}

export interface ChoosePaymentPlanVariables {
  data: ChoosePaymentPlanInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateResellerConversation
// ====================================================

export interface CreateResellerConversation_CreateResellerConversation_conversation_feedback {
  __typename: "FeedbackResponse";
  title: string;
}

export interface CreateResellerConversation_CreateResellerConversation_conversation_reseller {
  __typename: "ResellerResponse";
  referenceId: string;
  name: string;
}

export interface CreateResellerConversation_CreateResellerConversation_conversation_supervisor {
  __typename: "SupervisorResponse";
  geraId: string;
  name: string;
}

export interface CreateResellerConversation_CreateResellerConversation_conversation {
  __typename: "ResellerConversationResponse";
  feedback: CreateResellerConversation_CreateResellerConversation_conversation_feedback | null;
  status: ConversationStatusEnum | null;
  note: string | null;
  createdAt: any;
  reseller: CreateResellerConversation_CreateResellerConversation_conversation_reseller;
  /**
   * Informações da atendente
   */
  supervisor: CreateResellerConversation_CreateResellerConversation_conversation_supervisor;
}

export interface CreateResellerConversation_CreateResellerConversation {
  __typename: "CreateConversationResponse";
  conversation: CreateResellerConversation_CreateResellerConversation_conversation;
  schedulingMessage: string | null;
}

export interface CreateResellerConversation {
  /**
   * # Mutation - StartResellerConversation
   * ## Description:
   * 
   * 
   *  - Inicia um atendimento
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## External Dependencies:
   * 
   * 
   *  - ### BaseBusHttpDatasource
   *     
   * 
   * 	
   * 	 - Endpoint Path: /grb/sb/intencaorevenda/intencoesRevenda/${resellerId}/contatos
   * ## Internal Dependencies:
   * 
   * 
   *  - ### CreateResellerConversationUseCase
   *     
   * 
   * 
   *  - ### ResellerConversationDataSource
   *     
   * 
   * 
   *  - ### TabulateGeraUseCase
   *     
   * 
   * 
   *  - ### ResellerConversationGeraDataSource
   */
  CreateResellerConversation: CreateResellerConversation_CreateResellerConversation | null;
}

export interface CreateResellerConversationVariables {
  conversation: ConversationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateResellerInfo
// ====================================================

export interface CreateResellerInfo_CreateResellerInfo_reseller {
  __typename: "BasicInfoResponse";
  referenceId: string | null;
  name: string | null;
}

export interface CreateResellerInfo_CreateResellerInfo_franchise {
  __typename: "BasicInfoResponse";
  referenceId: string | null;
  name: string | null;
}

export interface CreateResellerInfo_CreateResellerInfo_store {
  __typename: "BasicInfoResponse";
  referenceId: string | null;
  name: string | null;
}

export interface CreateResellerInfo_CreateResellerInfo {
  __typename: "ResellerInfoResponse";
  reseller: CreateResellerInfo_CreateResellerInfo_reseller;
  franchise: CreateResellerInfo_CreateResellerInfo_franchise;
  store: CreateResellerInfo_CreateResellerInfo_store;
  availableCredit: number | null;
  birthdate: any | null;
  residentialPhone: string | null;
  mobilePhone: string | null;
  contactPhone: string | null;
  email: string | null;
  street: string | null;
  streetNumber: string | null;
  complement: string | null;
  neighborhood: string | null;
  city: string | null;
  zipCode: string | null;
  cycleAtFirstOrder: string | null;
  fraudRisk: string | null;
  registrationDate: any | null;
}

export interface CreateResellerInfo {
  /**
   * # Mutation - CreateResellerInfo
   * ## Description:
   * 
   * 
   *  - Cria informações de contato do(a) revendedor(a) na base
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## External Dependencies:
   * 
   * 
   *  - ### ResellerHttpDataSource
   *     
   * 
   * 	
   * 	 - Endpoint Path: /grb/sb/revendedoras/${resellerId}/cadastro
   * ## Internal Dependencies:
   * 
   * 
   *  - ### CreateResellerInfoUseCase
   *     
   * 
   * 
   *  - ### ResellerInfoDataSource
   */
  CreateResellerInfo: CreateResellerInfo_CreateResellerInfo;
}

export interface CreateResellerInfoVariables {
  resellerId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditDeliveryAddress
// ====================================================

export interface EditDeliveryAddress_EditDeliveryAddress {
  __typename: "AddressUpdateResponse";
  message: string | null;
}

export interface EditDeliveryAddress {
  /**
   * # Mutation - EditDeliveryAddress
   * ## Description:
   * 
   * 
   *  - Edita informações de endereço do(a) revendedor(a)
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## External Dependencies:
   * 
   * 
   *  - ### ResellerHttpDataSource
   *     
   * 
   * 	
   * 	 - Endpoint Path: /grb/sb/revendedoras/${resellerId}/cadastro
   * ## Internal Dependencies:
   * 
   * 
   *  - ### EditAddressUseCase
   */
  EditDeliveryAddress: EditDeliveryAddress_EditDeliveryAddress;
}

export interface EditDeliveryAddressVariables {
  resellerInfo: AddressUpdateInput;
  resellerId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditResellerInfo
// ====================================================

export interface EditResellerInfo_EditResellerInfo {
  __typename: "EditResellerInfoResponse";
  name: string | null;
  mobilePhone: string | null;
  residentialPhone: string | null;
  contactPhone: string | null;
}

export interface EditResellerInfo {
  /**
   * # Mutation - EditResellerInfo
   * ## Description:
   * 
   * 
   *  - Edita informações de contato do(a) revendedor(a)
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## External Dependencies:
   * 
   * 
   *  - ### ResellerHttpDataSource
   *     
   * 
   * 	
   * 	 - Endpoint Path: /grb/sb/revendedoras/${resellerId}/cadastro
   * ## Internal Dependencies:
   * 
   * 
   *  - ### EditResellerInfoUseCase
   *     
   * 
   * 
   *  - ### ResellerInfoDataSource
   */
  EditResellerInfo: EditResellerInfo_EditResellerInfo;
}

export interface EditResellerInfoVariables {
  resellerInfo: ResellerContactInfoUpdateInput;
  resellerId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EndIrConversation
// ====================================================

export interface EndIrConversation_EndIrConversation {
  __typename: "EndIrConversationResponse";
  id: number;
  createdAt: any;
  inConversation: boolean;
  resellerId: string;
  resellerName: string;
  completedAt: any | null;
}

export interface EndIrConversation {
  EndIrConversation: EndIrConversation_EndIrConversation;
}

export interface EndIrConversationVariables {
  resellerId: string;
  feedback: IrConversationFeedbackInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EudoraEndConversation
// ====================================================

export interface EudoraEndConversation_FvcEudoraEndConversation {
  __typename: "FvcResellerConversationResponse";
  /**
   * Id
   */
  id: string;
  /**
   * Id da revendedora
   */
  resellerId: string;
  /**
   * Nome da revendedora
   */
  resellerName: string | null;
  /**
   * Horário de Início do Atendimento
   */
  createdAt: any;
  /**
   * Horário da finalização do Atendimento
   */
  completedAt: any | null;
  /**
   * Indica se atendimento retornado é um agendamento
   */
  isScheduling: boolean | null;
}

export interface EudoraEndConversation {
  /**
   * # Mutation - FvcEndConversation
   * ## Description:
   * 
   * 
   *  - Finaliza um atendimento.
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## Internal Dependencies:
   * 
   * 
   *  - ### FvcEndConversationUseCase
   *     
   * 
   * 
   *  - ### FeedbackDataSource
   *     
   * 
   * 
   *  - ### FvcSchedulingDbDatasource
   *     
   * 
   * 
   *  - ### ResellerActivationDbDataSource
   *     
   * 
   * 
   *  - ### FvcResellerConversationsDatasource
   *     
   * 
   * 
   *  - ### FvcResellerStructureDbDataSource
   *     
   * 
   * 
   *  - ### FVCResellerInstantMessageDatasource
   */
  FvcEudoraEndConversation: EudoraEndConversation_FvcEudoraEndConversation;
}

export interface EudoraEndConversationVariables {
  activation: FvcEudoraFeedbackInput;
  resellerCode: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: FinishOrder
// ====================================================

export interface FinishOrder_FinishOrder_externalPaymentLink {
  __typename: "NewPaymentLinkResponse";
  /**
   * Url de pagamento
   */
  externalPaymentLink: string | null;
  /**
   * Data de expiração da url de pagamento
   */
  expirationDatePaymentLink: any | null;
}

export interface FinishOrder_FinishOrder_credit {
  __typename: "ResellerCreditResponse";
  /**
   * Crédito disponível para compra no momento
   */
  available: number | null;
}

export interface FinishOrder_FinishOrder_products_origin {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface FinishOrder_FinishOrder_products {
  __typename: "CartProductType";
  /**
   * Número representativo do produto no carrinho Gera
   */
  sequentialNumber: string;
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * URL da imagem do produto
   */
  imageUrl: string;
  /**
   * Quantidade
   */
  quantity: number;
  /**
   * Preço de mercado (consumidor)
   */
  unitMarketValue: number;
  /**
   * Preço de tabela (consumidor)
   */
  unitTableValue: number;
  /**
   * Preço promocionado (consumidor)
   */
  unitNetValue: number | null;
  /**
   * Origem do produto no pedido
   */
  origin: FinishOrder_FinishOrder_products_origin;
  /**
   * Bloqueado para edição?
   */
  blockEdit: boolean;
  /**
   * Bloqueado para remoção?
   */
  blockDelete: boolean;
  /**
   * Valor unitário de compra
   */
  unitPayValue: number;
  /**
   * Valor de compra
   */
  payValue: number;
  /**
   * Lucro
   */
  profit: number;
  /**
   * Lucro com descontos
   */
  profitWithDiscount: number;
  /**
   * Se o produto é um combo personalizável
   */
  isCombo: boolean;
}

export interface FinishOrder_FinishOrder_cutProducts_type {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface FinishOrder_FinishOrder_cutProducts_cutReason {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface FinishOrder_FinishOrder_cutProducts {
  __typename: "CartCutProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * URL da imagem do produto
   */
  imageUrl: string;
  /**
   * Quantidade
   */
  quantity: number;
  /**
   * Preço de mercado (consumidor)
   */
  unitMarketValue: number;
  /**
   * Preço de tabela (consumidor)
   */
  unitTableValue: number;
  /**
   * Preço promocionado (consumidor)
   */
  unitNetValue: number | null;
  /**
   * Tipo do item
   */
  type: FinishOrder_FinishOrder_cutProducts_type;
  /**
   * Razão do corte
   */
  cutReason: FinishOrder_FinishOrder_cutProducts_cutReason;
}

export interface FinishOrder_FinishOrder_acquiredProducts_promotion {
  __typename: "CartPromotionType";
  /**
   * Código
   */
  code: string;
  /**
   * Título
   */
  title: string;
}

export interface FinishOrder_FinishOrder_acquiredProducts {
  __typename: "AcquiredProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * URL da imagem
   */
  imageUrl: string;
  /**
   * Quantidade
   */
  quantity: number | null;
  /**
   * Promoção que originou o brinde
   */
  promotion: FinishOrder_FinishOrder_acquiredProducts_promotion | null;
}

export interface FinishOrder_FinishOrder_acquiredPromotions_destination {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface FinishOrder_FinishOrder_acquiredPromotions_rewards {
  __typename: "RewardProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Quantidade
   */
  quantity: number | null;
  /**
   * URL da imagem
   */
  imageUrl: string;
  /**
   * Desconto
   */
  discount: number;
  /**
   * Tipo
   */
  type: RewardTypeEnum;
  /**
   * Descrição
   */
  description: string | null;
  /**
   * Indica se é um brinde a ser escolhido
   */
  isGiftToChoose: boolean;
}

export interface FinishOrder_FinishOrder_acquiredPromotions {
  __typename: "AcquiredPromotionType";
  /**
   * Código da promoção
   */
  code: string;
  /**
   * Nome
   */
  title: string;
  /**
   * Descrição
   */
  description: string | null;
  /**
   * Destino
   */
  destination: FinishOrder_FinishOrder_acquiredPromotions_destination;
  /**
   * Indica se é promoção de cupom de desconto
   */
  isCupomPromotion: boolean;
  /**
   * Lista de brindes/descontos
   */
  rewards: FinishOrder_FinishOrder_acquiredPromotions_rewards[];
}

export interface FinishOrder_FinishOrder_partialPromotions_destination {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface FinishOrder_FinishOrder_partialPromotions_requirements_type {
  __typename: "RequirementType";
  /**
   * Id do tipo de requisito
   */
  id: number | null;
  /**
   * Descrição do requisito
   */
  description: string | null;
}

export interface FinishOrder_FinishOrder_partialPromotions_requirements_productRequired {
  __typename: "RequiredProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome do produto
   */
  name: string;
  /**
   * Descrição do produto
   */
  description: string | null;
  /**
   * Indica se é um kit
   */
  isKit: boolean | null;
  /**
   * Quantidade de pontos
   */
  points: number | null;
  /**
   * Preço
   */
  price: number | null;
}

export interface FinishOrder_FinishOrder_partialPromotions_requirements_structureRequired {
  __typename: "RequiredStructureType";
  /**
   * Código da estrutura
   */
  code: string;
  /**
   * Nome da estrutura
   */
  name: string;
}

export interface FinishOrder_FinishOrder_partialPromotions_requirements {
  __typename: "PromotionRequirementType";
  /**
   * Valor faltante para obter a promoção
   */
  missingValue: number;
  /**
   * Tipo do requisito
   */
  type: FinishOrder_FinishOrder_partialPromotions_requirements_type;
  /**
   * Produto requisitado
   */
  productRequired: FinishOrder_FinishOrder_partialPromotions_requirements_productRequired | null;
  /**
   * Estrutura na qual a compra deve ser feita
   */
  structureRequired: FinishOrder_FinishOrder_partialPromotions_requirements_structureRequired | null;
  /**
   * Descrição
   */
  description: string | null;
}

export interface FinishOrder_FinishOrder_partialPromotions {
  __typename: "PartialPromotionType";
  /**
   * Código da promoção
   */
  code: string;
  /**
   * Nome
   */
  title: string;
  /**
   * Descrição
   */
  description: string | null;
  /**
   * Destino
   */
  destination: FinishOrder_FinishOrder_partialPromotions_destination;
  /**
   * Indica se é promoção de cupom de desconto
   */
  isCupomPromotion: boolean;
  /**
   * Lista de requisitos para conquistar a promoção
   */
  requirements: FinishOrder_FinishOrder_partialPromotions_requirements[];
}

export interface FinishOrder_FinishOrder_rewardsToChoose_destination {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface FinishOrder_FinishOrder_rewardsToChoose_productsToChoose {
  __typename: "RewardProductToChooseType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Url da imagem
   */
  imageUrl: string;
  /**
   * Quantidade escolhida
   */
  chosenQuantity: number | null;
  /**
   * Quantidade máxima a ser selecionada
   */
  maxQuantity: number;
  /**
   * Indica se está indisponível (só atualiza depois de escolhido)
   */
  unavailable: boolean;
  /**
   * ?
   */
  isReorderAllowed: boolean;
}

export interface FinishOrder_FinishOrder_rewardsToChoose_discountsToChoose {
  __typename: "RewardDiscountToChooseType";
  /**
   * Código da opção de desconto
   */
  code: string;
  /**
   * Valor do desconto (unidade é de acordo com o tipo do desconto)
   */
  value: number;
  /**
   * Descrição
   */
  auxiliaryDescription: string | null;
  /**
   * Tipo da conquista
   */
  rewardType: RewardTypeEnum;
}

export interface FinishOrder_FinishOrder_rewardsToChoose {
  __typename: "RewardToChooseType";
  /**
   * Código da promoção (pode repetir)
   */
  code: string;
  /**
   * Título da promoção
   */
  title: string;
  /**
   * Indica se escolheu produtos
   */
  hasChosenProducts: boolean;
  /**
   * Indica se escolheu a opção de brindes
   */
  hasChosenDiscount: boolean;
  /**
   * Número sequencial (diferenciador das opções)
   */
  giftNumber: string;
  /**
   * Número sequencial (diferenciador dos itens)
   */
  giftItemNumber: string;
  /**
   * Número máximo de brindes a serem escolhidos
   */
  maxGiftsToChoose: number;
  /**
   * Descrição
   */
  description: string | null;
  /**
   * Destino
   */
  destination: FinishOrder_FinishOrder_rewardsToChoose_destination;
  /**
   * Indica se é promoção de cupom
   */
  isCupomPromotion: boolean;
  /**
   * Produto (brinde) a ser escolhido na promoção
   */
  productsToChoose: FinishOrder_FinishOrder_rewardsToChoose_productsToChoose[] | null;
  /**
   * Detalhes de uma opção de desconto em uma promoção
   */
  discountsToChoose: FinishOrder_FinishOrder_rewardsToChoose_discountsToChoose[] | null;
}

export interface FinishOrder_FinishOrder_chosenAddress_type {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface FinishOrder_FinishOrder_chosenAddress_address {
  __typename: "RegistrationAddressType";
  /**
   * Logradouro
   */
  streetName: string | null;
  /**
   * Número
   */
  addressNumber: string | null;
  /**
   * CEP
   */
  cep: string | null;
  /**
   * Bairro
   */
  neighborhood: string | null;
  /**
   * Complemento
   */
  complement: string | null;
  /**
   * Cidade
   */
  city: string | null;
  /**
   * Estado
   */
  state: string | null;
  /**
   * País
   */
  country: string | null;
}

export interface FinishOrder_FinishOrder_chosenAddress {
  __typename: "CartDeliveryAddressType";
  /**
   * Tipo do endereço
   */
  type: FinishOrder_FinishOrder_chosenAddress_type;
  /**
   * Endereço em formato de objeto
   */
  address: FinishOrder_FinishOrder_chosenAddress_address;
  /**
   * Endereço formatado
   */
  formattedAddress: string;
  /**
   * CEP
   */
  cep: string;
}

export interface FinishOrder_FinishOrder_chosenFreightOption_type {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface FinishOrder_FinishOrder_chosenFreightOption {
  __typename: "FreightOptionResponse";
  /**
   * Código da opção de frete
   */
  code: string;
  /**
   * Nome da opção de frete
   */
  name: string;
  /**
   * Tipo
   */
  type: FinishOrder_FinishOrder_chosenFreightOption_type;
  /**
   * Valor da entrega
   */
  price: number;
  /**
   * Dias úteis para a entrega
   */
  deliveryUtilDays: number;
}

export interface FinishOrder_FinishOrder_chosenPaymentPlan_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface FinishOrder_FinishOrder_chosenPaymentPlan_creditCardOptions_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface FinishOrder_FinishOrder_chosenPaymentPlan_creditCardOptions {
  __typename: "CreditCardOptionType";
  /**
   * Descrição da bandeira do cartão de crédito
   */
  type: string;
  /**
   * Identificador da bandeira do cartão de crédito
   */
  flag: string;
  /**
   * Modo de pagamento para o cartão especificado
   */
  paymentMode: FinishOrder_FinishOrder_chosenPaymentPlan_creditCardOptions_paymentMode;
  /**
   * Parcelas possíveis de serem escolhidas
   */
  possibleNumberOfInstallments: number[] | null;
}

export interface FinishOrder_FinishOrder_chosenPaymentPlan_selectedInstallments {
  __typename: "InstallmentType";
  /**
   * Número da parcela
   */
  number: number;
  /**
   * Valor da parcela
   */
  value: number;
  /**
   * Data de vencimento
   */
  dueDate: any;
  /**
   * Indica se está bloqueada
   */
  isBlocked: boolean;
}

export interface FinishOrder_FinishOrder_chosenPaymentPlan {
  __typename: "PaymentPlanResponse";
  /**
   * Código do plano de pagamento
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Indica se consumirá crédito da revendedora
   */
  willConsumeCredit: boolean;
  /**
   * Indica se ela deve ter saldo de crédito para poder comprar
   */
  shouldVerifyCredit: boolean;
  /**
   * Indica se está bloqueado
   */
  isBlocked: boolean;
  /**
   * Taxa de administração
   */
  tax: number;
  /**
   * Número de parcelas (para planos sem cartão crédito)
   */
  installments: number | null;
  /**
   * Identificador do modo de pagamento
   */
  paymentMode: FinishOrder_FinishOrder_chosenPaymentPlan_paymentMode | null;
  /**
   * Opções quando pagamento é por cartão de crédito
   */
  creditCardOptions: FinishOrder_FinishOrder_chosenPaymentPlan_creditCardOptions[] | null;
  /**
   * Parcelas do plano selecionado
   */
  selectedInstallments: FinishOrder_FinishOrder_chosenPaymentPlan_selectedInstallments[] | null;
  /**
   * Indica se é múltiplas formas de pagamento
   */
  isMultiplePayment: boolean;
}

export interface FinishOrder_FinishOrder_totals {
  __typename: "CartTotalsType";
  /**
   * Total de venda
   */
  totalSell: number | null;
  /**
   * Lucro
   */
  profit: number | null;
  /**
   * Frete
   */
  deliveryTax: number | null;
  /**
   * Subtotal
   */
  subtotalPay: number | null;
  /**
   * Total
   */
  totalPay: number | null;
  /**
   * Lucro vindo dos brindes
   */
  profitFromGifts: number | null;
  /**
   * Valor de crédito utilizado da CCR (Conta Corrente Residual)
   */
  checkingAccount: number | null;
  /**
   * Taxa administrativa
   */
  collectionFee: number | null;
  /**
   * Impostos
   */
  taxes: number | null;
  /**
   * Comissão
   */
  commission: number | null;
}

export interface FinishOrder_FinishOrder_deliveryAddresses_type {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface FinishOrder_FinishOrder_deliveryAddresses_address {
  __typename: "RegistrationAddressType";
  /**
   * Logradouro
   */
  streetName: string | null;
  /**
   * Número
   */
  addressNumber: string | null;
  /**
   * CEP
   */
  cep: string | null;
  /**
   * Bairro
   */
  neighborhood: string | null;
  /**
   * Complemento
   */
  complement: string | null;
  /**
   * Cidade
   */
  city: string | null;
  /**
   * Estado
   */
  state: string | null;
  /**
   * País
   */
  country: string | null;
}

export interface FinishOrder_FinishOrder_deliveryAddresses {
  __typename: "CartDeliveryAddressType";
  /**
   * Tipo do endereço
   */
  type: FinishOrder_FinishOrder_deliveryAddresses_type;
  /**
   * Endereço em formato de objeto
   */
  address: FinishOrder_FinishOrder_deliveryAddresses_address;
  /**
   * Endereço formatado
   */
  formattedAddress: string;
  /**
   * CEP
   */
  cep: string;
}

export interface FinishOrder_FinishOrder_paymentPlans_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface FinishOrder_FinishOrder_paymentPlans_creditCardOptions_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface FinishOrder_FinishOrder_paymentPlans_creditCardOptions {
  __typename: "CreditCardOptionType";
  /**
   * Descrição da bandeira do cartão de crédito
   */
  type: string;
  /**
   * Identificador da bandeira do cartão de crédito
   */
  flag: string;
  /**
   * Modo de pagamento para o cartão especificado
   */
  paymentMode: FinishOrder_FinishOrder_paymentPlans_creditCardOptions_paymentMode;
  /**
   * Parcelas possíveis de serem escolhidas
   */
  possibleNumberOfInstallments: number[] | null;
}

export interface FinishOrder_FinishOrder_paymentPlans_selectedInstallments {
  __typename: "InstallmentType";
  /**
   * Número da parcela
   */
  number: number;
  /**
   * Valor da parcela
   */
  value: number;
  /**
   * Data de vencimento
   */
  dueDate: any;
  /**
   * Indica se está bloqueada
   */
  isBlocked: boolean;
}

export interface FinishOrder_FinishOrder_paymentPlans {
  __typename: "PaymentPlanResponse";
  /**
   * Código do plano de pagamento
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Indica se consumirá crédito da revendedora
   */
  willConsumeCredit: boolean;
  /**
   * Indica se ela deve ter saldo de crédito para poder comprar
   */
  shouldVerifyCredit: boolean;
  /**
   * Indica se está bloqueado
   */
  isBlocked: boolean;
  /**
   * Taxa de administração
   */
  tax: number;
  /**
   * Número de parcelas (para planos sem cartão crédito)
   */
  installments: number | null;
  /**
   * Identificador do modo de pagamento
   */
  paymentMode: FinishOrder_FinishOrder_paymentPlans_paymentMode | null;
  /**
   * Opções quando pagamento é por cartão de crédito
   */
  creditCardOptions: FinishOrder_FinishOrder_paymentPlans_creditCardOptions[] | null;
  /**
   * Parcelas do plano selecionado
   */
  selectedInstallments: FinishOrder_FinishOrder_paymentPlans_selectedInstallments[] | null;
  /**
   * Indica se é múltiplas formas de pagamento
   */
  isMultiplePayment: boolean;
}

export interface FinishOrder_FinishOrder {
  __typename: "CartResponse";
  /**
   * Código do pedido
   */
  orderCode: string;
  /**
   * Código do espaço revendedor
   */
  distributionCenterCode: string;
  /**
   * Modo de entrega (retirar na central / entrega em endereço)
   */
  pickupOnDistributionCenter: boolean | null;
  /**
   * Valor do pedido mínimo
   */
  requiredMinimum: number;
  /**
   * Valor atual considerado para o pedido mínimo
   */
  currentValueConsideredToMinimum: number;
  /**
   * Ciclo do pedido
   */
  cycle: number;
  /**
   * SubCiclo do pedido
   */
  subCycle: number;
  /**
   * Dados do link externo de pagamento
   */
  externalPaymentLink: FinishOrder_FinishOrder_externalPaymentLink | null;
  /**
   * Crédito disponível
   */
  credit: FinishOrder_FinishOrder_credit | null;
  /**
   * Produtos do pedido
   */
  products: FinishOrder_FinishOrder_products[];
  /**
   * Produtos cortados
   */
  cutProducts: FinishOrder_FinishOrder_cutProducts[];
  /**
   * Produto (brinde) conquistado
   */
  acquiredProducts: FinishOrder_FinishOrder_acquiredProducts[] | null;
  /**
   * Promoção conquistada
   */
  acquiredPromotions: FinishOrder_FinishOrder_acquiredPromotions[] | null;
  /**
   * Promoção conquistada
   */
  partialPromotions: FinishOrder_FinishOrder_partialPromotions[] | null;
  /**
   * Opção a ser escolhida na promoção (pode ser brinde ou desconto)
   */
  rewardsToChoose: FinishOrder_FinishOrder_rewardsToChoose[] | null;
  /**
   * Endereço escolhido (ou padrão) para a entrega
   */
  chosenAddress: FinishOrder_FinishOrder_chosenAddress | null;
  /**
   * Opção de frete escolhida (ou padrão)
   */
  chosenFreightOption: FinishOrder_FinishOrder_chosenFreightOption | null;
  /**
   * Opção de pagamento escolhida (ou padrão)
   */
  chosenPaymentPlan: FinishOrder_FinishOrder_chosenPaymentPlan | null;
  /**
   * Resumo de valores do pedido
   */
  totals: FinishOrder_FinishOrder_totals;
  /**
   * Endereços para entrega
   */
  deliveryAddresses: FinishOrder_FinishOrder_deliveryAddresses[] | null;
  /**
   * Planos de pagamento
   */
  paymentPlans: FinishOrder_FinishOrder_paymentPlans[] | null;
}

export interface FinishOrder {
  /**
   * # Mutation - FinishOrder
   * ## Description:
   * 
   * 
   *  - Finaliza o pedido para a revendedora
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## External Dependencies:
   * 
   * 
   *  - ### FinishOrderDatasource
   *     
   * 
   * 	
   * 	 - Endpoint Path: /cart/orders/${orderCode}/close
   * ## Internal Dependencies:
   * 
   * 
   *  - ### FinishOrderUseCase
   *     
   * 
   * 
   *  - ### FinishOrderDatasource
   *     
   * 
   * 
   *  - ### ResellerCurrentOrderDbDatasource
   */
  FinishOrder: FinishOrder_FinishOrder;
}

export interface FinishOrderVariables {
  orderCode: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: FvcEndConversation
// ====================================================

export interface FvcEndConversation_FvcEndConversation {
  __typename: "FvcResellerConversationResponse";
  /**
   * Id
   */
  id: string;
  /**
   * Id da revendedora
   */
  resellerId: string;
  /**
   * Nome da revendedora
   */
  resellerName: string | null;
  /**
   * Horário de Início do Atendimento
   */
  createdAt: any;
  /**
   * Horário da finalização do Atendimento
   */
  completedAt: any | null;
  /**
   * Indica se atendimento retornado é um agendamento
   */
  isScheduling: boolean | null;
}

export interface FvcEndConversation {
  /**
   * # Mutation - FvcEndConversation
   * ## Description:
   * 
   * 
   *  - Finaliza um atendimento.
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## Internal Dependencies:
   * 
   * 
   *  - ### FvcEndConversationUseCase
   *     
   * 
   * 
   *  - ### FeedbackDataSource
   *     
   * 
   * 
   *  - ### FvcSchedulingDbDatasource
   *     
   * 
   * 
   *  - ### ResellerActivationDbDataSource
   *     
   * 
   * 
   *  - ### FvcResellerConversationsDatasource
   *     
   * 
   * 
   *  - ### FvcResellerStructureDbDataSource
   *     
   * 
   * 
   *  - ### FVCResellerInstantMessageDatasource
   */
  FvcEndConversation: FvcEndConversation_FvcEndConversation;
}

export interface FvcEndConversationVariables {
  activation: ActivationInput;
  resellerCode: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: HandleOrder
// ====================================================

export interface HandleOrder_HandleOrder {
  __typename: "NewPaymentLinkResponse";
  /**
   * Url de pagamento
   */
  externalPaymentLink: string | null;
  /**
   * Data de expiração da url de pagamento
   */
  expirationDatePaymentLink: any | null;
}

export interface HandleOrder {
  /**
   * # Mutation - HandleOrder
   * ## Description:
   * 
   * 
   *  - Gerencia os links de pagamento do pedido de acordo a ação solicitada nos parâmetros
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## External Dependencies:
   * 
   * 
   *  - ### CartHandlerDatasource
   *     
   * 
   * 	
   * 	 - Endpoint Path: /cart/orders/${orderCode}/Handler
   * ## Internal Dependencies:
   * 
   * 
   *  - ### CartHandlerUseCase
   */
  HandleOrder: HandleOrder_HandleOrder;
}

export interface HandleOrderVariables {
  params: CartHandlerParamsInput;
  orderCode: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveFromCart
// ====================================================

export interface RemoveFromCart_RemoveFromCart_externalPaymentLink {
  __typename: "NewPaymentLinkResponse";
  /**
   * Url de pagamento
   */
  externalPaymentLink: string | null;
  /**
   * Data de expiração da url de pagamento
   */
  expirationDatePaymentLink: any | null;
}

export interface RemoveFromCart_RemoveFromCart_credit {
  __typename: "ResellerCreditResponse";
  /**
   * Crédito disponível para compra no momento
   */
  available: number | null;
}

export interface RemoveFromCart_RemoveFromCart_products_origin {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface RemoveFromCart_RemoveFromCart_products {
  __typename: "CartProductType";
  /**
   * Número representativo do produto no carrinho Gera
   */
  sequentialNumber: string;
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * URL da imagem do produto
   */
  imageUrl: string;
  /**
   * Quantidade
   */
  quantity: number;
  /**
   * Preço de mercado (consumidor)
   */
  unitMarketValue: number;
  /**
   * Preço de tabela (consumidor)
   */
  unitTableValue: number;
  /**
   * Preço promocionado (consumidor)
   */
  unitNetValue: number | null;
  /**
   * Origem do produto no pedido
   */
  origin: RemoveFromCart_RemoveFromCart_products_origin;
  /**
   * Bloqueado para edição?
   */
  blockEdit: boolean;
  /**
   * Bloqueado para remoção?
   */
  blockDelete: boolean;
  /**
   * Valor unitário de compra
   */
  unitPayValue: number;
  /**
   * Valor de compra
   */
  payValue: number;
  /**
   * Lucro
   */
  profit: number;
  /**
   * Lucro com descontos
   */
  profitWithDiscount: number;
  /**
   * Se o produto é um combo personalizável
   */
  isCombo: boolean;
}

export interface RemoveFromCart_RemoveFromCart_cutProducts_type {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface RemoveFromCart_RemoveFromCart_cutProducts_cutReason {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface RemoveFromCart_RemoveFromCart_cutProducts {
  __typename: "CartCutProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * URL da imagem do produto
   */
  imageUrl: string;
  /**
   * Quantidade
   */
  quantity: number;
  /**
   * Preço de mercado (consumidor)
   */
  unitMarketValue: number;
  /**
   * Preço de tabela (consumidor)
   */
  unitTableValue: number;
  /**
   * Preço promocionado (consumidor)
   */
  unitNetValue: number | null;
  /**
   * Tipo do item
   */
  type: RemoveFromCart_RemoveFromCart_cutProducts_type;
  /**
   * Razão do corte
   */
  cutReason: RemoveFromCart_RemoveFromCart_cutProducts_cutReason;
}

export interface RemoveFromCart_RemoveFromCart_acquiredProducts_promotion {
  __typename: "CartPromotionType";
  /**
   * Código
   */
  code: string;
  /**
   * Título
   */
  title: string;
}

export interface RemoveFromCart_RemoveFromCart_acquiredProducts {
  __typename: "AcquiredProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * URL da imagem
   */
  imageUrl: string;
  /**
   * Quantidade
   */
  quantity: number | null;
  /**
   * Promoção que originou o brinde
   */
  promotion: RemoveFromCart_RemoveFromCart_acquiredProducts_promotion | null;
}

export interface RemoveFromCart_RemoveFromCart_acquiredPromotions_destination {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface RemoveFromCart_RemoveFromCart_acquiredPromotions_rewards {
  __typename: "RewardProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Quantidade
   */
  quantity: number | null;
  /**
   * URL da imagem
   */
  imageUrl: string;
  /**
   * Desconto
   */
  discount: number;
  /**
   * Tipo
   */
  type: RewardTypeEnum;
  /**
   * Descrição
   */
  description: string | null;
  /**
   * Indica se é um brinde a ser escolhido
   */
  isGiftToChoose: boolean;
}

export interface RemoveFromCart_RemoveFromCart_acquiredPromotions {
  __typename: "AcquiredPromotionType";
  /**
   * Código da promoção
   */
  code: string;
  /**
   * Nome
   */
  title: string;
  /**
   * Descrição
   */
  description: string | null;
  /**
   * Destino
   */
  destination: RemoveFromCart_RemoveFromCart_acquiredPromotions_destination;
  /**
   * Indica se é promoção de cupom de desconto
   */
  isCupomPromotion: boolean;
  /**
   * Lista de brindes/descontos
   */
  rewards: RemoveFromCart_RemoveFromCart_acquiredPromotions_rewards[];
}

export interface RemoveFromCart_RemoveFromCart_partialPromotions_destination {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface RemoveFromCart_RemoveFromCart_partialPromotions_requirements_type {
  __typename: "RequirementType";
  /**
   * Id do tipo de requisito
   */
  id: number | null;
  /**
   * Descrição do requisito
   */
  description: string | null;
}

export interface RemoveFromCart_RemoveFromCart_partialPromotions_requirements_productRequired {
  __typename: "RequiredProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome do produto
   */
  name: string;
  /**
   * Descrição do produto
   */
  description: string | null;
  /**
   * Indica se é um kit
   */
  isKit: boolean | null;
  /**
   * Quantidade de pontos
   */
  points: number | null;
  /**
   * Preço
   */
  price: number | null;
}

export interface RemoveFromCart_RemoveFromCart_partialPromotions_requirements_structureRequired {
  __typename: "RequiredStructureType";
  /**
   * Código da estrutura
   */
  code: string;
  /**
   * Nome da estrutura
   */
  name: string;
}

export interface RemoveFromCart_RemoveFromCart_partialPromotions_requirements {
  __typename: "PromotionRequirementType";
  /**
   * Valor faltante para obter a promoção
   */
  missingValue: number;
  /**
   * Tipo do requisito
   */
  type: RemoveFromCart_RemoveFromCart_partialPromotions_requirements_type;
  /**
   * Produto requisitado
   */
  productRequired: RemoveFromCart_RemoveFromCart_partialPromotions_requirements_productRequired | null;
  /**
   * Estrutura na qual a compra deve ser feita
   */
  structureRequired: RemoveFromCart_RemoveFromCart_partialPromotions_requirements_structureRequired | null;
  /**
   * Descrição
   */
  description: string | null;
}

export interface RemoveFromCart_RemoveFromCart_partialPromotions {
  __typename: "PartialPromotionType";
  /**
   * Código da promoção
   */
  code: string;
  /**
   * Nome
   */
  title: string;
  /**
   * Descrição
   */
  description: string | null;
  /**
   * Destino
   */
  destination: RemoveFromCart_RemoveFromCart_partialPromotions_destination;
  /**
   * Indica se é promoção de cupom de desconto
   */
  isCupomPromotion: boolean;
  /**
   * Lista de requisitos para conquistar a promoção
   */
  requirements: RemoveFromCart_RemoveFromCart_partialPromotions_requirements[];
}

export interface RemoveFromCart_RemoveFromCart_rewardsToChoose_destination {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface RemoveFromCart_RemoveFromCart_rewardsToChoose_productsToChoose {
  __typename: "RewardProductToChooseType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Url da imagem
   */
  imageUrl: string;
  /**
   * Quantidade escolhida
   */
  chosenQuantity: number | null;
  /**
   * Quantidade máxima a ser selecionada
   */
  maxQuantity: number;
  /**
   * Indica se está indisponível (só atualiza depois de escolhido)
   */
  unavailable: boolean;
  /**
   * ?
   */
  isReorderAllowed: boolean;
}

export interface RemoveFromCart_RemoveFromCart_rewardsToChoose_discountsToChoose {
  __typename: "RewardDiscountToChooseType";
  /**
   * Código da opção de desconto
   */
  code: string;
  /**
   * Valor do desconto (unidade é de acordo com o tipo do desconto)
   */
  value: number;
  /**
   * Descrição
   */
  auxiliaryDescription: string | null;
  /**
   * Tipo da conquista
   */
  rewardType: RewardTypeEnum;
}

export interface RemoveFromCart_RemoveFromCart_rewardsToChoose {
  __typename: "RewardToChooseType";
  /**
   * Código da promoção (pode repetir)
   */
  code: string;
  /**
   * Título da promoção
   */
  title: string;
  /**
   * Indica se escolheu produtos
   */
  hasChosenProducts: boolean;
  /**
   * Indica se escolheu a opção de brindes
   */
  hasChosenDiscount: boolean;
  /**
   * Número sequencial (diferenciador das opções)
   */
  giftNumber: string;
  /**
   * Número sequencial (diferenciador dos itens)
   */
  giftItemNumber: string;
  /**
   * Número máximo de brindes a serem escolhidos
   */
  maxGiftsToChoose: number;
  /**
   * Descrição
   */
  description: string | null;
  /**
   * Destino
   */
  destination: RemoveFromCart_RemoveFromCart_rewardsToChoose_destination;
  /**
   * Indica se é promoção de cupom
   */
  isCupomPromotion: boolean;
  /**
   * Produto (brinde) a ser escolhido na promoção
   */
  productsToChoose: RemoveFromCart_RemoveFromCart_rewardsToChoose_productsToChoose[] | null;
  /**
   * Detalhes de uma opção de desconto em uma promoção
   */
  discountsToChoose: RemoveFromCart_RemoveFromCart_rewardsToChoose_discountsToChoose[] | null;
}

export interface RemoveFromCart_RemoveFromCart_chosenAddress_type {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface RemoveFromCart_RemoveFromCart_chosenAddress_address {
  __typename: "RegistrationAddressType";
  /**
   * Logradouro
   */
  streetName: string | null;
  /**
   * Número
   */
  addressNumber: string | null;
  /**
   * CEP
   */
  cep: string | null;
  /**
   * Bairro
   */
  neighborhood: string | null;
  /**
   * Complemento
   */
  complement: string | null;
  /**
   * Cidade
   */
  city: string | null;
  /**
   * Estado
   */
  state: string | null;
  /**
   * País
   */
  country: string | null;
}

export interface RemoveFromCart_RemoveFromCart_chosenAddress {
  __typename: "CartDeliveryAddressType";
  /**
   * Tipo do endereço
   */
  type: RemoveFromCart_RemoveFromCart_chosenAddress_type;
  /**
   * Endereço em formato de objeto
   */
  address: RemoveFromCart_RemoveFromCart_chosenAddress_address;
  /**
   * Endereço formatado
   */
  formattedAddress: string;
  /**
   * CEP
   */
  cep: string;
}

export interface RemoveFromCart_RemoveFromCart_chosenFreightOption_type {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface RemoveFromCart_RemoveFromCart_chosenFreightOption {
  __typename: "FreightOptionResponse";
  /**
   * Código da opção de frete
   */
  code: string;
  /**
   * Nome da opção de frete
   */
  name: string;
  /**
   * Tipo
   */
  type: RemoveFromCart_RemoveFromCart_chosenFreightOption_type;
  /**
   * Valor da entrega
   */
  price: number;
  /**
   * Dias úteis para a entrega
   */
  deliveryUtilDays: number;
}

export interface RemoveFromCart_RemoveFromCart_chosenPaymentPlan_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface RemoveFromCart_RemoveFromCart_chosenPaymentPlan_creditCardOptions_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface RemoveFromCart_RemoveFromCart_chosenPaymentPlan_creditCardOptions {
  __typename: "CreditCardOptionType";
  /**
   * Descrição da bandeira do cartão de crédito
   */
  type: string;
  /**
   * Identificador da bandeira do cartão de crédito
   */
  flag: string;
  /**
   * Modo de pagamento para o cartão especificado
   */
  paymentMode: RemoveFromCart_RemoveFromCart_chosenPaymentPlan_creditCardOptions_paymentMode;
  /**
   * Parcelas possíveis de serem escolhidas
   */
  possibleNumberOfInstallments: number[] | null;
}

export interface RemoveFromCart_RemoveFromCart_chosenPaymentPlan_selectedInstallments {
  __typename: "InstallmentType";
  /**
   * Número da parcela
   */
  number: number;
  /**
   * Valor da parcela
   */
  value: number;
  /**
   * Data de vencimento
   */
  dueDate: any;
  /**
   * Indica se está bloqueada
   */
  isBlocked: boolean;
}

export interface RemoveFromCart_RemoveFromCart_chosenPaymentPlan {
  __typename: "PaymentPlanResponse";
  /**
   * Código do plano de pagamento
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Indica se consumirá crédito da revendedora
   */
  willConsumeCredit: boolean;
  /**
   * Indica se ela deve ter saldo de crédito para poder comprar
   */
  shouldVerifyCredit: boolean;
  /**
   * Indica se está bloqueado
   */
  isBlocked: boolean;
  /**
   * Taxa de administração
   */
  tax: number;
  /**
   * Número de parcelas (para planos sem cartão crédito)
   */
  installments: number | null;
  /**
   * Identificador do modo de pagamento
   */
  paymentMode: RemoveFromCart_RemoveFromCart_chosenPaymentPlan_paymentMode | null;
  /**
   * Opções quando pagamento é por cartão de crédito
   */
  creditCardOptions: RemoveFromCart_RemoveFromCart_chosenPaymentPlan_creditCardOptions[] | null;
  /**
   * Parcelas do plano selecionado
   */
  selectedInstallments: RemoveFromCart_RemoveFromCart_chosenPaymentPlan_selectedInstallments[] | null;
  /**
   * Indica se é múltiplas formas de pagamento
   */
  isMultiplePayment: boolean;
}

export interface RemoveFromCart_RemoveFromCart_totals {
  __typename: "CartTotalsType";
  /**
   * Total de venda
   */
  totalSell: number | null;
  /**
   * Lucro
   */
  profit: number | null;
  /**
   * Frete
   */
  deliveryTax: number | null;
  /**
   * Subtotal
   */
  subtotalPay: number | null;
  /**
   * Total
   */
  totalPay: number | null;
  /**
   * Lucro vindo dos brindes
   */
  profitFromGifts: number | null;
  /**
   * Valor de crédito utilizado da CCR (Conta Corrente Residual)
   */
  checkingAccount: number | null;
  /**
   * Taxa administrativa
   */
  collectionFee: number | null;
  /**
   * Impostos
   */
  taxes: number | null;
  /**
   * Comissão
   */
  commission: number | null;
}

export interface RemoveFromCart_RemoveFromCart_deliveryAddresses_type {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface RemoveFromCart_RemoveFromCart_deliveryAddresses_address {
  __typename: "RegistrationAddressType";
  /**
   * Logradouro
   */
  streetName: string | null;
  /**
   * Número
   */
  addressNumber: string | null;
  /**
   * CEP
   */
  cep: string | null;
  /**
   * Bairro
   */
  neighborhood: string | null;
  /**
   * Complemento
   */
  complement: string | null;
  /**
   * Cidade
   */
  city: string | null;
  /**
   * Estado
   */
  state: string | null;
  /**
   * País
   */
  country: string | null;
}

export interface RemoveFromCart_RemoveFromCart_deliveryAddresses {
  __typename: "CartDeliveryAddressType";
  /**
   * Tipo do endereço
   */
  type: RemoveFromCart_RemoveFromCart_deliveryAddresses_type;
  /**
   * Endereço em formato de objeto
   */
  address: RemoveFromCart_RemoveFromCart_deliveryAddresses_address;
  /**
   * Endereço formatado
   */
  formattedAddress: string;
  /**
   * CEP
   */
  cep: string;
}

export interface RemoveFromCart_RemoveFromCart_paymentPlans_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface RemoveFromCart_RemoveFromCart_paymentPlans_creditCardOptions_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface RemoveFromCart_RemoveFromCart_paymentPlans_creditCardOptions {
  __typename: "CreditCardOptionType";
  /**
   * Descrição da bandeira do cartão de crédito
   */
  type: string;
  /**
   * Identificador da bandeira do cartão de crédito
   */
  flag: string;
  /**
   * Modo de pagamento para o cartão especificado
   */
  paymentMode: RemoveFromCart_RemoveFromCart_paymentPlans_creditCardOptions_paymentMode;
  /**
   * Parcelas possíveis de serem escolhidas
   */
  possibleNumberOfInstallments: number[] | null;
}

export interface RemoveFromCart_RemoveFromCart_paymentPlans_selectedInstallments {
  __typename: "InstallmentType";
  /**
   * Número da parcela
   */
  number: number;
  /**
   * Valor da parcela
   */
  value: number;
  /**
   * Data de vencimento
   */
  dueDate: any;
  /**
   * Indica se está bloqueada
   */
  isBlocked: boolean;
}

export interface RemoveFromCart_RemoveFromCart_paymentPlans {
  __typename: "PaymentPlanResponse";
  /**
   * Código do plano de pagamento
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Indica se consumirá crédito da revendedora
   */
  willConsumeCredit: boolean;
  /**
   * Indica se ela deve ter saldo de crédito para poder comprar
   */
  shouldVerifyCredit: boolean;
  /**
   * Indica se está bloqueado
   */
  isBlocked: boolean;
  /**
   * Taxa de administração
   */
  tax: number;
  /**
   * Número de parcelas (para planos sem cartão crédito)
   */
  installments: number | null;
  /**
   * Identificador do modo de pagamento
   */
  paymentMode: RemoveFromCart_RemoveFromCart_paymentPlans_paymentMode | null;
  /**
   * Opções quando pagamento é por cartão de crédito
   */
  creditCardOptions: RemoveFromCart_RemoveFromCart_paymentPlans_creditCardOptions[] | null;
  /**
   * Parcelas do plano selecionado
   */
  selectedInstallments: RemoveFromCart_RemoveFromCart_paymentPlans_selectedInstallments[] | null;
  /**
   * Indica se é múltiplas formas de pagamento
   */
  isMultiplePayment: boolean;
}

export interface RemoveFromCart_RemoveFromCart {
  __typename: "CartResponse";
  /**
   * Código do pedido
   */
  orderCode: string;
  /**
   * Código do espaço revendedor
   */
  distributionCenterCode: string;
  /**
   * Modo de entrega (retirar na central / entrega em endereço)
   */
  pickupOnDistributionCenter: boolean | null;
  /**
   * Valor do pedido mínimo
   */
  requiredMinimum: number;
  /**
   * Valor atual considerado para o pedido mínimo
   */
  currentValueConsideredToMinimum: number;
  /**
   * Ciclo do pedido
   */
  cycle: number;
  /**
   * SubCiclo do pedido
   */
  subCycle: number;
  /**
   * Dados do link externo de pagamento
   */
  externalPaymentLink: RemoveFromCart_RemoveFromCart_externalPaymentLink | null;
  /**
   * Crédito disponível
   */
  credit: RemoveFromCart_RemoveFromCart_credit | null;
  /**
   * Produtos do pedido
   */
  products: RemoveFromCart_RemoveFromCart_products[];
  /**
   * Produtos cortados
   */
  cutProducts: RemoveFromCart_RemoveFromCart_cutProducts[];
  /**
   * Produto (brinde) conquistado
   */
  acquiredProducts: RemoveFromCart_RemoveFromCart_acquiredProducts[] | null;
  /**
   * Promoção conquistada
   */
  acquiredPromotions: RemoveFromCart_RemoveFromCart_acquiredPromotions[] | null;
  /**
   * Promoção conquistada
   */
  partialPromotions: RemoveFromCart_RemoveFromCart_partialPromotions[] | null;
  /**
   * Opção a ser escolhida na promoção (pode ser brinde ou desconto)
   */
  rewardsToChoose: RemoveFromCart_RemoveFromCart_rewardsToChoose[] | null;
  /**
   * Endereço escolhido (ou padrão) para a entrega
   */
  chosenAddress: RemoveFromCart_RemoveFromCart_chosenAddress | null;
  /**
   * Opção de frete escolhida (ou padrão)
   */
  chosenFreightOption: RemoveFromCart_RemoveFromCart_chosenFreightOption | null;
  /**
   * Opção de pagamento escolhida (ou padrão)
   */
  chosenPaymentPlan: RemoveFromCart_RemoveFromCart_chosenPaymentPlan | null;
  /**
   * Resumo de valores do pedido
   */
  totals: RemoveFromCart_RemoveFromCart_totals;
  /**
   * Endereços para entrega
   */
  deliveryAddresses: RemoveFromCart_RemoveFromCart_deliveryAddresses[] | null;
  /**
   * Planos de pagamento
   */
  paymentPlans: RemoveFromCart_RemoveFromCart_paymentPlans[] | null;
}

export interface RemoveFromCart {
  /**
   * # Mutation - RemoveFromCart
   * ## Description:
   * 
   * 
   *  - Remove um produto do pedido da revendedora
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## External Dependencies:
   * 
   * 
   *  - ### ManageOrderDatasource
   *     
   * 
   * 	
   * 	 - Endpoint Path: /cart/orders/${orderCode}/Manage
   * ## Internal Dependencies:
   * 
   * 
   *  - ### RemoveFromCartUseCase
   *     
   * 
   * 
   *  - ### ManageOrderDatasource
   */
  RemoveFromCart: RemoveFromCart_RemoveFromCart;
}

export interface RemoveFromCartVariables {
  data: RemoveFromCartInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveMultiplePaymentOption
// ====================================================

export interface RemoveMultiplePaymentOption_RemoveMultiplePaymentOption_paymentPlanSelected_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface RemoveMultiplePaymentOption_RemoveMultiplePaymentOption_paymentPlanSelected_creditCardOptions_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface RemoveMultiplePaymentOption_RemoveMultiplePaymentOption_paymentPlanSelected_creditCardOptions {
  __typename: "CreditCardOptionType";
  /**
   * Descrição da bandeira do cartão de crédito
   */
  type: string;
  /**
   * Identificador da bandeira do cartão de crédito
   */
  flag: string;
  /**
   * Modo de pagamento para o cartão especificado
   */
  paymentMode: RemoveMultiplePaymentOption_RemoveMultiplePaymentOption_paymentPlanSelected_creditCardOptions_paymentMode;
  /**
   * Parcelas possíveis de serem escolhidas
   */
  possibleNumberOfInstallments: number[] | null;
}

export interface RemoveMultiplePaymentOption_RemoveMultiplePaymentOption_paymentPlanSelected_selectedInstallments {
  __typename: "InstallmentType";
  /**
   * Número da parcela
   */
  number: number;
  /**
   * Valor da parcela
   */
  value: number;
  /**
   * Data de vencimento
   */
  dueDate: any;
  /**
   * Indica se está bloqueada
   */
  isBlocked: boolean;
}

export interface RemoveMultiplePaymentOption_RemoveMultiplePaymentOption_paymentPlanSelected {
  __typename: "PaymentPlanResponse";
  /**
   * Código do plano de pagamento
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Indica se consumirá crédito da revendedora
   */
  willConsumeCredit: boolean;
  /**
   * Indica se ela deve ter saldo de crédito para poder comprar
   */
  shouldVerifyCredit: boolean;
  /**
   * Indica se está bloqueado
   */
  isBlocked: boolean;
  /**
   * Taxa de administração
   */
  tax: number;
  /**
   * Número de parcelas (para planos sem cartão crédito)
   */
  installments: number | null;
  /**
   * Identificador do modo de pagamento
   */
  paymentMode: RemoveMultiplePaymentOption_RemoveMultiplePaymentOption_paymentPlanSelected_paymentMode | null;
  /**
   * Opções quando pagamento é por cartão de crédito
   */
  creditCardOptions: RemoveMultiplePaymentOption_RemoveMultiplePaymentOption_paymentPlanSelected_creditCardOptions[] | null;
  /**
   * Parcelas do plano selecionado
   */
  selectedInstallments: RemoveMultiplePaymentOption_RemoveMultiplePaymentOption_paymentPlanSelected_selectedInstallments[] | null;
  /**
   * Indica se é múltiplas formas de pagamento
   */
  isMultiplePayment: boolean;
}

export interface RemoveMultiplePaymentOption_RemoveMultiplePaymentOption_paymentPlansAvailable_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface RemoveMultiplePaymentOption_RemoveMultiplePaymentOption_paymentPlansAvailable_creditCardOptions_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface RemoveMultiplePaymentOption_RemoveMultiplePaymentOption_paymentPlansAvailable_creditCardOptions {
  __typename: "CreditCardOptionType";
  /**
   * Descrição da bandeira do cartão de crédito
   */
  type: string;
  /**
   * Identificador da bandeira do cartão de crédito
   */
  flag: string;
  /**
   * Modo de pagamento para o cartão especificado
   */
  paymentMode: RemoveMultiplePaymentOption_RemoveMultiplePaymentOption_paymentPlansAvailable_creditCardOptions_paymentMode;
  /**
   * Parcelas possíveis de serem escolhidas
   */
  possibleNumberOfInstallments: number[] | null;
}

export interface RemoveMultiplePaymentOption_RemoveMultiplePaymentOption_paymentPlansAvailable_selectedInstallments {
  __typename: "InstallmentType";
  /**
   * Número da parcela
   */
  number: number;
  /**
   * Valor da parcela
   */
  value: number;
  /**
   * Data de vencimento
   */
  dueDate: any;
  /**
   * Indica se está bloqueada
   */
  isBlocked: boolean;
}

export interface RemoveMultiplePaymentOption_RemoveMultiplePaymentOption_paymentPlansAvailable {
  __typename: "PaymentPlanResponse";
  /**
   * Código do plano de pagamento
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Indica se consumirá crédito da revendedora
   */
  willConsumeCredit: boolean;
  /**
   * Indica se ela deve ter saldo de crédito para poder comprar
   */
  shouldVerifyCredit: boolean;
  /**
   * Indica se está bloqueado
   */
  isBlocked: boolean;
  /**
   * Taxa de administração
   */
  tax: number;
  /**
   * Número de parcelas (para planos sem cartão crédito)
   */
  installments: number | null;
  /**
   * Identificador do modo de pagamento
   */
  paymentMode: RemoveMultiplePaymentOption_RemoveMultiplePaymentOption_paymentPlansAvailable_paymentMode | null;
  /**
   * Opções quando pagamento é por cartão de crédito
   */
  creditCardOptions: RemoveMultiplePaymentOption_RemoveMultiplePaymentOption_paymentPlansAvailable_creditCardOptions[] | null;
  /**
   * Parcelas do plano selecionado
   */
  selectedInstallments: RemoveMultiplePaymentOption_RemoveMultiplePaymentOption_paymentPlansAvailable_selectedInstallments[] | null;
  /**
   * Indica se é múltiplas formas de pagamento
   */
  isMultiplePayment: boolean;
}

export interface RemoveMultiplePaymentOption_RemoveMultiplePaymentOption_creditCardFlag_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface RemoveMultiplePaymentOption_RemoveMultiplePaymentOption_creditCardFlag {
  __typename: "CreditCardOptionType";
  /**
   * Descrição da bandeira do cartão de crédito
   */
  type: string;
  /**
   * Identificador da bandeira do cartão de crédito
   */
  flag: string;
  /**
   * Modo de pagamento para o cartão especificado
   */
  paymentMode: RemoveMultiplePaymentOption_RemoveMultiplePaymentOption_creditCardFlag_paymentMode;
  /**
   * Parcelas possíveis de serem escolhidas
   */
  possibleNumberOfInstallments: number[] | null;
}

export interface RemoveMultiplePaymentOption_RemoveMultiplePaymentOption_statusPayment {
  __typename: "StatusPaymentType";
  /**
   * Cartão recusado
   */
  refused: boolean | null;
  /**
   * Pagamento cancelado
   */
  cancel: boolean | null;
  /**
   * Pagamento aprovado
   */
  approved: boolean | null;
  /**
   * Em processamento
   */
  notProcess: boolean | null;
  /**
   * Esperando aprovação do pagamento
   */
  waitingApproved: boolean | null;
  /**
   * Em processo de cancelamento
   */
  waitingCancel: boolean | null;
  /**
   * Pagamento com erro
   */
  failed: boolean | null;
}

export interface RemoveMultiplePaymentOption_RemoveMultiplePaymentOption_fraudPrevention {
  __typename: "FraudPreventionType";
  /**
   * App
   */
  app: string;
  /**
   * Session id
   */
  sessionID: string;
}

export interface RemoveMultiplePaymentOption_RemoveMultiplePaymentOption {
  __typename: "PaymentOptionSelectedType";
  /**
   * Id do pagamento
   */
  id: number | null;
  /**
   * Plano de pagamento selecionado
   */
  paymentPlanSelected: RemoveMultiplePaymentOption_RemoveMultiplePaymentOption_paymentPlanSelected;
  /**
   * Plano de pagamento disponíveis
   */
  paymentPlansAvailable: RemoveMultiplePaymentOption_RemoveMultiplePaymentOption_paymentPlansAvailable[] | null;
  /**
   * Código do pagamento
   */
  distributionCodeDetails: number | null;
  /**
   * Valor escolhido
   */
  amount: number;
  /**
   * Informação do cartão
   */
  creditCardFlag: RemoveMultiplePaymentOption_RemoveMultiplePaymentOption_creditCardFlag | null;
  /**
   * Status do pagamento do cartão
   */
  statusPayment: RemoveMultiplePaymentOption_RemoveMultiplePaymentOption_statusPayment | null;
  /**
   * Prevenção de fraude
   */
  fraudPrevention: RemoveMultiplePaymentOption_RemoveMultiplePaymentOption_fraudPrevention | null;
  /**
   * Bloqueado para alteração
   */
  isChangeBlocked: boolean;
  /**
   * Quantidade de parcelas selecionada
   */
  installmentSelected: number | null;
  /**
   * Número do pagamento
   */
  numberPayment: number | null;
}

export interface RemoveMultiplePaymentOption {
  /**
   * # Mutation - RemoveMultiplePaymentOption
   * ## Description:
   * 
   * 
   *  - Remove um pagamento da múltiplas formas
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## External Dependencies:
   * 
   * 
   *  - ### MultiplePaymentDatasource
   *     
   * 
   * 	
   * 	 - Endpoint Path: orders/${orderCode}/multiplePaymentPlans/${distributionCodeDetails}
   * ## Internal Dependencies:
   * 
   * 
   *  - ### MultiplePaymentRemoveOptionsUseCase
   */
  RemoveMultiplePaymentOption: RemoveMultiplePaymentOption_RemoveMultiplePaymentOption[];
}

export interface RemoveMultiplePaymentOptionVariables {
  data: RemoveMultiplePaymentOptionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: FvcRescheduling
// ====================================================

export interface FvcRescheduling_FvcRescheduling {
  __typename: "FvcSchedulingResponse";
  /**
   * Horário do agendamento
   */
  scheduledTo: any;
}

export interface FvcRescheduling {
  /**
   * # Mutation - FvcRescheduling
   * ## Description:
   * 
   * 
   *  - Remarca um agendamento
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## Internal Dependencies:
   * 
   * 
   *  - ### FvcReschedulingUseCase
   *     
   * 
   * 
   *  - ### FvcSchedulingDbDatasource
   */
  FvcRescheduling: FvcRescheduling_FvcRescheduling;
}

export interface FvcReschedulingVariables {
  id: number;
  scheduledTo: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ResellerUpdateAddress
// ====================================================

export interface ResellerUpdateAddress {
  /**
   * # Mutation - ResellerUpdateAddress
   * ## Description:
   * 
   * 
   *  - Atualiza endereços de uma revendedora
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## Internal Dependencies:
   * 
   * 
   *  - ### ResellerUpdateAddressUseCase
   *     
   * 
   * 
   *  - ### ResellerDbDataSource
   */
  ResellerUpdateAddress: boolean;
}

export interface ResellerUpdateAddressVariables {
  id: string;
  data: (string | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ResellerUpdateEmail
// ====================================================

export interface ResellerUpdateEmail {
  /**
   * # Mutation - ResellerUpdateEmail
   * ## Description:
   * 
   * 
   *  - Atualiza emails de uma revendedora
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## Internal Dependencies:
   * 
   * 
   *  - ### ResellerUpdateEmailUseCase
   *     
   * 
   * 
   *  - ### ResellerDbDataSource
   */
  ResellerUpdateEmail: boolean;
}

export interface ResellerUpdateEmailVariables {
  id: string;
  data: (string | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ResellerUpdateNotes
// ====================================================

export interface ResellerUpdateNotes {
  /**
   * Atualiza notas adicionais de uma revendedora
   */
  ResellerUpdateNotes: boolean;
}

export interface ResellerUpdateNotesVariables {
  id: string;
  data: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ResellerUpdatePhone
// ====================================================

export interface ResellerUpdatePhone {
  /**
   * # Mutation - ResellerUpdatePhone
   * ## Description:
   * 
   * 
   *  - Atualiza telefones de uma revendedora
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## Internal Dependencies:
   * 
   * 
   *  - ### ResellerUpdatePhoneUseCase
   *     
   * 
   * 
   *  - ### ResellerDbDataSource
   */
  ResellerUpdatePhone: boolean;
}

export interface ResellerUpdatePhoneVariables {
  id: string;
  data: (string | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendMessage
// ====================================================

export interface SendMessage_SendMessage {
  __typename: "MessageResponse";
  /**
   * Identificador da mensagem
   */
  id: string;
  /**
   * Telefone remetente
   */
  from: string;
  /**
   * Telefone destino
   */
  to: string;
  /**
   * Tipo da mensagem
   */
  type: MessageType | null;
  /**
   * Conteúdo da mensagem
   */
  message: string | null;
  /**
   * URL da imagem/áudio
   */
  mediaUrl: string | null;
  /**
   * Status
   */
  status: MessageStatus;
  /**
   * Data de envio (UTC)
   */
  sentAt: any | null;
}

export interface SendMessage {
  /**
   * # Mutation - SendMessage
   * ## Description:
   * 
   * 
   *  - Envia uma mensagem direta a uma revendedora
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## External Dependencies:
   * 
   * 
   *  - ### InstantMessageHttpDatasource
   *     
   * 
   * 	
   * 	 - Endpoint Path: /messages
   * ## Internal Dependencies:
   * 
   * 
   *  - ### SendMessageUseCase
   *     
   * 
   * 
   *  - ### ResellerMasterDataMetricDbDataSource
   *     
   * 
   * 
   *  - ### FvcStructureDbDataSource
   *     
   * 
   * 
   *  - ### FVCResellerInstantMessageDatasource
   *     
   * 
   * 
   *  - ### SafeGetMediaUrlUseCase
   */
  SendMessage: SendMessage_SendMessage | null;
}

export interface SendMessageVariables {
  data: SendMessageInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendTemplateMessage
// ====================================================

export interface SendTemplateMessage_SendTemplateMessage {
  __typename: "MessageResponse";
  /**
   * Identificador da mensagem
   */
  id: string;
  /**
   * Telefone remetente
   */
  from: string;
  /**
   * Telefone destino
   */
  to: string;
  /**
   * Tipo da mensagem
   */
  type: MessageType | null;
  /**
   * Conteúdo da mensagem
   */
  message: string | null;
  /**
   * URL da imagem/áudio
   */
  mediaUrl: string | null;
  /**
   * Status
   */
  status: MessageStatus;
  /**
   * Data de envio (UTC)
   */
  sentAt: any | null;
}

export interface SendTemplateMessage {
  /**
   * # Mutation - SendTemplateMessage
   * ## Description:
   * 
   * 
   *  - Envia uma mensagem de template para uma revendedora
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## External Dependencies:
   * 
   * 
   *  - ### InstantMessageHttpDatasource
   *     
   * 
   * 	
   * 	 - Endpoint Path: /messages
   * ## Internal Dependencies:
   * 
   * 
   *  - ### SendTemplateMessageUseCase
   *     
   * 
   * 
   *  - ### ResellerMasterDataMetricDbDataSource
   *     
   * 
   * 
   *  - ### FvcStructureDbDataSource
   *     
   * 
   * 
   *  - ### FVCResellerInstantMessageDatasource
   *     
   * 
   * 
   *  - ### SafeGetMediaUrlUseCase
   */
  SendTemplateMessage: SendTemplateMessage_SendTemplateMessage | null;
}

export interface SendTemplateMessageVariables {
  data: SendTemplateMessageInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetCoupon
// ====================================================

export interface SetCoupon_SetCoupon_externalPaymentLink {
  __typename: "NewPaymentLinkResponse";
  /**
   * Url de pagamento
   */
  externalPaymentLink: string | null;
  /**
   * Data de expiração da url de pagamento
   */
  expirationDatePaymentLink: any | null;
}

export interface SetCoupon_SetCoupon_credit {
  __typename: "ResellerCreditResponse";
  /**
   * Crédito disponível para compra no momento
   */
  available: number | null;
}

export interface SetCoupon_SetCoupon_products_origin {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface SetCoupon_SetCoupon_products {
  __typename: "CartProductType";
  /**
   * Número representativo do produto no carrinho Gera
   */
  sequentialNumber: string;
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * URL da imagem do produto
   */
  imageUrl: string;
  /**
   * Quantidade
   */
  quantity: number;
  /**
   * Preço de mercado (consumidor)
   */
  unitMarketValue: number;
  /**
   * Preço de tabela (consumidor)
   */
  unitTableValue: number;
  /**
   * Preço promocionado (consumidor)
   */
  unitNetValue: number | null;
  /**
   * Origem do produto no pedido
   */
  origin: SetCoupon_SetCoupon_products_origin;
  /**
   * Bloqueado para edição?
   */
  blockEdit: boolean;
  /**
   * Bloqueado para remoção?
   */
  blockDelete: boolean;
  /**
   * Valor unitário de compra
   */
  unitPayValue: number;
  /**
   * Valor de compra
   */
  payValue: number;
  /**
   * Lucro
   */
  profit: number;
  /**
   * Lucro com descontos
   */
  profitWithDiscount: number;
  /**
   * Se o produto é um combo personalizável
   */
  isCombo: boolean;
}

export interface SetCoupon_SetCoupon_cutProducts_type {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface SetCoupon_SetCoupon_cutProducts_cutReason {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface SetCoupon_SetCoupon_cutProducts {
  __typename: "CartCutProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * URL da imagem do produto
   */
  imageUrl: string;
  /**
   * Quantidade
   */
  quantity: number;
  /**
   * Preço de mercado (consumidor)
   */
  unitMarketValue: number;
  /**
   * Preço de tabela (consumidor)
   */
  unitTableValue: number;
  /**
   * Preço promocionado (consumidor)
   */
  unitNetValue: number | null;
  /**
   * Tipo do item
   */
  type: SetCoupon_SetCoupon_cutProducts_type;
  /**
   * Razão do corte
   */
  cutReason: SetCoupon_SetCoupon_cutProducts_cutReason;
}

export interface SetCoupon_SetCoupon_acquiredProducts_promotion {
  __typename: "CartPromotionType";
  /**
   * Código
   */
  code: string;
  /**
   * Título
   */
  title: string;
}

export interface SetCoupon_SetCoupon_acquiredProducts {
  __typename: "AcquiredProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * URL da imagem
   */
  imageUrl: string;
  /**
   * Quantidade
   */
  quantity: number | null;
  /**
   * Promoção que originou o brinde
   */
  promotion: SetCoupon_SetCoupon_acquiredProducts_promotion | null;
}

export interface SetCoupon_SetCoupon_acquiredPromotions_destination {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface SetCoupon_SetCoupon_acquiredPromotions_rewards {
  __typename: "RewardProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Quantidade
   */
  quantity: number | null;
  /**
   * URL da imagem
   */
  imageUrl: string;
  /**
   * Desconto
   */
  discount: number;
  /**
   * Tipo
   */
  type: RewardTypeEnum;
  /**
   * Descrição
   */
  description: string | null;
  /**
   * Indica se é um brinde a ser escolhido
   */
  isGiftToChoose: boolean;
}

export interface SetCoupon_SetCoupon_acquiredPromotions {
  __typename: "AcquiredPromotionType";
  /**
   * Código da promoção
   */
  code: string;
  /**
   * Nome
   */
  title: string;
  /**
   * Descrição
   */
  description: string | null;
  /**
   * Destino
   */
  destination: SetCoupon_SetCoupon_acquiredPromotions_destination;
  /**
   * Indica se é promoção de cupom de desconto
   */
  isCupomPromotion: boolean;
  /**
   * Lista de brindes/descontos
   */
  rewards: SetCoupon_SetCoupon_acquiredPromotions_rewards[];
}

export interface SetCoupon_SetCoupon_partialPromotions_destination {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface SetCoupon_SetCoupon_partialPromotions_requirements_type {
  __typename: "RequirementType";
  /**
   * Id do tipo de requisito
   */
  id: number | null;
  /**
   * Descrição do requisito
   */
  description: string | null;
}

export interface SetCoupon_SetCoupon_partialPromotions_requirements_productRequired {
  __typename: "RequiredProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome do produto
   */
  name: string;
  /**
   * Descrição do produto
   */
  description: string | null;
  /**
   * Indica se é um kit
   */
  isKit: boolean | null;
  /**
   * Quantidade de pontos
   */
  points: number | null;
  /**
   * Preço
   */
  price: number | null;
}

export interface SetCoupon_SetCoupon_partialPromotions_requirements_structureRequired {
  __typename: "RequiredStructureType";
  /**
   * Código da estrutura
   */
  code: string;
  /**
   * Nome da estrutura
   */
  name: string;
}

export interface SetCoupon_SetCoupon_partialPromotions_requirements {
  __typename: "PromotionRequirementType";
  /**
   * Valor faltante para obter a promoção
   */
  missingValue: number;
  /**
   * Tipo do requisito
   */
  type: SetCoupon_SetCoupon_partialPromotions_requirements_type;
  /**
   * Produto requisitado
   */
  productRequired: SetCoupon_SetCoupon_partialPromotions_requirements_productRequired | null;
  /**
   * Estrutura na qual a compra deve ser feita
   */
  structureRequired: SetCoupon_SetCoupon_partialPromotions_requirements_structureRequired | null;
  /**
   * Descrição
   */
  description: string | null;
}

export interface SetCoupon_SetCoupon_partialPromotions {
  __typename: "PartialPromotionType";
  /**
   * Código da promoção
   */
  code: string;
  /**
   * Nome
   */
  title: string;
  /**
   * Descrição
   */
  description: string | null;
  /**
   * Destino
   */
  destination: SetCoupon_SetCoupon_partialPromotions_destination;
  /**
   * Indica se é promoção de cupom de desconto
   */
  isCupomPromotion: boolean;
  /**
   * Lista de requisitos para conquistar a promoção
   */
  requirements: SetCoupon_SetCoupon_partialPromotions_requirements[];
}

export interface SetCoupon_SetCoupon_rewardsToChoose_destination {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface SetCoupon_SetCoupon_rewardsToChoose_productsToChoose {
  __typename: "RewardProductToChooseType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Url da imagem
   */
  imageUrl: string;
  /**
   * Quantidade escolhida
   */
  chosenQuantity: number | null;
  /**
   * Quantidade máxima a ser selecionada
   */
  maxQuantity: number;
  /**
   * Indica se está indisponível (só atualiza depois de escolhido)
   */
  unavailable: boolean;
  /**
   * ?
   */
  isReorderAllowed: boolean;
}

export interface SetCoupon_SetCoupon_rewardsToChoose_discountsToChoose {
  __typename: "RewardDiscountToChooseType";
  /**
   * Código da opção de desconto
   */
  code: string;
  /**
   * Valor do desconto (unidade é de acordo com o tipo do desconto)
   */
  value: number;
  /**
   * Descrição
   */
  auxiliaryDescription: string | null;
  /**
   * Tipo da conquista
   */
  rewardType: RewardTypeEnum;
}

export interface SetCoupon_SetCoupon_rewardsToChoose {
  __typename: "RewardToChooseType";
  /**
   * Código da promoção (pode repetir)
   */
  code: string;
  /**
   * Título da promoção
   */
  title: string;
  /**
   * Indica se escolheu produtos
   */
  hasChosenProducts: boolean;
  /**
   * Indica se escolheu a opção de brindes
   */
  hasChosenDiscount: boolean;
  /**
   * Número sequencial (diferenciador das opções)
   */
  giftNumber: string;
  /**
   * Número sequencial (diferenciador dos itens)
   */
  giftItemNumber: string;
  /**
   * Número máximo de brindes a serem escolhidos
   */
  maxGiftsToChoose: number;
  /**
   * Descrição
   */
  description: string | null;
  /**
   * Destino
   */
  destination: SetCoupon_SetCoupon_rewardsToChoose_destination;
  /**
   * Indica se é promoção de cupom
   */
  isCupomPromotion: boolean;
  /**
   * Produto (brinde) a ser escolhido na promoção
   */
  productsToChoose: SetCoupon_SetCoupon_rewardsToChoose_productsToChoose[] | null;
  /**
   * Detalhes de uma opção de desconto em uma promoção
   */
  discountsToChoose: SetCoupon_SetCoupon_rewardsToChoose_discountsToChoose[] | null;
}

export interface SetCoupon_SetCoupon_chosenAddress_type {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface SetCoupon_SetCoupon_chosenAddress_address {
  __typename: "RegistrationAddressType";
  /**
   * Logradouro
   */
  streetName: string | null;
  /**
   * Número
   */
  addressNumber: string | null;
  /**
   * CEP
   */
  cep: string | null;
  /**
   * Bairro
   */
  neighborhood: string | null;
  /**
   * Complemento
   */
  complement: string | null;
  /**
   * Cidade
   */
  city: string | null;
  /**
   * Estado
   */
  state: string | null;
  /**
   * País
   */
  country: string | null;
}

export interface SetCoupon_SetCoupon_chosenAddress {
  __typename: "CartDeliveryAddressType";
  /**
   * Tipo do endereço
   */
  type: SetCoupon_SetCoupon_chosenAddress_type;
  /**
   * Endereço em formato de objeto
   */
  address: SetCoupon_SetCoupon_chosenAddress_address;
  /**
   * Endereço formatado
   */
  formattedAddress: string;
  /**
   * CEP
   */
  cep: string;
}

export interface SetCoupon_SetCoupon_chosenFreightOption_type {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface SetCoupon_SetCoupon_chosenFreightOption {
  __typename: "FreightOptionResponse";
  /**
   * Código da opção de frete
   */
  code: string;
  /**
   * Nome da opção de frete
   */
  name: string;
  /**
   * Tipo
   */
  type: SetCoupon_SetCoupon_chosenFreightOption_type;
  /**
   * Valor da entrega
   */
  price: number;
  /**
   * Dias úteis para a entrega
   */
  deliveryUtilDays: number;
}

export interface SetCoupon_SetCoupon_chosenPaymentPlan_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface SetCoupon_SetCoupon_chosenPaymentPlan_creditCardOptions_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface SetCoupon_SetCoupon_chosenPaymentPlan_creditCardOptions {
  __typename: "CreditCardOptionType";
  /**
   * Descrição da bandeira do cartão de crédito
   */
  type: string;
  /**
   * Identificador da bandeira do cartão de crédito
   */
  flag: string;
  /**
   * Modo de pagamento para o cartão especificado
   */
  paymentMode: SetCoupon_SetCoupon_chosenPaymentPlan_creditCardOptions_paymentMode;
  /**
   * Parcelas possíveis de serem escolhidas
   */
  possibleNumberOfInstallments: number[] | null;
}

export interface SetCoupon_SetCoupon_chosenPaymentPlan_selectedInstallments {
  __typename: "InstallmentType";
  /**
   * Número da parcela
   */
  number: number;
  /**
   * Valor da parcela
   */
  value: number;
  /**
   * Data de vencimento
   */
  dueDate: any;
  /**
   * Indica se está bloqueada
   */
  isBlocked: boolean;
}

export interface SetCoupon_SetCoupon_chosenPaymentPlan {
  __typename: "PaymentPlanResponse";
  /**
   * Código do plano de pagamento
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Indica se consumirá crédito da revendedora
   */
  willConsumeCredit: boolean;
  /**
   * Indica se ela deve ter saldo de crédito para poder comprar
   */
  shouldVerifyCredit: boolean;
  /**
   * Indica se está bloqueado
   */
  isBlocked: boolean;
  /**
   * Taxa de administração
   */
  tax: number;
  /**
   * Número de parcelas (para planos sem cartão crédito)
   */
  installments: number | null;
  /**
   * Identificador do modo de pagamento
   */
  paymentMode: SetCoupon_SetCoupon_chosenPaymentPlan_paymentMode | null;
  /**
   * Opções quando pagamento é por cartão de crédito
   */
  creditCardOptions: SetCoupon_SetCoupon_chosenPaymentPlan_creditCardOptions[] | null;
  /**
   * Parcelas do plano selecionado
   */
  selectedInstallments: SetCoupon_SetCoupon_chosenPaymentPlan_selectedInstallments[] | null;
  /**
   * Indica se é múltiplas formas de pagamento
   */
  isMultiplePayment: boolean;
}

export interface SetCoupon_SetCoupon_totals {
  __typename: "CartTotalsType";
  /**
   * Total de venda
   */
  totalSell: number | null;
  /**
   * Lucro
   */
  profit: number | null;
  /**
   * Frete
   */
  deliveryTax: number | null;
  /**
   * Subtotal
   */
  subtotalPay: number | null;
  /**
   * Total
   */
  totalPay: number | null;
  /**
   * Lucro vindo dos brindes
   */
  profitFromGifts: number | null;
  /**
   * Valor de crédito utilizado da CCR (Conta Corrente Residual)
   */
  checkingAccount: number | null;
  /**
   * Taxa administrativa
   */
  collectionFee: number | null;
  /**
   * Impostos
   */
  taxes: number | null;
  /**
   * Comissão
   */
  commission: number | null;
}

export interface SetCoupon_SetCoupon_deliveryAddresses_type {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface SetCoupon_SetCoupon_deliveryAddresses_address {
  __typename: "RegistrationAddressType";
  /**
   * Logradouro
   */
  streetName: string | null;
  /**
   * Número
   */
  addressNumber: string | null;
  /**
   * CEP
   */
  cep: string | null;
  /**
   * Bairro
   */
  neighborhood: string | null;
  /**
   * Complemento
   */
  complement: string | null;
  /**
   * Cidade
   */
  city: string | null;
  /**
   * Estado
   */
  state: string | null;
  /**
   * País
   */
  country: string | null;
}

export interface SetCoupon_SetCoupon_deliveryAddresses {
  __typename: "CartDeliveryAddressType";
  /**
   * Tipo do endereço
   */
  type: SetCoupon_SetCoupon_deliveryAddresses_type;
  /**
   * Endereço em formato de objeto
   */
  address: SetCoupon_SetCoupon_deliveryAddresses_address;
  /**
   * Endereço formatado
   */
  formattedAddress: string;
  /**
   * CEP
   */
  cep: string;
}

export interface SetCoupon_SetCoupon_paymentPlans_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface SetCoupon_SetCoupon_paymentPlans_creditCardOptions_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface SetCoupon_SetCoupon_paymentPlans_creditCardOptions {
  __typename: "CreditCardOptionType";
  /**
   * Descrição da bandeira do cartão de crédito
   */
  type: string;
  /**
   * Identificador da bandeira do cartão de crédito
   */
  flag: string;
  /**
   * Modo de pagamento para o cartão especificado
   */
  paymentMode: SetCoupon_SetCoupon_paymentPlans_creditCardOptions_paymentMode;
  /**
   * Parcelas possíveis de serem escolhidas
   */
  possibleNumberOfInstallments: number[] | null;
}

export interface SetCoupon_SetCoupon_paymentPlans_selectedInstallments {
  __typename: "InstallmentType";
  /**
   * Número da parcela
   */
  number: number;
  /**
   * Valor da parcela
   */
  value: number;
  /**
   * Data de vencimento
   */
  dueDate: any;
  /**
   * Indica se está bloqueada
   */
  isBlocked: boolean;
}

export interface SetCoupon_SetCoupon_paymentPlans {
  __typename: "PaymentPlanResponse";
  /**
   * Código do plano de pagamento
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Indica se consumirá crédito da revendedora
   */
  willConsumeCredit: boolean;
  /**
   * Indica se ela deve ter saldo de crédito para poder comprar
   */
  shouldVerifyCredit: boolean;
  /**
   * Indica se está bloqueado
   */
  isBlocked: boolean;
  /**
   * Taxa de administração
   */
  tax: number;
  /**
   * Número de parcelas (para planos sem cartão crédito)
   */
  installments: number | null;
  /**
   * Identificador do modo de pagamento
   */
  paymentMode: SetCoupon_SetCoupon_paymentPlans_paymentMode | null;
  /**
   * Opções quando pagamento é por cartão de crédito
   */
  creditCardOptions: SetCoupon_SetCoupon_paymentPlans_creditCardOptions[] | null;
  /**
   * Parcelas do plano selecionado
   */
  selectedInstallments: SetCoupon_SetCoupon_paymentPlans_selectedInstallments[] | null;
  /**
   * Indica se é múltiplas formas de pagamento
   */
  isMultiplePayment: boolean;
}

export interface SetCoupon_SetCoupon {
  __typename: "CartResponse";
  /**
   * Código do pedido
   */
  orderCode: string;
  /**
   * Código do espaço revendedor
   */
  distributionCenterCode: string;
  /**
   * Modo de entrega (retirar na central / entrega em endereço)
   */
  pickupOnDistributionCenter: boolean | null;
  /**
   * Valor do pedido mínimo
   */
  requiredMinimum: number;
  /**
   * Valor atual considerado para o pedido mínimo
   */
  currentValueConsideredToMinimum: number;
  /**
   * Ciclo do pedido
   */
  cycle: number;
  /**
   * SubCiclo do pedido
   */
  subCycle: number;
  /**
   * Dados do link externo de pagamento
   */
  externalPaymentLink: SetCoupon_SetCoupon_externalPaymentLink | null;
  /**
   * Crédito disponível
   */
  credit: SetCoupon_SetCoupon_credit | null;
  /**
   * Produtos do pedido
   */
  products: SetCoupon_SetCoupon_products[];
  /**
   * Produtos cortados
   */
  cutProducts: SetCoupon_SetCoupon_cutProducts[];
  /**
   * Produto (brinde) conquistado
   */
  acquiredProducts: SetCoupon_SetCoupon_acquiredProducts[] | null;
  /**
   * Promoção conquistada
   */
  acquiredPromotions: SetCoupon_SetCoupon_acquiredPromotions[] | null;
  /**
   * Promoção conquistada
   */
  partialPromotions: SetCoupon_SetCoupon_partialPromotions[] | null;
  /**
   * Opção a ser escolhida na promoção (pode ser brinde ou desconto)
   */
  rewardsToChoose: SetCoupon_SetCoupon_rewardsToChoose[] | null;
  /**
   * Endereço escolhido (ou padrão) para a entrega
   */
  chosenAddress: SetCoupon_SetCoupon_chosenAddress | null;
  /**
   * Opção de frete escolhida (ou padrão)
   */
  chosenFreightOption: SetCoupon_SetCoupon_chosenFreightOption | null;
  /**
   * Opção de pagamento escolhida (ou padrão)
   */
  chosenPaymentPlan: SetCoupon_SetCoupon_chosenPaymentPlan | null;
  /**
   * Resumo de valores do pedido
   */
  totals: SetCoupon_SetCoupon_totals;
  /**
   * Endereços para entrega
   */
  deliveryAddresses: SetCoupon_SetCoupon_deliveryAddresses[] | null;
  /**
   * Planos de pagamento
   */
  paymentPlans: SetCoupon_SetCoupon_paymentPlans[] | null;
}

export interface SetCoupon {
  /**
   * # Mutation - SetCoupon
   * ## Description:
   * 
   * 
   *  - Adiciona um cupom de desconto no pedido
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## External Dependencies:
   * 
   * 
   *  - ### BoticarioBaseCartHttpDatasource
   *     
   * 
   * 	
   * 	 - Endpoint Path: /grb/sb/CarrinhoGera/orders/${orderCode}/Manage
   * ## Internal Dependencies:
   * 
   * 
   *  - ### SetDiscountUseCase
   *     
   * 
   * 
   *  - ### ManageOrderDatasource
   */
  SetCoupon: SetCoupon_SetCoupon;
}

export interface SetCouponVariables {
  data: SetCouponInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: StartConversation
// ====================================================

export interface StartConversation_FvcStartConversation {
  __typename: "FvcResellerConversationResponse";
  /**
   * Id
   */
  id: string;
  /**
   * Id da revendedora
   */
  resellerId: string;
  /**
   * Nome da revendedora
   */
  resellerName: string | null;
  /**
   * Horário de Início do Atendimento
   */
  createdAt: any;
  /**
   * Horário da finalização do Atendimento
   */
  completedAt: any | null;
  /**
   * Indica se atendimento retornado é um agendamento
   */
  isScheduling: boolean | null;
}

export interface StartConversation {
  /**
   * # Mutation - FvcStartConversation
   * ## Description:
   * 
   * 
   *  - Inicia um novo atendimento.
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## Internal Dependencies:
   * 
   * 
   *  - ### FvcStartConversationUseCase
   *     
   * 
   * 
   *  - ### FvcSchedulingUseCase
   *     
   * 
   * 
   *  - ### FvcSchedulingDbDatasource
   *     
   * 
   * 
   *  - ### FvcConversationHelper
   *     
   * 
   * 
   *  - ### FvcResellerConversationsDatasource
   *     
   * 
   * 
   *  - ### FvcResellerStructureDbDataSource
   */
  FvcStartConversation: StartConversation_FvcStartConversation[];
}

export interface StartConversationVariables {
  resellerCode?: string | null;
  commercialStructureId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: StartIrConversation
// ====================================================

export interface StartIrConversation_StartIrConversation {
  __typename: "StartIrConversationResponse";
  id: number;
  createdAt: any;
  inConversation: boolean;
  resellerId: string;
  resellerName: string;
  completedAt: any | null;
}

export interface StartIrConversation {
  StartIrConversation: StartIrConversation_StartIrConversation;
}

export interface StartIrConversationVariables {
  commercialStructure: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: StartOrder
// ====================================================

export interface StartOrder_StartOrder_externalPaymentLink {
  __typename: "NewPaymentLinkResponse";
  /**
   * Url de pagamento
   */
  externalPaymentLink: string | null;
  /**
   * Data de expiração da url de pagamento
   */
  expirationDatePaymentLink: any | null;
}

export interface StartOrder_StartOrder_credit {
  __typename: "ResellerCreditResponse";
  /**
   * Crédito disponível para compra no momento
   */
  available: number | null;
}

export interface StartOrder_StartOrder_products_origin {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface StartOrder_StartOrder_products {
  __typename: "CartProductType";
  /**
   * Número representativo do produto no carrinho Gera
   */
  sequentialNumber: string;
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * URL da imagem do produto
   */
  imageUrl: string;
  /**
   * Quantidade
   */
  quantity: number;
  /**
   * Preço de mercado (consumidor)
   */
  unitMarketValue: number;
  /**
   * Preço de tabela (consumidor)
   */
  unitTableValue: number;
  /**
   * Preço promocionado (consumidor)
   */
  unitNetValue: number | null;
  /**
   * Origem do produto no pedido
   */
  origin: StartOrder_StartOrder_products_origin;
  /**
   * Bloqueado para edição?
   */
  blockEdit: boolean;
  /**
   * Bloqueado para remoção?
   */
  blockDelete: boolean;
  /**
   * Valor unitário de compra
   */
  unitPayValue: number;
  /**
   * Valor de compra
   */
  payValue: number;
  /**
   * Lucro
   */
  profit: number;
  /**
   * Lucro com descontos
   */
  profitWithDiscount: number;
  /**
   * Se o produto é um combo personalizável
   */
  isCombo: boolean;
}

export interface StartOrder_StartOrder_cutProducts_type {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface StartOrder_StartOrder_cutProducts_cutReason {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface StartOrder_StartOrder_cutProducts {
  __typename: "CartCutProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * URL da imagem do produto
   */
  imageUrl: string;
  /**
   * Quantidade
   */
  quantity: number;
  /**
   * Preço de mercado (consumidor)
   */
  unitMarketValue: number;
  /**
   * Preço de tabela (consumidor)
   */
  unitTableValue: number;
  /**
   * Preço promocionado (consumidor)
   */
  unitNetValue: number | null;
  /**
   * Tipo do item
   */
  type: StartOrder_StartOrder_cutProducts_type;
  /**
   * Razão do corte
   */
  cutReason: StartOrder_StartOrder_cutProducts_cutReason;
}

export interface StartOrder_StartOrder_acquiredProducts_promotion {
  __typename: "CartPromotionType";
  /**
   * Código
   */
  code: string;
  /**
   * Título
   */
  title: string;
}

export interface StartOrder_StartOrder_acquiredProducts {
  __typename: "AcquiredProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * URL da imagem
   */
  imageUrl: string;
  /**
   * Quantidade
   */
  quantity: number | null;
  /**
   * Promoção que originou o brinde
   */
  promotion: StartOrder_StartOrder_acquiredProducts_promotion | null;
}

export interface StartOrder_StartOrder_acquiredPromotions_destination {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface StartOrder_StartOrder_acquiredPromotions_rewards {
  __typename: "RewardProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Quantidade
   */
  quantity: number | null;
  /**
   * URL da imagem
   */
  imageUrl: string;
  /**
   * Desconto
   */
  discount: number;
  /**
   * Tipo
   */
  type: RewardTypeEnum;
  /**
   * Descrição
   */
  description: string | null;
  /**
   * Indica se é um brinde a ser escolhido
   */
  isGiftToChoose: boolean;
}

export interface StartOrder_StartOrder_acquiredPromotions {
  __typename: "AcquiredPromotionType";
  /**
   * Código da promoção
   */
  code: string;
  /**
   * Nome
   */
  title: string;
  /**
   * Descrição
   */
  description: string | null;
  /**
   * Destino
   */
  destination: StartOrder_StartOrder_acquiredPromotions_destination;
  /**
   * Indica se é promoção de cupom de desconto
   */
  isCupomPromotion: boolean;
  /**
   * Lista de brindes/descontos
   */
  rewards: StartOrder_StartOrder_acquiredPromotions_rewards[];
}

export interface StartOrder_StartOrder_partialPromotions_destination {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface StartOrder_StartOrder_partialPromotions_requirements_type {
  __typename: "RequirementType";
  /**
   * Id do tipo de requisito
   */
  id: number | null;
  /**
   * Descrição do requisito
   */
  description: string | null;
}

export interface StartOrder_StartOrder_partialPromotions_requirements_productRequired {
  __typename: "RequiredProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome do produto
   */
  name: string;
  /**
   * Descrição do produto
   */
  description: string | null;
  /**
   * Indica se é um kit
   */
  isKit: boolean | null;
  /**
   * Quantidade de pontos
   */
  points: number | null;
  /**
   * Preço
   */
  price: number | null;
}

export interface StartOrder_StartOrder_partialPromotions_requirements_structureRequired {
  __typename: "RequiredStructureType";
  /**
   * Código da estrutura
   */
  code: string;
  /**
   * Nome da estrutura
   */
  name: string;
}

export interface StartOrder_StartOrder_partialPromotions_requirements {
  __typename: "PromotionRequirementType";
  /**
   * Valor faltante para obter a promoção
   */
  missingValue: number;
  /**
   * Tipo do requisito
   */
  type: StartOrder_StartOrder_partialPromotions_requirements_type;
  /**
   * Produto requisitado
   */
  productRequired: StartOrder_StartOrder_partialPromotions_requirements_productRequired | null;
  /**
   * Estrutura na qual a compra deve ser feita
   */
  structureRequired: StartOrder_StartOrder_partialPromotions_requirements_structureRequired | null;
  /**
   * Descrição
   */
  description: string | null;
}

export interface StartOrder_StartOrder_partialPromotions {
  __typename: "PartialPromotionType";
  /**
   * Código da promoção
   */
  code: string;
  /**
   * Nome
   */
  title: string;
  /**
   * Descrição
   */
  description: string | null;
  /**
   * Destino
   */
  destination: StartOrder_StartOrder_partialPromotions_destination;
  /**
   * Indica se é promoção de cupom de desconto
   */
  isCupomPromotion: boolean;
  /**
   * Lista de requisitos para conquistar a promoção
   */
  requirements: StartOrder_StartOrder_partialPromotions_requirements[];
}

export interface StartOrder_StartOrder_rewardsToChoose_destination {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface StartOrder_StartOrder_rewardsToChoose_productsToChoose {
  __typename: "RewardProductToChooseType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Url da imagem
   */
  imageUrl: string;
  /**
   * Quantidade escolhida
   */
  chosenQuantity: number | null;
  /**
   * Quantidade máxima a ser selecionada
   */
  maxQuantity: number;
  /**
   * Indica se está indisponível (só atualiza depois de escolhido)
   */
  unavailable: boolean;
  /**
   * ?
   */
  isReorderAllowed: boolean;
}

export interface StartOrder_StartOrder_rewardsToChoose_discountsToChoose {
  __typename: "RewardDiscountToChooseType";
  /**
   * Código da opção de desconto
   */
  code: string;
  /**
   * Valor do desconto (unidade é de acordo com o tipo do desconto)
   */
  value: number;
  /**
   * Descrição
   */
  auxiliaryDescription: string | null;
  /**
   * Tipo da conquista
   */
  rewardType: RewardTypeEnum;
}

export interface StartOrder_StartOrder_rewardsToChoose {
  __typename: "RewardToChooseType";
  /**
   * Código da promoção (pode repetir)
   */
  code: string;
  /**
   * Título da promoção
   */
  title: string;
  /**
   * Indica se escolheu produtos
   */
  hasChosenProducts: boolean;
  /**
   * Indica se escolheu a opção de brindes
   */
  hasChosenDiscount: boolean;
  /**
   * Número sequencial (diferenciador das opções)
   */
  giftNumber: string;
  /**
   * Número sequencial (diferenciador dos itens)
   */
  giftItemNumber: string;
  /**
   * Número máximo de brindes a serem escolhidos
   */
  maxGiftsToChoose: number;
  /**
   * Descrição
   */
  description: string | null;
  /**
   * Destino
   */
  destination: StartOrder_StartOrder_rewardsToChoose_destination;
  /**
   * Indica se é promoção de cupom
   */
  isCupomPromotion: boolean;
  /**
   * Produto (brinde) a ser escolhido na promoção
   */
  productsToChoose: StartOrder_StartOrder_rewardsToChoose_productsToChoose[] | null;
  /**
   * Detalhes de uma opção de desconto em uma promoção
   */
  discountsToChoose: StartOrder_StartOrder_rewardsToChoose_discountsToChoose[] | null;
}

export interface StartOrder_StartOrder_chosenAddress_type {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface StartOrder_StartOrder_chosenAddress_address {
  __typename: "RegistrationAddressType";
  /**
   * Logradouro
   */
  streetName: string | null;
  /**
   * Número
   */
  addressNumber: string | null;
  /**
   * CEP
   */
  cep: string | null;
  /**
   * Bairro
   */
  neighborhood: string | null;
  /**
   * Complemento
   */
  complement: string | null;
  /**
   * Cidade
   */
  city: string | null;
  /**
   * Estado
   */
  state: string | null;
  /**
   * País
   */
  country: string | null;
}

export interface StartOrder_StartOrder_chosenAddress {
  __typename: "CartDeliveryAddressType";
  /**
   * Tipo do endereço
   */
  type: StartOrder_StartOrder_chosenAddress_type;
  /**
   * Endereço em formato de objeto
   */
  address: StartOrder_StartOrder_chosenAddress_address;
  /**
   * Endereço formatado
   */
  formattedAddress: string;
  /**
   * CEP
   */
  cep: string;
}

export interface StartOrder_StartOrder_chosenFreightOption_type {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface StartOrder_StartOrder_chosenFreightOption {
  __typename: "FreightOptionResponse";
  /**
   * Código da opção de frete
   */
  code: string;
  /**
   * Nome da opção de frete
   */
  name: string;
  /**
   * Tipo
   */
  type: StartOrder_StartOrder_chosenFreightOption_type;
  /**
   * Valor da entrega
   */
  price: number;
  /**
   * Dias úteis para a entrega
   */
  deliveryUtilDays: number;
}

export interface StartOrder_StartOrder_chosenPaymentPlan_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface StartOrder_StartOrder_chosenPaymentPlan_creditCardOptions_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface StartOrder_StartOrder_chosenPaymentPlan_creditCardOptions {
  __typename: "CreditCardOptionType";
  /**
   * Descrição da bandeira do cartão de crédito
   */
  type: string;
  /**
   * Identificador da bandeira do cartão de crédito
   */
  flag: string;
  /**
   * Modo de pagamento para o cartão especificado
   */
  paymentMode: StartOrder_StartOrder_chosenPaymentPlan_creditCardOptions_paymentMode;
  /**
   * Parcelas possíveis de serem escolhidas
   */
  possibleNumberOfInstallments: number[] | null;
}

export interface StartOrder_StartOrder_chosenPaymentPlan_selectedInstallments {
  __typename: "InstallmentType";
  /**
   * Número da parcela
   */
  number: number;
  /**
   * Valor da parcela
   */
  value: number;
  /**
   * Data de vencimento
   */
  dueDate: any;
  /**
   * Indica se está bloqueada
   */
  isBlocked: boolean;
}

export interface StartOrder_StartOrder_chosenPaymentPlan {
  __typename: "PaymentPlanResponse";
  /**
   * Código do plano de pagamento
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Indica se consumirá crédito da revendedora
   */
  willConsumeCredit: boolean;
  /**
   * Indica se ela deve ter saldo de crédito para poder comprar
   */
  shouldVerifyCredit: boolean;
  /**
   * Indica se está bloqueado
   */
  isBlocked: boolean;
  /**
   * Taxa de administração
   */
  tax: number;
  /**
   * Número de parcelas (para planos sem cartão crédito)
   */
  installments: number | null;
  /**
   * Identificador do modo de pagamento
   */
  paymentMode: StartOrder_StartOrder_chosenPaymentPlan_paymentMode | null;
  /**
   * Opções quando pagamento é por cartão de crédito
   */
  creditCardOptions: StartOrder_StartOrder_chosenPaymentPlan_creditCardOptions[] | null;
  /**
   * Parcelas do plano selecionado
   */
  selectedInstallments: StartOrder_StartOrder_chosenPaymentPlan_selectedInstallments[] | null;
  /**
   * Indica se é múltiplas formas de pagamento
   */
  isMultiplePayment: boolean;
}

export interface StartOrder_StartOrder_totals {
  __typename: "CartTotalsType";
  /**
   * Total de venda
   */
  totalSell: number | null;
  /**
   * Lucro
   */
  profit: number | null;
  /**
   * Frete
   */
  deliveryTax: number | null;
  /**
   * Subtotal
   */
  subtotalPay: number | null;
  /**
   * Total
   */
  totalPay: number | null;
  /**
   * Lucro vindo dos brindes
   */
  profitFromGifts: number | null;
  /**
   * Valor de crédito utilizado da CCR (Conta Corrente Residual)
   */
  checkingAccount: number | null;
  /**
   * Taxa administrativa
   */
  collectionFee: number | null;
  /**
   * Impostos
   */
  taxes: number | null;
  /**
   * Comissão
   */
  commission: number | null;
}

export interface StartOrder_StartOrder_deliveryAddresses_type {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface StartOrder_StartOrder_deliveryAddresses_address {
  __typename: "RegistrationAddressType";
  /**
   * Logradouro
   */
  streetName: string | null;
  /**
   * Número
   */
  addressNumber: string | null;
  /**
   * CEP
   */
  cep: string | null;
  /**
   * Bairro
   */
  neighborhood: string | null;
  /**
   * Complemento
   */
  complement: string | null;
  /**
   * Cidade
   */
  city: string | null;
  /**
   * Estado
   */
  state: string | null;
  /**
   * País
   */
  country: string | null;
}

export interface StartOrder_StartOrder_deliveryAddresses {
  __typename: "CartDeliveryAddressType";
  /**
   * Tipo do endereço
   */
  type: StartOrder_StartOrder_deliveryAddresses_type;
  /**
   * Endereço em formato de objeto
   */
  address: StartOrder_StartOrder_deliveryAddresses_address;
  /**
   * Endereço formatado
   */
  formattedAddress: string;
  /**
   * CEP
   */
  cep: string;
}

export interface StartOrder_StartOrder_paymentPlans_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface StartOrder_StartOrder_paymentPlans_creditCardOptions_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface StartOrder_StartOrder_paymentPlans_creditCardOptions {
  __typename: "CreditCardOptionType";
  /**
   * Descrição da bandeira do cartão de crédito
   */
  type: string;
  /**
   * Identificador da bandeira do cartão de crédito
   */
  flag: string;
  /**
   * Modo de pagamento para o cartão especificado
   */
  paymentMode: StartOrder_StartOrder_paymentPlans_creditCardOptions_paymentMode;
  /**
   * Parcelas possíveis de serem escolhidas
   */
  possibleNumberOfInstallments: number[] | null;
}

export interface StartOrder_StartOrder_paymentPlans_selectedInstallments {
  __typename: "InstallmentType";
  /**
   * Número da parcela
   */
  number: number;
  /**
   * Valor da parcela
   */
  value: number;
  /**
   * Data de vencimento
   */
  dueDate: any;
  /**
   * Indica se está bloqueada
   */
  isBlocked: boolean;
}

export interface StartOrder_StartOrder_paymentPlans {
  __typename: "PaymentPlanResponse";
  /**
   * Código do plano de pagamento
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Indica se consumirá crédito da revendedora
   */
  willConsumeCredit: boolean;
  /**
   * Indica se ela deve ter saldo de crédito para poder comprar
   */
  shouldVerifyCredit: boolean;
  /**
   * Indica se está bloqueado
   */
  isBlocked: boolean;
  /**
   * Taxa de administração
   */
  tax: number;
  /**
   * Número de parcelas (para planos sem cartão crédito)
   */
  installments: number | null;
  /**
   * Identificador do modo de pagamento
   */
  paymentMode: StartOrder_StartOrder_paymentPlans_paymentMode | null;
  /**
   * Opções quando pagamento é por cartão de crédito
   */
  creditCardOptions: StartOrder_StartOrder_paymentPlans_creditCardOptions[] | null;
  /**
   * Parcelas do plano selecionado
   */
  selectedInstallments: StartOrder_StartOrder_paymentPlans_selectedInstallments[] | null;
  /**
   * Indica se é múltiplas formas de pagamento
   */
  isMultiplePayment: boolean;
}

export interface StartOrder_StartOrder {
  __typename: "CartResponse";
  /**
   * Código do pedido
   */
  orderCode: string;
  /**
   * Código do espaço revendedor
   */
  distributionCenterCode: string;
  /**
   * Modo de entrega (retirar na central / entrega em endereço)
   */
  pickupOnDistributionCenter: boolean | null;
  /**
   * Valor do pedido mínimo
   */
  requiredMinimum: number;
  /**
   * Valor atual considerado para o pedido mínimo
   */
  currentValueConsideredToMinimum: number;
  /**
   * Ciclo do pedido
   */
  cycle: number;
  /**
   * SubCiclo do pedido
   */
  subCycle: number;
  /**
   * Dados do link externo de pagamento
   */
  externalPaymentLink: StartOrder_StartOrder_externalPaymentLink | null;
  /**
   * Crédito disponível
   */
  credit: StartOrder_StartOrder_credit | null;
  /**
   * Produtos do pedido
   */
  products: StartOrder_StartOrder_products[];
  /**
   * Produtos cortados
   */
  cutProducts: StartOrder_StartOrder_cutProducts[];
  /**
   * Produto (brinde) conquistado
   */
  acquiredProducts: StartOrder_StartOrder_acquiredProducts[] | null;
  /**
   * Promoção conquistada
   */
  acquiredPromotions: StartOrder_StartOrder_acquiredPromotions[] | null;
  /**
   * Promoção conquistada
   */
  partialPromotions: StartOrder_StartOrder_partialPromotions[] | null;
  /**
   * Opção a ser escolhida na promoção (pode ser brinde ou desconto)
   */
  rewardsToChoose: StartOrder_StartOrder_rewardsToChoose[] | null;
  /**
   * Endereço escolhido (ou padrão) para a entrega
   */
  chosenAddress: StartOrder_StartOrder_chosenAddress | null;
  /**
   * Opção de frete escolhida (ou padrão)
   */
  chosenFreightOption: StartOrder_StartOrder_chosenFreightOption | null;
  /**
   * Opção de pagamento escolhida (ou padrão)
   */
  chosenPaymentPlan: StartOrder_StartOrder_chosenPaymentPlan | null;
  /**
   * Resumo de valores do pedido
   */
  totals: StartOrder_StartOrder_totals;
  /**
   * Endereços para entrega
   */
  deliveryAddresses: StartOrder_StartOrder_deliveryAddresses[] | null;
  /**
   * Planos de pagamento
   */
  paymentPlans: StartOrder_StartOrder_paymentPlans[] | null;
}

export interface StartOrder {
  /**
   * # Mutation - StartOrder
   * ## Description:
   * 
   * 
   *  - Inicia um pedido para a revendedora
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## External Dependencies:
   * 
   * 
   *  - ### ResellerCurrentOrderDbDatasource
   *     
   * 
   * 	
   * 	 - Endpoint Path: /cart/orders/Start
   * 
   * 
   *  - ### ResellerHttpDataSource
   *     
   * 
   * 	
   * 	 - Endpoint Path: /grb/sb/revendedoras/${resellerId}/creditos
   * ## Internal Dependencies:
   * 
   * 
   *  - ### StartOrderUseCase
   *     
   * 
   * 
   *  - ### ResellerCurrentOrderUseCase
   *     
   * 
   * 
   *  - ### ResellerCreditUseCase
   *     
   * 
   * 
   *  - ### ResellerCurrentOrderDbDatasource
   *     
   * 
   * 
   *  - ### ResellerDbDataSource
   *     
   * 
   * 
   *  - ### ResellerCreditMetricDbDataSource
   *     
   * 
   * 
   *  - ### ResellerMasterDataMetricDbDataSource
   */
  StartOrder: StartOrder_StartOrder;
}

export interface StartOrderVariables {
  data: StartOrderInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: StartResellerConversation
// ====================================================

export interface StartResellerConversation_StartResellerConversation_reseller {
  __typename: "ResellerResponse";
  referenceId: string;
  name: string;
}

export interface StartResellerConversation_StartResellerConversation {
  __typename: "ResellerConversationResponse";
  status: ConversationStatusEnum | null;
  reseller: StartResellerConversation_StartResellerConversation_reseller;
}

export interface StartResellerConversation {
  /**
   * # Mutation - StartResellerConversation
   * ## Description:
   * 
   * 
   *  - Inicia um atendimento
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## Internal Dependencies:
   * 
   * 
   *  - ### StartResellerConversationUseCase
   *     
   * 
   * 
   *  - ### ResellerConversationDataSource
   */
  StartResellerConversation: StartResellerConversation_StartResellerConversation;
}

export interface StartResellerConversationVariables {
  resellerId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: StartSpecificConversation
// ====================================================

export interface StartSpecificConversation_FvcStartSpecificConversation {
  __typename: "FvcResellerConversationResponse";
  /**
   * Id
   */
  id: string;
  /**
   * Id da revendedora
   */
  resellerId: string;
  /**
   * Nome da revendedora
   */
  resellerName: string | null;
  /**
   * Horário de Início do Atendimento
   */
  createdAt: any;
  /**
   * Horário da finalização do Atendimento
   */
  completedAt: any | null;
  /**
   * Indica se atendimento retornado é um agendamento
   */
  isScheduling: boolean | null;
}

export interface StartSpecificConversation {
  /**
   * # Mutation - FvcStartSpecificConversation
   * ## Description:
   * 
   * 
   *  - Inicia um atendimento com uma determinada revendedora.
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## Internal Dependencies:
   * 
   * 
   *  - ### FvcStartSpecificConversationUseCase
   *     
   * 
   * 
   *  - ### FvcSchedulingUseCase
   *     
   * 
   * 
   *  - ### FvcSchedulingDbDatasource
   *     
   * 
   * 
   *  - ### FvcConversationHelper
   *     
   * 
   * 
   *  - ### FvcResellerConversationsDatasource
   *     
   * 
   * 
   *  - ### FvcResellerStructureDbDataSource
   *     
   * 
   * 
   *  - ### FvcStructureDbDataSource
   */
  FvcStartSpecificConversation: StartSpecificConversation_FvcStartSpecificConversation[];
}

export interface StartSpecificConversationVariables {
  resellerCode: string;
  creationMethod?: ConversationCreationMethod | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: StartSpecificIrConversation
// ====================================================

export interface StartSpecificIrConversation_StartSpecificIrConversation {
  __typename: "StartIrConversationResponse";
  id: number;
  createdAt: any;
  inConversation: boolean;
  resellerId: string;
  resellerName: string;
  completedAt: any | null;
}

export interface StartSpecificIrConversation {
  StartSpecificIrConversation: StartSpecificIrConversation_StartSpecificIrConversation;
}

export interface StartSpecificIrConversationVariables {
  resellerId: string;
  commercialStructure: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateDocumentStatus
// ====================================================

export interface UpdateDocumentStatus_UpdateDocumentStatus {
  __typename: "UpdateDocumentStatusResponse";
  message: string;
}

export interface UpdateDocumentStatus {
  /**
   * # Mutation - UpdateDocumentStatus
   * ## Description:
   * 
   * 
   *  - Altera status de documentos no Gera
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## External Dependencies:
   * 
   * 
   *  - ### ResellerHttpDataSource
   *     
   * 
   * 	
   * 	 - Endpoint Path: /grb/sb/revendedoras/${resellerId}/documento
   * ## Internal Dependencies:
   * 
   * 
   *  - ### UpdateDocumentStatusUseCase
   */
  UpdateDocumentStatus: UpdateDocumentStatus_UpdateDocumentStatus;
}

export interface UpdateDocumentStatusVariables {
  documentStatus: DocumentStatusInput;
  resellerId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AntiFraudBranch
// ====================================================

export interface AntiFraudBranch_AntiFraudBranch {
  __typename: "AntiFraudResponse";
  /**
   * Ramal anti fraude
   */
  antiFraudBranch: string;
}

export interface AntiFraudBranch {
  /**
   * # Query - AntiFraudBranch
   * ## Description:
   * 
   * 
   *  - Retorna o valor do ramal anti fraude
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## Internal Dependencies:
   * 
   * 
   *  - ### AntiFraudUseCase
   */
  AntiFraudBranch: AntiFraudBranch_AntiFraudBranch;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ConversationHistoryMetrics
// ====================================================

export interface ConversationHistoryMetrics_ConversationHistoryMetrics {
  __typename: "ConversationHistoryMetricsResponse";
  /**
   * Total de atendimentos no ciclo
   */
  conversationsAmount: number;
  /**
   * Total de atendimentos receptivos no ciclo
   */
  receptiveAmount: number;
  /**
   * Total de atendimentos ativos no ciclo
   */
  activeAmount: number;
}

export interface ConversationHistoryMetrics {
  /**
   * # Query - ConversationHistoryMetrics
   * ## Description:
   * 
   * 
   *  - Métricas do histórico de atendimentos por ciclo
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## Internal Dependencies:
   * 
   * 
   *  - ### ConversationHistoryMetricsUseCase
   *     
   * 
   * 
   *  - ### FvcResellerConversationsDatasource
   */
  ConversationHistoryMetrics: ConversationHistoryMetrics_ConversationHistoryMetrics;
}

export interface ConversationHistoryMetricsVariables {
  commercialStructure: string;
  cycleId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Auth
// ====================================================

export interface Auth_Auth_profile {
  __typename: "AuthSupervisorDataType";
  /**
   * Nome
   */
  name: string | null;
  /**
   * Id
   */
  id: string | null;
  /**
   * Estruturas comerciais fake a qual a super pertence
   */
  structures: string[] | null;
}

export interface Auth_Auth {
  __typename: "AuthResponse";
  /**
   * Token de acesso à API
   */
  token: string;
  /**
   * Dados da supervisora
   */
  profile: Auth_Auth_profile;
}

export interface Auth {
  /**
   * Login FVC
   */
  Auth: Auth_Auth | null;
}

export interface AuthVariables {
  data: AuthInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TitleList
// ====================================================

export interface TitleList_TitleList_nodes_paymentPlan {
  __typename: "TitlePaymentPlanType";
  /**
   * Id
   */
  id: string;
  /**
   * descrição
   */
  description: string | null;
}

export interface TitleList_TitleList_nodes {
  __typename: "TitleResponse";
  /**
   * Id
   */
  id: string;
  /**
   * Id da revendedora
   */
  resellerId: string | null;
  /**
   * Id do título
   */
  titleId: string | null;
  /**
   * Data de emissão
   */
  issueDate: any | null;
  /**
   * Data de vencimento
   */
  dueDate: any | null;
  /**
   * Valor total
   */
  totalValue: number | null;
  /**
   * Valor
   */
  value: number | null;
  /**
   * Saldo total
   */
  totalBalance: number | null;
  /**
   * Dias de atraso
   */
  daysOfDelay: number | null;
  /**
   * Situação do pagamento
   */
  paymentPosition: string | null;
  /**
   * Situação do título
   */
  titlePosition: string | null;
  /**
   * Plano de pagamento
   */
  paymentPlan: TitleList_TitleList_nodes_paymentPlan | null;
}

export interface TitleList_TitleList_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface TitleList_TitleList {
  __typename: "TitleListResponse";
  /**
   * Total number of elements
   */
  count: number;
  /**
   * Array of TitleResponse
   */
  nodes: TitleList_TitleList_nodes[];
  /**
   * Page information
   */
  pageInfo: TitleList_TitleList_pageInfo;
}

export interface TitleList {
  /**
   * Lista dos títulos
   */
  TitleList: TitleList_TitleList;
}

export interface TitleListVariables {
  offset?: number | null;
  limit?: number | null;
  resellerId: string;
  filters?: TitleListFilterInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BusinessModels
// ====================================================

export interface BusinessModels_BusinessModels_deliveryModes {
  __typename: "DeliveryModeResponse";
  /**
   * Indica se o endereço se trata de um espaço revendedor
   */
  isWithdrawalCenter: boolean;
  /**
   * Identificador do espaço revendedor
   */
  distributionCenterCode: string;
  /**
   * Nome do espaço revendedor
   */
  distributionCenterName: string;
  /**
   * Endereço (de entrega ou do espaço revendedor)
   */
  descriptiveAddress: string;
}

export interface BusinessModels_BusinessModels_starterPacks_products {
  __typename: "StarterPackProductResponse";
  /**
   * Identificador do produto
   */
  id: string;
  /**
   * Nome do produto
   */
  name: string;
  /**
   * Descrição do produto
   */
  description: string;
}

export interface BusinessModels_BusinessModels_starterPacks {
  __typename: "StarterPackResponse";
  /**
   * Identificador do kit início
   */
  id: string;
  /**
   * Nome do kit início
   */
  name: string;
  /**
   * Descrição do kit início
   */
  description: string;
  /**
   * Quantidade de produtos a serem escolidos para o kit início
   */
  quantity: number;
  /**
   * Produtos disponíveis para escolha do kit início
   */
  products: BusinessModels_BusinessModels_starterPacks_products[];
}

export interface BusinessModels_BusinessModels {
  __typename: "BusinessModelResponse";
  /**
   * Código
   */
  id: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Descrição
   */
  description: string;
  /**
   * Lucratividade
   */
  profitability: number;
  /**
   * Valor mínimo do pedido
   */
  minimumOrderValue: number;
  /**
   * Opções de modos de entrega
   */
  deliveryModes: BusinessModels_BusinessModels_deliveryModes[];
  /**
   * Opções de kit inícios
   */
  starterPacks: BusinessModels_BusinessModels_starterPacks[];
}

export interface BusinessModels {
  /**
   * Obtem dados de modelos cmerciais de uma representante
   */
  BusinessModels: BusinessModels_BusinessModels[] | null;
}

export interface BusinessModelsVariables {
  resellerId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CartDetails
// ====================================================

export interface CartDetails_CartDetails_totals {
  __typename: "CartTotalsType";
  /**
   * Total de venda
   */
  totalSell: number | null;
  /**
   * Lucro
   */
  profit: number | null;
  /**
   * Frete
   */
  deliveryTax: number | null;
  /**
   * Subtotal
   */
  subtotalPay: number | null;
  /**
   * Total
   */
  totalPay: number | null;
  /**
   * Lucro vindo dos brindes
   */
  profitFromGifts: number | null;
  /**
   * Valor de crédito utilizado da CCR (Conta Corrente Residual)
   */
  checkingAccount: number | null;
  /**
   * Taxa administrativa
   */
  collectionFee: number | null;
  /**
   * Impostos
   */
  taxes: number | null;
  /**
   * Comissão
   */
  commission: number | null;
}

export interface CartDetails_CartDetails {
  __typename: "CartDetailsType";
  /**
   * Código do pedido
   */
  orderCode: string;
  /**
   * Ciclo do carrinho
   */
  cycle: string;
  /**
   * Subciclo do carrinho
   */
  subcycleId: string | null;
  /**
   * Id do centro de distribuição do carrinho
   */
  distributionCenterCode: string;
  /**
   * Flag que indica retirada na central
   */
  pickupOnDistributionCenter: boolean;
  /**
   * Código do modelo comercial utilizado
   */
  businessModelCode: number;
  /**
   * Totais do carrinho
   */
  totals: CartDetails_CartDetails_totals;
}

export interface CartDetails {
  /**
   * # Query - ResellerMultiplePaymentOrderTotal
   * ## Description:
   * 
   * 
   *  - Traz as informações do total do pedido
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## External Dependencies:
   * 
   * 
   *  - ### BoticarioBaseCartHttpDatasource
   *     
   * 
   * 	
   * 	 - Endpoint Path: /orders/${orderCode}/Details
   * ## Internal Dependencies:
   * 
   * 
   *  - ### GetTotalsUseCase
   *     
   * 
   * 
   *  - ### ManageOrderDatasource
   */
  CartDetails: CartDetails_CartDetails;
}

export interface CartDetailsVariables {
  orderCode: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CartState
// ====================================================

export interface CartState_CartState_externalPaymentLink {
  __typename: "NewPaymentLinkResponse";
  /**
   * Url de pagamento
   */
  externalPaymentLink: string | null;
  /**
   * Data de expiração da url de pagamento
   */
  expirationDatePaymentLink: any | null;
}

export interface CartState_CartState_credit {
  __typename: "ResellerCreditResponse";
  /**
   * Crédito disponível para compra no momento
   */
  available: number | null;
}

export interface CartState_CartState_products_origin {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface CartState_CartState_products {
  __typename: "CartProductType";
  /**
   * Número representativo do produto no carrinho Gera
   */
  sequentialNumber: string;
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * URL da imagem do produto
   */
  imageUrl: string;
  /**
   * Quantidade
   */
  quantity: number;
  /**
   * Preço de mercado (consumidor)
   */
  unitMarketValue: number;
  /**
   * Preço de tabela (consumidor)
   */
  unitTableValue: number;
  /**
   * Preço promocionado (consumidor)
   */
  unitNetValue: number | null;
  /**
   * Origem do produto no pedido
   */
  origin: CartState_CartState_products_origin;
  /**
   * Bloqueado para edição?
   */
  blockEdit: boolean;
  /**
   * Bloqueado para remoção?
   */
  blockDelete: boolean;
  /**
   * Valor unitário de compra
   */
  unitPayValue: number;
  /**
   * Valor de compra
   */
  payValue: number;
  /**
   * Lucro
   */
  profit: number;
  /**
   * Lucro com descontos
   */
  profitWithDiscount: number;
  /**
   * Se o produto é um combo personalizável
   */
  isCombo: boolean;
}

export interface CartState_CartState_cutProducts_type {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface CartState_CartState_cutProducts_cutReason {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface CartState_CartState_cutProducts {
  __typename: "CartCutProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * URL da imagem do produto
   */
  imageUrl: string;
  /**
   * Quantidade
   */
  quantity: number;
  /**
   * Preço de mercado (consumidor)
   */
  unitMarketValue: number;
  /**
   * Preço de tabela (consumidor)
   */
  unitTableValue: number;
  /**
   * Preço promocionado (consumidor)
   */
  unitNetValue: number | null;
  /**
   * Tipo do item
   */
  type: CartState_CartState_cutProducts_type;
  /**
   * Razão do corte
   */
  cutReason: CartState_CartState_cutProducts_cutReason;
}

export interface CartState_CartState_acquiredProducts_promotion {
  __typename: "CartPromotionType";
  /**
   * Código
   */
  code: string;
  /**
   * Título
   */
  title: string;
}

export interface CartState_CartState_acquiredProducts {
  __typename: "AcquiredProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * URL da imagem
   */
  imageUrl: string;
  /**
   * Quantidade
   */
  quantity: number | null;
  /**
   * Promoção que originou o brinde
   */
  promotion: CartState_CartState_acquiredProducts_promotion | null;
}

export interface CartState_CartState_acquiredPromotions_destination {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface CartState_CartState_acquiredPromotions_rewards {
  __typename: "RewardProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Quantidade
   */
  quantity: number | null;
  /**
   * URL da imagem
   */
  imageUrl: string;
  /**
   * Desconto
   */
  discount: number;
  /**
   * Tipo
   */
  type: RewardTypeEnum;
  /**
   * Descrição
   */
  description: string | null;
  /**
   * Indica se é um brinde a ser escolhido
   */
  isGiftToChoose: boolean;
}

export interface CartState_CartState_acquiredPromotions {
  __typename: "AcquiredPromotionType";
  /**
   * Código da promoção
   */
  code: string;
  /**
   * Nome
   */
  title: string;
  /**
   * Descrição
   */
  description: string | null;
  /**
   * Destino
   */
  destination: CartState_CartState_acquiredPromotions_destination;
  /**
   * Indica se é promoção de cupom de desconto
   */
  isCupomPromotion: boolean;
  /**
   * Lista de brindes/descontos
   */
  rewards: CartState_CartState_acquiredPromotions_rewards[];
}

export interface CartState_CartState_partialPromotions_destination {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface CartState_CartState_partialPromotions_requirements_type {
  __typename: "RequirementType";
  /**
   * Id do tipo de requisito
   */
  id: number | null;
  /**
   * Descrição do requisito
   */
  description: string | null;
}

export interface CartState_CartState_partialPromotions_requirements_productRequired {
  __typename: "RequiredProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome do produto
   */
  name: string;
  /**
   * Descrição do produto
   */
  description: string | null;
  /**
   * Indica se é um kit
   */
  isKit: boolean | null;
  /**
   * Quantidade de pontos
   */
  points: number | null;
  /**
   * Preço
   */
  price: number | null;
}

export interface CartState_CartState_partialPromotions_requirements_structureRequired {
  __typename: "RequiredStructureType";
  /**
   * Código da estrutura
   */
  code: string;
  /**
   * Nome da estrutura
   */
  name: string;
}

export interface CartState_CartState_partialPromotions_requirements {
  __typename: "PromotionRequirementType";
  /**
   * Valor faltante para obter a promoção
   */
  missingValue: number;
  /**
   * Tipo do requisito
   */
  type: CartState_CartState_partialPromotions_requirements_type;
  /**
   * Produto requisitado
   */
  productRequired: CartState_CartState_partialPromotions_requirements_productRequired | null;
  /**
   * Estrutura na qual a compra deve ser feita
   */
  structureRequired: CartState_CartState_partialPromotions_requirements_structureRequired | null;
  /**
   * Descrição
   */
  description: string | null;
}

export interface CartState_CartState_partialPromotions {
  __typename: "PartialPromotionType";
  /**
   * Código da promoção
   */
  code: string;
  /**
   * Nome
   */
  title: string;
  /**
   * Descrição
   */
  description: string | null;
  /**
   * Destino
   */
  destination: CartState_CartState_partialPromotions_destination;
  /**
   * Indica se é promoção de cupom de desconto
   */
  isCupomPromotion: boolean;
  /**
   * Lista de requisitos para conquistar a promoção
   */
  requirements: CartState_CartState_partialPromotions_requirements[];
}

export interface CartState_CartState_rewardsToChoose_destination {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface CartState_CartState_rewardsToChoose_productsToChoose {
  __typename: "RewardProductToChooseType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Url da imagem
   */
  imageUrl: string;
  /**
   * Quantidade escolhida
   */
  chosenQuantity: number | null;
  /**
   * Quantidade máxima a ser selecionada
   */
  maxQuantity: number;
  /**
   * Indica se está indisponível (só atualiza depois de escolhido)
   */
  unavailable: boolean;
  /**
   * ?
   */
  isReorderAllowed: boolean;
}

export interface CartState_CartState_rewardsToChoose_discountsToChoose {
  __typename: "RewardDiscountToChooseType";
  /**
   * Código da opção de desconto
   */
  code: string;
  /**
   * Valor do desconto (unidade é de acordo com o tipo do desconto)
   */
  value: number;
  /**
   * Descrição
   */
  auxiliaryDescription: string | null;
  /**
   * Tipo da conquista
   */
  rewardType: RewardTypeEnum;
}

export interface CartState_CartState_rewardsToChoose {
  __typename: "RewardToChooseType";
  /**
   * Código da promoção (pode repetir)
   */
  code: string;
  /**
   * Título da promoção
   */
  title: string;
  /**
   * Indica se escolheu produtos
   */
  hasChosenProducts: boolean;
  /**
   * Indica se escolheu a opção de brindes
   */
  hasChosenDiscount: boolean;
  /**
   * Número sequencial (diferenciador das opções)
   */
  giftNumber: string;
  /**
   * Número sequencial (diferenciador dos itens)
   */
  giftItemNumber: string;
  /**
   * Número máximo de brindes a serem escolhidos
   */
  maxGiftsToChoose: number;
  /**
   * Descrição
   */
  description: string | null;
  /**
   * Destino
   */
  destination: CartState_CartState_rewardsToChoose_destination;
  /**
   * Indica se é promoção de cupom
   */
  isCupomPromotion: boolean;
  /**
   * Produto (brinde) a ser escolhido na promoção
   */
  productsToChoose: CartState_CartState_rewardsToChoose_productsToChoose[] | null;
  /**
   * Detalhes de uma opção de desconto em uma promoção
   */
  discountsToChoose: CartState_CartState_rewardsToChoose_discountsToChoose[] | null;
}

export interface CartState_CartState_chosenAddress_type {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface CartState_CartState_chosenAddress_address {
  __typename: "RegistrationAddressType";
  /**
   * Logradouro
   */
  streetName: string | null;
  /**
   * Número
   */
  addressNumber: string | null;
  /**
   * CEP
   */
  cep: string | null;
  /**
   * Bairro
   */
  neighborhood: string | null;
  /**
   * Complemento
   */
  complement: string | null;
  /**
   * Cidade
   */
  city: string | null;
  /**
   * Estado
   */
  state: string | null;
  /**
   * País
   */
  country: string | null;
}

export interface CartState_CartState_chosenAddress {
  __typename: "CartDeliveryAddressType";
  /**
   * Tipo do endereço
   */
  type: CartState_CartState_chosenAddress_type;
  /**
   * Endereço em formato de objeto
   */
  address: CartState_CartState_chosenAddress_address;
  /**
   * Endereço formatado
   */
  formattedAddress: string;
  /**
   * CEP
   */
  cep: string;
}

export interface CartState_CartState_chosenFreightOption_type {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface CartState_CartState_chosenFreightOption {
  __typename: "FreightOptionResponse";
  /**
   * Código da opção de frete
   */
  code: string;
  /**
   * Nome da opção de frete
   */
  name: string;
  /**
   * Tipo
   */
  type: CartState_CartState_chosenFreightOption_type;
  /**
   * Valor da entrega
   */
  price: number;
  /**
   * Dias úteis para a entrega
   */
  deliveryUtilDays: number;
}

export interface CartState_CartState_chosenPaymentPlan_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface CartState_CartState_chosenPaymentPlan_creditCardOptions_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface CartState_CartState_chosenPaymentPlan_creditCardOptions {
  __typename: "CreditCardOptionType";
  /**
   * Descrição da bandeira do cartão de crédito
   */
  type: string;
  /**
   * Identificador da bandeira do cartão de crédito
   */
  flag: string;
  /**
   * Modo de pagamento para o cartão especificado
   */
  paymentMode: CartState_CartState_chosenPaymentPlan_creditCardOptions_paymentMode;
  /**
   * Parcelas possíveis de serem escolhidas
   */
  possibleNumberOfInstallments: number[] | null;
}

export interface CartState_CartState_chosenPaymentPlan_selectedInstallments {
  __typename: "InstallmentType";
  /**
   * Número da parcela
   */
  number: number;
  /**
   * Valor da parcela
   */
  value: number;
  /**
   * Data de vencimento
   */
  dueDate: any;
  /**
   * Indica se está bloqueada
   */
  isBlocked: boolean;
}

export interface CartState_CartState_chosenPaymentPlan {
  __typename: "PaymentPlanResponse";
  /**
   * Código do plano de pagamento
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Indica se consumirá crédito da revendedora
   */
  willConsumeCredit: boolean;
  /**
   * Indica se ela deve ter saldo de crédito para poder comprar
   */
  shouldVerifyCredit: boolean;
  /**
   * Indica se está bloqueado
   */
  isBlocked: boolean;
  /**
   * Taxa de administração
   */
  tax: number;
  /**
   * Número de parcelas (para planos sem cartão crédito)
   */
  installments: number | null;
  /**
   * Identificador do modo de pagamento
   */
  paymentMode: CartState_CartState_chosenPaymentPlan_paymentMode | null;
  /**
   * Opções quando pagamento é por cartão de crédito
   */
  creditCardOptions: CartState_CartState_chosenPaymentPlan_creditCardOptions[] | null;
  /**
   * Parcelas do plano selecionado
   */
  selectedInstallments: CartState_CartState_chosenPaymentPlan_selectedInstallments[] | null;
  /**
   * Indica se é múltiplas formas de pagamento
   */
  isMultiplePayment: boolean;
}

export interface CartState_CartState_totals {
  __typename: "CartTotalsType";
  /**
   * Total de venda
   */
  totalSell: number | null;
  /**
   * Lucro
   */
  profit: number | null;
  /**
   * Frete
   */
  deliveryTax: number | null;
  /**
   * Subtotal
   */
  subtotalPay: number | null;
  /**
   * Total
   */
  totalPay: number | null;
  /**
   * Lucro vindo dos brindes
   */
  profitFromGifts: number | null;
  /**
   * Valor de crédito utilizado da CCR (Conta Corrente Residual)
   */
  checkingAccount: number | null;
  /**
   * Taxa administrativa
   */
  collectionFee: number | null;
  /**
   * Impostos
   */
  taxes: number | null;
  /**
   * Comissão
   */
  commission: number | null;
}

export interface CartState_CartState_deliveryAddresses_type {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface CartState_CartState_deliveryAddresses_address {
  __typename: "RegistrationAddressType";
  /**
   * Logradouro
   */
  streetName: string | null;
  /**
   * Número
   */
  addressNumber: string | null;
  /**
   * CEP
   */
  cep: string | null;
  /**
   * Bairro
   */
  neighborhood: string | null;
  /**
   * Complemento
   */
  complement: string | null;
  /**
   * Cidade
   */
  city: string | null;
  /**
   * Estado
   */
  state: string | null;
  /**
   * País
   */
  country: string | null;
}

export interface CartState_CartState_deliveryAddresses {
  __typename: "CartDeliveryAddressType";
  /**
   * Tipo do endereço
   */
  type: CartState_CartState_deliveryAddresses_type;
  /**
   * Endereço em formato de objeto
   */
  address: CartState_CartState_deliveryAddresses_address;
  /**
   * Endereço formatado
   */
  formattedAddress: string;
  /**
   * CEP
   */
  cep: string;
}

export interface CartState_CartState_paymentPlans_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface CartState_CartState_paymentPlans_creditCardOptions_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface CartState_CartState_paymentPlans_creditCardOptions {
  __typename: "CreditCardOptionType";
  /**
   * Descrição da bandeira do cartão de crédito
   */
  type: string;
  /**
   * Identificador da bandeira do cartão de crédito
   */
  flag: string;
  /**
   * Modo de pagamento para o cartão especificado
   */
  paymentMode: CartState_CartState_paymentPlans_creditCardOptions_paymentMode;
  /**
   * Parcelas possíveis de serem escolhidas
   */
  possibleNumberOfInstallments: number[] | null;
}

export interface CartState_CartState_paymentPlans_selectedInstallments {
  __typename: "InstallmentType";
  /**
   * Número da parcela
   */
  number: number;
  /**
   * Valor da parcela
   */
  value: number;
  /**
   * Data de vencimento
   */
  dueDate: any;
  /**
   * Indica se está bloqueada
   */
  isBlocked: boolean;
}

export interface CartState_CartState_paymentPlans {
  __typename: "PaymentPlanResponse";
  /**
   * Código do plano de pagamento
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Indica se consumirá crédito da revendedora
   */
  willConsumeCredit: boolean;
  /**
   * Indica se ela deve ter saldo de crédito para poder comprar
   */
  shouldVerifyCredit: boolean;
  /**
   * Indica se está bloqueado
   */
  isBlocked: boolean;
  /**
   * Taxa de administração
   */
  tax: number;
  /**
   * Número de parcelas (para planos sem cartão crédito)
   */
  installments: number | null;
  /**
   * Identificador do modo de pagamento
   */
  paymentMode: CartState_CartState_paymentPlans_paymentMode | null;
  /**
   * Opções quando pagamento é por cartão de crédito
   */
  creditCardOptions: CartState_CartState_paymentPlans_creditCardOptions[] | null;
  /**
   * Parcelas do plano selecionado
   */
  selectedInstallments: CartState_CartState_paymentPlans_selectedInstallments[] | null;
  /**
   * Indica se é múltiplas formas de pagamento
   */
  isMultiplePayment: boolean;
}

export interface CartState_CartState {
  __typename: "CartResponse";
  /**
   * Código do pedido
   */
  orderCode: string;
  /**
   * Código do espaço revendedor
   */
  distributionCenterCode: string;
  /**
   * Modo de entrega (retirar na central / entrega em endereço)
   */
  pickupOnDistributionCenter: boolean | null;
  /**
   * Valor do pedido mínimo
   */
  requiredMinimum: number;
  /**
   * Valor atual considerado para o pedido mínimo
   */
  currentValueConsideredToMinimum: number;
  /**
   * Ciclo do pedido
   */
  cycle: number;
  /**
   * SubCiclo do pedido
   */
  subCycle: number;
  /**
   * Dados do link externo de pagamento
   */
  externalPaymentLink: CartState_CartState_externalPaymentLink | null;
  /**
   * Crédito disponível
   */
  credit: CartState_CartState_credit | null;
  /**
   * Produtos do pedido
   */
  products: CartState_CartState_products[];
  /**
   * Produtos cortados
   */
  cutProducts: CartState_CartState_cutProducts[];
  /**
   * Produto (brinde) conquistado
   */
  acquiredProducts: CartState_CartState_acquiredProducts[] | null;
  /**
   * Promoção conquistada
   */
  acquiredPromotions: CartState_CartState_acquiredPromotions[] | null;
  /**
   * Promoção conquistada
   */
  partialPromotions: CartState_CartState_partialPromotions[] | null;
  /**
   * Opção a ser escolhida na promoção (pode ser brinde ou desconto)
   */
  rewardsToChoose: CartState_CartState_rewardsToChoose[] | null;
  /**
   * Endereço escolhido (ou padrão) para a entrega
   */
  chosenAddress: CartState_CartState_chosenAddress | null;
  /**
   * Opção de frete escolhida (ou padrão)
   */
  chosenFreightOption: CartState_CartState_chosenFreightOption | null;
  /**
   * Opção de pagamento escolhida (ou padrão)
   */
  chosenPaymentPlan: CartState_CartState_chosenPaymentPlan | null;
  /**
   * Resumo de valores do pedido
   */
  totals: CartState_CartState_totals;
  /**
   * Endereços para entrega
   */
  deliveryAddresses: CartState_CartState_deliveryAddresses[] | null;
  /**
   * Planos de pagamento
   */
  paymentPlans: CartState_CartState_paymentPlans[] | null;
}

export interface CartState {
  /**
   * Carrinho com opções de produtos substitutos
   */
  CartState: CartState_CartState;
}

export interface CartStateVariables {
  orderCode: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ComboDetail
// ====================================================

export interface ComboDetail_ComboDetail_products {
  __typename: "ComboProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome do produto
   */
  name: string;
  /**
   * Url da imagem (tamanho P)
   */
  imageUrl: string;
  /**
   * Se há quantidade disponível deste produto
   */
  available: boolean;
  /**
   * Quantidade do produto no combo
   */
  quantity: number;
  /**
   * Se a quantidade do produto no combo é fixa
   */
  quantityBlocked: boolean;
}

export interface ComboDetail_ComboDetail_productSelections_products {
  __typename: "ComboProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome do produto
   */
  name: string;
  /**
   * Url da imagem (tamanho P)
   */
  imageUrl: string;
  /**
   * Se há quantidade disponível deste produto
   */
  available: boolean;
  /**
   * Quantidade do produto no combo
   */
  quantity: number;
  /**
   * Se a quantidade do produto no combo é fixa
   */
  quantityBlocked: boolean;
}

export interface ComboDetail_ComboDetail_productSelections {
  __typename: "ComboProductSelectionType";
  /**
   * Código da seleção de produto
   */
  code: string;
  /**
   * Descrição da seleção
   */
  description: string;
  /**
   * Se mais produtos podem ser carregados
   */
  loadMore: boolean;
  /**
   * Quantidade de produtos que devem ser selecionados
   */
  quantityPerKit: number;
  /**
   * Produtos que podem ser selecionados
   */
  products: ComboDetail_ComboDetail_productSelections_products[];
}

export interface ComboDetail_ComboDetail {
  __typename: "ComboType";
  /**
   * Código do combo
   */
  code: string;
  /**
   * Nome do combo
   */
  name: string;
  /**
   * Descrição do combo
   */
  description: string | null;
  /**
   * Mensagem do Combo
   */
  message: string | null;
  /**
   * Produtos fixos do combo
   */
  products: ComboDetail_ComboDetail_products[];
  /**
   * Seleções de produtos personalizáveis
   */
  productSelections: ComboDetail_ComboDetail_productSelections[];
}

export interface ComboDetail {
  /**
   * Detalhe do combo personalizável
   */
  ComboDetail: ComboDetail_ComboDetail;
}

export interface ComboDetailVariables {
  orderCode: string;
  codeCombo: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CommercialStructureList
// ====================================================

export interface CommercialStructureList_CommercialStructureList_nodes_responsible {
  __typename: "AuthSupervisorDataType";
  /**
   * Id
   */
  id: string | null;
  /**
   * Nome
   */
  name: string | null;
}

export interface CommercialStructureList_CommercialStructureList_nodes {
  __typename: "CommercialStructureResponse";
  /**
   * Id
   */
  id: string | null;
  /**
   * Nome da estrutura
   */
  name: string | null;
  /**
   * Responsável
   */
  responsible: CommercialStructureList_CommercialStructureList_nodes_responsible | null;
}

export interface CommercialStructureList_CommercialStructureList {
  __typename: "CommercialStructurePaginatedResponse";
  /**
   * Array of CommercialStructureResponse
   */
  nodes: CommercialStructureList_CommercialStructureList_nodes[];
}

export interface CommercialStructureList {
  /**
   * Lista de estruturas comerciais do FVC
   */
  CommercialStructureList: CommercialStructureList_CommercialStructureList;
}

export interface CommercialStructureListVariables {
  offset: number;
  limit: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CompleteInfo
// ====================================================

export interface CompleteInfo_CompleteInfo_block {
  __typename: "CompleteInfoBlockType";
  /**
   * Data
   */
  date: any | null;
  /**
   * Está bloqueada?
   */
  isBlocked: boolean | null;
  /**
   * Informação sobre o motivo
   */
  info: string | null;
  /**
   * Descrição estendida do motivo
   */
  description: string | null;
}

export interface CompleteInfo_CompleteInfo_ceased {
  __typename: "CompleteInfoCeasedType";
  /**
   * Está cessada?
   */
  isCeased: boolean | null;
  /**
   * Data
   */
  date: any | null;
  /**
   * Informação sobre o motivo
   */
  info: string | null;
  /**
   * Descrição estendida do motivo
   */
  description: string | null;
}

export interface CompleteInfo_CompleteInfo_address {
  __typename: "CompleteInfoAddressType";
  /**
   * Rua
   */
  street: string | null;
  /**
   * Número
   */
  streetNumber: string | null;
  /**
   * Complemento
   */
  complement: string | null;
  /**
   * bairro
   */
  neighborhood: string | null;
  /**
   * Cidade
   */
  city: string | null;
  /**
   * Cep
   */
  zipCode: string | null;
  /**
   * Telefone residencial
   */
  residentialPhone: string | null;
  /**
   * Celular
   */
  mobilePhone: string | null;
  /**
   * Telefone de Contato
   */
  contactPhone: string | null;
  /**
   * Estado
   */
  contactAddressState: string | null;
}

export interface CompleteInfo_CompleteInfo_firstOrder {
  __typename: "CompleteInfoFirstOrderType";
  /**
   * Ciclo
   */
  cycle: string | null;
  /**
   * Data
   */
  date: any | null;
  /**
   * Estrutura
   */
  structure: string | null;
}

export interface CompleteInfo_CompleteInfo_financial {
  __typename: "CompleteInfoFinancialType";
  /**
   * Crédito disponível
   */
  availableCredit: number | null;
  /**
   * Limíte total
   */
  totalLimit: number | null;
  /**
   * Saldo EPM
   */
  epmBalance: number | null;
  /**
   * Indicação de crédito
   */
  creditIndication: string | null;
  /**
   * Retorno da Clear Sale
   */
  clearsaleReturn: string | null;
}

export interface CompleteInfo_CompleteInfo {
  __typename: "CompleteInfoResponse";
  /**
   * Última atualização
   */
  lastSync: any | null;
  /**
   * Nome
   */
  name: string | null;
  /**
   * Data de aniversário
   */
  birthdate: any | null;
  /**
   * Sexo
   */
  sex: CompleteInfoSex | null;
  /**
   * Email
   */
  email: string | null;
  /**
   * Loja
   */
  store: string | null;
  /**
   * Grupo de franquia
   */
  franchiseGroup: string | null;
  /**
   * CPF
   */
  cpf: string | null;
  /**
   * Informações de bloqueio
   */
  block: CompleteInfo_CompleteInfo_block | null;
  /**
   * Date de corte
   */
  cutDate: any | null;
  /**
   * Data de registro
   */
  registrationDate: any | null;
  /**
   * Zona de corte
   */
  cutZone: string | null;
  /**
   * Contagem de inatividade atual
   */
  currentInativityCount: number | null;
  /**
   * Contagem de inatividade na abertura
   */
  oppeningInativityCount: number | null;
  /**
   * Ciclo de vida
   */
  lifecycle: string | null;
  /**
   * Informações de cessado
   */
  ceased: CompleteInfo_CompleteInfo_ceased | null;
  /**
   * Endereço
   */
  address: CompleteInfo_CompleteInfo_address | null;
  /**
   * Primeira compra
   */
  firstOrder: CompleteInfo_CompleteInfo_firstOrder | null;
  /**
   * Financeiro
   */
  financial: CompleteInfo_CompleteInfo_financial | null;
}

export interface CompleteInfo {
  /**
   * Informações de uma revendedora
   */
  CompleteInfo: CompleteInfo_CompleteInfo;
}

export interface CompleteInfoVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FvcConversationHistory
// ====================================================

export interface FvcConversationHistory_FvcConversationHistory_overview {
  __typename: "OverviewResponse";
  count: number;
  active: number;
  receptive: number;
}

export interface FvcConversationHistory_FvcConversationHistory_nodes {
  __typename: "FvcConversationHistoryItemResponse";
  /**
   * Id
   */
  id: string;
  /**
   * Id da revendedora
   */
  resellerId: string;
  /**
   * Nome da revendedora
   */
  resellerName: string | null;
  /**
   * Id da supervisora
   */
  supervisorId: string;
  /**
   * Horário de Início do Atendimento
   */
  createdAt: any | null;
  /**
   * Horário da finalização do Atendimento
   */
  completedAt: any | null;
  /**
   * Título do Feedback
   */
  feedbackTitle: string | null;
  /**
   * Título do Feedback customizado
   */
  customTitle: string | null;
  /**
   * Notas do feedback
   */
  feedbackNote: string | null;
  /**
   * Atendimento ativo ou não
   */
  isActive: boolean | null;
  /**
   * Ciclo em que o atendimento foi realizado
   */
  cycle: string | null;
  /**
   * Ciclo em que o atendimento foi realizado
   */
  status: ConversationStatusEnum | null;
}

export interface FvcConversationHistory_FvcConversationHistory {
  __typename: "FvcConversationHistoryResponse";
  overview: FvcConversationHistory_FvcConversationHistory_overview;
  nodes: FvcConversationHistory_FvcConversationHistory_nodes[];
}

export interface FvcConversationHistory {
  /**
   * # Query - FvcConversationHistory
   * ## Description:
   * 
   * 
   *  - Histórico de atendimentos
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## Internal Dependencies:
   * 
   * 
   *  - ### FvcConversationHistoryUseCase
   *     
   * 
   * 
   *  - ### FvcResellerConversationsDatasource
   */
  FvcConversationHistory: FvcConversationHistory_FvcConversationHistory;
}

export interface FvcConversationHistoryVariables {
  commercialStructure: string;
  date: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FvcResellerConversation
// ====================================================

export interface FvcResellerConversation_FvcResellerConversation {
  __typename: "FvcResellerConversationResponse";
  /**
   * Id
   */
  id: string;
  /**
   * Id da revendedora
   */
  resellerId: string;
  /**
   * Nome da revendedora
   */
  resellerName: string | null;
  /**
   * Horário de Início do Atendimento
   */
  createdAt: any;
  /**
   * Horário da finalização do Atendimento
   */
  completedAt: any | null;
  /**
   * Indica se atendimento retornado é um agendamento
   */
  isScheduling: boolean | null;
}

export interface FvcResellerConversation {
  /**
   * # Query - FvcResellerConversation
   * ## Description:
   * 
   * 
   *  - Traz a fila de atendimentos
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## Internal Dependencies:
   * 
   * 
   *  - ### FvcResellerConversationUseCase
   *     
   * 
   * 
   *  - ### FvcResellerConversationsDatasource
   */
  FvcResellerConversation: FvcResellerConversation_FvcResellerConversation[];
}

export interface FvcResellerConversationVariables {
  commercialStructure?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CurrentConversations
// ====================================================

export interface CurrentConversations_CurrentConversations_conversations {
  __typename: "CurrentConversationsItemResponse";
  resellerId: string;
  resellerName: string;
  createdAt: any;
}

export interface CurrentConversations_CurrentConversations {
  __typename: "CurrentConversationsResponse";
  conversations: CurrentConversations_CurrentConversations_conversations[];
}

export interface CurrentConversations {
  /**
   * # Query - CurrentConversations
   * ## Description:
   * 
   * 
   *  - Lista as revendedoras que estão sendo atendidas pela supervisora.
   * ## Access Roles:
   * 
   * 
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## Internal Dependencies:
   * 
   * 
   *  - ### CurrentConversations
   *     
   * 
   * 
   *  - ### ResellerConversationDataSource
   */
  CurrentConversations: CurrentConversations_CurrentConversations;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CurrentOrders
// ====================================================

export interface CurrentOrders_ResellerCurrentOrders {
  __typename: "CurrentOrderResponse";
  /**
   * Id da revendedora
   */
  resellerId: string;
  /**
   * Identificador do ciclo para o qual o pedido se refere
   */
  cycle: string;
  /**
   * Código do centro de distribuição
   */
  distributionCenterCode: string;
  /**
   * Indica se é para retirar na CS
   */
  pickupOnDistributionCenter: boolean;
  /**
   * Data de expiração do pedido (UTC)
   */
  expiration: any;
}

export interface CurrentOrders {
  /**
   * # Query - ResellerCurrentOrders
   * ## Description:
   * 
   * 
   *  - Lista os pedidos em andamento para a supervisora
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## Internal Dependencies:
   * 
   * 
   *  - ### ResellerCurrentOrdersUseCase
   *     
   * 
   * 
   *  - ### ResellerCurrentOrderDbDatasource
   */
  ResellerCurrentOrders: CurrentOrders_ResellerCurrentOrders[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CycleDetail
// ====================================================

export interface CycleDetail_DashboardCicle {
  __typename: "DashboardCicleResponse";
  /**
   * Id do ciclo selecionado atualmente
   */
  currentCicleId: string;
  /**
   * Data de início do ciclo selecionado atualmente
   */
  currentCicleStartDate: any;
  /**
   * Data de fim do ciclo selecionado atualmente
   */
  currentCicleEndDate: any;
  /**
   * Id do próximo ciclo
   */
  nextCicleId: string | null;
  /**
   * Id do ciclo anterior
   */
  previousCicleId: string | null;
  /**
   * Última atualização do indicador
   */
  lastSync: any | null;
}

export interface CycleDetail {
  /**
   * # Query - DashboardCicle
   * ## Description:
   * 
   * 
   *  - Traz informações do ciclo selecionado
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## External Dependencies:
   * 
   * 
   *  - ### DashboardCycleUseCase
   *     
   * 
   * 
   *  - ### CycleUpdateUseCaseBoticario
   *     
   * 
   * 
   *  - ### CycleDbDataSource
   */
  DashboardCicle: CycleDetail_DashboardCicle | null;
}

export interface CycleDetailVariables {
  cycle?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DashboardResellerCycleActivation
// ====================================================

export interface DashboardResellerCycleActivation_DashboardResellerCicleActivation {
  __typename: "DashboardValueDailyGoalType";
  /**
   * Valor
   */
  value: number | null;
  /**
   * Meta
   */
  goal: number;
  /**
   * Meta até o dia atual
   */
  goalForToday: number | null;
  /**
   * Valor restante para atingir a meta no dia de hoje
   */
  pendingForTodayGoal: number | null;
  /**
   * Última atualização do indicador
   */
  lastSync: any | null;
}

export interface DashboardResellerCycleActivation {
  /**
   * # Query - DashboardResellerCicleActivation
   * ## Description:
   * 
   * 
   *  - Traz os Ativos no Ciclo
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## External Dependencies:
   * 
   * 
   *  - ### GoalHttpDataSource
   *     
   * 
   * 	
   * 	 - Endpoint Path: /grb/sb/estruturaComercial/estruturaComercial/metas/${goal}/cadastro
   * ## Internal Dependencies:
   * 
   * 
   *  - ### DashboardCycleActivationBaseUseCase
   *     
   * 
   * 
   *  - ### SupervisorCycleActivationMetricDbDataSource
   *     
   * 
   * 
   *  - ### ProcessDashboardMetricUseCase
   *     
   * 
   * 
   *  - ### SupervisorMetricGoalsDbDataSource
   *     
   * 
   * 
   *  - ### SupervisorRankingMetricDbDataSource
   */
  DashboardResellerCicleActivation: DashboardResellerCycleActivation_DashboardResellerCicleActivation | null;
}

export interface DashboardResellerCycleActivationVariables {
  cycleId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DashboardFinance
// ====================================================

export interface DashboardFinance_DashboardFinance {
  __typename: "DashboardValueDailyGoalType";
  /**
   * Valor Praticado
   */
  practicedValue: number | null;
  /**
   * Percentual da Meta praticada
   */
  practicedGoalPercentage: number | null;
  /**
   * Percentual da meta praticada até o dia atual
   */
  practicedGoalForTodayPercentage: number | null;
  /**
   * Meta praticada
   */
  practicedGoal: number | null;
  /**
   * Meta praticada até o dia atual
   */
  practicedGoalForToday: number | null;
  /**
   * Valor
   */
  value: number | null;
  /**
   * Meta
   */
  goal: number;
  /**
   * Meta até o dia atual
   */
  goalForToday: number | null;
  /**
   * Valor restante para atingir a meta no dia de hoje
   */
  pendingForTodayGoal: number | null;
  /**
   * Última atualização do indicador
   */
  lastSync: any | null;
}

export interface DashboardFinance {
  /**
   * # Query - DashboardFinance
   * ## Description:
   * 
   * 
   *  - Traz a receita do ciclo selecionado (metas, valor e etc.)
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## External Dependencies:
   * 
   * 
   *  - ### GoalHttpDataSource
   *     
   * 
   * 	
   * 	 - Endpoint Path: /grb/sb/estruturaComercial/estruturaComercial/metas/${goal}/cadastro
   * ## Internal Dependencies:
   * 
   * 
   *  - ### DashboardRevenueUseCase
   *     
   * 
   * 
   *  - ### SupervisorRevenueMetricDbDataSource
   *     
   * 
   * 
   *  - ### ProcessDashboardMetricUseCase
   *     
   * 
   * 
   *  - ### SupervisorMetricGoalsDbDataSource
   *     
   * 
   * 
   *  - ### SupervisorRankingMetricDbDataSource
   */
  DashboardFinance: DashboardFinance_DashboardFinance | null;
}

export interface DashboardFinanceVariables {
  cycleId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DashboardIndicatorGoalsQuery
// ====================================================

export interface DashboardIndicatorGoalsQuery_FvcGoals {
  __typename: "FvcGoalsResponse";
  /**
   * Meta de recuperação
   */
  retrieval: number;
  /**
   * Meta de revendedoras a cessar
   */
  i6Amount: number;
  /**
   * Meta de revendedoras ativas no ciclo
   */
  cycleActives: number;
  /**
   * Meta de receita
   */
  revenue: number;
  /**
   * Data de importação das metas
   */
  lastSync: any;
}

export interface DashboardIndicatorGoalsQuery {
  /**
   * # Query - FvcGoals
   * ## Description:
   * 
   * 
   *  - Traz a relação de revendedoras da estrutura comercial selecionada e seus respectivos níveis
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## Internal Dependencies:
   * 
   * 
   *  - ### FvcGoalsUseCase
   *     
   * 
   * 
   *  - ### SupervisorMetricGoalsDbDataSource
   */
  FvcGoals: DashboardIndicatorGoalsQuery_FvcGoals;
}

export interface DashboardIndicatorGoalsQueryVariables {
  cycleId: string;
  commercialStructure: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DashboardResellerBaseInOutQuery
// ====================================================

export interface DashboardResellerBaseInOutQuery_DashboardResellerBaseInOut {
  __typename: "DashboardResellerBaseInOutResponse";
  /**
   * Inícios
   */
  start: number;
  /**
   * Reinícios
   */
  restart: number;
  /**
   * Cessando no ciclo
   */
  outOnCicle: number;
  /**
   * Última atualização do indicador
   */
  lastSync: any | null;
}

export interface DashboardResellerBaseInOutQuery {
  /**
   * # Query - DashboardResellerBaseInOut
   * ## Description:
   * 
   * 
   *  - Traz o movimento da Base - Entradas e Saídas
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## Internal Dependencies:
   * 
   * 
   *  - ### DashboardBaseInOutBaseUseCase
   *     
   * 
   * 
   *  - ### SupervisorBaseInOutMetricDbDataSource
   */
  DashboardResellerBaseInOut: DashboardResellerBaseInOutQuery_DashboardResellerBaseInOut | null;
}

export interface DashboardResellerBaseInOutQueryVariables {
  cycleId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DashboardResellerStatus
// ====================================================

export interface DashboardResellerStatus_DashboardResellerBaseQuality_activity {
  __typename: "DashboardResellerActivationCategoryTotalType";
  /**
   * Categoria de Ativação
   */
  activationCategory: ResellerActivationCategoryType;
  /**
   * Quantidade de revendedores atualmente
   */
  quantity: number;
  /**
   * Quantidade de revendedores no início do ciclo
   */
  openingQuantity: number;
  /**
   * Porcentagem nesta categoria atualmente
   */
  percentage: number;
  /**
   * Porcentagem nesta categoria no início do ciclo
   */
  openingPercentage: number;
  /**
   * Porcentagem de revendedores que saíram da categoria atual e foram para a categoria A0
   */
  recovery: number;
}

export interface DashboardResellerStatus_DashboardResellerBaseQuality {
  __typename: "DashboardResellerBaseQualityResponse";
  /**
   * Qualidade da base - Quantidade e %
   */
  activity: DashboardResellerStatus_DashboardResellerBaseQuality_activity[];
  /**
   * Última atualização do indicador
   */
  lastSync: any;
}

export interface DashboardResellerStatus {
  /**
   * # Query - DashboardResellerBaseQuality
   * ## Description:
   * 
   * 
   *  - Traz a qualidade da Base
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## Internal Dependencies:
   * 
   * 
   *  - ### DashboardBaseQualityUseCase
   *     
   * 
   * 
   *  - ### SupervisorBaseQualityMetricDbDataSource
   */
  DashboardResellerBaseQuality: DashboardResellerStatus_DashboardResellerBaseQuality | null;
}

export interface DashboardResellerStatusVariables {
  cycleId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Dashboard
// ====================================================

export interface Dashboard_DashboardRanking {
  __typename: "DashboardRankingResponse";
  /**
   * Categoria
   */
  category: string;
  /**
   * Posição Categoria-CS
   */
  positionCategoryCS: number;
  /**
   * Posição-CS
   */
  positionCS: number;
  /**
   * Última atualização do indicador
   */
  lastSync: any | null;
}

export interface Dashboard_DashboardResellerBaseIn {
  __typename: "DashboardValueDailyGoalType";
  /**
   * Valor
   */
  value: number | null;
  /**
   * Meta
   */
  goal: number;
  /**
   * Meta até o dia atual
   */
  goalForToday: number | null;
  /**
   * Valor restante para atingir a meta no dia de hoje
   */
  pendingForTodayGoal: number | null;
  /**
   * Última atualização do indicador
   */
  lastSync: any | null;
}

export interface Dashboard_DashboardResellerBaseInOut {
  __typename: "DashboardResellerBaseInOutResponse";
  /**
   * Inícios
   */
  start: number;
  /**
   * Reinícios
   */
  restart: number;
  /**
   * Cessando no ciclo
   */
  outOnCicle: number;
  /**
   * Saldo
   */
  delta: number;
  /**
   * Última atualização do indicador
   */
  lastSync: any | null;
}

export interface Dashboard_DashboardResellerStoreVsDelivery_store {
  __typename: "DashboardResellerStoreVsDeliveryItemType";
  /**
   * Receita R$ %
   */
  income: number;
  /**
   * Número de Pedidos %
   */
  orders: number;
  /**
   * R$/Ativo
   */
  incomePerActive: number;
  /**
   * Pedido médio
   */
  averageOrder: number;
}

export interface Dashboard_DashboardResellerStoreVsDelivery_delivery {
  __typename: "DashboardResellerStoreVsDeliveryItemType";
  /**
   * Receita R$ %
   */
  income: number;
  /**
   * Número de Pedidos %
   */
  orders: number;
  /**
   * R$/Ativo
   */
  incomePerActive: number;
  /**
   * Pedido médio
   */
  averageOrder: number;
}

export interface Dashboard_DashboardResellerStoreVsDelivery {
  __typename: "DashboardResellerStoreVsDeliveryResponse";
  /**
   * Vendas na Loja
   */
  store: Dashboard_DashboardResellerStoreVsDelivery_store;
  /**
   * Vendas no Delivery
   */
  delivery: Dashboard_DashboardResellerStoreVsDelivery_delivery;
  /**
   * R$ por Ativo
   */
  totalRevenue: number | null;
  /**
   * Última atualização do indicador
   */
  lastSync: any;
}

export interface Dashboard_DashboardWelch {
  __typename: "DashboardWelchResponse";
  /**
   * % de inadimplência atual
   */
  currentPercentage: number | null;
  /**
   * Meta de % de inadimplência
   */
  goal: number;
  /**
   * Total de inadimplentes (somente para ciclo atual)
   */
  totalForCurrentCicle: number | null;
  /**
   * Última atualização do indicador
   */
  lastSync: any | null;
}

export interface Dashboard_DashboardResellerCycleERActivation {
  __typename: "ResellerCycleERActivationType";
  /**
   * Valor
   */
  value: number | null;
  /**
   * Meta
   */
  goal: number;
  /**
   * Porcentagem da Meta
   */
  goalPercentage: number | null;
  /**
   * Meta Acumulada até a data atual
   */
  goalForToday: number | null;
  /**
   * Porcentagem da Meta Acumulada até a data atual
   */
  goalForTodayPercentage: number | null;
  /**
   * Última atualização do indicador
   */
  lastSync: any | null;
}

export interface Dashboard {
  /**
   * # Query - DashboardRanking
   * ## Description:
   * 
   * 
   *  - Traz o ranking da supervisora selecionada
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## Internal Dependencies:
   * 
   * 
   *  - ### DashboardRankingUseCase
   *     
   * 
   * 
   *  - ### SupervisorRankingMetricDbDataSource
   */
  DashboardRanking: Dashboard_DashboardRanking | null;
  /**
   * # Query - DashboardResellerBaseIn
   * ## Description:
   * 
   * 
   *  - Traz o movimento da Base
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## External Dependencies:
   * 
   * 
   *  - ### GoalHttpDataSource
   *     
   * 
   * 	
   * 	 - Endpoint Path: /grb/sb/estruturaComercial/estruturaComercial/metas/${goal}/cadastro
   * ## Internal Dependencies:
   * 
   * 
   *  - ### DashboardBaseInUseCase
   *     
   * 
   * 
   *  - ### SupervisorBaseInOutMetricDbDataSource
   *     
   * 
   * 
   *  - ### ProcessDashboardMetricUseCase
   *     
   * 
   * 
   *  - ### SupervisorMetricGoalsDbDataSource
   *     
   * 
   * 
   *  - ### SupervisorRankingMetricDbDataSource
   */
  DashboardResellerBaseIn: Dashboard_DashboardResellerBaseIn | null;
  /**
   * # Query - DashboardResellerBaseInOut
   * ## Description:
   * 
   * 
   *  - Traz o movimento da Base - Entradas e Saídas
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## Internal Dependencies:
   * 
   * 
   *  - ### DashboardBaseInOutBaseUseCase
   *     
   * 
   * 
   *  - ### SupervisorBaseInOutMetricDbDataSource
   */
  DashboardResellerBaseInOut: Dashboard_DashboardResellerBaseInOut | null;
  /**
   * # Query - DashboardResellerStoreVsDelivery
   * ## Description:
   * 
   * 
   *  - Traz a venda e receita por subcanal no ciclo selecionado
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## Internal Dependencies:
   * 
   * 
   *  - ### DashboardStoreVsDeliveryUseCase
   *     
   * 
   * 
   *  - ### SupervisorStoreVsDeliveryMetricDbDataSource
   *     
   * 
   * 
   *  - ### SupervisorActiveValueMetricDbDataSource
   */
  DashboardResellerStoreVsDelivery: Dashboard_DashboardResellerStoreVsDelivery | null;
  /**
   * # Query - DashboardWelch
   * ## Description:
   * 
   * 
   *  - Traz informações de inadimplência do ciclo selecionado
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## External Dependencies:
   * 
   * 
   *  - ### DashboardCycleUseCase
   *     
   * 
   * 
   *  - ### CycleUpdateUseCaseBoticario
   *     
   * 
   * 
   *  - ### CycleDbDataSource
   */
  DashboardWelch: Dashboard_DashboardWelch | null;
  /**
   * # Query - DashboardResellerCycleERActivation
   * ## Description:
   * 
   * 
   *  - Traz os ativos no Ciclo ER
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## Internal Dependencies:
   * 
   * 
   *  - ### ResellerCycleERActivationUseCase
   *     
   * 
   * 
   *  - ### SupervisorCycleERActivationMetricDbDataSource
   */
  DashboardResellerCycleERActivation: Dashboard_DashboardResellerCycleERActivation | null;
}

export interface DashboardVariables {
  cycleId?: string | null;
  commercialStructure?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DocumentsStatus
// ====================================================

export interface DocumentsStatus_DocumentsStatus {
  __typename: "DocumentStatusResponse";
  /**
   * tipo do documento
   */
  document: DocumentTypeModel;
  /**
   * Status do documento
   */
  status: DocumentCheckModel;
}

export interface DocumentsStatus {
  /**
   * # Query - DocumentsStatus
   * ## Description:
   * 
   * 
   *  - Lista status dos documentos de uma revendedora
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## External Dependencies:
   * 
   * 
   *  - ### ResellerHttpDataSource
   *     
   * 
   * 	
   * 	 - Endpoint Path: /grb/sb/revendedoras/${resellerId}/documentos
   * ## Internal Dependencies:
   * 
   * 
   *  - ### ListDocumentsStatusUseCase
   */
  DocumentsStatus: DocumentsStatus_DocumentsStatus[];
}

export interface DocumentsStatusVariables {
  resellerId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: EudoraProductSearch
// ====================================================

export interface EudoraProductSearch_EudoraProductSearch {
  __typename: "EudoraProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Url da imagem (tamanho P)
   */
  imageUrl: string;
  /**
   * Se o produto é um combo personalizável
   */
  isCombo: boolean | null;
}

export interface EudoraProductSearch {
  /**
   * Produtos retornados da busca
   */
  EudoraProductSearch: EudoraProductSearch_EudoraProductSearch[];
}

export interface EudoraProductSearchVariables {
  limit?: number | null;
  offset?: number | null;
  orderCode: string;
  codeOrName: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: EudoraResellerCredit
// ====================================================

export interface EudoraResellerCredit_EudoraResellerCredit_residual_creditStatement {
  __typename: "ResidualCreditAccountEntriesType";
  id: number;
  /**
   * Data de crédito (yyyy-mm-dd)
   */
  date: any;
  /**
   * Data de utilização
   */
  usedAt: any | null;
  /**
   * Origem do crédito
   */
  originDescription: string;
  /**
   * ID do pedido em que o crédito foi utilizado
   */
  orderCredit: number | null;
  /**
   * Situação do crédito (ATIVO | INATIVO | CANCELADO)
   */
  status: StatusCCREntry;
  /**
   * Tipo do crédito (Débito | Crédito)
   */
  type: string;
  /**
   * Valor do crédito
   */
  value: number;
}

export interface EudoraResellerCredit_EudoraResellerCredit_residual {
  __typename: "ResidualCreditAccountType";
  /**
   * Valor do crédito residual
   */
  balance: number | null;
  /**
   * Entradas do crédito residual
   */
  creditStatement: EudoraResellerCredit_EudoraResellerCredit_residual_creditStatement[] | null;
}

export interface EudoraResellerCredit_EudoraResellerCredit {
  __typename: "EudoraResellerCreditResponse";
  /**
   * Crédito disponível para compra no momento
   */
  available: number | null;
  /**
   * Crédito total para compra no momento
   */
  total: number | null;
  /**
   * Crédito não faturado
   */
  uninvoiced: number | null;
  /**
   * Crédito a vencer
   */
  due: number | null;
  /**
   * Crédito vencido
   */
  overdue: number | null;
  /**
   * Crédito residual
   */
  residual: EudoraResellerCredit_EudoraResellerCredit_residual | null;
}

export interface EudoraResellerCredit {
  /**
   * Dados de crédito de uma revendedora sem tolerância
   */
  EudoraResellerCredit: EudoraResellerCredit_EudoraResellerCredit;
}

export interface EudoraResellerCreditVariables {
  id: string;
  filters?: ResellerCreditCCRFiltersInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: EudoraResellerDetail
// ====================================================

export interface EudoraResellerDetail_ResellerRegistrationInfo_commercialStructure {
  __typename: "ResellerCommercialStructureType";
  /**
   * Identificador da estrutura comercial
   */
  referenceId: string | null;
  /**
   * Nome do franqueado
   */
  franchiseName: string | null;
  /**
   * Nome da central de serviço
   */
  serviceCenterName: string | null;
}

export interface EudoraResellerDetail_ResellerRegistrationInfo_addresses_info {
  __typename: "AddressInfoType";
  state: string | null;
}

export interface EudoraResellerDetail_ResellerRegistrationInfo_addresses {
  __typename: "AddressType";
  /**
   * Tipo de endereço
   */
  type: RegistrationInfoAddressType | null;
  /**
   * Informações do endereço
   */
  info: EudoraResellerDetail_ResellerRegistrationInfo_addresses_info | null;
}

export interface EudoraResellerDetail_ResellerRegistrationInfo {
  __typename: "ResellerRegistrationInfoResponse";
  resellerId: string | null;
  commercialStructure: EudoraResellerDetail_ResellerRegistrationInfo_commercialStructure;
  /**
   * Endereços
   */
  addresses: EudoraResellerDetail_ResellerRegistrationInfo_addresses[] | null;
  /**
   * Está inadimplente?
   */
  isWelch: boolean | null;
}

export interface EudoraResellerDetail_FvcResellerDetail_profile {
  __typename: "ResellerDetailProfileType";
  /**
   * Nome da revendedora
   */
  name: string;
}

export interface EudoraResellerDetail_FvcResellerDetail_cicleStatus {
  __typename: "ResellerDetailCicleStatusType";
  /**
   * Categoria de Ativação
   */
  activationCategory: ResellerActivationCategoryType | null;
}

export interface EudoraResellerDetail_FvcResellerDetail {
  __typename: "ResellerDetailResponse";
  /**
   * Id da revendedora
   */
  id: string;
  /**
   * Dados do perfil da revendedora
   */
  profile: EudoraResellerDetail_FvcResellerDetail_profile;
  /**
   * Status da revendedora no ciclo atual
   */
  cicleStatus: EudoraResellerDetail_FvcResellerDetail_cicleStatus;
}

export interface EudoraResellerDetail {
  /**
   * # Query - ResellerRegistrationInfo
   * ## Description:
   * 
   * 
   *  - Traz as informações cadastrais do(a) revendedor(a) armazenadas no gera
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## External Dependencies:
   * 
   * 
   *  - ### ResellerHttpDataSource
   *     
   * 
   * 	
   * 	 - Endpoint Path:  /grb/sb/revendedoras/${resellerId}/cadastro
   * ## Internal Dependencies:
   * 
   * 
   *  - ### ResellerRegistrationInfoUseCase
   *     
   * 
   * 
   *  - ### ResellerRegistrationInfoRepository
   *     
   * 
   * 
   *  - ### SupervisorRankingMetricDbDataSource
   */
  ResellerRegistrationInfo: EudoraResellerDetail_ResellerRegistrationInfo;
  /**
   * # Query - FvcResellerDetail
   * ## Description:
   * 
   * 
   *  - Traz os detalhes da revendedora selecionada
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## Internal Dependencies:
   * 
   * 
   *  - ### FvcResellerDetailUseCase
   *     
   * 
   * 
   *  - ### ResellerDbDataSource
   */
  FvcResellerDetail: EudoraResellerDetail_FvcResellerDetail | null;
}

export interface EudoraResellerDetailVariables {
  resellerId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FvcConversationHistoryByPeriod
// ====================================================

export interface FvcConversationHistoryByPeriod_FvcConversationHistoryByPeriod_overview {
  __typename: "OverviewResponse";
  count: number;
  active: number;
  receptive: number;
}

export interface FvcConversationHistoryByPeriod_FvcConversationHistoryByPeriod_nodes {
  __typename: "FvcConversationHistoryItemResponse";
  /**
   * Id
   */
  id: string;
  /**
   * Id da revendedora
   */
  resellerId: string;
  /**
   * Nome da revendedora
   */
  resellerName: string | null;
  /**
   * Id da supervisora
   */
  supervisorId: string;
  /**
   * Horário de Início do Atendimento
   */
  createdAt: any | null;
  /**
   * Horário da finalização do Atendimento
   */
  completedAt: any | null;
  /**
   * Título do Feedback
   */
  feedbackTitle: string | null;
  /**
   * Título do Feedback customizado
   */
  customTitle: string | null;
  /**
   * Notas do feedback
   */
  feedbackNote: string | null;
  /**
   * Atendimento ativo ou não
   */
  isActive: boolean | null;
  /**
   * Horário de abertura do agendamento
   */
  schedulingOpening: any | null;
  /**
   * Horário de fechamento do agendamento
   */
  schedulingClosing: any | null;
  /**
   * Ciclo em que o atendimento foi realizado
   */
  cycle: string | null;
  /**
   * Ciclo em que o atendimento foi realizado
   */
  status: ConversationStatusEnum | null;
}

export interface FvcConversationHistoryByPeriod_FvcConversationHistoryByPeriod {
  __typename: "FvcConversationHistoryResponse";
  overview: FvcConversationHistoryByPeriod_FvcConversationHistoryByPeriod_overview;
  nodes: FvcConversationHistoryByPeriod_FvcConversationHistoryByPeriod_nodes[];
}

export interface FvcConversationHistoryByPeriod {
  /**
   * # Query - FvcConversationHistory
   * ## Description:
   * 
   * 
   *  - Histórico de atendimentos com filtero de periodo
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## Internal Dependencies:
   * 
   * 
   *  - ### FvcConversationHistoryByPeriodUseCase
   *     
   * 
   * 
   *  - ### FvcResellerConversationsDatasource
   */
  FvcConversationHistoryByPeriod: FvcConversationHistoryByPeriod_FvcConversationHistoryByPeriod;
}

export interface FvcConversationHistoryByPeriodVariables {
  commercialStructure: string;
  endDate: any;
  startDate: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FvcIndicators
// ====================================================

export interface FvcIndicators_FvcIndicators_resellerStatusCount {
  __typename: "IndicatorStatusResponse";
  status: string;
  count: number;
  resellerAttendedCount: number;
  feedbackCount: number;
}

export interface FvcIndicators_FvcIndicators_allFeedbacksVolume {
  __typename: "FeedbackRankResponse";
  count: number;
  feedback: string;
  category: string;
  percentage: string;
}

export interface FvcIndicators_FvcIndicators {
  __typename: "FvcIndicatorsResponse";
  /**
   * Revendedores na estrutura
   */
  resellerInStructure: number;
  /**
   * Revendedores ativos na estrutura
   */
  activeResellersInStructure: number;
  /**
   * Quantidade de REs com status I6 que fizeram pedido no ciclo
   */
  recoveryCount: number;
  /**
   * Vendas realizadas
   */
  boughtFeedbacks: number;
  /**
   * Total de tabulações
   */
  allFeedbacks: number;
  /**
   * Médias de tabulações por dia
   */
  feedbackPerDay: number;
  /**
   * Quantidade de resellers em cada status
   */
  resellerStatusCount: FvcIndicators_FvcIndicators_resellerStatusCount[];
  /**
   * Ranking dos 10 maiores casos de tabulação
   */
  allFeedbacksVolume: FvcIndicators_FvcIndicators_allFeedbacksVolume[];
  /**
   * Ranking de vendas realizadas
   */
  allBoughtFeedbacks: number[];
}

export interface FvcIndicators {
  /**
   * # Query - FvcIndicators
   * ## Description:
   * 
   * 
   *  - Traz indicadores relacionados ao atendimento de uma atendente
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## Internal Dependencies:
   * 
   * 
   *  - ### FvcIndicatorsUseCase
   *     
   * 
   * 
   *  - ### FvcIndicatorsDbDataSource
   */
  FvcIndicators: FvcIndicators_FvcIndicators;
}

export interface FvcIndicatorsVariables {
  structure: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GeraStructure
// ====================================================

export interface GeraStructure_FvcGeraStructure {
  __typename: "FvcGeraStructurePaginatedResponse";
  /**
   * Array of String
   */
  nodes: string[];
}

export interface GeraStructure {
  /**
   * # Query - FvcGeraStructure
   * ## Description:
   * 
   * 
   *  - Traz a estrutura gera da supervisora FVC
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## Internal Dependencies:
   * 
   * 
   *  - ### FvcGeraStructureUseCase
   *     
   * 
   * 
   *  - ### FvcStructureDbDataSource
   */
  FvcGeraStructure: GeraStructure_FvcGeraStructure;
}

export interface GeraStructureVariables {
  fakeCommercialStructure: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetGanheMaisTiers
// ====================================================

export interface GetGanheMaisTiers_GetGanheMaisTiers_tiers_prizes_replacements {
  __typename: "GanheMaisPrizeReplacementResponse";
  code: string | null;
  image: string | null;
  name: string | null;
}

export interface GetGanheMaisTiers_GetGanheMaisTiers_tiers_prizes {
  __typename: "GanheMaisPrizeResponse";
  code: string | null;
  image: string | null;
  name: string | null;
  quantity: number | null;
  replacements: GetGanheMaisTiers_GetGanheMaisTiers_tiers_prizes_replacements[];
}

export interface GetGanheMaisTiers_GetGanheMaisTiers_tiers {
  __typename: "GanheMaisTierResponse";
  startValue: number;
  endValue: number | null;
  missingValue: number;
  acquired: boolean;
  logicalOperator: GanheMaisLogicalOperator | null;
  prizes: GetGanheMaisTiers_GetGanheMaisTiers_tiers_prizes[];
}

export interface GetGanheMaisTiers_GetGanheMaisTiers {
  __typename: "GanheMaisResponse";
  currentValue: number;
  tiers: GetGanheMaisTiers_GetGanheMaisTiers_tiers[];
}

export interface GetGanheMaisTiers {
  /**
   * # Query - GetGanheMaisTiers
   * ## Description:
   * 
   * 
   *  - Traz as informações dos níveis do ganhe mais e o nível de uma dada revendedora
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## External Dependencies:
   * 
   * 
   *  - ### ResellerHttpDataSource
   *     
   * 
   * 	
   * 	 - Endpoint Path: /grb/sb/revendedoras/${resellerId}/promocoes
   * ## Internal Dependencies:
   * 
   * 
   *  - ### GanheMaisUseCase
   */
  GetGanheMaisTiers: GetGanheMaisTiers_GetGanheMaisTiers;
}

export interface GetGanheMaisTiersVariables {
  resellerId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetServiceCenter
// ====================================================

export interface GetServiceCenter_GetServiceCenter_promotions {
  __typename: "PromotionResponse";
  id: string;
  title: string;
  description: string;
  startDate: any;
  endDate: any;
}

export interface GetServiceCenter_GetServiceCenter_categories_questions_answers {
  __typename: "AnswerResponse";
  id: string;
  answer: string;
}

export interface GetServiceCenter_GetServiceCenter_categories_questions {
  __typename: "QuestionResponse";
  id: string;
  /**
   * Pergunta
   */
  question: string | null;
  /**
   * Resposta
   */
  answers: GetServiceCenter_GetServiceCenter_categories_questions_answers[] | null;
}

export interface GetServiceCenter_GetServiceCenter_categories {
  __typename: "CategoriesResponse";
  id: string;
  /**
   * Nome da categoria
   */
  categoryName: string | null;
  /**
   * Perguntas
   */
  questions: GetServiceCenter_GetServiceCenter_categories_questions[] | null;
}

export interface GetServiceCenter_GetServiceCenter {
  __typename: "ChecklistResponse";
  /**
   * Código da central de serviços
   */
  referenceId: string;
  /**
   * Seção de promoções
   */
  promotions: GetServiceCenter_GetServiceCenter_promotions[];
  /**
   * Seções das perguntas
   */
  categories: GetServiceCenter_GetServiceCenter_categories[];
}

export interface GetServiceCenter {
  /**
   * # Query - GetServiceCenter
   * ## Description:
   * 
   * 
   *  - Obtém os dados da CS(loja) para uma revendedora
   * ## Access Roles:
   * 
   * 
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## Internal Dependencies:
   * 
   * 
   *  - ### GetServiceCenterUseCase
   *     
   * 
   * 
   *  - ### ServiceCenterDataSource
   */
  GetServiceCenter: GetServiceCenter_GetServiceCenter | null;
}

export interface GetServiceCenterVariables {
  resellerId: string;
  questionInEnvironment?: QuestionEnvironment | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ResellerCartInitialParams
// ====================================================

export interface ResellerCartInitialParams_ResellerCartInitialParams_starterPacks_products {
  __typename: "CartInitialParamsStarterPackProductResponse";
  /**
   * Identificador do produto
   */
  id: string | null;
  /**
   * Nome do produto
   */
  name: string | null;
  /**
   * Descrição do produto
   */
  description: string | null;
}

export interface ResellerCartInitialParams_ResellerCartInitialParams_starterPacks {
  __typename: "CartInitialParamsStarterPackResponse";
  /**
   * Identificador do kit início
   */
  id: string | null;
  /**
   * Nome do kit início
   */
  name: string | null;
  /**
   * Descrição do kit início
   */
  description: string | null;
  /**
   * Quantidade de produtos a serem escolhidos para o kit início
   */
  quantity: number | null;
  /**
   * Produtos disponíveis para escolha do kit início
   */
  products: ResellerCartInitialParams_ResellerCartInitialParams_starterPacks_products[] | null;
}

export interface ResellerCartInitialParams_ResellerCartInitialParams_delivery_modes {
  __typename: "CartInitialParamsDeliveryModeResponse";
  /**
   * Código da central
   */
  modeId: string | null;
  /**
   * Modo de retirada
   */
  withdraw: boolean | null;
  /**
   * Endereço de entrega/retirada
   */
  address: string | null;
}

export interface ResellerCartInitialParams_ResellerCartInitialParams_delivery {
  __typename: "CartInitialParamsDeliveryResponse";
  /**
   * Código da central
   */
  id: string | null;
  /**
   * Modos de entrega
   */
  modes: ResellerCartInitialParams_ResellerCartInitialParams_delivery_modes[] | null;
}

export interface ResellerCartInitialParams_ResellerCartInitialParams {
  __typename: "CartInitialParamsResponse";
  /**
   * Parâmetros do(s) Kit(s) início
   */
  starterPacks: ResellerCartInitialParams_ResellerCartInitialParams_starterPacks[] | null;
  /**
   * Parâmetros de entrega
   */
  delivery: ResellerCartInitialParams_ResellerCartInitialParams_delivery | null;
}

export interface ResellerCartInitialParams {
  /**
   * # Query - ResellerCartInitialParams
   * ## Description:
   * 
   * 
   *  - Traz os parâmetros iniciais para um pedido
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## External Dependencies:
   * 
   * 
   *  - ### InitialParamsHttpDatasource
   *     
   * 
   * 
   *  - ### BoticarioBaseCartHttpDatasource
   *     
   * 
   * 	
   * 	 - Endpoint Path: /cart/initialparams
   * ## Internal Dependencies:
   * 
   * 
   *  - ### ResellerCartInitialParamsUseCase
   */
  ResellerCartInitialParams: ResellerCartInitialParams_ResellerCartInitialParams;
}

export interface ResellerCartInitialParamsVariables {
  resellerId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IrResellerDetail
// ====================================================

export interface IrResellerDetail_IrResellerDetail {
  __typename: "IrResellerDetailResponse";
  active: boolean;
  referenceId: string;
  hasActualCredit: boolean | null;
  journey: string | null;
  biometryApproved: boolean | null;
  biometryRepproved: boolean | null;
  biometryNotSent: boolean | null;
  waitingBiometry: boolean | null;
  actualFraude: boolean | null;
  isDigitalTable: boolean | null;
  registerCycle: number | null;
  registerDate: any | null;
  hasGift: boolean | null;
  activationChannel: string | null;
}

export interface IrResellerDetail {
  IrResellerDetail: IrResellerDetail_IrResellerDetail;
}

export interface IrResellerDetailVariables {
  resellerId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LatestResellerConversations
// ====================================================

export interface LatestResellerConversations_LatestResellerConversations_conversations_reseller {
  __typename: "ResellerResponse";
  referenceId: string;
  name: string;
}

export interface LatestResellerConversations_LatestResellerConversations_conversations_supervisor {
  __typename: "SupervisorResponse";
  geraId: string;
  name: string;
}

export interface LatestResellerConversations_LatestResellerConversations_conversations_feedback {
  __typename: "FeedbackResponse";
  title: string;
}

export interface LatestResellerConversations_LatestResellerConversations_conversations {
  __typename: "ResellerConversationResponse";
  reseller: LatestResellerConversations_LatestResellerConversations_conversations_reseller;
  /**
   * Informações da atendente
   */
  supervisor: LatestResellerConversations_LatestResellerConversations_conversations_supervisor;
  feedback: LatestResellerConversations_LatestResellerConversations_conversations_feedback | null;
  status: ConversationStatusEnum | null;
  note: string | null;
  createdAt: any;
}

export interface LatestResellerConversations_LatestResellerConversations {
  __typename: "LatestResellerConversationsListResponse";
  conversations: LatestResellerConversations_LatestResellerConversations_conversations[];
  count: number;
}

export interface LatestResellerConversations {
  /**
   * # Query - LatestResellerConversations
   * ## Description:
   * 
   * 
   *  - Lista últimas chamadas de uma revendedora
   * ## Access Roles:
   * 
   * 
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## Internal Dependencies:
   * 
   * 
   *  - ### LatestResellerConversations
   *     
   * 
   * 
   *  - ### ResellerConversationDataSource
   */
  LatestResellerConversations: LatestResellerConversations_LatestResellerConversations | null;
}

export interface LatestResellerConversationsVariables {
  resellerId: string;
  limit?: number | null;
  offset?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ListChannelByCoordinator
// ====================================================

export interface ListChannelByCoordinator_ListChannelByCoordinator {
  __typename: "ChannelResponse";
  /**
   * Código do canal
   */
  code: number;
  description: string;
  /**
   * Código do coordenador do canal
   */
  coordinatorCode: number;
}

export interface ListChannelByCoordinator {
  /**
   * Lista todos os canais por coordenação comercial
   */
  ListChannelByCoordinator: ListChannelByCoordinator_ListChannelByCoordinator[];
}

export interface ListChannelByCoordinatorVariables {
  coordinatorCode: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ListCommercialCoordinator
// ====================================================

export interface ListCommercialCoordinator_ListCommercialCoordinator {
  __typename: "CommercialCoordinatorResponse";
  /**
   * Código do coordenador comercial
   */
  code: number;
  description: string;
}

export interface ListCommercialCoordinator {
  /**
   * Lista todas as coordenações comerciais
   */
  ListCommercialCoordinator: ListCommercialCoordinator_ListCommercialCoordinator[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ListCategoriesAndFeedbacks
// ====================================================

export interface ListCategoriesAndFeedbacks_ListCategoriesAndFeedbacks_feedbacks {
  __typename: "FeedbackResponse";
  id: number | null;
  title: string;
  /**
   * Sinaliza se feedback terá campo de forma de contato
   */
  hasContactForm: boolean;
  /**
   * Sinaliza se o campo possui agendamento
   */
  hasScheduling: boolean | null;
  /**
   * Sinaliza se feedback terá campo de observações
   */
  hasNote: boolean;
  /**
   * Sinaliza se o campo de observação é obrigatório
   */
  isRequiredNote: boolean | null;
  /**
   * Sinaliza para feedbacks de Ativação se eles são visíveis para adimplentes e inadimplentes
   */
  visibility: ActivationFeedbackVisibilty | null;
  /**
   * Código usado na tabulação do Gera
   */
  geraCode: string | null;
  /**
   * Código usado na tabulação do NCall
   */
  nCallCode: string | null;
  /**
   * Prioridade de ordenação
   */
  priority: number | null;
  active: boolean | null;
  /**
   * Detalhe do subnível de tabulação
   */
  details: string[] | null;
  /**
   * Sinaliza se o feedback não é tabulado no gera
   */
  noTabulation: boolean | null;
}

export interface ListCategoriesAndFeedbacks_ListCategoriesAndFeedbacks {
  __typename: "FeedbackCategoryItemsResponse";
  id: number;
  title: string;
  active: boolean;
  page: FvcPage;
  priority: number | null;
  feedbacks: ListCategoriesAndFeedbacks_ListCategoriesAndFeedbacks_feedbacks[];
}

export interface ListCategoriesAndFeedbacks {
  /**
   * # Query - ListCategoriesAndFeedbacks
   * ## Description:
   * 
   * 
   *  - Traz as categorias e feedbacks disponíveis conforme a página e o status da revendedora, lista todos independente caso seja admin.
   * ## Access Roles:
   * 
   * 
   *  - admin
   *  - supervisor
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## Internal Dependencies:
   * 
   * 
   *  - ### ListCategoriesAndFeedbacksUseCase
   *     
   * 
   * 
   *  - ### FeedbackCategoryDataSource
   */
  ListCategoriesAndFeedbacks: ListCategoriesAndFeedbacks_ListCategoriesAndFeedbacks[];
}

export interface ListCategoriesAndFeedbacksVariables {
  isWelch?: boolean | null;
  page: FvcPage;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ListFranchisees
// ====================================================

export interface ListFranchisees_ListFranchisees {
  __typename: "FranchiseeResponse";
  /**
   * Código do fraqueado
   */
  code: string;
}

export interface ListFranchisees {
  /**
   * # Query - ListFranchisees
   * ## Description:
   * 
   * 
   *  - Lista todos os franqueados disponíveis no ciclo atual
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## Internal Dependencies:
   * 
   * 
   *  - ### ListFranchiseesUseCase
   *     
   * 
   * 
   *  - ### FranchiseDbDataSource
   */
  ListFranchisees: ListFranchisees_ListFranchisees[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ListGeraStructuresByShop
// ====================================================

export interface ListGeraStructuresByShop_ListGeraStructuresByShop {
  __typename: "GeraStructureResponse";
  /**
   * Código da estrutura comercial
   */
  code: string;
}

export interface ListGeraStructuresByShop {
  /**
   * # Query - ListGeraStructuresByShop
   * ## Description:
   * 
   * 
   *  - Lista todas as estruturas por CS (loja) disponíveis no ciclo atual
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## Internal Dependencies:
   * 
   * 
   *  - ### ListGeraStructuresByShopUseCase
   *     
   * 
   * 
   *  - ### CommercialStructureDbDataSource
   */
  ListGeraStructuresByShop: ListGeraStructuresByShop_ListGeraStructuresByShop[];
}

export interface ListGeraStructuresByShopVariables {
  shopCode: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ListIrCurrentConversation
// ====================================================

export interface ListIrCurrentConversation_ListIrCurrentConversation_conversations {
  __typename: "IrConversationType";
  id: number;
  createdAt: any;
  inConversation: boolean;
  resellerId: string;
  resellerName: string;
  completedAt: any | null;
}

export interface ListIrCurrentConversation_ListIrCurrentConversation {
  __typename: "ListIrCurrentConversationResponse";
  conversations: ListIrCurrentConversation_ListIrCurrentConversation_conversations[];
  count: number;
}

export interface ListIrCurrentConversation {
  ListIrCurrentConversation: ListIrCurrentConversation_ListIrCurrentConversation;
}

export interface ListIrCurrentConversationVariables {
  commercialStructure: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ListNewsClient
// ====================================================

export interface ListNewsClient_ListNewsClient_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface ListNewsClient_ListNewsClient_news {
  __typename: "NewsResponse";
  id: string;
  title: string;
  message: string;
  /**
   * Nível de criticidade
   */
  criticalityLevel: NewsCriticalityLevel;
  /**
   * Projeto pertencente
   */
  environments: NewsEnvironment[];
  /**
   * Indica se a notícia é pública, isto é, pode ser acessada sem fazer login
   */
  isPublic: boolean;
  /**
   * Indica se a notícia está ativada e por isso pode ser visualizada pelos usuários
   */
  isEnable: boolean;
  /**
   * Lista de URLs vinculadas a notícia
   */
  references: string[];
}

export interface ListNewsClient_ListNewsClient {
  __typename: "NewsListResponse";
  pageInfo: ListNewsClient_ListNewsClient_pageInfo;
  news: ListNewsClient_ListNewsClient_news[];
  count: number;
}

export interface ListNewsClient {
  /**
   * # Query - ListNewsClient
   * ## Description:
   * 
   * 
   *  - Obtém lista de notícias
   * ## Access Roles:
   * 
   * 
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## Internal Dependencies:
   * 
   * 
   *  - ### ListNewsUseCase
   *     
   * 
   * 
   *  - ### NewsDataSource
   */
  ListNewsClient: ListNewsClient_ListNewsClient | null;
}

export interface ListNewsClientVariables {
  limit: number;
  offset: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ListPublicMedia
// ====================================================

export interface ListPublicMedia_ListPublicMedia_medias {
  __typename: "ShowPublicMediaResponse";
  id: number;
  /**
   * Nome da mídia
   */
  name: string;
  /**
   * Descrição da mídia
   */
  description: string | null;
  /**
   * ID da mídia
   */
  mediaId: string;
  /**
   * Tipo de arquivo
   */
  type: string;
}

export interface ListPublicMedia_ListPublicMedia {
  __typename: "ListPublicMediaResponse";
  /**
   * Items obtidos
   */
  medias: ListPublicMedia_ListPublicMedia_medias[] | null;
  /**
   * Total de items encontrados
   */
  count: number | null;
}

export interface ListPublicMedia {
  /**
   * # Query - ListPublicMedia
   * ## Description:
   * 
   * 
   *  - Lista as mídias públicas
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## Internal Dependencies:
   * 
   * 
   *  - ### ListPublicMediaUseCase
   *     
   * 
   * 
   *  - ### InstantMessagePublicMediaDbDataSource
   */
  ListPublicMedia: ListPublicMedia_ListPublicMedia;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ListPublicNews
// ====================================================

export interface ListPublicNews_ListPublicNews_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface ListPublicNews_ListPublicNews_news {
  __typename: "NewsResponse";
  id: string;
  title: string;
  message: string;
  /**
   * Nível de criticidade
   */
  criticalityLevel: NewsCriticalityLevel;
  /**
   * Projeto pertencente
   */
  environments: NewsEnvironment[];
  /**
   * Indica se a notícia é pública, isto é, pode ser acessada sem fazer login
   */
  isPublic: boolean;
  /**
   * Indica se a notícia está ativada e por isso pode ser visualizada pelos usuários
   */
  isEnable: boolean;
  /**
   * Lista de URLs vinculadas a notícia
   */
  references: string[];
}

export interface ListPublicNews_ListPublicNews {
  __typename: "NewsListResponse";
  pageInfo: ListPublicNews_ListPublicNews_pageInfo;
  news: ListPublicNews_ListPublicNews_news[];
  count: number;
}

export interface ListPublicNews {
  /**
   * # Query - LisPublictNews
   * ## Description:
   * 
   * 
   *  - Obtém lista de notícias na área deslogada
   * ## Access Roles:
   * 
   * 
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## Internal Dependencies:
   * 
   * 
   *  - ### ListNewsUseCase
   *     
   * 
   * 
   *  - ### NewsDataSource
   */
  ListPublicNews: ListPublicNews_ListPublicNews | null;
}

export interface ListPublicNewsVariables {
  limit?: number | null;
  offset?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ListResellerConversations
// ====================================================

export interface ListResellerConversations_ListResellerConversations_overview {
  __typename: "ResellerConversationsOverviewResponse";
  count: number;
  countActive: number;
  countReceptive: number;
}

export interface ListResellerConversations_ListResellerConversations_conversations_reseller {
  __typename: "ResellerResponse";
  name: string;
  referenceId: string;
}

export interface ListResellerConversations_ListResellerConversations_conversations_supervisor {
  __typename: "SupervisorResponse";
  name: string;
  geraId: string;
}

export interface ListResellerConversations_ListResellerConversations_conversations_feedback {
  __typename: "FeedbackResponse";
  title: string;
}

export interface ListResellerConversations_ListResellerConversations_conversations {
  __typename: "ResellerConversationResponse";
  reseller: ListResellerConversations_ListResellerConversations_conversations_reseller;
  /**
   * Informações da atendente
   */
  supervisor: ListResellerConversations_ListResellerConversations_conversations_supervisor;
  feedback: ListResellerConversations_ListResellerConversations_conversations_feedback | null;
  status: ConversationStatusEnum | null;
  note: string | null;
  createdAt: any;
}

export interface ListResellerConversations_ListResellerConversations {
  __typename: "ResellerConversationsListResponse";
  overview: ListResellerConversations_ListResellerConversations_overview;
  conversations: ListResellerConversations_ListResellerConversations_conversations[];
}

export interface ListResellerConversations {
  /**
   * # Query - ListResellerConversations
   * ## Description:
   * 
   * 
   *  - Lista os atendimentos de uma supervisora
   * ## Access Roles:
   * 
   * 
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## Internal Dependencies:
   * 
   * 
   *  - ### ListResellerConversationsUseCase
   *     
   * 
   * 
   *  - ### ResellerConversationDataSource
   */
  ListResellerConversations: ListResellerConversations_ListResellerConversations | null;
}

export interface ListResellerConversationsVariables {
  date: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetScriptClient
// ====================================================

export interface GetScriptClient_GetScriptClient {
  __typename: "ScriptStepResponse";
  id: string;
  step: string;
  instruction: string;
}

export interface GetScriptClient {
  /**
   * # Query - GetScriptClient
   * ## Description:
   * 
   * 
   *  - Obtém script completo
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## Internal Dependencies:
   * 
   * 
   *  - ### GetScriptUseCase
   *     
   * 
   * 
   *  - ### ScriptDataSource
   */
  GetScriptClient: GetScriptClient_GetScriptClient[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ListSectorByChannel
// ====================================================

export interface ListSectorByChannel_ListSectorByChannel {
  __typename: "SectorResponse";
  /**
   * Código do setor
   */
  code: number;
  description: string;
  /**
   * Código do canal relacionado
   */
  channelCode: number;
}

export interface ListSectorByChannel {
  /**
   * Lista todos os setores por canal
   */
  ListSectorByChannel: ListSectorByChannel_ListSectorByChannel[];
}

export interface ListSectorByChannelVariables {
  channelCode: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ListShopsByFranchisee
// ====================================================

export interface ListShopsByFranchisee_ListShopsByFranchisee {
  __typename: "StoreResponse";
  /**
   * Código da loja
   */
  code: string;
}

export interface ListShopsByFranchisee {
  /**
   * # Query - ListShopsByFranchisee
   * ## Description:
   * 
   * 
   *  - Lista todas as lojas por CP (franqueado) disponíveis no ciclo atual
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## Internal Dependencies:
   * 
   * 
   *  - ### ListShopsByFranchiseeUseCase
   *     
   * 
   * 
   *  - ### StoreDbDataSource
   */
  ListShopsByFranchisee: ListShopsByFranchisee_ListShopsByFranchisee[];
}

export interface ListShopsByFranchiseeVariables {
  franchiseeCode: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ListStructureBySector
// ====================================================

export interface ListStructureBySector_ListStructureBySector {
  __typename: "StructureResponse";
  /**
   * Código da estrutura comercial
   */
  code: number;
  description: string;
  /**
   * Código do setor da estrutura
   */
  sectorCode: number;
}

export interface ListStructureBySector {
  /**
   * Lista todas as estruturas por setor
   */
  ListStructureBySector: ListStructureBySector_ListStructureBySector[];
}

export interface ListStructureBySectorVariables {
  sectorCode: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ListMessageTemplates
// ====================================================

export interface ListMessageTemplates_ListMessageTemplates_templates_message {
  __typename: "TemplateMessage";
  /**
   * Texto da mensagem do header
   */
  header: string | null;
  /**
   * Texto da mensagem do body
   */
  body: string;
  /**
   * Texto da mensagem do footer
   */
  footer: string | null;
  /**
   * Texto da mensagens dos botões
   */
  buttons: string[] | null;
}

export interface ListMessageTemplates_ListMessageTemplates_templates_messageVariables_header {
  __typename: "Variables";
  /**
   * Identificador/número da variável
   */
  key: string | null;
  /**
   * Índice do botão
   */
  index: number | null;
  /**
   * Valor padrão da variável
   */
  defaultValue: string | null;
  /**
   * Link da media a ser incluida na mensagem
   */
  link: string | null;
  /**
   * Identificador da media a ser incluida na mensagem
   */
  mediaId: string | null;
  /**
   * Tipo da variável
   */
  type: string | null;
  /**
   * Flag que indica se o valor padrão pode ser alterado
   */
  isEditable: boolean;
}

export interface ListMessageTemplates_ListMessageTemplates_templates_messageVariables_body {
  __typename: "Variables";
  /**
   * Identificador/número da variável
   */
  key: string | null;
  /**
   * Índice do botão
   */
  index: number | null;
  /**
   * Valor padrão da variável
   */
  defaultValue: string | null;
  /**
   * Link da media a ser incluida na mensagem
   */
  link: string | null;
  /**
   * Identificador da media a ser incluida na mensagem
   */
  mediaId: string | null;
  /**
   * Tipo da variável
   */
  type: string | null;
  /**
   * Flag que indica se o valor padrão pode ser alterado
   */
  isEditable: boolean;
}

export interface ListMessageTemplates_ListMessageTemplates_templates_messageVariables_buttons {
  __typename: "Variables";
  /**
   * Identificador/número da variável
   */
  key: string | null;
  /**
   * Índice do botão
   */
  index: number | null;
  /**
   * Valor padrão da variável
   */
  defaultValue: string | null;
  /**
   * Link da media a ser incluida na mensagem
   */
  link: string | null;
  /**
   * Identificador da media a ser incluida na mensagem
   */
  mediaId: string | null;
  /**
   * Tipo da variável
   */
  type: string | null;
  /**
   * Flag que indica se o valor padrão pode ser alterado
   */
  isEditable: boolean;
}

export interface ListMessageTemplates_ListMessageTemplates_templates_messageVariables {
  __typename: "TemplateVariables";
  /**
   * Variáveis do header
   */
  header: ListMessageTemplates_ListMessageTemplates_templates_messageVariables_header[] | null;
  /**
   * Variáveis do body
   */
  body: ListMessageTemplates_ListMessageTemplates_templates_messageVariables_body[] | null;
  /**
   * Variáveis do botão
   */
  buttons: ListMessageTemplates_ListMessageTemplates_templates_messageVariables_buttons[] | null;
}

export interface ListMessageTemplates_ListMessageTemplates_templates {
  __typename: "MessageTemplateBaseResponse";
  /**
   * Id do template de mensagem
   */
  id: number | null;
  /**
   * Nome oficial do template de mensagem
   */
  name: string;
  /**
   * Nome amigável do template de mensagem que será exibido
   */
  friendlyName: string;
  /**
   * Mensagem do template
   */
  message: ListMessageTemplates_ListMessageTemplates_templates_message;
  /**
   * Variáveis do template de mensagem
   */
  messageVariables: ListMessageTemplates_ListMessageTemplates_templates_messageVariables | null;
  /**
   * Ambiente que o template está disponível
   */
  environment: TemplateEnvironment;
}

export interface ListMessageTemplates_ListMessageTemplates_pageInfo {
  __typename: "AdminPageInfoResponse";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface ListMessageTemplates_ListMessageTemplates {
  __typename: "ListMessageTemplatesResponse";
  count: number;
  templates: ListMessageTemplates_ListMessageTemplates_templates[];
  pageInfo: ListMessageTemplates_ListMessageTemplates_pageInfo;
}

export interface ListMessageTemplates {
  /**
   * # Query - ListMessageTemplates
   * ## Description:
   * 
   * 
   *  - Lista os templates de whatsapp disponíveis
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## Internal Dependencies:
   * 
   * 
   *  - ### ListMessageTemplatesUseCase
   *     
   * 
   * 
   *  - ### InstantMessageTemplateDbDatasource
   */
  ListMessageTemplates: ListMessageTemplates_ListMessageTemplates;
}

export interface ListMessageTemplatesVariables {
  offset?: number | null;
  limit?: number | null;
  environment?: TemplateEnvironment | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MCBBenefits
// ====================================================

export interface MCBBenefits_MeuClubeBotiCategoryDescription_benefits {
  __typename: "MeuClubeBotiBenefitType";
  /**
   * Título do benefício
   */
  title: string | null;
  /**
   * Informações adicionais do benefício
   */
  description: string | null;
  /**
   * Ícone do benefício
   */
  iconUrl: string | null;
}

export interface MCBBenefits_MeuClubeBotiCategoryDescription {
  __typename: "MeuClubeBotiCategoryDescriptionResponse";
  /**
   * Lista de benefícios de uma categoria MCB
   */
  benefits: MCBBenefits_MeuClubeBotiCategoryDescription_benefits[] | null;
}

export interface MCBBenefits {
  /**
   * # Query - MeuClubeBotiCategoryDescription
   * ## Description:
   * 
   * 
   *  - Detalha uma categoria do Meu Clube Boti
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## Internal Dependencies:
   * 
   * 
   *  - ### MeuClubeBotiCategoryDescriptionUseCase
   */
  MeuClubeBotiCategoryDescription: MCBBenefits_MeuClubeBotiCategoryDescription | null;
}

export interface MCBBenefitsVariables {
  category: ResellerMeuClubeBotiEnum;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MediaDownloadUrl
// ====================================================

export interface MediaDownloadUrl_MediaDownloadUrl {
  __typename: "MediaDownloadUrlResponse";
  /**
   * URL de upload de mídia do Instant Message
   */
  url: string;
}

export interface MediaDownloadUrl {
  /**
   * # Query - MediaDownloadUrl
   * ## Description:
   * 
   * 
   *  - Traz a url de uma media com determinado id
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## External Dependencies:
   * 
   * 
   *  - ### InstantMessageHttpDatasource
   *     
   * 
   * 	
   * 	 - Endpoint Path: /medias/${id}/
   * ## Internal Dependencies:
   * 
   * 
   *  - ### GetMediaUrlUseCase
   *     
   * 
   * 
   *  - ### FvcStructureDbDataSource
   */
  MediaDownloadUrl: MediaDownloadUrl_MediaDownloadUrl;
}

export interface MediaDownloadUrlVariables {
  data: MediaDownloadUrlInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MediaUploadUrl
// ====================================================

export interface MediaUploadUrl_MediaUploadUrl {
  __typename: "MediaUploadUrlResponse";
  /**
   * ID da mídia do Instant Message
   */
  id: string;
  /**
   * URL de upload de mídia do Instant Message
   */
  url: string;
}

export interface MediaUploadUrl {
  /**
   * # Query - MediaUploadUrl
   * ## Description:
   * 
   * 
   *  - Traz a url para upload de uma mídia e seu id
   * ## Access Roles:
   * 
   * 
   *  - admin
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## External Dependencies:
   * 
   * 
   *  - ### InstantMessageHttpDatasource
   *     
   * 
   * 	
   * 	 - Endpoint Path: /medias/async
   * ## Internal Dependencies:
   * 
   * 
   *  - ### UploadMediaUseCase
   *     
   * 
   * 
   *  - ### FvcStructureDbDataSource
   */
  MediaUploadUrl: MediaUploadUrl_MediaUploadUrl;
}

export interface MediaUploadUrlVariables {
  data: MediaUploadUrlInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Messages
// ====================================================

export interface Messages_Messages_messages {
  __typename: "MessageResponse";
  /**
   * Identificador da mensagem
   */
  id: string;
  /**
   * Telefone remetente
   */
  from: string;
  /**
   * Telefone destino
   */
  to: string;
  /**
   * Tipo da mensagem
   */
  type: MessageType | null;
  /**
   * Conteúdo da mensagem
   */
  message: string | null;
  /**
   * URL da imagem/áudio
   */
  mediaUrl: string | null;
  /**
   * Status
   */
  status: MessageStatus;
  /**
   * Data de envio (UTC)
   */
  sentAt: any | null;
}

export interface Messages_Messages_pagination {
  __typename: "InstantMessagesPaginationType";
  /**
   * Total de mensagens
   */
  totalItems: number;
  /**
   * Total de páginas
   */
  pages: number;
  /**
   * Página atual
   */
  currentPage: number;
  /**
   * Mensagens por página
   */
  itemsOnPage: number;
}

export interface Messages_Messages {
  __typename: "MessagesResponse";
  /**
   * Indica se o atendimento está na janela de 24 horas
   */
  isMinorThan24Hours: boolean;
  /**
   * Telefone da revendedora. Formato: +5511999999999
   */
  resellerCellphone: string;
  /**
   * Nome da revendedora
   */
  resellerName: string;
  /**
   * Mensagem trocada em chat (WhatsApp)
   */
  messages: Messages_Messages_messages[];
  /**
   * Detalhes de paginação de mensagens
   */
  pagination: Messages_Messages_pagination;
}

export interface Messages {
  /**
   * # Query - Messages
   * ## Description:
   * 
   * 
   *  - Lista mensagens para um telefone (paginada)
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## External Dependencies:
   * 
   * 
   *  - ### InstantMessageHttpDatasource
   *     
   * 
   * 	
   * 	 - Endpoint Path: {{instantMessageUrl}}/messages/
   * ## Internal Dependencies:
   * 
   * 
   *  - ### ListMessagesUseCase
   *     
   * 
   * 
   *  - ### ResellerMasterDataMetricDbDataSource
   *     
   * 
   * 
   *  - ### FvcStructureDbDataSource
   *     
   * 
   * 
   *  - ### SafeGetMediaUrlUseCase
   */
  Messages: Messages_Messages | null;
}

export interface MessagesVariables {
  itemsOnPage?: number | null;
  page: number;
  resellerId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProductSearch
// ====================================================

export interface ProductSearch_ProductSearch {
  __typename: "ProductResponse";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Url da imagem (tamanho P)
   */
  imageUrl: string;
  /**
   * Preço de venda de (tabela)
   */
  marketValue: number | null;
  /**
   * Preço de venda (promocionado, se for o caso)
   */
  netValue: number | null;
  /**
   * Preço de compra da revendedora
   */
  resellerValue: number | null;
}

export interface ProductSearch {
  /**
   * # Query - ProductSearch
   * ## Description:
   * 
   * 
   *  - Dados de contato de uma revendedora
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## External Dependencies:
   * 
   * 
   *  - ### ProductSearchHttpDatasource
   *     
   * 
   * 	
   * 	 - Endpoint Path: /grb/sb/produtos/pesquisa
   * ## Internal Dependencies:
   * 
   * 
   *  - ### ProductSearchUseCase
   */
  ProductSearch: ProductSearch_ProductSearch[];
}

export interface ProductSearchVariables {
  data: ProductSearchInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DistributionCenterAddress
// ====================================================

export interface DistributionCenterAddress_DistributionCenterAddress {
  __typename: "DistributionCenterAddressResponse";
  /**
   * ID do canal de distribuição
   */
  id: string | null;
  /**
   * Tipo da entrega
   */
  deliveryType: string | null;
}

export interface DistributionCenterAddress {
  /**
   * # Query - DistributionCenterAddress
   * ## Description:
   * 
   * 
   *  - Traz informações dos canais de distribuição da revendedora
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## External Dependencies:
   * 
   * 
   *  - ### DistributionCenterAddressDataSource
   *     
   * 
   * 
   *  - ### ResellerHttpDataSource
   *     
   * 
   * 	
   * 	 - Endpoint Path: /grb/sb/revendedoras/${resellerId}/centraisDistribuicao
   * ## Internal Dependencies:
   * 
   * 
   *  - ### DistributionCenterAddressUseCase
   */
  DistributionCenterAddress: DistributionCenterAddress_DistributionCenterAddress[] | null;
}

export interface DistributionCenterAddressVariables {
  resellerId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Promotions
// ====================================================

export interface Promotions_FvcSuperplanoCards_nodes_shareInfo {
  __typename: "SuperplanoShareInfoType";
  /**
   * Texto da promoção
   */
  text: string | null;
  /**
   * Imagem da promoção
   */
  image: string;
}

export interface Promotions_FvcSuperplanoCards_nodes {
  __typename: "SuperplanoCardResponse";
  /**
   * Código da promoção
   */
  code: string;
  /**
   * Tipo da promoção
   */
  type: SuperplanoCardTypeEnum;
  /**
   * Título da promoção
   */
  title: string;
  /**
   * Tipo da descrição da promoção
   */
  descriptionType: SuperplanoDescriptionType;
  /**
   * Descrição da promoção
   */
  description: string;
  /**
   * Imagem da promoção
   */
  image: string | null;
  /**
   * Data de início da promoção
   */
  startDate: any;
  /**
   * Data de fim da promoção
   */
  endDate: any;
  /**
   * Informações do compartilhamento da promoção
   */
  shareInfo: Promotions_FvcSuperplanoCards_nodes_shareInfo | null;
  /**
   * Hora de atualização
   */
  updatedAt: any;
  /**
   * Hora de criação
   */
  createdAt: any;
}

export interface Promotions_FvcSuperplanoCards_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface Promotions_FvcSuperplanoCards {
  __typename: "SuperplanoCardPaginatedResponse";
  /**
   * Total number of elements
   */
  count: number;
  /**
   * Array of SuperplanoCardResponse
   */
  nodes: Promotions_FvcSuperplanoCards_nodes[];
  /**
   * Page information
   */
  pageInfo: Promotions_FvcSuperplanoCards_pageInfo;
}

export interface Promotions {
  /**
   * # Query - FvcSuperplanoCards
   * ## Description:
   * 
   * 
   *  - Traz as informações dos cards que aparecem na tab do superplano
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## External Dependencies:
   * 
   * 
   *  - ### FranchiseHttpDataSource
   *     
   * 
   * 	
   * 	 - Endpoint Path: /grb/sb/franquia/franquias/${storeCode.padStart(10, 0)}
   * ## Internal Dependencies:
   * 
   * 
   *  - ### FvcListSuperplanoCardsUseCase
   *     
   * 
   * 
   *  - ### ListAllExclusiveSuperplanoCardsUseCase
   *     
   * 
   * 
   *  - ### ListExclusiveSuperplanoCardsUseCase
   *     
   * 
   * 
   *  - ### ListGeneralSuperplanoCardsUseCase
   *     
   * 
   * 
   *  - ### SuperplanoDbDataSource
   *     
   * 
   * 
   *  - ### ResellerDbDataSource
   *     
   * 
   * 
   *  - ### ResellerCeasedDbDatasource
   *     
   * 
   * 
   *  - ### SupervisorRankingMetricDbDataSource
   */
  FvcSuperplanoCards: Promotions_FvcSuperplanoCards;
}

export interface PromotionsVariables {
  id: string;
  offset: number;
  limit: number;
  cardType?: SuperplanoCardTypeEnum | null;
  onlyExclusives?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ResaleIntentionInteractions
// ====================================================

export interface ResaleIntentionInteractions_ResaleIntentionInteractions_lastMessage {
  __typename: "MessageResponse";
  /**
   * Identificador da mensagem
   */
  id: string;
  /**
   * Telefone remetente
   */
  from: string;
  /**
   * Telefone destino
   */
  to: string;
  /**
   * Tipo da mensagem
   */
  type: MessageType | null;
  /**
   * Conteúdo da mensagem
   */
  message: string | null;
  /**
   * URL da imagem/áudio
   */
  mediaUrl: string | null;
  /**
   * Status
   */
  status: MessageStatus;
  /**
   * Data de envio (UTC)
   */
  sentAt: any | null;
}

export interface ResaleIntentionInteractions_ResaleIntentionInteractions {
  __typename: "ResellerInteractionResponse";
  /**
   * ID da revendedora (se ela foi identificada)
   */
  resellerId: string | null;
  /**
   * Nome da revendedora (se ela foi identificada)
   */
  name: string | null;
  /**
   * Telefone da revendedora
   */
  cellphone: string;
  /**
   * Status do atendimento via WhatsApp
   */
  status: ResellerInteractionStatus;
  /**
   * Indica se está dentro da janela de 24 horas
   */
  isMinorThan24Hours: boolean;
  /**
   * Data da última mensagem enviada pela revendedora (UTC)
   */
  lastResellerMessageAt: any;
  /**
   * Última mensagem trocada
   */
  lastMessage: ResaleIntentionInteractions_ResaleIntentionInteractions_lastMessage;
}

export interface ResaleIntentionInteractions {
  /**
   * # Query - ResaleIntentionInteractions
   * ## Description:
   * 
   * 
   *  - Lista as interações com intenções de revendas (Whatsapp)
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## Internal Dependencies:
   * 
   * 
   *  - ### ListResaleIntentionInteractionsUseCase
   *     
   * 
   * 
   *  - ### FVCResellerInstantMessageDatasource
   *     
   * 
   * 
   *  - ### FvcStructureDbDataSource
   */
  ResaleIntentionInteractions: ResaleIntentionInteractions_ResaleIntentionInteractions[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ResellerActivationCommunication
// ====================================================

export interface ResellerActivationCommunication_ResellerActivationCommunication {
  __typename: "ResellerActivationCommunicationResponse";
  /**
   * Código Representante
   */
  id: string;
  /**
   * Campanha especifica para a representante 
   */
  campaignFocus: string | null;
  /**
   * Informações adicionais diversas 
   */
  additionalInfo: string | null;
  /**
   * Leitura rápida dos principais argumentos de venda em formato de frase. 
   */
  theme: string | null;
  /**
   * Prioridade do parcelamento
   */
  priority: string | null;
  /**
   * Flag se permite parcelamento
   */
  areInstallmentsEnabled: boolean | null;
  /**
   * Flag se Representante está negociando dívida
   */
  isNegotiating: boolean | null;
  /**
   * Método de pagamento habilitado
   */
  paymentPlan: string | null;
}

export interface ResellerActivationCommunication {
  /**
   * Plano de comunicação com a representante
   */
  ResellerActivationCommunication: ResellerActivationCommunication_ResellerActivationCommunication;
}

export interface ResellerActivationCommunicationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ResellerAddress
// ====================================================

export interface ResellerAddress_ResellerRegistrationInfo_addresses_info {
  __typename: "AddressInfoType";
  addressNumber: string | null;
  cep: string | null;
  city: string | null;
  complement: string | null;
  neighborhood: string | null;
  state: string | null;
  streetName: string | null;
  landmark: string | null;
}

export interface ResellerAddress_ResellerRegistrationInfo_addresses {
  __typename: "AddressType";
  /**
   * Tipo de endereço
   */
  type: RegistrationInfoAddressType | null;
  /**
   * Informações do endereço
   */
  info: ResellerAddress_ResellerRegistrationInfo_addresses_info | null;
}

export interface ResellerAddress_ResellerRegistrationInfo {
  __typename: "ResellerRegistrationInfoResponse";
  resellerId: string | null;
  /**
   * Endereços
   */
  addresses: ResellerAddress_ResellerRegistrationInfo_addresses[] | null;
}

export interface ResellerAddress {
  /**
   * # Query - ResellerRegistrationInfo
   * ## Description:
   * 
   * 
   *  - Traz as informações cadastrais do(a) revendedor(a) armazenadas no gera
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## External Dependencies:
   * 
   * 
   *  - ### ResellerHttpDataSource
   *     
   * 
   * 	
   * 	 - Endpoint Path:  /grb/sb/revendedoras/${resellerId}/cadastro
   * ## Internal Dependencies:
   * 
   * 
   *  - ### ResellerRegistrationInfoUseCase
   *     
   * 
   * 
   *  - ### ResellerRegistrationInfoRepository
   *     
   * 
   * 
   *  - ### SupervisorRankingMetricDbDataSource
   */
  ResellerRegistrationInfo: ResellerAddress_ResellerRegistrationInfo;
}

export interface ResellerAddressVariables {
  resellerId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MeuClubeBoti
// ====================================================

export interface MeuClubeBoti_MeuClubeBoti_currentCategory {
  __typename: "CurrentCategoryType";
  /**
   * Nome da categoria
   */
  name: string | null;
  /**
   * URL do ícone da categoria
   */
  imageUrl: string | null;
}

export interface MeuClubeBoti_MeuClubeBoti_nextCategory {
  __typename: "NextCategoryType";
  /**
   * Nome da categoria
   */
  name: string | null;
  /**
   * URL do ícone da categoria
   */
  imageUrl: string | null;
  /**
   * Valor mínimo a ser atingido para avançar uma categoria
   */
  minimumValue: number | null;
}

export interface MeuClubeBoti_MeuClubeBoti {
  __typename: "MeuClubeBotiResponse";
  /**
   * Valor acumulado
   */
  accumulatedValue: number | null;
  /**
   * Categoria atual da revendedora
   */
  currentCategory: MeuClubeBoti_MeuClubeBoti_currentCategory | null;
  /**
   * Próxima categoria a ser alcançada
   */
  nextCategory: MeuClubeBoti_MeuClubeBoti_nextCategory | null;
}

export interface MeuClubeBoti {
  /**
   * # Query - MeuClubeBoti
   * ## Description:
   * 
   * 
   *  - Informações da categoria da revendedora no programa Meu Clube Boti
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## External Dependencies:
   * 
   * 
   *  - ### ResellerHttpDataSource
   *     
   * 
   * 	
   * 	 - Endpoint Path: /grb/sb/revendedoras/${resellerId}/perfil
   * ## Internal Dependencies:
   * 
   * 
   *  - ### MeuClubeBotiUseCase
   *     
   * 
   * 
   *  - ### MeuClubeBotiDataSource
   *     
   * 
   * 
   *  - ### ResellerMasterDataMetricDbDataSource
   */
  MeuClubeBoti: MeuClubeBoti_MeuClubeBoti;
}

export interface MeuClubeBotiVariables {
  resellerId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ResellerCommercialStructure
// ====================================================

export interface ResellerCommercialStructure_ResellerCommercialStructure {
  __typename: "ResellerCommercialStructureResponse";
  /**
   * Código Representante
   */
  resellerId: string;
  /**
   * Código do setor
   */
  sectorId: string | null;
  /**
   * Nome do setor
   */
  sectorName: string | null;
  /**
   * Código do subsetor
   */
  subSectorId: string | null;
  /**
   * Nome do subsetor
   */
  subSectorName: string | null;
  /**
   * Responsável pelo subsetor
   */
  subSectorResponsible: string | null;
}

export interface ResellerCommercialStructure {
  /**
   * Informações de estrutura comercial de uma revendedora
   */
  ResellerCommercialStructure: ResellerCommercialStructure_ResellerCommercialStructure;
}

export interface ResellerCommercialStructureVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ResellerContactInfo
// ====================================================

export interface ResellerContactInfo_ResellerContactInfo {
  __typename: "ResellerContactInfoResponse";
  /**
   * Endereço da revendedora formatado cadastrado no "Gera"
   */
  address: string | null;
  /**
   * Outros telefones de contatos, incluindo os já retornados nos outros campos
   */
  allContacts: string[] | null;
}

export interface ResellerContactInfo {
  /**
   * # Query - ResellerContactInfo
   * ## Description:
   * 
   * 
   *  - Traz os dados de contato de uma revendedora
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## External Dependencies:
   * 
   * 
   *  - ### ResellerHttpDataSource
   *     
   * 
   * 	
   * 	 - Endpoint Path: /grb/sb/revendedoras/${resellerId}/cadastro
   * ## Internal Dependencies:
   * 
   * 
   *  - ### ResellerContactInfoUseCase
   *     
   * 
   * 
   *  - ### ResellerMasterDataMetricDbDataSource
   */
  ResellerContactInfo: ResellerContactInfo_ResellerContactInfo | null;
}

export interface ResellerContactInfoVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ResellerConversationsOverview
// ====================================================

export interface ResellerConversationsOverview_ResellerConversationsOverview {
  __typename: "ResellerConversationsOverviewResponse";
  count: number;
  countActive: number;
  countReceptive: number;
}

export interface ResellerConversationsOverview {
  /**
   * # Query - ResellerConversationsOverview
   * ## Description:
   * 
   * 
   *  - Contagem de atendimentos em um determinado período.
   * ## Access Roles:
   * 
   * 
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## Internal Dependencies:
   * 
   * 
   *  - ### ResellerConversationsOverviewUseCase
   *     
   * 
   * 
   *  - ### ResellerConversationDataSource
   */
  ResellerConversationsOverview: ResellerConversationsOverview_ResellerConversationsOverview;
}

export interface ResellerConversationsOverviewVariables {
  period: Period;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ResellerCreditIncrease
// ====================================================

export interface ResellerCreditIncrease_ResellerCreditIncrease_nodes {
  __typename: "ResellerCreditIncreaseType";
  /**
   * Id único da entidade (código da revendedora + número do incremento)
   */
  id: string;
  /**
   * Código da revendedora
   */
  resellerId: string;
  /**
   * Número do acréscimo de crédito
   */
  increaseNumber: number;
  /**
   * Limite de crédito
   */
  creditLimit: number | null;
  /**
   * Código do ciclo do incremento
   */
  cycle: string | null;
  /**
   * Data
   */
  increaseDate: any | null;
}

export interface ResellerCreditIncrease_ResellerCreditIncrease_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface ResellerCreditIncrease_ResellerCreditIncrease {
  __typename: "ResellerCreditIncreaseResponse";
  /**
   * Total number of elements
   */
  count: number;
  /**
   * Array of ResellerCreditIncreaseType
   */
  nodes: ResellerCreditIncrease_ResellerCreditIncrease_nodes[];
  /**
   * Page information
   */
  pageInfo: ResellerCreditIncrease_ResellerCreditIncrease_pageInfo;
}

export interface ResellerCreditIncrease {
  /**
   * Histórico de majoração de crédito da revendedora
   */
  ResellerCreditIncrease: ResellerCreditIncrease_ResellerCreditIncrease;
}

export interface ResellerCreditIncreaseVariables {
  limit?: number | null;
  offset?: number | null;
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ResellerCredit
// ====================================================

export interface ResellerCredit_ResellerCredit {
  __typename: "ResellerCreditResponse";
  /**
   * Crédito total para compra no momento
   */
  total: number | null;
  /**
   * Crédito disponível para compra no momento
   */
  available: number | null;
}

export interface ResellerCredit {
  /**
   * # Query - ResellerCredit
   * ## Description:
   * 
   * 
   *  - Traz os dados de crédito de uma revendedora
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## External Dependencies:
   * 
   * 
   *  - ### ResellerHttpDataSource
   *     
   * 
   * 	
   * 	 - Endpoint Path: /grb/sb/revendedoras/${resellerId}/creditos
   * ## Internal Dependencies:
   * 
   * 
   *  - ### ResellerCreditUseCase
   *     
   * 
   * 
   *  - ### ResellerCreditMetricDbDataSource
   *     
   * 
   * 
   *  - ### ResellerMasterDataMetricDbDataSource
   */
  ResellerCredit: ResellerCredit_ResellerCredit;
}

export interface ResellerCreditVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ResellerCRM
// ====================================================

export interface ResellerCRM_ResellerCRM {
  __typename: "ResellerCRMResponse";
  /**
   * Código da revendedora
   */
  id: string;
  /**
   * Prioridade da RE
   */
  priority: number | null;
  /**
   * Objetivo da RE
   */
  goal: string | null;
  /**
   * Valor atual no boleto
   */
  currentBoleto: number | null;
  /**
   * Potencial de compra no boleto
   */
  potentialBoleto: number | null;
  /**
   * Valor atual nas guias
   */
  currentGuideValue: number | null;
  /**
   * Valor atual da faixa
   */
  fxEpmCurrentCycle: string | null;
  /**
   * Tag de CRM
   */
  crmTag: string | null;
  /**
   * Valor para próxima faixa do Eu Posso Mais
   */
  nextEpmValue: number | null;
  /**
   * Top Categoria 1
   */
  topCategory1: string | null;
  /**
   * Top Categoria 2
   */
  topCategory2: string | null;
}

export interface ResellerCRM {
  /**
   * Informações de CRM da revendedora
   */
  ResellerCRM: ResellerCRM_ResellerCRM;
}

export interface ResellerCRMVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ResellerCurrentOrderCycles
// ====================================================

export interface ResellerCurrentOrderCycles_ResellerCurrentOrderCycles_currentCycleInfo_subCycles {
  __typename: "SubCycleType";
  /**
   * Id do subciclo
   */
  id: string;
  /**
   * Flag de subciclo atual
   */
  isActive: boolean;
  /**
   * Inicio do subciclo
   */
  start: any;
  /**
   * Fim do subciclo
   */
  end: any;
}

export interface ResellerCurrentOrderCycles_ResellerCurrentOrderCycles_currentCycleInfo {
  __typename: "CycleResponse";
  /**
   * Id do ciclo selecionado atualmente
   */
  id: string;
  /**
   * Id da estrutura comercial do ciclo
   */
  commercialStructure: string | null;
  /**
   * Data de início do ciclo selecionado atualmente
   */
  start: any;
  /**
   * Data de fim do ciclo selecionado atualmente
   */
  end: any;
  /**
   * Subciclos
   */
  subCycles: ResellerCurrentOrderCycles_ResellerCurrentOrderCycles_currentCycleInfo_subCycles[];
}

export interface ResellerCurrentOrderCycles_ResellerCurrentOrderCycles_previousCycleInfo_subCycles {
  __typename: "SubCycleType";
  /**
   * Id do subciclo
   */
  id: string;
  /**
   * Flag de subciclo atual
   */
  isActive: boolean;
  /**
   * Inicio do subciclo
   */
  start: any;
  /**
   * Fim do subciclo
   */
  end: any;
}

export interface ResellerCurrentOrderCycles_ResellerCurrentOrderCycles_previousCycleInfo {
  __typename: "CycleResponse";
  /**
   * Id do ciclo selecionado atualmente
   */
  id: string;
  /**
   * Id da estrutura comercial do ciclo
   */
  commercialStructure: string | null;
  /**
   * Data de início do ciclo selecionado atualmente
   */
  start: any;
  /**
   * Data de fim do ciclo selecionado atualmente
   */
  end: any;
  /**
   * Subciclos
   */
  subCycles: ResellerCurrentOrderCycles_ResellerCurrentOrderCycles_previousCycleInfo_subCycles[];
}

export interface ResellerCurrentOrderCycles_ResellerCurrentOrderCycles {
  __typename: "OrderCyclesResponse";
  /**
   * Ciclo anterior (deve ser permitido usar)
   */
  previousCycle: string | null;
  /**
   * Ciclos atual
   */
  currentCycle: string;
  /**
   * Indica se é período de ciclo estendido
   */
  isExtendedCyclePeriod: boolean;
  /**
   * Indica se a revendedora pode fazer pedido no ciclo anterior
   */
  isAllowedToOrderOnPreviousCycle: boolean;
  /**
   * Informações adicionais do ciclo
   */
  currentCycleInfo: ResellerCurrentOrderCycles_ResellerCurrentOrderCycles_currentCycleInfo | null;
  /**
   * Informações adicionais do ciclo
   */
  previousCycleInfo: ResellerCurrentOrderCycles_ResellerCurrentOrderCycles_previousCycleInfo | null;
}

export interface ResellerCurrentOrderCycles {
  /**
   * # Query - ResellerCurrentOrderCycles
   * ## Description:
   * 
   * 
   *  - Traz os ciclos disponíveis para o pedido da revendedora
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## External Dependencies:
   * 
   * 
   *  - ### InitialParamsHttpDatasource
   *     
   * 
   * 
   *  - ### BoticarioBaseCartHttpDatasource
   *     
   * 
   * 	
   * 	 - Endpoint Path: /cart/initialparams
   * 
   * 
   *  - ### CycleHttpDataSource
   *     
   * 
   * 	
   * 	 - Endpoint Path: /grb/sb/revendedoras/ciclosSellOut
   * 
   * 
   *  - ### ResellerHttpDataSource
   *     
   * 
   * 	
   * 	 - Endpoint Path: /grb/sb/revendedoras/${resellerId}/cadastro
   * ## Internal Dependencies:
   * 
   * 
   *  - ### ResellerCurrentOrderCycleUseCase
   */
  ResellerCurrentOrderCycles: ResellerCurrentOrderCycles_ResellerCurrentOrderCycles;
}

export interface ResellerCurrentOrderCyclesVariables {
  resellerId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ResellerCurrentOrderDeliveryModes
// ====================================================

export interface ResellerCurrentOrderDeliveryModes_ResellerCurrentOrderDeliveryModes {
  __typename: "ResellerCurrentOrderDeliveryModeResponse";
  /**
   * Indica se o endereço se trata de um espaço revendedor
   */
  isWithdrawalCenter: boolean;
  /**
   * Identificador do espaço revendedor
   */
  distributionCenterCode: string;
  /**
   * Nome do espaço revendedor
   */
  distributionCenterName: string;
  /**
   * Endereço (de entrega ou do espaço revendedor)
   */
  descriptiveAddress: string;
}

export interface ResellerCurrentOrderDeliveryModes {
  /**
   * Lista os modos de entrega para um pedido da revendedora
   */
  ResellerCurrentOrderDeliveryModes: ResellerCurrentOrderDeliveryModes_ResellerCurrentOrderDeliveryModes[];
}

export interface ResellerCurrentOrderDeliveryModesVariables {
  resellerId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ResellerCurrentOrder
// ====================================================

export interface ResellerCurrentOrder_ResellerCurrentOrder {
  __typename: "CurrentOrderResponse";
  /**
   * Id do pedido
   */
  id: string;
  /**
   * Id da revendedora
   */
  resellerId: string;
  /**
   * Nome da revendedora
   */
  resellerName: string;
  /**
   * Identificador do ciclo para o qual o pedido se refere
   */
  cycle: string;
  /**
   * Identificador do ciclo para o qual o pedido se refere
   */
  subcycle: string | null;
  /**
   * Código do centro de distribuição
   */
  distributionCenterCode: string;
  /**
   * Indica se é para retirar na CS
   */
  pickupOnDistributionCenter: boolean;
  /**
   * Data de criação do pedido (UTC)
   */
  createdAt: any;
  /**
   * Data de expiração do pedido (UTC)
   */
  expiration: any;
}

export interface ResellerCurrentOrder {
  /**
   * # Query - ResellerCurrentOrder
   * ## Description:
   * 
   * 
   *  - Lista os pedidos em andamento para dada revendedora
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## Internal Dependencies:
   * 
   * 
   *  - ### ResellerCurrentOrderUseCase
   *     
   * 
   * 
   *  - ### ResellerCurrentOrderDbDatasource
   */
  ResellerCurrentOrder: ResellerCurrentOrder_ResellerCurrentOrder;
}

export interface ResellerCurrentOrderVariables {
  resellerId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ResellerDetailByDocument
// ====================================================

export interface ResellerDetailByDocument_ResellerDetailByDocument_commercialStructure {
  __typename: "ResellerCommercialStructureType";
  /**
   * Identificador da estrutura comercial
   */
  referenceId: string | null;
  /**
   * Nome do franqueado
   */
  franchiseName: string | null;
  /**
   * Nome da central de serviço
   */
  serviceCenterName: string | null;
}

export interface ResellerDetailByDocument_ResellerDetailByDocument_addresses_info {
  __typename: "AddressInfoType";
  addressNumber: string | null;
  cep: string | null;
  city: string | null;
  complement: string | null;
  neighborhood: string | null;
  state: string | null;
  streetName: string | null;
  landmark: string | null;
}

export interface ResellerDetailByDocument_ResellerDetailByDocument_addresses {
  __typename: "AddressType";
  /**
   * Tipo de endereço
   */
  type: RegistrationInfoAddressType | null;
  /**
   * Informações do endereço
   */
  info: ResellerDetailByDocument_ResellerDetailByDocument_addresses_info | null;
}

export interface ResellerDetailByDocument_ResellerDetailByDocument_personalInfo_contactInfo {
  __typename: "ContactType";
  /**
   * Tipo de Contato
   */
  type: RegistrationInfoContactType | null;
  /**
   * Valor
   */
  value: string | null;
}

export interface ResellerDetailByDocument_ResellerDetailByDocument_personalInfo_documents {
  __typename: "DocumentType";
  /**
   * Tipo do documento
   */
  type: RegistrationInfoDocumentType | null;
  /**
   * Valor
   */
  value: string | null;
}

export interface ResellerDetailByDocument_ResellerDetailByDocument_personalInfo {
  __typename: "PersonalInfoType";
  /**
   * Razão do bloqueio
   */
  birthdate: any | null;
  /**
   * Apelido
   */
  nickname: string | null;
  /**
   * Nome
   */
  name: string | null;
  /**
   * Informação de contato
   */
  contactInfo: ResellerDetailByDocument_ResellerDetailByDocument_personalInfo_contactInfo[] | null;
  /**
   * Sexo
   */
  sex: RegistrationInfoSexType | null;
  /**
   * Documentos
   */
  documents: ResellerDetailByDocument_ResellerDetailByDocument_personalInfo_documents[] | null;
}

export interface ResellerDetailByDocument_ResellerDetailByDocument {
  __typename: "ResellerRegistrationInfoResponse";
  resellerId: string | null;
  commercialStructure: ResellerDetailByDocument_ResellerDetailByDocument_commercialStructure;
  /**
   * Endereços
   */
  addresses: ResellerDetailByDocument_ResellerDetailByDocument_addresses[] | null;
  /**
   * Informações pessoais
   */
  personalInfo: ResellerDetailByDocument_ResellerDetailByDocument_personalInfo | null;
  /**
   * Razão do bloqueio
   */
  blockReason: string | null;
  /**
   * Está ativo?
   */
  isActive: boolean | null;
  /**
   * Está bloqueado?
   */
  isBlocked: boolean | null;
  /**
   * Está inadimplente?
   */
  isWelch: boolean | null;
}

export interface ResellerDetailByDocument {
  /**
   * Obtém dados cadastrais da revendedora no Gera utilizando o ID ou documento RG/CPF
   */
  ResellerDetailByDocument: ResellerDetailByDocument_ResellerDetailByDocument;
}

export interface ResellerDetailByDocumentVariables {
  idType: ResellerIdentifierType;
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ResellerDetail
// ====================================================

export interface ResellerDetail_FvcResellerDetail_profile {
  __typename: "ResellerDetailProfileType";
  /**
   * Nome da revendedora
   */
  name: string;
  /**
   * URL do avatar da revendedora
   */
  avatarUrl: string | null;
  /**
   * Ciclo de início (número e ano)
   */
  startingCicle: string | null;
  /**
   * Telefones da revendedora cadastrados pela supervisora
   */
  phones: string[];
  /**
   * Endereços da revendedora cadastrados pela supervisora
   */
  addresses: string[];
  /**
   * E-mails da revendedora cadastrados pela supervisora
   */
  emails: string[];
  /**
   * Notas adicionais da revendedora cadastradas pela supervisora
   */
  additionalNotes: string | null;
}

export interface ResellerDetail_FvcResellerDetail_cicleStatus {
  __typename: "ResellerDetailCicleStatusType";
  /**
   * Categoria de Ativação
   */
  activationCategory: ResellerActivationCategoryType | null;
  /**
   * Feedback da Ativação
   */
  activationFeedback: ResellerActivationFeedbackType;
  /**
   * Potencial (farol) da revendedora
   */
  potencial: ResellerActivationPotencialType | null;
  /**
   * Se há um contato agendado
   */
  hasScheduledContact: boolean;
  /**
   * Data e hora do contato agendado (UTC)
   */
  scheduledContact: any | null;
}

export interface ResellerDetail_FvcResellerDetail_purchaseHistory_paymentMethodFrequency {
  __typename: "ResellerDetailPaymentMethodFrequencyType";
  /**
   * % de compras com cartão
   */
  card: number;
  /**
   * % de compras com boleto
   */
  boleto: number;
  /**
   * % de compras com dinheiro
   */
  cash: number;
  /**
   * % de compras misto
   */
  misc: number;
}

export interface ResellerDetail_FvcResellerDetail_purchaseHistory_previousCicles {
  __typename: "ResellerDetailPurchaseHistoryCicleType";
  /**
   * Id do ciclo
   */
  cicleId: string;
  /**
   * Valor total de compra no ciclo
   */
  value: number;
  /**
   * Potencial (farol) da revendedora no ciclo
   */
  potencial: ResellerActivationPotencialType;
}

export interface ResellerDetail_FvcResellerDetail_purchaseHistory {
  __typename: "ResellerDetailPurchaseHistoryType";
  /**
   * Valor potencial de compra total
   */
  valueTotalPurchasePotencial: number;
  /**
   * Valor comprado no ciclo atual
   */
  valuePurchasedOnCurrentCicle: number;
  /**
   * Valores comprados nos últimos 6 ciclos anteriores
   */
  valuePurchasedOnLastSixCicles: number;
  /**
   * Frequência média de pedidos por ciclo
   */
  averageOrdersPerCicle: number;
  /**
   * Pedido médio no ciclo
   */
  averageOrderOnCurrentCicle: number;
  /**
   * Status de inadimplência (% de inadimplência)
   */
  welchStatusPercentage: number;
  /**
   * Status de inadimplência (valor do crédito vencido)
   */
  welchStatusExpiredCredit: number;
  /**
   * Frequência de compra delivery (%s) - Número de pedidos
   */
  deliveryOrderFrequency: number;
  /**
   * Frequência de compra na loja (%s) - Número de pedidos
   */
  storeOrderFrequency: number;
  /**
   * Pedido médio para delivery (R$)
   */
  deliveryAverageOrder: number;
  /**
   * Pedido médio para compra na loja (R$)
   */
  storeAverageOrder: number;
  /**
   * Status de inadimplência
   */
  welchStatus: boolean;
  /**
   * Status de perda
   */
  welchIsLoss: boolean;
  /**
   * 6 produtos mais comprados pela revendedora
   */
  paymentMethodFrequency: ResellerDetail_FvcResellerDetail_purchaseHistory_paymentMethodFrequency;
  /**
   * Histórico de compra dos últimos ciclos
   */
  previousCicles: ResellerDetail_FvcResellerDetail_purchaseHistory_previousCicles[];
}

export interface ResellerDetail_FvcResellerDetail_reactions {
  __typename: "ResellersReactionResponse";
  name: string | null;
  emoji: string | null;
  commercialStructure: string | null;
  createdAt: any | null;
  updatedAt: any | null;
  id: string | null;
}

export interface ResellerDetail_FvcResellerDetail_tools {
  __typename: "ResellerDetailToolsType";
  /**
   * Revendedora usa o app
   */
  usesApp: boolean;
  /**
   * Revendedora usa MRD
   */
  usesMRD: boolean;
}

export interface ResellerDetail_FvcResellerDetail {
  __typename: "ResellerDetailResponse";
  /**
   * Id da revendedora
   */
  id: string;
  /**
   * Dados do perfil da revendedora
   */
  profile: ResellerDetail_FvcResellerDetail_profile;
  /**
   * Status da revendedora no ciclo atual
   */
  cicleStatus: ResellerDetail_FvcResellerDetail_cicleStatus;
  /**
   * Dados referentes ao histórico de compras da revendedora
   */
  purchaseHistory: ResellerDetail_FvcResellerDetail_purchaseHistory;
  /**
   * Reactions da revendedora
   */
  reactions: ResellerDetail_FvcResellerDetail_reactions[] | null;
  /**
   * Ferramentas usadas pela revendedora
   */
  tools: ResellerDetail_FvcResellerDetail_tools;
}

export interface ResellerDetail {
  /**
   * # Query - FvcResellerDetail
   * ## Description:
   * 
   * 
   *  - Traz os detalhes da revendedora selecionada
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## Internal Dependencies:
   * 
   * 
   *  - ### FvcResellerDetailUseCase
   *     
   * 
   * 
   *  - ### ResellerDbDataSource
   */
  FvcResellerDetail: ResellerDetail_FvcResellerDetail | null;
}

export interface ResellerDetailVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ResellerER
// ====================================================

export interface ResellerER_ResellerER {
  __typename: "ResellerERResponse";
  /**
   * Código da  ER
   */
  code: string | null;
  /**
   * Nome da ER
   */
  name: string | null;
  /**
   * Distância até a ER
   */
  distance: number | null;
  /**
   * Zona da ER
   */
  zone: ResellerERZone | null;
}

export interface ResellerER {
  /**
   * ER da Representante
   */
  ResellerER: ResellerER_ResellerER;
}

export interface ResellerERVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: EuPossoMais
// ====================================================

export interface EuPossoMais_ResellerEuPossoMais {
  __typename: "ResellerEuPossoMaisResponse";
  /**
   * Faixa do Eu Posso Mais atual
   */
  currentCategory: number | null;
  /**
   * Próxima faixa do Eu Posso Mais
   */
  nextCategory: number | null;
  /**
   * Receita da próxima categoria do Eu Posso Mais
   */
  pendingForNextCategory: number | null;
  /**
   * Crédito do Eu Posso Mais
   */
  creditForCurrentCycle: number | null;
}

export interface EuPossoMais {
  /**
   * # Query - ResellerEuPossoMais
   * ## Description:
   * 
   * 
   *  - Informações do Eu posso mais de uma representante
   * ## Request Headers:
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * ## External Dependencies:
   * 
   * 
   *  - ### EudoraResellerHttpDataSource
   *     
   * 
   * 	
   * 	 - Endpoint Path: /grb/sb/revendedoras/${id}/promocoes
   * ## Internal Dependencies:
   * 
   * 
   *  - ### ResellerEuPossoMaisUseCase
   *     
   * 
   * 
   *  - ### ResellerRevenueDbDataSource
   */
  ResellerEuPossoMais: EuPossoMais_ResellerEuPossoMais;
}

export interface EuPossoMaisVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ResellerFeedbackList
// ====================================================

export interface ResellerFeedbackList_FvcResellerFeedbackList_nodes_fvcFeedback {
  __typename: "FeedbackResponse";
  id: number | null;
  title: string;
  /**
   * Sinaliza se feedback terá campo de forma de contato
   */
  hasContactForm: boolean;
  /**
   * Sinaliza se feedback terá campo de observações
   */
  hasNote: boolean;
  /**
   * Código usado na tabulação do Gera
   */
  geraCode: string | null;
  active: boolean | null;
}

export interface ResellerFeedbackList_FvcResellerFeedbackList_nodes {
  __typename: "FvcResellerListFeedbackResponse";
  /**
   * Id de um feedback de ativação
   */
  id: number;
  /**
   * Feedback da Ativação
   */
  feedback: ResellerActivationFeedbackType;
  /**
   * Nota de um feedback de ativação
   */
  note: string | null;
  /**
   * Data e hora do contato agendado (UTC)
   */
  scheduledContact: any | null;
  /**
   * Data de criação do registro
   */
  createdAt: any;
  /**
   * Feedback customizado
   */
  fvcFeedback: ResellerFeedbackList_FvcResellerFeedbackList_nodes_fvcFeedback | null;
}

export interface ResellerFeedbackList_FvcResellerFeedbackList_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface ResellerFeedbackList_FvcResellerFeedbackList {
  __typename: "FvcResellerListFeedbackPaginatedResponse";
  /**
   * Array of FvcResellerListFeedbackResponse
   */
  nodes: ResellerFeedbackList_FvcResellerFeedbackList_nodes[];
  /**
   * Page information
   */
  pageInfo: ResellerFeedbackList_FvcResellerFeedbackList_pageInfo;
}

export interface ResellerFeedbackList {
  /**
   * # Query - FvcResellerFeedbackList
   * ## Description:
   * 
   * 
   *  - Traz a lista de feedbacks de uma revendedora (apenas FVC)
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## Internal Dependencies:
   * 
   * 
   *  - ### FvcResellerFeedbackListUseCase
   *     
   * 
   * 
   *  - ### ResellerActivationDbDataSource
   */
  FvcResellerFeedbackList: ResellerFeedbackList_FvcResellerFeedbackList;
}

export interface ResellerFeedbackListVariables {
  id: string;
  offset: number;
  limit: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ResellerInfo
// ====================================================

export interface ResellerInfo_ResellerInfo_reseller {
  __typename: "BasicInfoResponse";
  referenceId: string | null;
  name: string | null;
}

export interface ResellerInfo_ResellerInfo_franchise {
  __typename: "BasicInfoResponse";
  referenceId: string | null;
  name: string | null;
}

export interface ResellerInfo_ResellerInfo_store {
  __typename: "BasicInfoResponse";
  referenceId: string | null;
  name: string | null;
}

export interface ResellerInfo_ResellerInfo {
  __typename: "ResellerInfoResponse";
  reseller: ResellerInfo_ResellerInfo_reseller;
  franchise: ResellerInfo_ResellerInfo_franchise;
  store: ResellerInfo_ResellerInfo_store;
  availableCredit: number | null;
  birthdate: any | null;
  residentialPhone: string | null;
  mobilePhone: string | null;
  contactPhone: string | null;
  email: string | null;
  street: string | null;
  streetNumber: string | null;
  complement: string | null;
  neighborhood: string | null;
  city: string | null;
  zipCode: string | null;
  cycleAtFirstOrder: string | null;
  fraudRisk: string | null;
  registrationDate: any | null;
}

export interface ResellerInfo {
  /**
   * # Query - ResellerInfo
   * ## Description:
   * 
   * 
   *  - Traz as informações do(a) revendedor(a)
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## Internal Dependencies:
   * 
   * 
   *  - ### GetResellerInfoUseCase
   *     
   * 
   * 
   *  - ### ResellerInfoDataSource
   */
  ResellerInfo: ResellerInfo_ResellerInfo;
}

export interface ResellerInfoVariables {
  resellerId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ResellerInteractions
// ====================================================

export interface ResellerInteractions_ResellerInteractions_lastMessage {
  __typename: "MessageResponse";
  /**
   * Identificador da mensagem
   */
  id: string;
  /**
   * Telefone remetente
   */
  from: string;
  /**
   * Telefone destino
   */
  to: string;
  /**
   * Tipo da mensagem
   */
  type: MessageType | null;
  /**
   * Conteúdo da mensagem
   */
  message: string | null;
  /**
   * URL da imagem/áudio
   */
  mediaUrl: string | null;
  /**
   * Status
   */
  status: MessageStatus;
  /**
   * Data de envio (UTC)
   */
  sentAt: any | null;
}

export interface ResellerInteractions_ResellerInteractions {
  __typename: "ResellerInteractionResponse";
  /**
   * ID da revendedora (se ela foi identificada)
   */
  resellerId: string | null;
  /**
   * Nome da revendedora (se ela foi identificada)
   */
  name: string | null;
  /**
   * Telefone da revendedora
   */
  cellphone: string;
  /**
   * Indica se está dentro da janela de 24 horas
   */
  isMinorThan24Hours: boolean;
  /**
   * Status do atendimento via WhatsApp
   */
  status: ResellerInteractionStatus;
  /**
   * Data da última mensagem enviada pela revendedora (UTC)
   */
  lastResellerMessageAt: any;
  /**
   * Última mensagem trocada
   */
  lastMessage: ResellerInteractions_ResellerInteractions_lastMessage;
}

export interface ResellerInteractions {
  /**
   * # Query - ResellerInteractions
   * ## Description:
   * 
   * 
   *  - Lista as interações com revendedoras (Whatsapp)
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## Internal Dependencies:
   * 
   * 
   *  - ### CommonListResellerInteractionsUseCase
   *     
   * 
   * 
   *  - ### FVCResellerInstantMessageDatasource
   *     
   * 
   * 
   *  - ### FvcStructureDbDataSource
   */
  ResellerInteractions: ResellerInteractions_ResellerInteractions[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ResellerListByName
// ====================================================

export interface ResellerListByName_ResellerListByName_nodes {
  __typename: "ResellerListByNameResponse";
  /**
   * Código da revendedora
   */
  resellerId: string;
  /**
   * Nome da revendedora
   */
  name: string;
  /**
   * Data de nascimento da revendedora
   */
  birthdate: any | null;
  /**
   * Crédito da revendedora
   */
  credit: number | null;
  /**
   * Status da revendedora
   */
  status: ResellerListRegistrationStatus | null;
  /**
   * Origem do cadastro
   */
  origin: ResellerListRegistrationOrigin | null;
}

export interface ResellerListByName_ResellerListByName_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface ResellerListByName_ResellerListByName {
  __typename: "ResellerListByNamePaginatedResponse";
  /**
   * Total number of elements
   */
  count: number;
  /**
   * Array of ResellerListByNameResponse
   */
  nodes: ResellerListByName_ResellerListByName_nodes[];
  /**
   * Page information
   */
  pageInfo: ResellerListByName_ResellerListByName_pageInfo;
}

export interface ResellerListByName {
  /**
   * Obtém lista de revendedoras de acordo com nome fornecido e por intenção de revenda
   */
  ResellerListByName: ResellerListByName_ResellerListByName;
}

export interface ResellerListByNameVariables {
  page?: number | null;
  limit?: number | null;
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ResellerMultiplePaymentOptions
// ====================================================

export interface ResellerMultiplePaymentOptions_ResellerMultiplePaymentOptions_paymentOptions_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ResellerMultiplePaymentOptions_ResellerMultiplePaymentOptions_paymentOptions_creditCardOptions_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ResellerMultiplePaymentOptions_ResellerMultiplePaymentOptions_paymentOptions_creditCardOptions {
  __typename: "CreditCardOptionType";
  /**
   * Descrição da bandeira do cartão de crédito
   */
  type: string;
  /**
   * Identificador da bandeira do cartão de crédito
   */
  flag: string;
  /**
   * Modo de pagamento para o cartão especificado
   */
  paymentMode: ResellerMultiplePaymentOptions_ResellerMultiplePaymentOptions_paymentOptions_creditCardOptions_paymentMode;
  /**
   * Parcelas possíveis de serem escolhidas
   */
  possibleNumberOfInstallments: number[] | null;
}

export interface ResellerMultiplePaymentOptions_ResellerMultiplePaymentOptions_paymentOptions_selectedInstallments {
  __typename: "InstallmentType";
  /**
   * Número da parcela
   */
  number: number;
  /**
   * Valor da parcela
   */
  value: number;
  /**
   * Data de vencimento
   */
  dueDate: any;
  /**
   * Indica se está bloqueada
   */
  isBlocked: boolean;
}

export interface ResellerMultiplePaymentOptions_ResellerMultiplePaymentOptions_paymentOptions {
  __typename: "PaymentPlanResponse";
  /**
   * Código do plano de pagamento
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Indica se consumirá crédito da revendedora
   */
  willConsumeCredit: boolean;
  /**
   * Indica se ela deve ter saldo de crédito para poder comprar
   */
  shouldVerifyCredit: boolean;
  /**
   * Indica se está bloqueado
   */
  isBlocked: boolean;
  /**
   * Taxa de administração
   */
  tax: number;
  /**
   * Número de parcelas (para planos sem cartão crédito)
   */
  installments: number | null;
  /**
   * Identificador do modo de pagamento
   */
  paymentMode: ResellerMultiplePaymentOptions_ResellerMultiplePaymentOptions_paymentOptions_paymentMode | null;
  /**
   * Opções quando pagamento é por cartão de crédito
   */
  creditCardOptions: ResellerMultiplePaymentOptions_ResellerMultiplePaymentOptions_paymentOptions_creditCardOptions[] | null;
  /**
   * Parcelas do plano selecionado
   */
  selectedInstallments: ResellerMultiplePaymentOptions_ResellerMultiplePaymentOptions_paymentOptions_selectedInstallments[] | null;
  /**
   * Indica se é múltiplas formas de pagamento
   */
  isMultiplePayment: boolean;
}

export interface ResellerMultiplePaymentOptions_ResellerMultiplePaymentOptions_creditCardsAvailable_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ResellerMultiplePaymentOptions_ResellerMultiplePaymentOptions_creditCardsAvailable {
  __typename: "CreditCardOptionType";
  /**
   * Descrição da bandeira do cartão de crédito
   */
  type: string;
  /**
   * Identificador da bandeira do cartão de crédito
   */
  flag: string;
  /**
   * Modo de pagamento para o cartão especificado
   */
  paymentMode: ResellerMultiplePaymentOptions_ResellerMultiplePaymentOptions_creditCardsAvailable_paymentMode;
  /**
   * Parcelas possíveis de serem escolhidas
   */
  possibleNumberOfInstallments: number[] | null;
}

export interface ResellerMultiplePaymentOptions_ResellerMultiplePaymentOptions_paymentOptionsSelected_paymentPlanSelected_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ResellerMultiplePaymentOptions_ResellerMultiplePaymentOptions_paymentOptionsSelected_paymentPlanSelected_creditCardOptions_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ResellerMultiplePaymentOptions_ResellerMultiplePaymentOptions_paymentOptionsSelected_paymentPlanSelected_creditCardOptions {
  __typename: "CreditCardOptionType";
  /**
   * Descrição da bandeira do cartão de crédito
   */
  type: string;
  /**
   * Identificador da bandeira do cartão de crédito
   */
  flag: string;
  /**
   * Modo de pagamento para o cartão especificado
   */
  paymentMode: ResellerMultiplePaymentOptions_ResellerMultiplePaymentOptions_paymentOptionsSelected_paymentPlanSelected_creditCardOptions_paymentMode;
  /**
   * Parcelas possíveis de serem escolhidas
   */
  possibleNumberOfInstallments: number[] | null;
}

export interface ResellerMultiplePaymentOptions_ResellerMultiplePaymentOptions_paymentOptionsSelected_paymentPlanSelected_selectedInstallments {
  __typename: "InstallmentType";
  /**
   * Número da parcela
   */
  number: number;
  /**
   * Valor da parcela
   */
  value: number;
  /**
   * Data de vencimento
   */
  dueDate: any;
  /**
   * Indica se está bloqueada
   */
  isBlocked: boolean;
}

export interface ResellerMultiplePaymentOptions_ResellerMultiplePaymentOptions_paymentOptionsSelected_paymentPlanSelected {
  __typename: "PaymentPlanResponse";
  /**
   * Código do plano de pagamento
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Indica se consumirá crédito da revendedora
   */
  willConsumeCredit: boolean;
  /**
   * Indica se ela deve ter saldo de crédito para poder comprar
   */
  shouldVerifyCredit: boolean;
  /**
   * Indica se está bloqueado
   */
  isBlocked: boolean;
  /**
   * Taxa de administração
   */
  tax: number;
  /**
   * Número de parcelas (para planos sem cartão crédito)
   */
  installments: number | null;
  /**
   * Identificador do modo de pagamento
   */
  paymentMode: ResellerMultiplePaymentOptions_ResellerMultiplePaymentOptions_paymentOptionsSelected_paymentPlanSelected_paymentMode | null;
  /**
   * Opções quando pagamento é por cartão de crédito
   */
  creditCardOptions: ResellerMultiplePaymentOptions_ResellerMultiplePaymentOptions_paymentOptionsSelected_paymentPlanSelected_creditCardOptions[] | null;
  /**
   * Parcelas do plano selecionado
   */
  selectedInstallments: ResellerMultiplePaymentOptions_ResellerMultiplePaymentOptions_paymentOptionsSelected_paymentPlanSelected_selectedInstallments[] | null;
  /**
   * Indica se é múltiplas formas de pagamento
   */
  isMultiplePayment: boolean;
}

export interface ResellerMultiplePaymentOptions_ResellerMultiplePaymentOptions_paymentOptionsSelected_paymentPlansAvailable_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ResellerMultiplePaymentOptions_ResellerMultiplePaymentOptions_paymentOptionsSelected_paymentPlansAvailable_creditCardOptions_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ResellerMultiplePaymentOptions_ResellerMultiplePaymentOptions_paymentOptionsSelected_paymentPlansAvailable_creditCardOptions {
  __typename: "CreditCardOptionType";
  /**
   * Descrição da bandeira do cartão de crédito
   */
  type: string;
  /**
   * Identificador da bandeira do cartão de crédito
   */
  flag: string;
  /**
   * Modo de pagamento para o cartão especificado
   */
  paymentMode: ResellerMultiplePaymentOptions_ResellerMultiplePaymentOptions_paymentOptionsSelected_paymentPlansAvailable_creditCardOptions_paymentMode;
  /**
   * Parcelas possíveis de serem escolhidas
   */
  possibleNumberOfInstallments: number[] | null;
}

export interface ResellerMultiplePaymentOptions_ResellerMultiplePaymentOptions_paymentOptionsSelected_paymentPlansAvailable_selectedInstallments {
  __typename: "InstallmentType";
  /**
   * Número da parcela
   */
  number: number;
  /**
   * Valor da parcela
   */
  value: number;
  /**
   * Data de vencimento
   */
  dueDate: any;
  /**
   * Indica se está bloqueada
   */
  isBlocked: boolean;
}

export interface ResellerMultiplePaymentOptions_ResellerMultiplePaymentOptions_paymentOptionsSelected_paymentPlansAvailable {
  __typename: "PaymentPlanResponse";
  /**
   * Código do plano de pagamento
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Indica se consumirá crédito da revendedora
   */
  willConsumeCredit: boolean;
  /**
   * Indica se ela deve ter saldo de crédito para poder comprar
   */
  shouldVerifyCredit: boolean;
  /**
   * Indica se está bloqueado
   */
  isBlocked: boolean;
  /**
   * Taxa de administração
   */
  tax: number;
  /**
   * Número de parcelas (para planos sem cartão crédito)
   */
  installments: number | null;
  /**
   * Identificador do modo de pagamento
   */
  paymentMode: ResellerMultiplePaymentOptions_ResellerMultiplePaymentOptions_paymentOptionsSelected_paymentPlansAvailable_paymentMode | null;
  /**
   * Opções quando pagamento é por cartão de crédito
   */
  creditCardOptions: ResellerMultiplePaymentOptions_ResellerMultiplePaymentOptions_paymentOptionsSelected_paymentPlansAvailable_creditCardOptions[] | null;
  /**
   * Parcelas do plano selecionado
   */
  selectedInstallments: ResellerMultiplePaymentOptions_ResellerMultiplePaymentOptions_paymentOptionsSelected_paymentPlansAvailable_selectedInstallments[] | null;
  /**
   * Indica se é múltiplas formas de pagamento
   */
  isMultiplePayment: boolean;
}

export interface ResellerMultiplePaymentOptions_ResellerMultiplePaymentOptions_paymentOptionsSelected_creditCardFlag_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface ResellerMultiplePaymentOptions_ResellerMultiplePaymentOptions_paymentOptionsSelected_creditCardFlag {
  __typename: "CreditCardOptionType";
  /**
   * Descrição da bandeira do cartão de crédito
   */
  type: string;
  /**
   * Identificador da bandeira do cartão de crédito
   */
  flag: string;
  /**
   * Modo de pagamento para o cartão especificado
   */
  paymentMode: ResellerMultiplePaymentOptions_ResellerMultiplePaymentOptions_paymentOptionsSelected_creditCardFlag_paymentMode;
  /**
   * Parcelas possíveis de serem escolhidas
   */
  possibleNumberOfInstallments: number[] | null;
}

export interface ResellerMultiplePaymentOptions_ResellerMultiplePaymentOptions_paymentOptionsSelected_statusPayment {
  __typename: "StatusPaymentType";
  /**
   * Cartão recusado
   */
  refused: boolean | null;
  /**
   * Pagamento cancelado
   */
  cancel: boolean | null;
  /**
   * Pagamento aprovado
   */
  approved: boolean | null;
  /**
   * Em processamento
   */
  notProcess: boolean | null;
  /**
   * Esperando aprovação do pagamento
   */
  waitingApproved: boolean | null;
  /**
   * Em processo de cancelamento
   */
  waitingCancel: boolean | null;
  /**
   * Pagamento com erro
   */
  failed: boolean | null;
}

export interface ResellerMultiplePaymentOptions_ResellerMultiplePaymentOptions_paymentOptionsSelected_fraudPrevention {
  __typename: "FraudPreventionType";
  /**
   * App
   */
  app: string;
  /**
   * Session id
   */
  sessionID: string;
}

export interface ResellerMultiplePaymentOptions_ResellerMultiplePaymentOptions_paymentOptionsSelected {
  __typename: "PaymentOptionSelectedType";
  /**
   * Id do pagamento
   */
  id: number | null;
  /**
   * Plano de pagamento selecionado
   */
  paymentPlanSelected: ResellerMultiplePaymentOptions_ResellerMultiplePaymentOptions_paymentOptionsSelected_paymentPlanSelected;
  /**
   * Plano de pagamento disponíveis
   */
  paymentPlansAvailable: ResellerMultiplePaymentOptions_ResellerMultiplePaymentOptions_paymentOptionsSelected_paymentPlansAvailable[] | null;
  /**
   * Código do pagamento
   */
  distributionCodeDetails: number | null;
  /**
   * Valor escolhido
   */
  amount: number;
  /**
   * Informação do cartão
   */
  creditCardFlag: ResellerMultiplePaymentOptions_ResellerMultiplePaymentOptions_paymentOptionsSelected_creditCardFlag | null;
  /**
   * Status do pagamento do cartão
   */
  statusPayment: ResellerMultiplePaymentOptions_ResellerMultiplePaymentOptions_paymentOptionsSelected_statusPayment | null;
  /**
   * Prevenção de fraude
   */
  fraudPrevention: ResellerMultiplePaymentOptions_ResellerMultiplePaymentOptions_paymentOptionsSelected_fraudPrevention | null;
  /**
   * Bloqueado para alteração
   */
  isChangeBlocked: boolean;
  /**
   * Quantidade de parcelas selecionada
   */
  installmentSelected: number | null;
  /**
   * Número do pagamento
   */
  numberPayment: number | null;
}

export interface ResellerMultiplePaymentOptions_ResellerMultiplePaymentOptions {
  __typename: "MultiplePaymentOptionsResponse";
  /**
   * Código do pedido
   */
  orderCode: string;
  /**
   * Quantidade máxima de cartões
   */
  maxCardToChoose: number;
  /**
   * Possível editar o pedido
   */
  isOrderEditable: boolean | null;
  /**
   * Opções de pagamento de boleto
   */
  paymentOptions: ResellerMultiplePaymentOptions_ResellerMultiplePaymentOptions_paymentOptions[] | null;
  /**
   * Opções de pagamento de cartão
   */
  creditCardsAvailable: ResellerMultiplePaymentOptions_ResellerMultiplePaymentOptions_creditCardsAvailable[] | null;
  /**
   * Pagamentos selecionados
   */
  paymentOptionsSelected: ResellerMultiplePaymentOptions_ResellerMultiplePaymentOptions_paymentOptionsSelected[] | null;
}

export interface ResellerMultiplePaymentOptions {
  /**
   * # Query - ResellerMultiplePaymentOptions
   * ## Description:
   * 
   * 
   *  - Opções de pagamento
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## Internal Dependencies:
   * 
   * 
   *  - ### MultiplePaymentOptionsUseCase
   *     
   * 
   * 
   *  - ### MultiplePaymentDatasource
   */
  ResellerMultiplePaymentOptions: ResellerMultiplePaymentOptions_ResellerMultiplePaymentOptions;
}

export interface ResellerMultiplePaymentOptionsVariables {
  orderCode: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ResellerMultiplePaymentOrderTotal
// ====================================================

export interface ResellerMultiplePaymentOrderTotal_ResellerMultiplePaymentOrderTotal {
  __typename: "CartTotalsType";
  /**
   * Total de venda
   */
  totalSell: number | null;
  /**
   * Lucro
   */
  profit: number | null;
  /**
   * Frete
   */
  deliveryTax: number | null;
  /**
   * Subtotal
   */
  subtotalPay: number | null;
  /**
   * Total
   */
  totalPay: number | null;
  /**
   * Lucro vindo dos brindes
   */
  profitFromGifts: number | null;
  /**
   * Valor de crédito utilizado da CCR (Conta Corrente Residual)
   */
  checkingAccount: number | null;
  /**
   * Taxa administrativa
   */
  collectionFee: number | null;
  /**
   * Impostos
   */
  taxes: number | null;
  /**
   * Comissão
   */
  commission: number | null;
}

export interface ResellerMultiplePaymentOrderTotal {
  /**
   * # Query - ResellerMultiplePaymentOrderTotal
   * ## Description:
   * 
   * 
   *  - Traz as informações do total do pedido
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## External Dependencies:
   * 
   * 
   *  - ### BoticarioBaseCartHttpDatasource
   *     
   * 
   * 	
   * 	 - Endpoint Path: /orders/${orderCode}/Details
   * ## Internal Dependencies:
   * 
   * 
   *  - ### GetTotalsUseCase
   *     
   * 
   * 
   *  - ### ManageOrderDatasource
   */
  ResellerMultiplePaymentOrderTotal: ResellerMultiplePaymentOrderTotal_ResellerMultiplePaymentOrderTotal;
}

export interface ResellerMultiplePaymentOrderTotalVariables {
  orderCode: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ResellerOrderDetail
// ====================================================

export interface ResellerOrderDetail_ResellerOrderDetail_payments_titles {
  __typename: "ResellerOrderDetailPaymentTitleType";
  /**
   * Saldo total
   */
  totalBalance: number;
  /**
   * Situação do pagamento
   */
  paymentStatus: string;
  /**
   * Situação do título
   */
  titleStatus: string;
}

export interface ResellerOrderDetail_ResellerOrderDetail_payments {
  __typename: "ResellerOrderDetailPaymentType";
  /**
   * Id do pagamento
   */
  id: string;
  /**
   * Data de vencimento
   */
  dueDate: any | null;
  /**
   * Tipo
   */
  type: string | null;
  /**
   * Parcelas
   */
  installments: number | null;
  /**
   * Valor
   */
  value: number | null;
  /**
   * Link do arquivo
   */
  fileLink: string | null;
  /**
   * Títulos
   */
  titles: ResellerOrderDetail_ResellerOrderDetail_payments_titles[] | null;
}

export interface ResellerOrderDetail_ResellerOrderDetail_externalPaymentLink {
  __typename: "NewPaymentLinkResponse";
  /**
   * Url de pagamento
   */
  externalPaymentLink: string | null;
  /**
   * Data de expiração da url de pagamento
   */
  expirationDatePaymentLink: any | null;
}

export interface ResellerOrderDetail_ResellerOrderDetail_products {
  __typename: "ResellerOrderDetailItemType";
  /**
   * Quantidade
   */
  amount: number | null;
  /**
   * Valor unitário
   */
  unitValue: number | null;
  /**
   * Nome do produto
   */
  name: string | null;
  /**
   * Imagem principal do produto
   */
  image: string | null;
  /**
   * Código do canal de venda direta do produto
   */
  code: string | null;
  /**
   * Tipo de item
   */
  type: string | null;
}

export interface ResellerOrderDetail_ResellerOrderDetail_deliveryAddress {
  __typename: "ResellerOrderDetailAddressType";
  /**
   * Código da estrutura geográfica
   */
  id: string | null;
  /**
   * Número
   */
  addressNumber: string | null;
  /**
   * CEP
   */
  cep: string | null;
  /**
   * Cidade
   */
  city: string | null;
  /**
   * Complemento
   */
  complement: string | null;
  /**
   * Bairro
   */
  neighborhood: string | null;
  /**
   * Estado
   */
  state: string | null;
  /**
   * Logradouro
   */
  streetName: string | null;
  /**
   * País
   */
  country: string | null;
  /**
   * Acrônimo do estado
   */
  stateAcronym: string;
}

export interface ResellerOrderDetail_ResellerOrderDetail {
  __typename: "ResellerOrderDetailResponse";
  /**
   * Pagamentos
   */
  payments: ResellerOrderDetail_ResellerOrderDetail_payments[] | null;
  /**
   * Nome do status
   */
  statusName: string | null;
  /**
   * Situação fiscal
   */
  fiscalSituation: string | null;
  /**
   * Valor do CCR utilizado
   */
  creditValue: number | null;
  /**
   * Valor que a RE pagará
   */
  valueToPay: number | null;
  /**
   * Código
   */
  code: string | null;
  /**
   * Data
   */
  date: any | null;
  /**
   * Status
   */
  status: string | null;
  /**
   * Descrição do status
   */
  statusDescription: string | null;
  /**
   * Valor total
   */
  totalValue: number | null;
  /**
   * Dados do link externo de pagamento
   */
  externalPaymentLink: ResellerOrderDetail_ResellerOrderDetail_externalPaymentLink | null;
  /**
   * Items
   */
  products: ResellerOrderDetail_ResellerOrderDetail_products[] | null;
  /**
   * Endereço de entrega
   */
  deliveryAddress: ResellerOrderDetail_ResellerOrderDetail_deliveryAddress | null;
}

export interface ResellerOrderDetail {
  /**
   * # Query - ResellerOrderDetail
   * ## Description:
   * 
   * 
   *  - Traz os detalhes de um dado pedido da revendedora
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## External Dependencies:
   * 
   * 
   *  - ### ResellerOrderHttpDataSource
   *     
   * 
   * 	
   * 	 - Endpoint Path: /grb/sb/revendedoras/${resellerId}/vendas/${orderId}/detalhes
   * ## Internal Dependencies:
   * 
   * 
   *  - ### ResellerOrderDetailUseCase
   */
  ResellerOrderDetail: ResellerOrderDetail_ResellerOrderDetail;
}

export interface ResellerOrderDetailVariables {
  orderId: string;
  resellerId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ResellerOrderStatus
// ====================================================

export interface ResellerOrderStatus_ResellerOrderStatus_orderPending {
  __typename: "OrderPendingType";
  /**
   * Id do pagamento
   */
  code: number;
  /**
   * Tipo
   */
  description: string;
}

export interface ResellerOrderStatus_ResellerOrderStatus {
  __typename: "ResellerOrderStatusResponse";
  /**
   * Código do pedido
   */
  orderCode: number;
  /**
   * Código do status do pedido
   */
  statusCode: number;
  /**
   * Descrição do status do pedido
   */
  description: string;
  /**
   * Pendencias do pedido
   */
  orderPending: ResellerOrderStatus_ResellerOrderStatus_orderPending[];
}

export interface ResellerOrderStatus {
  /**
   * # Query - ResellerOrderStatus
   * ## Description:
   * 
   * 
   *  - Obtém o status do pedido
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## External Dependencies:
   * 
   * 
   *  - ### ResellerOrderStatusAeroDatasource
   *     
   * 
   * 	
   * 	 - Endpoint Path: /orders/${orderCode}
   * ## Internal Dependencies:
   * 
   * 
   *  - ### ResellerOrderStatusUseCase
   */
  ResellerOrderStatus: ResellerOrderStatus_ResellerOrderStatus;
}

export interface ResellerOrderStatusVariables {
  orderCode: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ResellerPreferencesInfo
// ====================================================

export interface ResellerPreferencesInfo_ResellerPreferencesInfo {
  __typename: "ResellerPreferencesInfoResponse";
  /**
   * Flag para indicar se a representante comprou no app da RE no último ciclo
   */
  boughtByApp: boolean | null;
  /**
   * Flag para indicar se a representante comprou no app da RE nos últimos 7 ciclos
   */
  boughtByAppLast7Cycles: boolean | null;
  /**
   * Flag para indicar se a representante comprou em uma loja física no último ciclo
   */
  boughtByConsultant: boolean | null;
  /**
   * Flag para indicar se a representante comprou em uma loja física nos últimos 7 ciclos
   */
  boughtByConsultantLast7Cycles: boolean | null;
  /**
   * Flag para indicar se a representante comprou na ER no último ciclo
   */
  boughtByER: boolean | null;
  /**
   * Flag para indicar se a representante comprou na ER nos últimos 7 ciclos
   */
  boughtByERLast7Cycles: boolean | null;
  /**
   * Flag para indicar se a representante comprou no site da RE no último ciclo
   */
  boughtBySite: boolean | null;
  /**
   * Flag para indicar se a representante comprou no site da RE nos últimos 7 ciclos
   */
  boughtBySiteLast7Cycles: boolean | null;
  /**
   * Engajamento da representante com a ER
   */
  engagementER: string | null;
  /**
   * Canal de venda favorito da representante
   */
  favoriteChannel: string | null;
  /**
   * Faixa VIP
   */
  vipTier: string | null;
  /**
   * Valor necessário para a próxima Faixa VIP
   */
  nextTierValue: number | null;
}

export interface ResellerPreferencesInfo {
  /**
   * Informações de preferências de compra da revendedora
   */
  ResellerPreferencesInfo: ResellerPreferencesInfo_ResellerPreferencesInfo;
}

export interface ResellerPreferencesInfoVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ResellerRegistrationInfo
// ====================================================

export interface ResellerRegistrationInfo_ResellerRegistrationInfo_commercialStructure {
  __typename: "ResellerCommercialStructureType";
  /**
   * Identificador da estrutura comercial
   */
  referenceId: string | null;
  /**
   * Nome do franqueado
   */
  franchiseName: string | null;
  /**
   * Nome da central de serviço
   */
  serviceCenterName: string | null;
}

export interface ResellerRegistrationInfo_ResellerRegistrationInfo_addresses_info {
  __typename: "AddressInfoType";
  addressNumber: string | null;
  cep: string | null;
  city: string | null;
  complement: string | null;
  neighborhood: string | null;
  state: string | null;
  streetName: string | null;
  landmark: string | null;
}

export interface ResellerRegistrationInfo_ResellerRegistrationInfo_addresses {
  __typename: "AddressType";
  /**
   * Tipo de endereço
   */
  type: RegistrationInfoAddressType | null;
  /**
   * Informações do endereço
   */
  info: ResellerRegistrationInfo_ResellerRegistrationInfo_addresses_info | null;
}

export interface ResellerRegistrationInfo_ResellerRegistrationInfo_personalInfo_contactInfo {
  __typename: "ContactType";
  /**
   * Tipo de Contato
   */
  type: RegistrationInfoContactType | null;
  /**
   * Valor
   */
  value: string | null;
}

export interface ResellerRegistrationInfo_ResellerRegistrationInfo_personalInfo_documents {
  __typename: "DocumentType";
  /**
   * Tipo do documento
   */
  type: RegistrationInfoDocumentType | null;
  /**
   * Valor
   */
  value: string | null;
}

export interface ResellerRegistrationInfo_ResellerRegistrationInfo_personalInfo {
  __typename: "PersonalInfoType";
  /**
   * Razão do bloqueio
   */
  birthdate: any | null;
  /**
   * Apelido
   */
  nickname: string | null;
  /**
   * Nome
   */
  name: string | null;
  /**
   * Informação de contato
   */
  contactInfo: ResellerRegistrationInfo_ResellerRegistrationInfo_personalInfo_contactInfo[] | null;
  /**
   * Sexo
   */
  sex: RegistrationInfoSexType | null;
  /**
   * Documentos
   */
  documents: ResellerRegistrationInfo_ResellerRegistrationInfo_personalInfo_documents[] | null;
}

export interface ResellerRegistrationInfo_ResellerRegistrationInfo {
  __typename: "ResellerRegistrationInfoResponse";
  resellerId: string | null;
  commercialStructure: ResellerRegistrationInfo_ResellerRegistrationInfo_commercialStructure;
  /**
   * Endereços
   */
  addresses: ResellerRegistrationInfo_ResellerRegistrationInfo_addresses[] | null;
  /**
   * Informações pessoais
   */
  personalInfo: ResellerRegistrationInfo_ResellerRegistrationInfo_personalInfo | null;
}

export interface ResellerRegistrationInfo {
  /**
   * # Query - ResellerRegistrationInfo
   * ## Description:
   * 
   * 
   *  - Traz as informações cadastrais do(a) revendedor(a) armazenadas no gera
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## External Dependencies:
   * 
   * 
   *  - ### ResellerHttpDataSource
   *     
   * 
   * 	
   * 	 - Endpoint Path:  /grb/sb/revendedoras/${resellerId}/cadastro
   * ## Internal Dependencies:
   * 
   * 
   *  - ### ResellerRegistrationInfoUseCase
   *     
   * 
   * 
   *  - ### ResellerRegistrationInfoRepository
   *     
   * 
   * 
   *  - ### SupervisorRankingMetricDbDataSource
   */
  ResellerRegistrationInfo: ResellerRegistrationInfo_ResellerRegistrationInfo;
}

export interface ResellerRegistrationInfoVariables {
  resellerId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ResellerSearch
// ====================================================

export interface ResellerSearch_ResellerSearch {
  __typename: "ResellerSearchResponse";
  referenceId: string;
  name: string;
}

export interface ResellerSearch {
  /**
   * # Query - ResellerSearch
   * ## Description:
   * 
   * 
   *  - Traz os dados de uma revendedora.
   * ## Access Roles:
   * 
   * 
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## External Dependencies:
   * 
   * 
   *  - ### ResellerSearchGeraDataSource
   *     
   * 
   * 	
   * 	 - Endpoint Path: /grb/sb/intencaorevenda/intencoesRevenda
   * ## Internal Dependencies:
   * 
   * 
   *  - ### ResellerSearchUseCase
   */
  ResellerSearch: ResellerSearch_ResellerSearch[] | null;
}

export interface ResellerSearchVariables {
  document: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ResellerStatusGeraQuery
// ====================================================

export interface ResellerStatusGeraQuery_ResellerStatusGera {
  __typename: "ResellerStatusGeraResponse";
  /**
   * Status do revendedor (GERA)
   */
  status: string | null;
}

export interface ResellerStatusGeraQuery {
  ResellerStatusGera: ResellerStatusGeraQuery_ResellerStatusGera[];
}

export interface ResellerStatusGeraQueryVariables {
  document: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ResellerTopProducts
// ====================================================

export interface ResellerTopProducts_ResellerTopProducts {
  __typename: "ResellerProductResponse";
  /**
   * Nome do produto
   */
  name: string;
  /**
   * Código do produto
   */
  code: string;
  /**
   * Foto do produto
   */
  pictureURL: string;
  /**
   * Preço do produto
   */
  price: number | null;
}

export interface ResellerTopProducts {
  /**
   * # Query - ResellerTopProducts
   * ## Description:
   * 
   * 
   *  - Produtos mais comprados pela revendedora
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## Internal Dependencies:
   * 
   * 
   *  - ### ResellerTopProductsUseCase
   *     
   * 
   * 
   *  - ### ResellerTopProductsMetricDbDataSource
   */
  ResellerTopProducts: ResellerTopProducts_ResellerTopProducts[];
}

export interface ResellerTopProductsVariables {
  id: string;
  commercialStructure?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ResellersList
// ====================================================

export interface ResellersList_FvcResellers_nodes {
  __typename: "FvcResellersResponse";
  /**
   * Id da revendedora
   */
  resellerId: string;
  /**
   * Nome da revendedora
   */
  resellerName: string | null;
}

export interface ResellersList_FvcResellers_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface ResellersList_FvcResellers {
  __typename: "FvcResellersPaginatedResponse";
  /**
   * Total number of elements
   */
  count: number;
  /**
   * Array of FvcResellersResponse
   */
  nodes: ResellersList_FvcResellers_nodes[];
  /**
   * Page information
   */
  pageInfo: ResellersList_FvcResellers_pageInfo;
}

export interface ResellersList {
  /**
   * # Query - FvcResellers
   * ## Description:
   * 
   * 
   *  - Traz as revendedoras de uma dada estrutura comercial FVC, incluindo as que ainda não estão na régua
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## Internal Dependencies:
   * 
   * 
   *  - ### FvcResellersUseCase
   */
  FvcResellers: ResellersList_FvcResellers;
}

export interface ResellersListVariables {
  offset?: number | null;
  limit?: number | null;
  commercialStructure: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ResellersOrderList
// ====================================================

export interface ResellersOrderList_ResellersOrderList_nodes {
  __typename: "ResellerOrderListResponse";
  /**
   * Id do pedido
   */
  id: string;
  /**
   * Data do pedido
   */
  date: any | null;
  /**
   * Ciclo que o pedido foi feito
   */
  cicle: string;
  /**
   * Método de pagamento
   */
  paymentMethod: string;
  /**
   * Status do pedido
   */
  status: string;
  /**
   * Valor total do pedido
   */
  total: number | null;
}

export interface ResellersOrderList_ResellersOrderList_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface ResellersOrderList_ResellersOrderList {
  __typename: "ResellersOrderListConnection";
  /**
   * Total number of elements
   */
  count: number;
  /**
   * Array of ResellerOrderListResponse
   */
  nodes: ResellersOrderList_ResellersOrderList_nodes[];
  /**
   * Page information
   */
  pageInfo: ResellersOrderList_ResellersOrderList_pageInfo;
}

export interface ResellersOrderList {
  /**
   * # Query - ResellersOrderList
   * ## Description:
   * 
   * 
   *  - Traz a listagem dos pedidos da Revendedoras
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## External Dependencies:
   * 
   * 
   *  - ### ResellerOrderHttpDataSource
   *     
   * 
   * 	
   * 	 - Endpoint Path: /grb/sb/revendedoras/${resellerId}/vendas
   * ## Internal Dependencies:
   * 
   * 
   *  - ### ResellerOrderListUseCase
   */
  ResellersOrderList: ResellersOrderList_ResellersOrderList;
}

export interface ResellersOrderListVariables {
  resellerId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ResellersOrderPaginated
// ====================================================

export interface ResellersOrderPaginated_ResellersOrderListPaginated_nodes {
  __typename: "ResellerOrderListResponse";
  /**
   * Id do pedido
   */
  id: string;
  /**
   * Data do pedido
   */
  date: any | null;
  /**
   * Ciclo que o pedido foi feito
   */
  cicle: string;
  /**
   * Método de pagamento
   */
  paymentMethod: string;
  /**
   * Status do pedido
   */
  status: string;
  /**
   * Valor total do pedido
   */
  total: number | null;
}

export interface ResellersOrderPaginated_ResellersOrderListPaginated_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface ResellersOrderPaginated_ResellersOrderListPaginated {
  __typename: "ResellersOrderListPaginatedConnection";
  /**
   * Total number of elements
   */
  count: number;
  /**
   * Array of ResellerOrderListResponse
   */
  nodes: ResellersOrderPaginated_ResellersOrderListPaginated_nodes[];
  /**
   * Page information
   */
  pageInfo: ResellersOrderPaginated_ResellersOrderListPaginated_pageInfo;
}

export interface ResellersOrderPaginated {
  /**
   * # Query - ResellersOrderListPaginated
   * ## Description:
   * 
   * 
   *  - Traz a listagem dos pedidos da Revendedoras com paginação
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## External Dependencies:
   * 
   * 
   *  - ### ResellerOrderHttpDataSource
   *     
   * 
   * 	
   * 	 - Endpoint Path: /grb/sb/revendedoras/${resellerId}/vendas
   * ## Internal Dependencies:
   * 
   * 
   *  - ### ResellerOrderListPaginatedUseCase
   */
  ResellersOrderListPaginated: ResellersOrderPaginated_ResellersOrderListPaginated;
}

export interface ResellersOrderPaginatedVariables {
  resellerId: string;
  offset?: number | null;
  limit?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FvcSchedulingList
// ====================================================

export interface FvcSchedulingList_FvcSchedulingList_nodes {
  __typename: "FvcSchedulingResponse";
  /**
   * Id
   */
  id: string;
  /**
   * Nome da revendedora
   */
  resellerName: string | null;
  /**
   * Id da revendedora
   */
  resellerId: string;
  /**
   * Horário de conclusão do agendamento
   */
  completedAt: any | null;
  /**
   * Horário do agendamento
   */
  scheduledTo: any;
  /**
   * Situação do registro
   */
  active: boolean;
}

export interface FvcSchedulingList_FvcSchedulingList_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface FvcSchedulingList_FvcSchedulingList {
  __typename: "FvcSchedulingPaginatedResponse";
  /**
   * Total number of elements
   */
  count: number;
  /**
   * Array of FvcSchedulingResponse
   */
  nodes: FvcSchedulingList_FvcSchedulingList_nodes[];
  /**
   * Page information
   */
  pageInfo: FvcSchedulingList_FvcSchedulingList_pageInfo;
}

export interface FvcSchedulingList {
  /**
   * # Query - FvcSchedulingList
   * ## Description:
   * 
   * 
   *  - Traz a lista de agendamentos
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## Internal Dependencies:
   * 
   * 
   *  - ### FvcListSchedulingUseCase
   *     
   * 
   * 
   *  - ### FvcSchedulingDbDatasource
   *     
   * 
   * 
   *  - ### FvcStructureDbDataSource
   */
  FvcSchedulingList: FvcSchedulingList_FvcSchedulingList;
}

export interface FvcSchedulingListVariables {
  offset?: number | null;
  limit?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Templates
// ====================================================

export interface Templates_Templates_templates_components_buttons {
  __typename: "ApprovedTemplateButtonResponse";
  type: string | null;
  text: string | null;
  phoneNumber: string | null;
  url: string | null;
}

export interface Templates_Templates_templates_components {
  __typename: "ApprovedTemplateComponentResponse";
  type: string | null;
  text: string | null;
  format: string | null;
  buttons: Templates_Templates_templates_components_buttons[] | null;
}

export interface Templates_Templates_templates {
  __typename: "TemplateResponse";
  id: string;
  components: Templates_Templates_templates_components[] | null;
  name: string | null;
  status: string | null;
}

export interface Templates_Templates_pageInfo {
  __typename: "ApprovedTemplatePageInfoResponse";
  nextPageToken: string | null;
  previousPageToken: string | null;
}

export interface Templates_Templates {
  __typename: "ApprovedTemplateListResponse";
  templates: Templates_Templates_templates[];
  pageInfo: Templates_Templates_pageInfo;
}

export interface Templates {
  /**
   * # Query - Templates
   * ## Description:
   * 
   * 
   *  - Lista templates de mensagens de Whatsapp, exceto os rejeitados
   * ## Request Headers:
   * 
   * ### Authorization
   * 
   * 
   *  - Authentication token
   * 
   * ### x-authentication-gera
   * 
   * 
   *  - GERA authentication token
   * ## External Dependencies:
   * 
   * 
   *  - ### InstantMessageHttpDatasource
   *     
   * 
   * 	
   * 	 - Endpoint Path: /whatsapp/templates
   * ## Internal Dependencies:
   * 
   * 
   *  - ### ListTemplatesUseCase
   *     
   * 
   * 
   *  - ### FvcStructureDbDataSource
   */
  Templates: Templates_Templates | null;
}

export interface TemplatesVariables {
  filters?: InstantMessageTemplatesInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TitleDetail
// ====================================================

export interface TitleDetail_TitleDetail_paymentPlan {
  __typename: "TitlePaymentPlanType";
  /**
   * descrição
   */
  description: string | null;
}

export interface TitleDetail_TitleDetail {
  __typename: "TitleDetailResponse";
  /**
   * Id
   */
  id: string;
  /**
   * Id da revendedora
   */
  resellerId: string | null;
  /**
   * Id do título
   */
  titleId: string | null;
  /**
   * Data de emissão
   */
  issueDate: any | null;
  /**
   * Data de vencimento
   */
  dueDate: any | null;
  /**
   * Valor
   */
  value: number | null;
  /**
   * Dias de atraso
   */
  daysOfDelay: number | null;
  /**
   * Situação do pagamento
   */
  paymentPosition: string | null;
  /**
   * Situação do título
   */
  titlePosition: string | null;
  /**
   * Plano de pagamento
   */
  paymentPlan: TitleDetail_TitleDetail_paymentPlan | null;
  /**
   * Link do Arquivo
   */
  fileLink: string | null;
}

export interface TitleDetail {
  /**
   * Detalhe do título
   */
  TitleDetail: TitleDetail_TitleDetail;
}

export interface TitleDetailVariables {
  titleId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TopCategories
// ====================================================

export interface TopCategories_ResellerCRM {
  __typename: "ResellerCRMResponse";
  /**
   * Código da revendedora
   */
  id: string;
  /**
   * Objetivo da RE
   */
  goal: string | null;
  /**
   * Top Categoria 1
   */
  topCategory1: string | null;
  /**
   * Top Categoria 2
   */
  topCategory2: string | null;
}

export interface TopCategories_ResellerActivationCommunication {
  __typename: "ResellerActivationCommunicationResponse";
  /**
   * Código Representante
   */
  id: string;
  /**
   * Leitura rápida dos principais argumentos de venda em formato de frase. 
   */
  theme: string | null;
  /**
   * Informações adicionais diversas 
   */
  additionalInfo: string | null;
  /**
   * Campanha especifica para a representante 
   */
  campaignFocus: string | null;
}

export interface TopCategories {
  /**
   * Informações de CRM da revendedora
   */
  ResellerCRM: TopCategories_ResellerCRM;
  /**
   * Plano de comunicação com a representante
   */
  ResellerActivationCommunication: TopCategories_ResellerActivationCommunication;
}

export interface TopCategoriesVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: MessageReceived
// ====================================================

export interface MessageReceived_MessageReceived_lastMessage {
  __typename: "MessageResponse";
  /**
   * Identificador da mensagem
   */
  id: string;
  /**
   * Telefone remetente
   */
  from: string;
  /**
   * Telefone destino
   */
  to: string;
  /**
   * Tipo da mensagem
   */
  type: MessageType | null;
  /**
   * Conteúdo da mensagem
   */
  message: string | null;
  /**
   * URL da imagem/áudio
   */
  mediaUrl: string | null;
  /**
   * Status
   */
  status: MessageStatus;
  /**
   * Data de envio (UTC)
   */
  sentAt: any | null;
}

export interface MessageReceived_MessageReceived {
  __typename: "ResellerInteractionResponse";
  /**
   * ID da revendedora (se ela foi identificada)
   */
  resellerId: string | null;
  /**
   * Nome da revendedora (se ela foi identificada)
   */
  name: string | null;
  /**
   * Telefone da revendedora
   */
  cellphone: string;
  /**
   * Indica se está dentro da janela de 24 horas
   */
  isMinorThan24Hours: boolean;
  /**
   * Status do atendimento via WhatsApp
   */
  status: ResellerInteractionStatus;
  /**
   * Data da última mensagem enviada pela revendedora (UTC)
   */
  lastResellerMessageAt: any;
  /**
   * Última mensagem trocada
   */
  lastMessage: MessageReceived_MessageReceived_lastMessage;
}

export interface MessageReceived {
  MessageReceived: MessageReceived_MessageReceived;
}

export interface MessageReceivedVariables {
  resellerId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: StatusReceived
// ====================================================

export interface StatusReceived_StatusReceived {
  __typename: "MessageResponse";
  /**
   * Identificador da mensagem
   */
  id: string;
  /**
   * Telefone remetente
   */
  from: string;
  /**
   * Telefone destino
   */
  to: string;
  /**
   * Tipo da mensagem
   */
  type: MessageType | null;
  /**
   * Conteúdo da mensagem
   */
  message: string | null;
  /**
   * URL da imagem/áudio
   */
  mediaUrl: string | null;
  /**
   * Status
   */
  status: MessageStatus;
  /**
   * Data de envio (UTC)
   */
  sentAt: any | null;
}

export interface StatusReceived {
  StatusReceived: StatusReceived_StatusReceived;
}

export interface StatusReceivedVariables {
  resellerId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Answers
// ====================================================

export interface Answers {
  __typename: "AdminAnswerResponse";
  answer: string;
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CommercialTimeResponse
// ====================================================

export interface CommercialTimeResponse {
  __typename: "CommercialTimeResponse";
  id: number;
  weekDay: number;
  startHour: string;
  stopHour: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FeedbackResponse
// ====================================================

export interface FeedbackResponse {
  __typename: "FeedbackResponse";
  id: number | null;
  title: string;
  /**
   * Sinaliza se feedback terá campo de forma de contato
   */
  hasContactForm: boolean;
  /**
   * Sinaliza se o campo possui agendamento
   */
  hasScheduling: boolean | null;
  /**
   * Sinaliza se feedback terá campo de observações
   */
  hasNote: boolean;
  /**
   * Sinaliza se o campo de observação é obrigatório
   */
  isRequiredNote: boolean | null;
  /**
   * Sinaliza para feedbacks de Ativação se eles são visíveis para adimplentes e inadimplentes
   */
  visibility: ActivationFeedbackVisibilty | null;
  /**
   * Código usado na tabulação do Gera
   */
  geraCode: string | null;
  /**
   * Código usado na tabulação do NCall
   */
  nCallCode: string | null;
  /**
   * Prioridade de ordenação
   */
  priority: number | null;
  active: boolean | null;
  /**
   * Detalhe do subnível de tabulação
   */
  details: string[] | null;
  /**
   * Sinaliza se o feedback não é tabulado no gera
   */
  noTabulation: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FeedbackBaseType
// ====================================================

export interface FeedbackBaseType {
  __typename: "FeedbackBaseType";
  id: number | null;
  title: string;
  /**
   * Sinaliza se feedback terá campo de forma de contato
   */
  hasContactForm: boolean;
  /**
   * Sinaliza se o campo possui agendamento
   */
  hasScheduling: boolean | null;
  /**
   * Sinaliza se feedback terá campo de observações
   */
  hasNote: boolean;
  /**
   * Sinaliza se o campo de observação é obrigatório
   */
  isRequiredNote: boolean | null;
  /**
   * Sinaliza para feedbacks de Ativação se eles são visíveis para adimplentes e inadimplentes
   */
  visibility: ActivationFeedbackVisibilty | null;
  /**
   * Código usado na tabulação do Gera
   */
  geraCode: string | null;
  /**
   * Código usado na tabulação do NCall
   */
  nCallCode: string | null;
  /**
   * Prioridade de ordenação
   */
  priority: number | null;
  active: boolean | null;
  /**
   * Sinaliza se o feedback não é tabulado no gera
   */
  noTabulation: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CategoryResponse
// ====================================================

export interface CategoryResponse_feedbacks {
  __typename: "FeedbackResponse";
  id: number | null;
  title: string;
  /**
   * Sinaliza se feedback terá campo de forma de contato
   */
  hasContactForm: boolean;
  /**
   * Sinaliza se o campo possui agendamento
   */
  hasScheduling: boolean | null;
  /**
   * Sinaliza se feedback terá campo de observações
   */
  hasNote: boolean;
  /**
   * Sinaliza se o campo de observação é obrigatório
   */
  isRequiredNote: boolean | null;
  /**
   * Sinaliza para feedbacks de Ativação se eles são visíveis para adimplentes e inadimplentes
   */
  visibility: ActivationFeedbackVisibilty | null;
  /**
   * Código usado na tabulação do Gera
   */
  geraCode: string | null;
  /**
   * Código usado na tabulação do NCall
   */
  nCallCode: string | null;
  /**
   * Prioridade de ordenação
   */
  priority: number | null;
  active: boolean | null;
  /**
   * Detalhe do subnível de tabulação
   */
  details: string[] | null;
  /**
   * Sinaliza se o feedback não é tabulado no gera
   */
  noTabulation: boolean | null;
}

export interface CategoryResponse {
  __typename: "FeedbackCategoryItemsResponse";
  id: number;
  title: string;
  active: boolean;
  page: FvcPage;
  priority: number | null;
  feedbacks: CategoryResponse_feedbacks[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ListPublicMediaResponse
// ====================================================

export interface ListPublicMediaResponse_medias {
  __typename: "ShowPublicMediaResponse";
  id: number;
  /**
   * Nome da mídia
   */
  name: string;
  /**
   * Descrição da mídia
   */
  description: string | null;
  /**
   * ID da mídia
   */
  mediaId: string;
  /**
   * Tipo de arquivo
   */
  type: string;
}

export interface ListPublicMediaResponse {
  __typename: "ListPublicMediaResponse";
  /**
   * Items obtidos
   */
  medias: ListPublicMediaResponse_medias[] | null;
  /**
   * Total de items encontrados
   */
  count: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: MessageTemplateResponse
// ====================================================

export interface MessageTemplateResponse_message {
  __typename: "TemplateMessage";
  /**
   * Texto da mensagem do header
   */
  header: string | null;
  /**
   * Texto da mensagem do body
   */
  body: string;
  /**
   * Texto da mensagem do footer
   */
  footer: string | null;
  /**
   * Texto da mensagens dos botões
   */
  buttons: string[] | null;
}

export interface MessageTemplateResponse_messageVariables_header {
  __typename: "Variables";
  /**
   * Identificador/número da variável
   */
  key: string | null;
  /**
   * Índice do botão
   */
  index: number | null;
  /**
   * Valor padrão da variável
   */
  defaultValue: string | null;
  /**
   * Link da media a ser incluida na mensagem
   */
  link: string | null;
  /**
   * Identificador da media a ser incluida na mensagem
   */
  mediaId: string | null;
  /**
   * Tipo da variável
   */
  type: string | null;
  /**
   * Flag que indica se o valor padrão pode ser alterado
   */
  isEditable: boolean;
}

export interface MessageTemplateResponse_messageVariables_body {
  __typename: "Variables";
  /**
   * Identificador/número da variável
   */
  key: string | null;
  /**
   * Índice do botão
   */
  index: number | null;
  /**
   * Valor padrão da variável
   */
  defaultValue: string | null;
  /**
   * Link da media a ser incluida na mensagem
   */
  link: string | null;
  /**
   * Identificador da media a ser incluida na mensagem
   */
  mediaId: string | null;
  /**
   * Tipo da variável
   */
  type: string | null;
  /**
   * Flag que indica se o valor padrão pode ser alterado
   */
  isEditable: boolean;
}

export interface MessageTemplateResponse_messageVariables_buttons {
  __typename: "Variables";
  /**
   * Identificador/número da variável
   */
  key: string | null;
  /**
   * Índice do botão
   */
  index: number | null;
  /**
   * Valor padrão da variável
   */
  defaultValue: string | null;
  /**
   * Link da media a ser incluida na mensagem
   */
  link: string | null;
  /**
   * Identificador da media a ser incluida na mensagem
   */
  mediaId: string | null;
  /**
   * Tipo da variável
   */
  type: string | null;
  /**
   * Flag que indica se o valor padrão pode ser alterado
   */
  isEditable: boolean;
}

export interface MessageTemplateResponse_messageVariables {
  __typename: "TemplateVariables";
  /**
   * Variáveis do header
   */
  header: MessageTemplateResponse_messageVariables_header[] | null;
  /**
   * Variáveis do body
   */
  body: MessageTemplateResponse_messageVariables_body[] | null;
  /**
   * Variáveis do botão
   */
  buttons: MessageTemplateResponse_messageVariables_buttons[] | null;
}

export interface MessageTemplateResponse {
  __typename: "MessageTemplateBaseResponse";
  /**
   * Id do template de mensagem
   */
  id: number | null;
  /**
   * Nome oficial do template de mensagem
   */
  name: string;
  /**
   * Nome amigável do template de mensagem que será exibido
   */
  friendlyName: string;
  /**
   * Mensagem do template
   */
  message: MessageTemplateResponse_message;
  /**
   * Variáveis do template de mensagem
   */
  messageVariables: MessageTemplateResponse_messageVariables | null;
  /**
   * Ambiente que o template está disponível
   */
  environment: TemplateEnvironment;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Variables
// ====================================================

export interface Variables {
  __typename: "Variables";
  /**
   * Identificador/número da variável
   */
  key: string | null;
  /**
   * Índice do botão
   */
  index: number | null;
  /**
   * Valor padrão da variável
   */
  defaultValue: string | null;
  /**
   * Link da media a ser incluida na mensagem
   */
  link: string | null;
  /**
   * Identificador da media a ser incluida na mensagem
   */
  mediaId: string | null;
  /**
   * Tipo da variável
   */
  type: string | null;
  /**
   * Flag que indica se o valor padrão pode ser alterado
   */
  isEditable: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: QueueOptionResponse
// ====================================================

export interface QueueOptionResponse_state {
  __typename: "QueueOptionStateFieldResponse";
  /**
   * Tipo do campo
   */
  type: FvcQueueOptionStateFieldType;
  /**
   * Posição relativa escolhida pelo usuário
   */
  position: number;
  /**
   * Nível do status (se o tipo for um Status)
   */
  statusLevel: ResellerActivationCategoryType | null;
  /**
   * Identificador da categoria (se o tipo for uma categoria)
   */
  categoryId: number | null;
  /**
   * Nível do status Meu Clube Boti (se o tipo for Meu Clube Boti)
   */
  meuClubeBotiLevel: ResellerMeuClubeBotiEnum | null;
}

export interface QueueOptionResponse {
  __typename: "QueueOptionResponse";
  id: number;
  /**
   * Estado atual das configurações da opção sobre a régua
   */
  state: QueueOptionResponse_state[];
  /**
   * Lista de estuturas Gera ao qual a opção está aplicada
   */
  geraStructures: string[];
  /**
   * Indica se a opção está ativada ou não pelo usuário
   */
  isEnabled: boolean;
  /**
   * Nome da opção
   */
  name: string;
  /**
   * Nome livre da opção
   */
  description: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ResellerListByNameResponse
// ====================================================

export interface ResellerListByNameResponse {
  __typename: "ResellerListByNameResponse";
  /**
   * Código da revendedora
   */
  resellerId: string;
  /**
   * Nome da revendedora
   */
  name: string;
  /**
   * Data de nascimento da revendedora
   */
  birthdate: any | null;
  /**
   * Crédito da revendedora
   */
  credit: number | null;
  /**
   * Status da revendedora
   */
  status: ResellerListRegistrationStatus | null;
  /**
   * Origem do cadastro
   */
  origin: ResellerListRegistrationOrigin | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ShowPublicMediaResponse
// ====================================================

export interface ShowPublicMediaResponse {
  __typename: "ShowPublicMediaResponse";
  id: number;
  /**
   * Nome da mídia
   */
  name: string;
  /**
   * Descrição da mídia
   */
  description: string | null;
  /**
   * ID da mídia
   */
  mediaId: string;
  /**
   * Tipo de arquivo
   */
  type: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UpsertFeedbackResponse
// ====================================================

export interface UpsertFeedbackResponse_feedbacks {
  __typename: "FeedbackBaseType";
  id: number | null;
  title: string;
  /**
   * Sinaliza se feedback terá campo de forma de contato
   */
  hasContactForm: boolean;
  /**
   * Sinaliza se o campo possui agendamento
   */
  hasScheduling: boolean | null;
  /**
   * Sinaliza se feedback terá campo de observações
   */
  hasNote: boolean;
  /**
   * Sinaliza se o campo de observação é obrigatório
   */
  isRequiredNote: boolean | null;
  /**
   * Sinaliza para feedbacks de Ativação se eles são visíveis para adimplentes e inadimplentes
   */
  visibility: ActivationFeedbackVisibilty | null;
  /**
   * Código usado na tabulação do Gera
   */
  geraCode: string | null;
  /**
   * Código usado na tabulação do NCall
   */
  nCallCode: string | null;
  /**
   * Prioridade de ordenação
   */
  priority: number | null;
  active: boolean | null;
  /**
   * Sinaliza se o feedback não é tabulado no gera
   */
  noTabulation: boolean | null;
}

export interface UpsertFeedbackResponse {
  __typename: "UpsertFeedbackResponse";
  id: number;
  title: string;
  active: boolean;
  page: FvcPage;
  /**
   * Prioridade de ordenação
   */
  priority: number | null;
  feedbacks: UpsertFeedbackResponse_feedbacks[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: activity
// ====================================================

export interface activity {
  __typename: "DashboardResellerActivationCategoryTotalType";
  /**
   * Categoria de Ativação
   */
  activationCategory: ResellerActivationCategoryType;
  /**
   * Quantidade de revendedores no início do ciclo
   */
  openingQuantity: number;
  /**
   * Quantidade de revendedores atualmente
   */
  quantity: number;
  /**
   * Porcentagem nesta categoria no início do ciclo
   */
  openingPercentage: number;
  /**
   * Porcentagem nesta categoria atualmente
   */
  percentage: number;
  /**
   * Porcentagem de revendedores que saíram da categoria atual e foram para a categoria A0
   */
  recovery: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AddressInfoType
// ====================================================

export interface AddressInfoType {
  __typename: "AddressInfoType";
  addressNumber: string | null;
  cep: string | null;
  city: string | null;
  complement: string | null;
  neighborhood: string | null;
  state: string | null;
  streetName: string | null;
  landmark: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: RegisterAddress
// ====================================================

export interface RegisterAddress {
  __typename: "RegistrationAddressType";
  /**
   * Logradouro
   */
  streetName: string | null;
  /**
   * Número
   */
  addressNumber: string | null;
  /**
   * CEP
   */
  cep: string | null;
  /**
   * Bairro
   */
  neighborhood: string | null;
  /**
   * Complemento
   */
  complement: string | null;
  /**
   * Cidade
   */
  city: string | null;
  /**
   * Estado
   */
  state: string | null;
  /**
   * País
   */
  country: string | null;
  /**
   * Código da estrutura geográfica
   */
  id: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BusinessModelResponse
// ====================================================

export interface BusinessModelResponse_deliveryModes {
  __typename: "DeliveryModeResponse";
  /**
   * Indica se o endereço se trata de um espaço revendedor
   */
  isWithdrawalCenter: boolean;
  /**
   * Identificador do espaço revendedor
   */
  distributionCenterCode: string;
  /**
   * Nome do espaço revendedor
   */
  distributionCenterName: string;
  /**
   * Endereço (de entrega ou do espaço revendedor)
   */
  descriptiveAddress: string;
}

export interface BusinessModelResponse_starterPacks_products {
  __typename: "StarterPackProductResponse";
  /**
   * Identificador do produto
   */
  id: string;
  /**
   * Nome do produto
   */
  name: string;
  /**
   * Descrição do produto
   */
  description: string;
}

export interface BusinessModelResponse_starterPacks {
  __typename: "StarterPackResponse";
  /**
   * Identificador do kit início
   */
  id: string;
  /**
   * Nome do kit início
   */
  name: string;
  /**
   * Descrição do kit início
   */
  description: string;
  /**
   * Quantidade de produtos a serem escolidos para o kit início
   */
  quantity: number;
  /**
   * Produtos disponíveis para escolha do kit início
   */
  products: BusinessModelResponse_starterPacks_products[];
}

export interface BusinessModelResponse {
  __typename: "BusinessModelResponse";
  /**
   * Código
   */
  id: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Descrição
   */
  description: string;
  /**
   * Lucratividade
   */
  profitability: number;
  /**
   * Valor mínimo do pedido
   */
  minimumOrderValue: number;
  /**
   * Opções de modos de entrega
   */
  deliveryModes: BusinessModelResponse_deliveryModes[];
  /**
   * Opções de kit inícios
   */
  starterPacks: BusinessModelResponse_starterPacks[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CardData
// ====================================================

export interface CardData_shareInfo {
  __typename: "SuperplanoShareInfoType";
  /**
   * Texto da promoção
   */
  text: string | null;
  /**
   * Imagem da promoção
   */
  image: string;
}

export interface CardData {
  __typename: "SuperplanoCardResponse";
  /**
   * Código da promoção
   */
  code: string;
  /**
   * Tipo da promoção
   */
  type: SuperplanoCardTypeEnum;
  /**
   * Título da promoção
   */
  title: string;
  /**
   * Tipo da descrição da promoção
   */
  descriptionType: SuperplanoDescriptionType;
  /**
   * Descrição da promoção
   */
  description: string;
  /**
   * Imagem da promoção
   */
  image: string | null;
  /**
   * Data de início da promoção
   */
  startDate: any;
  /**
   * Data de fim da promoção
   */
  endDate: any;
  /**
   * Informações do compartilhamento da promoção
   */
  shareInfo: CardData_shareInfo | null;
  /**
   * Hora de atualização
   */
  updatedAt: any;
  /**
   * Hora de criação
   */
  createdAt: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CardList
// ====================================================

export interface CardList_nodes_shareInfo {
  __typename: "SuperplanoShareInfoType";
  /**
   * Texto da promoção
   */
  text: string | null;
  /**
   * Imagem da promoção
   */
  image: string;
}

export interface CardList_nodes {
  __typename: "SuperplanoCardResponse";
  /**
   * Código da promoção
   */
  code: string;
  /**
   * Tipo da promoção
   */
  type: SuperplanoCardTypeEnum;
  /**
   * Título da promoção
   */
  title: string;
  /**
   * Tipo da descrição da promoção
   */
  descriptionType: SuperplanoDescriptionType;
  /**
   * Descrição da promoção
   */
  description: string;
  /**
   * Imagem da promoção
   */
  image: string | null;
  /**
   * Data de início da promoção
   */
  startDate: any;
  /**
   * Data de fim da promoção
   */
  endDate: any;
  /**
   * Informações do compartilhamento da promoção
   */
  shareInfo: CardList_nodes_shareInfo | null;
  /**
   * Hora de atualização
   */
  updatedAt: any;
  /**
   * Hora de criação
   */
  createdAt: any;
}

export interface CardList_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface CardList {
  __typename: "SuperplanoCardPaginatedResponse";
  /**
   * Total number of elements
   */
  count: number;
  /**
   * Array of SuperplanoCardResponse
   */
  nodes: CardList_nodes[];
  /**
   * Page information
   */
  pageInfo: CardList_pageInfo;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ShareInfo
// ====================================================

export interface ShareInfo {
  __typename: "SuperplanoShareInfoType";
  /**
   * Texto da promoção
   */
  text: string | null;
  /**
   * Imagem da promoção
   */
  image: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CartDetailsResponse
// ====================================================

export interface CartDetailsResponse_totals {
  __typename: "CartTotalsType";
  /**
   * Total de venda
   */
  totalSell: number | null;
  /**
   * Lucro
   */
  profit: number | null;
  /**
   * Frete
   */
  deliveryTax: number | null;
  /**
   * Subtotal
   */
  subtotalPay: number | null;
  /**
   * Total
   */
  totalPay: number | null;
  /**
   * Lucro vindo dos brindes
   */
  profitFromGifts: number | null;
  /**
   * Valor de crédito utilizado da CCR (Conta Corrente Residual)
   */
  checkingAccount: number | null;
  /**
   * Taxa administrativa
   */
  collectionFee: number | null;
  /**
   * Impostos
   */
  taxes: number | null;
  /**
   * Comissão
   */
  commission: number | null;
}

export interface CartDetailsResponse {
  __typename: "CartDetailsType";
  /**
   * Código do pedido
   */
  orderCode: string;
  /**
   * Ciclo do carrinho
   */
  cycle: string;
  /**
   * Subciclo do carrinho
   */
  subcycleId: string | null;
  /**
   * Id do centro de distribuição do carrinho
   */
  distributionCenterCode: string;
  /**
   * Flag que indica retirada na central
   */
  pickupOnDistributionCenter: boolean;
  /**
   * Código do modelo comercial utilizado
   */
  businessModelCode: number;
  /**
   * Totais do carrinho
   */
  totals: CartDetailsResponse_totals;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CicleStatus
// ====================================================

export interface CicleStatus {
  __typename: "ResellerDetailCicleStatusType";
  /**
   * Categoria de Ativação
   */
  activationCategory: ResellerActivationCategoryType | null;
  /**
   * Feedback da Ativação
   */
  activationFeedback: ResellerActivationFeedbackType;
  /**
   * Potencial (farol) da revendedora
   */
  potencial: ResellerActivationPotencialType | null;
  /**
   * Se há um contato agendado
   */
  hasScheduledContact: boolean;
  /**
   * Data e hora do contato agendado (UTC)
   */
  scheduledContact: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ComboType
// ====================================================

export interface ComboType_products {
  __typename: "ComboProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome do produto
   */
  name: string;
  /**
   * Url da imagem (tamanho P)
   */
  imageUrl: string;
  /**
   * Se há quantidade disponível deste produto
   */
  available: boolean;
  /**
   * Quantidade do produto no combo
   */
  quantity: number;
  /**
   * Se a quantidade do produto no combo é fixa
   */
  quantityBlocked: boolean;
}

export interface ComboType_productSelections_products {
  __typename: "ComboProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome do produto
   */
  name: string;
  /**
   * Url da imagem (tamanho P)
   */
  imageUrl: string;
  /**
   * Se há quantidade disponível deste produto
   */
  available: boolean;
  /**
   * Quantidade do produto no combo
   */
  quantity: number;
  /**
   * Se a quantidade do produto no combo é fixa
   */
  quantityBlocked: boolean;
}

export interface ComboType_productSelections {
  __typename: "ComboProductSelectionType";
  /**
   * Código da seleção de produto
   */
  code: string;
  /**
   * Descrição da seleção
   */
  description: string;
  /**
   * Se mais produtos podem ser carregados
   */
  loadMore: boolean;
  /**
   * Quantidade de produtos que devem ser selecionados
   */
  quantityPerKit: number;
  /**
   * Produtos que podem ser selecionados
   */
  products: ComboType_productSelections_products[];
}

export interface ComboType {
  __typename: "ComboType";
  /**
   * Código do combo
   */
  code: string;
  /**
   * Nome do combo
   */
  name: string;
  /**
   * Descrição do combo
   */
  description: string | null;
  /**
   * Mensagem do Combo
   */
  message: string | null;
  /**
   * Produtos fixos do combo
   */
  products: ComboType_products[];
  /**
   * Seleções de produtos personalizáveis
   */
  productSelections: ComboType_productSelections[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ComboProductType
// ====================================================

export interface ComboProductType {
  __typename: "ComboProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome do produto
   */
  name: string;
  /**
   * Url da imagem (tamanho P)
   */
  imageUrl: string;
  /**
   * Se há quantidade disponível deste produto
   */
  available: boolean;
  /**
   * Quantidade do produto no combo
   */
  quantity: number;
  /**
   * Se a quantidade do produto no combo é fixa
   */
  quantityBlocked: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ComboProductSelectionType
// ====================================================

export interface ComboProductSelectionType_products {
  __typename: "ComboProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome do produto
   */
  name: string;
  /**
   * Url da imagem (tamanho P)
   */
  imageUrl: string;
  /**
   * Se há quantidade disponível deste produto
   */
  available: boolean;
  /**
   * Quantidade do produto no combo
   */
  quantity: number;
  /**
   * Se a quantidade do produto no combo é fixa
   */
  quantityBlocked: boolean;
}

export interface ComboProductSelectionType {
  __typename: "ComboProductSelectionType";
  /**
   * Código da seleção de produto
   */
  code: string;
  /**
   * Descrição da seleção
   */
  description: string;
  /**
   * Se mais produtos podem ser carregados
   */
  loadMore: boolean;
  /**
   * Quantidade de produtos que devem ser selecionados
   */
  quantityPerKit: number;
  /**
   * Produtos que podem ser selecionados
   */
  products: ComboProductSelectionType_products[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Conversation
// ====================================================

export interface Conversation {
  __typename: "FvcResellerConversationResponse";
  /**
   * Id
   */
  id: string;
  /**
   * Id da revendedora
   */
  resellerId: string;
  /**
   * Nome da revendedora
   */
  resellerName: string | null;
  /**
   * Horário de Início do Atendimento
   */
  createdAt: any;
  /**
   * Horário da finalização do Atendimento
   */
  completedAt: any | null;
  /**
   * Indica se atendimento retornado é um agendamento
   */
  isScheduling: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CustomFeedbackType
// ====================================================

export interface CustomFeedbackType {
  __typename: "FeedbackResponse";
  id: number | null;
  title: string;
  /**
   * Sinaliza se feedback terá campo de forma de contato
   */
  hasContactForm: boolean;
  /**
   * Sinaliza se feedback terá campo de observações
   */
  hasNote: boolean;
  /**
   * Código usado na tabulação do Gera
   */
  geraCode: string | null;
  active: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CycleResponse
// ====================================================

export interface CycleResponse_subCycles {
  __typename: "SubCycleType";
  /**
   * Id do subciclo
   */
  id: string;
  /**
   * Flag de subciclo atual
   */
  isActive: boolean;
  /**
   * Inicio do subciclo
   */
  start: any;
  /**
   * Fim do subciclo
   */
  end: any;
}

export interface CycleResponse {
  __typename: "CycleResponse";
  /**
   * Id do ciclo selecionado atualmente
   */
  id: string;
  /**
   * Id da estrutura comercial do ciclo
   */
  commercialStructure: string | null;
  /**
   * Data de início do ciclo selecionado atualmente
   */
  start: any;
  /**
   * Data de fim do ciclo selecionado atualmente
   */
  end: any;
  /**
   * Subciclos
   */
  subCycles: CycleResponse_subCycles[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Cycle
// ====================================================

export interface Cycle {
  __typename: "DashboardCicleResponse";
  /**
   * Id do ciclo selecionado atualmente
   */
  currentCicleId: string;
  /**
   * Id do próximo ciclo
   */
  nextCicleId: string | null;
  /**
   * Id do ciclo anterior
   */
  previousCicleId: string | null;
  /**
   * Data de início do ciclo selecionado atualmente
   */
  currentCicleStartDate: any;
  /**
   * Data de fim do ciclo selecionado atualmente
   */
  currentCicleEndDate: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DashboardValues
// ====================================================

export interface DashboardValues {
  __typename: "DashboardValueDailyGoalType";
  /**
   * Valor
   */
  value: number | null;
  /**
   * Meta
   */
  goal: number;
  /**
   * Meta até o dia atual
   */
  goalForToday: number | null;
  /**
   * Valor restante para atingir a meta no dia de hoje
   */
  pendingForTodayGoal: number | null;
  /**
   * Última atualização do indicador
   */
  lastSync: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: deliveryModes
// ====================================================

export interface deliveryModes_modes {
  __typename: "CartInitialParamsDeliveryModeResponse";
  /**
   * Código da central
   */
  modeId: string | null;
  /**
   * Modo de retirada
   */
  withdraw: boolean | null;
  /**
   * Endereço de entrega/retirada
   */
  address: string | null;
}

export interface deliveryModes {
  __typename: "CartInitialParamsDeliveryResponse";
  /**
   * Código da central
   */
  id: string | null;
  /**
   * Modos de entrega
   */
  modes: deliveryModes_modes[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DocumentStatusResponse
// ====================================================

export interface DocumentStatusResponse {
  __typename: "DocumentStatusResponse";
  /**
   * tipo do documento
   */
  document: DocumentTypeModel;
  /**
   * Status do documento
   */
  status: DocumentCheckModel;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FvcConversationHistoryItemResponse
// ====================================================

export interface FvcConversationHistoryItemResponse {
  __typename: "FvcConversationHistoryItemResponse";
  /**
   * Id
   */
  id: string;
  /**
   * Id da revendedora
   */
  resellerId: string;
  /**
   * Nome da revendedora
   */
  resellerName: string | null;
  /**
   * Id da supervisora
   */
  supervisorId: string;
  /**
   * Horário de Início do Atendimento
   */
  createdAt: any | null;
  /**
   * Horário da finalização do Atendimento
   */
  completedAt: any | null;
  /**
   * Título do Feedback
   */
  feedbackTitle: string | null;
  /**
   * Título do Feedback customizado
   */
  customTitle: string | null;
  /**
   * Notas do feedback
   */
  feedbackNote: string | null;
  /**
   * Atendimento ativo ou não
   */
  isActive: boolean | null;
  /**
   * Horário de abertura do agendamento
   */
  schedulingOpening: any | null;
  /**
   * Horário de fechamento do agendamento
   */
  schedulingClosing: any | null;
  /**
   * Ciclo em que o atendimento foi realizado
   */
  cycle: string | null;
  /**
   * Ciclo em que o atendimento foi realizado
   */
  status: ConversationStatusEnum | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GanheMaisPrizeResponse
// ====================================================

export interface GanheMaisPrizeResponse_replacements {
  __typename: "GanheMaisPrizeReplacementResponse";
  code: string | null;
  image: string | null;
  name: string | null;
}

export interface GanheMaisPrizeResponse {
  __typename: "GanheMaisPrizeResponse";
  code: string | null;
  image: string | null;
  name: string | null;
  quantity: number | null;
  replacements: GanheMaisPrizeResponse_replacements[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GanheMaisTierResponse
// ====================================================

export interface GanheMaisTierResponse_prizes_replacements {
  __typename: "GanheMaisPrizeReplacementResponse";
  code: string | null;
  image: string | null;
  name: string | null;
}

export interface GanheMaisTierResponse_prizes {
  __typename: "GanheMaisPrizeResponse";
  code: string | null;
  image: string | null;
  name: string | null;
  quantity: number | null;
  replacements: GanheMaisTierResponse_prizes_replacements[];
}

export interface GanheMaisTierResponse {
  __typename: "GanheMaisTierResponse";
  startValue: number;
  endValue: number | null;
  missingValue: number;
  acquired: boolean;
  logicalOperator: GanheMaisLogicalOperator | null;
  prizes: GanheMaisTierResponse_prizes[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: InstantMessageTemplateResponseFragment
// ====================================================

export interface InstantMessageTemplateResponseFragment_message {
  __typename: "TemplateMessage";
  /**
   * Texto da mensagem do header
   */
  header: string | null;
  /**
   * Texto da mensagem do body
   */
  body: string;
  /**
   * Texto da mensagem do footer
   */
  footer: string | null;
  /**
   * Texto da mensagens dos botões
   */
  buttons: string[] | null;
}

export interface InstantMessageTemplateResponseFragment_messageVariables_header {
  __typename: "Variables";
  /**
   * Identificador/número da variável
   */
  key: string | null;
  /**
   * Índice do botão
   */
  index: number | null;
  /**
   * Valor padrão da variável
   */
  defaultValue: string | null;
  /**
   * Link da media a ser incluida na mensagem
   */
  link: string | null;
  /**
   * Identificador da media a ser incluida na mensagem
   */
  mediaId: string | null;
  /**
   * Tipo da variável
   */
  type: string | null;
  /**
   * Flag que indica se o valor padrão pode ser alterado
   */
  isEditable: boolean;
}

export interface InstantMessageTemplateResponseFragment_messageVariables_body {
  __typename: "Variables";
  /**
   * Identificador/número da variável
   */
  key: string | null;
  /**
   * Índice do botão
   */
  index: number | null;
  /**
   * Valor padrão da variável
   */
  defaultValue: string | null;
  /**
   * Link da media a ser incluida na mensagem
   */
  link: string | null;
  /**
   * Identificador da media a ser incluida na mensagem
   */
  mediaId: string | null;
  /**
   * Tipo da variável
   */
  type: string | null;
  /**
   * Flag que indica se o valor padrão pode ser alterado
   */
  isEditable: boolean;
}

export interface InstantMessageTemplateResponseFragment_messageVariables_buttons {
  __typename: "Variables";
  /**
   * Identificador/número da variável
   */
  key: string | null;
  /**
   * Índice do botão
   */
  index: number | null;
  /**
   * Valor padrão da variável
   */
  defaultValue: string | null;
  /**
   * Link da media a ser incluida na mensagem
   */
  link: string | null;
  /**
   * Identificador da media a ser incluida na mensagem
   */
  mediaId: string | null;
  /**
   * Tipo da variável
   */
  type: string | null;
  /**
   * Flag que indica se o valor padrão pode ser alterado
   */
  isEditable: boolean;
}

export interface InstantMessageTemplateResponseFragment_messageVariables {
  __typename: "TemplateVariables";
  /**
   * Variáveis do header
   */
  header: InstantMessageTemplateResponseFragment_messageVariables_header[] | null;
  /**
   * Variáveis do body
   */
  body: InstantMessageTemplateResponseFragment_messageVariables_body[] | null;
  /**
   * Variáveis do botão
   */
  buttons: InstantMessageTemplateResponseFragment_messageVariables_buttons[] | null;
}

export interface InstantMessageTemplateResponseFragment {
  __typename: "MessageTemplateBaseResponse";
  /**
   * Id do template de mensagem
   */
  id: number | null;
  /**
   * Nome oficial do template de mensagem
   */
  name: string;
  /**
   * Nome amigável do template de mensagem que será exibido
   */
  friendlyName: string;
  /**
   * Mensagem do template
   */
  message: InstantMessageTemplateResponseFragment_message;
  /**
   * Variáveis do template de mensagem
   */
  messageVariables: InstantMessageTemplateResponseFragment_messageVariables | null;
  /**
   * Ambiente que o template está disponível
   */
  environment: TemplateEnvironment;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TemplateVariables
// ====================================================

export interface TemplateVariables_header {
  __typename: "Variables";
  /**
   * Identificador/número da variável
   */
  key: string | null;
  /**
   * Índice do botão
   */
  index: number | null;
  /**
   * Valor padrão da variável
   */
  defaultValue: string | null;
  /**
   * Link da media a ser incluida na mensagem
   */
  link: string | null;
  /**
   * Identificador da media a ser incluida na mensagem
   */
  mediaId: string | null;
  /**
   * Tipo da variável
   */
  type: string | null;
  /**
   * Flag que indica se o valor padrão pode ser alterado
   */
  isEditable: boolean;
}

export interface TemplateVariables_body {
  __typename: "Variables";
  /**
   * Identificador/número da variável
   */
  key: string | null;
  /**
   * Índice do botão
   */
  index: number | null;
  /**
   * Valor padrão da variável
   */
  defaultValue: string | null;
  /**
   * Link da media a ser incluida na mensagem
   */
  link: string | null;
  /**
   * Identificador da media a ser incluida na mensagem
   */
  mediaId: string | null;
  /**
   * Tipo da variável
   */
  type: string | null;
  /**
   * Flag que indica se o valor padrão pode ser alterado
   */
  isEditable: boolean;
}

export interface TemplateVariables_buttons {
  __typename: "Variables";
  /**
   * Identificador/número da variável
   */
  key: string | null;
  /**
   * Índice do botão
   */
  index: number | null;
  /**
   * Valor padrão da variável
   */
  defaultValue: string | null;
  /**
   * Link da media a ser incluida na mensagem
   */
  link: string | null;
  /**
   * Identificador da media a ser incluida na mensagem
   */
  mediaId: string | null;
  /**
   * Tipo da variável
   */
  type: string | null;
  /**
   * Flag que indica se o valor padrão pode ser alterado
   */
  isEditable: boolean;
}

export interface TemplateVariables {
  __typename: "TemplateVariables";
  /**
   * Variáveis do header
   */
  header: TemplateVariables_header[] | null;
  /**
   * Variáveis do body
   */
  body: TemplateVariables_body[] | null;
  /**
   * Variáveis do botão
   */
  buttons: TemplateVariables_buttons[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TemplateMessage
// ====================================================

export interface TemplateMessage {
  __typename: "TemplateMessage";
  /**
   * Texto da mensagem do header
   */
  header: string | null;
  /**
   * Texto da mensagem do body
   */
  body: string;
  /**
   * Texto da mensagem do footer
   */
  footer: string | null;
  /**
   * Texto da mensagens dos botões
   */
  buttons: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: NewsListResponse
// ====================================================

export interface NewsListResponse_news {
  __typename: "NewsResponse";
  id: string;
  title: string;
  message: string;
  /**
   * Nível de criticidade
   */
  criticalityLevel: NewsCriticalityLevel;
  /**
   * Projeto pertencente
   */
  environments: NewsEnvironment[];
  /**
   * Indica se a notícia é pública, isto é, pode ser acessada sem fazer login
   */
  isPublic: boolean;
  /**
   * Indica se a notícia está ativada e por isso pode ser visualizada pelos usuários
   */
  isEnable: boolean;
  /**
   * Lista de URLs vinculadas a notícia
   */
  references: string[];
}

export interface NewsListResponse {
  __typename: "NewsListResponse";
  news: NewsListResponse_news[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Message
// ====================================================

export interface Message {
  __typename: "MessageResponse";
  /**
   * Identificador da mensagem
   */
  id: string;
  /**
   * Telefone remetente
   */
  from: string;
  /**
   * Telefone destino
   */
  to: string;
  /**
   * Tipo da mensagem
   */
  type: MessageType | null;
  /**
   * Conteúdo da mensagem
   */
  message: string | null;
  /**
   * URL da imagem/áudio
   */
  mediaUrl: string | null;
  /**
   * Status
   */
  status: MessageStatus;
  /**
   * Data de envio (UTC)
   */
  sentAt: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: MessagePagination
// ====================================================

export interface MessagePagination {
  __typename: "InstantMessagesPaginationType";
  /**
   * Total de mensagens
   */
  totalItems: number;
  /**
   * Total de páginas
   */
  pages: number;
  /**
   * Página atual
   */
  currentPage: number;
  /**
   * Mensagens por página
   */
  itemsOnPage: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ResellerInteraction
// ====================================================

export interface ResellerInteraction_lastMessage {
  __typename: "MessageResponse";
  /**
   * Identificador da mensagem
   */
  id: string;
  /**
   * Telefone remetente
   */
  from: string;
  /**
   * Telefone destino
   */
  to: string;
  /**
   * Tipo da mensagem
   */
  type: MessageType | null;
  /**
   * Conteúdo da mensagem
   */
  message: string | null;
  /**
   * URL da imagem/áudio
   */
  mediaUrl: string | null;
  /**
   * Status
   */
  status: MessageStatus;
  /**
   * Data de envio (UTC)
   */
  sentAt: any | null;
}

export interface ResellerInteraction {
  __typename: "ResellerInteractionResponse";
  /**
   * ID da revendedora (se ela foi identificada)
   */
  resellerId: string | null;
  /**
   * Nome da revendedora (se ela foi identificada)
   */
  name: string | null;
  /**
   * Telefone da revendedora
   */
  cellphone: string;
  /**
   * Indica se está dentro da janela de 24 horas
   */
  isMinorThan24Hours: boolean;
  /**
   * Status do atendimento via WhatsApp
   */
  status: ResellerInteractionStatus;
  /**
   * Data da última mensagem enviada pela revendedora (UTC)
   */
  lastResellerMessageAt: any;
  /**
   * Última mensagem trocada
   */
  lastMessage: ResellerInteraction_lastMessage;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: MultiplePaymentOptionsResponse
// ====================================================

export interface MultiplePaymentOptionsResponse_paymentOptions_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface MultiplePaymentOptionsResponse_paymentOptions_creditCardOptions_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface MultiplePaymentOptionsResponse_paymentOptions_creditCardOptions {
  __typename: "CreditCardOptionType";
  /**
   * Descrição da bandeira do cartão de crédito
   */
  type: string;
  /**
   * Identificador da bandeira do cartão de crédito
   */
  flag: string;
  /**
   * Modo de pagamento para o cartão especificado
   */
  paymentMode: MultiplePaymentOptionsResponse_paymentOptions_creditCardOptions_paymentMode;
  /**
   * Parcelas possíveis de serem escolhidas
   */
  possibleNumberOfInstallments: number[] | null;
}

export interface MultiplePaymentOptionsResponse_paymentOptions_selectedInstallments {
  __typename: "InstallmentType";
  /**
   * Número da parcela
   */
  number: number;
  /**
   * Valor da parcela
   */
  value: number;
  /**
   * Data de vencimento
   */
  dueDate: any;
  /**
   * Indica se está bloqueada
   */
  isBlocked: boolean;
}

export interface MultiplePaymentOptionsResponse_paymentOptions {
  __typename: "PaymentPlanResponse";
  /**
   * Código do plano de pagamento
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Indica se consumirá crédito da revendedora
   */
  willConsumeCredit: boolean;
  /**
   * Indica se ela deve ter saldo de crédito para poder comprar
   */
  shouldVerifyCredit: boolean;
  /**
   * Indica se está bloqueado
   */
  isBlocked: boolean;
  /**
   * Taxa de administração
   */
  tax: number;
  /**
   * Número de parcelas (para planos sem cartão crédito)
   */
  installments: number | null;
  /**
   * Identificador do modo de pagamento
   */
  paymentMode: MultiplePaymentOptionsResponse_paymentOptions_paymentMode | null;
  /**
   * Opções quando pagamento é por cartão de crédito
   */
  creditCardOptions: MultiplePaymentOptionsResponse_paymentOptions_creditCardOptions[] | null;
  /**
   * Parcelas do plano selecionado
   */
  selectedInstallments: MultiplePaymentOptionsResponse_paymentOptions_selectedInstallments[] | null;
  /**
   * Indica se é múltiplas formas de pagamento
   */
  isMultiplePayment: boolean;
}

export interface MultiplePaymentOptionsResponse_creditCardsAvailable_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface MultiplePaymentOptionsResponse_creditCardsAvailable {
  __typename: "CreditCardOptionType";
  /**
   * Descrição da bandeira do cartão de crédito
   */
  type: string;
  /**
   * Identificador da bandeira do cartão de crédito
   */
  flag: string;
  /**
   * Modo de pagamento para o cartão especificado
   */
  paymentMode: MultiplePaymentOptionsResponse_creditCardsAvailable_paymentMode;
  /**
   * Parcelas possíveis de serem escolhidas
   */
  possibleNumberOfInstallments: number[] | null;
}

export interface MultiplePaymentOptionsResponse_paymentOptionsSelected_paymentPlanSelected_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface MultiplePaymentOptionsResponse_paymentOptionsSelected_paymentPlanSelected_creditCardOptions_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface MultiplePaymentOptionsResponse_paymentOptionsSelected_paymentPlanSelected_creditCardOptions {
  __typename: "CreditCardOptionType";
  /**
   * Descrição da bandeira do cartão de crédito
   */
  type: string;
  /**
   * Identificador da bandeira do cartão de crédito
   */
  flag: string;
  /**
   * Modo de pagamento para o cartão especificado
   */
  paymentMode: MultiplePaymentOptionsResponse_paymentOptionsSelected_paymentPlanSelected_creditCardOptions_paymentMode;
  /**
   * Parcelas possíveis de serem escolhidas
   */
  possibleNumberOfInstallments: number[] | null;
}

export interface MultiplePaymentOptionsResponse_paymentOptionsSelected_paymentPlanSelected_selectedInstallments {
  __typename: "InstallmentType";
  /**
   * Número da parcela
   */
  number: number;
  /**
   * Valor da parcela
   */
  value: number;
  /**
   * Data de vencimento
   */
  dueDate: any;
  /**
   * Indica se está bloqueada
   */
  isBlocked: boolean;
}

export interface MultiplePaymentOptionsResponse_paymentOptionsSelected_paymentPlanSelected {
  __typename: "PaymentPlanResponse";
  /**
   * Código do plano de pagamento
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Indica se consumirá crédito da revendedora
   */
  willConsumeCredit: boolean;
  /**
   * Indica se ela deve ter saldo de crédito para poder comprar
   */
  shouldVerifyCredit: boolean;
  /**
   * Indica se está bloqueado
   */
  isBlocked: boolean;
  /**
   * Taxa de administração
   */
  tax: number;
  /**
   * Número de parcelas (para planos sem cartão crédito)
   */
  installments: number | null;
  /**
   * Identificador do modo de pagamento
   */
  paymentMode: MultiplePaymentOptionsResponse_paymentOptionsSelected_paymentPlanSelected_paymentMode | null;
  /**
   * Opções quando pagamento é por cartão de crédito
   */
  creditCardOptions: MultiplePaymentOptionsResponse_paymentOptionsSelected_paymentPlanSelected_creditCardOptions[] | null;
  /**
   * Parcelas do plano selecionado
   */
  selectedInstallments: MultiplePaymentOptionsResponse_paymentOptionsSelected_paymentPlanSelected_selectedInstallments[] | null;
  /**
   * Indica se é múltiplas formas de pagamento
   */
  isMultiplePayment: boolean;
}

export interface MultiplePaymentOptionsResponse_paymentOptionsSelected_paymentPlansAvailable_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface MultiplePaymentOptionsResponse_paymentOptionsSelected_paymentPlansAvailable_creditCardOptions_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface MultiplePaymentOptionsResponse_paymentOptionsSelected_paymentPlansAvailable_creditCardOptions {
  __typename: "CreditCardOptionType";
  /**
   * Descrição da bandeira do cartão de crédito
   */
  type: string;
  /**
   * Identificador da bandeira do cartão de crédito
   */
  flag: string;
  /**
   * Modo de pagamento para o cartão especificado
   */
  paymentMode: MultiplePaymentOptionsResponse_paymentOptionsSelected_paymentPlansAvailable_creditCardOptions_paymentMode;
  /**
   * Parcelas possíveis de serem escolhidas
   */
  possibleNumberOfInstallments: number[] | null;
}

export interface MultiplePaymentOptionsResponse_paymentOptionsSelected_paymentPlansAvailable_selectedInstallments {
  __typename: "InstallmentType";
  /**
   * Número da parcela
   */
  number: number;
  /**
   * Valor da parcela
   */
  value: number;
  /**
   * Data de vencimento
   */
  dueDate: any;
  /**
   * Indica se está bloqueada
   */
  isBlocked: boolean;
}

export interface MultiplePaymentOptionsResponse_paymentOptionsSelected_paymentPlansAvailable {
  __typename: "PaymentPlanResponse";
  /**
   * Código do plano de pagamento
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Indica se consumirá crédito da revendedora
   */
  willConsumeCredit: boolean;
  /**
   * Indica se ela deve ter saldo de crédito para poder comprar
   */
  shouldVerifyCredit: boolean;
  /**
   * Indica se está bloqueado
   */
  isBlocked: boolean;
  /**
   * Taxa de administração
   */
  tax: number;
  /**
   * Número de parcelas (para planos sem cartão crédito)
   */
  installments: number | null;
  /**
   * Identificador do modo de pagamento
   */
  paymentMode: MultiplePaymentOptionsResponse_paymentOptionsSelected_paymentPlansAvailable_paymentMode | null;
  /**
   * Opções quando pagamento é por cartão de crédito
   */
  creditCardOptions: MultiplePaymentOptionsResponse_paymentOptionsSelected_paymentPlansAvailable_creditCardOptions[] | null;
  /**
   * Parcelas do plano selecionado
   */
  selectedInstallments: MultiplePaymentOptionsResponse_paymentOptionsSelected_paymentPlansAvailable_selectedInstallments[] | null;
  /**
   * Indica se é múltiplas formas de pagamento
   */
  isMultiplePayment: boolean;
}

export interface MultiplePaymentOptionsResponse_paymentOptionsSelected_creditCardFlag_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface MultiplePaymentOptionsResponse_paymentOptionsSelected_creditCardFlag {
  __typename: "CreditCardOptionType";
  /**
   * Descrição da bandeira do cartão de crédito
   */
  type: string;
  /**
   * Identificador da bandeira do cartão de crédito
   */
  flag: string;
  /**
   * Modo de pagamento para o cartão especificado
   */
  paymentMode: MultiplePaymentOptionsResponse_paymentOptionsSelected_creditCardFlag_paymentMode;
  /**
   * Parcelas possíveis de serem escolhidas
   */
  possibleNumberOfInstallments: number[] | null;
}

export interface MultiplePaymentOptionsResponse_paymentOptionsSelected_statusPayment {
  __typename: "StatusPaymentType";
  /**
   * Cartão recusado
   */
  refused: boolean | null;
  /**
   * Pagamento cancelado
   */
  cancel: boolean | null;
  /**
   * Pagamento aprovado
   */
  approved: boolean | null;
  /**
   * Em processamento
   */
  notProcess: boolean | null;
  /**
   * Esperando aprovação do pagamento
   */
  waitingApproved: boolean | null;
  /**
   * Em processo de cancelamento
   */
  waitingCancel: boolean | null;
  /**
   * Pagamento com erro
   */
  failed: boolean | null;
}

export interface MultiplePaymentOptionsResponse_paymentOptionsSelected_fraudPrevention {
  __typename: "FraudPreventionType";
  /**
   * App
   */
  app: string;
  /**
   * Session id
   */
  sessionID: string;
}

export interface MultiplePaymentOptionsResponse_paymentOptionsSelected {
  __typename: "PaymentOptionSelectedType";
  /**
   * Id do pagamento
   */
  id: number | null;
  /**
   * Plano de pagamento selecionado
   */
  paymentPlanSelected: MultiplePaymentOptionsResponse_paymentOptionsSelected_paymentPlanSelected;
  /**
   * Plano de pagamento disponíveis
   */
  paymentPlansAvailable: MultiplePaymentOptionsResponse_paymentOptionsSelected_paymentPlansAvailable[] | null;
  /**
   * Código do pagamento
   */
  distributionCodeDetails: number | null;
  /**
   * Valor escolhido
   */
  amount: number;
  /**
   * Informação do cartão
   */
  creditCardFlag: MultiplePaymentOptionsResponse_paymentOptionsSelected_creditCardFlag | null;
  /**
   * Status do pagamento do cartão
   */
  statusPayment: MultiplePaymentOptionsResponse_paymentOptionsSelected_statusPayment | null;
  /**
   * Prevenção de fraude
   */
  fraudPrevention: MultiplePaymentOptionsResponse_paymentOptionsSelected_fraudPrevention | null;
  /**
   * Bloqueado para alteração
   */
  isChangeBlocked: boolean;
  /**
   * Quantidade de parcelas selecionada
   */
  installmentSelected: number | null;
  /**
   * Número do pagamento
   */
  numberPayment: number | null;
}

export interface MultiplePaymentOptionsResponse {
  __typename: "MultiplePaymentOptionsResponse";
  /**
   * Código do pedido
   */
  orderCode: string;
  /**
   * Quantidade máxima de cartões
   */
  maxCardToChoose: number;
  /**
   * Possível editar o pedido
   */
  isOrderEditable: boolean | null;
  /**
   * Opções de pagamento de boleto
   */
  paymentOptions: MultiplePaymentOptionsResponse_paymentOptions[] | null;
  /**
   * Opções de pagamento de cartão
   */
  creditCardsAvailable: MultiplePaymentOptionsResponse_creditCardsAvailable[] | null;
  /**
   * Pagamentos selecionados
   */
  paymentOptionsSelected: MultiplePaymentOptionsResponse_paymentOptionsSelected[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PaymentPlanResponse
// ====================================================

export interface PaymentPlanResponse_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface PaymentPlanResponse_creditCardOptions_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface PaymentPlanResponse_creditCardOptions {
  __typename: "CreditCardOptionType";
  /**
   * Descrição da bandeira do cartão de crédito
   */
  type: string;
  /**
   * Identificador da bandeira do cartão de crédito
   */
  flag: string;
  /**
   * Modo de pagamento para o cartão especificado
   */
  paymentMode: PaymentPlanResponse_creditCardOptions_paymentMode;
  /**
   * Parcelas possíveis de serem escolhidas
   */
  possibleNumberOfInstallments: number[] | null;
}

export interface PaymentPlanResponse_selectedInstallments {
  __typename: "InstallmentType";
  /**
   * Número da parcela
   */
  number: number;
  /**
   * Valor da parcela
   */
  value: number;
  /**
   * Data de vencimento
   */
  dueDate: any;
  /**
   * Indica se está bloqueada
   */
  isBlocked: boolean;
}

export interface PaymentPlanResponse {
  __typename: "PaymentPlanResponse";
  /**
   * Código do plano de pagamento
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Indica se consumirá crédito da revendedora
   */
  willConsumeCredit: boolean;
  /**
   * Indica se ela deve ter saldo de crédito para poder comprar
   */
  shouldVerifyCredit: boolean;
  /**
   * Indica se está bloqueado
   */
  isBlocked: boolean;
  /**
   * Taxa de administração
   */
  tax: number;
  /**
   * Número de parcelas (para planos sem cartão crédito)
   */
  installments: number | null;
  /**
   * Identificador do modo de pagamento
   */
  paymentMode: PaymentPlanResponse_paymentMode | null;
  /**
   * Opções quando pagamento é por cartão de crédito
   */
  creditCardOptions: PaymentPlanResponse_creditCardOptions[] | null;
  /**
   * Parcelas do plano selecionado
   */
  selectedInstallments: PaymentPlanResponse_selectedInstallments[] | null;
  /**
   * Indica se é múltiplas formas de pagamento
   */
  isMultiplePayment: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CreditCardOptionType
// ====================================================

export interface CreditCardOptionType_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface CreditCardOptionType {
  __typename: "CreditCardOptionType";
  /**
   * Descrição da bandeira do cartão de crédito
   */
  type: string;
  /**
   * Identificador da bandeira do cartão de crédito
   */
  flag: string;
  /**
   * Modo de pagamento para o cartão especificado
   */
  paymentMode: CreditCardOptionType_paymentMode;
  /**
   * Parcelas possíveis de serem escolhidas
   */
  possibleNumberOfInstallments: number[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: InstallmentType
// ====================================================

export interface InstallmentType {
  __typename: "InstallmentType";
  /**
   * Número da parcela
   */
  number: number;
  /**
   * Valor da parcela
   */
  value: number;
  /**
   * Data de vencimento
   */
  dueDate: any;
  /**
   * Indica se está bloqueada
   */
  isBlocked: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PaymentOptionSelectedType
// ====================================================

export interface PaymentOptionSelectedType_paymentPlanSelected_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface PaymentOptionSelectedType_paymentPlanSelected_creditCardOptions_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface PaymentOptionSelectedType_paymentPlanSelected_creditCardOptions {
  __typename: "CreditCardOptionType";
  /**
   * Descrição da bandeira do cartão de crédito
   */
  type: string;
  /**
   * Identificador da bandeira do cartão de crédito
   */
  flag: string;
  /**
   * Modo de pagamento para o cartão especificado
   */
  paymentMode: PaymentOptionSelectedType_paymentPlanSelected_creditCardOptions_paymentMode;
  /**
   * Parcelas possíveis de serem escolhidas
   */
  possibleNumberOfInstallments: number[] | null;
}

export interface PaymentOptionSelectedType_paymentPlanSelected_selectedInstallments {
  __typename: "InstallmentType";
  /**
   * Número da parcela
   */
  number: number;
  /**
   * Valor da parcela
   */
  value: number;
  /**
   * Data de vencimento
   */
  dueDate: any;
  /**
   * Indica se está bloqueada
   */
  isBlocked: boolean;
}

export interface PaymentOptionSelectedType_paymentPlanSelected {
  __typename: "PaymentPlanResponse";
  /**
   * Código do plano de pagamento
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Indica se consumirá crédito da revendedora
   */
  willConsumeCredit: boolean;
  /**
   * Indica se ela deve ter saldo de crédito para poder comprar
   */
  shouldVerifyCredit: boolean;
  /**
   * Indica se está bloqueado
   */
  isBlocked: boolean;
  /**
   * Taxa de administração
   */
  tax: number;
  /**
   * Número de parcelas (para planos sem cartão crédito)
   */
  installments: number | null;
  /**
   * Identificador do modo de pagamento
   */
  paymentMode: PaymentOptionSelectedType_paymentPlanSelected_paymentMode | null;
  /**
   * Opções quando pagamento é por cartão de crédito
   */
  creditCardOptions: PaymentOptionSelectedType_paymentPlanSelected_creditCardOptions[] | null;
  /**
   * Parcelas do plano selecionado
   */
  selectedInstallments: PaymentOptionSelectedType_paymentPlanSelected_selectedInstallments[] | null;
  /**
   * Indica se é múltiplas formas de pagamento
   */
  isMultiplePayment: boolean;
}

export interface PaymentOptionSelectedType_paymentPlansAvailable_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface PaymentOptionSelectedType_paymentPlansAvailable_creditCardOptions_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface PaymentOptionSelectedType_paymentPlansAvailable_creditCardOptions {
  __typename: "CreditCardOptionType";
  /**
   * Descrição da bandeira do cartão de crédito
   */
  type: string;
  /**
   * Identificador da bandeira do cartão de crédito
   */
  flag: string;
  /**
   * Modo de pagamento para o cartão especificado
   */
  paymentMode: PaymentOptionSelectedType_paymentPlansAvailable_creditCardOptions_paymentMode;
  /**
   * Parcelas possíveis de serem escolhidas
   */
  possibleNumberOfInstallments: number[] | null;
}

export interface PaymentOptionSelectedType_paymentPlansAvailable_selectedInstallments {
  __typename: "InstallmentType";
  /**
   * Número da parcela
   */
  number: number;
  /**
   * Valor da parcela
   */
  value: number;
  /**
   * Data de vencimento
   */
  dueDate: any;
  /**
   * Indica se está bloqueada
   */
  isBlocked: boolean;
}

export interface PaymentOptionSelectedType_paymentPlansAvailable {
  __typename: "PaymentPlanResponse";
  /**
   * Código do plano de pagamento
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Indica se consumirá crédito da revendedora
   */
  willConsumeCredit: boolean;
  /**
   * Indica se ela deve ter saldo de crédito para poder comprar
   */
  shouldVerifyCredit: boolean;
  /**
   * Indica se está bloqueado
   */
  isBlocked: boolean;
  /**
   * Taxa de administração
   */
  tax: number;
  /**
   * Número de parcelas (para planos sem cartão crédito)
   */
  installments: number | null;
  /**
   * Identificador do modo de pagamento
   */
  paymentMode: PaymentOptionSelectedType_paymentPlansAvailable_paymentMode | null;
  /**
   * Opções quando pagamento é por cartão de crédito
   */
  creditCardOptions: PaymentOptionSelectedType_paymentPlansAvailable_creditCardOptions[] | null;
  /**
   * Parcelas do plano selecionado
   */
  selectedInstallments: PaymentOptionSelectedType_paymentPlansAvailable_selectedInstallments[] | null;
  /**
   * Indica se é múltiplas formas de pagamento
   */
  isMultiplePayment: boolean;
}

export interface PaymentOptionSelectedType_creditCardFlag_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface PaymentOptionSelectedType_creditCardFlag {
  __typename: "CreditCardOptionType";
  /**
   * Descrição da bandeira do cartão de crédito
   */
  type: string;
  /**
   * Identificador da bandeira do cartão de crédito
   */
  flag: string;
  /**
   * Modo de pagamento para o cartão especificado
   */
  paymentMode: PaymentOptionSelectedType_creditCardFlag_paymentMode;
  /**
   * Parcelas possíveis de serem escolhidas
   */
  possibleNumberOfInstallments: number[] | null;
}

export interface PaymentOptionSelectedType_statusPayment {
  __typename: "StatusPaymentType";
  /**
   * Cartão recusado
   */
  refused: boolean | null;
  /**
   * Pagamento cancelado
   */
  cancel: boolean | null;
  /**
   * Pagamento aprovado
   */
  approved: boolean | null;
  /**
   * Em processamento
   */
  notProcess: boolean | null;
  /**
   * Esperando aprovação do pagamento
   */
  waitingApproved: boolean | null;
  /**
   * Em processo de cancelamento
   */
  waitingCancel: boolean | null;
  /**
   * Pagamento com erro
   */
  failed: boolean | null;
}

export interface PaymentOptionSelectedType_fraudPrevention {
  __typename: "FraudPreventionType";
  /**
   * App
   */
  app: string;
  /**
   * Session id
   */
  sessionID: string;
}

export interface PaymentOptionSelectedType {
  __typename: "PaymentOptionSelectedType";
  /**
   * Id do pagamento
   */
  id: number | null;
  /**
   * Plano de pagamento selecionado
   */
  paymentPlanSelected: PaymentOptionSelectedType_paymentPlanSelected;
  /**
   * Plano de pagamento disponíveis
   */
  paymentPlansAvailable: PaymentOptionSelectedType_paymentPlansAvailable[] | null;
  /**
   * Código do pagamento
   */
  distributionCodeDetails: number | null;
  /**
   * Valor escolhido
   */
  amount: number;
  /**
   * Informação do cartão
   */
  creditCardFlag: PaymentOptionSelectedType_creditCardFlag | null;
  /**
   * Status do pagamento do cartão
   */
  statusPayment: PaymentOptionSelectedType_statusPayment | null;
  /**
   * Prevenção de fraude
   */
  fraudPrevention: PaymentOptionSelectedType_fraudPrevention | null;
  /**
   * Bloqueado para alteração
   */
  isChangeBlocked: boolean;
  /**
   * Quantidade de parcelas selecionada
   */
  installmentSelected: number | null;
  /**
   * Número do pagamento
   */
  numberPayment: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: StatusPayment
// ====================================================

export interface StatusPayment {
  __typename: "StatusPaymentType";
  /**
   * Cartão recusado
   */
  refused: boolean | null;
  /**
   * Pagamento cancelado
   */
  cancel: boolean | null;
  /**
   * Pagamento aprovado
   */
  approved: boolean | null;
  /**
   * Em processamento
   */
  notProcess: boolean | null;
  /**
   * Esperando aprovação do pagamento
   */
  waitingApproved: boolean | null;
  /**
   * Em processo de cancelamento
   */
  waitingCancel: boolean | null;
  /**
   * Pagamento com erro
   */
  failed: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: NewsResponse
// ====================================================

export interface NewsResponse {
  __typename: "NewsResponse";
  id: string;
  title: string;
  message: string;
  /**
   * Nível de criticidade
   */
  criticalityLevel: NewsCriticalityLevel;
  /**
   * Projeto pertencente
   */
  environments: NewsEnvironment[];
  /**
   * Indica se a notícia é pública, isto é, pode ser acessada sem fazer login
   */
  isPublic: boolean;
  /**
   * Indica se a notícia está ativada e por isso pode ser visualizada pelos usuários
   */
  isEnable: boolean;
  /**
   * Lista de URLs vinculadas a notícia
   */
  references: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: OverviewResponse
// ====================================================

export interface OverviewResponse {
  __typename: "OverviewResponse";
  count: number;
  active: number;
  receptive: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PageInfo
// ====================================================

export interface PageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PaymentMethodFrequency
// ====================================================

export interface PaymentMethodFrequency {
  __typename: "ResellerDetailPaymentMethodFrequencyType";
  /**
   * % de compras com cartão
   */
  card: number;
  /**
   * % de compras com boleto
   */
  boleto: number;
  /**
   * % de compras com dinheiro
   */
  cash: number;
  /**
   * % de compras misto
   */
  misc: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PersonalInfoType
// ====================================================

export interface PersonalInfoType_contactInfo {
  __typename: "ContactType";
  /**
   * Tipo de Contato
   */
  type: RegistrationInfoContactType | null;
  /**
   * Valor
   */
  value: string | null;
}

export interface PersonalInfoType_documents {
  __typename: "DocumentType";
  /**
   * Tipo do documento
   */
  type: RegistrationInfoDocumentType | null;
  /**
   * Valor
   */
  value: string | null;
}

export interface PersonalInfoType {
  __typename: "PersonalInfoType";
  /**
   * Razão do bloqueio
   */
  birthdate: any | null;
  /**
   * Apelido
   */
  nickname: string | null;
  /**
   * Nome
   */
  name: string | null;
  /**
   * Informação de contato
   */
  contactInfo: PersonalInfoType_contactInfo[] | null;
  /**
   * Sexo
   */
  sex: RegistrationInfoSexType | null;
  /**
   * Documentos
   */
  documents: PersonalInfoType_documents[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ContactType
// ====================================================

export interface ContactType {
  __typename: "ContactType";
  /**
   * Tipo de Contato
   */
  type: RegistrationInfoContactType | null;
  /**
   * Valor
   */
  value: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PreviousCicles
// ====================================================

export interface PreviousCicles {
  __typename: "ResellerDetailPurchaseHistoryCicleType";
  /**
   * Id do ciclo
   */
  cicleId: string;
  /**
   * Valor total de compra no ciclo
   */
  value: number;
  /**
   * Potencial (farol) da revendedora no ciclo
   */
  potencial: ResellerActivationPotencialType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Product
// ====================================================

export interface Product {
  __typename: "ResellerProductResponse";
  /**
   * Nome do produto
   */
  name: string;
  /**
   * Código do produto
   */
  code: string;
  /**
   * Foto do produto
   */
  pictureURL: string;
  /**
   * Preço do produto
   */
  price: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PurchaseHistory
// ====================================================

export interface PurchaseHistory_paymentMethodFrequency {
  __typename: "ResellerDetailPaymentMethodFrequencyType";
  /**
   * % de compras com cartão
   */
  card: number;
  /**
   * % de compras com boleto
   */
  boleto: number;
  /**
   * % de compras com dinheiro
   */
  cash: number;
  /**
   * % de compras misto
   */
  misc: number;
}

export interface PurchaseHistory_previousCicles {
  __typename: "ResellerDetailPurchaseHistoryCicleType";
  /**
   * Id do ciclo
   */
  cicleId: string;
  /**
   * Valor total de compra no ciclo
   */
  value: number;
  /**
   * Potencial (farol) da revendedora no ciclo
   */
  potencial: ResellerActivationPotencialType;
}

export interface PurchaseHistory {
  __typename: "ResellerDetailPurchaseHistoryType";
  /**
   * Valor potencial de compra total
   */
  valueTotalPurchasePotencial: number;
  /**
   * Valor comprado no ciclo atual
   */
  valuePurchasedOnCurrentCicle: number;
  /**
   * Valores comprados nos últimos 6 ciclos anteriores
   */
  valuePurchasedOnLastSixCicles: number;
  /**
   * Frequência média de pedidos por ciclo
   */
  averageOrdersPerCicle: number;
  /**
   * Pedido médio no ciclo
   */
  averageOrderOnCurrentCicle: number;
  /**
   * Status de inadimplência (% de inadimplência)
   */
  welchStatusPercentage: number;
  /**
   * Status de inadimplência (valor do crédito vencido)
   */
  welchStatusExpiredCredit: number;
  /**
   * Frequência de compra delivery (%s) - Número de pedidos
   */
  deliveryOrderFrequency: number;
  /**
   * Frequência de compra na loja (%s) - Número de pedidos
   */
  storeOrderFrequency: number;
  /**
   * Pedido médio para delivery (R$)
   */
  deliveryAverageOrder: number;
  /**
   * Pedido médio para compra na loja (R$)
   */
  storeAverageOrder: number;
  /**
   * Status de inadimplência
   */
  welchStatus: boolean;
  /**
   * Status de perda
   */
  welchIsLoss: boolean;
  /**
   * 6 produtos mais comprados pela revendedora
   */
  paymentMethodFrequency: PurchaseHistory_paymentMethodFrequency;
  /**
   * Histórico de compra dos últimos ciclos
   */
  previousCicles: PurchaseHistory_previousCicles[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ContactInfo
// ====================================================

export interface ContactInfo {
  __typename: "ResellerContactInfoResponse";
  /**
   * Endereço da revendedora formatado cadastrado no "Gera"
   */
  address: string | null;
  /**
   * Outros telefones de contatos, incluindo os já retornados nos outros campos
   */
  allContacts: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Credit
// ====================================================

export interface Credit {
  __typename: "ResellerCreditResponse";
  /**
   * Crédito total para compra no momento
   */
  total: number | null;
  /**
   * Crédito disponível para compra no momento
   */
  available: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CurrentOrder
// ====================================================

export interface CurrentOrder {
  __typename: "CurrentOrderResponse";
  /**
   * Id do pedido
   */
  id: string;
  /**
   * Id da revendedora
   */
  resellerId: string;
  /**
   * Nome da revendedora
   */
  resellerName: string;
  /**
   * Identificador do ciclo para o qual o pedido se refere
   */
  cycle: string;
  /**
   * Identificador do ciclo para o qual o pedido se refere
   */
  subcycle: string | null;
  /**
   * Código do centro de distribuição
   */
  distributionCenterCode: string;
  /**
   * Indica se é para retirar na CS
   */
  pickupOnDistributionCenter: boolean;
  /**
   * Data de criação do pedido (UTC)
   */
  createdAt: any;
  /**
   * Data de expiração do pedido (UTC)
   */
  expiration: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DeliveryMode
// ====================================================

export interface DeliveryMode {
  __typename: "ResellerCurrentOrderDeliveryModeResponse";
  /**
   * Indica se o endereço se trata de um espaço revendedor
   */
  isWithdrawalCenter: boolean;
  /**
   * Identificador do espaço revendedor
   */
  distributionCenterCode: string;
  /**
   * Nome do espaço revendedor
   */
  distributionCenterName: string;
  /**
   * Endereço (de entrega ou do espaço revendedor)
   */
  descriptiveAddress: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CartProduct
// ====================================================

export interface CartProduct_origin {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface CartProduct {
  __typename: "CartProductType";
  /**
   * Número representativo do produto no carrinho Gera
   */
  sequentialNumber: string;
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * URL da imagem do produto
   */
  imageUrl: string;
  /**
   * Quantidade
   */
  quantity: number;
  /**
   * Preço de mercado (consumidor)
   */
  unitMarketValue: number;
  /**
   * Preço de tabela (consumidor)
   */
  unitTableValue: number;
  /**
   * Preço promocionado (consumidor)
   */
  unitNetValue: number | null;
  /**
   * Origem do produto no pedido
   */
  origin: CartProduct_origin;
  /**
   * Bloqueado para edição?
   */
  blockEdit: boolean;
  /**
   * Bloqueado para remoção?
   */
  blockDelete: boolean;
  /**
   * Valor unitário de compra
   */
  unitPayValue: number;
  /**
   * Valor de compra
   */
  payValue: number;
  /**
   * Lucro
   */
  profit: number;
  /**
   * Lucro com descontos
   */
  profitWithDiscount: number;
  /**
   * Se o produto é um combo personalizável
   */
  isCombo: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CartCutProduct
// ====================================================

export interface CartCutProduct_type {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface CartCutProduct_cutReason {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface CartCutProduct {
  __typename: "CartCutProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * URL da imagem do produto
   */
  imageUrl: string;
  /**
   * Quantidade
   */
  quantity: number;
  /**
   * Preço de mercado (consumidor)
   */
  unitMarketValue: number;
  /**
   * Preço de tabela (consumidor)
   */
  unitTableValue: number;
  /**
   * Preço promocionado (consumidor)
   */
  unitNetValue: number | null;
  /**
   * Tipo do item
   */
  type: CartCutProduct_type;
  /**
   * Razão do corte
   */
  cutReason: CartCutProduct_cutReason;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AcquiredProduct
// ====================================================

export interface AcquiredProduct_promotion {
  __typename: "CartPromotionType";
  /**
   * Código
   */
  code: string;
  /**
   * Título
   */
  title: string;
}

export interface AcquiredProduct {
  __typename: "AcquiredProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * URL da imagem
   */
  imageUrl: string;
  /**
   * Quantidade
   */
  quantity: number | null;
  /**
   * Promoção que originou o brinde
   */
  promotion: AcquiredProduct_promotion | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AcquiredPromotion
// ====================================================

export interface AcquiredPromotion_destination {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface AcquiredPromotion_rewards {
  __typename: "RewardProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Quantidade
   */
  quantity: number | null;
  /**
   * URL da imagem
   */
  imageUrl: string;
  /**
   * Desconto
   */
  discount: number;
  /**
   * Tipo
   */
  type: RewardTypeEnum;
  /**
   * Descrição
   */
  description: string | null;
  /**
   * Indica se é um brinde a ser escolhido
   */
  isGiftToChoose: boolean;
}

export interface AcquiredPromotion {
  __typename: "AcquiredPromotionType";
  /**
   * Código da promoção
   */
  code: string;
  /**
   * Nome
   */
  title: string;
  /**
   * Descrição
   */
  description: string | null;
  /**
   * Destino
   */
  destination: AcquiredPromotion_destination;
  /**
   * Indica se é promoção de cupom de desconto
   */
  isCupomPromotion: boolean;
  /**
   * Lista de brindes/descontos
   */
  rewards: AcquiredPromotion_rewards[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: RewardToChoose
// ====================================================

export interface RewardToChoose_destination {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface RewardToChoose_productsToChoose {
  __typename: "RewardProductToChooseType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Url da imagem
   */
  imageUrl: string;
  /**
   * Quantidade escolhida
   */
  chosenQuantity: number | null;
  /**
   * Quantidade máxima a ser selecionada
   */
  maxQuantity: number;
  /**
   * Indica se está indisponível (só atualiza depois de escolhido)
   */
  unavailable: boolean;
  /**
   * ?
   */
  isReorderAllowed: boolean;
}

export interface RewardToChoose_discountsToChoose {
  __typename: "RewardDiscountToChooseType";
  /**
   * Código da opção de desconto
   */
  code: string;
  /**
   * Valor do desconto (unidade é de acordo com o tipo do desconto)
   */
  value: number;
  /**
   * Descrição
   */
  auxiliaryDescription: string | null;
  /**
   * Tipo da conquista
   */
  rewardType: RewardTypeEnum;
}

export interface RewardToChoose {
  __typename: "RewardToChooseType";
  /**
   * Código da promoção (pode repetir)
   */
  code: string;
  /**
   * Título da promoção
   */
  title: string;
  /**
   * Indica se escolheu produtos
   */
  hasChosenProducts: boolean;
  /**
   * Indica se escolheu a opção de brindes
   */
  hasChosenDiscount: boolean;
  /**
   * Número sequencial (diferenciador das opções)
   */
  giftNumber: string;
  /**
   * Número sequencial (diferenciador dos itens)
   */
  giftItemNumber: string;
  /**
   * Número máximo de brindes a serem escolhidos
   */
  maxGiftsToChoose: number;
  /**
   * Descrição
   */
  description: string | null;
  /**
   * Destino
   */
  destination: RewardToChoose_destination;
  /**
   * Indica se é promoção de cupom
   */
  isCupomPromotion: boolean;
  /**
   * Produto (brinde) a ser escolhido na promoção
   */
  productsToChoose: RewardToChoose_productsToChoose[] | null;
  /**
   * Detalhes de uma opção de desconto em uma promoção
   */
  discountsToChoose: RewardToChoose_discountsToChoose[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CartDeliveryAddress
// ====================================================

export interface CartDeliveryAddress_type {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface CartDeliveryAddress_address {
  __typename: "RegistrationAddressType";
  /**
   * Logradouro
   */
  streetName: string | null;
  /**
   * Número
   */
  addressNumber: string | null;
  /**
   * CEP
   */
  cep: string | null;
  /**
   * Bairro
   */
  neighborhood: string | null;
  /**
   * Complemento
   */
  complement: string | null;
  /**
   * Cidade
   */
  city: string | null;
  /**
   * Estado
   */
  state: string | null;
  /**
   * País
   */
  country: string | null;
}

export interface CartDeliveryAddress {
  __typename: "CartDeliveryAddressType";
  /**
   * Tipo do endereço
   */
  type: CartDeliveryAddress_type;
  /**
   * Endereço em formato de objeto
   */
  address: CartDeliveryAddress_address;
  /**
   * Endereço formatado
   */
  formattedAddress: string;
  /**
   * CEP
   */
  cep: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FreightOption
// ====================================================

export interface FreightOption_type {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface FreightOption {
  __typename: "FreightOptionResponse";
  /**
   * Código da opção de frete
   */
  code: string;
  /**
   * Nome da opção de frete
   */
  name: string;
  /**
   * Tipo
   */
  type: FreightOption_type;
  /**
   * Valor da entrega
   */
  price: number;
  /**
   * Dias úteis para a entrega
   */
  deliveryUtilDays: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CartTotals
// ====================================================

export interface CartTotals {
  __typename: "CartTotalsType";
  /**
   * Total de venda
   */
  totalSell: number | null;
  /**
   * Lucro
   */
  profit: number | null;
  /**
   * Frete
   */
  deliveryTax: number | null;
  /**
   * Subtotal
   */
  subtotalPay: number | null;
  /**
   * Total
   */
  totalPay: number | null;
  /**
   * Lucro vindo dos brindes
   */
  profitFromGifts: number | null;
  /**
   * Valor de crédito utilizado da CCR (Conta Corrente Residual)
   */
  checkingAccount: number | null;
  /**
   * Taxa administrativa
   */
  collectionFee: number | null;
  /**
   * Impostos
   */
  taxes: number | null;
  /**
   * Comissão
   */
  commission: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PromotionRequirementType
// ====================================================

export interface PromotionRequirementType_type {
  __typename: "RequirementType";
  /**
   * Id do tipo de requisito
   */
  id: number | null;
  /**
   * Descrição do requisito
   */
  description: string | null;
}

export interface PromotionRequirementType_productRequired {
  __typename: "RequiredProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome do produto
   */
  name: string;
  /**
   * Descrição do produto
   */
  description: string | null;
  /**
   * Indica se é um kit
   */
  isKit: boolean | null;
  /**
   * Quantidade de pontos
   */
  points: number | null;
  /**
   * Preço
   */
  price: number | null;
}

export interface PromotionRequirementType_structureRequired {
  __typename: "RequiredStructureType";
  /**
   * Código da estrutura
   */
  code: string;
  /**
   * Nome da estrutura
   */
  name: string;
}

export interface PromotionRequirementType {
  __typename: "PromotionRequirementType";
  /**
   * Valor faltante para obter a promoção
   */
  missingValue: number;
  /**
   * Tipo do requisito
   */
  type: PromotionRequirementType_type;
  /**
   * Produto requisitado
   */
  productRequired: PromotionRequirementType_productRequired | null;
  /**
   * Estrutura na qual a compra deve ser feita
   */
  structureRequired: PromotionRequirementType_structureRequired | null;
  /**
   * Descrição
   */
  description: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PartialPromotionType
// ====================================================

export interface PartialPromotionType_destination {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface PartialPromotionType_requirements_type {
  __typename: "RequirementType";
  /**
   * Id do tipo de requisito
   */
  id: number | null;
  /**
   * Descrição do requisito
   */
  description: string | null;
}

export interface PartialPromotionType_requirements_productRequired {
  __typename: "RequiredProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome do produto
   */
  name: string;
  /**
   * Descrição do produto
   */
  description: string | null;
  /**
   * Indica se é um kit
   */
  isKit: boolean | null;
  /**
   * Quantidade de pontos
   */
  points: number | null;
  /**
   * Preço
   */
  price: number | null;
}

export interface PartialPromotionType_requirements_structureRequired {
  __typename: "RequiredStructureType";
  /**
   * Código da estrutura
   */
  code: string;
  /**
   * Nome da estrutura
   */
  name: string;
}

export interface PartialPromotionType_requirements {
  __typename: "PromotionRequirementType";
  /**
   * Valor faltante para obter a promoção
   */
  missingValue: number;
  /**
   * Tipo do requisito
   */
  type: PartialPromotionType_requirements_type;
  /**
   * Produto requisitado
   */
  productRequired: PartialPromotionType_requirements_productRequired | null;
  /**
   * Estrutura na qual a compra deve ser feita
   */
  structureRequired: PartialPromotionType_requirements_structureRequired | null;
  /**
   * Descrição
   */
  description: string | null;
}

export interface PartialPromotionType {
  __typename: "PartialPromotionType";
  /**
   * Código da promoção
   */
  code: string;
  /**
   * Nome
   */
  title: string;
  /**
   * Descrição
   */
  description: string | null;
  /**
   * Destino
   */
  destination: PartialPromotionType_destination;
  /**
   * Indica se é promoção de cupom de desconto
   */
  isCupomPromotion: boolean;
  /**
   * Lista de requisitos para conquistar a promoção
   */
  requirements: PartialPromotionType_requirements[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PaymentPlan
// ====================================================

export interface PaymentPlan_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface PaymentPlan_creditCardOptions_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface PaymentPlan_creditCardOptions {
  __typename: "CreditCardOptionType";
  /**
   * Descrição da bandeira do cartão de crédito
   */
  type: string;
  /**
   * Identificador da bandeira do cartão de crédito
   */
  flag: string;
  /**
   * Modo de pagamento para o cartão especificado
   */
  paymentMode: PaymentPlan_creditCardOptions_paymentMode;
  /**
   * Parcelas possíveis de serem escolhidas
   */
  possibleNumberOfInstallments: number[] | null;
}

export interface PaymentPlan_selectedInstallments {
  __typename: "InstallmentType";
  /**
   * Número da parcela
   */
  number: number;
  /**
   * Valor da parcela
   */
  value: number;
  /**
   * Data de vencimento
   */
  dueDate: any;
  /**
   * Indica se está bloqueada
   */
  isBlocked: boolean;
}

export interface PaymentPlan {
  __typename: "PaymentPlanResponse";
  /**
   * Código do plano de pagamento
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Indica se consumirá crédito da revendedora
   */
  willConsumeCredit: boolean;
  /**
   * Indica se ela deve ter saldo de crédito para poder comprar
   */
  shouldVerifyCredit: boolean;
  /**
   * Indica se está bloqueado
   */
  isBlocked: boolean;
  /**
   * Taxa de administração
   */
  tax: number;
  /**
   * Número de parcelas (para planos sem cartão crédito)
   */
  installments: number | null;
  /**
   * Identificador do modo de pagamento
   */
  paymentMode: PaymentPlan_paymentMode | null;
  /**
   * Opções quando pagamento é por cartão de crédito
   */
  creditCardOptions: PaymentPlan_creditCardOptions[] | null;
  /**
   * Parcelas do plano selecionado
   */
  selectedInstallments: PaymentPlan_selectedInstallments[] | null;
  /**
   * Indica se é múltiplas formas de pagamento
   */
  isMultiplePayment: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Cart
// ====================================================

export interface Cart_externalPaymentLink {
  __typename: "NewPaymentLinkResponse";
  /**
   * Url de pagamento
   */
  externalPaymentLink: string | null;
  /**
   * Data de expiração da url de pagamento
   */
  expirationDatePaymentLink: any | null;
}

export interface Cart_credit {
  __typename: "ResellerCreditResponse";
  /**
   * Crédito disponível para compra no momento
   */
  available: number | null;
}

export interface Cart_products_origin {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface Cart_products {
  __typename: "CartProductType";
  /**
   * Número representativo do produto no carrinho Gera
   */
  sequentialNumber: string;
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * URL da imagem do produto
   */
  imageUrl: string;
  /**
   * Quantidade
   */
  quantity: number;
  /**
   * Preço de mercado (consumidor)
   */
  unitMarketValue: number;
  /**
   * Preço de tabela (consumidor)
   */
  unitTableValue: number;
  /**
   * Preço promocionado (consumidor)
   */
  unitNetValue: number | null;
  /**
   * Origem do produto no pedido
   */
  origin: Cart_products_origin;
  /**
   * Bloqueado para edição?
   */
  blockEdit: boolean;
  /**
   * Bloqueado para remoção?
   */
  blockDelete: boolean;
  /**
   * Valor unitário de compra
   */
  unitPayValue: number;
  /**
   * Valor de compra
   */
  payValue: number;
  /**
   * Lucro
   */
  profit: number;
  /**
   * Lucro com descontos
   */
  profitWithDiscount: number;
  /**
   * Se o produto é um combo personalizável
   */
  isCombo: boolean;
}

export interface Cart_cutProducts_type {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface Cart_cutProducts_cutReason {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface Cart_cutProducts {
  __typename: "CartCutProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * URL da imagem do produto
   */
  imageUrl: string;
  /**
   * Quantidade
   */
  quantity: number;
  /**
   * Preço de mercado (consumidor)
   */
  unitMarketValue: number;
  /**
   * Preço de tabela (consumidor)
   */
  unitTableValue: number;
  /**
   * Preço promocionado (consumidor)
   */
  unitNetValue: number | null;
  /**
   * Tipo do item
   */
  type: Cart_cutProducts_type;
  /**
   * Razão do corte
   */
  cutReason: Cart_cutProducts_cutReason;
}

export interface Cart_acquiredProducts_promotion {
  __typename: "CartPromotionType";
  /**
   * Código
   */
  code: string;
  /**
   * Título
   */
  title: string;
}

export interface Cart_acquiredProducts {
  __typename: "AcquiredProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * URL da imagem
   */
  imageUrl: string;
  /**
   * Quantidade
   */
  quantity: number | null;
  /**
   * Promoção que originou o brinde
   */
  promotion: Cart_acquiredProducts_promotion | null;
}

export interface Cart_acquiredPromotions_destination {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface Cart_acquiredPromotions_rewards {
  __typename: "RewardProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Quantidade
   */
  quantity: number | null;
  /**
   * URL da imagem
   */
  imageUrl: string;
  /**
   * Desconto
   */
  discount: number;
  /**
   * Tipo
   */
  type: RewardTypeEnum;
  /**
   * Descrição
   */
  description: string | null;
  /**
   * Indica se é um brinde a ser escolhido
   */
  isGiftToChoose: boolean;
}

export interface Cart_acquiredPromotions {
  __typename: "AcquiredPromotionType";
  /**
   * Código da promoção
   */
  code: string;
  /**
   * Nome
   */
  title: string;
  /**
   * Descrição
   */
  description: string | null;
  /**
   * Destino
   */
  destination: Cart_acquiredPromotions_destination;
  /**
   * Indica se é promoção de cupom de desconto
   */
  isCupomPromotion: boolean;
  /**
   * Lista de brindes/descontos
   */
  rewards: Cart_acquiredPromotions_rewards[];
}

export interface Cart_partialPromotions_destination {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface Cart_partialPromotions_requirements_type {
  __typename: "RequirementType";
  /**
   * Id do tipo de requisito
   */
  id: number | null;
  /**
   * Descrição do requisito
   */
  description: string | null;
}

export interface Cart_partialPromotions_requirements_productRequired {
  __typename: "RequiredProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome do produto
   */
  name: string;
  /**
   * Descrição do produto
   */
  description: string | null;
  /**
   * Indica se é um kit
   */
  isKit: boolean | null;
  /**
   * Quantidade de pontos
   */
  points: number | null;
  /**
   * Preço
   */
  price: number | null;
}

export interface Cart_partialPromotions_requirements_structureRequired {
  __typename: "RequiredStructureType";
  /**
   * Código da estrutura
   */
  code: string;
  /**
   * Nome da estrutura
   */
  name: string;
}

export interface Cart_partialPromotions_requirements {
  __typename: "PromotionRequirementType";
  /**
   * Valor faltante para obter a promoção
   */
  missingValue: number;
  /**
   * Tipo do requisito
   */
  type: Cart_partialPromotions_requirements_type;
  /**
   * Produto requisitado
   */
  productRequired: Cart_partialPromotions_requirements_productRequired | null;
  /**
   * Estrutura na qual a compra deve ser feita
   */
  structureRequired: Cart_partialPromotions_requirements_structureRequired | null;
  /**
   * Descrição
   */
  description: string | null;
}

export interface Cart_partialPromotions {
  __typename: "PartialPromotionType";
  /**
   * Código da promoção
   */
  code: string;
  /**
   * Nome
   */
  title: string;
  /**
   * Descrição
   */
  description: string | null;
  /**
   * Destino
   */
  destination: Cart_partialPromotions_destination;
  /**
   * Indica se é promoção de cupom de desconto
   */
  isCupomPromotion: boolean;
  /**
   * Lista de requisitos para conquistar a promoção
   */
  requirements: Cart_partialPromotions_requirements[];
}

export interface Cart_rewardsToChoose_destination {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface Cart_rewardsToChoose_productsToChoose {
  __typename: "RewardProductToChooseType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Url da imagem
   */
  imageUrl: string;
  /**
   * Quantidade escolhida
   */
  chosenQuantity: number | null;
  /**
   * Quantidade máxima a ser selecionada
   */
  maxQuantity: number;
  /**
   * Indica se está indisponível (só atualiza depois de escolhido)
   */
  unavailable: boolean;
  /**
   * ?
   */
  isReorderAllowed: boolean;
}

export interface Cart_rewardsToChoose_discountsToChoose {
  __typename: "RewardDiscountToChooseType";
  /**
   * Código da opção de desconto
   */
  code: string;
  /**
   * Valor do desconto (unidade é de acordo com o tipo do desconto)
   */
  value: number;
  /**
   * Descrição
   */
  auxiliaryDescription: string | null;
  /**
   * Tipo da conquista
   */
  rewardType: RewardTypeEnum;
}

export interface Cart_rewardsToChoose {
  __typename: "RewardToChooseType";
  /**
   * Código da promoção (pode repetir)
   */
  code: string;
  /**
   * Título da promoção
   */
  title: string;
  /**
   * Indica se escolheu produtos
   */
  hasChosenProducts: boolean;
  /**
   * Indica se escolheu a opção de brindes
   */
  hasChosenDiscount: boolean;
  /**
   * Número sequencial (diferenciador das opções)
   */
  giftNumber: string;
  /**
   * Número sequencial (diferenciador dos itens)
   */
  giftItemNumber: string;
  /**
   * Número máximo de brindes a serem escolhidos
   */
  maxGiftsToChoose: number;
  /**
   * Descrição
   */
  description: string | null;
  /**
   * Destino
   */
  destination: Cart_rewardsToChoose_destination;
  /**
   * Indica se é promoção de cupom
   */
  isCupomPromotion: boolean;
  /**
   * Produto (brinde) a ser escolhido na promoção
   */
  productsToChoose: Cart_rewardsToChoose_productsToChoose[] | null;
  /**
   * Detalhes de uma opção de desconto em uma promoção
   */
  discountsToChoose: Cart_rewardsToChoose_discountsToChoose[] | null;
}

export interface Cart_chosenAddress_type {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface Cart_chosenAddress_address {
  __typename: "RegistrationAddressType";
  /**
   * Logradouro
   */
  streetName: string | null;
  /**
   * Número
   */
  addressNumber: string | null;
  /**
   * CEP
   */
  cep: string | null;
  /**
   * Bairro
   */
  neighborhood: string | null;
  /**
   * Complemento
   */
  complement: string | null;
  /**
   * Cidade
   */
  city: string | null;
  /**
   * Estado
   */
  state: string | null;
  /**
   * País
   */
  country: string | null;
}

export interface Cart_chosenAddress {
  __typename: "CartDeliveryAddressType";
  /**
   * Tipo do endereço
   */
  type: Cart_chosenAddress_type;
  /**
   * Endereço em formato de objeto
   */
  address: Cart_chosenAddress_address;
  /**
   * Endereço formatado
   */
  formattedAddress: string;
  /**
   * CEP
   */
  cep: string;
}

export interface Cart_chosenFreightOption_type {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface Cart_chosenFreightOption {
  __typename: "FreightOptionResponse";
  /**
   * Código da opção de frete
   */
  code: string;
  /**
   * Nome da opção de frete
   */
  name: string;
  /**
   * Tipo
   */
  type: Cart_chosenFreightOption_type;
  /**
   * Valor da entrega
   */
  price: number;
  /**
   * Dias úteis para a entrega
   */
  deliveryUtilDays: number;
}

export interface Cart_chosenPaymentPlan_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface Cart_chosenPaymentPlan_creditCardOptions_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface Cart_chosenPaymentPlan_creditCardOptions {
  __typename: "CreditCardOptionType";
  /**
   * Descrição da bandeira do cartão de crédito
   */
  type: string;
  /**
   * Identificador da bandeira do cartão de crédito
   */
  flag: string;
  /**
   * Modo de pagamento para o cartão especificado
   */
  paymentMode: Cart_chosenPaymentPlan_creditCardOptions_paymentMode;
  /**
   * Parcelas possíveis de serem escolhidas
   */
  possibleNumberOfInstallments: number[] | null;
}

export interface Cart_chosenPaymentPlan_selectedInstallments {
  __typename: "InstallmentType";
  /**
   * Número da parcela
   */
  number: number;
  /**
   * Valor da parcela
   */
  value: number;
  /**
   * Data de vencimento
   */
  dueDate: any;
  /**
   * Indica se está bloqueada
   */
  isBlocked: boolean;
}

export interface Cart_chosenPaymentPlan {
  __typename: "PaymentPlanResponse";
  /**
   * Código do plano de pagamento
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Indica se consumirá crédito da revendedora
   */
  willConsumeCredit: boolean;
  /**
   * Indica se ela deve ter saldo de crédito para poder comprar
   */
  shouldVerifyCredit: boolean;
  /**
   * Indica se está bloqueado
   */
  isBlocked: boolean;
  /**
   * Taxa de administração
   */
  tax: number;
  /**
   * Número de parcelas (para planos sem cartão crédito)
   */
  installments: number | null;
  /**
   * Identificador do modo de pagamento
   */
  paymentMode: Cart_chosenPaymentPlan_paymentMode | null;
  /**
   * Opções quando pagamento é por cartão de crédito
   */
  creditCardOptions: Cart_chosenPaymentPlan_creditCardOptions[] | null;
  /**
   * Parcelas do plano selecionado
   */
  selectedInstallments: Cart_chosenPaymentPlan_selectedInstallments[] | null;
  /**
   * Indica se é múltiplas formas de pagamento
   */
  isMultiplePayment: boolean;
}

export interface Cart_totals {
  __typename: "CartTotalsType";
  /**
   * Total de venda
   */
  totalSell: number | null;
  /**
   * Lucro
   */
  profit: number | null;
  /**
   * Frete
   */
  deliveryTax: number | null;
  /**
   * Subtotal
   */
  subtotalPay: number | null;
  /**
   * Total
   */
  totalPay: number | null;
  /**
   * Lucro vindo dos brindes
   */
  profitFromGifts: number | null;
  /**
   * Valor de crédito utilizado da CCR (Conta Corrente Residual)
   */
  checkingAccount: number | null;
  /**
   * Taxa administrativa
   */
  collectionFee: number | null;
  /**
   * Impostos
   */
  taxes: number | null;
  /**
   * Comissão
   */
  commission: number | null;
}

export interface Cart_deliveryAddresses_type {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface Cart_deliveryAddresses_address {
  __typename: "RegistrationAddressType";
  /**
   * Logradouro
   */
  streetName: string | null;
  /**
   * Número
   */
  addressNumber: string | null;
  /**
   * CEP
   */
  cep: string | null;
  /**
   * Bairro
   */
  neighborhood: string | null;
  /**
   * Complemento
   */
  complement: string | null;
  /**
   * Cidade
   */
  city: string | null;
  /**
   * Estado
   */
  state: string | null;
  /**
   * País
   */
  country: string | null;
}

export interface Cart_deliveryAddresses {
  __typename: "CartDeliveryAddressType";
  /**
   * Tipo do endereço
   */
  type: Cart_deliveryAddresses_type;
  /**
   * Endereço em formato de objeto
   */
  address: Cart_deliveryAddresses_address;
  /**
   * Endereço formatado
   */
  formattedAddress: string;
  /**
   * CEP
   */
  cep: string;
}

export interface Cart_paymentPlans_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface Cart_paymentPlans_creditCardOptions_paymentMode {
  __typename: "CartGenericTypeType";
  /**
   * Identificador
   */
  id: string;
  /**
   * Descrição
   */
  description: string;
}

export interface Cart_paymentPlans_creditCardOptions {
  __typename: "CreditCardOptionType";
  /**
   * Descrição da bandeira do cartão de crédito
   */
  type: string;
  /**
   * Identificador da bandeira do cartão de crédito
   */
  flag: string;
  /**
   * Modo de pagamento para o cartão especificado
   */
  paymentMode: Cart_paymentPlans_creditCardOptions_paymentMode;
  /**
   * Parcelas possíveis de serem escolhidas
   */
  possibleNumberOfInstallments: number[] | null;
}

export interface Cart_paymentPlans_selectedInstallments {
  __typename: "InstallmentType";
  /**
   * Número da parcela
   */
  number: number;
  /**
   * Valor da parcela
   */
  value: number;
  /**
   * Data de vencimento
   */
  dueDate: any;
  /**
   * Indica se está bloqueada
   */
  isBlocked: boolean;
}

export interface Cart_paymentPlans {
  __typename: "PaymentPlanResponse";
  /**
   * Código do plano de pagamento
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Indica se consumirá crédito da revendedora
   */
  willConsumeCredit: boolean;
  /**
   * Indica se ela deve ter saldo de crédito para poder comprar
   */
  shouldVerifyCredit: boolean;
  /**
   * Indica se está bloqueado
   */
  isBlocked: boolean;
  /**
   * Taxa de administração
   */
  tax: number;
  /**
   * Número de parcelas (para planos sem cartão crédito)
   */
  installments: number | null;
  /**
   * Identificador do modo de pagamento
   */
  paymentMode: Cart_paymentPlans_paymentMode | null;
  /**
   * Opções quando pagamento é por cartão de crédito
   */
  creditCardOptions: Cart_paymentPlans_creditCardOptions[] | null;
  /**
   * Parcelas do plano selecionado
   */
  selectedInstallments: Cart_paymentPlans_selectedInstallments[] | null;
  /**
   * Indica se é múltiplas formas de pagamento
   */
  isMultiplePayment: boolean;
}

export interface Cart {
  __typename: "CartResponse";
  /**
   * Código do pedido
   */
  orderCode: string;
  /**
   * Código do espaço revendedor
   */
  distributionCenterCode: string;
  /**
   * Modo de entrega (retirar na central / entrega em endereço)
   */
  pickupOnDistributionCenter: boolean | null;
  /**
   * Valor do pedido mínimo
   */
  requiredMinimum: number;
  /**
   * Valor atual considerado para o pedido mínimo
   */
  currentValueConsideredToMinimum: number;
  /**
   * Ciclo do pedido
   */
  cycle: number;
  /**
   * SubCiclo do pedido
   */
  subCycle: number;
  /**
   * Dados do link externo de pagamento
   */
  externalPaymentLink: Cart_externalPaymentLink | null;
  /**
   * Crédito disponível
   */
  credit: Cart_credit | null;
  /**
   * Produtos do pedido
   */
  products: Cart_products[];
  /**
   * Produtos cortados
   */
  cutProducts: Cart_cutProducts[];
  /**
   * Produto (brinde) conquistado
   */
  acquiredProducts: Cart_acquiredProducts[] | null;
  /**
   * Promoção conquistada
   */
  acquiredPromotions: Cart_acquiredPromotions[] | null;
  /**
   * Promoção conquistada
   */
  partialPromotions: Cart_partialPromotions[] | null;
  /**
   * Opção a ser escolhida na promoção (pode ser brinde ou desconto)
   */
  rewardsToChoose: Cart_rewardsToChoose[] | null;
  /**
   * Endereço escolhido (ou padrão) para a entrega
   */
  chosenAddress: Cart_chosenAddress | null;
  /**
   * Opção de frete escolhida (ou padrão)
   */
  chosenFreightOption: Cart_chosenFreightOption | null;
  /**
   * Opção de pagamento escolhida (ou padrão)
   */
  chosenPaymentPlan: Cart_chosenPaymentPlan | null;
  /**
   * Resumo de valores do pedido
   */
  totals: Cart_totals;
  /**
   * Endereços para entrega
   */
  deliveryAddresses: Cart_deliveryAddresses[] | null;
  /**
   * Planos de pagamento
   */
  paymentPlans: Cart_paymentPlans[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: OrderCycles
// ====================================================

export interface OrderCycles {
  __typename: "OrderCyclesResponse";
  /**
   * Ciclo anterior (deve ser permitido usar)
   */
  previousCycle: string | null;
  /**
   * Ciclos atual
   */
  currentCycle: string;
  /**
   * Indica se é período de ciclo estendido
   */
  isExtendedCyclePeriod: boolean;
  /**
   * Indica se a revendedora pode fazer pedido no ciclo anterior
   */
  isAllowedToOrderOnPreviousCycle: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductResponse
// ====================================================

export interface ProductResponse {
  __typename: "ProductResponse";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Url da imagem (tamanho P)
   */
  imageUrl: string;
  /**
   * Preço de venda de (tabela)
   */
  marketValue: number | null;
  /**
   * Preço de venda (promocionado, se for o caso)
   */
  netValue: number | null;
  /**
   * Preço de compra da revendedora
   */
  resellerValue: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EudoraProductType
// ====================================================

export interface EudoraProductType {
  __typename: "EudoraProductType";
  /**
   * Código do produto
   */
  code: string;
  /**
   * Nome
   */
  name: string;
  /**
   * Url da imagem (tamanho P)
   */
  imageUrl: string;
  /**
   * Se o produto é um combo personalizável
   */
  isCombo: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ResellerDetailData
// ====================================================

export interface ResellerDetailData_profile {
  __typename: "ResellerDetailProfileType";
  /**
   * Nome da revendedora
   */
  name: string;
  /**
   * URL do avatar da revendedora
   */
  avatarUrl: string | null;
  /**
   * Ciclo de início (número e ano)
   */
  startingCicle: string | null;
  /**
   * Telefones da revendedora cadastrados pela supervisora
   */
  phones: string[];
  /**
   * Endereços da revendedora cadastrados pela supervisora
   */
  addresses: string[];
  /**
   * E-mails da revendedora cadastrados pela supervisora
   */
  emails: string[];
  /**
   * Notas adicionais da revendedora cadastradas pela supervisora
   */
  additionalNotes: string | null;
}

export interface ResellerDetailData_cicleStatus {
  __typename: "ResellerDetailCicleStatusType";
  /**
   * Categoria de Ativação
   */
  activationCategory: ResellerActivationCategoryType | null;
  /**
   * Feedback da Ativação
   */
  activationFeedback: ResellerActivationFeedbackType;
  /**
   * Potencial (farol) da revendedora
   */
  potencial: ResellerActivationPotencialType | null;
  /**
   * Se há um contato agendado
   */
  hasScheduledContact: boolean;
  /**
   * Data e hora do contato agendado (UTC)
   */
  scheduledContact: any | null;
}

export interface ResellerDetailData_purchaseHistory_paymentMethodFrequency {
  __typename: "ResellerDetailPaymentMethodFrequencyType";
  /**
   * % de compras com cartão
   */
  card: number;
  /**
   * % de compras com boleto
   */
  boleto: number;
  /**
   * % de compras com dinheiro
   */
  cash: number;
  /**
   * % de compras misto
   */
  misc: number;
}

export interface ResellerDetailData_purchaseHistory_previousCicles {
  __typename: "ResellerDetailPurchaseHistoryCicleType";
  /**
   * Id do ciclo
   */
  cicleId: string;
  /**
   * Valor total de compra no ciclo
   */
  value: number;
  /**
   * Potencial (farol) da revendedora no ciclo
   */
  potencial: ResellerActivationPotencialType;
}

export interface ResellerDetailData_purchaseHistory {
  __typename: "ResellerDetailPurchaseHistoryType";
  /**
   * Valor potencial de compra total
   */
  valueTotalPurchasePotencial: number;
  /**
   * Valor comprado no ciclo atual
   */
  valuePurchasedOnCurrentCicle: number;
  /**
   * Valores comprados nos últimos 6 ciclos anteriores
   */
  valuePurchasedOnLastSixCicles: number;
  /**
   * Frequência média de pedidos por ciclo
   */
  averageOrdersPerCicle: number;
  /**
   * Pedido médio no ciclo
   */
  averageOrderOnCurrentCicle: number;
  /**
   * Status de inadimplência (% de inadimplência)
   */
  welchStatusPercentage: number;
  /**
   * Status de inadimplência (valor do crédito vencido)
   */
  welchStatusExpiredCredit: number;
  /**
   * Frequência de compra delivery (%s) - Número de pedidos
   */
  deliveryOrderFrequency: number;
  /**
   * Frequência de compra na loja (%s) - Número de pedidos
   */
  storeOrderFrequency: number;
  /**
   * Pedido médio para delivery (R$)
   */
  deliveryAverageOrder: number;
  /**
   * Pedido médio para compra na loja (R$)
   */
  storeAverageOrder: number;
  /**
   * Status de inadimplência
   */
  welchStatus: boolean;
  /**
   * Status de perda
   */
  welchIsLoss: boolean;
  /**
   * 6 produtos mais comprados pela revendedora
   */
  paymentMethodFrequency: ResellerDetailData_purchaseHistory_paymentMethodFrequency;
  /**
   * Histórico de compra dos últimos ciclos
   */
  previousCicles: ResellerDetailData_purchaseHistory_previousCicles[];
}

export interface ResellerDetailData_reactions {
  __typename: "ResellersReactionResponse";
  name: string | null;
  emoji: string | null;
  commercialStructure: string | null;
  createdAt: any | null;
  updatedAt: any | null;
  id: string | null;
}

export interface ResellerDetailData_tools {
  __typename: "ResellerDetailToolsType";
  /**
   * Revendedora usa o app
   */
  usesApp: boolean;
  /**
   * Revendedora usa MRD
   */
  usesMRD: boolean;
}

export interface ResellerDetailData {
  __typename: "ResellerDetailResponse";
  /**
   * Id da revendedora
   */
  id: string;
  /**
   * Dados do perfil da revendedora
   */
  profile: ResellerDetailData_profile;
  /**
   * Status da revendedora no ciclo atual
   */
  cicleStatus: ResellerDetailData_cicleStatus;
  /**
   * Dados referentes ao histórico de compras da revendedora
   */
  purchaseHistory: ResellerDetailData_purchaseHistory;
  /**
   * Reactions da revendedora
   */
  reactions: ResellerDetailData_reactions[] | null;
  /**
   * Ferramentas usadas pela revendedora
   */
  tools: ResellerDetailData_tools;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ResellerEuPossoMaisResponse
// ====================================================

export interface ResellerEuPossoMaisResponse {
  __typename: "ResellerEuPossoMaisResponse";
  /**
   * Faixa do Eu Posso Mais atual
   */
  currentCategory: number | null;
  /**
   * Próxima faixa do Eu Posso Mais
   */
  nextCategory: number | null;
  /**
   * Receita da próxima categoria do Eu Posso Mais
   */
  pendingForNextCategory: number | null;
  /**
   * Crédito do Eu Posso Mais
   */
  creditForCurrentCycle: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ResellerListFeedbackResponse
// ====================================================

export interface ResellerListFeedbackResponse_fvcFeedback {
  __typename: "FeedbackResponse";
  id: number | null;
  title: string;
  /**
   * Sinaliza se feedback terá campo de forma de contato
   */
  hasContactForm: boolean;
  /**
   * Sinaliza se feedback terá campo de observações
   */
  hasNote: boolean;
  /**
   * Código usado na tabulação do Gera
   */
  geraCode: string | null;
  active: boolean | null;
}

export interface ResellerListFeedbackResponse {
  __typename: "FvcResellerListFeedbackResponse";
  /**
   * Id de um feedback de ativação
   */
  id: number;
  /**
   * Feedback da Ativação
   */
  feedback: ResellerActivationFeedbackType;
  /**
   * Nota de um feedback de ativação
   */
  note: string | null;
  /**
   * Data e hora do contato agendado (UTC)
   */
  scheduledContact: any | null;
  /**
   * Data de criação do registro
   */
  createdAt: any;
  /**
   * Feedback customizado
   */
  fvcFeedback: ResellerListFeedbackResponse_fvcFeedback | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ResellerInfoResponse
// ====================================================

export interface ResellerInfoResponse_reseller {
  __typename: "BasicInfoResponse";
  referenceId: string | null;
  name: string | null;
}

export interface ResellerInfoResponse_franchise {
  __typename: "BasicInfoResponse";
  referenceId: string | null;
  name: string | null;
}

export interface ResellerInfoResponse_store {
  __typename: "BasicInfoResponse";
  referenceId: string | null;
  name: string | null;
}

export interface ResellerInfoResponse {
  __typename: "ResellerInfoResponse";
  reseller: ResellerInfoResponse_reseller;
  franchise: ResellerInfoResponse_franchise;
  store: ResellerInfoResponse_store;
  availableCredit: number | null;
  birthdate: any | null;
  residentialPhone: string | null;
  mobilePhone: string | null;
  contactPhone: string | null;
  email: string | null;
  street: string | null;
  streetNumber: string | null;
  complement: string | null;
  neighborhood: string | null;
  city: string | null;
  zipCode: string | null;
  cycleAtFirstOrder: string | null;
  fraudRisk: string | null;
  registrationDate: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ResellerOrderListItem
// ====================================================

export interface ResellerOrderListItem {
  __typename: "ResellerOrderListResponse";
  /**
   * Id do pedido
   */
  id: string;
  /**
   * Data do pedido
   */
  date: any | null;
  /**
   * Ciclo que o pedido foi feito
   */
  cicle: string;
  /**
   * Método de pagamento
   */
  paymentMethod: string;
  /**
   * Status do pedido
   */
  status: string;
  /**
   * Valor total do pedido
   */
  total: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ResellerPreferencesInfoResponse
// ====================================================

export interface ResellerPreferencesInfoResponse {
  __typename: "ResellerPreferencesInfoResponse";
  /**
   * Flag para indicar se a representante comprou no app da RE no último ciclo
   */
  boughtByApp: boolean | null;
  /**
   * Flag para indicar se a representante comprou no app da RE nos últimos 7 ciclos
   */
  boughtByAppLast7Cycles: boolean | null;
  /**
   * Flag para indicar se a representante comprou em uma loja física no último ciclo
   */
  boughtByConsultant: boolean | null;
  /**
   * Flag para indicar se a representante comprou em uma loja física nos últimos 7 ciclos
   */
  boughtByConsultantLast7Cycles: boolean | null;
  /**
   * Flag para indicar se a representante comprou na ER no último ciclo
   */
  boughtByER: boolean | null;
  /**
   * Flag para indicar se a representante comprou na ER nos últimos 7 ciclos
   */
  boughtByERLast7Cycles: boolean | null;
  /**
   * Flag para indicar se a representante comprou no site da RE no último ciclo
   */
  boughtBySite: boolean | null;
  /**
   * Flag para indicar se a representante comprou no site da RE nos últimos 7 ciclos
   */
  boughtBySiteLast7Cycles: boolean | null;
  /**
   * Engajamento da representante com a ER
   */
  engagementER: string | null;
  /**
   * Canal de venda favorito da representante
   */
  favoriteChannel: string | null;
  /**
   * Faixa VIP
   */
  vipTier: string | null;
  /**
   * Valor necessário para a próxima Faixa VIP
   */
  nextTierValue: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ResellerProfile
// ====================================================

export interface ResellerProfile {
  __typename: "ResellerDetailProfileType";
  /**
   * Nome da revendedora
   */
  name: string;
  /**
   * URL do avatar da revendedora
   */
  avatarUrl: string | null;
  /**
   * Ciclo de início (número e ano)
   */
  startingCicle: string | null;
  /**
   * Telefones da revendedora cadastrados pela supervisora
   */
  phones: string[];
  /**
   * Endereços da revendedora cadastrados pela supervisora
   */
  addresses: string[];
  /**
   * E-mails da revendedora cadastrados pela supervisora
   */
  emails: string[];
  /**
   * Notas adicionais da revendedora cadastradas pela supervisora
   */
  additionalNotes: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ResellersReaction
// ====================================================

export interface ResellersReaction {
  __typename: "ResellersReactionResponse";
  name: string | null;
  emoji: string | null;
  commercialStructure: string | null;
  createdAt: any | null;
  updatedAt: any | null;
  id: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ResellerRegistrationInfoResponse
// ====================================================

export interface ResellerRegistrationInfoResponse_commercialStructure {
  __typename: "ResellerCommercialStructureType";
  /**
   * Identificador da estrutura comercial
   */
  referenceId: string | null;
  /**
   * Nome do franqueado
   */
  franchiseName: string | null;
  /**
   * Nome da central de serviço
   */
  serviceCenterName: string | null;
}

export interface ResellerRegistrationInfoResponse_addresses_info {
  __typename: "AddressInfoType";
  addressNumber: string | null;
  cep: string | null;
  city: string | null;
  complement: string | null;
  neighborhood: string | null;
  state: string | null;
  streetName: string | null;
  landmark: string | null;
}

export interface ResellerRegistrationInfoResponse_addresses {
  __typename: "AddressType";
  /**
   * Tipo de endereço
   */
  type: RegistrationInfoAddressType | null;
  /**
   * Informações do endereço
   */
  info: ResellerRegistrationInfoResponse_addresses_info | null;
}

export interface ResellerRegistrationInfoResponse_personalInfo_contactInfo {
  __typename: "ContactType";
  /**
   * Tipo de Contato
   */
  type: RegistrationInfoContactType | null;
  /**
   * Valor
   */
  value: string | null;
}

export interface ResellerRegistrationInfoResponse_personalInfo_documents {
  __typename: "DocumentType";
  /**
   * Tipo do documento
   */
  type: RegistrationInfoDocumentType | null;
  /**
   * Valor
   */
  value: string | null;
}

export interface ResellerRegistrationInfoResponse_personalInfo {
  __typename: "PersonalInfoType";
  /**
   * Razão do bloqueio
   */
  birthdate: any | null;
  /**
   * Apelido
   */
  nickname: string | null;
  /**
   * Nome
   */
  name: string | null;
  /**
   * Informação de contato
   */
  contactInfo: ResellerRegistrationInfoResponse_personalInfo_contactInfo[] | null;
  /**
   * Sexo
   */
  sex: RegistrationInfoSexType | null;
  /**
   * Documentos
   */
  documents: ResellerRegistrationInfoResponse_personalInfo_documents[] | null;
}

export interface ResellerRegistrationInfoResponse {
  __typename: "ResellerRegistrationInfoResponse";
  resellerId: string | null;
  commercialStructure: ResellerRegistrationInfoResponse_commercialStructure;
  /**
   * Endereços
   */
  addresses: ResellerRegistrationInfoResponse_addresses[] | null;
  /**
   * Informações pessoais
   */
  personalInfo: ResellerRegistrationInfoResponse_personalInfo | null;
  /**
   * Razão do bloqueio
   */
  blockReason: string | null;
  /**
   * Está ativo?
   */
  isActive: boolean | null;
  /**
   * Está bloqueado?
   */
  isBlocked: boolean | null;
  /**
   * Está inadimplente?
   */
  isWelch: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ResellerTools
// ====================================================

export interface ResellerTools {
  __typename: "ResellerDetailToolsType";
  /**
   * Revendedora usa o app
   */
  usesApp: boolean;
  /**
   * Revendedora usa MRD
   */
  usesMRD: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Reseller
// ====================================================

export interface Reseller {
  __typename: "ResellerListResponse";
  /**
   * Código da revendedora
   */
  id: string;
  /**
   * Nome da revendedora
   */
  name: string;
  /**
   * URL do avatar da revendedora
   */
  avatarUrl: string | null;
  /**
   * Categoria de Ativação
   */
  activationCategory: ResellerActivationCategoryType | null;
  /**
   * Feedback da Ativação
   */
  activationFeedback: ResellerActivationFeedbackType;
  /**
   * Potencial de ativação (farol) da revendedora
   */
  potencial: ResellerActivationPotencialType | null;
  /**
   * É Inadimplente
   */
  isWelch: boolean;
  /**
   * Data de agendamento
   */
  scheduledTo: any | null;
  /**
   * É perda
   */
  isLoss: boolean;
  /**
   * Nota de um feedback de ativação
   */
  note: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ResidualCreditEudora
// ====================================================

export interface ResidualCreditEudora_creditStatement {
  __typename: "ResidualCreditAccountEntriesType";
  id: number;
  /**
   * Data de crédito (yyyy-mm-dd)
   */
  date: any;
  /**
   * Data de utilização
   */
  usedAt: any | null;
  /**
   * Origem do crédito
   */
  originDescription: string;
  /**
   * ID do pedido em que o crédito foi utilizado
   */
  orderCredit: number | null;
  /**
   * Situação do crédito (ATIVO | INATIVO | CANCELADO)
   */
  status: StatusCCREntry;
  /**
   * Tipo do crédito (Débito | Crédito)
   */
  type: string;
  /**
   * Valor do crédito
   */
  value: number;
}

export interface ResidualCreditEudora {
  __typename: "ResidualCreditAccountType";
  /**
   * Valor do crédito residual
   */
  balance: number | null;
  /**
   * Entradas do crédito residual
   */
  creditStatement: ResidualCreditEudora_creditStatement[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: starterPacksProducts
// ====================================================

export interface starterPacksProducts {
  __typename: "CartInitialParamsStarterPackProductResponse";
  /**
   * Identificador do produto
   */
  id: string | null;
  /**
   * Nome do produto
   */
  name: string | null;
  /**
   * Descrição do produto
   */
  description: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: starterPacks
// ====================================================

export interface starterPacks_products {
  __typename: "CartInitialParamsStarterPackProductResponse";
  /**
   * Identificador do produto
   */
  id: string | null;
  /**
   * Nome do produto
   */
  name: string | null;
  /**
   * Descrição do produto
   */
  description: string | null;
}

export interface starterPacks {
  __typename: "CartInitialParamsStarterPackResponse";
  /**
   * Identificador do kit início
   */
  id: string | null;
  /**
   * Nome do kit início
   */
  name: string | null;
  /**
   * Descrição do kit início
   */
  description: string | null;
  /**
   * Quantidade de produtos a serem escolhidos para o kit início
   */
  quantity: number | null;
  /**
   * Produtos disponíveis para escolha do kit início
   */
  products: starterPacks_products[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: StoreVsDeliveryValues
// ====================================================

export interface StoreVsDeliveryValues {
  __typename: "DashboardResellerStoreVsDeliveryItemType";
  /**
   * Receita R$ %
   */
  income: number;
  /**
   * Número de Pedidos %
   */
  orders: number;
  /**
   * R$/Ativo
   */
  incomePerActive: number;
  /**
   * Pedido médio
   */
  averageOrder: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TemplateResponse
// ====================================================

export interface TemplateResponse_components_buttons {
  __typename: "ApprovedTemplateButtonResponse";
  type: string | null;
  text: string | null;
  phoneNumber: string | null;
  url: string | null;
}

export interface TemplateResponse_components {
  __typename: "ApprovedTemplateComponentResponse";
  type: string | null;
  text: string | null;
  format: string | null;
  buttons: TemplateResponse_components_buttons[] | null;
}

export interface TemplateResponse {
  __typename: "TemplateResponse";
  id: string;
  components: TemplateResponse_components[] | null;
  name: string | null;
  status: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * Sinaliza para feedbacks de Ativação se eles são visíveis para adimplentes e inadimplentes
 */
export enum ActivationFeedbackVisibilty {
  Both = "Both",
  CompliantOnly = "CompliantOnly",
  WelchOnly = "WelchOnly",
}

/**
 * Resultado da adição do produto no pedido
 */
export enum AddProductResultTypeEnum {
  Error = "Error",
  Success = "Success",
}

/**
 * Tipo da aplicação que chamou a query (apenas boticário FVC)
 */
export enum ApplicationTypeEnum {
  Activation = "Activation",
  Starts = "Starts",
}

export enum CartHandlerActionParams {
  generateExternalPaymentLink = "generateExternalPaymentLink",
  paymentLinkByEmail = "paymentLinkByEmail",
}

/**
 * Tipo de ação ao interagir com brinde/desconto
 */
export enum ChooseGiftActionEnum {
  Add = "Add",
  Remove = "Remove",
}

/**
 * Sexo
 */
export enum CompleteInfoSex {
  F = "F",
  M = "M",
}

/**
 * Método de inicialização do atendimento
 */
export enum ConversationCreationMethod {
  Notification = "Notification",
  Queue = "Queue",
  Search = "Search",
}

/**
 * Tipo do atendimento
 */
export enum ConversationStatusEnum {
  Active = "Active",
  Receptive = "Receptive",
}

/**
 * Status atual do documento
 */
export enum DocumentCheckModel {
  Checked = "Checked",
  Pending = "Pending",
  Unchecked = "Unchecked",
}

/**
 * Tipo do documento
 */
export enum DocumentTypeModel {
  Cpf = "Cpf",
  ProofOfAddress = "ProofOfAddress",
  Rg = "Rg",
}

/**
 * Página do feedback (inícios ou ativação)
 */
export enum FvcPage {
  Activation = "Activation",
  Starts = "Starts",
}

/**
 * Tipos de campos que podem ser utilizados para compor o estado da opção. Atualmente: Status, Category, Welch, Honored, MeuClubeBoti, NonBlocked, NonCeased, InNegotiation
 */
export enum FvcQueueOptionStateFieldType {
  Category = "Category",
  Honored = "Honored",
  InNegotiation = "InNegotiation",
  MeuClubeBoti = "MeuClubeBoti",
  NonBlocked = "NonBlocked",
  NonCeased = "NonCeased",
  Status = "Status",
  Welch = "Welch",
}

/**
 * Relação de seleção entre os itens de brinde
 */
export enum GanheMaisLogicalOperator {
  And = "And",
  Or = "Or",
}

/**
 * Status de mensagem
 */
export enum MessageStatus {
  DELETED = "DELETED",
  DELIVERED = "DELIVERED",
  FAILED = "FAILED",
  PROCESSED = "PROCESSED",
  READ = "READ",
  SENT = "SENT",
  STORED = "STORED",
}

/**
 * Tipo de mensagem
 */
export enum MessageType {
  AUDIO = "AUDIO",
  DOCUMENT = "DOCUMENT",
  IMAGE = "IMAGE",
  TEXT = "TEXT",
  VIDEO = "VIDEO",
}

/**
 * Nível de criticidade da notícia
 */
export enum NewsCriticalityLevel {
  CRITICAL = "CRITICAL",
  NON_CRITICAL = "NON_CRITICAL",
  VERY_CRITICAL = "VERY_CRITICAL",
}

/**
 * Indica o projeto ao qual a notícia faz parte (inícios, ativação, etc)
 */
export enum NewsEnvironment {
  ACTIVATION = "ACTIVATION",
  START = "START",
}

/**
 * Origem do crédito
 */
export enum OriginCCRFilter {
  Bonus = "Bonus",
  Charge = "Charge",
  CommercialCampaign = "CommercialCampaign",
  Complaint = "Complaint",
  CutItem = "CutItem",
  DistributorDevolutionOrder = "DistributorDevolutionOrder",
  Due = "Due",
  EuPossoMaisCampaign = "EuPossoMaisCampaign",
  ExternalPaymentReversal = "ExternalPaymentReversal",
  ExternalSystem = "ExternalSystem",
  ICMSExemption = "ICMSExemption",
  MaterialReturn = "MaterialReturn",
  OrderCancellation = "OrderCancellation",
  OthersDevolution = "OthersDevolution",
  PointsCampaign = "PointsCampaign",
  StarterKit = "StarterKit",
  TaxReplacement = "TaxReplacement",
  VariableRemuneration = "VariableRemuneration",
}

/**
 * Período da busca
 */
export enum Period {
  CurrentMonth = "CurrentMonth",
  Today = "Today",
  Yesterday = "Yesterday",
}

/**
 * Inícios, Ativação, todos
 */
export enum QuestionEnvironment {
  ACTIVATION = "ACTIVATION",
  ALL = "ALL",
  START = "START",
}

/**
 * Tipo de endereço
 */
export enum RegistrationInfoAddressType {
  Delivery = "Delivery",
  Residential = "Residential",
}

/**
 * Tipo de contato
 */
export enum RegistrationInfoContactType {
  ContactPhone = "ContactPhone",
  Email = "Email",
  MobilePhone = "MobilePhone",
  ResidentialPhone = "ResidentialPhone",
}

/**
 * Tipo de documento
 */
export enum RegistrationInfoDocumentType {
  CPF = "CPF",
  RG = "RG",
}

/**
 * Sexo
 */
export enum RegistrationInfoSexType {
  Female = "Female",
  Male = "Male",
}

/**
 * Categoria de Ativação
 */
export enum ResellerActivationCategoryType {
  A0 = "A0",
  A1 = "A1",
  A2 = "A2",
  A3 = "A3",
  I0 = "I0",
  I1 = "I1",
  I10 = "I10",
  I11 = "I11",
  I12 = "I12",
  I13 = "I13",
  I14 = "I14",
  I15 = "I15",
  I16 = "I16",
  I17 = "I17",
  I18 = "I18",
  I19 = "I19",
  I2 = "I2",
  I20 = "I20",
  I3 = "I3",
  I4 = "I4",
  I5 = "I5",
  I6 = "I6",
  I7 = "I7",
  I8 = "I8",
  I9 = "I9",
}

/**
 * Feedback da Ativação
 */
export enum ResellerActivationFeedbackType {
  Abandoned = "Abandoned",
  AnotherTopic = "AnotherTopic",
  Complaint = "Complaint",
  Compliment = "Compliment",
  CustomFeedback = "CustomFeedback",
  Fail = "Fail",
  Fraud = "Fraud",
  FutureContact = "FutureContact",
  Negotiating = "Negotiating",
  NoAnswer = "NoAnswer",
  NoContact = "NoContact",
  Ordered = "Ordered",
  OrderedFVC = "OrderedFVC",
  Predicted = "Predicted",
  Quarantine = "Quarantine",
  RegistrationCheck = "RegistrationCheck",
  Relationship = "Relationship",
  RequestedInfo = "RequestedInfo",
  Support = "Support",
  WillPay = "WillPay",
  WontBuy = "WontBuy",
  WontPay = "WontPay",
}

/**
 * Potencial (farol) da revendedora
 */
export enum ResellerActivationPotencialType {
  Completed = "Completed",
  CouldOrderMore = "CouldOrderMore",
  DidNotOrder = "DidNotOrder",
}

export enum ResellerERZone {
  Cold = "Cold",
  Dead = "Dead",
  Hot = "Hot",
  Warm = "Warm",
}

/**
 * Tipo do identificador
 */
export enum ResellerIdentifierType {
  CPF = "CPF",
  GERA = "GERA",
  RG = "RG",
}

/**
 * Status do atendimento via WhatsApp
 */
export enum ResellerInteractionStatus {
  CHATTING = "CHATTING",
  FINISHED = "FINISHED",
  NEED_ANSWER = "NEED_ANSWER",
}

export enum ResellerListRegistrationOrigin {
  App = "App",
  External = "External",
}

export enum ResellerListRegistrationStatus {
  Approved = "Approved",
  ChangesRequest = "ChangesRequest",
  PendingDocuments = "PendingDocuments",
  PendingResellerAction = "PendingResellerAction",
  PendingReview = "PendingReview",
  Refused = "Refused",
}

/**
 * Nível no sistema de recompensas Meu Clube Boti
 */
export enum ResellerMeuClubeBotiEnum {
  Blue = "Blue",
  Diamante = "Diamante",
  Esmeralda = "Esmeralda",
  Gold = "Gold",
  Rose = "Rose",
}

/**
 * Tipo da conquista
 */
export enum RewardTypeEnum {
  Absolute = "Absolute",
  FinalValue = "FinalValue",
  Gift = "Gift",
  Percentage = "Percentage",
}

/**
 * Situação do crédito
 */
export enum SituationCCRFilter {
  Active = "Active",
  Canceled = "Canceled",
  Inactive = "Inactive",
}

/**
 * Status do crédito residual
 */
export enum StatusCCREntry {
  ATIVO = "ATIVO",
  CANCELADO = "CANCELADO",
  INATIVO = "INATIVO",
}

/**
 * Tipos de card para apresentar
 */
export enum SuperplanoCardTypeEnum {
  incentive = "incentive",
  promotion = "promotion",
  redemption = "redemption",
}

/**
 * Tipo da descrição da promoção
 */
export enum SuperplanoDescriptionType {
  html = "html",
  text = "text",
}

/**
 * Ambientes disponíveis (inícios ou ativação)
 */
export enum TemplateEnvironment {
  ACTIVATION = "ACTIVATION",
  ALL = "ALL",
  START = "START",
}

/**
 * Ordenação dos titulos
 */
export enum TitleOrder {
  EndDateAscending = "EndDateAscending",
  EndDateDescending = "EndDateDescending",
  None = "None",
  NumberAscending = "NumberAscending",
  NumberDescending = "NumberDescending",
  ResellerAscending = "ResellerAscending",
  StartDateAscending = "StartDateAscending",
  StartDateDescending = "StartDateDescending",
}

/**
 * Situação do pagamento
 */
export enum TitlePaymentSituation {
  Canceled = "Canceled",
  Open = "Open",
}

/**
 * Situação do titulo
 */
export enum TitleSituation {
  Expired = "Expired",
  NotExpired = "NotExpired",
}

/**
 * Tipo do crédito
 */
export enum TypeCCRFilter {
  Credit = "Credit",
  Debit = "Debit",
}

/**
 * Feedback necessário para finalizar um atendimento
 */
export interface ActivationInput {
  feedbackId: number;
  scheduledTo?: any | null;
  note?: string | null;
  byWhatsApp?: boolean | null;
  byTelephone?: boolean | null;
  requestedInfo?: string | null;
  feedbackDetail?: string | null;
  status: ConversationStatusEnum;
}

/**
 * Produto que será incluído no carrinho
 */
export interface AddBatchProductItemInput {
  productCode: string;
  originId: string;
  comboProducts?: ComboProductInput[] | null;
  quantity: number;
}

/**
 * Parâmetros para inclusão de um item no carrinho
 */
export interface AddBatchToCartInput {
  orderCode: string;
  items?: AddBatchProductItemInput[] | null;
}

/**
 * Parâmetros para inclusão de um item no carrinho
 */
export interface AddToCartInput {
  productCode: string;
  originId: string;
  comboProducts?: ComboProductInput[] | null;
  quantity: number;
  orderCode: string;
}

/**
 * Endereço
 */
export interface AddressInput {
  street?: string | null;
  streetNumber?: string | null;
  zipCode?: string | null;
  neighborhood?: string | null;
  complement?: string | null;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  referencePoint?: string | null;
}

export interface AddressUpdateInput {
  address: AddressInput;
}

export interface AdminAnswerInput {
  questionOrder: string;
  answer: string;
}

/**
 * Dados para autenticar o usuário no App supervisor
 */
export interface AuthInput {
  code: string;
}

export interface CartHandlerParamsInput {
  orderActions?: CartHandlerActionParams | null;
}

/**
 * Parâmetros para troca de quantidade de um item no carrinho
 */
export interface ChangeProductQuantityInput {
  productCode: string;
  originId: string;
  comboProducts?: ComboProductInput[] | null;
  quantity: number;
  orderCode: string;
  sequentialNumber: string;
}

/**
 * Dados para escolha da opção de entrega (normal, expressa, etc...)
 */
export interface ChooseFreightOptionInput {
  resellerId: string;
  orderCode: string;
  addressTypeId: string;
  isWithdrawalCenter: boolean;
  isSupportCenter: boolean;
  freightOptionCode: string;
}

/**
 * Escolhe o brinde/desconto para uma promoção. Quando escolhendo brindes, é necessário enviar todos as opções dos produtos, mesmo os não escolhidos (estes com quantidade 0).
 */
export interface ChooseGiftInput {
  orderCode: string;
  promotionCode: string;
  giftNumber: string;
  giftItemNumber: string;
  action: ChooseGiftActionEnum;
  isDiscount: boolean;
  products?: ChooseGiftProductInput[] | null;
  acceptReorder: boolean;
}

/**
 * Brinde escolhido (ou disponível para escolha)
 */
export interface ChooseGiftProductInput {
  code?: string | null;
  quantity?: number | null;
}

/**
 * Dados para escolha da distribuição do pagamento
 */
export interface ChooseMultiplePaymentOptionInput {
  distributionCodeDetails?: number | null;
  amount: number;
  isTicketDistributionPlan: boolean;
  paymentIdSelected: number;
  numberPayment?: number | null;
}

/**
 * Dados para escolha da distribuição do pagamento
 */
export interface ChooseMultiplePaymentOptionsInput {
  orderCode: string;
  options: ChooseMultiplePaymentOptionInput[];
}

/**
 * Dados para escolha do endereço de entrega
 */
export interface ChooseOrderAddressInput {
  resellerId: string;
  orderCode: string;
  addressTypeId: string;
  isWithdrawalCenter: boolean;
  isSupportCenter: boolean;
}

/**
 * Dados para escolha do plano de pagamento
 */
export interface ChoosePaymentPlanInput {
  orderCode: string;
  paymentPlanCode: string;
  paymentModeId: string;
  installmentsQuantity: number;
  creditCardTypeId?: string | null;
}

/**
 * Parâmetros para inclusão de itens de combo no carrinho
 */
export interface ComboProductInput {
  selectionCode?: string | null;
  productCode: string;
  quantity: number;
}

/**
 * Dados para a criação de um atendimento
 */
export interface ConversationInput {
  resellerId: string;
  feedbackId: string;
  status: ConversationStatusEnum;
  note?: string | null;
  scheduling?: SchedulingInput | null;
}

/**
 * Dados para criação de uma nova franquia
 */
export interface CreateFranchiseInput {
  referenceId: string;
  franchiseName: string;
  active?: boolean | null;
}

/**
 * Dados para a criação de uma notícia
 */
export interface CreateNewsInput {
  title: string;
  message: string;
  criticalityLevel?: NewsCriticalityLevel | null;
  environments?: NewsEnvironment[] | null;
  isPublic?: boolean | null;
  isEnable?: boolean | null;
  references?: string[] | null;
}

/**
 * Dados para a criação de uma promoção
 */
export interface CreatePromotionInput {
  title: string;
  description: string;
  startDate: any;
  endDate: any;
}

export interface CreatePublicMediaInput {
  name: string;
  description?: string | null;
  mediaId: string;
  type: string;
}

/**
 * Dados para a criação de uma etapa para o roteiro de atendimento
 */
export interface CreateScriptStepInput {
  step: string;
  instruction: string;
}

export interface DeletePublicMediaInput {
  id?: number | null;
}

export interface DocumentStatusInput {
  type: DocumentTypeModel;
  check: DocumentCheckModel;
}

/**
 * Dados para criação de uma nova franquia
 */
export interface EditFranchiseInput {
  referenceId?: string | null;
  franchiseName?: string | null;
  active?: boolean | null;
}

/**
 * Dados para edição de uma notícia
 */
export interface EditNewsInput {
  title?: string | null;
  message?: string | null;
  criticalityLevel?: NewsCriticalityLevel | null;
  environments?: NewsEnvironment[] | null;
  isPublic?: boolean | null;
  isEnable?: boolean | null;
  references?: string[] | null;
}

/**
 * Dados para a edição de uma promoção
 */
export interface EditPromotionInput {
  title?: string | null;
  description?: string | null;
  startDate?: any | null;
  endDate?: any | null;
}

/**
 * Dados para a edição de uma etapa do roteiro de atendimento
 */
export interface EditScriptStepInput {
  step?: string | null;
  instruction?: string | null;
}

/**
 * Dados para edição ou criação de uma central de serviços
 */
export interface EditServiceCenterInput {
  cpCode: string;
  csCode: string;
  answers: AdminAnswerInput[];
}

export interface FeedbackInput {
  id?: number | null;
  title: string;
  hasContactForm: boolean;
  hasNote: boolean;
  hasScheduling?: boolean | null;
  visibility?: ActivationFeedbackVisibilty | null;
  isRequiredNote?: boolean | null;
  geraCode?: string | null;
  nCallCode?: string | null;
  priority?: number | null;
  active?: boolean | null;
  details?: string[] | null;
  noTabulation?: boolean | null;
}

/**
 * Feedback necessário para finalizar um atendimento
 */
export interface FvcEudoraFeedbackInput {
  feedbackId: number;
  scheduledTo?: any | null;
  note?: string | null;
  byWhatsApp?: boolean | null;
  byTelephone?: boolean | null;
  requestedInfo?: string | null;
  status: ConversationStatusEnum;
}

export interface InstantMessageTemplatesInput {
  name?: string | null;
  nextPageToken?: string | null;
  previousPageToken?: string | null;
}

/**
 * Dados para a criação de um atendimento
 */
export interface IrConversationFeedbackInput {
  feedbackId: string;
  note?: string | null;
  byTelephone?: boolean | null;
  byWhatsApp?: boolean | null;
  status: ConversationStatusEnum;
}

export interface MediaDownloadUrlInput {
  mediaId: string;
}

export interface MediaUploadUrlInput {
  type: string;
  mediaId?: string | null;
}

/**
 * Parâmetros de busca de um produto para a revendedora
 */
export interface ProductSearchInput {
  codeOrTerm: string;
  distributionCenterCode: string;
  cycle: string;
  resellerId: string;
}

export interface QueueOptionInput {
  id?: number | null;
  state?: QueueOptionStateFieldInput[] | null;
  geraStructures: string[];
  isEnabled: boolean;
  name: string;
  description?: string | null;
}

export interface QueueOptionStateFieldInput {
  type: FvcQueueOptionStateFieldType;
  position: number;
  statusLevel?: ResellerActivationCategoryType | null;
  categoryId?: number | null;
  meuClubeBotiLevel?: ResellerMeuClubeBotiEnum | null;
}

/**
 * Dados para remoção de um produto do pedido da revendedora
 */
export interface RemoveFromCartInput {
  productCode: string;
  originId: string;
  comboProducts?: ComboProductInput[] | null;
  orderCode?: string | null;
  sequentialNumber?: string | null;
}

/**
 * Dados para remoção de uma distribuição de pagamento
 */
export interface RemoveMultiplePaymentOptionInput {
  orderCode: string;
  distributionCodeDetails: number;
}

/**
 * Dados para editar as informações de uma revendedora
 */
export interface ResellerContactInfoUpdateInput {
  name?: string | null;
  contactPhone?: string | null;
  mobilePhone?: string | null;
  residentialPhone?: string | null;
}

export interface ResellerCreditCCRFiltersInput {
  origin?: OriginCCRFilter | null;
  situation?: SituationCCRFilter | null;
  type?: TypeCCRFilter | null;
  usedEntry?: boolean | null;
}

/**
 * Dados para a criação de um agendamento
 */
export interface SchedulingInput {
  dateAndTime: any;
  contactPhone: string;
}

/**
 * Dados para envio de uma mensagem de interação (WhatsApp)
 */
export interface SendMessageInput {
  message: string;
  resellerId: string;
  mediaId?: string | null;
  mediaUrl?: string | null;
  type?: MessageType | null;
}

/**
 * Dados para envio de uma mensagem template (WhatsApp)
 */
export interface SendTemplateMessageInput {
  resellerId: string;
  templateName: string;
  variables?: TemplateVariablesInput | null;
}

/**
 * Dados para adicionar um cupom de desconto
 */
export interface SetCouponInput {
  orderCode?: string | null;
  couponNumber?: string | null;
}

export interface ShowPublicMediaInput {
  id?: number | null;
}

/**
 * Parâmetros para iniciar pedido da revendedora
 */
export interface StartOrderInput {
  resellerId: string;
  cycle: string;
  distributionCenterCode: string;
  application?: ApplicationTypeEnum | null;
  pickupOnDistributionCenter: boolean;
  changingDeliveryMode?: boolean | null;
  businessModelCode?: number | null;
  subcycleId?: string | null;
  copyCanceledLastOrder?: boolean | null;
  editOrderCode?: string | null;
  starterPacks?: StartOrderStarterPackInput[] | null;
}

/**
 * Escolha do kit início
 */
export interface StartOrderStarterPackInput {
  id?: string | null;
  productId?: string | null;
}

export interface TemplateVariablesInput {
  header?: VariablesInput[] | null;
  body?: VariablesInput[] | null;
  buttons?: VariablesInput[] | null;
}

/**
 * Filtros de titulos de revendedora
 */
export interface TitleListFilterInput {
  paymentSituation?: TitlePaymentSituation | null;
  titleSituation?: TitleSituation | null;
  start?: any | null;
  end?: any | null;
  titleOrder?: TitleOrder | null;
}

export interface UpdatePublicMediaInput {
  id: number;
  name?: string | null;
  description?: string | null;
  mediaId?: string | null;
  type?: string | null;
}

/**
 * Dados apara criar uma entrada de horario comercial
 */
export interface UpsertCommercialTimeInput {
  id?: number | null;
  weekDay: number;
  startHour: string;
  stopHour: string;
}

/**
 * Dados para a criação ou edição de um feedback
 */
export interface UpsertFeedbackInput {
  id?: number | null;
  title: string;
  active: boolean;
  page: FvcPage;
  priority?: number | null;
  feedbacks: FeedbackInput[];
}

export interface UpsertMessageTemplateInput {
  id?: number | null;
  name: string;
  friendlyName: string;
  message: UpsertTemplateMessage;
  messageVariables?: UpsertTemplateVariables | null;
  environment: TemplateEnvironment;
}

export interface UpsertTemplateMessage {
  header?: string | null;
  body: string;
  footer?: string | null;
  buttons?: string[] | null;
}

export interface UpsertTemplateVariables {
  header?: UpsertVariables[] | null;
  body?: UpsertVariables[] | null;
  buttons?: UpsertVariables[] | null;
}

export interface UpsertVariables {
  key?: string | null;
  index?: number | null;
  defaultValue?: string | null;
  link?: string | null;
  mediaId?: string | null;
  type?: string | null;
  isEditable: boolean;
}

export interface VariablesInput {
  key?: string | null;
  index?: number | null;
  value?: string | null;
  link?: string | null;
  mediaId?: string | null;
  type?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
