import * as React from 'react';

export const IcWhatsapp = (props) => (
  <svg width={24} height={24} viewBox='0 0 24 24' fill={props.fill || '#000'} {...props}>
    <path
      d='M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14H6l-2 2V4h16v12z'
      fill={props.color || '#525252'}
      fillRule='nonzero'
    />
  </svg>
);
