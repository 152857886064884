import * as React from 'react';

export const IcTrash = (props) => (
  <svg viewBox='0 0 20 20' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12 2.018V3.48h3.385c.34 0 .615.328.615.732 0 .404-.275.731-.615.731h-.718v11.712c0 .723-.667 1.454-1.334 1.454H2.667c-.642 0-1.334-.745-1.334-1.455V4.944H.615c-.34 0-.615-.328-.615-.732 0-.404.275-.732.615-.732H4V2.018C4 1.253 4.676.555 5.333.555h5.334c.648 0 1.333.73 1.333 1.463zm-6.667.731c0-.404.299-.731.667-.731h4c.368 0 .667.327.667.731v.731H5.333V2.75zm7.334 13.897c.368 0 .666-.327.666-.731V4.943H2.667v10.98c0 .404.298.731.666.731l9.334-.008zM4 7.87c0-.404.299-.731.667-.731.368 0 .666.327.666.731v5.852c0 .403-.298.731-.666.731-.368 0-.667-.328-.667-.731V7.869zm6.667 5.852V7.869c0-.404.298-.731.666-.731.368 0 .667.327.667.731v5.852c0 .403-.299.731-.667.731-.368 0-.666-.328-.666-.731zM8 7.138c-.368 0-.667.327-.667.731v5.852c0 .403.299.731.667.731.368 0 .667-.328.667-.731V7.869c0-.404-.299-.731-.667-.731z'
      fill='#32363E'
    />
  </svg>
);
