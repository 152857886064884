import { Container } from 'typedi';
import { AppConfig } from '@app/config-variables';
import { HttpClient, HttpRequestBuilder } from '@app/core/http';
import { JsonContentHeaderInterceptor, MiddlewareTokenHttpInterceptor } from '../interceptor';

export class SgiPaymentRequestBuilder extends HttpRequestBuilder {}

export function configPaymentRequestBuilder(env: AppConfig) {
  const baseUrl = env.paymentApiUrl;
  const client = Container.get(HttpClient);

  const interceptors = [new JsonContentHeaderInterceptor(), new MiddlewareTokenHttpInterceptor()];

  const builder = new SgiPaymentRequestBuilder().configure({ baseUrl, client, interceptors });
  Container.set(SgiPaymentRequestBuilder, builder);
}
