import { Theme } from '@atomic/obj.constants';
import * as React from 'react';
import { Col as FlexCol, Grid as FlexGrid, Row as FlexRow } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

export interface RowProps extends Theme {
  mb?: boolean;
  mt?: boolean;
  noGutter?: boolean;
}

const RowStyled = styled(FlexRow)`
  margin-bottom: ${(props: RowProps) => (props.mb ? props.theme.Spacing.Medium : '0px')};
  margin-top: ${(props: RowProps) => (props.mt ? props.theme.Spacing.Medium : '0px')};

  ${(props: RowProps) => {
    if (props.noGutter) {
      return `overflow: hidden;
              margin-left: -${rowMargin}rem;
              margin-right: -${rowMargin}rem;`;
    } else {
      return '';
    }
  }}
`;

export const Grid = (props) => <FlexGrid {...props} />;
export const Col = (props) => <FlexCol {...props} />;
export const Row = (props) => <RowStyled {...props} />;

export const GridSettings = {
  flexboxgrid: {
    // rem
    breakpoints: {
      // em
      lg: 64,

      // em
      md: 48,

      // em
      sm: 32,

      xs: 0, // em
    },

    gridSize: 12,

    // Defaults
    gutterWidth: 1,
    // rem
    outerMargin: 2,
  },
};

export const rowMargin = GridSettings.flexboxgrid.outerMargin + GridSettings.flexboxgrid.gutterWidth / 2;

interface VSeparatorProps extends Theme {
  small?: boolean;
}
export const VSeparator = styled.div`
  padding-top: ${(props: VSeparatorProps) => (props.small ? props.theme.Spacing.Small : props.theme.Spacing.Medium)};
`;
