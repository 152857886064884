import styled from 'styled-components';

const INFO_MAX_WIDTH = '250px';

export const BodySecondaryEllipsis = styled.p`
  color: ${(props) => props.theme.Color.Gray};
  font-size: ${(props) => props.theme.FontSize.XSmall};
  font-family: ${(props) => props.theme.FontFamily.Primary};
  font-weight: ${(props) => props.theme.FontWeight.Normal};
  line-height: 1.5;
  margin: 0;
  overflow: hidden;
  max-width: ${INFO_MAX_WIDTH};
  text-overflow: ellipsis;
  white-space: nowrap;
  &:hover {
    overflow: show;
    white-space: normal;
  }
`;
