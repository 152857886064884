import { ThemeToken } from '@atomic/obj.constants';
import { HighlightStyle } from '@atomic/obj.mixin';
import * as ColorFunc from 'color';
import styled from 'styled-components';
import Container from 'typedi';
import { NewsCriticalityLevel } from '@app/data/graphql';

const theme = Container.get(ThemeToken);

const bannerMessageTypes = {
  [NewsCriticalityLevel.NON_CRITICAL]: theme.Color.Success,
  [NewsCriticalityLevel.CRITICAL]: theme.Color.Warning,
  [NewsCriticalityLevel.VERY_CRITICAL]: theme.Color.Alert,
};

export const BannerMessageContentStyled = styled.div`
  ${HighlightStyle}

  &:hover > span {
    color: ${(props) => ColorFunc(bannerMessageTypes[props.type]).darken(0.5).string()};
  }
`;

export const BannerMessageCloseStyled = styled.div`
  ${HighlightStyle}

  &:hover > span {
    color: ${(props) => ColorFunc(bannerMessageTypes[props.type]).darken(0.5).string()};
  }
`;

export const BannerMessageStyled = styled.div`
  background-color: ${(props: { criticalityLevel: NewsCriticalityLevel }) =>
    ColorFunc(bannerMessageTypes[props.criticalityLevel]).lighten(0.4).string()};
  border-radius: ${(props) => props.theme.Border.Radius};
  padding: ${(props) => props.theme.Spacing.Medium};
  font-family: ${(props) => props.theme.FontFamily.Primary};
  font-weight: ${(props) => props.theme.FontWeight.Normal};
  font-size: ${(props) => props.theme.FontSize.Small};
  transition: opacity 0.3s ease-in-out;

  p,
  svg {
    color: ${(props: { criticalityLevel: NewsCriticalityLevel }) =>
      ColorFunc(bannerMessageTypes[props.criticalityLevel]).darken(0.3).string()};
  }
`;

const breakpoint = '25em';

export const BannerMessageWrapperStyled = styled.div`
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  top: ${(props) => props.theme.Spacing.Medium};
  z-index: ${(props) => props.theme.Zindex.BannerMessageWrapperStyled};

  @media all and (min-width: ${breakpoint}) {
    max-width: ${breakpoint};
  }
`;
