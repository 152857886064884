import { ApolloError, NetworkStatus } from '@apollo/client';
import { FlashDispatcherService } from '@app/components/obj.flash-wrapper/flash-dispatcher.service';
import { removeTmzFromDateString } from '@app/components/obj.formatter/date-formatter';
import { parseNumberValue } from '@app/components/obj.formatter/number-formatter';
import { useCheckoutResetContentErrorHandler } from '@app/components/orders/checkout-reset-content-error.hook';
import { useLazyQueryHook, useMutationHook, useQueryHook } from '@app/core/graphql';
import {
  AddBatchProductItemInput,
  AddBatchToCart,
  AddBatchToCartVariables,
  AddToCart,
  AddToCartVariables,
  ApplicationTypeEnum,
  ApplyPromotions,
  ApplyPromotionsVariables,
  CancelOrder,
  CancelOrderVariables,
  Cart,
  CartState,
  CartStateVariables,
  ChangeProductQuantity,
  ChangeProductQuantityVariables,
  ComboDetail,
  ComboDetailVariables,
  ComboProductInput,
  EudoraProductSearch,
  EudoraProductSearchVariables,
  EudoraProductType,
  OrderCycles,
  ProductResponse,
  ProductSearch,
  ProductSearchVariables,
  RemoveFromCart,
  RemoveFromCartInput,
  RemoveFromCartVariables,
  ResellerCurrentOrderCycles,
  ResellerCurrentOrderCyclesVariables,
  ResellerOrderStatus,
  ResellerOrderStatusVariables,
  ResellersOrderList,
  ResellersOrderListVariables,
  ResellersOrderPaginated,
  ResellersOrderPaginatedVariables,
  SetCoupon,
  SetCouponVariables,
  StartOrder,
  StartOrderInput,
  StartOrderStarterPackInput,
  StartOrderVariables,
} from '@app/data/graphql';
import { HomeQueryArguments } from '@app/interfaces';
import { Orders, OrderStatusData } from '@app/models/orders.model';
import { QueueContext } from '@app/providers/queue.provider';
import { OrderAddress } from '@app/view-model/order-address/order-address.vm';
import { mapDeliveryAddresses } from '@app/view-model/order-address/order-address.vm.mapper';
import * as React from 'react';
import Container from 'typedi';

export const mapResellerOrders = (data: ResellersOrderList) => {
  if (data?.ResellersOrderList?.nodes?.length === 0 || !data?.ResellersOrderList?.nodes) {
    return null;
  }

  const ordersList: Orders[] = data.ResellersOrderList?.nodes.map((order) => ({
    cycle: order.cicle,
    date: removeTmzFromDateString(order?.date),
    number: order.id,
    price: parseNumberValue(order?.total, true, 2),
    status: order.status,
  }));

  return ordersList;
};

export const useCancelOrderMutation = ({
  onCompleted,
  onError,
}: {
  onCompleted: (data: CancelOrder) => void;
  onError?: (error: ApolloError) => void;
}) => {
  const [mutate, cancelResult] = useMutationHook<CancelOrder, CancelOrderVariables>({
    mutationName: 'cancel-order.mutation',
    options: {
      notifyOnNetworkStatusChange: true,
      onCompleted,
      onError,
      refetchQueries: ['ResellerOrderDetail', 'ResellerCurrentOrder'],
    },
  });

  const cancelOrder = (orderCode: string) => {
    mutate({ variables: { orderCode } });
  };

  return { cancelOrder, cancelResult };
};

export const useResellerOrdersListQuery = ({ id }: HomeQueryArguments) => {
  const result = useQueryHook<ResellersOrderList, ResellersOrderListVariables>('resellers-order-list.query', {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    skip: !id,
    variables: { resellerId: id },
  });
  return {
    data: mapResellerOrders(result.data),
    error: result.error,
    loading: result.loading || result.networkStatus === NetworkStatus.refetch,
    networkStatus: result.networkStatus,
    refetch: () => result.refetch(),
  };
};

export const mapResellerOrdersPaginatedQuery = (data: ResellersOrderPaginated) => {
  if (data?.ResellersOrderListPaginated?.nodes?.length === 0 || !data?.ResellersOrderListPaginated?.nodes) {
    return null;
  }

  const ordersList: Orders[] = data.ResellersOrderListPaginated?.nodes.map((order) => ({
    cycle: order.cicle,
    date: removeTmzFromDateString(order.date),
    number: order.id,
    price: parseNumberValue(order.total, true, 2),
    status: order.status,
  }));

  return {
    ordersList,
    totalOrders: data.ResellersOrderListPaginated?.count,
  };
};

export const useResellerOrdersPaginatedQuery = (resellerId: string, offset: number, limit: number) => {
  const result = useQueryHook<ResellersOrderPaginated, ResellersOrderPaginatedVariables>(
    'resellers-order-paginated.query',
    {
      notifyOnNetworkStatusChange: true,
      variables: { limit, offset, resellerId },
    },
  );
  return {
    data: mapResellerOrdersPaginatedQuery(result.data),
    error: result.error,
    loading: result.loading || result.networkStatus === NetworkStatus.refetch,
    networkStatus: result.networkStatus,
    refetch: () => result.refetch(),
  };
};

interface StartOrderParams {
  application?: ApplicationTypeEnum;
  resellerId: string;
  cycle: string;
  distributionCenterCode: string;
  pickupOnDistributionCenter: boolean;
  changingDeliveryMode?: boolean;
  starterPacks?: StartOrderStarterPackInput[];
  businessModelCode?: number;
  subcycleId?: string;
  copyCanceledLastOrder?: boolean;
  editOrderCode?: string;
}

export const useStartOrderMutation = ({
  startOrderParams,
  onCompleted,
  onError,
}: {
  startOrderParams: StartOrderParams;
  onCompleted: (cart: Cart) => void;
  onError?: (error: ApolloError) => void;
  skip?: boolean;
}) => {
  const input: StartOrderInput = startOrderParams;

  const [startOrder, result] = useMutationHook<StartOrder, StartOrderVariables>({
    mutationName: 'start-order.mutation',
    options: {
      onCompleted: (data: { StartOrder: Cart }) => onCompleted(data.StartOrder),
      onError,
      variables: {
        data: input,
      },
    },
  });

  const updateOrder = React.useCallback(
    (variables?: StartOrderVariables) => {
      startOrder({ variables });
    },
    [startOrder],
  );

  return {
    called: result.called,
    data: result.data?.StartOrder,
    error: result.error,
    loading: result.loading,
    updateOrder,
  };
};

export const useClearOrderMutation = ({
  startOrderParams,
  onCompleted,
  onError,
}: {
  startOrderParams: StartOrderParams;
  onCompleted: (cart: Cart) => void;
  onError?: () => void;
}) => {
  const input: StartOrderInput = startOrderParams;

  const [clearOrder, result] = useMutationHook<StartOrder, StartOrderVariables>({
    mutationName: 'start-order.mutation',
    options: {
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      onCompleted: (data: { StartOrder: Cart }) => onCompleted(data.StartOrder),
      onError: (error: ApolloError) => {
        const flashMessageService = Container.get(FlashDispatcherService);
        flashMessageService.dispatchMessage({ message: error?.message }, 'alert');
        onError?.();
      },
      refetchQueries: ['ResellerCurrentOrder, ResellerCurrentOrders'],
      variables: {
        data: { ...input, changingDeliveryMode: true, copyCanceledLastOrder: false },
      },
    },
  });

  return {
    called: result.called,
    clearOrder,
    data: result.data?.StartOrder,
    error: result.error,
    loading: result.loading,
  };
};

export const useLazyCartStateQuery = ({
  onCompleted,
  onError,
}: {
  onCompleted: (newCart: Cart) => void;
  onError?: (error: ApolloError) => void;
}) => {
  const [query, result] = useLazyQueryHook<CartState, CartStateVariables>('cart-state', {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data: CartState) => onCompleted(data.CartState),
    onError,
  });

  const getCartState = React.useCallback((orderCode: string) => query({ variables: { orderCode } }), [query]);

  return {
    called: result.called,
    data: result.data?.CartState,
    error: result.error,
    getCartState,
    loading: result.loading,
    refetch: () => {
      result.refetch();
    },
  };
};

export const useCartStateQuery = ({
  orderCode,
  onCompleted,
  onError,
}: {
  orderCode: string;
  onCompleted?: (newCart: Cart) => void;
  onError?: (error: ApolloError) => void;
}) => {
  const result = useQueryHook<CartState, CartStateVariables>('cart-state', {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => onCompleted?.(data.CartState),
    onError,
    skip: !orderCode,
    variables: { orderCode },
  });

  return {
    data: result.data?.CartState,
    error: result.error,
    loading: result.loading,
    refetch: () => result.refetch(),
  };
};

export const SALE_ORIGIN = '1';

export interface ChangeCartProductsParams {
  productCode: string;
  quantity: number;
  comboProducts?: ComboProductInput[];
  originId?: string;
  sequentialNumber?: string;
}

export const useAddToCartMutation = ({
  orderCode,
  onCompleted,
  onError,
}: {
  orderCode: string;
  onCompleted: (cart: Cart) => void;
  onError?: (error: ApolloError) => void;
}): {
  addToCart: (data: ChangeCartProductsParams) => void;
  loading: boolean;
  error: ApolloError;
} => {
  const [mutate, result] = useMutationHook<AddToCart, AddToCartVariables>({
    mutationName: 'add-to-cart.mutation',
    options: {
      errorPolicy: 'all',
      onCompleted: (data) => onCompleted(data.AddToCart),
      onError: (error) => {
        onError?.(error);
      },
    },
  });

  const addToCart = (data: ChangeCartProductsParams) => {
    mutate({
      variables: {
        data: {
          comboProducts: data.comboProducts,
          orderCode,
          originId: SALE_ORIGIN,
          productCode: data.productCode,
          quantity: data.quantity,
        },
      },
    });
  };

  return { addToCart, error: result.error, loading: result.loading };
};

export const ADD_BATCH_TO_CART_TIMEOUT = 1000 * 60 * 1.5;

export const useAddBatchToCartMutation = ({
  orderCode,
  onCompleted,
  onError,
}: {
  orderCode: string;
  onCompleted: (data: AddBatchToCart) => void;
  onError?: (error: ApolloError) => void;
}) => {
  const [mutate, result] = useMutationHook<AddBatchToCart, AddBatchToCartVariables>({
    mutationName: 'add-batch-to-cart',
    options: {
      context: { timeout: ADD_BATCH_TO_CART_TIMEOUT },
      onCompleted,
      onError,
    },
  });

  const addBatchToCart = (items: AddBatchProductItemInput[]) => {
    mutate({
      variables: {
        data: {
          items,
          orderCode,
        },
      },
    });
  };

  return {
    addBatchToCart,
    error: result.error,
    loading: result.loading,
  };
};

export const useResellerOrderCyclesQuery = (
  resellerId: string,
  onCompleted?: (data: OrderCycles) => void,
  onError?: (error: ApolloError) => void,
) => {
  const result = useQueryHook<ResellerCurrentOrderCycles, ResellerCurrentOrderCyclesVariables>(
    'reseller-current-order-cycles.query',
    {
      onCompleted: (data) => onCompleted?.(data.ResellerCurrentOrderCycles),
      onError,
      variables: {
        resellerId,
      },
    },
  );

  return {
    data: result.data?.ResellerCurrentOrderCycles,
    error: result.error,
    loading: result.loading || result.networkStatus === NetworkStatus.refetch,
    refetch: () => result.refetch(),
  };
};

export const useChangeProductQuantityMutation = ({
  orderCode,
  onCompleted,
  onError,
}: {
  orderCode: string;
  onCompleted: (cart: Cart) => void;
  onError?: (error: ApolloError) => void;
}): { changeProductQuantity: (data: ChangeCartProductsParams) => void; loading: boolean; error: ApolloError } => {
  const [mutate, result] = useMutationHook<ChangeProductQuantity, ChangeProductQuantityVariables>({
    mutationName: 'change-product-quantity.mutation',
    options: {
      onCompleted: (data) => onCompleted(data.ChangeProductQuantity),
      onError,
    },
  });

  const changeProductQuantity = (data: ChangeCartProductsParams) => {
    mutate({
      variables: {
        data: {
          comboProducts: data.comboProducts,
          orderCode,
          originId: data.originId,
          productCode: data.productCode,
          quantity: data.quantity,
          sequentialNumber: data.sequentialNumber,
        },
      },
    });
  };
  return { changeProductQuantity, error: result.error, loading: result.loading };
};

type UseProductSearchResponse = [
  (codeOrTerm: string) => void,
  {
    loading: boolean;
    data: ProductResponse[];
    error: ApolloError;
  },
];

export const useProductSearchQuery = (distributionCenterCode: string, cycle: string): UseProductSearchResponse => {
  const resellerId = React.useContext(QueueContext)?.activeIndex;
  const [query, result] = useLazyQueryHook<ProductSearch, ProductSearchVariables>('products-search.query', {
    fetchPolicy: 'no-cache',
  });

  const search = React.useCallback(
    (codeOrTerm: string) => {
      query({
        variables: {
          data: {
            codeOrTerm,
            cycle,
            distributionCenterCode,
            resellerId,
          },
        },
      });
    },
    [cycle, resellerId, distributionCenterCode, query],
  );

  return [
    search,
    {
      data: result.data?.ProductSearch,
      error: result.error,
      loading: result.loading,
    },
  ];
};

interface UseEudoraProductSearchResponse {
  loading: boolean;
  data: EudoraProductType[];
  error: ApolloError;
  search: (codeOrTerm: string) => void;
}

export const useEudoraProductSearchQuery = ({
  orderCode,
  onCompleted,
  onError,
}: {
  orderCode: string;
  onCompleted?: (result: EudoraProductSearch) => void;
  onError?: (error: ApolloError) => void;
}): UseEudoraProductSearchResponse => {
  const [query, result] = useLazyQueryHook<EudoraProductSearch, EudoraProductSearchVariables>(
    'eudora-products-search.query',
    {
      fetchPolicy: 'no-cache',
      onCompleted,
      onError,
    },
  );

  const search = React.useCallback(
    (codeOrName: string) => {
      query({
        variables: {
          codeOrName,
          orderCode,
        },
      });
    },
    [orderCode, query],
  );

  return {
    data: result.data?.EudoraProductSearch,
    error: result.error,
    loading: result.loading,
    search,
  };
};

export const useComboDetailLazyQuery = (orderCode: string, onCompleted?: (comboDetail: ComboDetail) => void) => {
  const [query, result] = useLazyQueryHook<ComboDetail, ComboDetailVariables>('combo-detail', {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    onCompleted,
  });

  const getComboDetail = (codeCombo: string) => {
    query({ variables: { codeCombo, orderCode } });
  };

  return {
    called: result.called,
    data: result.data,
    error: result.error,
    getComboDetail,
    loading: result.loading || result.networkStatus === NetworkStatus.refetch,
    refetch: () => result.refetch(),
  };
};

export const useRemoveFromCartMutation = ({
  orderCode,
  onSuccess,
  onError,
}: {
  orderCode: string;
  onSuccess: (cart: Cart) => void;
  onError?: (error?: ApolloError) => void;
}): { removeFromCart: (data: RemoveFromCartInput) => void; loading: boolean; error: ApolloError } => {
  const [mutate, result] = useMutationHook<RemoveFromCart, RemoveFromCartVariables>({
    mutationName: 'remove-from-cart.mutation',
    options: {
      onCompleted: (data) => onSuccess(data.RemoveFromCart),
      onError,
    },
  });

  const removeFromCart = (data: RemoveFromCartInput) => {
    mutate({
      variables: {
        data: {
          orderCode,
          originId: data.originId,
          productCode: data.productCode,
          sequentialNumber: data.sequentialNumber,
        },
      },
    });
  };
  return { error: result.error, loading: result.loading, removeFromCart };
};

export const useApplyPromotionsMutation = ({
  orderCode,
  onCompleted,
}: {
  orderCode?: string;
  onCompleted?: (data: Cart) => void;
}) => {
  const handleResetContentError = useCheckoutResetContentErrorHandler();

  const [deliveryAddresses, setDeliveryAddresses] = React.useState<OrderAddress[]>([]);

  const [mutate, result] = useMutationHook<ApplyPromotions, ApplyPromotionsVariables>({
    mutationName: 'apply-promotions.mutation',
    options: {
      onCompleted: (data) => onCompleted?.(data?.ApplyPromotions),
      onError: handleResetContentError,
      variables: { orderCode },
    },
  });

  React.useEffect(() => {
    if (orderCode) {
      mutate();
    }
  }, [orderCode, mutate]);

  React.useEffect(() => {
    const mappedDeliveryAdresses = mapDeliveryAddresses(
      result.data?.ApplyPromotions?.deliveryAddresses,
      result.data?.ApplyPromotions?.pickupOnDistributionCenter,
    );

    setDeliveryAddresses(mappedDeliveryAdresses);
  }, [result.data]);

  return {
    applyPromotions: mutate,
    data: result.data,
    deliveryAddresses,
    error: result.error,
    loading: result.loading,
  };
};

export const useSetCouponMutation = ({ orderCode }: { orderCode: string }) => {
  const [mutate, result] = useMutationHook<SetCoupon, SetCouponVariables>({
    mutationName: 'set-coupon',
  });

  const addCouponToCart = (couponNumber: string) => {
    return mutate({ variables: { data: { couponNumber, orderCode } } });
  };

  return {
    addCouponToCart,
    data: result.data,
    error: result.error,
    loading: result.loading,
  };
};

export const useOrderStatusQuery = (orderCode?: string) => {
  const result = useQueryHook<ResellerOrderStatus, ResellerOrderStatusVariables>('reseller-order-status', {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    skip: !orderCode,
    variables: {
      orderCode,
    },
  });

  return {
    data: mapOrderStatus(result.data),
    error: result.error,
    loading: result.loading || result.networkStatus === NetworkStatus.refetch,
    refetch: () => result.refetch(),
  };
};

const mapOrderStatus = (data: ResellerOrderStatus): OrderStatusData => {
  if (!data?.ResellerOrderStatus) {
    return null;
  }

  return {
    description: data.ResellerOrderStatus.description,
    orderCode: String(data.ResellerOrderStatus.orderCode),
    orderPending: data.ResellerOrderStatus.orderPending.map((item) => item.description),
    statusCode: String(data.ResellerOrderStatus.statusCode),
  };
};
