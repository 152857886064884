import { ScheduleCell } from '@app/components/atm.schedule-cell/';
import { IcSchedulePlaceholder } from '@app/components/icons/ic-schedule-placeholder.component';
import {
  isOneOrMoreDaysAhead,
  isTomorrow,
  parseDateToFormattedString,
} from '@app/components/obj.formatter/date-formatter';
import { LocalizedContext } from '@app/components/obj.localization';
import { FutureSchedule, ScheduleAction } from '@app/models/schedule.model';
import { Button } from '@atomic/atm.button';
import { DividerGray } from '@atomic/atm.divider/divider.component.style';
import { Body, BodySecondary, Centered, H4 } from '@atomic/atm.typography';
import { ShimmerInput } from '@atomic/mol.shimmer';
import { Hbox } from '@atomic/obj.box';
import { VSeparator } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import { Modal } from '@atomic/obj.modal';
import * as React from 'react';

import { ScheduleNotificationsWrapper } from './schedule-notifications-wrapper.component';

interface NextSchedulesModalProps {
  scheduleList?: FutureSchedule[];
  refetch: () => void;
  onClick: (action: ScheduleAction, id?: number) => void;
  loading: boolean;
  error: boolean;
  opened?: boolean;
  onClose: () => void;
}

export const NextSchedulesModal: React.FC<NextSchedulesModalProps> = (props) => {
  const localized = React.useContext(LocalizedContext).strings.components.scheduleNotifications;
  const { scheduleList, refetch, loading, error, opened, onClose } = props;

  return (
    <Modal opened={opened} onClose={onClose} small>
      <LoadingState loading={loading} data={scheduleList?.length > 0} error={error}>
        <LoadingState.Shimmer>
          <ShimmerInput />
        </LoadingState.Shimmer>

        <LoadingState.Error>
          <DividerGray />
          <VSeparator />
          <Hbox>
            <Hbox.Item hAlign='center'>
              <Body>{localized.error}</Body>
              <Button onClick={refetch} kind='link'>
                {localized.tryAgain}
              </Button>
            </Hbox.Item>
          </Hbox>
          <VSeparator />
        </LoadingState.Error>

        <LoadingState.NoData>
          <DividerGray />
          <VSeparator />
          <Hbox>
            <Hbox.Item hAlign='center'>
              <IcSchedulePlaceholder />
              <VSeparator />
              <Body>{localized.nothingScheduledFuture}</Body>
            </Hbox.Item>
          </Hbox>
          <VSeparator />
        </LoadingState.NoData>
        <VSeparator />
        <VSeparator />
        <VSeparator />
        <ScheduleNotificationsWrapper>
          {scheduleList?.map((schedule, index) => {
            const tomorrow = isTomorrow(schedule.scheduledDate);
            const dayChanged =
              index > 0 && isOneOrMoreDaysAhead(schedule.scheduledDate, scheduleList[index - 1].scheduledDate);
            return (
              <React.Fragment key={schedule.id}>
                {index === 0 && (
                  <>
                    <Centered>
                      <BodySecondary>{localized.schedulesOn}</BodySecondary>
                      <H4>{tomorrow ? localized.tomorrow : parseDateToFormattedString(schedule.scheduledDate)}</H4>
                    </Centered>
                    <VSeparator />
                  </>
                )}
                {dayChanged && (
                  <>
                    <DividerGray />
                    <VSeparator />
                    <Centered>
                      <BodySecondary>{localized.schedulesOn}</BodySecondary>
                      <H4>{parseDateToFormattedString(schedule.scheduledDate)}</H4>
                    </Centered>
                    <VSeparator />
                  </>
                )}
                <ScheduleCell key={schedule.id} schedule={schedule} onClick={props.onClick} />
              </React.Fragment>
            );
          })}
        </ScheduleNotificationsWrapper>
      </LoadingState>
      <VSeparator />
    </Modal>
  );
};
