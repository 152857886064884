import { CommonStringsType } from '@app/components/obj.localization';
import React from 'react';

export interface LocalizedProviderState {
  strings: CommonStringsType;
}

export const LocalizedContext = React.createContext<LocalizedProviderState>(null);

export const LocalizedProvider: React.FC<{ strings: CommonStringsType }> = ({ strings, children }) => {
  return <LocalizedContext.Provider value={{ strings }}>{children}</LocalizedContext.Provider>;
};
