import {
  PageWrapperContentStyled,
  PageWrapperStyled,
} from '@app/components/obj.page-wrapper/page-wrapper.component.style';
import { NewsBanners } from '@app/modules/news/news-banners.component';
import * as React from 'react';
import { AppHeader } from './mol.app-header/app-header.component';

/**
 * On pages that have a header (hamburguer menu) you can use
 * this component to wrap the page content.
 * This component enables content scrolling, fixing header position
 */
export class PageWrapper extends React.Component<{}, {}> {
  render() {
    return (
      <PageWrapperStyled>
        <NewsBanners />
        <AppHeader />
        <PageWrapperContentStyled>{this.props.children}</PageWrapperContentStyled>
      </PageWrapperStyled>
    );
  }
}
