import { ScrollYStyle } from '@atomic/obj.mixin';
import styled, { keyframes } from 'styled-components';
import { ModalProps } from './modal.component';

const MODAL_WIDTH = '460px';
export const ModalVerticalPadding = '80px';

export const ModalStyled = styled.div`
  position: absolute;
  visibility: ${(props: ModalProps) => (props.opened ? 'visible' : 'hidden')};
`;

const SlideDown = keyframes`
  0% {
    transform: translate(-50%, -100%);
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const SlideDownFull = keyframes`
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
`;

export const ModalOpacityStyled = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${(props) => props.theme.Zindex.ModalOpacityStyled};
  background-color: ${(props) => props.theme.Color.Black};
  opacity: ${(props: ModalProps) => (props.opened ? '0.5' : '0')};
  transition: all 0.2s ease-out;
  visibility: ${(props: ModalProps) => (props.opened ? 'visible' : 'hidden')};
`;

interface ModalBoxWrapperStyledProps {
  full: boolean;
  small: boolean;
}

export const ModalBoxWrapperStyled = styled.div`
  z-index: ${(props) => props.theme.Zindex.ModalBoxWrapperStyled};
  position: fixed;
  top: ${(props: ModalBoxWrapperStyledProps) => (props.full ? '0' : ModalVerticalPadding)};
  bottom: ${(props: ModalBoxWrapperStyledProps) => (props.full ? '0' : ModalVerticalPadding)};
  right: 0;
  left: ${(props: ModalBoxWrapperStyledProps) => (props.full ? '0' : '50%')};
  transform: ${(props: ModalBoxWrapperStyledProps) => (props.full ? '0' : 'translateX(-50%)')};
  pointer-events: none;
  ${(props: ModalBoxWrapperStyledProps) => props.small && `width: ${MODAL_WIDTH};`}
  animation: ${(props: ModalBoxStyledProps) => (props.full ? SlideDownFull : SlideDown)} 0.3s ease-out;
`;

interface ModalBoxStyledProps {
  isScrollable: boolean;
  isScheduling: boolean;
  full: boolean;
}

export const ModalBoxStyled = styled.div`
  ${ScrollYStyle}
  pointer-events: all;
  z-index: ${(props) => props.theme.Zindex.ModalBoxStyled};
  position: absolute;
  ${(props: ModalBoxStyledProps) => props.isScrollable && 'overflow-y: scroll;'}
  ${(props: ModalBoxStyledProps) => props.isScheduling && 'overflow: inherit;'}
  ${(props: ModalBoxStyledProps) => props.full && 'height: 100%;'}
  max-height: 90%;
  width: 100%;
  background-color: ${(props) => props.theme.Color.White};
  border-radius: ${(props) => props.theme.Border.Radius};
  display: flex;
  flex-direction: column;
`;

export const ModalCloseStyled = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: ${(props) => props.theme.Zindex.ModalCloseStyled};
  padding: ${(props) => props.theme.Spacing.Large};
  color: ${(props) => props.theme.Color.GrayDark};
  cursor: pointer;
  pointer-events: all;
`;

export const ModalBoxBodyStyled = styled.div`
  max-height: 75vh;
  padding: ${(props) => props.theme.Spacing.Large};
`;

export const ModalFooterStyled = styled.div`
  position: relative;
  box-shadow: ${(props) => props.theme.BoxShadow.Depth2Negative} ${(props) => props.theme.Color.GrayLight};
  padding: ${(props) => props.theme.Spacing.Medium};
`;
