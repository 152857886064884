import {
  MutationHookOptions,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  useQuery,
  NetworkStatus,
  QueryLazyOptions,
  useLazyQuery,
  MutationTuple,
  PureQueryOptions,
  useMutation,
  ApolloError,
  SubscriptionHookOptions,
  useSubscription,
  LazyQueryResult,
  LazyQueryHookOptions,
} from '@apollo/client';
import { Container } from 'typedi';
import { GraphQLDocsService } from './graphql-docs.service';

interface Variables {
  [key: string]: any;
}

export interface Refetch {
  query: string;
  variables?: Variables;
}

interface UseMutationHook<TData, TVariables> {
  mutationName: string;
  options?: MutationHookOptions<TData, TVariables>;
  refetchQueries?: Refetch[];
  refetchQueriesByQueryName?: string[];
}

export function useQueryHook<TData = any, TVariables = OperationVariables>(
  queryName: string,
  options?: QueryHookOptions<TData, TVariables>,
): QueryResult<TData, TVariables> {
  const graphqlDocsService = Container.get(GraphQLDocsService);
  const query = graphqlDocsService.getQuery(queryName);
  const queryresult = useQuery<TData, TVariables>(query, options);
  return { ...queryresult, loading: queryresult.loading || queryresult.networkStatus === NetworkStatus.refetch };
}

export function useLazyQueryHook<TData = any, TVariables = OperationVariables>(
  queryName: string,
  options?: LazyQueryHookOptions<TData, TVariables>,
): [(options?: QueryLazyOptions<TVariables> | undefined) => void, LazyQueryResult<TData, TVariables>] {
  const graphqlDocsService = Container.get(GraphQLDocsService);
  const query = graphqlDocsService.getQuery(queryName);
  return useLazyQuery<TData, TVariables>(query, options);
}

export function useMutationHook<TData = any, TVariables = OperationVariables>({
  mutationName,
  options = {},
  refetchQueries,
  refetchQueriesByQueryName,
}: UseMutationHook<TData, TVariables>): MutationTuple<TData, TVariables> {
  const graphqlDocsService = Container.get(GraphQLDocsService);
  const queries: PureQueryOptions[] = (refetchQueries || []).map((refetch) => ({
    query: graphqlDocsService.getQuery(refetch.query),
    variables: refetch.variables,
  }));
  options.refetchQueries = queries.length > 0 ? queries : refetchQueriesByQueryName;
  const mutation = graphqlDocsService.getMutation(mutationName);
  return useMutation<TData, TVariables>(mutation, options);
}

interface UseSubscriptionHookResponse<TData> {
  loading: boolean;
  data?: TData;
  error?: ApolloError;
}

export function useSubscriptionHook<TData, TVariables = OperationVariables>(
  subscriptionName: string,
  options: SubscriptionHookOptions<TData, TVariables> = {},
): UseSubscriptionHookResponse<TData> {
  const graphqlDocsService = Container.get(GraphQLDocsService);
  const subscription = graphqlDocsService.getSubscription(subscriptionName);
  return useSubscription<TData, TVariables>(subscription, options);
}
