import { CartListWrapper } from '@app/components/atm.cart-section-wrapper';
import { CartProductCell } from '@app/components/mol.cart-product-cell';
import { LocalizedContext } from '@app/components/obj.localization';
import { StartOrderStarterPackInput } from '@app/data/graphql';
import { StarterPacks } from '@app/domain/initial-params.use-case';
import { EventCategory } from '@app/utils/data-layer';
import { Body, H3, H4 } from '@atomic/atm.typography';
import { VSeparator } from '@atomic/obj.grid';
import * as React from 'react';
import { getProductCellPropsFromStarterPackOption } from './start-items-selector.mapper';

export interface StartItemsSelectorSectionProps {
  onStarterPackChange: (input: StartOrderStarterPackInput) => void;
  starterPackOptions: StarterPacks[];
  selectedStarterPacks: StartOrderStarterPackInput[];
}

export const StartItemsSelectorSection: React.FC<StartItemsSelectorSectionProps> = ({
  onStarterPackChange,
  starterPackOptions,
  selectedStarterPacks,
}) => {
  const localized = React.useContext(LocalizedContext).strings.components.cartPages;
  const selectionLocalized = React.useContext(LocalizedContext).strings.components.productSelection;

  return (
    <>
      <H4>{starterPackOptions?.length > 1 ? localized.chooseStartItemsMultiplePacks : localized.chooseStartItems}</H4>
      <VSeparator small />
      <Body>
        {selectionLocalized.selectedQuantityFn(selectedStarterPacks?.length || 0, starterPackOptions?.length)}
      </Body>
      <VSeparator small />
      <CartListWrapper>
        {starterPackOptions?.map((pack, index) => (
          <React.Fragment key={pack.id}>
            <H3>{(starterPackOptions.length > 1 ? `${index + 1} - ` : '') + pack.name}</H3>
            <Body>{pack.description}</Body>
            <VSeparator small />
            {pack?.products?.map((product) => (
              <React.Fragment key={pack.id + '-' + product.id}>
                <CartProductCell
                  {...getProductCellPropsFromStarterPackOption(
                    product,
                    selectedStarterPacks,
                    pack,
                    onStarterPackChange,
                  )}
                  dataGtmEventCategory={EventCategory.Attendence}
                />
                <VSeparator />
              </React.Fragment>
            ))}
            {index < starterPackOptions?.length - 1 && <VSeparator />}
          </React.Fragment>
        ))}
      </CartListWrapper>
    </>
  );
};
