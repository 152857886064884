import styled from 'styled-components';

interface FeedbackModalCategoryWrapperProps {
  showOverflow: boolean;
}

export const FeedbackModalCategoryWrapperStyled = styled.div`
  padding: ${({ theme }) => theme.Spacing.Medium};
  overflow-y: ${(props: FeedbackModalCategoryWrapperProps) => (props.showOverflow ? 'visible' : 'auto')};
`;
