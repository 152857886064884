import { HighlightStyle } from '@atomic/obj.mixin';
import * as ColorFunc from 'color';
import * as React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { ButtonProps } from './button.component';

const buttonColors = (props: any) => {
  return {
    accessory: props.theme.Color.Accessory,
    alert: props.theme.Color.Alert,
    callToAction: props.theme.Color.CallToAction,
    disabled: props.theme.Color.GrayLight,
    link: props.theme.Color.Accessory,
    neutral: props.theme.Color.Neutral,
    primary: props.theme.Color.Primary,
    secondary: props.theme.Color.Secondary,
    white: props.theme.Color.White,
  };
};

const buttonTextColors = (props: any) => {
  const bCollors = buttonColors(props);
  return {
    ...bCollors,
    disabled: props.theme.Color.GrayXDark,
    secondary: props.theme.Color.Secondary,
    white: props.theme.Color.GrayXDark,
  };
};

export const ButtonSmallHeight = '36px';
export const ButtonHalfSmallHeight = '18px';
export const ButtonHeight = '48px';
export const ButtonHalfHeight = '24px';

export const ButtonStyledCss = css`
  position: relative;
  width: ${(props: ButtonProps) => (props.expanded ? '100%' : 'fit-content')};
  min-height: ${(props: ButtonProps) => (props.size === 'small' ? ButtonSmallHeight : ButtonHeight)};
  line-height: ${(props: ButtonProps) => (props.size === 'small' ? ButtonSmallHeight : ButtonHeight)};
  padding: 0 ${(props) => props.theme.Spacing.Medium};
  font-family: ${(props) => props.theme.FontFamily.Primary};
  font-weight: ${(props) => props.theme.FontWeight.Normal};
  font-size: ${(props: ButtonProps) =>
    props.kind === 'link' ? props.theme.FontSize.Small : props.theme.FontSize.Medium};
  letter-spacing: 1px;
  text-decoration: none;
  text-align: ${(props: ButtonProps) => (props.expanded ? 'center' : 'left')};
  ${HighlightStyle}
  cursor: ${(props: ButtonProps) => (props.disabled ? 'default' : 'pointer')};
  opacity: ${(props: ButtonProps) => (props.disabled || props.loading ? 0.5 : 1)};

  /* this is useful when its father is pointer-events: none */
  pointer-events: auto;

  ${(props: ButtonProps) =>
    props.kind === 'link' || props.kind === 'neutralLink' || props.kind === 'externalLink'
      ? `
      min-height: ${props.theme.FontSize.Medium};
      line-height: ${props.theme.FontSize.Medium};
      margin: 0;
      padding: 0;
      background-color: transparent;
      border-color: transparent;
      color: ${props.kind === 'neutralLink' ? buttonColors(props).neutral : buttonColors(props)[props.kind]};
      ${props.kind === 'neutralLink' && 'text-decoration-line:underline;'};
      ${props.kind === 'link' && `font-weight: ${props.theme.FontWeight.Bold};`}
      &:hover {
        ${props.disabled ? null : `color: ${ColorFunc(props.theme.Color.Primary).darken(0.2).hsl().string()};`};
      }

      &:active {
        ${props.disabled ? null : `color: ${ColorFunc(props.theme.Color.Primary).darken(0.3).hsl().string()};`}
      }

    `
      : `
      background-color: ${props.outlined ? props.theme.Color.White : buttonColors(props)[props.kind]};
      border-color: ${props.outlined ? buttonColors(props)[props.kind] : 'transparent'};
      border-style: solid;
      border-width: ${props.theme.Border.Width};
      border-radius: ${props.rounded ? props.theme.Border.Radius : 0};

      color: ${
        props.outlined || props.kind === 'secondary' || props.kind === 'disabled' || props.kind === 'white'
          ? buttonTextColors(props)[props.kind]
          : props.theme.Color.White
      };

      &:hover {
        ${
          props.disabled
            ? null
            : props.outlined
            ? `color: ${ColorFunc(buttonColors(props)[props.kind]).lighten(0.2).hsl().string()};
            background-color: ${props.theme.Color.GrayXLight};`
            : `background-color: ${ColorFunc(buttonColors(props)[props.kind]).lighten(0.1).hsl().string()};`
        }
      }

      &:active {
        ${
          props.disabled
            ? null
            : props.outlined
            ? `color: ${ColorFunc(buttonColors(props)[props.kind]).darken(0.3).hsl().string()};`
            : `background-color: ${ColorFunc(buttonColors(props)[props.kind]).darken(0.2).hsl().string()};`
        }
      }
    `}
`;

/*
 * https://github.com/styled-components/styled-components/issues/135#issuecomment-455385004
 * Don't use refs while styled components don't fix this issue.
 */
export const ButtonStyled = styled(({ loading, rounded, noText, outlined, first, active, last, ...DOMProps }) => (
  <button {...DOMProps} />
))`
  ${ButtonStyledCss}
  &:focus {
    outline: 0;
  }
`;

export const LinkButtonStyled = styled(Link).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['loading', 'rounded', 'noText'].includes(prop) && defaultValidatorFn(prop),
})`
  ${ButtonStyledCss}
  display: inline-block;
  p > &,
  label > & {
    padding: 0;
  }
`;

export const ButtonContentStyled = styled('div').withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => !['loading', 'noText'].includes(prop) && defaultValidatorFn(prop),
})`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  opacity: ${(props: { loading: boolean }) => (props.loading ? 0 : 1)};
  font-stretch: 100%;

  & .fa,
  & img,
  & svg {
    margin-right: ${(props) => (!props.noText ? props.theme.Spacing.Small : 0)};
  }
`;

export const ButtonSpinnerStyled = styled(({ loading, ...DOMProps }) => <span {...DOMProps} />)`
  display: ${(props: { loading: boolean }) => (props.loading ? 'inline-block' : 'none')} !important;
  position: absolute;
  right: calc(50% - 7px);
  top: calc(50% - ${(props) => (props.size === 'small' ? ButtonHalfSmallHeight : ButtonHalfHeight)});
`;
