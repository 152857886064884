import { CartListWrapper } from '@app/components/atm.cart-section-wrapper';
import { LocalizedContext } from '@app/components/obj.localization';
import { ApplicationTypeEnum, Cart, StartOrderInput } from '@app/data/graphql';
import { useCartDetailsQuery } from '@app/domain/cart-detail.use-case';
import { useErrorFlashDispatcher } from '@app/domain/error-dispatcher.hook';
import { useStartOrderMutation } from '@app/domain/orders.use-case';
import { CartPageLocationState } from '@app/models/orders.model';
import { useAppType } from '@app/modules/account/use-appType';
import { AppPath } from '@app/modules/app/routes';
import { OrderContext } from '@app/providers';
import { Button } from '@atomic/atm.button';
import { Frame } from '@atomic/atm.frame';
import { Body, H3 } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { Col, Grid, Row, VSeparator } from '@atomic/obj.grid';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router';

export const CartEditOrderPage: React.FC = () => {
  const order = React.useContext(OrderContext);
  const history = useHistory();
  const { state } = useLocation<CartPageLocationState>();
  const localized = React.useContext(LocalizedContext).strings.components.cartPages;
  const { isStarts } = useAppType();

  const cartDetail = useCartDetailsQuery({ orderCode: order.editOrderCode });

  const startOrderParams: StartOrderInput = {
    application: isStarts ? ApplicationTypeEnum.Starts : ApplicationTypeEnum.Activation,
    businessModelCode: cartDetail?.data?.businessModelCode,
    cycle: cartDetail?.data?.cycle,
    distributionCenterCode: cartDetail?.data?.distributionCenterCode,
    editOrderCode: cartDetail?.data?.orderCode,
    pickupOnDistributionCenter: cartDetail?.data?.pickupOnDistributionCenter,
    resellerId: order.resellerId,
    subcycleId: cartDetail?.data?.subcycleId,
  };

  const handleStartOrderComplete = (newCart: Cart) => {
    order.updateCart(newCart);
    order.updateCurrentOrder();
    history.push({ pathname: AppPath.Cart.Management, state });
  };

  const { loading: startOrderLoading, error: startOrderError, updateOrder } = useStartOrderMutation({
    onCompleted: handleStartOrderComplete,
    startOrderParams,
  });

  useErrorFlashDispatcher(startOrderError);

  return (
    <Grid>
      <Row center='xs' mt>
        <Col xs={8}>
          <Frame>
            <H3>{localized.editOrderTitle}</H3>
            <VSeparator small />

            <CartListWrapper>
              <Body>{localized.editOrderBody(state.editOrderCode)}</Body>
            </CartListWrapper>

            <VSeparator />
            <Hbox>
              <Hbox.Item>
                <Button expanded outlined onClick={() => history.goBack()} loading={startOrderLoading}>
                  {localized.back}
                </Button>
              </Hbox.Item>

              <Hbox.Separator />
              <Hbox.Item hAlign='flex-end'>
                <Button expanded onClick={() => updateOrder()} loading={startOrderLoading || cartDetail?.loading}>
                  {localized.continueToEditOrder}
                </Button>
              </Hbox.Item>
            </Hbox>
          </Frame>
        </Col>
      </Row>
    </Grid>
  );
};
