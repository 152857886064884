import styled, { keyframes } from 'styled-components';

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

export const shakeHorizontal = keyframes`
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    transform: translateX(10px);
  }
  80% {
    transform: translateX(8px);
  }
  90% {
    transform: translateX(-8px);
  }
`;

interface FadeProps {
  show: boolean;
}

interface ShakeProps {
  show: boolean;
}

export const Fade = styled.div`
  border: 1px solid transparent;
  animation: ${(props: FadeProps) => (props.show ? fadeIn : fadeOut)} ${(props) => props.theme.TransitionDuration}
    linear forwards;
`;

export const Shake = styled.div`
  animation: ${(props: ShakeProps) => (props.show ? shakeHorizontal : 'none')} 0.8s linear forwards;
`;
