import * as React from 'react';

import { ProgressCell, ProgressStatus } from './progress-cell.component';
import { ProgressStyled } from './progress.component.style';

export interface ProgressProps {
  step: number;
}

export class Progress extends React.Component<ProgressProps, undefined> {
  static Cell = ProgressCell;

  constructor(props: ProgressProps) {
    super(props);
  }

  render() {
    const itens = React.Children.map(this.props.children, (child: any, i: number) => {
      const key = 'ProgressCell_' + i;
      return (
        child &&
        React.cloneElement(child, {
          key,
          status: child.props?.status ?? this.status(i + 1, this.props.step),
        })
      );
    });

    return <ProgressStyled>{itens}</ProgressStyled>;
  }

  private status(step: number, currentStep: number): ProgressStatus {
    if (step < currentStep) {
      return 'past';
    } else if (step === currentStep) {
      return 'current';
    } else {
      return 'future';
    }
  }
}
