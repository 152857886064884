import { ApolloLinkBuilder } from '@app/core/graphql/apollo-link.builder';
import { TitleList_TitleList_nodes } from '@app/data/graphql';
import { ApolloClient, defaultDataIdFromObject, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { Token } from 'typedi';
import { GraphqlInterceptor } from './graphql.interceptor';

export const GraphqlUrlToken = new Token<string>('graphql-url');

interface BuildArguments {
  url: string;
  interceptors: GraphqlInterceptor[];
  ssr: boolean;
  isDevelop: boolean;
  pathname: string;
}

export class ApolloClientBuilderService {
  static build({
    isDevelop,
    interceptors = [],
    ssr = false,
    url,
    pathname,
  }: BuildArguments): ApolloClient<NormalizedCacheObject> {
    const link: any = ApolloLinkBuilder.build(url + pathname, interceptors);

    const getDataIdFromTitleList = ({ titleId }: TitleList_TitleList_nodes) => titleId;

    const client = new ApolloClient({
      cache: new InMemoryCache({
        dataIdFromObject: (object) => {
          switch (object.__typename) {
            // server should return unique id
            case 'TitleResponse':
              return getDataIdFromTitleList((object as unknown) as TitleList_TitleList_nodes);
            case 'ResellerRegistrationInfoResponse':
              return `ResellerRegistrationInfoResponse:${object.resellerId}`;
            default:
              return defaultDataIdFromObject(object);
          }
        },
      }),
      connectToDevTools: isDevelop,
      link,
      ssrMode: ssr,
    });

    return client;
  }
}
