import { useState } from 'react';

export const useAudio = (audioFile) => {
  const [audio] = useState(new Audio(audioFile));

  const play = () => audio.play();

  const pause = () => audio.pause();

  return { pause, play };
};
