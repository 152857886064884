import { defaultLoadableConfig } from '@app/core/loadable/default-config';
import { LoadingCentered } from '@atomic/mol.loading';
import React from 'react';
import * as Loadable from 'react-loadable';

export const ActivationLoadable = Loadable({
  loader: () => import(/* webpackChunkName: "reseller" */ './activation.router'),
  loading: () => <LoadingCentered />,
  ...defaultLoadableConfig,
  modules: ['./activation.router'],
  webpack: () => [require.resolveWeak('./activation.router')],
});
