import { FaIcon } from '@atomic/atm.fa-icon';
import { Theme } from '@atomic/obj.constants';
import { HighlightStyle } from '@atomic/obj.mixin';
import styled from 'styled-components';

export const Circle = styled.div`
  width: ${(props) => props.theme.Spacing.Medium};
  height: ${(props) => props.theme.Spacing.Medium};
  border-color: ${(props) => props.theme.Color.Gray};
  border-width: ${(props) => props.theme.Border.Width};
  border-style: solid;
  border-radius: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

export const CheckedStep = styled(FaIcon.Check).attrs((props: Theme) => ({
  color: props.theme.Color.White,
}))``;

interface StepChevronProps extends Theme {
  completed?: boolean;
}

export const StepChevron = styled(FaIcon.ChevronRight).attrs((props: StepChevronProps) => ({
  color: props.completed ? props.theme.Color.White : props.theme.Color.Black,
}))``;

interface StepWrapperProps extends Theme {
  disabled: boolean;
}

export const StepWrapper = styled.div`
  ${(props: StepWrapperProps) =>
    props.disabled
      ? 'opacity: 0.6'
      : `  ${HighlightStyle}
  &:hover {
    opacity: 0.4;
  }`}
`;
