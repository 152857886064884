import { IcDiscountSign } from '@app/components/icons/ic-discount.component';
import { parseNumberValue } from '@app/components/obj.formatter/number-formatter';
import { EventAction, GtmEventTag, sanitizeMessage } from '@app/utils/data-layer';
import { BorderedList } from '@atomic/atm.bordered-list/bordered-list.component';
import { RadioField } from '@atomic/atm.radio';
import { BodySecondary, H4, ProductPrice } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { ThemeToken } from '@atomic/obj.constants';
import { useTimestamp } from '@atomic/obj.custom-hooks/timestamp.hook';
import { Form } from '@atomic/obj.form';
import * as React from 'react';
import Container from 'typedi';
const theme = Container.get(ThemeToken);

export interface CellData {
  title: string;
  description?: string;
  price?: number;
  radioId?: string;
}
interface RadioIconListProps extends GtmEventTag {
  fieldName: string;
  list: CellData[];
  initialValue?: string;
  onValueChange?: (id: string) => void;
  withIcon?: boolean;
  direction?: 'row' | 'column';
  dataCy?: string;
  gtmEvent?: string;
  gtmEventLabels?: string[];
}

export const RadioIconList: React.FunctionComponent<RadioIconListProps> = (props) => {
  const [selectedId, setSelectedId] = React.useState(props.initialValue);
  const [formTimestamp, updateFormTimestamp] = useTimestamp();

  return (
    <BorderedList direction={props.direction} data-cy={props.dataCy || 'radio-icon-list'}>
      <Form.Field name={props.fieldName} initialValue={selectedId} key={formTimestamp}>
        {props?.list?.map((cell, index) => (
          <BorderedList.Item
            key={cell.radioId}
            onClick={getHandleClick(selectedId, cell.radioId, setSelectedId, updateFormTimestamp, props.onValueChange)}
          >
            {cell.radioId && (
              <Hbox.Item vAlign='center' noGrow>
                <RadioField
                  id={cell.radioId}
                  dataGtmEventAction={props.dataGtmEventAction ?? EventAction.Click.Radio}
                  dataGtmEventCategory={props.dataGtmEventCategory}
                  dataGtmEventLabel={props.gtmEventLabels?.[index] ?? sanitizeMessage(cell.title)}
                >
                  <Hbox>
                    <Hbox.Item noGrow>
                      <H4>{cell.title}</H4>
                      {cell.description && <BodySecondary>{cell.description}</BodySecondary>}
                      {!isNaN(cell.price) && (
                        <ProductPrice>
                          {cell.price === 0 ? 'Grátis' : parseNumberValue(cell.price, true, 2)}
                        </ProductPrice>
                      )}
                    </Hbox.Item>
                    {props.withIcon && (
                      <>
                        <Hbox.Item noGrow>
                          <IcDiscountSign height={theme.FontSize.Large} />
                        </Hbox.Item>
                        <Hbox.Separator />
                      </>
                    )}
                  </Hbox>
                </RadioField>
              </Hbox.Item>
            )}
          </BorderedList.Item>
        ))}
      </Form.Field>
    </BorderedList>
  );
};

const getHandleClick = (
  previousId: string,
  id: string,
  setSelectedId: (id: string) => void,
  updateTimestamp: () => void,
  handleUpdate?: (id: string) => void,
) => () => {
  if (previousId !== id) {
    setSelectedId(id);
    updateTimestamp();
    if (handleUpdate) {
      handleUpdate(id);
    }
  }
};
