import { createGlobalStyle } from 'styled-components';

export const GlobalStyled = createGlobalStyle`
  * {
    box-sizing: border-box;
    }
  body {
    margin: 0;
  }
  a {
    text-decoration: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0.05);
    color: ${(props) => props.theme.Color.Primary};
  }
`;
