import { IcTrash } from '@app/components/icons/ic-trash.component';
import { parseNumberValue } from '@app/components/obj.formatter/number-formatter';
import { LocalizedContext } from '@app/components/obj.localization/localized.hook';
import { Button } from '@atomic/atm.button';
import { Card, CardContentWrapper } from '@atomic/atm.card';
import { Stepper } from '@atomic/atm.stepper';
import { Body, BodySecondary, H4 } from '@atomic/atm.typography';
import { useBooleanState } from '@atomic/obj.boolean-state/boolean-state.hook';
import { Hbox } from '@atomic/obj.box';
import { VSeparator } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import { Modal } from '@atomic/obj.modal';
import * as React from 'react';

import { CartProductCellShimmer } from './cart-product-cell.shimmer.component';
import { ProductCellProps } from './cart-product-cell.component';
import Container from 'typedi';
import { ThemeToken } from '@atomic/obj.constants';
import { Project } from '@app/config-variables';
import { EventAction, EventCategory, sanitizeMessage } from '@app/utils/data-layer';

const theme = Container.get(ThemeToken);

export const CartProductCellView: React.FC<ProductCellProps> = (props) => {
  const { product, loading, error, onChangeQuantity, onRemoveFromCart, onComboDetailClick } = props;
  const { value: opened, toggle: toggleModal } = useBooleanState(false);
  const localized = React.useContext(LocalizedContext).strings.components.productCell;
  const [quantity, setQuantity] = React.useState<{ amount: number; changedByRequest: boolean }>({
    amount: product.quantity,
    changedByRequest: false,
  });

  React.useEffect(() => {
    setQuantity({ amount: product.quantity, changedByRequest: true });
  }, [product.quantity]);

  const productPrice = parseNumberValue(product.payValue, true, 2);

  const handleConfirmRemoveItem = () => {
    toggleModal();
    onRemoveFromCart({
      originId: product.originId,
      productCode: product.code,
      sequentialNumber: product.sequentialNumber,
    });
  };

  const onChangeValue = (newValue: number) => {
    setQuantity({ amount: newValue, changedByRequest: false });
  };

  const handleDebouncedChangeValue = (newValue: number) => {
    if (!quantity.changedByRequest) {
      onChangeQuantity(newValue);
    }
  };

  return (
    <>
      <Card bordered>
        <LoadingState loading={loading} data={!!product} error={!!error}>
          <LoadingState.Shimmer>
            <CartProductCellShimmer />
          </LoadingState.Shimmer>
          <Hbox>
            <Hbox.Item>
              <H4>{product.code}</H4>
            </Hbox.Item>
            {product.isRemovable && (
              <>
                <Hbox.Separator />
                <Hbox.Item noGrow>
                  <Button kind='link' onClick={toggleModal} noText>
                    <IcTrash width={theme.IconSize.Medium} height={theme.IconSize.Medium} />
                  </Button>
                </Hbox.Item>
              </>
            )}
          </Hbox>
          <VSeparator small />
          <BodySecondary>{product.name}</BodySecondary>
          <VSeparator small />
          <Hbox>
            <Hbox.Item hAlign='flex-start' vAlign='center'>
              {product.isFixedQuantity ? (
                <Body>{localized.quantity(quantity.amount)}</Body>
              ) : (
                <Stepper
                  onChangeValue={onChangeValue}
                  onDebouncedChangeValue={handleDebouncedChangeValue}
                  value={quantity.amount}
                  minValue={props.minValue !== undefined ? props.minValue : 1}
                  maxValue={props.maxValue}
                  dataGtmEventAction={props.dataGtmEventAction}
                  dataGtmEventCategory={props.dataGtmEventCategory}
                  dataGtmEventLabel={props.dataGtmEventLabel}
                />
              )}
            </Hbox.Item>
            {productPrice && (
              <>
                <Hbox.Separator />
                <Hbox.Item vAlign='center' noGrow>
                  <BodySecondary>{theme.Project === Project.bot ? localized.magazine : localized.total}</BodySecondary>
                  <Body>{product.isGift ? localized.gift : productPrice}</Body>
                </Hbox.Item>
              </>
            )}
          </Hbox>
          <VSeparator />
          {product.isCombo && (
            <Hbox>
              <Hbox.Item>
                <Button
                  kind='externalLink'
                  onClick={() => onComboDetailClick(quantity.amount)}
                  dataGtmEventAction={EventAction.Click.Button}
                  dataGtmEventCategory={EventCategory.Attendence}
                  dataGtmEventLabel={sanitizeMessage(localized.comboDetail)}
                >
                  {localized.comboDetail}
                </Button>
              </Hbox.Item>
            </Hbox>
          )}
        </LoadingState>
      </Card>
      {product.isRemovable && (
        <Modal opened={opened} small onClose={toggleModal}>
          <CardContentWrapper>
            <Body>{localized.areYouSure}</Body>
            <VSeparator />
            <Hbox>
              <Hbox.Item>
                <Button kind='neutral' outlined onClick={toggleModal}>
                  {localized.cancel}
                </Button>
              </Hbox.Item>
              <Hbox.Separator />
              <Hbox.Item>
                <Button kind='alert' onClick={handleConfirmRemoveItem}>
                  {localized.remove}
                </Button>
              </Hbox.Item>
            </Hbox>
          </CardContentWrapper>
        </Modal>
      )}
    </>
  );
};
