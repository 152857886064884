export interface AppConfig {
  graphQLUrl: string;
  busUrl: string;
  googleAnalyticsTrackingId: string;
  debug: boolean;
  version: string;
  vapidPublicKey: string;
  businessUnit: string;
  origin: string;
  project: string;
  blockFirebaseUrl: string;
  blockFirebaseUrlRedirectTo: string;
  clientId: string;
  commercialModelCode: string;
  paymentApiUrl: string;
  orderMachine: string;
  configCatSDKKey: string;
  oneTrust: OneTrustConfig;
  queueLimit?: QueueLimit;
  hotjar?: HotjarConfig;
  googleAnalyticsContainerId?: string;
  googleTagManagerId?: string;
  privacyPolicy: string;
  newRelic?: NewRelicConfig;
}

export interface NewRelicConfig {
  accountID: string;
  trustKey: string;
  agentID: string;
  licenseKey: string;
  applicationID: string;
  cors: string;
}

export interface OneTrustConfig {
  baseUrl: string;
  consentId: string;
}

export interface HotjarConfig {
  id: string;
  sv: string;
}

export interface QueueLimit {
  starts: number;
  activation: number;
}

export enum Project {
  // Boticário
  bot = 'bot',

  // Eudora
  eudora = 'eudora',
}

export enum ToggleableFeature {
  Orders = 'ordersFvc',
  StartConversation = 'startConversationFvc',
  NotAllowedWords = 'notAllowedWords',
  MultipleFormsPayment = 'multipleFormsPayment',
  PurchaseHistory = 'fvcPurchaseHistory',
  TopProducts = 'fvcTopProducts',
  MessageTemplate = 'fvcMessageTemplates',
  CancelOrder = 'fvcCancelOrder',
  UpdateOrder = 'fvcUpdateOrder',
  StartsPage = 'fvcStartsPageEnabled',
  FVCCartPage = 'fvcCartPage',
  QueueLimit = 'fvcQueueLimit',
  IndicatorsPage = 'fvcIndicatorsPage',
  NotificationSound = 'fvcNotificationSound',
}
