import { ApolloError } from '@apollo/client';
import { FlashDispatcherService } from '@app/components/obj.flash-wrapper/flash-dispatcher.service';
import { LocalizedContext } from '@app/components/obj.localization';
import { isResetContentError } from '@app/components/orders/checkout-reset-content-error.hook';
import { useCartCycles } from '@app/components/org.cart/cart-cycles.hook';
import { Project } from '@app/config-variables';
import {
  Cart,
  CartCutProduct,
  Cart_acquiredProducts,
  Cart_paymentPlans,
  Cart_products,
  Cart_totals,
  ChooseGift_ChooseGift_rewardsToChoose,
  CurrentOrder,
  ResellerCurrentOrder_ResellerCurrentOrder,
  StartOrderStarterPackInput,
} from '@app/data/graphql';
import { MultiplePaymentResponse } from '@app/data/http/dtos/order';
import { useResellerCreditQuery } from '@app/domain/buying-potential.use-case';
import { useCurrentOrderQuery } from '@app/domain/current-orders.use-case';
import { mapErrorMessage } from '@app/domain/error-dispatcher.hook';
import { StarterPacks, useInitialParamsQuery } from '@app/domain/initial-params.use-case';
import { CartPageLocationState, CreditCardSelection, OrderComboProducts } from '@app/models/orders.model';
import { AppPath } from '@app/modules/app/routes';
import { isPast } from '@app/utils/is-past';
import { DeliveryMode } from '@app/view-model/current-order-delivery-modes';
import { OrderAddress } from '@app/view-model/order-address/order-address.vm';
import { SelectOption } from '@atomic/atm.select';
import { CommonTheme } from '@atomic/obj.constants';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router';
import { ThemeContext } from 'styled-components';
import Container from 'typedi';

export interface OrderContextState {
  resellerId: string;
  isEditing: boolean;
  deliveryModes: DeliveryMode[];
  updateAvailableDeliveryModes: (data: DeliveryMode[]) => void;
  starterPacks: StarterPacks[];
  updateAvailableStarterPacks: (data: StarterPacks[]) => void;
  resellerCredit: number;
  updateResellerCredit: () => void;
  editOrderCode: string;
  cart: Cart;
  updateCart: (newCart: Cart) => void;
  appliedPromotionsCart: Cart;
  updateAppliedPromotionsCart: (newCart: Cart) => void;
  currentOrder: ResellerCurrentOrder_ResellerCurrentOrder;
  recoverLastCancelledOrder: boolean;
  updateRecoverLastCancelledOrder: (flag: boolean) => void;
  updateCurrentOrder: () => void;
  currentOrderAddress: OrderAddress;
  updateCurrentOrderAddress: (newChosenAddress: OrderAddress) => void;
  hasCurrentOrder: boolean;
  loading: boolean;
  error: ApolloError;
  paymentPlan: Cart_paymentPlans;
  selectedDeliveryMode: DeliveryMode;
  updateDeliveryMode: (deliverMode: DeliveryMode) => void;
  updatePaymentPlan: (newPaymentPlan: Cart_paymentPlans) => void;
  rewardsToChoose: ChooseGift_ChooseGift_rewardsToChoose[];
  updateRewardsToChoose: (data: ChooseGift_ChooseGift_rewardsToChoose[]) => void;
  totals: Cart_totals;
  updateTotals: (data: Cart_totals) => void;
  acquiredProducts: Cart_acquiredProducts[];
  updateAcquiredProducts: (data: Cart_acquiredProducts[]) => void;
  products: Cart_products[];
  updateProducts: (data: Cart_products[]) => void;
  cutProducts: CartCutProduct[];
  updateCutProducts: (data: CartCutProduct[]) => void;
  creditCardSelection: CreditCardSelection;
  updateCreditCardSelection: (data: CreditCardSelection) => void;
  selectedStarterPacks: StartOrderStarterPackInput[];
  updateSelectedStarterPacks: (input: StartOrderStarterPackInput) => void;
  comboProducts: OrderComboProducts;
  updateComboProducts: (data: OrderComboProducts) => void;
  multiplePaymentResponse: MultiplePaymentResponse[];
  updateMultiplePaymentResponse: (
    data: MultiplePaymentResponse[] | ((data: MultiplePaymentResponse[]) => MultiplePaymentResponse[]),
  ) => void;
  availableCycles: any;
  currentCycle: string;
  currentSubCycles: SelectOption[];
  previousCycle: string;
  previousSubCycles: SelectOption[];
  canOrderOnPreviousCycle: boolean;
  selectedCycle: string;
  selectedSubcycle: string;
  updateCycle: (newCycle: string) => void;
  updateSubcycle: (newSubcycle: string) => void;
  selectedBusinessModel: string;
  updateSelectedBusinessModel: (businessModel: string) => void;
  handleOrderError: (error: ApolloError) => void;
}

export const OrderContext = React.createContext<OrderContextState>(null);

interface OrderProviderProps {
  resellerId: string;
  editOrderCode?: string;
  onCurrentOrderComplete?: (data: CurrentOrder) => void;
  onCurrentOrderError?: (error: ApolloError) => void;
}

export const OrderProvider: React.FC<OrderProviderProps> = (props) => {
  const [cart, setCart] = React.useState<Cart>(null);
  const [appliedPromotionsCart, setAppliedPromotionsCart] = React.useState<Cart>(null);
  const [currentOrderAddress, setCurrentOrderAddress] = React.useState<OrderAddress>();
  const [chosenPaymentPlan, setChosenPaymentPlan] = React.useState<Cart_paymentPlans>();
  const [rewardsToChoose, setRewardsToChoose] = React.useState<ChooseGift_ChooseGift_rewardsToChoose[]>();
  const [totals, setTotals] = React.useState<Cart_totals>();
  const [acquiredProducts, setAcquiredProducts] = React.useState<Cart_acquiredProducts[]>();
  const [products, setProducts] = React.useState<Cart_products[]>();
  const [cutProducts, setCutProducts] = React.useState<CartCutProduct[]>();
  const [creditCardSelection, setCreditCardSelection] = React.useState<CreditCardSelection>();
  const [selectedStarterPacks, setSelectedStarterPacks] = React.useState<StartOrderStarterPackInput[]>(null);
  const [starterPacks, setStarterPacks] = React.useState<StarterPacks[]>([]);
  const [deliveryModes, setDeliveryModes] = React.useState<DeliveryMode[]>([]);
  const [comboProducts, setComboProducts] = React.useState<OrderComboProducts>({});
  const [multiplePaymentResponse, setMultiplePaymentResponse] = React.useState<MultiplePaymentResponse[]>([]);
  const [recoverLastCancelledOrder, setRecoverLastCancelledOrder] = React.useState<boolean>();
  const [selectedDeliveryMode, setSelectedDeliveryMode] = React.useState<DeliveryMode>();
  const [selectedBusinessModel, setSelectedBusinessModel] = React.useState<string>(null);

  const flashDispatcherService = Container.get(FlashDispatcherService);
  const theme = React.useContext<CommonTheme>(ThemeContext);
  const history = useHistory();
  const location = useLocation<CartPageLocationState>();

  const localized = React.useContext(LocalizedContext).strings.error.order;

  const handleCurrentOrderCompleted = (data: CurrentOrder) => {
    if (isPast(data?.expiration)) {
      setSelectedDeliveryMode({
        distributionCenterCode: data?.distributionCenterCode,
        isWithdrawalCenter: data?.pickupOnDistributionCenter,
      });
    }

    props.onCurrentOrderComplete?.(data);
  };

  const currentOrder = useCurrentOrderQuery({
    onCompleted: handleCurrentOrderCompleted,
    onError: props.onCurrentOrderError,
    resellerId: props.resellerId,
  });

  const initialParams = useInitialParamsQuery(props.resellerId, (data) => {
    setStarterPacks(data.starterPacks);
    setDeliveryModes(data.deliveryModes);
  });

  const resellerCredit = useResellerCreditQuery({ id: props.resellerId });

  const {
    canOrderOnPreviousCycle,
    availableCycles,
    currentCycle,
    previousCycle,
    selectedCycle,
    handleChangeCycle,
    currentSubCycles,
    previousSubCycles,
    selectedSubcycle,
    handleChangeSubcycle,
  } = useCartCycles(currentOrder.data, props.resellerId);

  const updateSelectedStarterPacks = (input: StartOrderStarterPackInput) => {
    const index = selectedStarterPacks?.findIndex((pack) => input.id === pack.id);

    if (!input.productId) {
      if (index === -1) {
        return;
      }

      setSelectedStarterPacks(selectedStarterPacks.filter((_, packIndex) => index !== packIndex));
      return;
    }

    if (!selectedStarterPacks?.length || index === -1) {
      setSelectedStarterPacks([...(selectedStarterPacks || []), input]);
    } else {
      setSelectedStarterPacks(selectedStarterPacks.map((item, itemIndex) => (index === itemIndex && input) || item));
    }
  };

  const handleOrderError = (error: ApolloError) => {
    if (isResetContentError(error)) {
      flashDispatcherService.dispatchMessage(
        { additionalInfo: 'order.provider.tsx:193', message: localized.resetContent },
        'warning',
      );

      currentOrder.refetch();

      setCart(null);
      setSelectedStarterPacks(null);
      setRecoverLastCancelledOrder(null);
      setSelectedDeliveryMode(null);
      setSelectedBusinessModel(null);

      return history.push({
        pathname: theme.Project === Project.eudora ? AppPath.Cart.BusinessModel : AppPath.Cart.Address,
        state: location?.state,
      });
    } else {
      const fullMessage = mapErrorMessage(error);
      flashDispatcherService.dispatchMessage(
        { additionalInfo: 'order.provider.tsx:212', error: fullMessage.error, message: fullMessage.message },
        'warning',
      );
    }
  };

  return (
    <OrderContext.Provider
      value={{
        acquiredProducts,
        appliedPromotionsCart,
        availableCycles,
        canOrderOnPreviousCycle,
        cart,
        comboProducts,
        creditCardSelection,
        currentCycle,
        currentOrder: currentOrder.data,
        currentOrderAddress,
        currentSubCycles,
        cutProducts,
        deliveryModes,
        editOrderCode: props.editOrderCode,
        error: initialParams.error,
        handleOrderError,
        hasCurrentOrder: !currentOrder.error && isPast(currentOrder.data?.expiration),
        isEditing: props.editOrderCode && currentOrder?.data?.id === props.editOrderCode,
        loading: currentOrder.loading || initialParams.loading || resellerCredit.loading,
        multiplePaymentResponse,
        paymentPlan: chosenPaymentPlan,
        previousCycle,
        previousSubCycles,
        products,
        recoverLastCancelledOrder,
        resellerCredit: resellerCredit?.data?.available,
        resellerId: props.resellerId,
        rewardsToChoose,
        selectedBusinessModel,
        selectedCycle,
        selectedDeliveryMode,
        selectedStarterPacks,
        selectedSubcycle,
        starterPacks,
        totals,
        updateAcquiredProducts: setAcquiredProducts,
        updateAppliedPromotionsCart: setAppliedPromotionsCart,
        updateAvailableDeliveryModes: setDeliveryModes,
        updateAvailableStarterPacks: setStarterPacks,
        updateCart: setCart,
        updateComboProducts: setComboProducts,
        updateCreditCardSelection: setCreditCardSelection,
        updateCurrentOrder: currentOrder.refetch,
        updateCurrentOrderAddress: setCurrentOrderAddress,
        updateCutProducts: setCutProducts,
        updateCycle: handleChangeCycle,
        updateDeliveryMode: setSelectedDeliveryMode,
        updateMultiplePaymentResponse: setMultiplePaymentResponse,
        updatePaymentPlan: setChosenPaymentPlan,
        updateProducts: setProducts,
        updateRecoverLastCancelledOrder: setRecoverLastCancelledOrder,
        updateResellerCredit: resellerCredit.refetch,
        updateRewardsToChoose: setRewardsToChoose,
        updateSelectedBusinessModel: setSelectedBusinessModel,
        updateSelectedStarterPacks,
        updateSubcycle: handleChangeSubcycle,
        updateTotals: setTotals,
      }}
    >
      {props.children}
    </OrderContext.Provider>
  );
};
