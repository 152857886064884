import { ComboEudoraModal } from '@app/components/mol.combo-eudora-modal/combo-eudora-modal.component';
import { EudoraProductSearchModal } from '@app/components/mol.product-search-modal/product-search-modal-eudora.component';
import { FlashDispatcherService } from '@app/components/obj.flash-wrapper/flash-dispatcher.service';
import { LocalizedContext } from '@app/components/obj.localization';
import { Cart, EudoraProductSearch, EudoraProductType } from '@app/data/graphql';
import { useErrorFlashDispatcher } from '@app/domain/error-dispatcher.hook';
import {
  useAddToCartMutation,
  useComboDetailLazyQuery,
  useEudoraProductSearchQuery,
} from '@app/domain/orders.use-case';
import { OrderContext } from '@app/providers/order.provider';
import { Button } from '@atomic/atm.button';
import { TextField } from '@atomic/atm.text-field';
import { BodySecondary } from '@atomic/atm.typography';
import { useBooleanState } from '@atomic/obj.boolean-state/boolean-state.hook';
import { Hbox } from '@atomic/obj.box';
import { Form, FormData } from '@atomic/obj.form';
import { VSeparator } from '@atomic/obj.grid';
import { ApolloError } from '@apollo/client';
import * as React from 'react';
import Container from 'typedi';

interface EudoraFastShopFormProps {
  loading: boolean;
  orderCode: string;
  onProductAdded: (cart: Cart) => void;
  onError?: (error: ApolloError) => void;
  withoutSearch?: boolean;
}

export const EudoraFastShopForm: React.FC<EudoraFastShopFormProps> = (props) => {
  const { onError, onProductAdded } = props;
  const flashDispatcherService = Container.get(FlashDispatcherService);
  const localized = React.useContext(LocalizedContext).strings.components.fastShop;
  const order = React.useContext(OrderContext).cart;
  const products = order?.products;

  const { value: isSearchModalOpened, setFalse: closeSearchModal, setTrue: openSearchModal } = useBooleanState(false);
  const [searchId, setSearchId] = React.useState('');
  const [searchQuantity, setSearchQuantity] = React.useState(1);
  const [comboModalOpened, setComboModalOpened] = React.useState(false);
  const [clearComboModal, setClearComboModal] = React.useState(Date.now());

  const {
    getComboDetail,
    data: comboDetailData,
    loading: comboDetailLoading,
    error: comboDetailError,
    refetch: comboDetailRefetch,
  } = useComboDetailLazyQuery(props.orderCode);

  const handleSearchComplete = (result: EudoraProductSearch) => {
    const product = result?.EudoraProductSearch?.find((item) => item.code === searchId);

    if (!product) {
      return flashDispatcherService.dispatchMessage({ message: localized.searchError }, 'warning');
    }

    handleSearchAdd(product);
  };

  const { error: searchProductError, loading: searchLoading, search } = useEudoraProductSearchQuery({
    onCompleted: handleSearchComplete,
    orderCode: props.orderCode,
  });

  const handleProductAdded = (cart: Cart) => {
    onProductAdded(cart);
    handleCloseComboModal();
  };

  const { addToCart, loading: addToCartLoading } = useAddToCartMutation({
    onCompleted: handleProductAdded,
    onError,
    orderCode: props.orderCode,
  });

  const handleSearchIdChange = (text: string) => {
    setSearchId(text);
  };

  const handleSearchQuantityChange = (quantity: number) => {
    setSearchQuantity(quantity);
  };

  const handleFastShopAdd = (FormInput: FormData<{ productCode: string; productQuantity: string }>) => {
    const selectedProduct = products.find((product) => product.code === FormInput.data.productCode);
    const totalProductQuantity = +FormInput.data.productQuantity + (selectedProduct?.quantity ?? 0);
    search(FormInput.data.productCode);
    setSearchQuantity(totalProductQuantity);
  };

  const handleCloseComboModal = () => {
    setSearchId('');
    setSearchQuantity(1);
    setComboModalOpened(false);
  };

  const handleSearchAdd = (product: EudoraProductType) => {
    if (product.isCombo) {
      getComboDetail(product.code);
      setComboModalOpened(true);
      setClearComboModal(Date.now());
    } else {
      addToCart({ productCode: product.code, quantity: searchQuantity ?? 1 });
    }
    closeSearchModal();
  };

  useErrorFlashDispatcher(searchProductError);

  return (
    <>
      <Form onSubmit={handleFastShopAdd}>
        <Hbox>
          <Hbox.Item vAlign={'flex-end'}>
            <Form.Field label={localized.label} name='productCode' value={searchId}>
              <TextField
                type='number'
                kind='normal'
                placeholder={localized.placeholder}
                dismissable
                onValueChange={handleSearchIdChange}
                data-cy='cart-add-product-code'
                min={0}
              />
            </Form.Field>
          </Hbox.Item>
          <Hbox.Separator />

          <Hbox.Item vAlign={'flex-end'}>
            <Form.Field label={localized.quantityLabel} name='productQuantity' value={searchQuantity}>
              <TextField
                type='number'
                kind='normal'
                min={0}
                placeholder={localized.quantityPlaceholder}
                onValueChange={handleSearchQuantityChange}
                data-cy='cart-add-product-quantity'
              />
            </Form.Field>
          </Hbox.Item>
          <Hbox.Separator />

          <Hbox.Item vAlign={'flex-end'}>
            <Button
              kind='primary'
              outlined
              expanded
              type='submit'
              loading={props.loading || addToCartLoading || searchLoading}
              size='normal'
              data-cy='cart-add-product-button'
            >
              {localized.submit}
            </Button>
          </Hbox.Item>
        </Hbox>
      </Form>

      {!props.withoutSearch && (
        <>
          <VSeparator small />
          <BodySecondary>{localized.dontKnow}</BodySecondary>

          <Button kind='link' onClick={openSearchModal} loading={props.loading}>
            {localized.searchProduct}
          </Button>

          <EudoraProductSearchModal
            isOpened={isSearchModalOpened}
            orderCode={props.orderCode}
            onClose={closeSearchModal}
            onProductClick={handleSearchAdd}
          />
        </>
      )}

      <ComboEudoraModal
        key={`${clearComboModal}`}
        opened={comboModalOpened}
        data={comboDetailData?.ComboDetail}
        loading={comboDetailLoading || addToCartLoading}
        error={comboDetailError}
        initialQuantity={searchQuantity}
        onSubmit={addToCart}
        refetch={comboDetailRefetch}
        onClose={handleCloseComboModal}
      />
    </>
  );
};
