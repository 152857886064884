import { ButtonHeight } from '@atomic/atm.button/button.component.style';
import { Hbox } from '@atomic/obj.box';
import { ThemeToken } from '@atomic/obj.constants';
import * as React from 'react';
import Container from 'typedi';
import { TextShimmerBoxStyled, ThumbShimmerBoxStyled } from './shimmer.component.style';
import { getRandomPercentage } from './shimmer.component.utils';

const _ShimmerInput = (props) => <TextShimmerBoxStyled height='48px' margin='0' {...props} />;
export const ShimmerInput = React.memo(_ShimmerInput);

interface ShimmerHProps {
  heading?: 'H1' | 'H2' | 'H3' | 'H4';
}
const theme = Container.get(ThemeToken);
const _ShimmerH: React.FC<ShimmerHProps> = (props) => {
  const boxHeight = {
    H1: theme.FontSize.XLarge,
    H2: theme.FontSize.Medium,
    H3: theme.FontSize.Small,
    H4: theme.FontSize.Small,
    undefined: theme.FontSize.Small,
  };

  return <TextShimmerBoxStyled height={boxHeight[props.heading]} width={getRandomPercentage(20, 60)} {...props} />;
};
/** Deprecating... On new components, use "ShimmerText" */
export const ShimmerH = React.memo(_ShimmerH);

interface ShimmerTextProps {
  size?: string;
  width?: number;
  minWidthPercent?: number;
  maxWidthPercent?: number;
}

export const ShimmerText: React.FC<ShimmerTextProps> = React.memo((props) => (
  <TextShimmerBoxStyled
    height={props.size}
    width={props.width ? `${props.width}px` : getRandomPercentage(props.minWidthPercent, props.maxWidthPercent)}
  />
));
ShimmerText.defaultProps = { maxWidthPercent: 80, minWidthPercent: 20, size: theme.FontSize.Small };

const _ShimmerButton = (props) => <TextShimmerBoxStyled height={ButtonHeight} {...props} />;
export const ShimmerButton = React.memo(_ShimmerButton);

const _ShimmerCell = () => {
  return (
    <Hbox>
      <Hbox.Item hAlign='flex-start' noGrow>
        <ThumbShimmerBoxStyled width='80px' />
      </Hbox.Item>
      <Hbox.Separator />
      <Hbox.Item vAlign='flex-start'>
        <ShimmerH heading='H2' />
        <ShimmerH heading='H2' />
        <ShimmerH heading='H2' />
      </Hbox.Item>
    </Hbox>
  );
};
export const ShimmerCell = React.memo(_ShimmerCell);

interface ShimmerBoxProps {
  width: string;
}

const _ShimmerBox: React.FC<ShimmerBoxProps> = (props) => {
  return <ThumbShimmerBoxStyled width={props.width} />;
};
export const ShimmerBox = React.memo(_ShimmerBox);

const _ShimmerMilestone = () => {
  return <TextShimmerBoxStyled height={theme.Spacing.XLarge} />;
};
export const ShimmerMilestone = _ShimmerMilestone;
