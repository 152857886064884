import { BotCategoryColor } from '@atomic/obj.constants';
import { Token } from 'typedi';
import { BotColor, botTheme } from './bot-constants';
import { EudoraCategoryColor, EudoraColor, eudoraTheme } from './eudora-constants';

type BotTheme = typeof botTheme;
type EudoraTheme = typeof eudoraTheme;
export type CommonTheme = BotTheme | EudoraTheme;
export type Colors = typeof BotColor | typeof EudoraColor;
export type CategoryColor = typeof BotCategoryColor | typeof EudoraCategoryColor;
export interface Theme {
  theme?: CommonTheme;
}

export const ThemeToken = new Token<BotTheme | EudoraTheme>('theme');
