import { Theme } from '@atomic/obj.constants';
import styled from 'styled-components';

export const BorderedListCellStyled = styled.li`
  padding: ${(props) => props.theme.Spacing.Medium};
`;

interface BorderedListStyledProps extends Theme {
  direction?: 'row' | 'column';
}

export const BorderedListStyled = styled.ul`
  margin: 0;
  ${(props: BorderedListStyledProps) => props.direction === 'column' && 'display: inline-flex;'}

  box-shadow: ${(props) => props.theme.BoxShadow.Depth2} rgba(0, 0, 0, 0.1);
  list-style: none;
  padding: 0;
  border-radius: ${(props) => props.theme.Border.Radius};
  border-style: solid;
  border-color: ${(props) => props.theme.Border.Color};
  border-width: ${(props) => props.theme.Border.Width};

  ${(props: BorderedListStyledProps) =>
    props.direction === 'row' &&
    `
      & > ${BorderedListCellStyled} {
        border-bottom-style: solid;
        border-bottom-color: ${props.theme.Border.Color};
        border-bottom-width: ${props.theme.Border.Width};
      }
    `}

  ${(props: BorderedListStyledProps) =>
    props.direction === 'column' &&
    `
      & > ${BorderedListCellStyled} {
        border-right-style: solid;
        border-right-color: ${props.theme.Border.Color};
        border-right-width: ${props.theme.Border.Width};
      }
    `}

  ${BorderedListCellStyled}:last-child {
    border-style: none;
  }
`;

BorderedListStyled.defaultProps = {
  direction: 'row',
};
