import * as React from 'react';
import { Hbox } from '@atomic/obj.box';
import {
  StepHeaderItem,
  StepHeaderItemProps,
  SteptHeaderPosition,
} from '@app/components/mol.step-header/step-header-item.component';

export interface StepHeaderProps {
  items: StepHeaderItemProps[];
}

export const StepHeader: React.FC<StepHeaderProps> = (props) => {
  const handlePosition = (index: number, length: number) => {
    return index === 0
      ? SteptHeaderPosition.First
      : index === length - 1
      ? SteptHeaderPosition.Last
      : SteptHeaderPosition.Middle;
  };

  return (
    <Hbox hAlign='center'>
      {props.items?.map((item, index) => (
        <Hbox.Item key={item.title} noGrow>
          <StepHeaderItem position={handlePosition(index, props.items?.length)} {...item} />
        </Hbox.Item>
      ))}
    </Hbox>
  );
};
