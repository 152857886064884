import DatePicker from 'react-datepicker';
import styled from 'styled-components';

// import { Color } from '@atomic/obj.constants';
import { FieldBorderCss, FieldCss } from '@atomic/obj.form/field.component.style';
import 'react-datepicker/dist/react-datepicker.css';

// const DATE_PICKER_COLOR = Color.Primary;

export const DatePickerStyled = styled(DatePicker)`
  ${FieldBorderCss}
  ${FieldCss}
`;
