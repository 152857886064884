import { Project } from '@app/config-variables';
import { useQueryHook } from '@app/core/graphql';
import { CurrentConversations, CurrentConversations_CurrentConversations_conversations } from '@app/data/graphql';
import { mapApolloError } from '@app/data/graphql/graphql-error.mapper';
import { QueueItem } from '@app/interfaces';
import { useAppType } from '@app/modules/account/use-appType';
import { ThemeToken } from '@atomic/obj.constants';
import Container from 'typedi';

const theme = Container.get(ThemeToken);

export const useCurrentConversationsQuery = () => {
  const { isActivation } = useAppType();
  const skip = theme.Project === Project.eudora || isActivation;

  const result = useQueryHook<CurrentConversations>('current-conversations', { fetchPolicy: 'network-only', skip });

  return {
    data: result.data?.CurrentConversations?.conversations.map(mapConversationResult),
    error: result.error && mapApolloError(result.error),
    loading: result.loading,
    refetch: () => result.refetch(),
  };
};

const mapConversationResult = (conversation: CurrentConversations_CurrentConversations_conversations): QueueItem => {
  return {
    createdAt: conversation.createdAt,
    id: conversation.resellerId,
    name: conversation.resellerName,
  };
};
