import { CartListWrapper } from '@app/components/atm.cart-section-wrapper';
import { LocalizedContext } from '@app/components/obj.localization';
import { Body } from '@atomic/atm.typography';
import { CellData, RadioIconList } from '@atomic/mol.radio-icon-list/radio-icon-list.component';
import { VSeparator } from '@atomic/obj.grid';
import * as React from 'react';

export interface CartCycleSelectSectionProps {
  cycleList: CellData[];
  initialValue?: string;
  handleCycleChange: (value) => void;
}

export const CartCycleSelectSection: React.FC<CartCycleSelectSectionProps> = ({
  cycleList,
  handleCycleChange,
  initialValue,
}) => {
  const localized = React.useContext(LocalizedContext).strings.components.cartPages;

  return (
    <>
      <VSeparator />
      <Body>{localized.chooseCycle}</Body>

      <VSeparator />
      <CartListWrapper>
        <RadioIconList
          fieldName='address'
          list={cycleList}
          onValueChange={handleCycleChange}
          initialValue={initialValue}
        />
      </CartListWrapper>
    </>
  );
};
