import * as React from 'react';

import { FrameStyled, FrameStyleProps } from './frame.component.style';

export interface FrameProps extends FrameStyleProps {
  children: any;
}

export const Frame = (props: FrameProps) => {
  return <FrameStyled {...props}>{props.children}</FrameStyled>;
};
