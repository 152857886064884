import * as React from 'react';

import { ShimmerH } from '@atomic/mol.shimmer';
import { ThumbShimmerBoxStyled } from '@atomic/mol.shimmer/shimmer.component.style';
import { Hbox } from '@atomic/obj.box';

const THUMB_WIDTH = '45px';

export const ProductsShimmer = () => {
  const productsShimmered = [];
  for (let i = 0; i < 3; i++) {
    productsShimmered.push(
      <Hbox key={i}>
        <Hbox.Separator />
        <Hbox.Item noGrow>
          <ThumbShimmerBoxStyled width={THUMB_WIDTH} />
        </Hbox.Item>
        <Hbox.Separator />
        <Hbox.Item>
          <ShimmerH heading='H3' />
          <ShimmerH heading='H3' />
        </Hbox.Item>
        <Hbox.Separator />
      </Hbox>,
    );
  }
  return <>{productsShimmered}</>;
};
