import { Service } from 'typedi';
import { isNullOrUndefined } from 'util';

import { getStorage, StorageType } from './storage.provider';

const PAGE_LOADING_ERROR_KEY = 'pageLoadingError';
const PAGE_LOADING_ERROR_SEPARATOR = ' ';

@Service()
export class SessionTokenDataSource {
  private storage = getStorage(StorageType.Session);

  addPageLoadingError(name: string) {
    const currPageLoadingError: Set<string> = this.getPageLoadingError();
    currPageLoadingError.add(name);
    this.savePageLoadingError(currPageLoadingError);
  }

  removePageLoadingError(name: string) {
    const pageLoadingError: Set<string> = this.getPageLoadingError();
    pageLoadingError.delete(name);
    this.savePageLoadingError(pageLoadingError);
  }

  cleanPageLoadingError() {
    this.storage.put(PAGE_LOADING_ERROR_KEY, '');
  }

  getPageLoadingError(): Set<string> {
    const errorSetString = this.storage.get(PAGE_LOADING_ERROR_KEY);
    let errorSet: Set<string> = new Set();
    if (!isNullOrUndefined(errorSetString)) {
      errorSet = new Set(errorSetString.split(PAGE_LOADING_ERROR_SEPARATOR));
    }
    return errorSet;
  }

  private savePageLoadingError(nextPageLoadingErrorSet: Set<string>) {
    const nextPageLoadingError: string = Array.from(nextPageLoadingErrorSet).join(PAGE_LOADING_ERROR_SEPARATOR);
    this.storage.put(PAGE_LOADING_ERROR_KEY, nextPageLoadingError);
  }
}
