import { Theme } from '@atomic/obj.constants';
import styled from 'styled-components';

interface DropDownMenuStyledProps extends Theme {
  show: boolean;
}

export const DropDownMenuWrapperStyled = styled.div`
  position: relative;
`;

export const DropDownMenuStyled = styled.div`
  cursor: pointer;
  text-align: center;
  color: ${(props) => props.theme.Color.White};
  padding: ${(props) => props.theme.Spacing.Medium};
  width: 46px;

  &:hover {
    opacity: 0.8;
  }
`;

export const DropDownMenuContentStyled = styled.div`
  position: fixed;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 220px;
  border: ${(props) => props.theme.Border.Width} ${(props) => props.theme.Border.Color} solid;
  border-radius: ${(props) => props.theme.Border.Radius};
  top: calc(${(props) => props.theme.HeaderHeight.Desk} - ${(props) => props.theme.Spacing.XSmall});
  right: 16px;
  background-color: ${(props) => props.theme.Color.White};

  ${(props: DropDownMenuStyledProps) =>
    props.show
      ? `
    opacity: 1;
    transition: opacity ${props.theme.TransitionDuration};
    z-index: ${props.theme.Zindex.DropDownMenuActive};
  `
      : `
    opacity: 0;
    transition: opacity ${props.theme.TransitionDuration}, z-index 0s ${props.theme.TransitionDuration};
    z-index: ${props.theme.Zindex.DropDownMenuInactive};
  `}
`;
