import { Project } from '@app/config-variables';
import { RouteComponentProps } from '@app/core/route';
import { useCommercialStructureListQuery } from '@app/domain/commercial-structure.use-case';
import { useCurrentConversationsQuery } from '@app/domain/current-conversations.use-case';
import { useClientErrorFlashDispatcher } from '@app/domain/error-dispatcher.hook';
import { useConversationsQuery } from '@app/domain/queue.use-case';
import { CartPageLocationState } from '@app/models/orders.model';
import { useAppType } from '@app/modules/account/use-appType';
import { AppPath } from '@app/modules/app/routes';
import { OrderProvider, QueueProvider } from '@app/providers';
import { isPast } from '@app/utils/is-past';
import { CommonTheme } from '@atomic/obj.constants';
import * as React from 'react';
import { useHistory } from 'react-router';
import { ThemeContext } from 'styled-components';
import CartPagesRouter from './cart-pages.router';

interface CartSearchParams {
  finishConversation?: boolean;
  orderCode?: string;
}

export const CartRouter: React.FC<RouteComponentProps<CartSearchParams, {}, CartPageLocationState>> = (props) => {
  const theme = React.useContext<CommonTheme>(ThemeContext);
  const history = useHistory();
  const { isStarts } = useAppType();

  const startsQueue = useCurrentConversationsQuery();
  const commercialStructure = useCommercialStructureListQuery();
  const ActivationQueue = useConversationsQuery(commercialStructure.data);
  const queue = isStarts ? startsQueue : ActivationQueue;

  useClientErrorFlashDispatcher(startsQueue.error);
  useClientErrorFlashDispatcher(ActivationQueue.error);

  return (
    <QueueProvider initialIndex={props.location?.state?.resellerId} queue={queue}>
      <OrderProvider
        resellerId={props.location?.state?.resellerId}
        editOrderCode={props.location?.state?.editOrderCode}
        onCurrentOrderComplete={(data) => {
          if (props.location?.pathname !== AppPath.Cart.Base) {
            return;
          }

          if (isPast(data?.expiration)) {
            return history.push({
              pathname: AppPath.Cart.Management,
              state: props.location?.state,
            });
          }

          return history.push({
            pathname: theme.Project === Project.eudora ? AppPath.Cart.BusinessModel : AppPath.Cart.Address,
            state: props.location?.state,
          });
        }}
        onCurrentOrderError={() => {
          if (props.location?.pathname !== AppPath.Cart.Base) {
            return;
          }

          return history.push({
            pathname: theme.Project === Project.eudora ? AppPath.Cart.BusinessModel : AppPath.Cart.Address,
            state: props.location?.state,
          });
        }}
      >
        <CartPagesRouter />
      </OrderProvider>
    </QueueProvider>
  );
};

export default CartRouter;
