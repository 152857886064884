import { NewsEnvironment } from '@app/data/graphql';
import { NewsDatasource } from '@app/data/local/news.datasource';
import { useListNewsClientQuery } from '@app/domain/list-news-client.use-case';
import { NewsData } from '@app/models/news.model';
import { useAppType } from '@app/modules/account/use-appType';
import * as React from 'react';
import Container from 'typedi';
import { NewsBannerMessage } from './mol.news-banner-message/news-banner-message.component';

export const NewsBanners: React.FC = () => {
  const [newsQueue, setNewsQueue] = React.useState<NewsData[]>([]);
  const { isStarts, isActivation } = useAppType();
  const visualizedNews = Container.get(NewsDatasource).getVisualizedNews();

  useListNewsClientQuery({
    limit: 10,
    offset: 0,
    onCompleted: (data) =>
      setNewsQueue(
        data
          .filter((news) => {
            if (isActivation) {
              return (
                !visualizedNews?.includes(news.id) &&
                (news.isPublic || news.environments.includes(NewsEnvironment.ACTIVATION))
              );
            }

            if (isStarts) {
              return (
                !visualizedNews?.includes(news.id) &&
                (news.isPublic || news.environments.includes(NewsEnvironment.START))
              );
            }

            return false;
          })
          .map(({ __typename, ...news }) => news),
      ),
  });

  return <NewsBannerMessage news={newsQueue} updateQueueMessages={setNewsQueue} />;
};
