import * as React from 'react';

export const IcSchedule = (props) => (
  <svg width={28} height={28} viewBox='0 0 24 24' {...props}>
    <g fill={props.color || '#525252'} fillRule='nonzero'>
      <path d='M11.87 6.485a.696.696 0 00-.696.696v5.033a.697.697 0 00.173.453l.072.07 2.651 2.181a.68.68 0 00.518.188.697.697 0 00.434-.187l.061-.066a.699.699 0 00.05-.817.697.697 0 00-.197-.201L12.53 11.84l.036-4.66a.695.695 0 00-.696-.696z' />
      <path d='M11.97 2.8a9.1 9.1 0 014.503 1.193l.223.132.107-.105c.169-.156.352-.295.548-.416l.2-.113.207-.1c.44-.196.916-.299 1.397-.302a3.497 3.497 0 011.782 6.533l-.133.07.07.285a9.126 9.126 0 01.051 3.687l-.077.365-.093.361a9.124 9.124 0 01-2.191 3.856l-.182.183 1.654 2.166c.067.089.11.191.13.298l.01.109-.007.11a.696.696 0 01-1.172.401l-.073-.082-1.592-2.099-.2.145a9.125 9.125 0 01-4.484 1.583l-.345.02-.346.006a9.124 9.124 0 01-5.175-1.61l-.202-.144-1.595 2.105-.068.076a.696.696 0 01-1.1-.824l.06-.095 1.653-2.166-.18-.182a9.124 9.124 0 01-1.981-3.197l-.112-.327-.099-.332a9.124 9.124 0 01-.118-4.415l.07-.288-.142-.08a3.497 3.497 0 01-.572-.438l-.17-.172-.158-.183a3.497 3.497 0 015.054-4.8l.118.112.224-.132a9.1 9.1 0 013.801-1.167l.366-.02.338-.007zm-1.5 1.504a7.757 7.757 0 102.998 15.223A7.757 7.757 0 0010.47 4.304zm-6.522.325A2.105 2.105 0 002.65 6.585a2.13 2.13 0 00.848 1.688l.074.051.09-.206a9.174 9.174 0 012.251-3.027l.172-.147-.058-.048a2.105 2.105 0 00-.673-.34L5.17 4.51a2.105 2.105 0 00-1.22.12zm14.492-.006c-.186.067-.36.16-.52.274l-.064.05.17.145a9.174 9.174 0 012.25 3.026l.089.204.066-.045c.104-.08.201-.17.289-.267l.124-.153a2.106 2.106 0 00-2.404-3.234z' />
    </g>
  </svg>
);
