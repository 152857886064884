import { ApolloError } from '@apollo/client';
import { ProductSearchModal } from '@app/components/mol.product-search-modal/product-search-modal.component';
import { LocalizedContext } from '@app/components/obj.localization';
import { Cart } from '@app/data/graphql';
import { useAddToCartMutation } from '@app/domain/orders.use-case';
import { OrderContext } from '@app/providers/order.provider';
import { EventAction, EventCategory, sanitizeMessage } from '@app/utils/data-layer';
import { Button } from '@atomic/atm.button';
import { TextField } from '@atomic/atm.text-field';
import { BodySecondary } from '@atomic/atm.typography';
import { useBooleanState } from '@atomic/obj.boolean-state/boolean-state.hook';
import { Hbox } from '@atomic/obj.box';
import { Form, FormData } from '@atomic/obj.form';
import { VSeparator } from '@atomic/obj.grid';
import * as React from 'react';

interface FastShopFormProps {
  loading: boolean;
  onProductAdded: (cart: Cart) => void;
  onError?: (error?: ApolloError) => void;
  withoutSearch?: boolean;
}

export const FastShopForm: React.FC<FastShopFormProps> = (props) => {
  const { onError } = props;
  const localized = React.useContext(LocalizedContext).strings.components.fastShop;
  const order = React.useContext(OrderContext).cart;
  const products = order?.products;

  const { value: isModalOpened, toggle: toggleModal } = useBooleanState(false);
  const [searchId, setSearchId] = React.useState('');
  const [searchQuantity, setSearchQuantity] = React.useState(1);

  const { addToCart, loading: addToCartLoading } = useAddToCartMutation({
    onCompleted: props.onProductAdded,
    onError,
    orderCode: order?.orderCode,
  });

  const handleSearchIdChange = (text: string) => {
    setSearchId(text);
  };

  const handleSearchQuantityChange = (quantity: number) => {
    setSearchQuantity(quantity);
  };

  const handleFastShopAdd = (FormInput: FormData<{ productCode: string; productQuantity: string }>) => {
    setSearchId('');
    setSearchQuantity(1);
    const selectedProduct = products?.find((product) => product.code === FormInput.data.productCode);
    const totalProductQuantity = parseInt(FormInput.data.productQuantity, 10) + (selectedProduct?.quantity || 0);
    addToCart({ productCode: FormInput.data.productCode, quantity: totalProductQuantity });
  };

  const handleSearchAdd = (productCode: string) => {
    addToCart({ productCode: productCode, quantity: 1 });
    toggleModal();
  };

  return (
    <>
      <Form onSubmit={handleFastShopAdd}>
        <Hbox>
          <Hbox.Item vAlign={'flex-end'}>
            <Form.Field label={localized.label} name='productCode' value={searchId}>
              <TextField
                type='number'
                kind='normal'
                placeholder={localized.placeholder}
                dismissable
                onValueChange={handleSearchIdChange}
                data-cy='cart-add-product-code'
                min={0}
              />
            </Form.Field>
          </Hbox.Item>

          <Hbox.Separator />
          <Hbox.Item vAlign={'flex-end'}>
            <Form.Field label={localized.quantityLabel} name='productQuantity' value={searchQuantity}>
              <TextField
                type='number'
                kind='normal'
                min={0}
                placeholder={localized.quantityPlaceholder}
                onValueChange={handleSearchQuantityChange}
                data-cy='cart-add-product-quantity'
              />
            </Form.Field>
          </Hbox.Item>

          <Hbox.Separator />
          <Hbox.Item vAlign={'flex-end'}>
            <Button
              kind='primary'
              outlined
              expanded
              type='submit'
              loading={props.loading || addToCartLoading}
              size='normal'
              data-cy='cart-add-product-button'
              dataGtmEventCategory={EventCategory.Attendence}
              dataGtmEventAction={EventAction.Click.Button}
              dataGtmEventLabel={sanitizeMessage(localized.submit)}
            >
              {localized.submit}
            </Button>
          </Hbox.Item>
        </Hbox>
      </Form>

      {!props.withoutSearch && (
        <>
          <VSeparator small />
          <BodySecondary>{localized.dontKnow}</BodySecondary>

          <Button
            kind='link'
            onClick={toggleModal}
            loading={props.loading}
            dataGtmEventCategory={EventCategory.Attendence}
            dataGtmEventAction={EventAction.Click.Link}
            dataGtmEventLabel={sanitizeMessage(localized.searchProduct)}
          >
            {localized.searchProduct}
          </Button>

          <ProductSearchModal isOpened={isModalOpened} onClose={toggleModal} onProductClick={handleSearchAdd} />
        </>
      )}
    </>
  );
};
