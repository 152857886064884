import { ApolloError } from '@apollo/client';
import { useQueryHook } from '@app/core/graphql';
import { CurrentOrder, CurrentOrders, ResellerCurrentOrder, ResellerCurrentOrderVariables } from '@app/data/graphql';
import { mapApolloError } from '@app/data/graphql/graphql-error.mapper';
import { UserContext } from '@app/providers/user.provider';

import * as React from 'react';

interface CurrentOrderType {
  data: CurrentOrder;
  error: ApolloError;
  loading: boolean;
  refetch: () => void;
}

export const useCurrentOrderQuery = ({
  resellerId,
  onCompleted,
  onError,
}: {
  resellerId: string;
  onCompleted?: (data: CurrentOrder) => void;
  onError?: (error: ApolloError) => void;
}): CurrentOrderType => {
  const userContext = React.useContext(UserContext);
  const result = useQueryHook<ResellerCurrentOrder, ResellerCurrentOrderVariables>('reseller-current-order.query', {
    errorPolicy: 'none',
    fetchPolicy: 'no-cache',
    onCompleted: (data) => onCompleted?.(data.ResellerCurrentOrder),
    onError,
    skip: !userContext.isLogged() || !resellerId,
    variables: {
      resellerId,
    },
  });

  return {
    data: result.data?.ResellerCurrentOrder,
    error: result.error,
    loading: result.loading,
    refetch: () => result.refetch(),
  };
};

export const useCurrentOrdersQuery = () => {
  const userContext = React.useContext(UserContext);
  const result = useQueryHook<CurrentOrders>('current-orders', {
    fetchPolicy: 'network-only',
    skip: !userContext.isLogged(),
  });

  return {
    data: result.data,
    error: result.error && mapApolloError(result.error),
    loading: result.loading,
    refetch: () => result.refetch(),
  };
};
