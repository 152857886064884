import * as React from 'react';
import { RouteComponentProps as ReactRouterComponentProps, RouteProps as ReactRouteProps } from 'react-router';
import { Route as ReactRoute } from 'react-router-dom';
import { Container } from 'typedi';

import { Redirect } from './redirect.component';
import { RouteGuardProps } from './route-guard.component';
import { Router } from './router';

export interface RouteComponentProps<P = {}, C = {}, S = {}> extends ReactRouterComponentProps<P, C, S> {
  router: Router;
}

export interface RouteProps extends ReactRouteProps {
  guard?: React.FunctionComponent<RouteGuardProps>;
  redirectPath?: string;
}

/**
 * This class resolves relative paths allowing paths with `.` and `..`
 */
export class Route extends React.Component<RouteProps, any> {
  private router: Router = Container.get(Router);

  render() {
    const { guard, component, redirectPath, ...props } = this.props;
    return <ReactRoute {...props} component={this.getComponent} />;
  }

  private getComponent = (routeProps: any) => {
    const { guard, component } = this.props;
    const GuardComponent = guard;
    const WrappedComponent = component;
    if (!this.props.guard) {
      return <WrappedComponent {...routeProps} router={this.router} />;
    }

    return (
      <GuardComponent>
        {(authorized: boolean) => {
          return authorized ? (
            <WrappedComponent {...routeProps} router={this.router} />
          ) : (
            <Redirect to={this.props.redirectPath} />
          );
        }}
      </GuardComponent>
    );
  };
}
