import { ActivityIndicator } from '@atomic/atm.activity-indicator';
import * as React from 'react';

import { LoadingStyled } from './loading-state.style';

export interface LoadingStateProps {
  topMost?: boolean;
  loading: boolean;
  enableActivityIndicator?: boolean;
  error?: boolean;
  data?: boolean;
}

const StateShimmer: React.FC<any> = (props: any) => <>{props.children}</>;
StateShimmer.displayName = 'StateShimmer';

const StateError: React.FC<any> = (props: any) => <>{props.children}</>;
StateError.displayName = 'StateError';

const StateNoData: React.FC<any> = (props: any) => <>{props.children}</>;
StateNoData.displayName = 'StateNoData';

export class LoadingState extends React.Component<LoadingStateProps, undefined> {
  static defaultProps = {
    data: true,
    enableActivityIndicator: true,
    topMost: false,
  };

  static Shimmer = StateShimmer;
  static Error = StateError;
  static NoData = StateNoData;

  render() {
    const { shimmer, error, noData, data } = this.getTypedChildren();
    const showShimmer = this.props.loading;
    const showError = this.props.error && !showShimmer;
    const showNoData = !this.props.error && !this.props.data && !showShimmer;
    const showData = !showShimmer && !showError && !showNoData;
    const showActivityIndicator = showShimmer && !shimmer;

    return (
      <React.Fragment>
        {showError && error}
        {showShimmer && shimmer}
        {showNoData && noData}
        {(showData || showActivityIndicator) && data}
        {showActivityIndicator && (
          <LoadingStyled topMost={this.props.topMost}>
            <ActivityIndicator type='spinner' />
          </LoadingStyled>
        )}
      </React.Fragment>
    );
  }

  private getTypedChildren() {
    let shimmer;
    let error;
    let noData;
    const data = [];

    React.Children.map(this.props.children, (child: any) => {
      if (child && child.type) {
        switch (child.type.displayName) {
          case StateShimmer.displayName:
            shimmer = child;
            break;
          case StateError.displayName:
            error = child;
            break;
          case StateNoData.displayName:
            noData = child;
            break;
          default:
            data.push(child);
        }
      } else {
        data.push(child);
      }
    });

    return { data, error, noData, shimmer };
  }
}
