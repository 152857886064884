import { isNullOrUndefined } from 'util';

const DAY_IN_MILISSECONDS = 86400000;

const MIDNIGTH_REGEX = new RegExp(/T00:00:00.000Z/g);
const MIDNIGTH_REPLACE_TIME = 'T06:00:00.000Z';

export const parseMidnigthTimezoneDate = (date: string) => {
  if (MIDNIGTH_REGEX.test(date)) {
    return new Date(date.replace(MIDNIGTH_REGEX, MIDNIGTH_REPLACE_TIME));
  }

  return new Date(date);
};

export const removeTmzFromDateString = (date: string) => {
  const splittedDate = date?.split('-');
  return splittedDate && `${splittedDate[2]?.slice(0, 2)}/${splittedDate[1]}/${splittedDate[0]}`;
};

export const parseDateString = (date: string, omitYear?: boolean) => {
  const dateArray = date.split('-');
  const year = dateArray[0].slice(2);
  const month = dateArray[1];
  const day = dateArray[2];
  return `${day}/${month}` + (omitYear ? '' : `/${year}`);
};

export const parseDateToFormattedString = (date: Date, omitYear?: boolean, withTime?: boolean) => {
  if (!date) {
    return null;
  }

  const day = twoDigit(date.getDate());
  const month = twoDigit(date.getMonth() + 1);
  const year = twoDigit(date.getFullYear());
  const HH = twoDigit(date.getHours());
  const MM = twoDigit(date.getMinutes());

  return `${day}/${month}` + (omitYear ? '' : `/${year}`) + (withTime ? `, às ${HH}:${MM}` : '');
};

export const parseDateToFormattedYYYYString = (date: Date) => {
  if (!date) {
    return null;
  }

  const day = twoDigit(date.getDate());
  const month = twoDigit(date.getMonth() + 1);
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};

export const parseDateToFormattedTimeString = (date: Date) => {
  const HH = twoDigit(date.getHours());
  const MM = twoDigit(date.getMinutes());
  return `${HH}:${MM}`;
};

export const twoDigit = (num) => {
  return !isNullOrUndefined(num) ? ('0' + num).slice(-2) : null;
};

export const dmyToYmd = (date: { value: string; separator: string }) => {
  const dateArray = date.value.split(date.separator);
  return new Date(+dateArray[2], +dateArray[1] - 1, +dateArray[0]);
};

interface ExtractDateOptions {
  hideTime: boolean;
}

export function extractReadableDate(date: Date, options?: ExtractDateOptions) {
  if (!date || !date.getDate()) {
    return null;
  }
  let message = '';

  if (isToday(date)) {
    message += 'hoje ';
  }

  if (wasYesterday(date)) {
    message += 'ontem ';
  }

  if (!wasYesterday(date) && !isToday(date)) {
    message += 'em ' + parseDateToFormattedString(date);
  }

  if (!options?.hideTime) {
    message += ' às ' + getTime(date);
  }

  return message;
}

const getTime = (date: Date) =>
  `${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}:${('0' + date.getSeconds()).slice(-2)}`;

export function isToday(date: Date): boolean {
  const now = new Date();
  return (
    now.getDate() === date.getDate() && now.getMonth() === date.getMonth() && now.getFullYear() === date.getFullYear()
  );
}

export function isOneOrMoreDaysAhead(date: Date, baseDate?: Date): boolean {
  const compareDate = baseDate ?? new Date();

  const isMonthAhead = compareDate.getMonth() < date.getMonth();
  const isYearAhead = compareDate.getFullYear() < date.getFullYear();

  return (
    (compareDate.getDate() < date.getDate() || isMonthAhead || isYearAhead) &&
    (compareDate.getMonth() <= date.getMonth() || isYearAhead) &&
    compareDate.getFullYear() <= date.getFullYear()
  );
}

function wasYesterday(param: Date): boolean {
  const now = new Date();
  const date = new Date(param);
  date.setDate(param.getDate() + 1);
  return (
    now.getDate() === date.getDate() && now.getMonth() === date.getMonth() && now.getFullYear() === date.getFullYear()
  );
}

export function isTomorrow(param: Date): boolean {
  const now = new Date();
  const date = new Date(param);
  date.setDate(param.getDate() - 1);
  return (
    now.getDate() === date.getDate() && now.getMonth() === date.getMonth() && now.getFullYear() === date.getFullYear()
  );
}

export function intervalInDays(startDate: Date, endDate: Date): number {
  return Math.floor((endDate.getTime() - startDate.getTime()) / DAY_IN_MILISSECONDS);
}
