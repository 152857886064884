import { ThemeToken } from '@atomic/obj.constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import Container from 'typedi';
import { ActivityIndicatorProps } from './activity-indicator.component';
const theme = Container.get(ThemeToken);

export const ActivityIndicatorStyled = styled(FontAwesomeIcon).withConfig({
  shouldForwardProp: (prop) => !['light'].includes(prop),
})`
  color: ${(props: ActivityIndicatorProps) => (props.light ? theme.Color.White : theme.Color.Primary)};
  display: inline-block;
`;
