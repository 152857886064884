import { AutocompleteProductCell } from '@app/components/atm.product-cell/autocomplete-product-cell.component';
import { FastShopProductListWrapper } from '@app/components/mol.fast-shop';
import { ProductSearchModalPlaceholder } from '@app/components/mol.product-search-modal/product-search-modal-placeholder.component';
import { LocalizedContext } from '@app/components/obj.localization';
import { ProductsShimmer } from '@app/components/org.products/products.shimmer';
import { TextField } from '@atomic/atm.text-field';
import { Body, Centered, H1 } from '@atomic/atm.typography';
import { VSeparator } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import * as React from 'react';

interface GenericProductType {
  code: string;
  name: string;
  imageUrl: string;
}

interface ProductNameSearchProps {
  onProductClick: (product: GenericProductType) => () => void;
  onValueChange: (product: string) => void;
  loading: boolean;
  error: boolean;
  data: GenericProductType[];
  hasSearchStarted: boolean;
}

export const ProductNameSearchView: React.FC<ProductNameSearchProps> = ({
  onProductClick,
  onValueChange,
  loading,
  error,
  data,
  hasSearchStarted,
}) => {
  const localized = React.useContext(LocalizedContext).strings.components.searchProducts;

  return (
    <>
      <H1>{localized.sideMenuTitle}</H1>
      <VSeparator />

      <TextField onValueChange={onValueChange} placeholder={localized.searchProduct} type='text' icon='search' />
      <VSeparator small />

      <FastShopProductListWrapper>
        <LoadingState loading={loading} error={error} data={data?.length > 0}>
          <LoadingState.Shimmer>
            <ProductsShimmer />
          </LoadingState.Shimmer>

          <LoadingState.Error>
            <Body>
              <Centered>{localized.error}</Centered>
            </Body>
          </LoadingState.Error>

          <LoadingState.NoData>
            <ProductSearchModalPlaceholder
              placeholderText={hasSearchStarted ? localized.startSearching : localized.productsNotFound}
            />
          </LoadingState.NoData>

          {data?.map((product) => (
            <>
              <AutocompleteProductCell
                key={product.code}
                product={{ id: product.code, name: product.name }}
                onClick={onProductClick(product)}
              />
              <VSeparator />
            </>
          ))}
        </LoadingState>
      </FastShopProductListWrapper>
    </>
  );
};
