import React from 'react';
import { H3, Bold, Body } from '@atomic/atm.typography';
import { VSeparator } from '@atomic/obj.grid';
import { LocalizedContext } from '@app/components/obj.localization';
import { ComboType } from '@app/data/graphql';

export const ComboDetail: React.FC<{ combo: ComboType; comboQuantity: number }> = ({ combo, comboQuantity }) => {
  const localized = React.useContext(LocalizedContext).strings.components.comboEudora;
  return (
    <>
      <VSeparator />
      <H3>{localized.content}:</H3>
      <VSeparator small />

      {combo?.products.map((item, index) => (
        <>
          <Body key={item.code + index}>
            {localized.productDetail(item.quantity * comboQuantity, item.code, item.name)}
          </Body>
          <VSeparator small />
        </>
      ))}

      {combo?.productSelections.map((item, index) => (
        <>
          <Body key={item.code + index}>
            {localized.productSelectionDetail(item.quantityPerKit * comboQuantity)}
            <Bold>{localized.category(index + 1)}</Bold>
          </Body>
          <VSeparator small />
        </>
      ))}
    </>
  );
};
