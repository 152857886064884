import { Theme } from '@atomic/obj.constants';
import { HighlightStyle } from '@atomic/obj.mixin';
import styled, { css } from 'styled-components';

const ICON_AND_PADDING = '40px';
const NOTIFICATION_HEIGHT = 38;
const MAX_NOTIFICATIONS = 5;

export const NotificationsDialogStyled = styled.div`
  position: relative;
  display: inline-block;
  z-index: ${(props) => props.theme.Zindex.notificationDialogStyled};
`;

export const NotificationsDialogIconStyled = styled.div`
  position: relative;
  display: inline-block;
  ${HighlightStyle}
`;

export const NotificationsDialogBadgeStyled = styled.div`
  position: absolute;
  top: -${(props) => props.theme.Spacing.Small};
  right: -${(props) => props.theme.Spacing.Small};
  text-align: center;
  color: ${(props) => props.theme.Color.White};
  font-size: ${(props) => props.theme.FontSize.Small};
  font-family: ${(props) => props.theme.FontFamily.Primary};
  font-weight: ${(props) => props.theme.FontWeight.Medium};
  background-color: ${(props) => props.theme.Color.Accessory};
  border-radius: ${(props) => props.theme.Border.RadiusLarge};
  border-width: ${(props) => props.theme.Border.Width};
  border-color: ${(props) => props.theme.Color.White};
  border-style: solid;
  padding-left: ${(props) => props.theme.Spacing.XSmall};
  padding-right: ${(props) => props.theme.Spacing.XSmall};
`;

const DialogArrowCss = css`
  &::after {
    content: '';
    position: absolute;
    top: -${(props) => props.theme.Spacing.Medium};
    right: ${(props) => props.theme.Spacing.Small};
    border-width: ${(props) => props.theme.Spacing.Small};
    border-style: solid;
    border-color: transparent transparent ${(props) => props.theme.Color.White} transparent;
  }

  /* Fake shadow */
  &::before {
    content: '';
    position: absolute;
    top: -17px;
    right: ${(props) => props.theme.Spacing.Small};
    border-width: ${(props) => props.theme.Spacing.Small};
    border-style: solid;
    border-color: transparent transparent ${(props) => props.theme.Color.GrayLight} transparent;
  }
`;

export const NotificationsDialogDialogStyled = styled.div`
  position: absolute;
  top: ${ICON_AND_PADDING};
  right: -${(props) => props.theme.Spacing.Small};
  width: 384px;
  max-height: ${(props: Theme) =>
    (NOTIFICATION_HEIGHT + parseInt(props.theme.Spacing.Medium, 10) * 2) * MAX_NOTIFICATIONS}px;
  overflow-y: auto;
  background-color: ${(props) => props.theme.Color.White};
  box-shadow: ${(props) => props.theme.BoxShadow.Depth12} ${(props) => props.theme.Color.GrayLight};
  border-radius: ${(props) => props.theme.Border.Radius};

  transition: all 0.3s ease-in-out;
  ${(props) => (props.shown ? 'opacity: 1;' : 'opacity: 0; visibility: hidden;')}

  ${DialogArrowCss}
`;
