import React from 'react';
import { useScriptTag } from '@app/components/atm.script-tag/script-tag.hook';
import { CommonTheme } from '@atomic/obj.constants';
import { Project } from '@app/config-variables';
import { ThemeContext } from 'styled-components';
import { chatScriptStr } from '@app/modules/app/embedded-chat/script';
import { UserContext } from '@app/providers';
import { useStyleTag } from '@app/components/atm.style-tag/style-tag.hook';
import { chatStyleStr, displayNone } from './embedded-chat/style';

export const useEmbeddedChat = () => {
  const project = React.useContext<CommonTheme>(ThemeContext).Project;
  const userContext = React.useContext(UserContext);
  const showChat = project === Project.eudora && userContext.isLogged();
  useScriptTag({ textContent: showChat ? chatScriptStr : undefined });
  useStyleTag({ textContent: showChat ? chatStyleStr : displayNone });
};
