import * as queryString from 'query-string';

import {
  BaseQueryParams,
  LeadQueryParams,
  RegisterFormQueryParams,
  RegisterQueryParams,
  ResellerFilterQueryParams,
  ResellerOrderQueryParams,
} from '@app/models/query-param.model';

export const parseSearchToObject = <T extends {}>(search: string) =>
  queryString.parse(search, { parseBooleans: true }) as T;

export const parseObjectToSearch = (object) => queryString.stringify(object);

export const parseBaseQueryParams = (queryParams: BaseQueryParams) =>
  queryString.stringify({
    commercialStructureCode: queryParams.commercialStructureCode,
    cycleId: queryParams.cycleId,
  });

export const parseResellerFilterQueryParams = (queryParams: ResellerFilterQueryParams) =>
  parseObjectToSearch({
    activationCategory: queryParams.activationCategory,
    activationFeedback: queryParams.activationFeedback,
    commercialStructureCode: queryParams.commercialStructureCode,
    cycleId: queryParams.cycleId,
    dateRangeFrom: queryParams.dateRangeFrom,
    dateRangeTo: queryParams.dateRangeTo,
    hasLimit: queryParams.hasLimit,
    location: queryParams.location,
    orderBy: queryParams.orderBy,
    potential: queryParams.potential,
    reactionId: queryParams.reactionId,
    resellerId: queryParams.resellerId,
    showWelchList: queryParams.showWelchList,
    withDefault: queryParams.withDefault,
  });

export const parseLeadQueryParams = (queryParams: LeadQueryParams) =>
  parseObjectToSearch({
    commercialStructureCode: queryParams.commercialStructureCode,
    cycleId: queryParams.cycleId,
    leadId: queryParams.leadId,
  });

export const parseRegisterFormQueryParams = (queryParams: RegisterFormQueryParams) =>
  parseObjectToSearch({
    commercialStructureCode: queryParams.commercialStructureCode,
    cycleId: queryParams.cycleId,
    email: queryParams.email,
    leadId: queryParams.leadId,
    name: queryParams.name,
    phone: queryParams.phone,
  });

export const parseRegisterQueryParams = (queryParams: RegisterQueryParams) =>
  parseObjectToSearch({
    commercialStructureCode: queryParams.commercialStructureCode,
    cycleId: queryParams.cycleId,
    dateRangeFrom: queryParams.dateRangeFrom,
    dateRangeTo: queryParams.dateRangeTo,
    geraId: queryParams.geraId,
    name: queryParams.name,
    orderBy: queryParams.orderBy,
    resellerId: queryParams.resellerId,
  });

export const parseResellerOrdersQueryParams = (queryParams: ResellerOrderQueryParams) =>
  parseObjectToSearch({
    commercialStructureCode: queryParams.commercialStructureCode,
    cycleId: queryParams.cycleId,
    orderId: queryParams.orderId,
    resellerId: queryParams.resellerId,
  });
