import { ShimmerBox, ShimmerH, ShimmerInput } from '@atomic/mol.shimmer';
import { Hbox } from '@atomic/obj.box';
import * as React from 'react';

export const CartProductCellShimmer: React.FC = () => {
  return (
    <>
      <Hbox>
        <Hbox.Item>
          <ShimmerH heading='H3' />
        </Hbox.Item>
        <Hbox.Separator />
        <Hbox.Item noGrow>
          <ShimmerBox width='20px' />
        </Hbox.Item>
      </Hbox>
      <ShimmerH heading='H3' />
      <Hbox>
        <Hbox.Item hAlign='flex-start' vAlign='center'>
          <ShimmerInput />
        </Hbox.Item>
        <Hbox.Separator />
        <Hbox.Item vAlign='center' hAlign='flex-end'>
          <ShimmerH heading='H3' />
          <ShimmerH heading='H3' />
        </Hbox.Item>
      </Hbox>
    </>
  );
};
