import { OrderContext } from '@app/providers';
import { SelectOption } from '@atomic/atm.select';
import * as React from 'react';
import { CartCycleSelectSectionEudora } from './cart-cycle-select.section.eudora';

export const CartCycleSelectContainerEudora: React.FC = () => {
  const order = React.useContext(OrderContext);

  const cycleOptions = [
    { label: 'Atual', value: order.currentCycle },
    { label: 'Passado', value: order.previousCycle },
  ];

  const cycles: SelectOption[] = order.canOrderOnPreviousCycle ? cycleOptions : [cycleOptions[0]];
  const subCycles = order.selectedCycle
    ? order.selectedCycle === cycles?.[1]?.value
      ? order.previousSubCycles
      : order.currentSubCycles
    : [];

  const selectedSubcycleDescription = subCycles.find((item) => item.value === order.selectedSubcycle)?.label;
  const initialCycleValueOption = cycles.find((cycle) => cycle.value === order.selectedCycle);

  return (
    <CartCycleSelectSectionEudora
      cycles={cycles}
      selectedSubcycleDescription={selectedSubcycleDescription}
      initialCycleValue={initialCycleValueOption}
      onCycleChange={(value) => {
        order.updateCycle(value);
      }}
    />
  );
};
