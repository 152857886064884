import { parseDateToFormattedString } from '@app/components/obj.formatter/date-formatter';
import { Project } from '@app/config-variables';
import { hasWindow } from '@app/core/browser/platform';
import { noDiactrics, noSpecialCharacters } from '@app/data/http/modules/authentication/utils';
import { ThemeToken } from '@atomic/obj.constants';
import Container from 'typedi';

interface DataLayer {
  [key: string]: any;
  event?: string;
  eventAction?: string;
  eventCategory?: string;
  eventLabel?: string;
}

export const sanitizeMessage = (message?: string) => {
  let sanitizedMessage: string;
  if (message) {
    sanitizedMessage = noDiactrics(message);
    sanitizedMessage = noSpecialCharacters(sanitizedMessage);
    sanitizedMessage = sanitizedMessage.replaceAll(' ', '-').toLowerCase();
  }
  return sanitizedMessage;
};

export interface ProductDetail {
  name: string;
  id: string;
  price: number;
  quantity: number;
}

export enum CheckoutSection {
  Address = 1,
  Cart,
  Payment,
  ReviewMessage,
}

export enum AnalyticsDimension {
  UserId = 'dimension1',
}

export const pushToDataLayer = (dataLayer: DataLayer) => {
  if (hasWindow()) {
    if (!(window as any).dataLayer) {
      (window as any).dataLayer = [];
    }
    (window as any).dataLayer.push(dataLayer);
  }
};

export const elementPosition = (position: string) => `Elemento ${position?.padStart(2, '0')}`;

export interface GtmEventTag {
  dataGtmEventCategory?: string;
  dataGtmEventAction?: string;
  dataGtmEventLabel?: string;
}

const themeContext = Container.get(ThemeToken);

export const EVENT_CATEGORY_BASE = `fvc-${themeContext.Project === Project.bot ? 'boticario' : 'eudora'}`;
export const EVENT_ACTION_CLICK_BASE = 'clique';

export const EventCategory = {
  Attendence: `${EVENT_CATEGORY_BASE}:atendimento`,
  Checkout: `${EVENT_CATEGORY_BASE}:checkout`,
  EnhancedEcommerce: `${EVENT_CATEGORY_BASE}:enhanced-ecommerce`,
  Filters: `${EVENT_CATEGORY_BASE}:filtros`,
  Franchise: `${EVENT_CATEGORY_BASE}:franquias`,
  General: `${EVENT_CATEGORY_BASE}:geral`,
  History: `${EVENT_CATEGORY_BASE}:historico`,
  Indicators: `${EVENT_CATEGORY_BASE}:indicadores`,
  News: `${EVENT_CATEGORY_BASE}:noticias`,
  Questions: `${EVENT_CATEGORY_BASE}:perguntas`,
  Scripts: `${EVENT_CATEGORY_BASE}:roteiros`,
  Tabulation: `${EVENT_CATEGORY_BASE}:tabulacao`,
};

export const EventAction = {
  Callback: 'callback',
  Click: {
    Button: `${EVENT_ACTION_CLICK_BASE}:botao`,
    Buttons: `${EVENT_ACTION_CLICK_BASE}:botoes`,
    Checkbox: `${EVENT_ACTION_CLICK_BASE}:checkbox`,
    CheckboxStatus: (active: boolean) => `${EVENT_ACTION_CLICK_BASE}:${active ? 'ativado' : 'desativado'}`,
    ClickSection: (section) => `${EVENT_ACTION_CLICK_BASE}:${sanitizeMessage(section)}`,
    Details: `${EVENT_ACTION_CLICK_BASE}:ver-detalhes`,
    Edit: (label?: string) => `${EVENT_ACTION_CLICK_BASE}:editar${label && `:${label}`}`,
    Feedback: `${EVENT_ACTION_CLICK_BASE}:feedback`,
    Filter: `${EVENT_ACTION_CLICK_BASE}:filtro`,
    Link: `${EVENT_ACTION_CLICK_BASE}:link`,
    Radio: `${EVENT_ACTION_CLICK_BASE}:radio`,
    SaveQuestion: `${EVENT_ACTION_CLICK_BASE}:salvar-pergunta`,
    Schedule: `${EVENT_ACTION_CLICK_BASE}:agendamento`,
    Section: `${EVENT_ACTION_CLICK_BASE}:aba`,
    SideMenu: `${EVENT_ACTION_CLICK_BASE}:menu-lateral`,
    SuperiorMenu: `${EVENT_ACTION_CLICK_BASE}:menu-superior`,
    WhatsApp: `${EVENT_ACTION_CLICK_BASE}:whatsapp`,
  },
  Fill: 'preencheu:campo',
};

export const EVENT_LABEL_CONTACT_INFO_BASE = 'informacoes-de-contato';
export const EVENT_LABEL_IR_DATA = 'dados-da-ir';
export const EVENT_LABEL_DOCUMENTS = 'documentos';
export const EVENT_LABEL_GENERAL_BASE = 'geral';
export const EVENT_LABEL_PROMOTIONS_BASE = 'promocoes';

export const EventLabel = {
  Attendence: {
    Contact: {
      Address: 'endereco',
      Cancel: 'cancelar',
      Close: 'fechar',
      Email: 'email',
      GetInTouch: 'entrar-em-contato',
      Phone: (phone) => `telefone-${noSpecialCharacters(phone)}`,
    },
    ContactInfoSection: {
      AddOrEditPhone: `${EVENT_LABEL_CONTACT_INFO_BASE}:editar-adicionar-telefone`,
      Cancel: `${EVENT_LABEL_CONTACT_INFO_BASE}:cancelar`,
      ContactInfo: EVENT_LABEL_CONTACT_INFO_BASE,
      Edit: `${EVENT_LABEL_CONTACT_INFO_BASE}:editar`,
      EditAdditionalInfo: `${EVENT_LABEL_CONTACT_INFO_BASE}:editar-informacoes-adicionais`,
      Save: `${EVENT_LABEL_CONTACT_INFO_BASE}:salvar`,
      SaveInformation: `${EVENT_LABEL_CONTACT_INFO_BASE}:salvar-informacoes`,
    },
    Documents: {
      Confirm: `${EVENT_LABEL_DOCUMENTS}:confirmar`,
      Edit: `${EVENT_LABEL_DOCUMENTS}:editar`,
    },
    GeneralSection: {
      Benefits: `${EVENT_LABEL_GENERAL_BASE}:consulte-beneficios`,
      General: `${EVENT_LABEL_GENERAL_BASE}`,
      ListMoreFeedbacks: `${EVENT_LABEL_GENERAL_BASE}:listar-mais-feedbacks`,
      MoreFeedbacks: `${EVENT_LABEL_GENERAL_BASE}:mostrar-mais-feedbacks`,
      OrdersHisotry: `${EVENT_LABEL_GENERAL_BASE}:ver-historico-pedidos`,
      SeeAllSlips: `${EVENT_LABEL_GENERAL_BASE}:ver-todos-titulos`,
      SeeDetails: `${EVENT_LABEL_GENERAL_BASE}:ver-detalhes`,
      Share: `${EVENT_LABEL_GENERAL_BASE}:compartilhar`,
    },
    History: {
      After: 'posterior',
      Before: 'anterior',
    },
    IrData: {
      Cancel: `${EVENT_LABEL_IR_DATA}:cancelar`,
      EditAddress: `${EVENT_LABEL_IR_DATA}:editar-endereco-de-entrega`,
      EditContactInfo: `${EVENT_LABEL_IR_DATA}:editar-informacoes`,
      SaveInfo: `${EVENT_LABEL_IR_DATA}:salvar-informacoes`,
      SeeMore: `${EVENT_LABEL_IR_DATA}:ver-mais-conversas`,
    },
    Notification: 'notificacao',
    Order: {
      CopyLastOrder: 'copiar-ultimo-pedido',
    },
    Promotions: {
      Base: EVENT_LABEL_PROMOTIONS_BASE,
      LearnMore: `${EVENT_LABEL_PROMOTIONS_BASE}:saiba-mais`,
      SeeMorePromotions: `${EVENT_LABEL_PROMOTIONS_BASE}:ver-mais-promocoes`,
      Share: `${EVENT_LABEL_PROMOTIONS_BASE}:compartilhar`,
    },
    ResellerDataSection: {
      ResellerData: 'dados-da-re',
    },
    Schedule: 'agendamento',
  },
  Close: 'fechar',
  General: {
    Attendence: 'atendimento',
    CommercialSupport: 'apoio-comercial',
    Configurations: 'configurações',
    Filters: 'filtros',
    Franchise: 'franquia',
    History: 'historico',
    Indicators: 'indicadores',
    News: 'noticias',
    PublicMedia: 'midia-publica',
    Tabulation: 'tabulacao',
    Templates: 'templates',
  },
  Question: {
    Base: 'questões',
  },
  Script: {
    Base: 'script',
  },
  SeeDetails: 'ver-detalhes',
  Stepper: {
    Minus: 'menos',
    Plus: 'mais',
  },
  Tabulation: {
    Checkbox: (label: string, position?: number, active?: boolean) =>
      `elemento-${position}:${active ? 'ativado' : 'desativado'}:${sanitizeMessage(label)}`,
    ElementPosition: (label: string, position?: number) =>
      position ? `elemento-${position}:${sanitizeMessage(label)}` : sanitizeMessage(label),
  },
};

export const pushSearchEvent = (searchType: string, errorMessage?: string) => {
  pushToDataLayer({
    event: 'event',
    eventAction: 'callback:busca',
    eventCategory: EventCategory.Attendence,
    eventLabel: `${sanitizeMessage(searchType)}:${errorMessage ? sanitizeMessage(errorMessage) : 'sucesso'}`,
  });
};

export const pushFillEvent = (category: string, labels: string[], action?: string) => {
  pushToDataLayer({
    event: 'event',
    eventAction: action ?? EventAction.Fill,
    eventCategory: category,
    eventLabel: labels.map((label) => sanitizeMessage(label)).join(':'),
  });
};

export const pushEvent = (action: string, category: string, label: string) => {
  pushToDataLayer({
    event: 'event',
    eventAction: action,
    eventCategory: category,
    eventLabel: label,
  });
};

export const pushCycleDateEvent = (from: string, to: string, cycleId: string) => {
  const fromFormatted = parseDateToFormattedString(new Date(from));
  const toFormatted = parseDateToFormattedString(new Date(to));
  pushToDataLayer({
    event: 'event',
    eventAction: `selecao:${cycleId}`,
    eventCategory: EventCategory.Indicators,
    eventLabel: `${fromFormatted}:${toFormatted}`,
  });
};

export const pushCheckoutPageView = (section: CheckoutSection, products: ProductDetail[]) => {
  pushToDataLayer({
    ecommerce: {
      checkout: {
        actionField: { step: section },
        products,
      },
    },
    event: 'checkout',
    eventAction: 'checkout',
    eventCategory: EventCategory.EnhancedEcommerce,
    eventLabel: `etapa:${section}`,
    noInteraction: '1',
  });
};

export const pushPaymentTypeEvent = (paymentOption: string) => {
  pushToDataLayer({
    ecommerce: {
      checkout_option: {
        actionField: { option: 'payment:' + paymentOption, step: CheckoutSection.Payment },
      },
    },
    event: 'checkoutOption',
    eventAction: 'checkoutOption',
    eventCategory: EventCategory.EnhancedEcommerce,
  });
};

export const pushSearchResultsResellerClickEvent = ({ type }: { type: string }) => {
  pushToDataLayer({
    event: 'event',
    eventAction: 'clique:busca',
    eventCategory: EventCategory.Attendence,
    eventLabel: sanitizeMessage(type),
  });
};

export const pushFinishAttendance = ({ succeeded, message }: { succeeded: boolean; message?: string }) => {
  pushToDataLayer({
    event: 'event',
    eventAction: 'callback:finalizar-atendimento',
    eventCategory: EventCategory.Attendence,
    eventLabel: succeeded ? 'successo' : 'falha',
    message,
  });
};

export const pushFinishOrder = (transactionId: string, revenue: number, products: ProductDetail[]) => {
  pushToDataLayer({
    ecommerce: {
      purchase: {
        actionField: {
          id: transactionId,
          revenue,
        },
        products,
      },
    },
    event: 'purchase',
    eventAction: 'purchase',
    eventCategory: EventCategory.EnhancedEcommerce,
    noInteraction: '1',
  });
};
