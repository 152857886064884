import * as React from 'react';
import { Hbox } from '@atomic/obj.box';
import { VSeparator } from '@atomic/obj.grid';
import { Body } from '@atomic/atm.typography';
import { Button } from '@atomic/atm.button';

import { ActionBannerWrapperStyled } from '@app/components/mol.action-banner/action-banner.component.style';

interface ActionBannerProps {
  onDismiss: () => void;
  onConfirmation: () => void;
  message: string;
  confirmationText: string;
  dismissText: string;
  icon: () => React.ReactElement;
  loading?: boolean;
}

export const ActionBanner: React.FC<ActionBannerProps> = ({
  confirmationText,
  icon: Icon,
  dismissText,
  message,
  onConfirmation,
  onDismiss,
  loading,
}) => {
  return (
    <ActionBannerWrapperStyled>
      <Hbox>
        <Hbox.Item vAlign='center' noGrow>
          <Icon />
        </Hbox.Item>
        <Hbox.Separator medium />
        <Hbox.Item>
          <Body>{message}</Body>
        </Hbox.Item>
      </Hbox>
      <VSeparator small />
      <Hbox>
        <Hbox.Item hAlign='flex-end'>
          <Button loading={loading} kind='link' onClick={onDismiss}>
            {dismissText}
          </Button>
        </Hbox.Item>
        <Hbox.Separator />
        <Hbox.Item noGrow>
          <Button loading={loading} kind='link' onClick={onConfirmation}>
            {confirmationText}
          </Button>
        </Hbox.Item>
      </Hbox>
    </ActionBannerWrapperStyled>
  );
};
