import * as React from 'react';
import { ActivityIndicatorStyled } from './activity-indicator.component.style';

export type ActivityIndicatorType = 'spinner' | 'circle-notch' | 'sync' | 'cog';

export interface ActivityIndicatorProps {
  type: ActivityIndicatorType;
  light?: boolean;
}

export const ActivityIndicator: React.FC<ActivityIndicatorProps> = (props) => {
  const { type, light } = props;
  return <ActivityIndicatorStyled light={light} icon={type} spin />;
};
