import { Container, Service } from 'typedi';

import { getStorage, StorageType } from '@app/core/storage/storage.provider';
import { Auth } from '@app/data/graphql';
import { BusAuthenticationData } from '@app/data/http/dtos';

import { AuthenticationRepositoryProxy } from './authentication-repository.proxy';
import { AuthenticationDataSource } from './authentication.datasource';
import { BusAuthenticationDatasource } from './bus-authentication.datasource';
import { LocalAuthenticationDataSource } from './local-authentication.datasource';

export interface AuthenticationRepositoryObserver {
  onLogin?: (userProfile: Auth['Auth']['profile']) => void;
  onLogout?: () => void;
}

const WELCOME_MESSAGE_DISMISSED = '@user:welcome_message_dismissed';

@Service()
export class AuthenticationRepository {
  private observers: Set<AuthenticationRepositoryObserver> = new Set();
  private localDatasource = Container.get(LocalAuthenticationDataSource);
  private repositoryProxy = Container.get(AuthenticationRepositoryProxy);
  private storage = getStorage(StorageType.Local);
  private busAuthenticationDatasource = Container.get(BusAuthenticationDatasource);

  constructor() {
    this.repositoryProxy.config([
      this.getValidBusToken.bind(this),
      this.refreshBusToken.bind(this),
      this.getMiddlewareToken.bind(this),
      this.refreshMiddlewareToken.bind(this),
    ]);
  }

  getWelcomeMessageDismissed() {
    return this.storage.get(WELCOME_MESSAGE_DISMISSED);
  }

  setWelcomeMessageDismissed() {
    this.storage.put(WELCOME_MESSAGE_DISMISSED, true);
  }

  clearWelcomeMessageDismissed() {
    this.storage.remove(WELCOME_MESSAGE_DISMISSED);
  }

  addObserver(observer: AuthenticationRepositoryObserver) {
    this.observers.add(observer);
  }

  removeObserver(observer: AuthenticationRepositoryObserver) {
    this.observers.delete(observer);
  }

  async login(code: string, password: string): Promise<void> {
    const busData = await this.loginToBus(code, password);
    const data = await this.loginToMiddleware(busData.supervisorId);
    this.observers.forEach((observer) => {
      if (observer.onLogin) {
        observer.onLogin(data.profile);
      }
    });
  }

  async logout(): Promise<void> {
    this.localDatasource.clearAuthData();
    await AuthenticationDataSource.logout();
    this.clearWelcomeMessageDismissed();
    this.observers.forEach((observer) => {
      if (observer.onLogout) {
        observer.onLogout();
      }
    });
  }

  isLoggedIn(): boolean {
    return this.localDatasource.isLoggedIn();
  }

  forgotPassword(email: string): Promise<any> {
    return this.busAuthenticationDatasource.forgotPassword(email);
  }

  getUserProfile(): Auth['Auth']['profile'] {
    const token = this.localDatasource.getMiddlewareAuth();
    return token && token.profile;
  }

  getValidBusToken(): string {
    const { expirationDate, token } = this.localDatasource.getBusAuth();
    return expirationDate > Date.now() ? token : undefined;
  }

  async refreshBusToken(): Promise<string> {
    if (!this.busAuthenticationDatasource.refreshToken) {
      return null;
    }
    const oldData = this.localDatasource.getBusAuth();

    if (oldData === undefined) {
      await this.logout();
      return null;
    }

    try {
      const data = await this.busAuthenticationDatasource.refreshToken(oldData.refreshToken);
      this.localDatasource.updateBusTokens(data);
      return data.token;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errorCode === 104) {
        await this.logout();
        return null;
      }
      throw error;
    }
  }

  getMiddlewareToken(): string {
    const data = this.localDatasource.getMiddlewareAuth();
    return data && data.token;
  }

  async refreshMiddlewareToken(): Promise<string> {
    const busData = this.localDatasource.getBusAuth();

    const middlewareData = await this.loginToMiddleware(busData.supervisorId);

    return middlewareData && middlewareData.token;
  }

  private async loginToBus(code: string, password: string): Promise<BusAuthenticationData> {
    const data = await this.busAuthenticationDatasource.login(code, password);
    this.localDatasource.clearAuthData();
    this.localDatasource.saveBusAuth(data);

    return data;
  }

  private async loginToMiddleware(supervisorId: string): Promise<Auth['Auth']> {
    try {
      const { Auth: data } = await AuthenticationDataSource.login(supervisorId);
      this.localDatasource.saveMiddlewareAuth(data);
      return data;
    } catch (error) {
      await this.logout();
      throw error;
    }
  }
}
