import styled, { StyledFunction } from 'styled-components';

interface AccordionCollapsibleProps {
  expanded: boolean;
  contentHeight: number;
}

export const AccordionStyled = styled.div``;

const styledCollapsible: StyledFunction<AccordionCollapsibleProps> = styled.div;

export const AccordionCollapsibleStyled = styledCollapsible`
  max-height: ${(props: AccordionCollapsibleProps) => (props.expanded ? `${props.contentHeight}px` : '0px')};
  -moz-transition: max-height .3s ease-in-out;
  -ms-transition: max-height .3s  ease-in-out;
  -o-transition: max-height .3s  ease-in-out;
  -webkit-transition: max-height .3s  ease-in-out;
  transition: max-height .3s  ease-in-out;
  overflow: hidden;
`;

export const AccordionHeaderStyled = styled.header`
  cursor: pointer;
`;

export const AccordionContentStyled = styled.div``;
