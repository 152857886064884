import * as React from 'react';

import { ActivityIndicator } from '@atomic/atm.activity-indicator';

import {
  FloatingActionButtonContentStyled,
  FloatingActionButtonSpinnerStyled,
  FloatingActionButtonStyled,
} from './floating-action-button.component.style';

export interface FloatingActionButtonProps {
  disabled?: boolean;
  expanded?: boolean;
  loading?: boolean;
  onClick?: () => any;
  outlined?: boolean;
}

export class FloatinActionButton extends React.Component<FloatingActionButtonProps, undefined> {
  constructor(props: FloatingActionButtonProps) {
    super(props);
  }

  render() {
    const { loading, onClick, disabled, ...props } = this.props;
    return (
      <FloatingActionButtonStyled onClick={this.props.onClick} disabled={loading || disabled} {...props}>
        <FloatingActionButtonContentStyled loading={loading}>{this.props.children}</FloatingActionButtonContentStyled>
        <FloatingActionButtonSpinnerStyled loading={loading}>
          <ActivityIndicator type='spinner' />
        </FloatingActionButtonSpinnerStyled>
      </FloatingActionButtonStyled>
    );
  }
}
