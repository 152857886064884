import { useBooleanState } from '@atomic/obj.boolean-state/boolean-state.hook';
import * as React from 'react';
import {
  NotificationsDialogBadgeStyled,
  NotificationsDialogDialogStyled,
  NotificationsDialogIconStyled,
  NotificationsDialogStyled,
} from './notification-dialog.component.style';
import { useEventListener } from '@atomic/obj.hooks/use-event-listener.hook';
import { GtmEventTag } from '@app/utils/data-layer';

interface NotificationDialogProps extends GtmEventTag {
  icon: React.ReactNode;
  notifications?: number;
  refetch?: () => void;
  onClose?: () => void;
}

export const NotificationDialog: React.FC<NotificationDialogProps> = (props) => {
  const menuRef = React.useRef(null);
  const { value: dialogShown, toggle: toggleDialog } = useBooleanState(false);
  const { notifications, refetch, onClose } = props;
  const Icon = props.icon;

  const handleToggleDialog = () => {
    dialogShown ? onClose?.() : refetch?.();
    toggleDialog();
  };

  const handleMouseDown = (event: MouseEvent) => {
    if (dialogShown && menuRef && !menuRef.current.contains(event.target)) {
      toggleDialog();
      onClose?.();
    }
  };
  useEventListener('mousedown', handleMouseDown);

  return (
    <NotificationsDialogStyled
      ref={menuRef}
      data-gtm-event-category={props.dataGtmEventCategory}
      data-gtm-event-action={props.dataGtmEventAction}
      data-gtm-event-label={props.dataGtmEventLabel}
    >
      <NotificationsDialogIconStyled onClick={handleToggleDialog}>
        {!!notifications && <NotificationsDialogBadgeStyled>{notifications}</NotificationsDialogBadgeStyled>}
        {Icon}
      </NotificationsDialogIconStyled>
      <NotificationsDialogDialogStyled shown={dialogShown}>{props.children}</NotificationsDialogDialogStyled>
    </NotificationsDialogStyled>
  );
};
