import styled from 'styled-components';

export const arrowSize = 6;
export const FixedTooltipMessageArrowStyled = styled.div`
  position: relative;
  height: 10px;

  &::before {
    display: block;
    position: absolute;
    content: '';
    max-width: 100%;
    bottom: 0;
    width: 0;
    height: 0;
    right: ${(props) => props.theme.Spacing.Large};
    border-left: ${arrowSize}px solid transparent;
    border-right: ${arrowSize}px solid transparent;
    border-bottom: ${arrowSize}px solid ${(props) => props.theme.Color.GrayLight};
  }
`;

export const FixedTooltipMessageStyled = styled.div`
  width: 100%;
  padding: ${(props) => props.theme.Spacing.Small};
  background-color: ${(props) => props.theme.Color.GrayLight};
  border-radius: ${(props) => props.theme.Border.Radius};
  color: ${(props) => props.theme.Color.GrayXDark};
  font-family: ${(props) => props.theme.FontFamily.Primary};
  font-weight: ${(props) => props.theme.FontWeight.Bold};
  font-size: ${(props) => props.theme.FontSize.XSmall};
`;
