import { Service } from 'typedi';
import { GraphqlInterceptor } from '@app/core/graphql';
import { ErrorResponse } from '@apollo/client/link/error';

@Service()
export class UuidErrorInterceptor implements GraphqlInterceptor {
  error(error: ErrorResponse) {
    if ((error.networkError as any)?.result?.errors) {
      (error.networkError as any).result.errors[0].uuid =
        error?.response?.extensions?.uuid ?? (error.networkError as any).result.extensions.uuid;
    }
    if (error.graphQLErrors?.length && !(error.graphQLErrors[0] as any).uuid) {
      (error.graphQLErrors as any)[0].uuid =
        error?.response?.extensions?.uuid ?? (error.networkError as any)?.result?.extensions?.uuid;
    }
  }
}
