export const AppPath = {
  Activation: {
    Base: '/activation',
    Checkout: {
      Base: '/activation/checkout',
      Finish: '/activation/checkout/finish',
      MultipleForms: '/activation/checkout/multiple-forms',
    },
    ForgotPassword: '/activation/forgot-password',
    History: '/activation/history',
    Home: {
      Base: '/activation/assistance',
      Dashboard: {
        Base: '/activation/assistance/dashboard',
        Checklist: '/activation/assistance/checklist',
        ContactInfo: '/activation/assistance/contact-info',
        Promotions: {
          AllExclusive: '/activation/assistance/promotions/all-exclusive',
          AllGeneral: '/activation/assistance/promotions/all-general',
          Base: '/activation/assistance/promotions',
          Detail: '/activation/assistance/promotions/detail',
        },
        ResellerData: '/activation/assistance/reseller-data',
      },
    },
    Indicators: '/activation/indicators',
    Login: '/activation/login',
    News: {
      Base: '/activation/news',
      Detail: '/activation/news/detail',
    },
    Reseller: {
      Base: '/activation/reseller',
      BoletoDetail: '/activation/reseller/boletos/detail',
      BoletosHistory: '/activation/reseller/boletos',
      EudoraHistory: '/activation/reseller/history',
      Order: '/activation/reseller/order',
      Orders: {
        History: '/activation/reseller/orders',
      },
    },
  },
  Admin: {
    Base: '/admin',
    Configuration: {
      Base: '/admin/configuration',
      WorkTime: '/admin/configuration/work-time',
    },
    CreateCP: '/admin/cp/create',
    Cs: {
      Base: '/admin/cs',
      Create: '/admin/cs/create',
      Detail: '/admin/cs/detail',
      Promotion: {
        Create: '/admin/promotion/create',
        Detail: '/admin/promotion/detail',
        Edit: '/admin/promotion/edit',
      },
    },
    Filters: '/admin/filters',
    Franchise: '/admin/franchise',
    Login: '/admin/login',
    News: {
      Base: '/admin/news',
      Create: '/admin/news/create',
      Detail: '/admin/news/detail',
    },
    PublicMedia: '/admin/public-media',
    Questions: '/admin/questions',
    Script: {
      Base: '/admin/script',
      Create: '/admin/script/create',
      Detail: '/admin/script/detail',
      Edit: '/admin/script/edit',
    },
    Tabulation: '/admin/tabulation',
    Templates: '/admin/templates',
  },
  Base: '/',
  Cart: {
    Address: '/cart/address',
    Base: '/cart',
    BusinessModel: '/cart/business-model',
    Cycle: '/cart/cycle',
    EditOrder: '/cart/edit-order',
    Management: '/cart/management',
    StarterKits: '/cart/kits',
  },
  IrDigital: {
    Base: '/ir-digital',
    ForgotPassword: '/ir-digital/forgot-password',
    Home: '/ir-digital/assistance',
    Login: '/ir-digital/login',
  },
  Login: '/login',
  Starts: {
    Attendance: '/starts/attendance',
    Base: '/starts',
    Checkout: {
      Base: '/starts/checkout',
      MultipleForms: '/starts/checkout/multiple-forms',
    },
    History: '/starts/service-history',
    Login: '/starts/login',
    News: {
      Base: '/starts/news',
      Detail: '/starts/news/detail',
    },
    WhatsappAttendance: '/starts/whatsapp',
  },
  error500: '/500',
};
