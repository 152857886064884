import { ToggleableFeature } from '@app/config-variables';
import { CONFIG_CAT_SDK_KEY } from '@app/modules/modules.token';
import { IConfigCatClient } from 'configcat-common/lib/ConfigCatClient';
import * as configCat from 'configcat-js';
import { backOff } from 'exponential-backoff';
import React from 'react';
import Container from 'typedi';
import { UserContext } from './user.provider';

const configCatSDKKey = Container.get(CONFIG_CAT_SDK_KEY);
interface NumericFeatureToggleOptions {
  [ToggleableFeature.QueueLimit]: number;
}

type BooleanFeatureToggleOptions = { [key in Exclude<ToggleableFeature, keyof NumericFeatureToggleOptions>]: boolean };

type FeatureToggleOptions = BooleanFeatureToggleOptions & NumericFeatureToggleOptions;

const defaultValue: FeatureToggleOptions = Object.fromEntries([
  ...Object.values(ToggleableFeature).map((feature) => [feature, false]),
  [ToggleableFeature.QueueLimit, 12],
]);

const pollIntervalSeconds = 300;

interface FeatureToggleState {
  featureToggleOptions: FeatureToggleOptions;
}

export const FeatureToggleContext = React.createContext<FeatureToggleState>({
  featureToggleOptions: defaultValue,
});

export const FeatureToggleProvider: React.FC = ({ children }) => {
  const { userProfile } = React.useContext(UserContext);
  const { id } = userProfile || {};
  const [featureToggleOptions, setFeatureToggleOptions] = React.useState<FeatureToggleOptions>(defaultValue);

  const updateOptions = React.useCallback(async () => {
    let newConfig: FeatureToggleOptions = { ...defaultValue };

    for (const feature of Object.values(ToggleableFeature)) {
      const value = await backOff(() =>
        configCatClientRef.current?.getValueAsync(feature, defaultValue[feature], { identifier: id }),
      );
      newConfig = { ...newConfig, [feature]: value };
    }

    setFeatureToggleOptions(newConfig);
  }, [id]);

  const configCatClientRef = React.useRef<IConfigCatClient>();

  React.useEffect(() => {
    configCatClientRef.current = configCat.getClient(configCatSDKKey, configCat.PollingMode.AutoPoll, {
      pollIntervalSeconds: pollIntervalSeconds,
    });
    updateOptions();
  }, [updateOptions]);

  return <FeatureToggleContext.Provider value={{ featureToggleOptions }}>{children}</FeatureToggleContext.Provider>;
};
