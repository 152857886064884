export const parseNumberValue = (value: number, hasPricePrefix = false, fixedDecimalPlaces?: number) => {
  if (value === null || value === undefined) {
    return null;
  }

  let valueString = value.toString();
  if (fixedDecimalPlaces !== null && fixedDecimalPlaces !== undefined) {
    valueString = value.toFixed(fixedDecimalPlaces);
  }

  const prefix = hasPricePrefix ? 'R$' : '';
  const [integer, decimal] = valueString.replace(/\B(?=(\d{3})+(?!\d))/g, ',').split('.');
  const parsedValue = [integer.replace(',', '.'), decimal].filter((text) => text).join(',');

  return `${prefix}${parsedValue}`;
};

export const parsePhoneNumber = (phone: string) => {
  const phoneWithoutDDI = phone.startsWith('55') && phone.length > 11 ? phone.slice(2) : phone;
  if (phoneWithoutDDI.length < 8 || phoneWithoutDDI.startsWith('(')) {
    return `+55 ${phoneWithoutDDI}`;
  }

  return (
    '+55 ' +
    (phoneWithoutDDI.length < 10
      ? splitPhoneNumber(phoneWithoutDDI)
      : `(${phoneWithoutDDI.slice(0, 2)}) ${splitPhoneNumber(phoneWithoutDDI.slice(2))}`)
  );
};

const splitPhoneNumber = (phone: string) => `${phone.slice(0, phone.length - 4)}-${phone.slice(-4)}`;
