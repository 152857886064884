import { MessageNotifications } from '@app/components/org.whatsapp/mol.message-notifications';
import { useStartMessageNotifications } from '@app/components/org.whatsapp/mol.message-notifications/message-notifications.hook';
import { EventCategory, EventAction, EventLabel } from '@app/utils/data-layer';
import * as React from 'react';

export const StartsMessageNotifications: React.FC = () => {
  const {
    error,
    handleStartConversation,
    interactions,
    loading,
    refetch,
    startConversationError,
    hasNextPage,
    interactionsLength,
    onClose,
    onLoadMore,
  } = useStartMessageNotifications();

  return (
    <MessageNotifications
      hasNextPage={hasNextPage}
      interactionsLength={interactionsLength}
      onClose={onClose}
      onLoadMore={onLoadMore}
      error={error}
      startConversation={handleStartConversation}
      interactions={interactions}
      loading={loading}
      refetch={refetch}
      startConversationError={startConversationError}
      dataGtmEventCategory={EventCategory.Attendence}
      dataGtmEventAction={EventAction.Click.WhatsApp}
      dataGtmEventLabel={EventLabel.Attendence.Notification}
    />
  );
};
