import React from 'react';
import { BodyAlert, Body } from '@atomic/atm.typography';
import { VSeparator } from '@atomic/obj.grid';
import { LocalizedContext } from '@app/components/obj.localization';
import { Hbox } from '@atomic/obj.box';
import { ComboType } from '@app/data/graphql';
import { ComboProduct } from '@app/components/mol.combo-eudora-modal/combo-eudora-modal.component';
import { ShimmerText, ShimmerMilestone, ShimmerButton } from '@atomic/mol.shimmer';
import { List } from '@atomic/mol.list';
import {
  ComboProductListItem,
  ComboProductListItemShimmer,
} from '@app/components/mol.combo-eudora-modal/mol.combo-product-list-item/combo-product-list-item.component';

interface ComboProductSeletionProps {
  combo: ComboType;
  category: number;
  quantitySelected: number;
  quantityPerKit: number;
  comboProducts: { [key: string]: ComboProduct };
  onValueChange: (product: ComboProduct) => void;
}

export const ComboProductSelection: React.FC<ComboProductSeletionProps> = ({
  combo,
  category,
  quantitySelected,
  quantityPerKit,
  comboProducts,
  onValueChange,
}) => {
  const localized = React.useContext(LocalizedContext).strings.components.comboEudora;
  return (
    <>
      <VSeparator />
      {combo?.productSelections?.[category] &&
        (quantitySelected > quantityPerKit ? (
          <BodyAlert>{localized.selectedProducts(quantitySelected, quantityPerKit)}</BodyAlert>
        ) : (
          <Body>{localized.selectedProducts(quantitySelected, quantityPerKit)}</Body>
        ))}
      {combo?.productSelections?.[category]?.products.map((item) => (
        <ComboProductListItem
          key={item.code}
          selectionCode={combo?.productSelections?.[category]?.code}
          productCode={item.code}
          imageUrl={item.imageUrl}
          title={item.name}
          quantity={comboProducts[item.code] ? comboProducts[item.code].quantity : item.quantity}
          disabledPlus={quantitySelected >= quantityPerKit || !item.available}
          onValueChange={onValueChange}
        />
      ))}
    </>
  );
};

export const ComboProductSelectionShimmer: React.FC = () => {
  return (
    <>
      <ShimmerText />
      <VSeparator />
      <Hbox>
        <Hbox.Item>
          <ShimmerText />
          <VSeparator />
          <ShimmerText />
        </Hbox.Item>
        <Hbox.Item>
          <ShimmerButton />
        </Hbox.Item>
      </Hbox>
      <VSeparator />
      <ShimmerMilestone />
      <VSeparator />
      <ShimmerText width={100} />
      <VSeparator />
      <List>
        <List.Row>
          <ComboProductListItemShimmer />
        </List.Row>
        <List.Row>
          <ComboProductListItemShimmer />
        </List.Row>
        <List.Row>
          <ComboProductListItemShimmer />
        </List.Row>
      </List>
    </>
  );
};
