import { LocalizedContext } from '@app/components/obj.localization';
import { OrderContext } from '@app/providers';
import { CellData } from '@atomic/mol.radio-icon-list/radio-icon-list.component';
import * as React from 'react';
import { CartCycleSelectSection } from './cart-cycle-select.section';

export const CartCycleSelectContainer: React.FC = () => {
  const localized = React.useContext(LocalizedContext).strings.components.cartPages;
  const order = React.useContext(OrderContext);

  const cycleList: CellData[] = [
    { description: order.currentCycle, radioId: '0', title: localized.currentCycleFn(order.currentCycle) },
    { description: order.previousCycle, radioId: '1', title: localized.previousCycleFn(order.previousCycle) },
  ];

  const handleCycleChange = (newCycleId: string) => {
    const newCycle: string = cycleList.find((x) => x.radioId === newCycleId).description;
    order.updateCycle(newCycle);
  };

  return (
    <CartCycleSelectSection
      cycleList={cycleList}
      handleCycleChange={handleCycleChange}
      initialValue={cycleList?.findIndex((element) => element.description === order.selectedCycle)?.toString()}
    />
  );
};
