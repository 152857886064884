import * as React from 'react';

import {
  TableColumnStyled,
  TableHeaderStyled,
  TableHeadStyled,
  TableRowStyled,
  TableStyled,
} from './table.component.style';

export interface TextAlignProps {
  textAlign?: 'right' | 'center' | 'left';
  children: any;
  noColor?: boolean;
}

export const TR = ({ children, ...other }) => <TableRowStyled {...other}>{children}</TableRowStyled>;
export const TD = (props: TextAlignProps) => <TableColumnStyled {...props}>{props.children}</TableColumnStyled>;
export const THead = (props: any) => <TableHeadStyled>{props.children}</TableHeadStyled>;
export const TH = (props: TextAlignProps) => <TableHeaderStyled {...props}>{props.children}</TableHeaderStyled>;

export class Table extends React.Component<{}> {
  render() {
    const { header, body } = this.getTypedChildren();

    return (
      <TableStyled>
        {header}
        <tbody>{body}</tbody>
      </TableStyled>
    );
  }

  private getTypedChildren() {
    let header;
    const body = [];

    React.Children.forEach(this.props.children, (child: any) => {
      if (child && child.type) {
        switch (child.type) {
          case THead:
            header = child;
            break;
          default:
            body.push(child);
        }
      } else {
        body.push(child);
      }
    });

    return { body, header };
  }
}
