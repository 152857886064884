import * as React from 'react';
import { DatePickerStyled } from './date-picker.component.style';
import { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import { FormFieldContext, FormFieldContextState } from '@atomic/obj.form';

// Based on https://reactdatepicker.com/
export interface DatePickerProps {
  startDate?: Date | null;
  endDate?: Date | null;
  placeholder?: string;
  onValueChange?: (date: any) => void;
  onFocusChange?: (focused: boolean) => void;
  showTimeSelect?: boolean;
  showTimeSelectOnly?: boolean;
  disabled?: boolean;
  endSelect?: boolean;
  timeInterval?: number;
  onlyMonths?: boolean;
  CustomInput?: React.FC;
  maxDate?: Date;
  minDate?: Date;
}

export interface DatePickerState {
  startDate: Date | null;
  endDate: Date | null;
}

export class DatePicker extends React.Component<DatePickerProps, DatePickerState> {
  static defaultProps = {
    endSelect: false,
  };

  private formFieldConsumer: FormFieldContextState;

  constructor(props: DatePickerProps) {
    super(props);

    registerLocale('pt-br', ptBR);
    this.state = {
      endDate: props.endDate,
      startDate: props.startDate,
    };
  }

  componentDidMount() {
    if (this.isStartDateControlled() && this.props.startDate !== undefined) {
      throw new Error('Use either the initialValue prop, or the value prop, but not both');
    }

    if (this.formFieldConsumer) {
      if (this.isStartDateControlled() || this.props.startDate !== undefined) {
        if (this.formFieldConsumer.value) {
          throw new Error('Please, use either value props in <TextField> or <Form.Field> component.');
        }

        this.formFieldConsumer.onValueChange(this.state.startDate, null);
      }
    }
  }

  render() {
    const {
      placeholder,
      showTimeSelect,
      showTimeSelectOnly,
      disabled,
      endSelect,
      CustomInput,
      timeInterval,
    } = this.props;
    return (
      <FormFieldContext.Consumer>
        {(formFieldConsumer: FormFieldContextState) => {
          this.formFieldConsumer = formFieldConsumer;

          const startDate = this.getCurrentValue();
          const endDate = this.isEndDateControlled() ? this.props.endDate : this.state.endDate;

          return (
            <DatePickerStyled
              locale='pt-br'
              dateFormat={showTimeSelectOnly ? 'h:mm aa' : this.props.onlyMonths ? 'MM/yyyy' : 'dd/MM/yyyy'}
              onChange={this.handleDateChange}
              startDate={startDate}
              endDate={endDate}
              minDate={this.props.minDate}
              maxDate={this.props.maxDate}
              selected={endSelect ? endDate : startDate}
              onFocus={this.handleFocusChange(true)}
              onBlur={this.handleFocusChange(false)}
              placeholderText={placeholder}
              showTimeSelect={showTimeSelect || showTimeSelectOnly}
              showTimeSelectOnly={showTimeSelectOnly}
              timeCaption='Time'
              disabled={disabled}
              timeIntervals={timeInterval || 30}
              selectsStart={!endSelect}
              selectsEnd={endSelect}
              showMonthYearPicker={this.props.onlyMonths}
              customInput={CustomInput ? <CustomInput /> : null}
              className='data-cy-datepicker'
            />
          );
        }}
      </FormFieldContext.Consumer>
    );
  }

  private isStartDateControlled() {
    return this.props.startDate != null && this.formFieldConsumer?.value != null;
  }

  private isEndDateControlled() {
    return this.props.endDate != null && this.props.endDate != null;
  }

  private handleDateChange = (date: Date) => {
    if (this.props.endSelect) {
      if (!this.isEndDateControlled()) {
        this.setState({ endDate: date });
      }
    } else {
      if (!this.isStartDateControlled()) {
        this.setState({ startDate: date });
      }
    }

    this.props.onValueChange?.(date);

    this.formFieldConsumer?.onValueChange?.(date, true);
  };

  private handleFocusChange = (focused: boolean) => () => {
    if (this.props.onFocusChange) {
      this.props.onFocusChange(focused);
    }
  };

  private getCurrentValue = () => {
    return this.props.startDate || this.formFieldConsumer?.value || this.state.startDate;
  };
}
