import styled from 'styled-components';

export const DividerGray = styled.div`
  width: 100%;
  height: ${(props) => props.theme.Border.Width};
  background-color: ${(props) => props.theme.Color.GrayLight};
`;

export const DividerGrayVertical = styled.div`
  width: ${(props) => props.theme.Border.Width};
  height: 100%;
  background-color: ${(props) => props.theme.Color.GrayLight};
`;
