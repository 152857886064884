import { ThemeToken } from '@atomic/obj.constants';
import styled, { StyledFunction } from 'styled-components';
import Container from 'typedi';
import { FaIcon } from '../atm.fa-icon';
import { FieldBorderCss, FieldCss } from '../obj.form/field.component.style';
import { HighlightStyle } from '../obj.mixin';

const theme = Container.get(ThemeToken);

const INPUT_PADDING = '38px'; // Spacing.Medium + ICON_HEIGHT = 14px + Spacing.Small

export const FileFieldInputStyled = styled.input.attrs({
  type: 'file',
})`
  position: absolute;
  z-index: ${(props) => props.theme.Zindex.FileFieldInputStyled};
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  border: 0;
  overflow: hidden;
`;

interface FileFieldStyledProps {
  empty: boolean;
}

const styledLabel: StyledFunction<FileFieldStyledProps & React.HTMLProps<HTMLLabelElement>> = styled.label;

export const FileFieldStyled = styledLabel`
  ${FieldCss}
  ${FieldBorderCss}
  display: block;

  position: relative;
  padding-left: ${INPUT_PADDING};
  color: ${(props: FileFieldStyledProps) => (props.empty ? theme.Color.Gray : theme.Color.Black)};

  &:active {
    border-color: ${(props) => props.theme.Color.Primary};
    box-shadow: inset 0 0 0 ${(props) => props.theme.Border.Width} ${(props) => props.theme.Color.Primary};
  }

`;

export const FileFieldIconStyled = styled(FaIcon.FileUpload)`
  position: absolute;
  z-index: ${(props) => props.theme.Zindex.FileFieldIconStyled};
  top: ${(props) => props.theme.Spacing.Medium};
  left: ${(props) => props.theme.Spacing.Medium};
`;

export const FileFieldClearStyled = styled.div`
  position: absolute;
  z-index: ${(props) => props.theme.Zindex.FileFieldClearStyled};
  top: 0;
  right: 0;
  padding: ${(props) => props.theme.Spacing.Medium};
  ${HighlightStyle}

  &:hover > span {
    color: ${(props) => props.theme.Color.GrayLight};
  }
`;
