import * as React from 'react';

export const IcEudoraAppLogo = (props) => {
  return (
    <svg width='50px' height='50px' viewBox='0 0 98 98' version='1.1' {...props}>
      <g id='Super-WEB' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Group-3-Copy'>
          <g id='placeholder-/-push' fill='#542785'>
            <g id='shadow'>
              <circle id='Oval' cx='49' cy='49' r='48.627451' />
            </g>
          </g>
          <path
            d='M56.5490196,48.0567061 C56.5490196,48.4939437 56.0715837,48.5968231 55.0161991,48.6482628 C44.4372247,49.2398194 42.8038914,51.2974077 40.7433786,54.5381093 C40.4920965,54.9753468 40.2156863,54.8724674 40.2156863,54.3837902 L40.2156863,41.7810618 C40.2156863,41.2409449 40.4669683,41.1895052 40.7433786,41.6267427 C42.8038914,44.8674443 44.4372247,46.9250326 55.0161991,47.5165892 C56.0715837,47.5680289 56.5490196,47.6709083 56.5490196,48.0567061 Z M68.3664858,72.6617284 C66.4638151,73.842963 61.2565059,76 52.7695933,76 C38.5496335,76 31.5397941,68.6044444 31.5397941,56.3812346 C31.5397941,50.1155556 27.959769,49.1397531 22.4770733,48.4977778 C21.0500703,48.3437037 21,48.1639506 21,47.9071605 C21,47.7017284 21.0500703,47.5219753 22.4770733,47.3679012 C27.959769,46.7259259 31.865251,45.7501235 31.865251,39.4844444 L31.865251,30.0345679 C31.865251,27.2355556 29.5369829,26.5165432 27.8095582,25.9259259 C26.7580823,25.5664198 25.9068875,25.4380247 25.9068875,24.7446914 C25.9068875,24.2824691 26.1572389,24 26.9583634,24 L66.5138854,24 C68.7169777,24 68.9923643,25.2839506 69.0424346,26.6449383 L69.5181022,38.4316049 C69.5681725,39.2790123 69.2427157,39.6641975 68.7169777,39.6641975 C68.0910992,39.6641975 67.865783,39.2790123 67.7656424,38.6883951 C66.3386394,31.2928395 64.135547,27.1841975 54.0714206,27.1841975 L40.953007,27.1841975 C39.5260039,27.1841975 39.000266,28.134321 39.000266,29.4953086 L39.000266,40.537284 C39.000266,44.5175309 35.620522,47.2395062 31.3645482,47.9841975 C36.5468222,49.2167901 39.2255823,51.4765432 39.2255823,57.5367901 C39.2255823,67.1407407 42.7305019,72.8158025 53.1200852,72.8158025 C60.8058733,72.8158025 66.5639556,69.6059259 68.1912398,59.5654321 C68.2913803,58.9748148 68.4666263,58.5896296 69.1926454,58.5896296 C69.7934888,58.5896296 70.0438402,59.0518519 69.9937699,59.8735802 L69.5681725,70.4019753 C69.5181022,71.3777778 69.2176805,72.1224691 68.3664858,72.6617284 Z'
            id='Combined-Shape'
            fill='#FFFFFF'
          />
        </g>
      </g>
    </svg>
  );
};
