import * as React from 'react';

export const IcNote = (props) => {
  const background = props.background || '#E7CBAD';
  const color = props.color || '#CD975A';
  return (
    <svg viewBox='0 0 16 16' {...props}>
      <g fillRule='nonzero' fill='none'>
        <path
          d='M15.823 4.715c-.293-.823-.785-1.408-1.495-1.776-.325-.17-.584-.308-1.168-.621l-.234-.125c-.389-.209-.661-.353-.933-.496l-.585-.31-.585-.312A82.438 82.438 0 0 0 9.64.455 3.524 3.524 0 0 0 8.105.052C7.439.035 6.875.155 6.388.42c-.1.054-.206.11-.334.176L5.723.77c-.278.145-.465.245-.651.35-1.065.553-2.203 1.16-3.39 1.803C.622 3.509.058 4.412.058 5.517.052 5.859.05 6.218.05 6.626c0 .31.001.56.004 1.11.003.556.004.796.004 1.099V10.486c0 .308.052.61.145.867.295.78.791 1.361 1.478 1.727.633.34 1.149.612 2.316 1.225 1.168.613 1.683.885 2.317 1.226.456.251.93.385 1.477.419h.26c.576 0 1.106-.13 1.588-.385l1.149-.619a806.558 806.558 0 0 1 2.333-1.248c.409-.218.796-.423 1.17-.619.503-.257.9-.612 1.185-1.071.33-.522.474-.955.474-1.42v-5.09a2.5 2.5 0 0 0-.127-.783z'
          stroke='#FFF'
          strokeWidth={0.1}
          fill={background}
          opacity={0.5}
        />
        <g fill={color}>
          <path d='M11.992 5.758v4.355a2.122 2.122 0 0 1-2.114 2.137H5.864a2.122 2.122 0 0 1-2.114-2.137V5.758c0-1.146.886-2.079 2.007-2.134v-.479c0-.224.169-.395.394-.395.224 0 .393.17.393.395v.476h2.654v-.476c0-.224.169-.395.393-.395.225 0 .394.17.394.395v.479a2.123 2.123 0 0 1 2.007 2.134zM5.864 4.121c-.894 0-1.614.73-1.614 1.637v4.355c0 .908.72 1.637 1.614 1.637h4.014c.894 0 1.614-.73 1.614-1.637V5.758c0-.908-.72-1.637-1.614-1.637H5.864zM4.537 5.758v.476h6.668v-.476c0-.712-.535-1.288-1.22-1.342v.471a.386.386 0 0 1-.394.395.386.386 0 0 1-.393-.395v-.476H6.544v.476a.386.386 0 0 1-.393.395.386.386 0 0 1-.394-.395v-.471a1.334 1.334 0 0 0-1.22 1.342zm5.341-1.847c1.013 0 1.827.824 1.827 1.847v.976H4.037v-.976c0-1.023.813-1.847 1.827-1.847h4.014zM4.537 7.024v3.089c0 .748.591 1.347 1.327 1.347h4.014c.736 0 1.327-.599 1.327-1.347V7.024H4.537zm7.168 3.089a1.832 1.832 0 0 1-1.827 1.847H5.864a1.832 1.832 0 0 1-1.827-1.847V6.524h7.668v3.589z' />
          <path d='M6.724 8.908a.385.385 0 0 1-.393-.394c0-.224.169-.393.393-.393h2.294c.224 0 .393.17.393.393 0 .224-.169.394-.393.394H6.724zm0 1.451a.385.385 0 0 1-.393-.393c0-.224.169-.393.393-.393h2.294c.224 0 .393.169.393.393s-.169.393-.393.393H6.724z' />
        </g>
      </g>
    </svg>
  );
};
