import { Product } from '@app/models/product.model';
import { Card } from '@atomic/atm.card';
import { BodySecondary, H4 } from '@atomic/atm.typography';
import * as React from 'react';

interface AutocompleteProductCellProps {
  product: Product;
  onClick: (productCode: string) => void;
}

export const AutocompleteProductCell: React.FC<AutocompleteProductCellProps> = ({ product, onClick }) => {
  const handleProductClick = () => onClick(product.id);

  return (
    <Card bordered onClick={handleProductClick} clickable>
      <H4>{product.id}</H4>
      <BodySecondary>{product.name}</BodySecondary>
    </Card>
  );
};
