import * as React from 'react';
import { BannerMessageWrapperStyled } from './banner-message.component.style';
import { BannerMessage } from './banner-message.component';
import { ClientNewsData, NewsData } from '@app/models/news.model';
import { useHistory } from 'react-router';
import Container from 'typedi';
import { NewsDatasource } from '@app/data/local/news.datasource';
import { useAppType } from '@app/modules/account/use-appType';
import { AppPath } from '@app/modules/app/routes';

interface NewsBannerMessageProps {
  news: NewsData[];
  updateQueueMessages?: (data: NewsData[]) => void;
}

export const NewsBannerMessage: React.FC<NewsBannerMessageProps> = (props) => {
  const newsStorage = Container.get(NewsDatasource);
  const history = useHistory<ClientNewsData>();
  const { isStarts } = useAppType();

  const handleCloseBannerMessageClick = (newsId: string) => {
    return () => {
      const visualizedNews = newsStorage.getVisualizedNews();
      const queue = props.news.filter((news) => news.id !== newsId);
      props.updateQueueMessages?.(queue);
      newsStorage.setVisualizedNews(visualizedNews ? [...visualizedNews, newsId] : [newsId]);
    };
  };

  const handleBannerClick = (news: NewsData) => {
    return () => {
      history.push(isStarts ? AppPath.Starts.News.Detail : AppPath.Activation.News.Detail, {
        criticalityLevel: news.criticalityLevel,
        message: news.message,
        references: news.references,
        title: news.title,
      });
    };
  };

  return (
    <BannerMessageWrapperStyled>
      {props.news
        ?.filter((news) => news.isEnable)
        ?.map((news) => (
          <BannerMessage
            key={news.id}
            message={news.message}
            title={news.title}
            criticalityLevel={news.criticalityLevel}
            onBannerClick={handleBannerClick(news)}
            onCloseClick={handleCloseBannerMessageClick(news.id)}
          />
        ))}
    </BannerMessageWrapperStyled>
  );
};
