import { GtmEventTag } from '@app/utils/data-layer';
import * as React from 'react';
import { CellStyled } from './cell.component.style';

export interface CellProps extends GtmEventTag {
  onClick?: () => void;
}

export const Cell: React.FC<CellProps> = (props) => (
  <CellStyled
    onClick={props.onClick}
    hasClick={!!props.onClick}
    data-gtm-event-action={props.dataGtmEventAction}
    data-gtm-event-category={props.dataGtmEventCategory}
    data-gtm-event-label={props.dataGtmEventLabel}
  >
    {props.children}
  </CellStyled>
);
