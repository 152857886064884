import React from 'react';
import { blocklistRegex } from '@app/components/atm.not-allowed-word-textarea-field/blocklist';

export interface BlocklistProviderState {
  blocklistRegex: RegExp;
}

export const BlocklistContext = React.createContext<BlocklistProviderState>(null);

export const BlocklistProvider: React.FC = (props) => {
  return <BlocklistContext.Provider value={{ blocklistRegex }}>{props.children}</BlocklistContext.Provider>;
};
