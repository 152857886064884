import { ActivityIndicator } from '@atomic/atm.activity-indicator';
import { BodySecondary, Centered } from '@atomic/atm.typography';
import { VSeparator } from '@atomic/obj.grid';
import * as React from 'react';

import { LoadingCenteredStyled } from './loading.component.style';

interface LoadingCenteredProps {
  text?: string;
}

export const LoadingCentered: React.FC<LoadingCenteredProps> = (props) => (
  <LoadingCenteredStyled>
    <ActivityIndicator type='spinner' />
    {props.text && (
      <>
        <VSeparator />
        <BodySecondary>
          <Centered>{props.text}</Centered>
        </BodySecondary>
      </>
    )}
  </LoadingCenteredStyled>
);
