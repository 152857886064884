import styled from 'styled-components';

interface CellStyledProps {
  onClick?: () => void;
}

export const CellStyled = styled.div`
  padding: ${(props) => props.theme.Spacing.Medium};
  transition: box-shadow 0.1s;
  background-color: ${(props) => props.theme.Color.White};

  ${(props: CellStyledProps) => !!props.onClick && 'cursor: pointer;'};

  :hover {
    box-shadow: ${(props) => props.theme.BoxShadow.Depth6} ${(props) => props.theme.Color.GrayLight};
    position: relative;
  }
`;
