import { ScrollYStyle } from '@atomic/obj.mixin';
import styled from 'styled-components';
import { DrawerProps } from './drawer.component';

export const DrawerStyled = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: ${(props) => props.theme.HeaderHeight.Mobile};
  left: 0;
  z-index: ${(props: DrawerProps) =>
    props.active ? props.theme.Zindex.DrawerStyledActive : props.theme.Zindex.DrawerStyledInactive};
  transition: ${(props: DrawerProps) => (props.active ? 'none' : 'z-index .3s .3s')};
`;

const DrawerWidth = '70%';
export const DrawerMenuStyled = styled.div`
  position: absolute;
  background-color: ${(props) => props.theme.Color.White};
  width: ${DrawerWidth};
  height: 100%;
  left: ${(props: DrawerProps) => (props.active ? 0 : `-${DrawerWidth}`)};
  top: 0;
  transition: ${(props: DrawerProps) => (props.active ? 'left .3s' : 'left .3s, z-index .3s .3s')};
  z-index: ${(props: DrawerProps) =>
    props.active ? props.theme.Zindex.DrawerMenuStyledActive : props.theme.Zindex.DrawerMenuStyledInactive};
  ${ScrollYStyle}
`;

export const DrawerOverlayStyled = styled.a`
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
  background-color: ${(props) => props.theme.Color.Black};
  opacity: ${(props: DrawerProps) => (props.active ? '.3' : '0')};
  z-index: ${(props: DrawerProps) =>
    props.active ? props.theme.Zindex.DrawerOverlayStyledActive : props.theme.Zindex.DrawerOverlayStyledInactive};
  pointer-events: ${(props: DrawerProps) => (props.active ? 'initial' : 'none')};
  transition: ${(props: DrawerProps) =>
    props.active ? 'opacity .3s ease-out' : 'opacity .3s ease-out, z-index .3s .3s'};
`;
