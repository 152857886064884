import { defaultLoadableConfig } from '@app/core/loadable/default-config';
import { LoadableLoadingWrapper } from '@app/core/loadable/loadable-loading-wrapper.component';
import { LoadingCentered } from '@atomic/mol.loading';
import * as React from 'react';
import * as Loadable from 'react-loadable';

export const AdminLoadable = () => {
  const Component = Loadable({
    loader: () => import(/* webpackChunkName: "admin" */ './admin.router'),
    loading: (props) => (
      <LoadableLoadingWrapper {...props}>
        <LoadingCentered />
      </LoadableLoadingWrapper>
    ),
    modules: ['./admin.router'],
    webpack: () => [require.resolveWeak('./admin.router')],
    ...defaultLoadableConfig,
  });

  return <Component />;
};
