import { css } from 'styled-components';

// https://stackoverflow.com/a/13924997/3670829
export const TextMaxLines = (maxLines: number, lineHeight: number, adjustment = 0) => `
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${maxLines};
  line-height: ${lineHeight}px;
  max-height: ${lineHeight * maxLines + adjustment}px;
  margin: 0;
`;

// https://css-tricks.com/snippets/css/remove-gray-highlight-when-tapping-links-in-mobile-safari/
export const HighlightStyle = `
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0,0,0,0.05);
`;

export const ScrollYStyle = `
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overscroll-behavior: contain;
`;

export interface DisabledStyleProps {
  disabled?: boolean;
}

export const HighlighAndDisabledStyle = css`
  ${(props: DisabledStyleProps) =>
    props.disabled
      ? 'opacity: 0.5;'
      : `
        ${HighlightStyle}

        &:hover {
          opacity: 0.8;
        }

        &:active {
          opacity: 0.6;
        }`}
`;
