import styled from 'styled-components';
import { CardProps } from './card.component';
import { Theme } from '@atomic/obj.constants';

export interface CardStyledProps extends Theme {
  noGutter?: boolean;
  clickable?: boolean;
  hideOverflow?: boolean;
}

export const CardWrapperStyled = styled.div<CardStyledProps>`
  position: relative;
  ${(props: CardStyledProps) => !props.noGutter && `padding: ${props.theme.Spacing.Medium}`};
  background-color: ${(props) => props.theme.Color.White};
  border-radius: ${(props) => props.theme.Border.Radius};
  border-width: ${(props) => (props.bordered ? props.theme.Border.Width : 0)};
  border-style: solid;
  border-color: ${(props) => (props.selected ? props.theme.Color.Primary : props.theme.Border.Color)};
  ${(props: CardProps) => props.clickable && 'cursor: pointer;'}
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  ${(props: CardStyledProps) => props.hideOverflow && 'overflow: hidden;'}
`;
