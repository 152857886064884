import { useQueryHook } from '@app/core/graphql';
import { CommercialStructureList, CommercialStructureListVariables } from '@app/data/graphql';
import { UserContext } from '@app/providers/user.provider';
import * as React from 'react';

const mapCommercialStructureList = (data: CommercialStructureList) => {
  if (!data || !data.CommercialStructureList || !data.CommercialStructureList.nodes) {
    return null;
  }
  const commercialStructure = data.CommercialStructureList.nodes[0] && data.CommercialStructureList.nodes[0].id;
  return commercialStructure;
};

export const useCommercialStructureListQuery = () => {
  const userContext = React.useContext(UserContext);
  const result = useQueryHook<CommercialStructureList, CommercialStructureListVariables>(
    'commercial-structure-list.query',
    { skip: !userContext.isLogged(), variables: { limit: 1, offset: 0 } },
  );
  return { data: mapCommercialStructureList(result.data), error: result.error, loading: result.loading };
};
