import { withTracker } from '@app/core/analytics/analytics.hoc';
import { Route, Switch } from '@app/core/route';
import { Guard } from '@app/modules/account/account.guard';
import { ForgotPasswordLoadable } from '@app/modules/account/forgot-password';
import { LoginLoadable } from '@app/modules/account/login';
import { InternalRoutes } from '@app/modules/app/routes/internal-routes';
import * as React from 'react';

import { AppPath } from './routes-list';

/**
 * External routes. We call external routes every route which IS NOT wrapped by the app shell (menu, navbar etc)
 */
export class ExternalRoutes extends React.PureComponent<any, any> {
  render() {
    return (
      <Switch>
        <Route
          path={[
            AppPath.Login,
            AppPath.Activation.Login,
            AppPath.Admin.Login,
            AppPath.Starts.Login,
            AppPath.IrDigital.Login,
          ]}
          component={LoginLoadable}
          exact
        />
        <Route path={AppPath.Activation.ForgotPassword} component={ForgotPasswordLoadable} exact />
        <Route component={withTracker(InternalRoutes)} guard={Guard} redirectPath={AppPath.Login} />
      </Switch>
    );
  }
}
