import { Theme } from '@atomic/obj.constants';
import { ScrollYStyle } from '@atomic/obj.mixin';
import styled from 'styled-components';

export type ThumbnailSizeType = keyof typeof SIDE_MENU_SIZE;

export interface SideMenuProps extends Theme {
  size?: ThumbnailSizeType;
}

export const SIDE_MENU_SIZE = {
  large: '450px',
  medium: '300px',
  small: '150px',
};

export const SideMenuWrapper = styled.div`
  width: ${(props) => (props.size ? SIDE_MENU_SIZE[props.size] : SIDE_MENU_SIZE.medium)};
  min-height: 100vh;
  ${ScrollYStyle}
  background: ${(props) => props.theme.Color.White};
  box-shadow: ${(props) => props.theme.BoxShadow.Depth2} ${(props) => props.theme.Color.GrayLight};
`;

export const PageWrapper = styled.div`
  height: 100%;
  display: flex;
`;
