import * as ReactGA from 'react-ga';

interface EventsArgs {
  category: string;
  action: string;
  label: string;
  value?: number;
  nonInteraction?: boolean;
}

export class GoogleAnalyticsService {
  constructor(trackingId: string, development: boolean) {
    ReactGA.initialize(trackingId, { debug: development });
  }

  static pageView(pageName: string) {
    ReactGA.pageview(pageName);
  }

  // Fields explanation: https://github.com/react-ga/react-ga#reactgaeventargs
  static event({ action, category, label, nonInteraction, value }: EventsArgs) {
    ReactGA.event({
      action,
      category,
      label,
      nonInteraction,
      value,
    });
  }

  static set(fieldsObject: ReactGA.FieldsObject) {
    ReactGA.set(fieldsObject);
  }
}
