import {
  parseDateString,
  parseDateToFormattedString,
  removeTmzFromDateString,
} from '@app/components/obj.formatter/date-formatter';
import { parseNumberValue } from '@app/components/obj.formatter/number-formatter';
import { Project } from '@app/config-variables';
import { useQueryHook } from '@app/core/graphql';
import {
  ResellerOrderDetail,
  ResellerOrderDetailVariables,
  ResellerOrderDetail_ResellerOrderDetail,
} from '@app/data/graphql';
import { ThemeToken } from '@atomic/obj.constants';
import { NetworkStatus } from '@apollo/client';
import { Container } from 'typedi';

export enum ProductTypes {
  Cuted = 'CORTADO',
  Prized = 'BRINDE',
  Standard = 'PADRAO',
}

const mapResellerOrderDetailQuery = (data: ResellerOrderDetail_ResellerOrderDetail) => {
  const theme = Container.get(ThemeToken);
  if (!data) {
    return null;
  }
  const paymentsDescription = data.payments?.map((payment) => ({
    descriptionList: [
      {
        body: parseDateString(payment.dueDate, true),
        title: 'Vencimento:',
      },
      {
        body: data.code,
        title: 'Pedido:',
      },
      {
        body: payment.type,
        title: 'Tipo:',
      },
    ],
    shareLink: payment.fileLink,
    status: payment.titles ? payment.titles.map((title) => title.paymentStatus).join(' | ') : null,
    value: parseNumberValue(payment.value, true, 2),
  }));

  const orderDataBoti = [
    {
      body: data.code,
      title: 'Número do pedido:',
    },
    {
      body: parseDateToFormattedString(new Date(data.date), false, false),
      title: 'Realizado em:',
    },
    {
      body: data.statusDescription,
      title: 'Status:',
    },
    {
      body: parseNumberValue(data.totalValue, true, 2),
      title: 'Valor:',
    },
  ];

  const orderDataEudora = [
    {
      body: data.code,
      title: 'Número do pedido:',
    },
    {
      body: removeTmzFromDateString(data.date),
      title: 'Realizado em:',
    },
    {
      body: data.statusName,
      title: 'Status:',
    },
    {
      body: data.statusDescription,
      title: 'Situação fiscal:',
    },
    {
      body: parseNumberValue(data.valueToPay, true, 2),
      title: 'Valor:',
    },
  ];

  const products = data.products?.filter((value) => (value.type as ProductTypes) === ProductTypes.Standard);
  const cutProducts = data.products?.filter((value) => (value.type as ProductTypes) === ProductTypes.Cuted);
  const prizeProducts = data.products?.filter((value) => (value.type as ProductTypes) === ProductTypes.Prized);

  return {
    cutProducts,
    deliveryAdress: data.deliveryAddress,
    externalPaymentInfo: data.externalPaymentLink,
    orderData: theme.Project === Project.eudora ? orderDataEudora : orderDataBoti,
    paymentsDescription,
    prizeProducts,
    products,
    status: data.statusName,
  };
};

export const useResellerOrderDetailQuery = ({ orderId, resellerId }: ResellerOrderDetailVariables) => {
  const skipQuery = !orderId || !resellerId;
  const result = useQueryHook<ResellerOrderDetail, ResellerOrderDetailVariables>('reseller-order-detail.query', {
    notifyOnNetworkStatusChange: true,
    skip: skipQuery,
    variables: {
      orderId,
      resellerId,
    },
  });
  return {
    data: mapResellerOrderDetailQuery(result.data?.ResellerOrderDetail),
    error: result.error,
    id: result.data?.ResellerOrderDetail?.code,
    loading: result.loading || result.networkStatus === NetworkStatus.refetch,
    refetch: () => result.refetch(),
  };
};
