import { InputValue } from '@atomic/atm.typography';
import { Theme } from '@atomic/obj.constants';
import { HighlightStyle } from '@atomic/obj.mixin';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const radioSize = 24;

interface RadioFieldStyledProps extends Theme {
  expanded: boolean;
  disabled?: boolean;
  mb?: boolean;
}

export const RadioFieldStyled = styled.span`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin-bottom: ${(props: RadioFieldStyledProps) => (props.mb ? props.theme.Spacing.Medium : 0)};
  ${(props: RadioFieldStyledProps) => props.expanded && 'flex: 1'};

  & ~ * {
    margin-top: ${(props) => props.theme.Spacing.Small};
  }
`;

export const RadioFieldLabelStyled = styled(InputValue)`
  line-height: ${radioSize}px !important;
  padding-left: 36px;
  cursor: pointer;
  ${(props: RadioFieldStyledProps) => props.expanded && 'flex: 1'};
  ${(props: RadioFieldStyledProps) => props.disabled && 'text-decoration: line-through'};
  ${(props: RadioFieldStyledProps) => props.disabled && `color: ${props.theme.Color.GrayLight}`};
`;

export const RadioFieldContentStyled = styled.div`
  margin-left: ${(props) => props.theme.Spacing.XLarge};
  ${HighlightStyle};
`;

const RadioBulletStyled = styled(FontAwesomeIcon).attrs({ size: 'lg' })`
  position: absolute;
  ${(props: RadioFieldStyledProps) => props.disabled && `color: ${props.theme.Color.GrayXLight} !important`};
`;

export const RadioCheckedStyled = styled(RadioBulletStyled).attrs({ icon: 'dot-circle' })`
  color: ${(props) => props.theme.Color.Primary};
`;

export const RadioUncheckedStyled = styled(RadioBulletStyled).attrs({ icon: ['far', 'circle'] })`
  color: ${(props) => props.theme.Color.Black};
`;

export const RadioFieldInputStyled = styled.input`
  display: none;
`;
