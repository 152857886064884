import styled from 'styled-components';

export const LogoutButtonStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-family: ${(props) => props.theme.FontFamily.Primary};
  font-weight: ${(props) => props.theme.FontWeight.Bold};
  font-size: ${(props) => props.theme.FontSize.Small};
  color: ${(props) => props.theme.Color.Primary};
  width: 62px;
  height: 20px;
  padding: 0 ${(props) => props.theme.Spacing.Small};

  &:hover {
    opacity: 0.8;
  }
`;
