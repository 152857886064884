import { NewsCriticalityLevel } from '@app/data/graphql';
import { Hbox } from '@atomic/obj.box';
import * as React from 'react';
import {
  BannerMessageCloseStyled,
  BannerMessageContentStyled,
  BannerMessageStyled,
} from './banner-message.component.style';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Body, BodySecondary } from '@atomic/atm.typography';
import { Fade } from '@atomic/obj.animation/animation.component.style';

interface BannerMessageProps {
  title: string;
  message: string;
  criticalityLevel: NewsCriticalityLevel;
  onBannerClick?: () => void;
  onCloseClick: () => void;
}

export const BannerMessage: React.FC<BannerMessageProps> = (props) => {
  const [show, setShow] = React.useState(true);

  const handleCloseClick = () => {
    setShow(false);
    setTimeout(props.onCloseClick, 300);
  };

  const handleBannerClick = () => {
    handleCloseClick();
    props.onBannerClick();
  };

  return (
    <Fade show={show}>
      <BannerMessageStyled criticalityLevel={props.criticalityLevel}>
        <Hbox>
          <Hbox.Item>
            <BannerMessageContentStyled onClick={handleBannerClick}>
              <Body>{props.title}</Body>
              <BodySecondary>{props.message}</BodySecondary>
            </BannerMessageContentStyled>
          </Hbox.Item>
          <Hbox.Separator />

          <Hbox.Item noGrow>
            <BannerMessageCloseStyled onClick={handleCloseClick}>
              <FaIcon.Close />
            </BannerMessageCloseStyled>
          </Hbox.Item>
        </Hbox>
      </BannerMessageStyled>
    </Fade>
  );
};
