import React from 'react';
import { Modal } from '@atomic/obj.modal';
import { LocalizedContext } from '@app/components/obj.localization';
import { LoadingState } from '@atomic/obj.loading-state';
import { ComboProductSelectionType, ComboType } from '@app/data/graphql';
import { ApolloError } from '@apollo/client';
import { ErrorMessage } from '@app/components/mol.error-message';
import Container from 'typedi';
import { FlashDispatcherService } from '@app/components/obj.flash-wrapper/flash-dispatcher.service';
import { ChangeCartProductsParams } from '@app/domain/orders.use-case';
import {
  ComboProductSelection,
  ComboProductSelectionShimmer,
} from './mol.combo-product-selection/combo-product-selection.component';
import { Hbox } from '@atomic/obj.box';
import { Button } from '@atomic/atm.button';
import { ModalFooterStyled, ModalBoxBodyStyled } from '@atomic/obj.modal/modal.component.style';
import { ComboReview } from './mol.combo-review/combo-review.component';
import { ComboDetail } from './mol.combo-detail/combo-detail.component';
import { ComboModalHeader, ComboStep } from './combo-modal-header.component';

interface ComboEudoraModalProps {
  opened: boolean;
  loading: boolean;
  error: ApolloError;
  data: ComboType;
  initialQuantity?: number;
  originId?: string;
  sequentialNumber?: string;
  showComboReview?: boolean;
  refetch: () => void;
  onClose: () => void;
  onSubmit: (data: ChangeCartProductsParams) => void;
}

export interface ComboProduct {
  productCode: string;
  selectionCode?: string;
  quantity: number;
}
export interface ComboProducts {
  [key: string]: ComboProduct;
}

export const ComboEudoraModal: React.FC<ComboEudoraModalProps> = ({
  opened,
  loading,
  error,
  data,
  initialQuantity,
  originId,
  sequentialNumber,
  showComboReview,
  refetch,
  onClose,
  onSubmit,
}) => {
  const localized = React.useContext(LocalizedContext).strings.components.comboEudora;
  const [comboQuantity, setComboQuantity] = React.useState(initialQuantity ?? 1);
  const [quantityPerKit, setQuantityPerKit] = React.useState(0);
  const [step, setStep] = React.useState(showComboReview ? ComboStep.Review : ComboStep.Details);
  const [category, setCategory] = React.useState(0);
  const [comboProducts, setComboProducts] = React.useState<ComboProducts>({});
  const flashDispatcher = Container.get(FlashDispatcherService);

  React.useEffect(() => {
    const mappedSelections = data?.productSelections
      ?.map((selection) =>
        selection.products.reduce((previousState, currentValue) => {
          return {
            ...previousState,
            [currentValue.code]: {
              productCode: currentValue.code,
              quantity: currentValue.quantity,
              selectionCode: selection.code,
            },
          };
        }, {}),
      )
      ?.reduce((previous, current) => ({ ...previous, ...current }));
    if (mappedSelections) {
      setComboProducts((previousState) => ({ ...mappedSelections, ...previousState }));
    }
    if (showComboReview) {
      setCategory(data?.productSelections?.length - 1);
    }
  }, [data?.productSelections, showComboReview]);

  const getQuantitySelected = React.useCallback(
    (selections: ComboProductSelectionType) => {
      let previousQuantitySelected = 0;

      selections?.products?.forEach((value) => {
        comboProducts[value.code]
          ? (previousQuantitySelected += comboProducts[value.code].quantity)
          : (previousQuantitySelected += value.quantity);
      });

      return previousQuantitySelected;
    },
    [comboProducts],
  );

  const handleValueChange = (product: ComboProduct) => {
    const productToInsert = comboProducts[product.productCode] ?? product;
    const diff = product.quantity - productToInsert.quantity;
    const quantitySelected = getQuantitySelected(data?.productSelections?.[category]) + diff;
    productToInsert.quantity = product.quantity;

    setComboProducts({ ...comboProducts, [productToInsert.productCode]: productToInsert });
    if (diff > 0) {
      notifyQuantitySelectedLimit(quantitySelected);
    }
  };

  const notifyQuantitySelectedLimit = (quantitySelected: number) => {
    if (quantitySelected === quantityPerKit) {
      flashDispatcher.dispatchMessage({ message: localized.productsSelected }, 'success');
    }
  };

  const handleNextClick = () => {
    const { step: nextStep, category: nextCategory } = getNextStepAndCategory();

    setStep(nextStep);
    setCategory(nextCategory);
    setQuantityPerKit(comboQuantity * (data?.productSelections?.[nextCategory]?.quantityPerKit ?? 0));
  };

  const getNextStepAndCategory = () => {
    if (step === ComboStep.Categories) {
      const nextCategory = category + 1;
      if (nextCategory >= data?.productSelections?.length) {
        return { category: category, step: ComboStep.Review };
      } else {
        return { category: nextCategory, step: ComboStep.Categories };
      }
    } else {
      return { category: category, step: ComboStep.Categories };
    }
  };

  const handleBackClick = () => {
    const { step: previousStep, category: previousCategory } = getPreviousStepAndCategory();
    setStep(previousStep);
    setCategory(previousCategory);
    if (previousStep === ComboStep.Categories) {
      setQuantityPerKit(comboQuantity * (data?.productSelections?.[previousCategory]?.quantityPerKit ?? 0));
    }
  };

  const getPreviousStepAndCategory = () => {
    if (step === ComboStep.Categories) {
      const previousCategory = category - 1;
      if (previousCategory >= 0) {
        return { category: previousCategory, step: ComboStep.Categories };
      } else {
        return { category: category, step: ComboStep.Details };
      }
    } else {
      return { category: category, step: ComboStep.Categories };
    }
  };

  const handleConfirmClick = () => {
    const products = [
      ...Object.values(comboProducts).filter((value) => value.quantity > 0),
      ...data?.products.map((product) => ({ productCode: product.code, quantity: product.quantity })),
    ];

    onSubmit({
      comboProducts: products,
      originId,
      productCode: data?.code,
      quantity: comboQuantity,
      sequentialNumber,
    });
  };

  const handleComboQuantityChange = (newQuantity: number) => {
    setComboQuantity(newQuantity);
    setQuantityPerKit(newQuantity * (data?.productSelections?.[category]?.quantityPerKit ?? 0));
  };

  return (
    <Modal opened={opened} onClose={() => onClose()} isScrollable>
      <ModalBoxBodyStyled>
        <LoadingState loading={loading} error={!!error} data={!!data}>
          <LoadingState.Error>
            <ErrorMessage refetch={refetch} />
          </LoadingState.Error>

          <LoadingState.Shimmer>
            <ComboProductSelectionShimmer />
          </LoadingState.Shimmer>

          <ComboModalHeader
            combo={data}
            step={step}
            category={category}
            comboQuantity={comboQuantity}
            onComboQuantityChange={handleComboQuantityChange}
          />

          {step === ComboStep.Details && <ComboDetail combo={data} comboQuantity={comboQuantity} />}

          {step === ComboStep.Categories && (
            <ComboProductSelection
              combo={data}
              category={category}
              quantitySelected={getQuantitySelected(data?.productSelections?.[category])}
              quantityPerKit={quantityPerKit}
              comboProducts={comboProducts}
              onValueChange={handleValueChange}
            />
          )}

          {step === ComboStep.Review && (
            <ComboReview
              productsSelected={comboProducts}
              combo={data}
              onEditClick={(editStep) => {
                const previusCategory = editStep - ComboStep.Categories;
                setStep(ComboStep.Categories);
                setCategory(previusCategory);
                setQuantityPerKit(comboQuantity * (data?.productSelections?.[previusCategory]?.quantityPerKit ?? 0));
              }}
              comboQuantity={comboQuantity}
            />
          )}
        </LoadingState>
      </ModalBoxBodyStyled>

      <ModalFooterStyled>
        <Hbox>
          <Hbox.Item>
            {step !== ComboStep.Details && (
              <Button kind='neutral' outlined onClick={handleBackClick} loading={loading}>
                {localized.back}
              </Button>
            )}
          </Hbox.Item>
          <Hbox.Separator />
          <Hbox.Item>
            {step === ComboStep.Review ? (
              <Button onClick={handleConfirmClick} loading={loading}>
                {localized.confirm}
              </Button>
            ) : (
              <Button onClick={handleNextClick} loading={loading}>
                {localized.nextSelection}
              </Button>
            )}
          </Hbox.Item>
        </Hbox>
      </ModalFooterStyled>
    </Modal>
  );
};
