import { Localized } from '@app/components/obj.localization';
import { FvcConversationModalData } from '@app/components/org.feedback/feedback-modal.constants';
import { ActivationInput, ConversationStatusEnum, ResellerDetailByDocument, ResellersList } from '@app/data/graphql';
import { AutoCompleteResult } from '@atomic/org.autocomplete';
import Container from 'typedi';

export interface Orders {
  number: string;
  cycle: string;
  date: string;
  status: string;
  price: string;
}

export const mapResellerList = (data: ResellersList) => {
  if (!data?.FvcResellers?.nodes) {
    return null;
  }
  const autoComplete: AutoCompleteResult[] = data.FvcResellers.nodes.map((node) => ({
    id: node?.resellerId,
    name: node?.resellerName,
  }));
  return autoComplete;
};

export const mapResellerDetailList = (data: ResellerDetailByDocument) => {
  if (!data?.ResellerDetailByDocument) {
    return null;
  }
  const autoComplete: AutoCompleteResult[] = [
    {
      id: data?.ResellerDetailByDocument?.resellerId,
      name: data?.ResellerDetailByDocument?.personalInfo?.name,
    },
  ];
  return autoComplete;
};

export const mapFormInputToFeedback = (closeModalData: FvcConversationModalData, resellerName: string) => {
  const localized = Container.get(Localized).strings.components.home;

  const note = closeModalData.note
    ? localized.noteWithMessage({ name: resellerName, note: closeModalData.note })
    : localized.note(resellerName);

  const activation: ActivationInput = {
    byTelephone: closeModalData.telephone,
    byWhatsApp: closeModalData.whatsapp,
    feedbackDetail: closeModalData.detail,
    feedbackId: Number(closeModalData.feedback),
    note,
    requestedInfo: closeModalData.info?.toString(),
    scheduledTo: closeModalData.scheduledTo,
    status: ConversationStatusEnum[closeModalData.status],
  };

  const feedback = {
    activation,
    title: closeModalData.category,
  };

  return feedback;
};
