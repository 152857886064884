import styled from 'styled-components';

const breakpoint = '25em';

export const FlashWrapperStyled = styled.div`
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: ${(props) =>
    props.additionalSpacing
      ? parseInt(props.additionalSpacing, 10) + parseInt(props.theme.Spacing.Medium, 10) + 'px'
      : props.theme.Spacing.Medium};
  z-index: ${(props) => props.theme.Zindex.FlashMessageWrapperStyled};

  @media all and (min-width: ${breakpoint}) {
    max-width: ${breakpoint};
  }
`;
