import { CartListWrapper } from '@app/components/atm.cart-section-wrapper';
import { LocalizedContext } from '@app/components/obj.localization';
import { EventCategory, pushFillEvent } from '@app/utils/data-layer';
import { SelectField, SelectOption } from '@atomic/atm.select';
import { FormField } from '@atomic/obj.form';
import { VSeparator } from '@atomic/obj.grid';
import * as React from 'react';
import { InputLabel, InputValue } from '@atomic/atm.typography';

export interface CartCycleSelectSectionEudoraProps {
  waitingLoading?: boolean;
  cycles: SelectOption[];
  initialCycleValue?: SelectOption;
  selectedSubcycleDescription: string;
  onCycleChange: (value: string) => void;
}

export const CartCycleSelectSectionEudora: React.FC<CartCycleSelectSectionEudoraProps> = ({
  waitingLoading,
  cycles,
  initialCycleValue,
  selectedSubcycleDescription,
  onCycleChange,
}) => {
  const localized = React.useContext(LocalizedContext).strings.components.cartPages;

  return (
    <CartListWrapper disableOverflow>
      <FormField
        name='selectedCycle'
        initialValue={initialCycleValue}
        onValueChange={(options) => {
          onCycleChange(((options as unknown) as SelectOption).value);
        }}
        label={localized.cycle}
      >
        <SelectField
          options={cycles}
          onBlur={() => pushFillEvent(EventCategory.Attendence, [localized.cycle])}
          isDisabled={waitingLoading}
        />
      </FormField>

      <VSeparator />
      {selectedSubcycleDescription && (
        <>
          <InputLabel>{localized.subcycle}</InputLabel>
          <InputValue>{selectedSubcycleDescription}</InputValue>
        </>
      )}
    </CartListWrapper>
  );
};
