import { Theme } from '@atomic/obj.constants';
import styled from 'styled-components';

export interface ThumbStyleProps extends Theme {
  size?: 'small' | 'medium';
  noMargin?: boolean;
  circular?: boolean;
}

const sizes = {
  medium: 75,
  small: 45,
};

export const ThumbStyled = styled.img`
  margin-top: ${(props: ThumbStyleProps) => (props.noMargin ? 0 : props.theme.Spacing.Small)};
  width: ${(props: ThumbStyleProps) => (props.size ? sizes[props.size] : 120)}px;
  border-radius: ${(props: ThumbStyleProps) => (props.circular ? '50%' : 0)};
`;
