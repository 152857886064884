import { GraphQLRequest } from '@apollo/client';
import { Container, Service } from 'typedi';

import { GraphqlInterceptor } from '@app/core/graphql';
import { AuthenticationRepositoryProxy } from '@app/data/http/modules/authentication/authentication-repository.proxy';

interface Context {
  headers: object;
  busTokenRefreshed?: boolean;
}

@Service()
export class BusTokenGraphqlInterceptor implements GraphqlInterceptor {
  private authDatasource = Container.get(AuthenticationRepositoryProxy);

  async before(_req: GraphQLRequest, { headers = {} }: Context): Promise<Context> {
    const [busToken, busTokenRefreshed] = await this.getToken();

    headers['x-authentication-gera'] = busToken;

    return { busTokenRefreshed, headers };
  }

  private async getToken(): Promise<[string, boolean]> {
    try {
      let busTokenRefreshed = false;
      let busToken = await this.authDatasource.getValidBusToken();

      if (!busToken) {
        busToken = await this.authDatasource.refreshBusToken();
        busTokenRefreshed = true;
      }

      return [busToken, busTokenRefreshed];
    } catch (err) {
      return ['', false];
    }
  }
}
