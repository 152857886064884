import { RouteGuardProps } from '@app/core/route';
import * as React from 'react';
import { useAppType } from '@app/modules/account/use-appType';
import { FeatureToggleContext } from '@app/providers';
import { WorkInProgessPage } from '../work-in-progess.page';

export const StartsGuard: React.FC<RouteGuardProps> = (props) => {
  const featureToggleOption = React.useContext(FeatureToggleContext).featureToggleOptions;
  const { isStarts } = useAppType();

  if (!isStarts) {
    return props.children(false);
  }

  if (!featureToggleOption.fvcStartsPageEnabled) {
    return <WorkInProgessPage />;
  }

  return props.children(true);
};
