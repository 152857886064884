import { LocalizedContext } from '@app/components/obj.localization';
import { AddressSelectorSection } from '@app/components/orders/mol.address-selector/address-selector.section';
import { Project } from '@app/config-variables';
import { Cart } from '@app/data/graphql';
import { useStartOrderMutation } from '@app/domain/orders.use-case';
import { CartPageLocationState } from '@app/models/orders.model';
import { useAppType } from '@app/modules/account/use-appType';
import { AppPath } from '@app/modules/app/routes';
import { useStartOrderParams } from '@app/modules/cart/org.start-oder-params-hook/start-order-params.hook';
import { OrderContext, QueueContext } from '@app/providers';
import { Button } from '@atomic/atm.button';
import { Frame } from '@atomic/atm.frame';
import { Hbox } from '@atomic/obj.box';
import { CommonTheme } from '@atomic/obj.constants';
import { Col, Grid, Row, VSeparator } from '@atomic/obj.grid';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router';
import { ThemeContext } from 'styled-components';

export const CartSelectAddressPage: React.FC = () => {
  const theme = React.useContext<CommonTheme>(ThemeContext);
  const history = useHistory();
  const { state } = useLocation<CartPageLocationState>();
  const order = React.useContext(OrderContext);
  const queue = React.useContext(QueueContext);
  const needsStarterPackSelection = order.starterPacks?.length > 0;
  const localized = React.useContext(LocalizedContext).strings.components.cartPages;
  const { isStarts } = useAppType();

  const startOrderParams = useStartOrderParams();

  const handleStartOrderComplete = (newCart: Cart) => {
    order.updateCart(newCart);
    order.updateCurrentOrder();
    queue?.currentOrders?.refetch();
    history.push({ pathname: AppPath.Cart.Management, state });
  };

  const { loading: startOrderLoading, updateOrder } = useStartOrderMutation({
    onCompleted: handleStartOrderComplete,
    onError: order.handleOrderError,
    startOrderParams,
  });

  const handleOrderClick = () => {
    if (theme.Project === Project.eudora) {
      return history.push({
        pathname: AppPath.Cart.Cycle,
        state,
      });
    }

    if (order.canOrderOnPreviousCycle) {
      return history.push({
        pathname: AppPath.Cart.Cycle,
        state,
      });
    }

    if (needsStarterPackSelection) {
      return history.push({
        pathname: AppPath.Cart.StarterKits,
        state,
      });
    }

    return updateOrder();
  };

  return (
    <Grid>
      <Row center='xs' mt>
        <Col xs={8}>
          <Frame>
            <AddressSelectorSection
              onAddressChange={order.updateDeliveryMode}
              hasCurrentOrder={order.hasCurrentOrder}
              onRecoverOrderChange={order.updateRecoverLastCancelledOrder}
            />
            <VSeparator />

            <Hbox>
              <Hbox.Item>
                <Button
                  expanded
                  outlined
                  onClick={() =>
                    theme.Project === Project.eudora
                      ? history.goBack()
                      : history.push({
                          pathname: isStarts ? AppPath.Starts.Base : AppPath.Activation.Home.Dashboard.Base,
                          state: { resellerId: state?.resellerId },
                        })
                  }
                  loading={startOrderLoading}
                >
                  {localized.back}
                </Button>
              </Hbox.Item>

              <Hbox.Separator />
              <Hbox.Item hAlign='flex-end'>
                <Button
                  expanded
                  onClick={handleOrderClick}
                  loading={startOrderLoading}
                  disabled={!order.selectedDeliveryMode?.descriptiveAddress}
                >
                  {localized.advance}
                </Button>
              </Hbox.Item>
            </Hbox>
          </Frame>
        </Col>
      </Row>
    </Grid>
  );
};
