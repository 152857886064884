import * as React from 'react';

export const IcContact = (props) => {
  const color = props.color || '#A5407D';
  return (
    <svg {...props} viewBox='0 0 16 16'>
      <path
        d='M4.645 10.556v1.08c0 .215.176.39.392.39h6.213a.391.391 0 0 0 .392-.39v-1.08a2.765 2.765 0 0 0-2.77-2.751h-.098a1.966 1.966 0 0 0-.63-3.831c-.968 0-1.793.698-1.95 1.649a1.966 1.966 0 0 0 1.32 2.182h-.102a2.765 2.765 0 0 0-2.767 2.751zM6.951 5.94c0-.48.29-.913.736-1.097a1.1951.195 0 0 1 1.298.258c.34.339.443.85.258 1.293a1.191 1.191 0 0 1-2.292-.454zm3.907 4.616v.689H5.43v-.689a1.98 1.98 0 0 1 1.983-1.97h1.46a1.981.98 0 0 1 1.986 1.97zM1.285 6.244h.784v3.512h-.784V6.244zM15 2.34V13.66A2.346 2.346 0 0 1 12.649 16H2.46c-.65 0-1.176-.524-1.176-1.17v-2.147h.784v2.146c0 .216.175.39.392.39h10.188c.866 0 1.567-.698 1.567-1.56V2.34c0-.862-.701-1.56-1.567-1.56H2.46a.391.391 0 0 0-.392.39v2.146h-.784V1.171C1.285.524 1.812 0 2.461 0h10.188A2.346 2.346 0 0 1 15 2.341zM0 11.18c0-.216.175-.39.392-.39h2.645a.391.391 0 1 1 0 .78H.392a.391.391 0 01-.392-.39zM0 4.82c0-.215.175-.39.392-.39H2.84a.391.391 0 1 1 0 .78H.39A.391.391 0 0 1 0 4.822z'
        fill={color}
        fillRule='nonzero'
      />
    </svg>
  );
};
