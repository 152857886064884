import { FlashDispatcherService } from '@app/components/obj.flash-wrapper/flash-dispatcher.service';
import { Localized } from '@app/components/obj.localization';
import { RESET_CONTENT_ERROR } from '@app/models/errors.model';
import { AppPath } from '@app/modules/app/routes';
import { ApolloError } from '@apollo/client';
import { useHistory } from 'react-router';
import Container from 'typedi';

export const useCheckoutResetContentErrorHandler = () => {
  const flashDispatcherService = Container.get(FlashDispatcherService);
  const localized = Container.get(Localized).strings.error.order;
  const history = useHistory();

  const handleResetContentError = (error: ApolloError) => {
    if (isResetContentError(error)) {
      flashDispatcherService.dispatchMessage(
        { additionalInfo: 'checkout-reset-content-error.hook.tsx:16', message: localized.resetContent },
        'warning',
      );

      history.push({
        pathname: history.location.pathname.includes('starts')
          ? AppPath.Starts.Base
          : AppPath.Activation.Home.Dashboard.Base,
        state: {
          shouldOpenCart: true,
        },
      });
    }
  };

  return handleResetContentError;
};

export function isResetContentError(error: ApolloError) {
  const networkError = (error as any)?.networkError?.result?.errors;

  return networkError?.length && networkError.find((graphqlError) => graphqlError.name === RESET_CONTENT_ERROR);
}
