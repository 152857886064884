import { Container, Service, Token } from 'typedi';

import { HttpRequestBuilder } from '@app/core/http';
import { BusAuthenticationData, BusAuthenticationResponse } from '@app/data/http/dtos';

import { convertTtlToExpirationDate } from './utils';

const endpoint = {
  FORGOT_PASSWORD: 'credencial/recuperarSenha',
  LOGIN: 'credencial/autenticacao',
  REFRESH_TOKEN: 'credencial/token',
};

export const OriginToken = new Token<string>('origin');

@Service()
export class BusAuthenticationDatasource {
  private origin = Container.get(OriginToken);
  private httpRequestBuilder = Container.get(HttpRequestBuilder);

  async login(userId: string, password: string): Promise<BusAuthenticationData> {
    const body = {
      login: userId,
      origem: this.origin,
      senha: password,
    };

    const response = await this.httpRequestBuilder.post(endpoint.LOGIN).data(body).execute<BusAuthenticationResponse>();

    return {
      expirationDate: convertTtlToExpirationDate(response.tokenValidade),
      refreshToken: response.tokenRenovacao,
      supervisorId: response.identificador,
      token: response.tokenAutorizacao,
    };
  }

  async refreshToken(refreshToken: string): Promise<BusAuthenticationData> {
    const body = {
      origem: this.origin,
      tokenRenovacao: refreshToken,
    };

    const response = await this.httpRequestBuilder
      .post(endpoint.REFRESH_TOKEN)
      .data(body)
      .execute<BusAuthenticationResponse>();

    return {
      expirationDate: convertTtlToExpirationDate(response.tokenValidade),
      refreshToken: response.tokenRenovacao,
      supervisorId: response.identificador,
      token: response.tokenAutorizacao,
    };
  }

  forgotPassword(email: string): Promise<any> {
    const body = {
      login: email,
      origem: this.origin,
    };

    return this.httpRequestBuilder.post(endpoint.FORGOT_PASSWORD).data(body).execute();
  }
}
