import { ApolloClient, ApolloQueryResult, FetchResult, QueryOptions, NormalizedCacheObject } from '@apollo/client';
import { GraphQLDocsService } from './graphql-docs.service';
import { mapApolloError } from '@app/data/graphql/graphql-error.mapper';

export class ApolloImperative {
  private apolloClient: ApolloClient<NormalizedCacheObject>;
  private graphqlDocsService: GraphQLDocsService;

  constructor(apolloClient: ApolloClient<NormalizedCacheObject>, graphqlDocsService: GraphQLDocsService) {
    this.apolloClient = apolloClient;
    this.graphqlDocsService = graphqlDocsService;
  }

  public mutate<TData, TVariables = any>(mutationName: string, variables?: TVariables): Promise<FetchResult<TData>> {
    const mutation = this.graphqlDocsService.getMutation(mutationName);

    return this.apolloClient
      .mutate<TData, TVariables>({ mutation, variables })
      .catch((err) => {
        throw mapApolloError(err);
      });
  }

  public query<TData, TVariables = any>(
    queryName: string,
    options: Partial<QueryOptions<TVariables>>,
  ): Promise<ApolloQueryResult<TData>> {
    const query = this.graphqlDocsService.getQuery(queryName);

    return this.apolloClient
      .query<TData, TVariables>({ query, ...options })
      .catch((err) => {
        throw mapApolloError(err);
      });
  }
}
