import { Service } from 'typedi';

import { HttpInterceptor, HttpRequest } from '@app/core/http';
import { MILLISECONDS_TO_HALF_MINUTE } from '@app/utils/time';

/* *
 * This interceptor configures a timeout to the api requests, because
 * the app was crashing without it
 */

@Service()
export class TimeoutInterceptor implements HttpInterceptor {
  async before(request: HttpRequest): Promise<HttpRequest> {
    request.timeout = MILLISECONDS_TO_HALF_MINUTE;

    return request;
  }
}
