import { ProductResponse } from '@app/data/graphql';
import { useProductSearchQuery } from '@app/domain/orders.use-case';
import { OrderContext } from '@app/providers';
import { debounce } from 'debounce';
import * as React from 'react';
import { ProductNameSearchView } from './product-name-search-view.component';

interface ProductNameSearchProps {
  onProductClick: (product: string) => void;
  loading: boolean;
}

const MinLengthToSearch = 3;
const DebounceToSearchMillis = 300;

export const ProductNameSearch: React.FC<ProductNameSearchProps> = (props) => {
  const order = React.useContext(OrderContext);

  const [searchInput, setSearchInput] = React.useState('');

  const [search, { loading: searchLoading, error, data }] = useProductSearchQuery(
    order.selectedDeliveryMode?.distributionCenterCode,
    order.currentCycle,
  );

  const handleChangeText = React.useMemo(
    () =>
      debounce((text: string) => {
        if (text.length >= MinLengthToSearch) {
          search(text);
        }
      }, DebounceToSearchMillis),
    [search],
  );

  const handleProductSearchSubmit = (text: string) => {
    setSearchInput(text);
    handleChangeText(text);
  };

  const handleProductClick = (product: ProductResponse) => {
    return () => {
      props.onProductClick(product.code);
    };
  };

  return (
    <ProductNameSearchView
      onProductClick={handleProductClick}
      onValueChange={handleProductSearchSubmit}
      loading={searchLoading || props.loading}
      error={!!error}
      data={data}
      hasSearchStarted={searchInput.length < MinLengthToSearch}
    />
  );
};
