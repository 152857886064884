import { ApolloError } from '@apollo/client';
import {
  FixedTooltipMessageArrowStyled,
  FixedTooltipMessageStyled,
} from '@app/components/atm.fixed-tooltip-message/fixed-tooltip-message.style';
import { IcWarningPlaceholder } from '@app/components/icons/ic-warning-placeholder.component';
import { ActionBanner } from '@app/components/mol.action-banner/action-banner.component';
import { CartCutProductList } from '@app/components/mol.cart-cut-product-list/cart-cut-product-list.component';
import { ConfirmationModal } from '@app/components/mol.confirmation-modal/confirmation-modal.component';
import { ErrorMessage } from '@app/components/mol.error-message';
import { FastShopForm } from '@app/components/mol.fast-shop';
import { EudoraFastShopForm } from '@app/components/mol.fast-shop/eudora-fast-shop.component';
import { OrderTotalShimmer } from '@app/components/mol.order-total';
import { OrderTotal } from '@app/components/mol.order-total/order-total.component';
import { LocalizedContext } from '@app/components/obj.localization';
import { CartProductList, CartProductListShimmer } from '@app/components/orders/mol.cart-product-list';
import { CartStepHeader } from '@app/components/orders/mol.cart-step-header/cart-step-header.component';
import { CartManagementProductsWrapper } from '@app/components/orders/org.cart-management/cart-management.style';
import { Project } from '@app/config-variables';
import { ApplicationTypeEnum, Cart, StartOrderInput } from '@app/data/graphql';
import { useErrorFlashDispatcher } from '@app/domain/error-dispatcher.hook';
import { useCartStateQuery, useClearOrderMutation } from '@app/domain/orders.use-case';
import { useResellerInfoClientQuery } from '@app/domain/reseller-info.use-case';
import { CartSectionState } from '@app/models/orders.model';
import { useAppType } from '@app/modules/account/use-appType';
import { OrderContext } from '@app/providers/order.provider';
import { EventAction, EventCategory, sanitizeMessage } from '@app/utils/data-layer';
import { DeliveryMode } from '@app/view-model/current-order-delivery-modes';
import { Button } from '@atomic/atm.button';
import { BodyAlert } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { CommonTheme } from '@atomic/obj.constants';
import { VSeparator } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { ThemeContext } from 'styled-components';

interface CartManagementPageSectionProps {
  onContinueClick: () => void;
  onRecoverSuccess: () => void;
  selectedCycle: string;
  selectedSubCycle?: string;
  deliveryMode: DeliveryMode;
  recoverLastCancelledOrder: boolean;
  loading?: boolean;
  onError?: (error: ApolloError) => void;
}

export const CartManagementPageSection: React.FC<CartManagementPageSectionProps> = ({
  onContinueClick,
  onRecoverSuccess,
  onError,
  selectedCycle,
  deliveryMode,
  recoverLastCancelledOrder,
  loading,
}) => {
  const theme = React.useContext<CommonTheme>(ThemeContext);
  const localized = React.useContext(LocalizedContext).strings.components;
  const order = React.useContext(OrderContext);
  const { isStarts } = useAppType();

  const location = useLocation<CartSectionState>();

  const [showRecoveredOrderBanner, setShowRecoveredOrderBanner] = React.useState(false);
  const [clearConfirmationModalOpen, setClearConfirmationModalOpen] = React.useState(false);

  const cart = order.cart;

  const reseller = useResellerInfoClientQuery({ resellerId: order.resellerId });

  const handleStartOrderComplete = (newCart: Cart) => {
    order.updateCart(newCart);
    order.updateCurrentOrder();
    if (recoverLastCancelledOrder) {
      setShowRecoveredOrderBanner(newCart?.products?.length > 0);
    }
  };

  const startOrderParams: StartOrderInput = {
    application: isStarts ? ApplicationTypeEnum.Starts : ApplicationTypeEnum.Activation,
    changingDeliveryMode: location.state?.changeAddress,
    copyCanceledLastOrder: recoverLastCancelledOrder,
    cycle: selectedCycle,
    distributionCenterCode: deliveryMode?.distributionCenterCode ?? '0',
    editOrderCode: order.editOrderCode,
    pickupOnDistributionCenter: deliveryMode?.isWithdrawalCenter ?? false,
    resellerId: order.resellerId,
    starterPacks: order.selectedStarterPacks,
  };

  const { loading: cartDetailLoading, refetch: refetchCartState } = useCartStateQuery({
    onCompleted: (newCart: Cart) => order.updateCart(newCart),
    onError,
    orderCode: order?.currentOrder?.id,
  });

  const { error: clearOrderError, loading: clearOrderLoading, clearOrder } = useClearOrderMutation({
    onCompleted: handleStartOrderComplete,
    startOrderParams,
  });

  const handleClearOrderConfirmation = () => {
    setShowRecoveredOrderBanner(false);
    clearOrder();
  };

  const handleAcceptProducts = () => {
    setShowRecoveredOrderBanner(false);
    onRecoverSuccess();
  };

  useErrorFlashDispatcher(reseller.error);
  useErrorFlashDispatcher(clearOrderError);

  const minimum = order.cart?.requiredMinimum / 100;
  const currentAmount = order.cart?.currentValueConsideredToMinimum / 100;
  const amountToMinimum = minimum - currentAmount;
  const hasStarterKit = order?.starterPacks?.length > 0;

  const cartLoading = loading || cartDetailLoading;

  return (
    <>
      {showRecoveredOrderBanner && (
        <>
          <VSeparator small />
          <ActionBanner
            onConfirmation={() => setClearConfirmationModalOpen(true)}
            icon={IcWarningPlaceholder}
            onDismiss={handleAcceptProducts}
            confirmationText={localized.clearOrderBanner.confirmationText}
            dismissText={localized.clearOrderBanner.dismissText}
            message={localized.clearOrderBanner.message}
          />
          <VSeparator />
        </>
      )}

      <Hbox>
        <Hbox.Item vAlign='center'>
          <CartStepHeader title={localized.cartManagement.orderFn(reseller.data?.reseller.name, order.isEditing)} />
        </Hbox.Item>
      </Hbox>

      <VSeparator />

      <CartManagementProductsWrapper>
        {theme.Project === Project.eudora ? (
          <EudoraFastShopForm
            loading={cartLoading}
            orderCode={cart?.orderCode}
            onProductAdded={order.updateCart}
            onError={onError}
            withoutSearch
          />
        ) : (
          <FastShopForm loading={cartLoading} onProductAdded={order.updateCart} onError={onError} withoutSearch />
        )}

        <VSeparator />
        <LoadingState loading={cartLoading || clearOrderLoading} data={!!cart} error={!!clearOrderError}>
          <LoadingState.Shimmer>
            <CartProductListShimmer />
          </LoadingState.Shimmer>

          <LoadingState.Error>
            <ErrorMessage refetch={refetchCartState} />
          </LoadingState.Error>

          <CartProductList onError={onError} />
          <VSeparator />

          <CartCutProductList />
        </LoadingState>
      </CartManagementProductsWrapper>
      <VSeparator />

      <LoadingState loading={cartLoading} data={!!cart}>
        <LoadingState.Shimmer>
          <OrderTotalShimmer />
        </LoadingState.Shimmer>

        <OrderTotal totalPriceWithoutDiscount={order?.cart?.totals?.totalPay} availableCredit={order?.resellerCredit} />
        {hasStarterKit && (
          <>
            <FixedTooltipMessageArrowStyled />
            <FixedTooltipMessageStyled>{localized.cartManagement.starterPackCreditMessage}</FixedTooltipMessageStyled>
            <VSeparator />
          </>
        )}

        {amountToMinimum > 0 && (
          <>
            <VSeparator small />
            <BodyAlert>{localized.cartManagement.notEnoughProductsFn(amountToMinimum, minimum)}</BodyAlert>
          </>
        )}
      </LoadingState>
      <VSeparator />

      <Button
        kind='primary'
        loading={cartDetailLoading}
        rounded={false}
        expanded
        disabled={amountToMinimum > 0}
        size='normal'
        onClick={onContinueClick}
        data-cy='cart-management-proceed-to-review-button'
        dataGtmEventCategory={EventCategory.Attendence}
        dataGtmEventAction={EventAction.Click.Button}
        dataGtmEventLabel={sanitizeMessage(localized.cartManagement.proceedToReview)}
      >
        {localized.cartManagement.proceedToReview}
      </Button>

      <ConfirmationModal
        title={localized.admin.clearConfirmation.title}
        eventCategory={EventCategory.Attendence}
        kind='alert'
        onConfirm={handleClearOrderConfirmation}
        confirmationButtonText={localized.admin.clearConfirmation.clear}
        message={localized.admin.clearConfirmation.clearOrderConfirmationMessage}
        onClose={() => setClearConfirmationModalOpen(false)}
        opened={clearConfirmationModalOpen}
        loading={clearOrderLoading}
      />
    </>
  );
};
