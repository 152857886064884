import { LocalizedContext } from '@app/components/obj.localization';
import { useErrorFlashDispatcher } from '@app/domain/error-dispatcher.hook';
import { OrderContext } from '@app/providers/order.provider';
import { EventAction, EventCategory, EventLabel } from '@app/utils/data-layer';
import { DeliveryMode, mapCurrentOrderDeliveryModes } from '@app/view-model/current-order-delivery-modes';
import { CheckboxField } from '@atomic/atm.checkbox';
import { Divisor } from '@atomic/atm.divisor';
import { Body } from '@atomic/atm.typography';
import { RadioIconList } from '@atomic/mol.radio-icon-list/radio-icon-list.component';
import { ShimmerInput } from '@atomic/mol.shimmer';
import { VSeparator } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import { CartListWrapper } from 'app/components/atm.cart-section-wrapper';
import * as React from 'react';

const RESTORE_CANCELLED_ORDER_ID = 'restoreCancelledOrder';

export interface AddressCartPageProps {
  onAddressChange: (address: DeliveryMode) => void;
  onRecoverOrderChange: (selection: boolean) => void;
  hasCurrentOrder: boolean;
}

export const AddressSelectorSection: React.FC<AddressCartPageProps> = (props) => {
  const localized = React.useContext(LocalizedContext).strings.components.cartPages;
  const order = React.useContext(OrderContext);

  const handleDeliveryModeChange = (newDeliveryModeIndex: string) => {
    const newDeliveryMode: DeliveryMode = order.deliveryModes[newDeliveryModeIndex];
    props.onAddressChange({
      descriptiveAddress: newDeliveryMode.descriptiveAddress,
      distributionCenterCode: newDeliveryMode.distributionCenterCode,
      isWithdrawalCenter: newDeliveryMode.isWithdrawalCenter,
    });
  };
  const mappedDeliveryAddress = mapCurrentOrderDeliveryModes(order.deliveryModes);

  const handleRestoreOrderSelection = (_id, checked) => {
    props.onRecoverOrderChange(checked);
  };

  useErrorFlashDispatcher(order.error);

  return (
    <>
      <VSeparator />
      <Body>{localized.chooseAddress}</Body>
      <VSeparator />
      <CartListWrapper>
        <LoadingState loading={order.loading} error={!!order.error} data={!!order.deliveryModes}>
          <LoadingState.Shimmer>
            <ShimmerInput height='60px' />
            <VSeparator small />
            <ShimmerInput height='60px' />
            <VSeparator small />
            <ShimmerInput height='60px' />
          </LoadingState.Shimmer>
          <RadioIconList
            dataCy='address-selector-list'
            fieldName='address'
            list={mappedDeliveryAddress}
            onValueChange={handleDeliveryModeChange}
            dataGtmEventCategory={EventCategory.Attendence}
            initialValue={order.deliveryModes
              ?.findIndex((element) => element?.descriptiveAddress === order.selectedDeliveryMode?.descriptiveAddress)
              ?.toString()}
          />
        </LoadingState>
      </CartListWrapper>
      <VSeparator />
      <Divisor />
      <VSeparator />
      {!props.hasCurrentOrder && (
        <CheckboxField
          id={RESTORE_CANCELLED_ORDER_ID}
          dataGtmEventAction={EventAction.Click.Checkbox}
          dataGtmEventCategory={EventCategory.Attendence}
          dataGtmEventLabel={EventLabel.Attendence.Order.CopyLastOrder}
          checked={order?.recoverLastCancelledOrder}
          onValueChange={handleRestoreOrderSelection}
        >
          {localized.copyLastCancelledOrder}
        </CheckboxField>
      )}
    </>
  );
};
