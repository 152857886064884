import styled from 'styled-components';

interface TabsStyledProps {
  selected: boolean;
  underlined?: boolean;
}

export const TabsStyled = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TabsItemStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  ${(props) => props.fullWidth && 'flex: 1'};
  padding: ${(props) => props.theme.Spacing.Medium};
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  transition: all 300ms;
  cursor: pointer;

  &::before,
  &::after {
    display: block;
    position: absolute;
    content: '';
    width: 0%;
    height: ${(props) => (props.selected ? '2px' : '1px')};
    bottom: 0;
    background-color: ${(props) => (props.selected ? props.theme.Color.Primary : props.theme.Color.GrayDark)};
    transition: all 300ms;
  }

  &::before {
    right: 50%;
  }
  &::after {
    left: 50%;
  }

  &:hover {
    opacity: 1;

    &::before,
    &::after {
      width: 50%;
    }
  }

  ${(props: TabsStyledProps) =>
    props.selected
      ? `
    opacity: 1;

    &::before,
    &::after {
      width: 50%;
    }
  `
      : `
    opacity: .6;
  `}

  ${(props: TabsStyledProps) =>
    props.underlined
      ? `
    &::before,
    &::after {
      width: 50%;
    }
  `
      : ''}
`;
