import { intervalInDays, parseDateToFormattedString } from '@app/components/obj.formatter/date-formatter';
import { Localized } from '@app/components/obj.localization';
import {
  CycleDetail_DashboardCicle,
  DashboardResellerBaseInOutQuery_DashboardResellerBaseInOut,
} from '@app/data/graphql';
import { Container } from 'typedi/Container';

interface CycleMapperVariables {
  id: string;
  startDate: string;
  endDate: string;
}

export interface CycleToCaption {
  current: string;
  duration: string;
  durationHeader?: string;
}

export interface CycleToCaptionWithIds extends CycleToCaption {
  id: string;
  nextId: string;
  prevId: string;
}

const mapCycleIdToCaption = (cycle: CycleMapperVariables): CycleToCaption => {
  const localized = Container.get(Localized).strings.components.home;
  const startDate = new Date(cycle.startDate);
  const startDateFixed = new Date(
    startDate.getUTCFullYear(),
    startDate.getUTCMonth(),
    startDate.getUTCDate(),
    startDate.getUTCHours(),
    startDate.getUTCMinutes(),
    startDate.getUTCSeconds(),
    startDate.getUTCMilliseconds(),
  );
  const current: string = localized.current(cycle.id);
  const duration: string = localized.duration({
    end: parseDateToFormattedString(new Date(cycle.endDate)),
    start: parseDateToFormattedString(startDateFixed),
  });
  const durationHeader: string = localized.duration({
    end: parseDateToFormattedString(new Date(cycle.endDate), true),
    start: parseDateToFormattedString(startDateFixed, true),
  });
  return {
    current,
    duration,
    durationHeader,
  };
};

export const mapCycleToCaption = (cycle: CycleDetail_DashboardCicle) => {
  if (!cycle) {
    return null;
  }
  return mapCycleIdToCaption({
    endDate: cycle.currentCicleEndDate,
    id: cycle.currentCicleId,
    startDate: cycle.currentCicleStartDate,
  });
};

export const mapCycleToCaptionKeepIds = (cycle: CycleDetail_DashboardCicle): CycleToCaptionWithIds => {
  if (!cycle) {
    return null;
  }
  const details = mapCycleIdToCaption({
    endDate: cycle.currentCicleEndDate,
    id: cycle.currentCicleId,
    startDate: cycle.currentCicleStartDate,
  });
  return {
    current: details.current,
    duration: details.duration,
    id: cycle.currentCicleId,
    nextId: cycle.nextCicleId,
    prevId: cycle.previousCicleId,
  };
};

export const getAccumulatedGoal = (cycleData: CycleDetail_DashboardCicle, cycleGoal: number) => {
  if (!cycleData || !cycleGoal) {
    return null;
  }

  const cycleDurationInDays = intervalInDays(
    new Date(cycleData.currentCicleStartDate),
    new Date(cycleData.currentCicleEndDate),
  );

  let daysSinceStart = intervalInDays(new Date(cycleData.currentCicleStartDate), new Date());

  daysSinceStart = Math.min(daysSinceStart, cycleDurationInDays);

  return Math.floor((cycleGoal / cycleDurationInDays) * daysSinceStart);
};

export const getActiveBaseWithoutRestart = (
  totalActiveBase: number,
  dashboardResellerInOut: DashboardResellerBaseInOutQuery_DashboardResellerBaseInOut,
) => {
  if (!totalActiveBase || !dashboardResellerInOut) {
    return null;
  }

  return totalActiveBase - dashboardResellerInOut.restart - dashboardResellerInOut.start;
};
