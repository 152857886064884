import * as React from 'react';
import * as Loadable from 'react-loadable';

import { HeadlessPageWrapperBkgStyled } from '@app/components/obj.headless-page-wrapper/headless-page-wrapper.component.style';
import { defaultLoadableConfig } from '@app/core/loadable/default-config';
import { LoadingCentered } from '@atomic/mol.loading';

export const LoginLoadable = Loadable({
  loader: () => import(/* webpackChunkName: "login" */ './login.page'),
  loading: () => (
    <HeadlessPageWrapperBkgStyled>
      <LoadingCentered />
    </HeadlessPageWrapperBkgStyled>
  ),
  ...defaultLoadableConfig,
});
