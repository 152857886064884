import { GtmEventTag } from '@app/utils/data-layer';
import { HTab } from '@atomic/atm.typography';
import * as React from 'react';

import { TabsItemStyled, TabsStyled } from './tabs.component.style';
export interface TabsProps {
  index?: number;
  initialIndex?: number;
  items: string[];
  gtmData?: GtmEventTag[];
  fullWidth?: boolean;
  onTabIndexChanged?: (index: number) => void;
  underlined?: boolean;
}

export const Tabs: React.FC<TabsProps> = React.memo((props: TabsProps) => {
  const [selectedIndex, setSelectedIndex] = React.useState(props.index || props.initialIndex || 0);

  const isControlled = React.useCallback(() => props.index !== undefined, [props.index]);

  const handleTabIndexChanged = (index: number) => () => {
    if (props.onTabIndexChanged) {
      props.onTabIndexChanged(index);
    }

    if (isControlled()) {
      return;
    }

    setSelectedIndex(index);
  };

  React.useEffect(() => {
    if (isControlled() && props.initialIndex) {
      throw new Error('Use either the initialIndex prop, or the index prop, but not both');
    }
  }, [isControlled, props.initialIndex]);

  const getCurrentValue = () => {
    if (props.index !== undefined) {
      return props.index;
    }

    return selectedIndex;
  };

  return (
    <TabsStyled>
      {props.items.map((item: string, index: number) => (
        <TabsItemStyled
          fullWidth={props.fullWidth}
          key={index}
          selected={getCurrentValue() === index}
          onClick={handleTabIndexChanged(index)}
          underlined={props.underlined}
          data-gtm-event-category={props.gtmData?.[index]?.dataGtmEventCategory}
          data-gtm-event-action={props.gtmData?.[index]?.dataGtmEventAction}
          data-gtm-event-label={props.gtmData?.[index]?.dataGtmEventLabel}
        >
          <HTab underlined={props.underlined} selected={getCurrentValue() === index}>
            {item}
          </HTab>
        </TabsItemStyled>
      ))}
    </TabsStyled>
  );
});
