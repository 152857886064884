import * as React from 'react';
import { isNull, isNullOrUndefined } from 'util';
import { components } from 'react-select';

import { FormFieldContext, FormFieldContextState } from '@atomic/obj.form';

import { AsyncSelectStyled, SelectStyled } from './select.component.style';
import { Hbox } from '@atomic/obj.box';
import { Body } from '@atomic/atm.typography';

export interface SelectOption {
  value: any;
  label: string;
}

enum PossibleActions {
  SelectOption = 'select-option',
  RemoveValue = 'remove-value',
  PopValue = 'pop-value',
  Clear = 'clear',
}

interface SelectChangeAction {
  action: PossibleActions;
  option?: SelectOption;
  removedValue?: SelectOption;
}
export interface SelectProps {
  innerRef?: React.Ref<unknown>;
  isLoading?: boolean;
  isMulti?: boolean;
  isClearable?: boolean;
  options?: SelectOption[];
  allowedValues?: string[];
  onChange?: (options: SelectOption | SelectOption[]) => void;
  onFocus?: (event) => void;
  onBlur?: (event) => void;
  /** if you set this, then the AsyncSelect will be used */
  loadOptions?: (inputValue: string) => Promise<SelectOption[]>;
  isDisabled?: boolean;
  placeholder?: string;
  dataCy?: string;
  showValues?: boolean;
}

export class SelectField extends React.Component<SelectProps, {}> {
  private formFieldConsumer: FormFieldContextState;

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <FormFieldContext.Consumer>
        {(formFieldConsumer: FormFieldContextState) => {
          this.formFieldConsumer = formFieldConsumer;
          const SelectComponent = this.props.loadOptions ? AsyncSelectStyled : SelectStyled;
          return (
            /** https://react-select.com/props */
            <div data-cy={this.props.dataCy || 'select'}>
              <SelectComponent
                ref={this.props.innerRef}
                isMulti={this.props.isMulti}
                isLoading={this.props.isLoading}
                isClearable={this.props.isClearable}
                // this component doesn't accept 'null' as a value
                options={isNull(this.props.options) ? undefined : this.props.options}
                onChange={this.handleChange}
                value={this.getValue()}
                defaultValue={this.getDefaultValue()}
                cacheOptions={!isNullOrUndefined(this.props.loadOptions)}
                loadOptions={this.props.loadOptions}
                loadingMessage={() => 'Carregando...'}
                noOptionsMessage={this.handleInputValue}
                placeholder={this.props.placeholder || ''}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                isDisabled={this.props.isDisabled}
                components={this.props.showValues && { Option: this.renderOptionWithValue }}
              />
            </div>
          );
        }}
      </FormFieldContext.Consumer>
    );
  }

  getDefaultValue = () => {
    if (this.formFieldConsumer?.defaultValue?.length === 0 || !this.formFieldConsumer?.defaultValue?.value) {
      return undefined;
    }

    const copiedValues = this.formFieldConsumer.defaultValue.slice
      ? this.formFieldConsumer.defaultValue.slice()
      : this.formFieldConsumer.defaultValue;

    if (Array.isArray(copiedValues)) {
      return copiedValues.filter((copiedObject) =>
        this.props.allowedValues
          ? this.props.allowedValues.find((allowedValue) => copiedObject.value.startsWith(allowedValue))
          : copiedObject,
      );
    } else {
      return copiedValues;
    }
  };

  getValue = () => {
    if (this.formFieldConsumer?.value?.length === 0 || !this.formFieldConsumer?.value) {
      return undefined;
    }
    // it seems that Select does some optimizations to avoid re-rendering. To
    // force a re-render here, the value is copied to make sure the value is
    // changed and then the SelectComponent is re-rendered
    const copiedValues = this.formFieldConsumer.value.slice
      ? this.formFieldConsumer.value.slice()
      : this.formFieldConsumer.value;

    if (Array.isArray(copiedValues)) {
      return copiedValues.filter((copiedObject) =>
        this.props.allowedValues
          ? this.props.allowedValues.find((allowedValue) => copiedObject.value.startsWith(allowedValue))
          : copiedObject,
      );
    } else {
      return copiedValues;
    }
  };

  handleInputValue = ({ inputValue }) => {
    return inputValue.length > 0 ? 'Nenhuma opção foi encontrada.' : 'Digite algo para buscar opções.';
  };

  private handleFocus = (event) => {
    this.props.onFocus?.(event);
    this.formFieldConsumer.onFocusChange(true);
  };

  private handleBlur = (event) => {
    this.props.onBlur?.(event);
    this.formFieldConsumer.onFocusChange(false);
  };

  private handleChange = (obj: SelectOption[], { action, removedValue, option }: SelectChangeAction) => {
    this.props.onChange?.(obj);

    if (this.formFieldConsumer) {
      switch (action) {
        case PossibleActions.RemoveValue:
        case PossibleActions.PopValue:
          this.formFieldConsumer.onValueChange([removedValue], false);
          break;
        case PossibleActions.Clear:
          this.formFieldConsumer.onClear();
          break;
        case PossibleActions.SelectOption:
          {
            const selectedOption = this.props.isMulti ? [option] : obj;
            this.formFieldConsumer.onValueChange(selectedOption, true);
          }
          break;
      }
    }
  };

  private renderOptionWithValue = (props) => {
    return (
      <components.Option {...props}>
        <Hbox>
          <Hbox.Item>
            <Body>{props.data.label}</Body>
          </Hbox.Item>
          <Hbox.Item hAlign='flex-end'>
            <Body>{props.data.value}</Body>
          </Hbox.Item>
        </Hbox>
      </components.Option>
    );
  };
}

export const SelectFieldWithRef = React.forwardRef((props: SelectProps, ref) => (
  <SelectField {...props} innerRef={ref} />
));
