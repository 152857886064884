import { ActivityIndicator } from '@atomic/atm.activity-indicator';
import { Theme } from '@atomic/obj.constants';
import * as React from 'react';
import { ButtonContentStyled, ButtonSpinnerStyled, ButtonStyled, LinkButtonStyled } from './button.component.style';
import { LocationDescriptorObject } from 'history';

export type ButtonKind =
  | 'primary'
  | 'secondary'
  | 'neutral'
  | 'callToAction'
  | 'link'
  | 'alert'
  | 'white'
  | 'disabled'
  | 'neutralLink'
  | 'externalLink';

export interface ButtonProps extends Theme {
  /** Enables/disables a component. */
  disabled?: boolean;
  /** Enables/disables full-width button. */
  expanded?: boolean;
  /** Enables/disables loading indicator. */
  loading?: boolean;
  /** Event. Fired when button is clicked. */
  onClick?: (event: React.SyntheticEvent<HTMLButtonElement>) => any;
  /** Enables/disables button outlined style. */
  outlined?: boolean;
  /** Adds a <a target= /> attribute. Applies only when "to" props is declared. */
  target?: '_self' | '_parent' | '_blank' | '_top';
  /** When declared, button component is rendered as <a href.. />. Otherwise, it renders a <button /> element. */
  to?: string | LocationDescriptorObject;
  /** Changes button's visual style. */
  kind?: ButtonKind;
  /**
   * why is it necessary? https://github.com/indigotech/eok-weekly/issues/114
   * https://www.w3schools.com/tags/att_button_type.asp
   */
  type?: 'button' | 'submit' | 'reset'; // default-value: "button"
  light?: boolean;
  rounded?: boolean;
  noText?: boolean;
  className?: string;
  // This will be removed later, when all the buttons will have a correct line height.
  size?: 'normal' | 'small';
  /** Adds html attribute title to button */
  title?: string;
  as?: any;
  href?: string;
  rel?: string;
  /** GTM Event tags */
  dataGtmEventCategory?: string;
  dataGtmEventAction?: string;
  dataGtmEventLabel?: string;
}

interface WithLinkProps extends ButtonProps {
  children?: any;
}

const withLink = (Component, props: WithLinkProps) => {
  const {
    loading,
    disabled,
    type,
    outlined,
    expanded,
    light,
    dataGtmEventCategory,
    dataGtmEventAction,
    dataGtmEventLabel,
    ...others
  } = props;
  const externalLinkProps =
    props.kind === 'externalLink'
      ? {
          as: 'a',
          href: props.to,
          rel: 'noopener noreferrer',
          target: '_blank',
        }
      : {};
  return (
    <Component
      disabled={loading || disabled}
      type={!props.to ? type : undefined}
      outlined={outlined ? 1 : 0}
      expanded={expanded ? 1 : 0}
      {...externalLinkProps}
      {...others}
    >
      <ButtonContentStyled
        loading={loading}
        noText={props.noText}
        data-gtm-event-category={dataGtmEventCategory}
        data-gtm-event-action={dataGtmEventAction}
        data-gtm-event-label={dataGtmEventLabel}
      >
        {props.children}
      </ButtonContentStyled>
      <ButtonSpinnerStyled loading={loading} size={others.size}>
        <ActivityIndicator type='spinner' light={(props.kind === 'primary' && !outlined) || light} />
      </ButtonSpinnerStyled>
    </Component>
  );
};

export class Button extends React.Component<ButtonProps, undefined> {
  static defaultProps = {
    disabled: false,
    expanded: false,
    kind: 'primary',
    light: false,
    loading: false,
    noText: false,
    outlined: false,
    rounded: true,
    size: 'small',
    type: 'button',
  };

  render() {
    return withLink(this.props.to ? LinkButtonStyled : ButtonStyled, this.props);
  }
}
