import * as React from 'react';

export const IcSchedulePlaceholder = (props) => (
  <svg width={100} height={100} viewBox='0 0 100 100' fill='none' {...props}>
    <path
      d='M48.115 50.271v.004a1.958 1.958 0 00.686 1.468h0l.005.004 8.704 7.159a1.957 1.957 0 102.452-3.034l-7.931-6.57V33.737a1.958 1.958 0 10-3.916 0v16.534z'
      fill='#333'
      stroke='#fff'
      strokeWidth={0.657}
    />
    <path
      d='M20.66 27.735a7.246 7.246 0 00-1.21 4.045l1.21-4.045zm0 0a7.245 7.245 0 013.259-2.684m-3.259 2.684l3.259-2.684m0 0a7.246 7.246 0 014.202-.413m-4.202.413l4.202-.413m0 0a7.247 7.247 0 013.455 1.748m-3.455-1.748l3.455 1.748m0 0a29.813 29.813 0 00-8.625 11.602m8.625-11.602l-8.625 11.602m0 0a7.327 7.327 0 01-3.5-6.207l3.5 6.207zm-4.827.909a11.162 11.162 0 003.544 2.913 29.651 29.651 0 008.01 28.857l-5.604 7.342-.002.002a1.958 1.958 0 003.132 2.35s0 0 0 0l5.421-7.15a29.65 29.65 0 0035.466 0l5.42 7.15h.002a1.958 1.958 0 003.131-2.35l-.002-.002-5.605-7.342a29.65 29.65 0 008.013-28.85 11.161 11.161 0 00-5.035-21.196h0a11.162 11.162 0 00-8.034 3.49 29.57 29.57 0 00-31.168.002 11.161 11.161 0 00-16.689 14.784zM50.4 75.11a25.816 25.816 0 110-51.633 25.816 25.816 0 010 51.633zm29.425-38.956a7.246 7.246 0 01-1.98 1.83 29.813 29.813 0 00-8.617-11.595 7.246 7.246 0 0110.597 9.765z'
      fill='#A3A3A3'
      stroke='#fff'
      strokeWidth={0.657}
    />
  </svg>
);
