import { useQueryHook } from '@app/core/graphql';
import { CycleDetail, CycleDetailVariables } from '@app/data/graphql';

interface CycleDetailQueryArguments {
  currentCycle?: string;
  onCompleted?: (data: CycleDetail) => void;
}

export const useCycleDetailQuery = (args?: CycleDetailQueryArguments) => {
  const currentCycle = args?.currentCycle;
  const handleOnCompleted = (data) => args?.onCompleted?.(data);
  const result = useQueryHook<CycleDetail, CycleDetailVariables>('cycle-detail.query', {
    notifyOnNetworkStatusChange: true,
    onCompleted: handleOnCompleted,
    variables: {
      cycle: currentCycle,
    },
  });

  return {
    data: result.data?.DashboardCicle,
    error: result.error,
    loading: result.loading,
    refetch: () => result.refetch(),
  };
};
