import * as React from 'react';

export const IcDiscountSign = (props) => (
  <svg viewBox='0 0 20 20' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.94.571a.4.4 0 00-.197.094L6.191 1.957 4.264 1.69a.418.418 0 00-.44.282l-.757 1.963-1.79.83a.47.47 0 00-.256.483l.243 2.115L.087 9.066a.5.5 0 000 .563l1.177 1.703-.243 2.115a.47.47 0 00.257.484l1.789.83.756 1.963a.418.418 0 00.44.281l1.928-.267 1.552 1.292a.393.393 0 00.514 0l1.552-1.292 1.927.267a.418.418 0 00.44-.281l.757-1.963 1.79-.83a.47.47 0 00.256-.484l-.243-2.115 1.177-1.703a.5.5 0 000-.563l-1.177-1.703.243-2.115a.47.47 0 00-.256-.483l-1.79-.83-.756-1.963a.419.419 0 00-.44-.282l-1.928.267L8.257.665A.397.397 0 007.98.571h-.04zM8 1.618l1.434 1.19c.087.071.196.102.303.087l1.789-.245.697 1.819a.442.442 0 00.223.245l1.658.765-.223 1.963a.499.499 0 00.078.332l1.086 1.574-1.086 1.573a.499.499 0 00-.079.332l.224 1.963-1.657.765a.442.442 0 00-.224.245l-.697 1.82-1.79-.246a.396.396 0 00-.302.086L8 17.077l-1.434-1.19a.396.396 0 00-.303-.087l-1.789.245-.697-1.819a.442.442 0 00-.224-.245l-1.657-.765.224-1.963a.5.5 0 00-.08-.332L.956 9.348l1.086-1.574a.5.5 0 00.079-.332L1.896 5.48l1.657-.765a.442.442 0 00.224-.245l.697-1.82 1.79.246a.396.396 0 00.302-.086L8 1.618zm3.315 3.565a.409.409 0 00-.243.137l-6.736 7.391a.483.483 0 00-.13.327.486.486 0 00.121.332.399.399 0 00.301.138.4.4 0 00.3-.14l6.736-7.39a.494.494 0 00.09-.538.414.414 0 00-.439-.257zm-5.42.008c-.925 0-1.684.832-1.684 1.847 0 1.015.76 1.848 1.684 1.848.925 0 1.684-.833 1.684-1.848 0-1.015-.759-1.847-1.684-1.847zm0 .923c.47 0 .842.408.842.924s-.372.924-.842.924-.842-.408-.842-.924.372-.924.842-.924zm4.21 3.696c-.925 0-1.684.832-1.684 1.847 0 1.015.759 1.848 1.684 1.848.925 0 1.684-.833 1.684-1.848 0-1.015-.76-1.847-1.684-1.847zm0 .923c.47 0 .842.408.842.924s-.372.924-.842.924-.842-.408-.842-.924.372-.924.842-.924z'
      fill='#06A066'
    />
  </svg>
);
