import { LocalizedContext } from '@app/components/obj.localization';
import { Schedule, ScheduleAction } from '@app/models/schedule.model';
import { EventAction, EventCategory, sanitizeMessage } from '@app/utils/data-layer';
import { Button } from '@atomic/atm.button';
import { DotBadge } from '@atomic/atm.dot-badge';
import { BodySecondary, DD, Disabled, DT, Ellipsed } from '@atomic/atm.typography';
import { useBooleanState } from '@atomic/obj.boolean-state/boolean-state.hook';
import { Hbox } from '@atomic/obj.box';
import React from 'react';
import { ScheduleCellStyled } from './schedule-cell.component.style';

export interface ScheduleCellProps {
  schedule: Schedule;
  onClick: (action: ScheduleAction, id?: number) => void;
}

export const ScheduleCell: React.FC<ScheduleCellProps> = (props) => {
  const { value: isHovered, toggle: toggleHover } = useBooleanState(false);
  const { resellerName, hasPassed, attendanceTime, scheduledTime, id } = props.schedule;

  const handleSetRescheduleId = () => {
    props.onClick(ScheduleAction.reschedule, +id);
  };

  const handleSetCanceledId = () => {
    props.onClick(ScheduleAction.cancelSchedule, +id);
  };

  return (
    <ScheduleCellStyled onMouseEnter={toggleHover} onMouseLeave={toggleHover}>
      <Hbox>
        <Hbox.Item noGrow vAlign='center'>
          {hasPassed ? (
            <DD>
              <Disabled>{scheduledTime} </Disabled>
            </DD>
          ) : (
            <DD>{scheduledTime}</DD>
          )}
        </Hbox.Item>
        <Hbox.Separator />
        <Hbox.Item vAlign='center' noGrow hideOverflow>
          <Ellipsed>
            {hasPassed ? (
              <DT>
                <Disabled>{resellerName} </Disabled>
              </DT>
            ) : (
              <DT>{resellerName}</DT>
            )}
          </Ellipsed>
        </Hbox.Item>
        <Hbox.Separator />
        {hasPassed ? (
          <ScheduleCellStatus attendanceTime={attendanceTime} />
        ) : (
          isHovered && (
            <ScheduleCellOptions
              onClickReschedule={handleSetRescheduleId}
              onClickCancelSchedule={handleSetCanceledId}
            />
          )
        )}
      </Hbox>
    </ScheduleCellStyled>
  );
};

interface ScheduleCellOptionsProps {
  onClickReschedule: () => void;
  onClickCancelSchedule: () => void;
}

const ScheduleCellOptions: React.FC<ScheduleCellOptionsProps> = ({ onClickReschedule, onClickCancelSchedule }) => {
  const localized = React.useContext(LocalizedContext).strings.components.scheduleList;

  return (
    <>
      <Hbox.Item hAlign='flex-end'>
        <Button
          onClick={onClickReschedule}
          kind='link'
          dataGtmEventAction={EventAction.Click.Link}
          dataGtmEventCategory={EventCategory.Attendence}
          dataGtmEventLabel={sanitizeMessage(localized.reschedule)}
        >
          {localized.reschedule}
        </Button>
      </Hbox.Item>
      <Hbox.Separator />
      <Hbox.Item noGrow hAlign='flex-end'>
        <Button
          onClick={onClickCancelSchedule}
          kind='link'
          dataGtmEventAction={EventAction.Click.Link}
          dataGtmEventCategory={EventCategory.Attendence}
          dataGtmEventLabel={sanitizeMessage(localized.cancel)}
        >
          {localized.cancel}
        </Button>
      </Hbox.Item>
    </>
  );
};

const ScheduleCellStatus: React.FC<{ attendanceTime?: string }> = (props) => {
  const localized = React.useContext(LocalizedContext).strings.components.scheduleList;

  return (
    <>
      <Hbox.Item hAlign='flex-end'>
        <Ellipsed>
          <BodySecondary>
            {props.attendanceTime ? localized.attendedFn(props.attendanceTime) : localized.notAttended}
          </BodySecondary>
        </Ellipsed>
      </Hbox.Item>
      <Hbox.Separator />
      <Hbox.Item vAlign='center' hAlign='flex-end' noGrow>
        <DotBadge type={props.attendanceTime ? 'success' : 'alert'} />
      </Hbox.Item>
    </>
  );
};
