import { Cart_deliveryAddresses } from '@app/data/graphql/graphql-schema';
import { OrderAddress } from '@app/view-model/order-address/order-address.vm';

export const mapDeliveryAddresses = (
  addresses: Cart_deliveryAddresses[],
  pickupOnDistributionCenter: boolean,
): OrderAddress[] => {
  return addresses?.map((address) => ({
    formattedAdrress: address.formattedAddress,
    id: address.type.id,
    pickupOnDistributionCenter,
    type: address.type.description,
  }));
};
