import { botStrings } from '@app/components/obj.localization/bot-strings';
import { eudoraStrings } from '@app/components/obj.localization/eudora-strings';
import { Project } from '@app/config-variables';
import { Service } from 'typedi';
import { commonStrings } from './common-strings';
export type CommonStringsType = ReturnType<typeof commonStrings>;
@Service()
class Localized {
  strings: CommonStringsType;
  constructor(project: string) {
    this.setStrings(project);
  }

  private setStrings(project: string) {
    const strings = {
      [Project.bot]: botStrings,
      [Project.eudora]: eudoraStrings,
    };
    this.strings = strings[project];
  }
}

export { Localized };
