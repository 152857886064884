import * as React from 'react';
import { IcMagnifier } from '@app/components/icons/ic-magnifier.component';
import { Body } from '@atomic/atm.typography';
import { VSeparator } from '@atomic/obj.grid';
import { Hbox } from '@atomic/obj.box';

interface FastModalShopPlaceholderProps {
  placeholderText: string;
}

export const ProductSearchModalPlaceholder: React.FC<FastModalShopPlaceholderProps> = ({ placeholderText }) => {
  return (
    <Hbox>
      <Hbox.Item hAlign='center'>
        <VSeparator />
        <IcMagnifier />
        <VSeparator />
        <Body>{placeholderText}</Body>
        <VSeparator />
      </Hbox.Item>
    </Hbox>
  );
};
