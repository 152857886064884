import { LocalizedContext } from '@app/components/obj.localization';
import { BusinessModelSelector } from '@app/components/orders/mol.business-model-selector/business-model-selector.component';
import { useBusinessModels } from '@app/domain/business-models.use-case';
import { useErrorFlashDispatcher } from '@app/domain/error-dispatcher.hook';
import { CartPageLocationState } from '@app/models/orders.model';
import { AppPath } from '@app/modules/app/routes';
import { OrderContext, QueueContext } from '@app/providers';
import { Button } from '@atomic/atm.button';
import { Frame } from '@atomic/atm.frame';
import { Hbox } from '@atomic/obj.box';
import { Col, Grid, Row, VSeparator } from '@atomic/obj.grid';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router';

export const CartSelectBusinessModelPage: React.FC = () => {
  const activeIndex = React.useContext(QueueContext)?.activeIndex;
  const localized = React.useContext(LocalizedContext).strings.components.cartPages;

  const businessModels = useBusinessModels(activeIndex);

  const history = useHistory();
  const { state } = useLocation<CartPageLocationState>();
  const order = React.useContext(OrderContext);

  const handleOrderClick = () => {
    if (order.starterPacks.length > 0) {
      return history.push({ pathname: AppPath.Cart.StarterKits, state });
    }

    return history.push({ pathname: AppPath.Cart.Address, state });
  };

  const handleRestoreOrderSelection = (checked: boolean) => {
    order.updateRecoverLastCancelledOrder(checked);
  };

  const handleBusinessModelChange = (value) => {
    order.updateSelectedBusinessModel(value);

    const businessModel = businessModels.data?.find((it) => it.id === value);
    if (businessModel?.starterPacks?.length > 0) {
      order.updateAvailableStarterPacks(businessModel.starterPacks);
    }

    if (businessModel?.deliveryModes) {
      order.updateAvailableDeliveryModes(businessModel.deliveryModes);
    }
  };

  useErrorFlashDispatcher(businessModels.error);

  return (
    <Grid>
      <Row center='xs' mt>
        <Col xs={8}>
          <Frame>
            <BusinessModelSelector
              businessModels={businessModels}
              handleRestoreOrderSelection={handleRestoreOrderSelection}
              handleBusinessModelChange={handleBusinessModelChange}
              hasCurrentOrder={order.hasCurrentOrder}
              recoverLastOrder={order.recoverLastCancelledOrder}
            />
            <VSeparator />

            <Hbox>
              <Hbox.Item>
                <Button
                  expanded
                  outlined
                  onClick={() =>
                    history.push({
                      pathname: AppPath.Activation.Home.Dashboard.Base,
                      state: { resellerId: state?.resellerId },
                    })
                  }
                  loading={businessModels.loading}
                >
                  {localized.back}
                </Button>
              </Hbox.Item>
              <Hbox.Separator />

              <Hbox.Item hAlign='flex-end'>
                <Button
                  expanded
                  onClick={handleOrderClick}
                  loading={businessModels.loading}
                  disabled={!order.selectedBusinessModel}
                >
                  {localized.advance}
                </Button>
              </Hbox.Item>
            </Hbox>
          </Frame>
        </Col>
      </Row>
    </Grid>
  );
};
