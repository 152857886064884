import { FeedbackModalCategoryWrapperStyled } from '@app/components/obj.feedback-modal-category-form';
import { LocalizedContext } from '@app/components/obj.localization';
import { EventCategory, EventAction, sanitizeMessage, pushFinishAttendance } from '@app/utils/data-layer';
import { Button } from '@atomic/atm.button';
import { DatePicker } from '@atomic/atm.date-picker';
import { H1 } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { Form } from '@atomic/obj.form';
import { VSeparator } from '@atomic/obj.grid';
import * as React from 'react';

const DATEPICKER_INTERVAL = 15;

interface AppointmentSectionProps {
  hasNextStep?: boolean;
  onSubmit: (data: Date) => void;
  onBack: () => void;
  loading?: boolean;
}

export const FeedbackModalAppointmentForm: React.FC<AppointmentSectionProps> = (props) => {
  const localized = React.useContext(LocalizedContext).strings.components.feedbackModal;
  const [date, setDate] = React.useState<Date>();

  const handleValueChange = (newDate) => setDate(newDate);
  const handleSubmit = () => {
    if (!date) {
      pushFinishAttendance({ message: localized.dateMustBeSelected, succeeded: false });
      return alert(localized.dateMustBeSelected);
    }
    const currentDate = new Date();
    if (date.getTime() - currentDate.getTime() <= 0) {
      pushFinishAttendance({ message: localized.dateCannotBeInPast, succeeded: false });

      return alert(localized.dateCannotBeInPast);
    }
    props.onSubmit(date);
  };

  return (
    <FeedbackModalCategoryWrapperStyled>
      <Form onSubmit={handleSubmit}>
        <H1>{localized.doNotForgetTheScheduling}</H1>
        <VSeparator />

        <Form.Field name='date' label='Agende um horário'>
          <DatePicker showTimeSelect onValueChange={handleValueChange} timeInterval={DATEPICKER_INTERVAL} />
        </Form.Field>
        <VSeparator />

        <Hbox>
          <Hbox.Item>
            <Button
              kind='neutral'
              expanded
              type='button'
              onClick={props.onBack}
              outlined
              loading={props.loading}
              dataGtmEventCategory={EventCategory.Attendence}
              dataGtmEventAction={EventAction.Click.Button}
              dataGtmEventLabel={sanitizeMessage(localized.back)}
            >
              {localized.back}
            </Button>
          </Hbox.Item>
          <Hbox.Separator />

          <Hbox.Item>
            <Button
              kind='callToAction'
              expanded
              type='submit'
              data-cy='button-send-feedback'
              loading={props.loading}
              light
              dataGtmEventCategory={EventCategory.Attendence}
              dataGtmEventAction={EventAction.Click.Button}
              dataGtmEventLabel={sanitizeMessage(props.hasNextStep ? localized.continue : localized.finish)}
            >
              {props.hasNextStep ? localized.continue : localized.finish}
            </Button>
          </Hbox.Item>
        </Hbox>
      </Form>
    </FeedbackModalCategoryWrapperStyled>
  );
};
