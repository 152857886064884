export const chatStyleStr = `
    :root {
      /* Cor dos botões em repouso */
      --cor1: #532883;

      /* Cor de fundo FAQ */
      --cor2: rgb(245, 245, 245);
    }

    .embeddedServiceSidebarMinimizedDefaultUI.helpButton {
      top: 15px;
      right: 100px;
      box-shadow: none;
      border: none;
    }

    .embeddedServiceHelpButton, .embeddedServiceSidebar.modalContainer {
      z-index: 25
    }

    .embeddedServiceSidebar.layout-docked .dockableContainer {
      right: 377px;
    }

    .embeddedServiceHelpButton .helpButton .uiButton {
      background-color: #532883;
      font-family: 'Arial', sans-serif;
      box-shadow: none
    }

    .embeddedServiceHelpButton .helpButton .uiButton:focus {
      outline: 1px solid #532883;
    }

    .embeddedServiceHelpButton .helpButton .helpButtonEnabled:hover::before,
    .embeddedServiceHelpButton .helpButton .helpButtonEnabled:focus::before {
      background-color: unset;
    }

    .embeddedServiceHelpButton .helpButton {
      top: 15px;
      right: 200px;
    }

    @font-face {
      font-family: 'Salesforce Sans';
      src: url('https://www.sfdcstatic.com/system/shared/common/assets/fonts/SalesforceSans/SalesforceSans-Regular.woff') format('woff'),
        url('https://www.sfdcstatic.com/system/shared/common/assets/fonts/SalesforceSans/SalesforceSans-Regular.ttf') format('truetype');
    }

    div.topicList h2 {
      margin-left: 10px;
      margin-bottom: 2px;
      text-align: left;
      font-size: 1.1em;
    }

    div.topicList ol {
      margin-top: 4px;
      margin-right: 5px;
      text-align: left;
      padding-left: 10px;
      display: block;
      background-color: var(--cor2);
      border-radius: 5px;
      padding-top: 20px;
      padding-bottom: 20px;
    }

    div.topicList li {
      display: list-item;
      list-style-position: inside;
      list-style-type: decimal !important;
      margin-bottom: 0.5rem;
      padding-left: 0.142857rem;
    }

    div.topicList a {
      color: var(--cor1) !important;
      text-decoration: none !important;
    }

    div.topicList button {
      width: 130px;
      margin-top: 5px;
    }

    div.topicList span {
      position: absolute;
      width: 120px;
    }`;

export const displayNone = `
    .embeddedServiceHelpButton .helpButton, .embeddedServiceSidebar .modalContainer {
      display: none;
    }
`;
