import React from 'react';
import { Container as TypediContainer } from 'typedi';
import { isNullOrUndefined } from 'util';

import { Auth } from '@app/data/graphql';
import { AuthenticationRepository } from '@app/data/http/';
import { filterApp } from '@app/modules/account/obj.redirect-if-logged-in/app-filter.hook';
import { AppType } from '@app/components/obj.page-wrapper/mol.app-header/pathname-to-app-type.mapper';

export type UserProfile = Auth['Auth']['profile'];

export interface UserProviderState {
  userProfile?: UserProfile;
  isLogged: () => boolean;
  appType: AppType;
}

export const UserContext = React.createContext<UserProviderState>(null);

export const UserProvider: React.FC = (props) => {
  const authenticationDataSource = TypediContainer.get(AuthenticationRepository);
  const initialUserProfile = authenticationDataSource.getUserProfile();
  const [userProfile, setUserProfile] = React.useState(initialUserProfile);

  const onLogin = (newUserProfile: UserProfile) => {
    setUserProfile(newUserProfile);
  };

  const onLogout = () => {
    setUserProfile(null);
  };

  const isLogged = () => {
    return !isNullOrUndefined(userProfile);
  };

  authenticationDataSource.addObserver({ onLogin, onLogout });

  const appType = filterApp(userProfile?.structures);

  return <UserContext.Provider value={{ appType, isLogged, userProfile }}>{props.children}</UserContext.Provider>;
};
