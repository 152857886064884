import { ShimmerH } from '@atomic/mol.shimmer';
import { Hbox } from '@atomic/obj.box';
import * as React from 'react';

export const OrderTotalShimmer: React.FC = () => {
  return (
    <>
      <Hbox>
        <Hbox.Item>
          <ShimmerH heading='H3' />
        </Hbox.Item>
        <Hbox.Separator />
        <Hbox.Item hAlign='flex-end'>
          <ShimmerH heading='H3' />
        </Hbox.Item>
      </Hbox>
      <Hbox>
        <Hbox.Item>
          <ShimmerH heading='H3' />
        </Hbox.Item>
        <Hbox.Separator />
        <Hbox.Item hAlign='flex-end'>
          <ShimmerH heading='H3' />
        </Hbox.Item>
      </Hbox>
    </>
  );
};
