import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

export const FaIcon = {
  Ban: (props) => <FontAwesomeIcon {...props} icon='ban' />,
  Bus: (props) => <FontAwesomeIcon {...props} icon='bus' />,
  Check: (props) => <FontAwesomeIcon {...props} icon='check' />,
  ChevronDown: (props) => <FontAwesomeIcon {...props} icon='chevron-down' />,
  ChevronLeft: (props) => <FontAwesomeIcon {...props} icon='chevron-left' />,
  ChevronRight: (props) => <FontAwesomeIcon {...props} icon='chevron-right' />,
  ChevronUp: (props) => <FontAwesomeIcon {...props} icon='chevron-up' />,
  Circle: (props) => <FontAwesomeIcon {...props} icon='circle' />,
  Clock: (props) => <FontAwesomeIcon {...props} icon='clock' />,
  Close: (props) => <FontAwesomeIcon {...props} icon='times' />,
  Comment: (props) => <FontAwesomeIcon {...props} icon='comment-alt' />,
  EllipsisV: (props) => <FontAwesomeIcon {...props} icon='ellipsis-v' />,
  FileUpload: (props) => <FontAwesomeIcon {...props} icon='upload' />,
  FlashAlert: (props) => <FontAwesomeIcon {...props} icon='times-circle' />,
  FlashInfo: (props) => <FontAwesomeIcon {...props} icon='info-circle' />,
  FlashSuccess: (props) => <FontAwesomeIcon {...props} icon='check-circle' />,
  FlashWarning: (props) => <FontAwesomeIcon {...props} icon='exclamation-circle' />,
  Heart: (props) => <FontAwesomeIcon {...props} icon='heart' />,
  Image: (props) => <FontAwesomeIcon {...props} icon='image' />,
  LongArrowRight: (props) => <FontAwesomeIcon {...props} icon='long-arrow-alt-right' />,
  Menu: (props) => <FontAwesomeIcon {...props} icon='bars' />,
  Microphone: (props) => <FontAwesomeIcon {...props} icon='microphone' />,
  NewMessage: (props) => <FontAwesomeIcon {...props} icon='comments' />,
  Phone: (props) => <FontAwesomeIcon {...props} icon='phone' />,
  PowerOff: (props) => <FontAwesomeIcon {...props} icon='power-off' />,
  Search: (props) => <FontAwesomeIcon {...props} icon='search' />,
  Share: (props) => <FontAwesomeIcon {...props} icon='share-alt' />,
  Star: (props) => <FontAwesomeIcon {...props} icon='star' />,
  StarHalf: (props) => <FontAwesomeIcon {...props} icon='star-half' />,
  StepperMinus: (props) => <FontAwesomeIcon {...props} icon='minus' />,
  StepperPlus: (props) => <FontAwesomeIcon {...props} icon='plus' />,
  Times: (props) => <FontAwesomeIcon {...props} icon='times' />,
  Trash: (props) => <FontAwesomeIcon {...props} icon='trash' />,
  TrashAlt: (props) => <FontAwesomeIcon {...props} icon='trash-alt' />,
  Warning: (props) => <FontAwesomeIcon {...props} icon='exclamation-triangle' />,
};
