import styled from 'styled-components';

const HeaderStyled = styled.div`
  background-color: ${(props) => props.theme.Color.Primary};
  flex-direction: column;
  justify-content: center;
`;

export const HeaderDeskStyled = styled(HeaderStyled)`
  display: none;
  padding: 0 ${(props) => props.theme.Spacing.XLarge};
  height: ${(props) => props.theme.HeaderHeight.Desk};

  @media all and (min-width: ${(props) => props.theme.Breakpoint.md}em) {
    display: flex;
  }
`;

export const HeaderMobileStyled = styled(HeaderStyled)`
  display: flex;
  padding: 0 ${(props) => props.theme.Spacing.Large};
  height: ${(props) => props.theme.HeaderHeight.Mobile};

  @media all and (min-width: ${(props) => props.theme.Breakpoint.md}em) {
    display: none;
  }
`;
