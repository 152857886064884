import { AutocompleteProductCell } from '@app/components/atm.product-cell/autocomplete-product-cell.component';
import {
  FastShopCard,
  FastShopContentWrapper,
  FastShopProductListWrapper,
} from '@app/components/mol.fast-shop/fast-shop.component.style';
import { LocalizedContext } from '@app/components/obj.localization';
import { ProductsShimmer } from '@app/components/org.products/products.shimmer';
import { useProductSearchQuery } from '@app/domain/orders.use-case';
import { OrderContext } from '@app/providers/order.provider';
import { TextField } from '@atomic/atm.text-field';
import { Body, Centered, H1 } from '@atomic/atm.typography';
import { VSeparator } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import { Modal } from '@atomic/obj.modal';
import { debounce } from 'debounce';
import * as React from 'react';

import { ProductSearchModalPlaceholder } from './product-search-modal-placeholder.component';

interface ProductSearchModalProps {
  isOpened: boolean;
  onClose: () => void;
  onProductClick: (productCode: string) => void;
}

const MinLengthToSearch = 3;
const DebounceToSearchMillis = 300;

export const ProductSearchModal: React.FC<ProductSearchModalProps> = (props) => {
  const { isOpened, onClose, onProductClick } = props;
  const [searchInput, setSearchInput] = React.useState('');
  const localized = React.useContext(LocalizedContext).strings.components.searchProducts;
  const orderInfo = React.useContext(OrderContext).currentOrder;

  const [search, productSearch] = useProductSearchQuery(orderInfo?.distributionCenterCode, orderInfo?.cycle);

  const handleProductSearchSubmit = (data: string) => {
    setSearchInput(data);
    handleChangeText(data);
  };

  const handleClose = () => {
    setSearchInput('');
    onClose();
  };

  const handleChangeText = React.useMemo(
    () =>
      debounce((text: string) => {
        if (text.length >= MinLengthToSearch) {
          search(text);
        }
      }, DebounceToSearchMillis),
    [search],
  );

  return (
    <Modal onClose={handleClose} opened={isOpened}>
      <FastShopCard>
        <FastShopContentWrapper>
          <H1>{localized.title}</H1>
          <VSeparator />
          <TextField
            onValueChange={handleProductSearchSubmit}
            placeholder={localized.searchProduct}
            type='text'
            icon='search'
          />
          <VSeparator small />
          <FastShopProductListWrapper>
            <LoadingState
              loading={productSearch.loading}
              error={!!productSearch.error}
              data={productSearch?.data?.length > 0}
            >
              <LoadingState.Shimmer>
                <ProductsShimmer />
              </LoadingState.Shimmer>
              <LoadingState.Error>
                <Body>
                  <Centered>{localized.error}</Centered>
                </Body>
              </LoadingState.Error>
              <LoadingState.NoData>
                <ProductSearchModalPlaceholder
                  placeholderText={
                    searchInput.length < MinLengthToSearch ? localized.startSearching : localized.productsNotFound
                  }
                />
              </LoadingState.NoData>
              {searchInput.length < MinLengthToSearch && productSearch?.data?.length > 0 ? (
                <ProductSearchModalPlaceholder placeholderText={localized.startSearching} />
              ) : (
                productSearch?.data?.map((product) => (
                  <>
                    <AutocompleteProductCell
                      key={product.code}
                      product={{ id: product.code, name: product.name }}
                      onClick={onProductClick}
                    />
                    <VSeparator />
                  </>
                ))
              )}
            </LoadingState>
          </FastShopProductListWrapper>
        </FastShopContentWrapper>
      </FastShopCard>
    </Modal>
  );
};
