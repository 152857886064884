import React from 'react';
import { UserContext } from '@app/providers/user.provider';
import { AppType } from '@app/components/obj.page-wrapper/mol.app-header/pathname-to-app-type.mapper';

export const useAppType = () => {
  const { appType } = React.useContext(UserContext);

  const isAdmin = appType === AppType.Admin;
  const isActivation = appType === AppType.Activation;
  const isIrDigital = appType === AppType.IrDigital;
  const isStarts = appType === AppType.Starts;

  return { isActivation, isAdmin, isIrDigital, isStarts };
};
