import { FaIcon } from '@atomic/atm.fa-icon';
import { Theme } from '@atomic/obj.constants';
import styled, { css } from 'styled-components';

const size = 44;
const BUTTON_SIZE = '36px';
const COUNTER_WIDTH = '80px';

export const StepperCounterStyled = styled.div`
  width: ${COUNTER_WIDTH};
`;

export interface StepperSymbolProps extends Theme {
  disabled: boolean;
  kind: 'primary' | 'neutral';
}

export const StepperSymbolCss = css`
  line-height: ${size}px;
  width: ${size}px;
  color: ${(props: StepperSymbolProps) =>
    props.disabled
      ? props.theme.Color.Primary
      : props.kind === 'neutral'
      ? props.theme.Color.Neutral
      : props.theme.Color.White};
`;

export const StepperWrapperStyled = styled.button`
  height: ${(props) => (props.size === 'small' ? BUTTON_SIZE : props.theme.FieldHeight)};
  width: ${(props) => (props.size === 'small' ? BUTTON_SIZE : props.theme.FieldHeight)};
  border-radius: 50%;
  background-color: ${(props: StepperSymbolProps) =>
    props.disabled ? props.theme.Color.GrayLight : props.theme.Color.Primary};
  border: none;
`;

export const StepperMinusStyled = styled(FaIcon.StepperMinus)`
  ${StepperSymbolCss}
`;

StepperMinusStyled.defaultProps = {
  kind: 'primary',
};

export const StepperPlusStyled = styled(FaIcon.StepperPlus)`
  ${StepperSymbolCss}
`;

StepperPlusStyled.defaultProps = {
  kind: 'primary',
};
