import { dd } from './regex-ddd-whitelist.validator';
import { blocklistRegex } from '@app/components/atm.not-allowed-word-textarea-field/blocklist';

const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const PHONE_REGEX = /^(\(?[0-9]{2}\)? ?[0-9]{4}-?[0-9]{4})|(\(?[0-9]{2}\)? ?[0-9]{5}-?[0-9]{4})$/i;
const CPF_REGEX = /^([0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9]{2})$/i;
const ZIP_CODE_REGEX = /^\d{5}[-]\d{3}$/;
const CNPJ_REGEX = /^([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})$/i;
const DATE_REGEX = /^((0?[1-9]|[12][0-9]|3[01])[/](0?[1-9]|1[012])[/](19|20)[0-9]{2})*$/;
const HEIGHT_REGEX = /^([1-2][0-9][0-9])|([1-9][0-9])$/;
const WEIGHT_REGEX = /^([1-9][0-9]?[0-9]?.?[0-9])$/;
const NAME_REGEX = /^[A-ZÀ-Ÿ][A-zÀ-ÿ']+\s([A-zÀ-ÿ']\s?)*[A-ZÀ-Ÿ][A-zÀ-ÿ']+$/;

// At least 1 char and 1 number regex: https://stackoverflow.com/a/7684859/3670829
// Security recommendation: Failing to enforce passwords of at least 7 characters, a complexity
// of at least alpha and numeric characters increases the risk of a brute force attack.
export const PASSWORD_REGEX = /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9])$/;

export function isEmailValid(email): boolean {
  return EMAIL_REGEX.test(email);
}

export function isHeightValid(height): boolean {
  return HEIGHT_REGEX.test(height);
}

export function isWeightValid(weight): boolean {
  return WEIGHT_REGEX.test(weight);
}

export function isNameValid(name): boolean {
  return NAME_REGEX.test(name);
}

export function isLuhnValid(cardNumbers: string): boolean {
  if (!cardNumbers) {
    return false;
  }
  let isDouble = 0;
  const sum = cardNumbers
    .replace(/\D/g, '')
    .split('')
    .reduceRight((accumulator, currentValue) => {
      const number = Number(currentValue);
      const value = isDouble ? number * 2 : number;
      const result = value > 9 ? value - 9 : value;
      isDouble = isDouble ^ 1;
      return accumulator + result;
    }, 0);
  return sum % 10 === 0;
}

export function isPhoneValid(phone): boolean {
  if (!phone) {
    return true;
  }

  const onlyNumbers = phone.replace(/\D/g, '');
  if (onlyNumbers.length < 10 || onlyNumbers.length > 11) {
    return false;
  }

  if (
    onlyNumbers.substr(2) === '00000000' ||
    onlyNumbers.substr(2) === '000000000' ||
    onlyNumbers.substr(2) === '11111111' ||
    onlyNumbers.substr(2) === '111111111' ||
    onlyNumbers.substr(2) === '000000000' ||
    onlyNumbers.substr(2) === '00000000' ||
    onlyNumbers.substr(2) === '11111111' ||
    onlyNumbers.substr(2) === '111111111'
  ) {
    return false;
  }
  return PHONE_REGEX.test(phone);
}
export function isDDValid(phone): boolean {
  const onlyNumbers = phone.replace(/\D/g, '');
  if (dd.includes(onlyNumbers[0] + onlyNumbers[1])) {
    return true;
  }
  return false;
}

export function isDateValid(date): boolean {
  if (date instanceof Date) {
    return true;
  }
  return DATE_REGEX.test(date);
}

export function isExpirationDateValid(value: string) {
  const [month, year] = value.split('/');
  if (!year || !month || +month > 12) {
    return false;
  }

  const centuryYear = new Date().getFullYear().toString().slice(0, -2) + '00';

  const expirationDate = new Date(+centuryYear + +year, +month, 0).getTime();

  return Date.now() < expirationDate;
}

export function maxDate(max, value) {
  if (value === null || value === '') {
    return true;
  }

  return value <= max;
}

export function isPasswordValid(password): boolean {
  return PASSWORD_REGEX.test(password);
}

// src: https://www.devmedia.com.br/validar-cpf-com-javascript/23916
// eslint-disable-next-line complexity
export function isSocialSecurityNumberValid(str) {
  if (str !== undefined && str !== null && str.trim().length === 0) {
    return true;
  }

  if (str === undefined) {
    return true;
  }

  const onlyNumbers = str.replace(/\D/g, '');
  let sum;
  let remainder;
  sum = 0;

  if (
    onlyNumbers === '00000000000' ||
    onlyNumbers === '11111111111' ||
    onlyNumbers === '22222222222' ||
    onlyNumbers === '33333333333' ||
    onlyNumbers === '44444444444' ||
    onlyNumbers === '55555555555' ||
    onlyNumbers === '66666666666' ||
    onlyNumbers === '77777777777' ||
    onlyNumbers === '88888888888' ||
    onlyNumbers === '99999999999'
  ) {
    return false;
  }

  for (let i = 1; i <= 9; i++) {
    sum = sum + parseInt(onlyNumbers.substring(i - 1, i), 10) * (11 - i);
  }
  remainder = (sum * 10) % 11;

  if (remainder === 10 || remainder === 11) {
    remainder = 0;
  }
  if (remainder !== parseInt(onlyNumbers.substring(9, 10), 10)) {
    return false;
  }

  sum = 0;
  for (let i = 1; i <= 10; i++) {
    sum = sum + parseInt(onlyNumbers.substring(i - 1, i), 10) * (12 - i);
  }
  remainder = (sum * 10) % 11;

  if (remainder === 10 || remainder === 11) {
    remainder = 0;
  }
  if (remainder !== parseInt(onlyNumbers.substring(10, 11), 10)) {
    return false;
  }
  return true;
}

export function isZipCodeValid(value): boolean {
  const onlyNumbers = value.replace(/\D/g, '');
  if (onlyNumbers === value && value.length === 8) {
    return true;
  }

  return ZIP_CODE_REGEX.test(value);
}

export function isCpfPatternValid(value): boolean {
  return CPF_REGEX.test(value);
}

export function isCnpjPatternValid(value): boolean {
  return CNPJ_REGEX.test(value);
}

export function isCnsValid(value): boolean {
  const CnsLength = 15;
  const CheckSumModule = 11;

  const checkSum = (cns: string): number => {
    const length = 0;
    let sum = 0;

    for (let i = 0; i < length; i++) {
      const digit: number = +cns.charAt(i) * (CnsLength - i);
      sum += digit;
    }

    return sum;
  };

  if (value === undefined) {
    return false;
  }

  const onlyNumbers = value.replace(/\D/g, '');

  const invalidMatches: boolean = !onlyNumbers.match('[1-2]\\d{10}00[0-1]\\d') && !onlyNumbers.match('[7-9]\\d{14}');
  if (onlyNumbers.length !== CnsLength || invalidMatches || checkSum(onlyNumbers) % CheckSumModule !== 0) {
    return false;
  }

  return true;
}

export function hasOnlyValidWords(value: string): boolean {
  return !(value?.match(blocklistRegex)?.length > 0);
}
