import { ConfirmationModal } from '@app/components/mol.confirmation-modal/confirmation-modal.component';
import { ErrorMessage } from '@app/components/mol.error-message';
import { LocalizedContext } from '@app/components/obj.localization';
import { Project } from '@app/config-variables';
import { Cart } from '@app/data/graphql';
import { useStartOrderMutation } from '@app/domain/orders.use-case';
import { CartPageLocationState } from '@app/models/orders.model';
import { AppPath } from '@app/modules/app/routes';
import { useStartOrderParams } from '@app/modules/cart/org.start-oder-params-hook/start-order-params.hook';
import { OrderContext, QueueContext } from '@app/providers';
import { Button } from '@atomic/atm.button';
import { Frame } from '@atomic/atm.frame';
import { ShimmerBox } from '@atomic/mol.shimmer';
import { Hbox } from '@atomic/obj.box';
import { CommonTheme } from '@atomic/obj.constants';
import { Col, Grid, Row, VSeparator } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router';
import { ThemeContext } from 'styled-components';
import { CartCycleSelectContainer } from './mol.cart-cycle-select/cart-cycle-select.container';
import { CartCycleSelectContainerEudora } from './mol.cart-cycle-select/cart-cycle-select.container.eudora';

export const CartSelectCyclePage: React.FC = () => {
  const theme = React.useContext<CommonTheme>(ThemeContext);
  const { cartPages, cartSection } = React.useContext(LocalizedContext).strings.components;
  const order = React.useContext(OrderContext);
  const needsStarterPackSelection = order.starterPacks?.length > 0;
  const history = useHistory();
  const { state } = useLocation<CartPageLocationState>();
  const queue = React.useContext(QueueContext);

  const [modalOpen, setModalOpen] = React.useState(false);

  const startOrderParams = useStartOrderParams();

  const handleStartOrderComplete = (newCart: Cart) => {
    order.updateCart(newCart);
    order.updateCurrentOrder();
    queue?.currentOrders?.refetch();
    history.push({ pathname: AppPath.Cart.Management, state });
  };

  const { loading: startOrderLoading, updateOrder } = useStartOrderMutation({
    onCompleted: handleStartOrderComplete,
    onError: order.handleOrderError,
    startOrderParams,
  });

  const isEudoraProject = theme.Project === Project.eudora;

  const handleNextStep = () => {
    !isEudoraProject && needsStarterPackSelection
      ? history.push({
          pathname: AppPath.Cart.StarterKits,
          state,
        })
      : updateOrder();
  };

  const handleCycleSelectionFinish = () => {
    order.selectedCycle === order.currentCycle && order.currentCycle !== order.previousCycle
      ? setModalOpen(true)
      : handleNextStep();
  };

  return (
    <Grid>
      <Row center='xs' mt>
        <Col xs={8}>
          <Frame>
            <LoadingState
              loading={order?.availableCycles.loading}
              error={!!order?.availableCycles.error}
              data={!!order?.availableCycles.data}
            >
              <LoadingState.Shimmer>
                <ShimmerBox width='300px' />
              </LoadingState.Shimmer>

              <LoadingState.Error>
                <ErrorMessage refetch={order?.availableCycles.refetch} />
              </LoadingState.Error>

              {isEudoraProject ? <CartCycleSelectContainerEudora /> : <CartCycleSelectContainer />}

              <VSeparator />
              <Hbox>
                <Hbox.Item>
                  <Button expanded outlined onClick={() => history.goBack()} loading={startOrderLoading}>
                    {cartPages.back}
                  </Button>
                </Hbox.Item>

                <Hbox.Separator />
                <Hbox.Item hAlign='flex-end'>
                  <Button
                    expanded
                    onClick={handleCycleSelectionFinish}
                    loading={startOrderLoading}
                    disabled={!order.selectedCycle}
                  >
                    {cartPages.advance}
                  </Button>
                </Hbox.Item>
              </Hbox>
            </LoadingState>
          </Frame>
          <ConfirmationModal
            opened={modalOpen}
            onClose={() => setModalOpen(false)}
            onConfirm={handleNextStep}
            title={cartSection.cycleSelectionConfirmation.title}
            message={cartSection.cycleSelectionConfirmation.message(order.previousCycle, order.currentCycle)}
            confirmationButtonText={cartSection.cycleSelectionConfirmation.buttonText}
          />
        </Col>
      </Row>
    </Grid>
  );
};
