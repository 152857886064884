import * as React from 'react';
import { hydrate, render } from 'react-dom';
import * as Loadable from 'react-loadable';
import { Router } from 'react-router';
import { Container } from 'typedi';

import { HistoryToken } from '@app/core/route';
import { App } from '@app/modules/app';

export function bootstrap() {
  const history = Container.get(HistoryToken);
  const renderMethod = module.hot ? render : hydrate;

  Loadable.preloadReady().then(() => {
    renderMethod(
      <Router history={history}>
        <App />
      </Router>,
      document.getElementById('root'),
    );
  });
}
