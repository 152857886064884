import React, { useEffect } from 'react';
import { FieldsObject } from 'react-ga';

import { RouteComponentProps } from '@app/core/route/route.component';

import { GoogleAnalyticsService } from './google-analytics.service';

export const withTracker = <P extends RouteComponentProps>(
  WrappedComponent: React.ComponentType<P>,
  options: FieldsObject = {},
) => {
  const trackPage = (page: string) => {
    GoogleAnalyticsService.set({ page, ...options });
    GoogleAnalyticsService.pageView(page);
  };

  return (props: P) => {
    useEffect(() => {
      trackPage(props.location.pathname);
    }, [props.location.pathname]);

    return <WrappedComponent {...props} />;
  };
};
