import { Service } from 'typedi';
import { GraphqlInterceptor } from '@app/core/graphql';
import { ErrorResponse } from '@apollo/client/link/error';
import { mapGraphqlErrorToNewRelic } from '@app/data/graphql/graphql-error.mapper';

@Service()
export class NewRelicErrorInterceptor implements GraphqlInterceptor {
  error(error: ErrorResponse) {
    const pathname = window.location.pathname;
    const info = mapGraphqlErrorToNewRelic(error);

    error.graphQLErrors.map((graphqlError) => {
      if (window?.newrelic) {
        window?.newrelic?.noticeError(graphqlError, { pathname, ...info });
      }
    });
  }
}
