import { IcWhatsapp } from '@app/components/icons/ic-whatsapp.component';
import { ErrorMessage } from '@app/components/mol.error-message';
import { LocalizedContext } from '@app/components/obj.localization';
import { useErrorFlashDispatcher } from '@app/domain/error-dispatcher.hook';
import { DividerGray } from '@atomic/atm.divider/divider.component.style';
import { Body, H5 } from '@atomic/atm.typography';
import { InfiniteScrollLoadingRow } from '@atomic/mol.infinite-scroll-loading-row';
import { NotificationDialog } from '@atomic/mol.notification-dialog';
import { ShimmerInput } from '@atomic/mol.shimmer';
import { Hbox, Vbox } from '@atomic/obj.box';
import { VSeparator } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import { ApolloError } from '@apollo/client';
import * as React from 'react';
import { ResellerInteraction } from '@app/data/graphql';
import Observer from 'react-intersection-observer';
import { WhatsappContactCell } from './whatsapp-contact-cell.component';
import { GtmEventTag } from '@app/utils/data-layer';
import { IcWhatsappWhite } from '@app/modules/header/components/mol.header-notification/header-notification.component.style';

interface MessageNotificationsProps extends GtmEventTag {
  error: ApolloError;
  interactions: ResellerInteraction[];
  loading: boolean;
  refetch: () => void;
  startConversation: (resellerCode: string, name: string) => void;
  startConversationError: ApolloError;
  hasNextPage: boolean;
  onLoadMore: () => void;
  onClose: () => void;
  interactionsLength: number;
}

export const MessageNotifications: React.FC<MessageNotificationsProps> = ({
  loading,
  interactions,
  error,
  refetch,
  startConversation,
  startConversationError,
  hasNextPage,
  onLoadMore,
  onClose,
  interactionsLength,
  dataGtmEventCategory,
  dataGtmEventAction,
  dataGtmEventLabel,
}) => {
  const strings = React.useContext(LocalizedContext).strings.components.messageNotifications;
  const hasMessages = interactionsLength > 0;
  useErrorFlashDispatcher(startConversationError);

  return (
    <NotificationDialog
      onClose={onClose}
      notifications={interactionsLength}
      icon={<IcWhatsappWhite />}
      dataGtmEventCategory={dataGtmEventCategory}
      dataGtmEventAction={dataGtmEventAction}
      dataGtmEventLabel={dataGtmEventLabel}
    >
      <Hbox>
        <Hbox.Separator />
        <Hbox.Item>
          <H5>{strings.title}</H5>
        </Hbox.Item>
        <Hbox.Separator />
      </Hbox>
      <DividerGray />

      <LoadingState loading={loading} data={!!interactions} error={!!error}>
        <LoadingState.Shimmer>
          <ShimmerInput />
        </LoadingState.Shimmer>

        <LoadingState.Error>
          <VSeparator />
          <ErrorMessage text={strings.error} refetch={() => refetch()} />
          <VSeparator />
        </LoadingState.Error>

        {!hasMessages ? (
          <Vbox hAlign='center'>
            <VSeparator />
            <IcWhatsapp />
            <VSeparator />
            <Body>{strings.noInteractions}</Body>
            <VSeparator />
          </Vbox>
        ) : (
          <>
            {interactions?.map((interaction) => (
              <WhatsappContactCell
                key={interaction.resellerId}
                name={interaction.name}
                lastMessage={interaction.lastMessage.message}
                lastMessageAt={new Date(interaction.lastMessage.sentAt)}
                onClick={() => startConversation(interaction.resellerId, interaction.name)}
              />
            ))}
            {hasNextPage && (
              <Observer>
                {({ inView, ref }) => <InfiniteScrollLoadingRow ref={ref} isVisible={inView} loadMore={onLoadMore} />}
              </Observer>
            )}
          </>
        )}
      </LoadingState>
    </NotificationDialog>
  );
};
