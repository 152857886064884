import { FormFieldContext, FormFieldContextState } from '@atomic/obj.form';
import * as React from 'react';
import {
  RadioCheckedStyled,
  RadioFieldInputStyled,
  RadioFieldLabelStyled,
  RadioFieldStyled,
  RadioUncheckedStyled,
} from './radio-field.component.style';

interface RadioFieldProps {
  id: any;
  onClick?: (id: any) => void;
  disabled?: boolean;
  expanded?: boolean;
  mb?: boolean;
  dataGtmEventCategory?: string;
  dataGtmEventAction?: string;
  dataGtmEventLabel?: string;
}

const DefaultRadioBullet = (props: any) =>
  props.checked ? <RadioCheckedStyled {...props} /> : <RadioUncheckedStyled {...props} />;

export class RadioField extends React.Component<RadioFieldProps, undefined> {
  private formFieldConsumer: FormFieldContextState;

  render() {
    return (
      <FormFieldContext.Consumer>
        {(formFieldConsumer: FormFieldContextState) => {
          this.formFieldConsumer = formFieldConsumer;
          if (!formFieldConsumer) {
            throw new Error('<RadioField /> must be wrapped with a <Form.Field> tag');
          }
          return (
            <RadioFieldStyled
              mb={this.props.mb}
              expanded={this.props.expanded}
              onClick={!this.props.disabled ? this.handlePress : undefined}
              data-cy='radiofield'
              data-gtm-event-category={this.props.dataGtmEventCategory}
              data-gtm-event-action={this.props.dataGtmEventAction}
              data-gtm-event-label={this.props.dataGtmEventLabel}
            >
              <RadioFieldInputStyled
                type='radio'
                name={this.formFieldConsumer.name}
                id={this.formFieldConsumer.name + '_' + this.props.id}
                value={this.props.id}
              />
              <DefaultRadioBullet
                checked={this.formFieldConsumer.value === this.props.id}
                disabled={this.props.disabled}
              />
              <RadioFieldLabelStyled
                htmlFor={this.formFieldConsumer.name + '_' + this.props.id}
                expanded={this.props.expanded}
                disabled={this.props.disabled}
              >
                {this.props.children}
              </RadioFieldLabelStyled>
            </RadioFieldStyled>
          );
        }}
      </FormFieldContext.Consumer>
    );
  }

  private handlePress = () => {
    if (this.props.id === this.formFieldConsumer.value) {
      return;
    }

    if (this.props.onClick) {
      this.props.onClick(this.props.id);
    }

    if (this.formFieldConsumer) {
      this.formFieldConsumer.onValueChange(this.props.id, null);
    }
  };
}
