import { useQueryHook } from '@app/core/graphql';
import { BusinessModelResponse, BusinessModels, BusinessModelsVariables } from '@app/data/graphql';
import { CellData } from '@atomic/mol.radio-icon-list/radio-icon-list.component';

export const useBusinessModels = (resellerId: string) => {
  const result = useQueryHook<BusinessModels, BusinessModelsVariables>('business-models.query', {
    skip: !resellerId,
    variables: {
      resellerId,
    },
  });

  return {
    data: result.data?.BusinessModels,
    error: result.error,
    loading: result.loading,
  };
};

export const mapBusinessModels = (models: BusinessModelResponse[]): CellData[] => {
  if (!models) {
    return null;
  }

  return models.map((mode) => {
    return {
      description: mode.description,
      radioId: mode.id,
      title: mode.name,
    };
  });
};
